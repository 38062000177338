import React from 'react';
import { noop } from '@/utils/helpers';
import { sendStatistics } from '@/actions/statisticsActions';

export interface ObjectDetailContextProps {
  isPrint: boolean;
  showPrintView: (visibility: boolean) => void;
  sendStatistics: (action: Parameters<typeof sendStatistics>[0]) => void;
  gaEvent: (action: string, label?: string) => void;
  scrollToHeader: () => void;
}

const ObjectDetailContext = React.createContext<ObjectDetailContextProps>({
  isPrint: false,
  showPrintView: noop,
  sendStatistics: noop,
  gaEvent: noop,
  scrollToHeader: noop,
});

export default ObjectDetailContext;
