import React from 'react';
import classNames from 'classnames';

import { Tooltip as ReactTooltip } from 'react-tooltip';

const Tooltip: typeof ReactTooltip = ({ children, className, ...tooltipProps }) => {
  const tooltipClass = classNames(className);
  return (
    <ReactTooltip className={tooltipClass} {...tooltipProps}>
      {children}
    </ReactTooltip>
  );
};

export default Tooltip;
