import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import type { NameValueExtra } from '@/types/collections';
import { formatPrice, objectTransactionString, urlFormatLocation, formatPriceRange } from '@/utils/formatting';
import { getObjectDetailRoute, typeCombinationString, getRouteByObjectType } from '@/utils/route';
import { identity } from '@/utils/helpers';

import { getPrefixedRoutes } from '@/selectors/appSelectors';
import { setSearchModalOpened } from '@/components/modals/ModalActions';

import Icon from '@/components/icon/Icon';
import LegacyToNextLink from '@/components/link/LegacyToNextLink';
import type { AutocompleteResultsProps } from './AutocompleteTypes';

type ExtraValue = {
  secondaryName: string;
  unit_type: string;
  transaction_type: { transaction_type: string };
  address: {
    county_name: string;
    parish_name: string;
    city_name: string;
    street_name: string;
    house_number: string;
    location_name: string;
  };
  price: string;
  price_min: string;
  price_max: string;
  count: number;
  project_name: string | null | undefined;
};
type Value = {
  name: string;
  values: NameValueExtra<ExtraValue>[];
};

export type SearchAutocompleteResultsProps = AutocompleteResultsProps<Value> & {
  getValue: (value: NameValueExtra<ExtraValue> | undefined) => void;
};

function findNestedValue(items: Value[], value: string) {
  for (let index = 0; index < items.length; index++) {
    const foundValue = items[index].values.find((item) => item.value === value);
    if (foundValue) {
      return foundValue;
    }
  }
  return undefined;
}

function getAddress(extra: ExtraValue) {
  const { address } = extra;

  const streetComponent =
    address.location_name || [address.street_name, address.house_number].filter(identity).join(' ');
  const location = [address.city_name, address.parish_name, address.county_name].filter(identity).join(', ');

  if (streetComponent.length > 0) {
    return (
      <>
        <span className="autocomplete__id-street">{streetComponent}</span>
        <span>, {location}</span>
      </>
    );
  }
  return <span>{location}</span>;
}

function SearchAutocompleteResults({
  t,
  values,
  getValue,
  close,
  gaEvent,
}: SearchAutocompleteResultsProps): React.ReactElement {
  const dispatch = useDispatch();
  const routes = useSelector(getPrefixedRoutes);
  const gaEventHandler = (groupName: string) => {
    const type = groupName.split('.').pop();
    const LABELS = {
      parish: 'cityParish',
      city: 'districtCity',
      friendlyId: 'object',
    };

    gaEvent?.(null, `select_${LABELS[type] || type}`);
  };

  return (
    <>
      {values.map((valueGroup) => {
        return (
          <div key={valueGroup.name}>
            <h5 className="autocomplete__section-title">{t(valueGroup.name)}</h5>
            <ul>
              {valueGroup.values.map(({ name, value, extra }) => {
                if (valueGroup.name === 'autocomplete.friendlyId') {
                  return (
                    <li key={value}>
                      <LegacyToNextLink
                        href={getObjectDetailRoute(
                          getRouteByObjectType(routes, extra.unit_type),
                          value,
                          typeCombinationString(extra.transaction_type.transaction_type, extra.unit_type, 'url'),
                          urlFormatLocation(extra.address)
                        )}
                        className="autocomplete__item"
                        onClick={() => {
                          gaEventHandler(valueGroup.name);
                          dispatch(setSearchModalOpened(false));
                        }}
                      >
                        <div className="autocomplete__id-content">
                          <div className="autocomplete__id-address">
                            {extra.project_name && `${extra.project_name}, `}
                            {getAddress(extra)}
                          </div>
                          <div className="autocomplete__id-price">
                            {extra.price
                              ? formatPrice(extra.price)
                              : formatPriceRange(extra.price_min, extra.price_max)}
                          </div>
                          <div className="autocomplete__id-info">
                            {`ID: ${name} |
                                ${t(
                                  objectTransactionString(extra.unit_type, extra.transaction_type.transaction_type)
                                )}`}
                          </div>
                        </div>
                        <Icon name="angle-right" className="autocomplete__id-icon" />
                      </LegacyToNextLink>
                    </li>
                  );
                }
                return (
                  <li key={`${value}${extra.secondaryName}`}>
                    <button
                      type="button"
                      className="autocomplete__item"
                      value={value}
                      onClick={(event) => {
                        if (getValue) {
                          const value = findNestedValue(values, event.target.value);
                          if (value) getValue(value);
                        }
                        close();
                        gaEventHandler(valueGroup.name);
                      }}
                    >
                      <span className="autocomplete__value">
                        <span className="autocomplete__primary-val">{name}</span>
                        {extra.secondaryName && (
                          <span className="autocomplete__secondary-val">{extra.secondaryName}</span>
                        )}
                      </span>
                      {/* <span className="autocomplete__type">{extra.type}</span> */}
                      <span className="autocomplete__count">{extra.count}</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </>
  );
}

export default SearchAutocompleteResults;
