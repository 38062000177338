import { GA_HEADER_MENU } from '@/constants/ga';
import { useAppSelector } from '@/hooks';
import { getPrefixedRoutes } from '@/selectors/appSelectors';
import React, { useContext } from 'react';
import ReactGA from '@/utils/ga';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import AppContext from '../appContext';
import BaseHeader, { HeaderProps } from './BaseHeader';
import { getLangPrefixlessPath } from './util';

function Header(props: HeaderProps) {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { Link } = useContext(AppContext);
  const routes = useAppSelector(getPrefixedRoutes);

  return (
    <BaseHeader
      t={t}
      routes={routes}
      changeLanguage={(lang) => history.push(`/${lang}${getLangPrefixlessPath(location)}`)}
      renderLanguageLink={(lang, children, className) => (
        <Link
          locale={lang}
          href={getLangPrefixlessPath(location)}
          className={className}
          onClick={() => {
            ReactGA.event({
              category: GA_HEADER_MENU,
              action: 'select_language',
              label: lang,
            });
          }}
        >
          {children}
        </Link>
      )}
      {...props}
    />
  );
}

export default Header;
