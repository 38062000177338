import React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { List, Map as ImmutableMap } from 'immutable';
import { withTranslation } from 'react-i18next';

import api from '@/api/City24Api';
import { applyImageFormat } from '@/utils/formatting';
import { fetchMyObject } from '@/legacyPages/MyObject/MyObjectActions';
import { fetchBrokerDetails } from '@/legacyPages/brokerDetail/brokerDetailActions';
import { fetchNearbyPlaces } from '@/components/map/Map/mapActions';
import { getBrowser, getPrefixedRoutes } from '@/selectors/appSelectors';
import { getAddressMap, getMyObjectImg, getMyObjectOriginal } from '@/legacyPages/MyObject/MyObjectSelectors';
import { getBrokerDetails, getBrokerOffice } from '@/legacyPages/brokerDetail/brokerDetailSelectors';
import { getAdditionalServices } from '@/components/object/objectDetailSelectors';
import { getMapNearbyPlaces } from '@/components/map/mapSelectors';

import Loading from '@/components/loading/Loading';
import Container from '@/components/container/Container';
import Section from '@/components/section/Section';
import AdGallery from '@/components/gallery/AdGallery';
import Description from '@/components/object/Description/Description';
import FullSpecs from '@/components/object/FullSpecs/FullSpecs';
import ObjectDetailInfo from '@/components/object/ObjectDetailInfo';
import BusinessCard from '@/components/BusinessCard/BusinessCard';
import AdditionalServices from '@/components/object/AdditionalServices/AdditionalServices';
import VirtualTour from '@/components/object/VirtualTour/VirtualTour';
import Streetview from '@/components/map/Streetview/Streetview';
import Button from '@/components/button/Button';
import Tabs from '@/components/tabs/Tabs';
import Tab from '@/components/tabs/Tab';

import logoEE from '@/img/logo_EE.svg';
import logoLV from '@/img/logo_LV.svg';
import Modal from '../Modal';

const ObjectDetailMap = React.lazy(() => import('@/components/map/Map/ObjectDetailMap'));

class DetailPreviewModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showThumbs: true,
      selectedTab: 'gallery',
    };
  }

  componentDidUpdate(prevProps) {
    const { id, isOpen, object, fetchMyObject, fetchBrokerDetails, fetchNearbyPlaces } = this.props;

    //  Modal is opened
    if (isOpen && !prevProps.isOpen) {
      const { loading } = this.state;
      if (id !== prevProps.id && !loading) {
        this.setState({ loading: true });
        fetchMyObject(id).finally(() => {
          this.setState({ loading: false });
        });
        fetchBrokerDetails(object.getIn(['broker', 'id']));
        const lat = object.get('latitude');
        const lng = object.get('longitude');
        if (lat && lng) {
          fetchNearbyPlaces(lat, lng);
        }
      }
    }
  }

  tabChange = (value) => {
    this.setState({ selectedTab: value });
  };

  toggleThumbs = () => {
    const { showThumbs } = this.state;
    this.setState({ showThumbs: !showThumbs });
  };

  renderAdditionalServices = () => {
    const { browser, services, t } = this.props;
    return <AdditionalServices t={t} services={services} slider={browser.lessThan.large} />;
  };

  renderMap = () => {
    const { object, t, nearbyPlaces } = this.props;

    return (
      <ObjectDetailMap
        t={t}
        longitude={object.get('longitude')}
        latitude={object.get('latitude')}
        zoom={object.get('zoom') || 16}
        nearbyPlaces={nearbyPlaces}
      />
    );
  };

  renderTabSelection() {
    const { t, object, browser } = this.props;
    const { selectedTab } = this.state;
    const hasPosition = object.get('latitude') || object.get('longitude');
    const hasStreetView = object.get('streetview_params');
    const hasVirtualTour = object.get('virtual_tours');
    const hasVideo = object.get('videos') && object.get('videos').length > 0;

    const tabsStyle = classNames('detail-preview__tabs', {
      'detail-preview__tabs--desktop': browser.greaterThan.medium,
    });

    return (
      <Tabs className={tabsStyle} onChange={this.tabChange} selected={selectedTab}>
        {hasPosition || hasVirtualTour || hasStreetView || hasVideo ? (
          <Tab value="gallery" selected>
            {t('objectDetail.photos')}
          </Tab>
        ) : null}
        {hasVideo && (
          <Tab value="video" selected>
            {t('objectDetail.video')}
          </Tab>
        )}
        {hasPosition ? <Tab value="map">{t('objectDetail.map')}</Tab> : null}
        {hasVirtualTour ? <Tab value="tour">{t('objectDetail.virtualTour')}</Tab> : null}
        {hasStreetView ? <Tab value="streetView">{t('objectDetail.streetView')}</Tab> : null}
      </Tabs>
    );
  }

  renderTab(selectedTab) {
    const { t, object, images, browser } = this.props;

    //  Images
    const imgs =
      images &&
      images.get('galleryImages').map(({ url }) => {
        return {
          fullscreen: applyImageFormat(url),
          original: applyImageFormat(url, '640x2000'),
          thumbnail: applyImageFormat(url, '100x66'),
          imageSet: [
            {
              srcSet: applyImageFormat(url, '640x2000'),
              media: '(max-width: 640px)',
            },
            {
              srcSet: applyImageFormat(url, 'original'),
              media: '(min-width: 641px)',
            },
          ],
        };
      });

    //  Videos
    const videos = object && object.get('videos') && object.get('videos').map((video) => video.source);

    //  Streetview POV
    const pov = ImmutableMap(object.get('streetview_params'));

    if (object.size > 0) {
      switch (selectedTab) {
        case 'map':
          return this.renderMap();

        case 'tour':
          return <VirtualTour src={object.getIn(['virtual_tours', 0, 'source'])} />;

        case 'streetView':
          return (
            <Streetview
              position={{
                lat: object.get('latitude'),
                lng: object.get('longitude'),
              }}
              pov={pov}
            />
          );

        case 'video':
          return <AdGallery t={t} videos={videos} browser={browser} />;

        case 'gallery':
        default:
          return <AdGallery t={t} images={imgs} browser={browser} startIndex={0} />;
      }
    } else {
      return null;
    }
  }

  render() {
    const { t, object, address, broker, office, nearbyPlaces, routes, browser, isOpen, closeModal } = this.props;
    const { loading, showThumbs, selectedTab } = this.state;

    const existingOffice = office.size > 0 ? office : null;
    const descriptions = object.getIn(['descriptions', api.getLocale()]);

    const mutatedObj = object.merge({
      address,
      slogan: descriptions && descriptions.description,
      description: descriptions && descriptions.introduction,
    });

    const modalStyles = classNames('detail-preview', {
      'obj-media--thumbs-hidden': !showThumbs,
      [`obj-media--${selectedTab}`]: true,
    });

    return (
      <Modal className={modalStyles} fullScreen fromBottom isOpen={isOpen} onRequestClose={closeModal}>
        <div className="detail-preview__header header">
          <img src={PORTAL_EE ? logoEE : logoLV} alt="City24 logo" />
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="detail-preview__wrap">
              <div className="detail-preview__media">
                <div className="detail-preview__ribbon">
                  <span>{t('objectDetail.previewRibbon')}</span>
                </div>
                <div className="detail-preview__media-content">
                  {this.renderTab(selectedTab)}
                  <div className="detail-preview__media-controls">
                    {selectedTab === 'gallery' && browser.greaterThan.medium && (
                      <Button
                        className="detail-preview__media-toggle-thumbs"
                        icon={showThumbs ? 'thumbnails' : 'thumbnails-hidden'}
                        onClick={this.toggleThumbs}
                      />
                    )}
                    {browser.greaterThan.medium && this.renderTabSelection()}
                  </div>
                </div>
              </div>
              <ObjectDetailInfo
                t={t}
                preview
                object={mutatedObj}
                broker={broker}
                office={existingOffice}
                nearbyPlaces={nearbyPlaces}
              />
            </div>
            <Section large>
              <Container center>
                <div className="detail-preview__overview">
                  {browser.greaterThan.medium && (
                    <div className="detail-preview__main">
                      <Description
                        t={t}
                        maxHeight={300}
                        largeSlogan
                        showSlogan
                        slogan={mutatedObj.get('slogan')}
                        description={mutatedObj.get('description')}
                      />
                      <FullSpecs large t={t} object={mutatedObj} />
                      {broker.size > 0 && (
                        <div className="obj-overview__b-card">
                          <BusinessCard
                            t={t}
                            preview
                            broker={broker}
                            office={existingOffice}
                            routes={routes}
                            object={mutatedObj}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="detail-preview__services">{this.renderAdditionalServices()}</div>
                </div>
              </Container>
            </Section>
          </>
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    object: getMyObjectOriginal(state),
    images: getMyObjectImg(state),
    address: getAddressMap(state),
    office: getBrokerOffice(state),
    broker: getBrokerDetails(state),
    browser: getBrowser(state),
    routes: getPrefixedRoutes(state),
    services: getAdditionalServices(state),
    nearbyPlaces: getMapNearbyPlaces(state),
  };
}

const mapDispatchToProps = { fetchMyObject, fetchBrokerDetails, fetchNearbyPlaces };

export default compose(
  withTranslation('objectDetail'),
  connect(mapStateToProps, mapDispatchToProps)
)(DetailPreviewModal);
