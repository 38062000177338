import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultProps = {};

const ToggleFieldGroup = (props) => {
  const { children } = props;
  return <div className="toggle-field-group">{children}</div>;
};

ToggleFieldGroup.propTypes = propTypes;
ToggleFieldGroup.defaultProps = defaultProps;

export default ToggleFieldGroup;
