import type { AdOceanInterface, MasterConfig, SlaveConfig } from '@/types/adOcean';

class AdOcean implements AdOceanInterface {
  queue: (() => void)[];

  scriptLoaded: boolean;

  constructor() {
    this.queue = [];
    this.scriptLoaded = false;
  }

  onLoad(): void {
    this.scriptLoaded = true;
    const { ado = {} as typeof window.ado } = window;
    /* (c)AdOcean 2003-2019 */
    if (!ado?.config) {
      /* eslint-disable-next-line no-multi-assign */
      ado.config = ado.preview = ado.placement = ado.master = ado.slave = () => undefined;
    }
    ado.config({
      mode: 'new',
      xml: false,
      consent: true,
      characterEncoding: true,
      fpc: 'auto',
      defaultServer: 'ao.city24.lv',
      cookieDomain: 'SLD',
    });
    ado.preview({ enabled: true });

    this.queue.forEach((fn) => {
      fn();
    });
    this.queue = [];
  }

  master(config: MasterConfig): void {
    if (this.scriptLoaded) {
      window.ado?.master(config);
    } else {
      this.queue.push(() => this.master(config));
    }
  }

  slave(id: string, config: SlaveConfig): void {
    if (this.scriptLoaded) {
      window.ado?.slave(id, config);
    } else {
      this.queue.push(() => this.slave(id, config));
    }
  }

  refresh(masterId: string): void {
    if (this.scriptLoaded) {
      window.ado?.refresh(masterId);
    } else {
      this.queue.push(() => this.refresh(masterId));
    }
  }
}

const adOcean = new AdOcean();
export default adOcean;
