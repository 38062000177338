import React from 'react';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  rounded?: boolean;
  small?: boolean;
  green?: boolean;
  red?: boolean;
  orange?: boolean;
  className?: string;
  bg?: 'green' | 'red' | 'orange' | 'primary';
}

function Label({ children, rounded, small, green, red, orange, className, bg }: Props) {
  const labelStyle = classNames(
    'label',
    {
      'label--rounded': rounded,
      'label--small': small,
    },
    bg
      ? `label--${bg}`
      : {
          'label--green': green,
          'label--red': red,
          'label--orange': orange,
        },
    className
  );

  return <div className={labelStyle}>{children}</div>;
}

export default Label;
