import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import type { TFunction } from 'next-i18next';
import StatisticsType from '@city24/common/enums/StatisticsType';

import GA from '@/utils/ga';
import { GA_DETAIL_VIEW } from '@/constants/ga';
import { scrollToElement } from '@/utils/helpers';
import { ObjectDetailContextProps } from '@/components/object/ObjectDetailContext';
import ButtonGroup from '@/components/button/ButtonGroup';
import Button from '@/components/button/Button';

interface Props {
  mail?: string;
  phone?: string;
  full?: boolean;
  sendStatistics: ObjectDetailContextProps['sendStatistics'];
  t: TFunction;
  brokerRef: React.RefObject<HTMLDivElement>;
}

function BrokerSticky({ mail, phone, full, sendStatistics, t, brokerRef }: Props) {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const screenHeight = window.innerHeight;
      const windowTop = window.scrollY;
      const elHeight = brokerRef.current!.clientHeight;
      const rect = brokerRef.current!.getBoundingClientRect();

      const offset = rect.top + windowTop;
      const windowBottom = screenHeight + windowTop;

      // Hide sticky if element is completely visible
      if (offset + elHeight < windowBottom || windowTop < 20) {
        if (!hidden) {
          setHidden(true);
        }
      } else if (hidden) {
        setHidden(false);
      }
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const brokerStickyStyle = classNames('broker-sticky', {
    'broker-sticky--hidden': hidden || full,
  });

  return (
    <div className={brokerStickyStyle}>
      <ButtonGroup fixed>
        {phone && (
          <Button
            href={`tel:${phone}`}
            primary
            icon="phone"
            text={t('broker.call')}
            onClick={() => {
              sendStatistics(StatisticsType.AGENT_SHOW_PHONE);
              GA.event({ category: GA_DETAIL_VIEW, action: 'call_number' });
            }}
          />
        )}
        {mail && (
          <Button
            primary
            icon="envelope"
            text={t('broker.sendEmail')}
            onClick={() => {
              scrollToElement({ el: brokerRef.current! });
              GA.event({ category: GA_DETAIL_VIEW, action: 'send_message' });
            }}
          />
        )}
      </ButtonGroup>
    </div>
  );
}

export default BrokerSticky;
