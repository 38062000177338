import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from '@/utils/ga';
import { Link } from 'react-router-dom';

import Modal from '../Modal';
import FancySection from '../ModalSections/FancySection';

import SearchSavedIcon from '../../../img/icons/search-saved.svg';
import Button from '../../button/Button';
import Icon from '../../icon/Icon';
import { GA_SAVE_SEARCH } from '@/constants/ga';

const propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

const defaultProps = {
  isOpen: false,
};

const SaveSearchConfirmModal = (props) => {
  const { isOpen, closeModal, t, routes } = props;

  const gaEventFunction = (action, label) =>
    ReactGA.event({
      category: GA_SAVE_SEARCH,
      action,
      label,
    });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      gaEvent={() => gaEventFunction('close_button')}
    >
      <FancySection
        image={SearchSavedIcon}
        title={t('saveSearchConfirmModal.title')}
        subtitle={[
          t('saveSearchConfirmModal.textBeforeLink'),
          ' ',
          <Link
            to={routes.mySearches}
            className="inline-link"
            onClick={() => {
              gaEventFunction('click_mySearches');
              closeModal();
            }}
          >
            <Icon name="my-searches" />
            &nbsp;
            {t('saveSearchConfirmModal.link')}
          </Link>,
        ]}
      />
      <Button
        primary
        extended
        text={t('common.close')}
        onClick={() => {
          gaEventFunction('close_button');
          closeModal();
        }}
      />
    </Modal>
  );
};

SaveSearchConfirmModal.propTypes = propTypes;
SaveSearchConfirmModal.defaultProps = defaultProps;

export default SaveSearchConfirmModal;
