import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseNotifications from '@/components/notifications/BaseNotifications';

function Notifications(): React.ReactElement {
  const [t] = useTranslation();

  return <BaseNotifications t={t} />;
}

export default Notifications;
