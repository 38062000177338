import React from 'react';
import classNames from 'classnames';
import ImageGallery from 'react-image-gallery';
import { Gallery as ImageGridGallery } from 'react-grid-gallery';
import PropTypes from 'prop-types';
import { defaultMemoize } from 'reselect';

import { omit } from '@/utils/object';
import ObjectPlaceholder from '@/img/placeholder/object_placeholder.svg';
import Icon from '@/components/icon/Icon';
import VideoPlayer from '@/components/videoPlayer/VideoPlayer';

function renderLeftNav(onClick, disabled) {
  return (
    <button type="button" className="image-gallery-icon image-gallery-left-nav" disabled={disabled} onClick={onClick}>
      <Icon name="angle-left" />
    </button>
  );
}

function renderRightNav(onClick, disabled) {
  return (
    <button type="button" className="image-gallery-icon image-gallery-right-nav" disabled={disabled} onClick={onClick}>
      <Icon name="angle-right" />
    </button>
  );
}

class AdGallery extends React.Component {
  slider = React.createRef();

  videoSlider = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      currentVideoIndex: 0,
      playingVideoIndex: null,
      gridView: false,
    };

    this.getImages = defaultMemoize((images, browser, ad, adPosition, calculator) => {
      const galleryImages = images.map((img) => {
        if (browser.greaterThan.medium) {
          return img;
        }
        // No large image for small screens
        return omit(img, 'fullscreen');
      });

      if (ad && adPosition) {
        const adPositionIdx = adPosition === -1 ? galleryImages.length : adPosition;

        galleryImages.splice(adPositionIdx, 0, {
          renderItem: () => ad,
          originalClass: 'enable-tight-arrows',
          thumbnailClass: 'image-gallery-thumbnail--ad',
        });
      }

      if (calculator) {
        galleryImages.push({
          renderItem: () => calculator,
          originalClass: 'enable-tight-arrows',
          thumbnailClass: 'image-gallery-thumbnail--ad',
        });
      }

      return galleryImages;
    });

    this.getGridImages = defaultMemoize((images) => {
      return images.map((img, i) => {
        return {
          src: img.gridViewThumbnail,
          width: 432,
          height: 288,
          index: i,
        };
      });
    });

    this.getVideos = defaultMemoize((videos) => {
      return videos.map((url, i) => {
        return { renderItem: () => this.renderVideo(url, i), thumbnail: ObjectPlaceholder };
      });
    });
  }

  componentDidMount() {
    if (this.props.startIndex) {
      this.setState({ currentIndex: this.props.startIndex });
    }
    this.setState({ gridView: this.props.gridView });
  }

  componentDidUpdate(prevProps) {
    // Reset previously playing video
    if (prevProps.videos !== this.props.videos) {
      this.setState({ playingVideoIndex: null });
    }

    // Reset gallery index
    if (prevProps.images !== this.props.images) {
      this.setState({ currentIndex: 0 });
    }

    if (prevProps.gridView !== this.props.gridView) {
      this.setState({ gridView: this.props.gridView });
    }
  }

  openFull = (force = false) => {
    const { full, openFull } = this.props;
    if (!full || force) {
      openFull();
    }
  };

  onImageSlide = (index) => {
    const { onSlide } = this.props;
    this.setState({ currentIndex: index });
    if (onSlide) onSlide(index);
    this.gaEventHandler(`imageViews_${index}`);
  };

  onVideoSlide = (index) => {
    const { onSlide } = this.props;
    this.setState({ currentVideoIndex: index });
    if (onSlide) onSlide(index);
    this.gaEventHandler(`videoViews_${index}`);
  };

  onGridImageClick = (index) => {
    const { setGridView } = this.props;
    this.setState({ currentIndex: index });
    setGridView(false);
    this.openFull(true);
  };

  gaEventHandler(action) {
    const { gaEvent } = this.props;

    if (gaEvent) {
      gaEvent(action);
    }
  }

  renderVideo(video, i) {
    const { playingVideoIndex } = this.state;

    return (
      <VideoPlayer
        key={video}
        url={video}
        width="100%"
        height="100%"
        controls
        config={{ youtube: { playerVars: { playsinline: 0 } } }}
        playing={i === playingVideoIndex}
        onPlay={() => this.setState({ playingVideoIndex: i })}
      />
    );
  }

  render() {
    const { t, images, videos, browser, ad, adPosition, showThumbs, tags, calculator } = this.props;
    const { currentIndex, currentVideoIndex, gridView } = this.state;

    const galleryImages = gridView ? [] : this.getImages(images, browser, ad, adPosition, calculator);
    const gridImages = gridView ? this.getGridImages(images) : [];
    const galleryVideos = this.getVideos(videos);

    const placeholderImage = ObjectPlaceholder.src ?? ObjectPlaceholder;
    return (
      <>
        {gridView && gridImages.length > 0 && (
          <div className="grid-container">
            <div className={browser.lessThan.medium ? 'grid-inner-container-mobile' : 'grid-inner-container'}>
              <ImageGridGallery
                images={gridImages}
                onClick={this.onGridImageClick}
                thumbnailStyle={({ item }) => {
                  return {
                    'border-radius': '15px',
                    border: item.index === currentIndex ? '4px solid orange' : 'none',
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                  };
                }}
                rowHeight={browser.lessThan.medium ? 140 : 190}
                margin={browser.lessThan.medium ? 4 : 10}
                enableImageSelection={false}
              />
            </div>
          </div>
        )}
        {!gridView && galleryImages.length > 0 && (
          <>
            {tags.length && currentIndex + 1 !== galleryImages.length ? (
              <div className="obj-media__content__tags">
                {tags.includes('projectOfTheMonth') && (
                  <span key="special" className="object__tags-special">
                    {t('objectSpec.projectOfTheMonth')}
                  </span>
                )}
                {tags.includes('comingSoon') && (
                  <span key="comingSoon" className="object__tags-cs">
                    {t('objectSpec.comingSoon')}
                  </span>
                )}
              </div>
            ) : null}
            <ImageGallery
              lazyLoad
              items={galleryImages}
              additionalClass={classNames('image-gallery--object', {
                'tight-arrows': galleryImages[currentIndex]?.originalClass?.includes('enable-tight-arrows'),
              })}
              slideDuration={browser.greaterThan.medium ? 0 : 250}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={browser.greaterThan.medium && showThumbs}
              showNav={browser.greaterThan.small && !gridView}
              renderLeftNav={renderLeftNav}
              renderRightNav={renderRightNav}
              defaultImage={placeholderImage}
              onErrorImageURL={placeholderImage}
              onSlide={this.onImageSlide}
              showIndex={browser.lessThan.large}
              startIndex={currentIndex}
              onClick={browser.greaterThan.small ? undefined : this.openFull}
            />
          </>
        )}
        {galleryVideos.length > 0 && (
          <ImageGallery
            ref={this.videoSlider}
            lazyLoad
            items={galleryVideos}
            additionalClass="image-gallery--object tight-arrows"
            slideDuration={browser.greaterThan.medium ? 0 : 250}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={false}
            showNav={browser.greaterThan.medium}
            defaultImage={placeholderImage}
            onErrorImageURL={placeholderImage}
            onSlide={this.onVideoSlide}
            onClick={this.openFull}
          />
        )}
        {galleryVideos.length > 1 && browser.lessThan.large && (
          <div className="video-nav">
            <button
              disabled={currentVideoIndex === 0}
              type="button"
              onClick={() => {
                this.videoSlider.current.slideToIndex(currentVideoIndex + 1);
                this.setState({ playingVideoIndex: null });
              }}
            >
              <Icon name="playback-prev" />
              {t('common.previous')}
            </button>
            <div className="video-nav__count">
              {currentVideoIndex + 1}/{videos.length}
            </div>
            <button
              disabled={currentVideoIndex + 1 === videos.length}
              type="button"
              onClick={() => {
                this.videoSlider.current.slideToIndex(currentVideoIndex - 1);
                this.setState({ playingVideoIndex: null });
              }}
            >
              {t('common.next')}
              <Icon name="playback-next" />
            </button>
          </div>
        )}
      </>
    );
  }
}

AdGallery.propTypes = {
  startIndex: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.string,
      originalAlt: PropTypes.string,
      thumbnail: PropTypes.string,
      thumbnailAlt: PropTypes.string,
    })
  ),
  videos: PropTypes.arrayOf(PropTypes.string),
  onSlide: PropTypes.func,
  ad: PropTypes.node,
  adPosition: PropTypes.number,
  calculator: PropTypes.node,
  gaEvent: PropTypes.func,
  gridView: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
};

AdGallery.defaultProps = {
  startIndex: 0,
  images: [],
  videos: [],
  ad: null,
  adPosition: -1,
  calculator: null,
  gaEvent: null,
  onSlide: undefined,
  gridView: null,
  tags: [],
};

export default AdGallery;
