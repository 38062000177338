/* eslint-disable max-classes-per-file */
export class HttpError extends Error {
  name = 'HttpError';

  context: { [key: string]: any };

  body: { [key: string]: any };

  constructor(message = '', context: { [key: string]: any } = {}, body: { [key: string]: any } = {}) {
    super(message);
    this.context = context;
    this.body = body;
  }
}

export class BadRequestError extends HttpError {
  name = 'BadRequestError';
}
export class UnauthenticatedError extends HttpError {
  name = 'UnauthenticatedError';
}
export class UnauthorizedError extends HttpError {
  name = 'UnauthorizedError';
}
export class NotFoundError extends HttpError {
  name = 'NotFoundError';
}
export class GoneError extends HttpError {
  name = 'GoneError';
}
export class TooManyRequestsError extends HttpError {
  name = 'TooManyRequestsError';
}

export function httpErrorfromResponse(res: Response, context: { [key: string]: any } = {}): Promise<HttpError> {
  return res.json().then((json) => {
    const { detail } = json;
    if (res.status === 400) {
      return new BadRequestError(detail, context, json);
    }
    if (res.status === 401) {
      return new UnauthenticatedError(detail, context, json);
    }
    if (res.status === 403) {
      throw new UnauthorizedError(detail, context, json);
    }
    if (res.status === 404) {
      return new NotFoundError('Not found', context, json);
    }
    if (res.status === 410) {
      return new GoneError('Gone', context, json);
    }
    if (res.status === 429) {
      return new TooManyRequestsError(detail, context, json);
    }
    return new HttpError(detail, context, json);
  });
}
