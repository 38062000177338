import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import {
  TransactionTypeLiteral,
  getTransactionTypeLiteral,
  parseTransactionTypeFromIriString,
} from '@city24/common/enums/realty/TransactionType';
import SizeUnit, { getSizeUnitString } from '@city24/common/enums/realty/SizeUnit';
import {
  RealtyCommonProperties,
  ProjectAndModularHouseCommonProperties,
  AddressNamesProperties,
} from '@city24/common/types/Realty';
import { getTypeCombinationString, getUrlFormatLocation } from '@city24/common/utils/seo';
import { ROUTES } from '@/constants/routes';
import { OBJECT_TRANSACTION_STRINGS, OBJECT_TRANSACTION_STRINGS_SINGULAR } from '@/constants/filters';
import { getObjectDetailRoute, getRouteByObjectType } from '@/utils/route';
import { ObjectDetailsState } from '@/components/object/types';

export function objectTransactionString(
  unitType: UnitTypeLiteral,
  transactionType: TransactionTypeLiteral,
  singularForm = false
) {
  const tokens = singularForm ? OBJECT_TRANSACTION_STRINGS_SINGULAR : OBJECT_TRANSACTION_STRINGS;
  const isSaleType = transactionType === 'sale';
  switch (unitType) {
    case UnitTypeLiteral.APARTMENT:
      return isSaleType ? tokens.apartmentsForSale : tokens.apartmentsForRent;
    case UnitTypeLiteral.HOUSE:
      return isSaleType ? tokens.housesForSale : tokens.housesForRent;
    case UnitTypeLiteral.HOUSE_SHARE:
      return isSaleType ? tokens.houseSharesForSale : tokens.houseSharesForRent;
    case UnitTypeLiteral.COMMERCIAL:
      return isSaleType ? tokens.commercialsForSale : tokens.commercialsForRent;
    case UnitTypeLiteral.COTTAGE_OR_VILLA:
      return isSaleType ? tokens.summerCottagesForSale : tokens.summerCottagesForRent;
    case UnitTypeLiteral.LAND:
      return isSaleType ? tokens.landsForSale : tokens.landsForRent;
    case UnitTypeLiteral.GARAGE:
      return isSaleType ? tokens.garagesForSale : tokens.garagesForRent;
    case UnitTypeLiteral.NEW_PROJECT:
      return isSaleType ? tokens.newProjectsForSale : tokens.newProjectsForRent;
    case UnitTypeLiteral.MODULAR_HOUSE:
      return isSaleType ? tokens.modularHousesForSale : tokens.modularHousesForRent;
    default:
      return '';
  }
}
export function getPricePerUnitType(
  unitType: UnitTypeLiteral,
  sizeUnitId: SizeUnit | null = null,
  lotSizeUnitId: SizeUnit | null = null
) {
  return getSizeUnitString(
    unitType === UnitTypeLiteral.LAND ? lotSizeUnitId || SizeUnit.Hectare : sizeUnitId || SizeUnit.SquareMeter
  );
}

export function showSlogan(object: ObjectDetailsState) {
  if ((object.ad_level ?? 0) > 0) {
    return true;
  }
  if (PORTAL_LV) {
    return (
      object.broker?.office?.some((office) => {
        const companyProducts = office.company.classified_products;
        const hasLevel = (cp: (typeof companyProducts)[number]) =>
          cp.product_level === 'GOLD' || cp.product_level === 'PRO';

        return companyProducts.some(hasLevel) || office.classified_products.some(hasLevel);
      }) ?? false
    );
  }

  return false;
}

export function getPrimaryPrice(object: RealtyCommonProperties | ProjectAndModularHouseCommonProperties) {
  return (object as ProjectAndModularHouseCommonProperties).price_min || (object as RealtyCommonProperties).price;
}

export function createObjectRoute(
  obj: Pick<RealtyCommonProperties, 'unit_type' | 'friendly_id' | 'transaction_type'> & {
    address: AddressNamesProperties;
  }
) {
  return getObjectDetailRoute(
    getRouteByObjectType(ROUTES, obj.unit_type),
    obj.friendly_id,
    getTypeCombinationString(
      getTransactionTypeLiteral(parseTransactionTypeFromIriString(obj.transaction_type)),
      obj.unit_type,
      'url'
    ),
    getUrlFormatLocation(obj.address)
  );
}
