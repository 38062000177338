import { SET_PASSWORD_RESET_MODAL, SET_PASSWORD_RESET_ERROR } from '@/constants/actions';
import api from '@/api/City24Api';
import { AppDispatch } from '@/store';

export function resetPassword(email: string, returnPath: string) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_PASSWORD_RESET_ERROR,
      hasError: false,
    });

    api.user
      .passwordReset({
        email,
        locale: api.getLocale(),
        portal: PORTAL,
        confirm_path: returnPath,
      })
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: SET_PASSWORD_RESET_MODAL,
            passwordResetModalOpened: true,
            confirmed: true,
          });
        } else {
          dispatch({
            type: SET_PASSWORD_RESET_ERROR,
            hasError: true,
          });
        }
      });
  };
}
