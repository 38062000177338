import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Toggle from './Toggle';

const propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  getValue: PropTypes.func,
  gaEvent: PropTypes.func,
};

const defaultProps = {
  id: '',
  value: '',
  checked: false,
  getValue: null,
  gaEvent: null,
};

class ToggleField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  handleChange = (checked, value) => {
    const { getValue } = this.props;
    this.gaEventHandler(checked);
    getValue(checked, value);
  };

  hover = (value) => {
    this.setState({ hover: value });
  };

  gaEventHandler = (checked) => {
    const { gaEvent } = this.props;
    if (gaEvent) {
      gaEvent(checked);
    }
  };

  render() {
    const { checked, value, id, title, subtitle, className } = this.props;
    const { hover } = this.state;

    const toggleStyle = classNames('toggle-field', className);
    return (
      <div className={toggleStyle}>
        <label
          htmlFor={id}
          className="toggle-field__wrap"
          onMouseEnter={() => this.hover(true)}
          onMouseLeave={() => this.hover(false)}
        >
          <span className="toggle-field__content">
            <span className="toggle-field__title">{title}</span>
            {subtitle && <span className="toggle-field__subtitle">{subtitle}</span>}
          </span>
          <Toggle id={id} value={value} handleChange={this.handleChange} checked={checked} hover={hover} />
        </label>
      </div>
    );
  }
}

ToggleField.propTypes = propTypes;
ToggleField.defaultProps = defaultProps;

export default ToggleField;
