import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';

import { parseQuickSearchDate } from '@/utils/search';
import { objectTransactionString, formatMinMaxPrice, formatDate } from '@/utils/formatting';
import Label from '@/components/label/Label';
import Icon from '@/components/icon/Icon';
import AppContext from '@/components/appContext';

function composeSearchName(lastSearch, t) {
  const filters = lastSearch.get('filters');
  const objectType = filters.getIn(['objectType', 'value']);
  const transactionType = filters.getIn(['transactionType', 'value']);
  return t(objectTransactionString(objectType, transactionType));
}

function composeSearchFilters(lastSearch) {
  const filterList = [];
  const filters = lastSearch.get('filters');

  const minPrice = Number(filters.get('minPrice'));
  const maxPrice = Number(filters.get('maxPrice'));

  if (minPrice || maxPrice) {
    filterList.push(<span key="priceRange">{formatMinMaxPrice(minPrice, maxPrice)}</span>);
  }

  const minSize = filters.get('minSize');
  const maxSize = filters.get('maxSize');

  if (minSize || maxSize) {
    filterList.push(<span key="sizeRange">{`${minSize || '...'} - ${maxSize || '...'} m²`}</span>);
  }

  const searchTerm = filters.get('searchTerm');
  if (searchTerm?.size) {
    const names = Array.from(searchTerm.values()).map((n) => n.name);
    filterList.push(
      <span key="keywords">{names.length > 6 ? `${names.slice(0, 6).join(', ')}...` : names.join(', ')}</span>
    );
  }

  return filterList;
}

function getDate(lastSearch, t) {
  const dateString = lastSearch.get('date');

  let dateText = '';

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const searchDate = formatDate(parseQuickSearchDate(dateString));
  if (formatDate(today) === searchDate) {
    dateText = t('common.today');
  } else if (formatDate(yesterday) === searchDate) {
    dateText = t('common.yesterday');
  } else {
    dateText = searchDate;
  }

  return dateText;
}

function QuickLastSearch({ linkTo, lastSearch, onClick, className, t }) {
  const { Link } = useContext(AppContext);
  const newAdsCount = lastSearch.get('new_ads_count');
  return (
    <Link href={linkTo} className={className} onClick={onClick}>
      <div className="h4 quick-search__title">
        {t('quickSearch.lastSearch')}
        <span className="quick-search__date">{getDate(lastSearch, t)}</span>
      </div>
      <div className="quick-search__wrap">
        {newAdsCount > 0 && (
          <Label rounded orange className="quick-search__new-count">
            {`+${newAdsCount}`}
          </Label>
        )}
        <div className="quick-search__content">
          <div className="quick-search__name">{composeSearchName(lastSearch, t)}</div>
          <div className="quick-search__filters">{composeSearchFilters(lastSearch)}</div>
          <div />
        </div>
        <Icon name="angle-right" />
      </div>
    </Link>
  );
}

QuickLastSearch.propTypes = {
  linkTo: PropTypes.string.isRequired,
  lastSearch: PropTypes.instanceOf(Collection.Keyed).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
QuickLastSearch.defaultProps = {
  onClick: () => {},
};

export default QuickLastSearch;
