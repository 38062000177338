import React, { useContext } from 'react';
import ReactGA from '@/utils/ga';
import type { TFunction } from 'next-i18next';

import Language, { ISO639_2 } from '@city24/common/enums/Language';
import { GA_HEADER_MENU } from '@/constants/ga';
import ActionList from '@/components/actionList/ActionList';
import MenuSelect, { MenuSelectContext } from './MenuSelect';
import { NameValue } from '@/types/collections';

export interface LanguageSwitcherProps {
  language: Language;
  renderLink: (lang: Language, children: React.ReactNode, className?: string) => React.ReactNode;
  options: NameValue<Language>[];
  t: TFunction;
}

function LanguageSwitcher({ language, renderLink, options, t }: LanguageSwitcherProps): React.ReactElement {
  const { closeDropdown } = useContext(MenuSelectContext);
  return (
    <MenuSelect
      t={t}
      title={ISO639_2[language]}
      gaEvent={() =>
        ReactGA.event({
          category: GA_HEADER_MENU,
          action: 'select_language',
          label: 'open_dropdown',
        })
      }
    >
      <ActionList>
        {options.map(({ value, name }) => (
          <ActionList.Item
            key={value}
            onClick={() => {
              ReactGA.event({
                category: GA_HEADER_MENU,
                action: 'select_language',
                label: value,
              });
              closeDropdown();
            }}
            renderLink={(className, children) => renderLink(value, children, className)}
          >
            {name}
          </ActionList.Item>
        ))}
      </ActionList>
    </MenuSelect>
  );
}

export default LanguageSwitcher;
