import React from 'react';
import classNames from 'classnames';
import Overlay from '@/components/common/Overlay';

interface Props {
  children: React.ReactNode;
  opened?: boolean;
  closeDropdown?: () => void;
  overlay?: boolean;
  extended?: boolean;
  floatRight?: boolean;
  className?: string;
}

function Dropdown({ children, opened, closeDropdown, overlay, extended, floatRight, className }: Props) {
  const dropdownStyle = classNames(
    'dropdown',
    {
      'dropdown--opened': opened,
      'dropdown--extended': extended,
      'dropdown--float-right': floatRight,
    },
    className
  );

  return (
    <>
      {opened && overlay ? <Overlay onClick={closeDropdown} /> : null}
      <div className={dropdownStyle}>{children}</div>
    </>
  );
}

export default Dropdown;
