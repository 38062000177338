import StatisticsType from '@city24/common/enums/StatisticsType';
import { addItemStatistics, addStatistics } from '@city24/common/api/city24/statistics';

export function sendStatistics(action: StatisticsType, guid: string) {
  addStatistics({
    counter_type: action,
    unit_guid: guid,
    portal: PORTAL,
  });
}

export function sendItemsCounterStatistics(action: StatisticsType, guid: string) {
  addItemStatistics({
    item_type: action,
    item_guid: guid,
    portal: PORTAL,
  });
}
