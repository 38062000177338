import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import FetchBackend from 'i18next-fetch-backend';

import api, { apiFetch } from './api/City24Api';
import { defaultLocale } from '@city24/common/enums/Language';

i18n.use(initReactI18next).use(FetchBackend);

i18n.on('languageChanged', (locale) => {
  api.changeLocale(defaultLocale[locale as keyof typeof defaultLocale] || locale);
  document.documentElement.setAttribute('lang', locale);
});

export function i18nInit(lng: string): void {
  i18n.init({
    debug: process.env.DEBUG === '1',
    lng,
    fallbackLng: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
    load: 'currentOnly',
    ns: 'common',
    defaultNS: 'common',
    fallbackNS: 'common',
    keySeparator: false,
    interpolation: {
      prefix: '%',
      suffix: '%',
    },
    // appendNamespaceToMissingKey: true,
    backend: {
      loadPath: '/language/%lng%/%ns%/tokens',
      fetch(url: string, options: any, callback: (res: Response) => Promise<any>) {
        return apiFetch(url.replace('-', '_'), {}, options).then(callback);
      },
    },
    // Pluralization rules changed in v4 but we're using the old rules for now
    // https://www.i18next.com/misc/json-format#i18next-json-v3
    compatibilityJSON: 'v3',
    react: {
      useSuspense: true,
    },
  });
  i18n.addResourceBundle('en', 'local', {
    'serverMaintenance.title':
      'We would like to inform you that the system improvement work is currently underway and the operation of the %portal% page has been disrupted!',
    'serverMaintenance.subtitle':
      'If the page is still not available in the near future or the page malfunctions again, please report it to <a href="mailto:%email%">%email%</a>',
  });
  i18n.addResourceBundle('et', 'local', {
    'serverMaintenance.title':
      'Anname teada, et hetkel käivad süsteemi parendustööd ning %portal% lehe töö on häiritud!',
    'serverMaintenance.subtitle':
      'Kui leht ei ole ikka veel lähiajal saadaval või lehel esineb uuesti tõrkeid, siis andke sellest teada aadressil <a href="mailto:%email%">%email%</a>',
  });
  i18n.addResourceBundle('lv', 'local', {
    'serverMaintenance.title':
      'Informējam, ka šobrīd notiek sistēmas uzlabošanas darbi un %portal% lapas darbība ir traucēta!',
    'serverMaintenance.subtitle':
      'Gadījumā, ja lapa joprojām nav pieejama tuvākajā laikā vai arī lapas darbības traucējumi atkārtojas, lūdzu ziņo <a href="mailto:%email%">%email%</a>',
  });
  i18n.addResourceBundle('ru', 'local', {
    'serverMaintenance.title':
      'Сообщаем вам, что в настоящее время ведутся работы по улучшению системы и работа страницы %portal% нарушена!',
    'serverMaintenance.subtitle':
      'Если страница будет по-прежнему недоступна в ближайшее время или работать со сбоями, то просим сообщить об этом на <a href="mailto:%email%">%email%</a>',
  });
}

export default i18n;
