import { isImmutable } from 'immutable';
import { favouriteTypeFromUnitType } from '@city24/common/enums/realty/FavouriteType';
import { OBJECT_TYPES, SIZE_UNIT_VALUES } from '@/constants/object';
import { SEO_GLOBAL_TITLE_WITH_SLOGAN } from '@/constants/seo';
import SEO from '@/constants/seo.json';

import { AsyncDataStatus } from '@/types/collections';
import { convertIRI } from '@/api/City24Api';
import { formatPrice, formatPriceRange, sanitizeWhitespaces } from '@/utils/formatting';
import { Favourite } from './collections';
import { isAddressTerm } from './searchTerms';
import { accessNested } from './object';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';

export const identity = (a) => a;
export const noop = () => {};
export const uniqueElements = (value, index, self) => self.indexOf(value) === index;

/** @deprecated convert to standard JS */
export function makeFavourite(id, objectType) {
  return Favourite({ id, type: favouriteTypeFromUnitType(objectType) });
}

export function combineLocationStringFromAddress(address) {
  if (!address) return null;

  const separator = ', ';
  const county = address.get('county_name');
  const parish = address.get('parish_name');
  const district = address.get('district_name');
  const city = address.get('city_name');
  const street = address.get('street_name');
  const houseNumber = address.get('house_number') ?? '';

  if (PORTAL_EE) {
    // level 4
    if (street) {
      return [parish, city, district, houseNumber ? `${street} ${houseNumber}` : street]
        .filter(identity)
        .join(separator);
    }
    // level 3
    if (district || city) {
      return `${city || parish}${separator}${district || city}`;
    }
    // level 2
    if (city || parish) {
      return `${city || parish}`;
    }
  }

  if (PORTAL_LV) {
    if (street) {
      return [county, parish, city, houseNumber ? `${street} ${houseNumber}` : street].filter(identity).join(separator);
    }
    if (city || parish) {
      return [county, parish, city].filter(identity).join(separator);
    }
  }

  // level 1
  if (county) {
    return `L1 ${county}`;
  }

  return null;
}

export function combineLocationString(terms, separator = ', ', nameOnly = false) {
  const names = [];
  terms.forEach((term) => {
    if (!isAddressTerm(term.value)) {
      return;
    }

    const {
      name,
      extra: { type, secondaryName },
    } = term;
    if (nameOnly) {
      names.push(name);
      return;
    }
    const county = accessNested(term.extra, 'county', 'name');
    const parish = accessNested(term.extra, 'parish', 'name');
    const city = accessNested(term.extra, 'city', 'name');

    let combinedName = [];
    if (PORTAL_LV) {
      switch (type) {
        case 'street':
          combinedName = [city || county, name];
          break;
        case 'city':
        case 'parish':
          combinedName = [county || city, name];
          break;
        default:
          names.push(name);
          return;
      }
    }

    if (PORTAL_EE) {
      switch (type) {
        case 'street':
          if (parish || city) {
            combinedName = [parish || city, name];
            break;
          }
          names.push(name);
          return;
        case 'city':
        case 'district':
          if (secondaryName || city || parish) {
            combinedName = [secondaryName || parish || city, name];
            break;
          }
          names.push(name);
          return;
        default:
          names.push(name);
          return;
      }
    }
    names.push(combinedName.filter(identity).join(separator));
  });
  return names;
}

export function scrollToElement({ el, offset = 50, ifTopHidden = false }) {
  const elTop = el.getBoundingClientRect().top;
  const top = elTop + window.scrollY;

  if (ifTopHidden) {
    if (elTop < 0) {
      window.scrollTo(0, top - offset);
    }
  } else {
    window.scrollTo(0, top - offset);
  }
}

export function camelCase(string) {
  if (!string) {
    return '';
  }
  return string
    .replace(/-/g, ' ')
    .replace(/\s(.)/g, ($1) => $1.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, ($1) => $1.toLowerCase());
}

export function stripHTML(html) {
  if (typeof window === 'undefined') {
    // In non-browser contexts, use regex
    return html.replace(/<(.|\n)*?>/g, '');
  }
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export function removeSpecialCharacters(string) {
  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w-]+/g, '');
}

export function addFavouriteToObject(favourites, object) {
  if (object) {
    if (isImmutable(object)) {
      return object.set('favourite', favourites.get(makeFavourite(object.get('guid'), object.get('unit_type'))));
    }

    object.favourite = favourites.get(makeFavourite(object.guid, object.unit_type));
    return object;
  }
  return null;
}

export function sortCompare(_a, _b, order) {
  const a = _a ? Number(_a) : null;
  const b = _b ? Number(_b) : null;

  if (order === 'desc') {
    return (b != null ? b : -Infinity) - (a != null ? a : -Infinity);
  }
  return (a != null ? a : Infinity) - (b != null ? b : Infinity);
}

export function isOrIncludes(arrayOrItem, value) {
  if (Array.isArray(arrayOrItem)) {
    return arrayOrItem.includes(value);
  }
  return arrayOrItem === value;
}

export function linkUrl(url) {
  if (!url) return url;

  if (RegExp(/^https?:\/\//).test(url)) {
    return url;
  }

  return `https://${url}`;
}

export function getParamsFromURL(url) {
  const split = url.split('/');
  return split.reduce((obj, item) => {
    const s = item.split('=');
    if (s[1] && s[1] !== 'undefined') {
      obj[s[0]] = s[1];
    }
    return obj;
  }, {});
}

export function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export function calculatePackagesTotalPrice(period, packages) {
  let initialSum = 0;
  if (period) {
    initialSum += period.get('price');
    if (period.getIn(['kvExportService', 'selected'])) {
      initialSum += period.getIn(['kvExportService', 'price']);
    }
  }

  return packages.reduce((sum, item) => {
    return sum + (item.get('price') || 0);
  }, initialSum);
}

/** @deprecated moved to ./realty/metadata.ts */
export function getObjectTitleFromMeta(metaData, t) {
  const titleSections = [];
  if (metaData.transactionType && metaData.unitType) {
    if (metaData.unitType === OBJECT_TYPES.NewProject) {
      if (metaData.types) {
        titleSections.push(metaData.types);
      }
      if (metaData.projectName) {
        titleSections.push(metaData.projectName);
      }
      titleSections.push(metaData.newProjectTypeName);
    } else if (metaData.projectName) {
      titleSections.push(metaData.typeCombinationSingular);
      titleSections.push(metaData.projectName);
      titleSections.push(metaData.newProjectTypeName);
    } else if (metaData?.purposeOfUse && metaData.purposeOfUse.length) {
      titleSections.push(
        metaData.purposeOfUse
          .map((i) =>
            t(`${metaData.unitType === OBJECT_TYPES.Land ? 'PURPOSE_OF_USE_LAND' : 'PURPOSE_OF_USE_COMMERCIAL'}.${i}`)
          )
          .join(', ')
      );
    } else {
      titleSections.push(metaData.typeCombinationSingular);
    }
  }
  titleSections.push(metaData.address);
  titleSections.push(metaData.specs);
  titleSections.push(metaData.slogan);
  return titleSections.filter(identity).join(' - ');
}

/** @deprecated moved to ./realty/metadata.ts */
export function getMetaDataFromObject(object, lang, t) {
  const { SEO_NP_UNIT_TYPE, SEO_TYPE_COMBINATIONS } = SEO;
  const metaData = {};

  if (object.get('transaction_type') && object.get('transaction_type').includes('transaction_type')) {
    metaData.transactionType = convertIRI(object.get('transaction_type'));
  } else {
    metaData.transactionType = object.get('transaction_type');
  }
  metaData.unitType = object.get('unit_type');

  if (metaData.transactionType && metaData.unitType) {
    if (metaData.unitType === OBJECT_TYPES.ModularHouse) {
      metaData.projectName = object.get('project_name') ?? null;
      metaData.typeCombinationSingular =
        SEO_TYPE_COMBINATIONS[lang][metaData.transactionType]?.[metaData.unitType]?.nameSingular;
    } else if (metaData.unitType === OBJECT_TYPES.NewProject) {
      const realtiesProxy = object.get('realties');
      if (realtiesProxy && realtiesProxy.status === AsyncDataStatus.Loaded && realtiesProxy.data.length) {
        metaData.types = realtiesProxy.data
          .map((obj) => obj.get('unit_type'))
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((type) => SEO_TYPE_COMBINATIONS[lang][metaData.transactionType]?.[type]?.name)
          .join(', ');
      }
      metaData.projectName = object.get('project_name') ?? null;
      metaData.newProjectTypeName = SEO_NP_UNIT_TYPE[lang];
    } else if (object.getIn(['project', 'project_name'])) {
      metaData.typeCombinationSingular =
        SEO_TYPE_COMBINATIONS[lang][metaData.transactionType]?.[metaData.unitType]?.nameSingular;
      metaData.projectName = object.getIn(['project', 'project_name']);
      metaData.newProjectTypeName = SEO_NP_UNIT_TYPE[lang];
    } else {
      metaData.typeCombinationSingular =
        SEO_TYPE_COMBINATIONS[lang][metaData.transactionType]?.[metaData.unitType]?.nameSingular;
      if (metaData.unitType === OBJECT_TYPES.Land || metaData.unitType === OBJECT_TYPES.Commercial) {
        metaData.purposeOfUse = object
          .getIn([
            'attributes',
            metaData.unitType === OBJECT_TYPES.Commercial ? 'PURPOSE_OF_USE_COMMERCIAL' : 'PURPOSE_OF_USE_LAND',
          ])
          ?.toArray();
      }
    }
  }
  metaData.address = combineLocationStringFromAddress(object.get('address'));
  metaData.slogan = SEO_GLOBAL_TITLE_WITH_SLOGAN[lang];
  metaData.specs = [
    object.get('property_size')
      ? `${object.get('property_size')} ${SIZE_UNIT_VALUES[object.get('size_unit_id')]}`
      : null,
    object.get('size_min') && object.get('size_max')
      ? `${object.get('size_min')}${SIZE_UNIT_VALUES[object.get('size_unit_id')]} - ${object.get('size_max')}${
          SIZE_UNIT_VALUES[object.get('size_unit_id')]
        }`
      : null,
    object.get('room_count') ? t('objectSpec.roomsCount', { count: object.get('room_count') }) : null,
    object.get('price') ? sanitizeWhitespaces(formatPrice(object.get('price'))) : null,
    object.get('price_min') && object.get('price_max')
      ? sanitizeWhitespaces(formatPriceRange(object.get('price_min'), object.get('price_max')))
      : null,
  ]
    .filter(identity)
    .join(', ');
  return metaData;
}

export function getMultibankCalculatorUnitType(object) {
  const unitType = isImmutable(object) ? object.get('unit_type') : object.unit_type;

  if (
    PORTAL_LV &&
    ((isImmutable(object) && object.get('project')) || object.project) &&
    unitType !== UnitTypeLiteral.LAND
  ) {
    return OBJECT_TYPES.NewProject;
  }

  return unitType;
}

export function redirectTo404(type = '') {
  window.location.href = `${window.location.origin}/404?t=${type}`;
}
/**
 *
 * @param {Map<string, any>} map1
 * @param {Map<string, any>} map2
 * @returns {boolean}
 */
export function keysAreEqual(map1, map2) {
  if (map1.size !== map2.size) {
    return false;
  }

  let isEqual = true;

  map1.forEach((value, key) => {
    if (!map2.has(key)) {
      isEqual = false;
    }
  });

  return isEqual;
}
