import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'next-i18next';
import ReactGA from '@/utils/ga';
import { useAppSelector } from '@/hooks';
import { MultiBankCalculatorItem } from '@/types/finance';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import {
  MULTI_BANK_HOME_LOAN_CALCULATOR,
  MULTI_BANK_PERIOD_LAND_OPTIONS_YEARS,
  MULTI_BANK_PERIOD_OPTIONS_YEARS,
  SELF_FINANCE_UNIT_OPTIONS,
} from '@/constants/finance';
import { OBJECT_TYPES } from '@/constants/object';
import { GA_MULTI_BANK_CALCULATOR } from '@/constants/ga';
import { getLanguage } from '@/selectors/appSelectors';
import { getEuriborRate, getMultiBankCalcOrder } from '@/selectors/calculatorsSelectors';
import { computeLoanSale } from '@/components/SEBCalculator/SEBCalculatorFunctions';
import { getSwedbankCalculatorCid } from '@/utils/integrations';
import MultiBankCalculatorContext from '@/components/finance/Calculators/MultiBankCalculator/context';

import Tab from '@/components/tabs/Tab';
import Tabs from '@/components/tabs/Tabs';
import Image from '@/components/img/Image';
import ExtendedLoanCalculator from '@/components/finance/Calculators/MultiBankCalculator/ExtendedLoanCalculator';
import CompactLoanCalculator from '@/components/finance/Calculators/MultiBankCalculator/CompactLoanCalculator';
import sebLogo from '@/img/calculators/seb.svg';
import bigBankLogo from '@/img/calculators/bigbank.svg';
import luminorLogo from '@/img/calculators/luminor.svg';
import swedbankLogo from '@/img/calculators/swedbank.svg';
import coopLogo from '@/img/calculators/coop.svg';
import lhvLogo from '@/img/calculators/lhv.svg';
import citadeleLogo from '@/img/calculators/citadele.svg';

interface Props {
  t: TFunction;
  objectPrice: number | string;
  unitType: UnitTypeLiteral;
  selectedBank?: string | null;
  compact?: boolean;
  compactWide?: boolean;
  placementName: string;
}

const MultiBankCalculator = ({
  t,
  objectPrice,
  unitType,
  selectedBank = null,
  compact = false,
  compactWide = false,
  placementName,
}: Props): React.ReactElement => {
  const lang = useAppSelector(getLanguage);
  const euriborRate = useAppSelector(getEuriborRate);
  const bankOrder = useAppSelector(getMultiBankCalcOrder);
  const { yearBuilt } = useContext(MultiBankCalculatorContext);
  const filteredBanks = useMemo(
    () =>
      bankOrder
        .map((name: string) => MULTI_BANK_HOME_LOAN_CALCULATOR.find((item) => item.name === name))
        .filter((item: MultiBankCalculatorItem) => unitType in item.defaults)
        .filter((item: MultiBankCalculatorItem) => {
          // handle maximumLoanAmount
          const defaults = item.defaults[unitType];
          if (!defaults.maximumLoanAmount) {
            return true;
          }
          return (
            Number(objectPrice) - (Number(objectPrice) * defaults.selfFinancing) / 100 <= defaults.maximumLoanAmount
          );
        })
        .map((item: MultiBankCalculatorItem) => {
          /** Custom defaults overrides */
          if (item.name === 'swedbank' && [UnitTypeLiteral.APARTMENT, UnitTypeLiteral.HOUSE].includes(unitType)) {
            if (yearBuilt && yearBuilt >= 2000) {
              item.defaults[unitType] = {
                ...item.defaults[unitType],
                fixedInterestRate: item.defaults[unitType].fixedInterestOverYear2000,
              };
            } else {
              item.defaults[unitType] = {
                ...item.defaults[unitType],
                fixedInterestRate: item.defaults[unitType].fixedInterestDefault,
              };
            }
          }

          return item;
        }),
    [unitType, bankOrder, objectPrice, yearBuilt]
  );
  const [activeBank, setActiveBank] = useState(
    selectedBank ? filteredBanks.find((item: MultiBankCalculatorItem) => item.name === selectedBank) : filteredBanks[0]
  );
  const defaults = activeBank.defaults[unitType];
  const periodOptions =
    unitType === OBJECT_TYPES.Land ? MULTI_BANK_PERIOD_LAND_OPTIONS_YEARS : MULTI_BANK_PERIOD_OPTIONS_YEARS;

  const [touchedFields, setTouchedFields] = useState({
    price: false,
    period: false,
    interest: false,
    selfFinancing: false,
    selfFinancingUnit: false,
  });

  const [calcState, setCalcState] = useState({
    price: objectPrice,
    period: periodOptions.find((option) => Number(option.get('value')) === defaults.period),
    interest: defaults.fixedInterestRate,
    selfFinancing: defaults.selfFinancing,
    selfFinancingUnit: SELF_FINANCE_UNIT_OPTIONS.first(),
  });

  useEffect(() => {
    // update defaults only for untouched fields
    setCalcState((prevState) => ({
      price: touchedFields.price ? prevState.price : objectPrice,
      period: touchedFields.period
        ? prevState.period
        : periodOptions.find((option) => Number(option.get('value')) === defaults.period),
      interest: touchedFields.interest ? prevState.interest : defaults.fixedInterestRate,
      selfFinancing: touchedFields.selfFinancing ? prevState.selfFinancing : defaults.selfFinancing,
      selfFinancingUnit: touchedFields.selfFinancingUnit
        ? prevState.selfFinancingUnit
        : SELF_FINANCE_UNIT_OPTIONS.first(),
    }));
  }, [defaults]);

  const gaEventHandler = (action: string, label?: string) => {
    ReactGA.event({
      category: GA_MULTI_BANK_CALCULATOR,
      action,
      label,
      placement_name: placementName,
    });
  };

  useEffect(() => {
    gaEventHandler('show_calculator', activeBank.name);
  }, [activeBank]);

  const results = useMemo(() => {
    return computeLoanSale(
      calcState.price,
      calcState.period?.get('value'),
      (Number(calcState.interest) + Number(euriborRate)).toFixed(2),
      calcState.selfFinancing,
      calcState.selfFinancingUnit?.get('value'),
      defaults.loanCommissionFee,
      defaults.loanCommissionFeeMin ?? 0,
      defaults.loanCommissionFeeMax ?? 0,
      defaults.accountMonthlyFee,
      defaults.loanRegistrationFee,
      defaults.loanRegistrationFeeAddition
    );
  }, [calcState, euriborRate, defaults]);

  const links = useMemo(() => {
    let l = activeBank.links;
    // overrides links by unit type
    if (l.overrides && l.overrides[unitType]) {
      l = l.overrides[unitType];
    }

    return {
      button: l?.button[lang]
        .replace('%period%', Number(calcState.period?.get('value')) * 12)
        .replace('%amount%', calcState.price)
        .replace('%loanAmount%', results.loanAmount)
        .replace('%utmContent%', `${unitType}-${lang}`)
        .replace('%swedbank-cid%', activeBank.name === 'swedbank' ? getSwedbankCalculatorCid(placementName) : ''),
      link: l?.link[lang].replace('%utmContent%', `${unitType}-${lang}`),
    };
  }, [activeBank, lang, calcState.price, calcState.period, results.loanAmount, unitType]);

  const setCalcStateValue = (field: string, value: any) => {
    setCalcState((prevState) => ({ ...prevState, [field]: value }));
    setTouchedFields((prevState) => ({ ...prevState, [field]: true }));
  };

  const renderTabLogo = (bank: string) => {
    switch (bank) {
      case 'seb':
        return <Image src={sebLogo} alt={bank} />;
      case 'swedbank':
        return <Image src={swedbankLogo} alt={bank} />;
      case 'luminor':
      case 'luminor_ee':
        return <Image src={luminorLogo} alt={bank} />;
      case 'bigbank':
        return <Image src={bigBankLogo} alt={bank} />;
      case 'coop':
        return <Image src={coopLogo} alt={bank} />;
      case 'lhv':
        return <Image src={lhvLogo} alt={bank} />;
      case 'citadele':
        return <Image src={citadeleLogo} alt={bank} />;
      default:
    }
    return bank;
  };

  return (
    <div className="multi-bank-calc">
      <Tabs
        className="multi-bank-calc__tabs"
        selected={activeBank.name}
        onChange={(val: string) => {
          setActiveBank(MULTI_BANK_HOME_LOAN_CALCULATOR.find((item) => item.name === val));
          gaEventHandler('select_tab', val);
        }}
      >
        {filteredBanks.map((item: MultiBankCalculatorItem) => (
          <Tab key={item.name} value={item.name} className={item.name}>
            {renderTabLogo(item.name)}
          </Tab>
        ))}
      </Tabs>
      {compact ? (
        <CompactLoanCalculator
          t={t}
          calcState={calcState}
          setCalcState={setCalcStateValue}
          results={results}
          defaults={defaults}
          links={links}
          bank={activeBank.name}
          accentColor={activeBank.color}
          unitType={unitType}
          withButton={compactWide}
          periodOptions={periodOptions}
          gaEventHandler={gaEventHandler}
        />
      ) : (
        <ExtendedLoanCalculator
          t={t}
          calcState={calcState}
          euriborRate={euriborRate}
          setCalcState={setCalcStateValue}
          results={results}
          defaults={defaults}
          links={links}
          bank={activeBank.name}
          accentColor={activeBank.color}
          compact={compact}
          periodOptions={periodOptions}
          gaEventHandler={gaEventHandler}
        />
      )}
    </div>
  );
};

export default MultiBankCalculator;
