import React, { useMemo } from 'react';
import produce from 'immer';
import { shuffleArray } from '@/utils/helpers';
import { SEO_FOOTER_MAX_LINKS, SEO_FOOTER_SECTIONS } from '@/constants/siteLinks';
import SiteLinksSection from '@/components/siteLinks/SiteLinksSection';
import { TFunction } from 'i18next';

type Props = {
  t: TFunction;
  onFooterLinkClick: () => void;
};

const SiteLinks = ({ t, onFooterLinkClick }: Props): React.ReactElement => {
  const sections = useMemo(() => {
    return produce(SEO_FOOTER_SECTIONS, (draft) => {
      draft.forEach((section) => {
        section.tabs.forEach((tab) => {
          tab.name = t(tab.name);
          tab.sections?.forEach((tabSection) => {
            tabSection.name = t(tabSection.name);
            tabSection.links.forEach((link) => {
              link.text = t(link.text);
            });
            if (tabSection.links.length > SEO_FOOTER_MAX_LINKS) {
              tabSection.links = shuffleArray(tabSection.links);
            }
          });
        });
      });
    });
  }, [t]);

  return (
    <div className="site-links">
      {sections.map((section) => (
        <SiteLinksSection
          key={section.link + section.name}
          title={t(section.name)}
          tabs={section.tabs}
          link={section.link}
          onFooterLinkClick={onFooterLinkClick}
        />
      ))}
    </div>
  );
};

export default SiteLinks;
