import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import { EMAIL_ALERT_ASAP, EMAIL_ALERT_ONCE_A_DAY, EMAIL_ALERT_ONCE_A_WEEK } from '@/constants/savedSearches';
import { GAL_SAVE_SEARCH_INTERVAL } from '@/constants/ga';
import { NameValue } from '@/utils/collections';

import RadioGroup from '@/components/radio/RadioGroup';
import ToggleField from '@/components/toggle/ToggleField';
import Input from '@/components/input/Input';

const propTypes = {
  alert: PropTypes.bool,
  interval: PropTypes.instanceOf(NameValue).isRequired,
  getAlert: PropTypes.func.isRequired,
  getInterval: PropTypes.func.isRequired,
  gaEvent: PropTypes.func,
};

export const emailIntervalOptions = {
  [EMAIL_ALERT_ASAP]: { name: 'emailAlerts.interval.atOnce', value: EMAIL_ALERT_ASAP },
  [EMAIL_ALERT_ONCE_A_DAY]: {
    name: 'emailAlerts.interval.oncePerDay',
    value: EMAIL_ALERT_ONCE_A_DAY,
  },
  [EMAIL_ALERT_ONCE_A_WEEK]: {
    name: 'emailAlerts.interval.oncePerWeek',
    value: EMAIL_ALERT_ONCE_A_WEEK,
  },
};

export const intervalOptions = List([
  NameValue(emailIntervalOptions[EMAIL_ALERT_ASAP]),
  NameValue(emailIntervalOptions[EMAIL_ALERT_ONCE_A_DAY]),
  NameValue(emailIntervalOptions[EMAIL_ALERT_ONCE_A_WEEK]),
]);
const defaultProps = {
  alert: false,
  gaEvent: null,
};

const EmailAlert = (props) => {
  const {
    alert,
    getEmail,
    emailMissing,
    emailNotEmail,
    interval,
    priceChange,
    getAlert,
    getInterval,
    getPriceChange,
    email,
    gaEvent,
    t,
  } = props;
  return (
    <div className="email-alert">
      <ToggleField
        title={t('emailAlerts.title')}
        id="email-alerts"
        getValue={getAlert}
        checked={alert}
        subtitle={!alert ? email : null}
        gaEvent={(checked) => {
          const action = checked ? 'activate_alerts' : 'deactivate_alerts';
          gaEvent(action);
        }}
      />
      {alert && (
        <div className="email-alert__content">
          <div className="email-alert__settings">
            <Input
              type="email"
              placeholder={t('common.yourEmail')}
              value={email}
              getValue={getEmail}
              error={(() => {
                if (emailMissing) {
                  return t('errors.fillField');
                }
                if (emailNotEmail) {
                  return t('errors.email.notEmail');
                }
                return null;
              })()}
            />
            <div className="email-alert__interval">
              <h5 className="email-alert__subtitle">{t('emailAlerts.interval.title')}</h5>
              <RadioGroup
                name="email-interval"
                options={intervalOptions}
                selected={interval}
                getValue={getInterval}
                gaEvent={(selectedValue) => gaEvent('select_frequency', GAL_SAVE_SEARCH_INTERVAL[selectedValue])}
                t={t}
              />
            </div>
            <div className="email-alert__price-change">
              <ToggleField
                title={t('emailAlerts.notifyPriceChange')}
                id="email-price-change"
                getValue={getPriceChange}
                checked={priceChange}
                gaEvent={(checked) => {
                  // const action = checked ? 'activate_alerts' : 'deactivate_alerts';
                  // gaEvent(action);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

EmailAlert.propTypes = propTypes;
EmailAlert.defaultProps = defaultProps;

export default EmailAlert;
