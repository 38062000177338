import { favouriteTypeFromUnitType } from '@city24/common/enums/realty/FavouriteType';
import api, { convertIRI, parsePagination } from '@/api/City24Api';
import {
  GET_OBJECT_DETAILS,
  GET_PROJECT_DETAILS,
  GET_MODULAR_HOUSE_DETAILS,
  SET_OBJECT_LOADING,
  SET_OBJECT_DETAIL_BACK,
  SET_OBJECT_MEDIA_FULL,
  SET_SLIDE_COUNT,
  SET_OPEN_HOUSE_DETAILS,
  GET_PROJECT_REALTIES,
  GET_OBJECT_FAVOURITE_COUNT,
  OBJECT_STATUS_ERROR,
  SET_STREET_VIEW_AVAILABILITY,
  SET_OBJECT_NAV_COUNT,
  SET_DETAIL_PREVIEW_MODAL,
  GET_INTERNAL_FEED_ARTICLES,
  GET_CENU_BANKA_DATA,
} from '@/constants/actions';
import { HAS_OPEN_DAYS } from '@/constants/attributes';
import { shuffleArray } from '@/utils/helpers';
import { isInactive } from '@/utils/objectStates';
import { HttpError, NotFoundError, GoneError } from '@/errors/http';
import openDaysApi from '@/api/openDaysApi';
import { AsyncDataStatus, createAsyncData } from '@/types/collections';
import { openModal } from '../modals/modalReducer';
import { ModalName } from '../modals/modalInterfaces';

export function objectLoading(loading) {
  return {
    type: SET_OBJECT_LOADING,
    loading,
  };
}

export function fetchOpenHouseDetails(id, fallbackId = null) {
  return (dispatch) => {
    return openDaysApi
      .getOpenDays(id)
      .then((res) => res.json())
      .then((openHouseDetails) => {
        // Check if fallback object/project has openDays
        if (fallbackId && openHouseDetails.length === 0) {
          dispatch(fetchOpenHouseDetails(fallbackId));
        } else {
          dispatch({
            type: SET_OPEN_HOUSE_DETAILS,
            openHouseDetails,
          });
        }
      });
  };
}

export function processObjectResponse(res) {
  if (res.ok) {
    return res.json();
  }
  if (res.status === 404) {
    throw new NotFoundError('Object not found');
  } else if (res.status === 410) {
    throw new GoneError('Object unavailable');
  } else {
    throw new HttpError('Error');
  }
}

export function fetchProjectRealties(id, removeRealtyId = null, actionType = GET_PROJECT_REALTIES) {
  return (dispatch) => {
    dispatch({
      type: actionType,
      projectId: id,
      realties: createAsyncData([], AsyncDataStatus.Loading),
    });

    return api
      .getProjectRealties(id, { order: { roomCount: 'asc' } })
      .then(processObjectResponse)
      .then((realties) => {
        // When object is not a project, remove itself from associated realties
        const updatedRealties = removeRealtyId ? realties.filter((r) => r.id !== removeRealtyId) : realties;
        return dispatch({
          type: actionType,
          projectId: id,
          realties: createAsyncData(updatedRealties, AsyncDataStatus.Loaded),
        });
      })
      .catch(() => {
        dispatch({
          type: actionType,
          projectId: id,
          realties: createAsyncData([], AsyncDataStatus.Loaded),
        });
      });
  };
}

function objectStatusError(status) {
  return {
    type: OBJECT_STATUS_ERROR,
    status,
  };
}

function processObjectError(dispatch) {
  return (err) => {
    if (err instanceof GoneError) {
      dispatch(objectStatusError('gone'));
    } else {
      dispatch(objectStatusError('error'));
    }
    console.error(err);
  };
}

function resetUnpublishedFields(object) {
  if (!isInactive(object.status_id)) {
    return object;
  }
  const resetFields = ['office', 'broker', 'price', 'price_per_unit', 'descriptions', 'slogans'];

  resetFields.forEach((field) => {
    const f = object[field];
    if (f === undefined) return;

    let resetValue = null;

    if (Array.isArray(f)) {
      resetValue = [];
    } else if (typeof f === 'object') {
      resetValue = {};
    }

    // eslint-disable-next-line no-param-reassign
    object[field] = resetValue;
  });

  return object;
}

export function mapObject(object) {
  return {
    ...resetUnpublishedFields(object),
    transaction_type: convertIRI(object.transaction_type),
    images: object.images.map(({ url }) => url),
    info: Array.isArray(object.info) ? object.info[0] || null : object.info,
  };
}

function objectDetails(dispatch, id) {
  return api
    .getObject(id)
    .then(processObjectResponse)
    .then((object) => {
      return dispatch({
        type: GET_OBJECT_DETAILS,
        object: mapObject(object),
      });
    })
    .then(({ object }) => {
      let openHouseProjectId = null;
      if (object.project) {
        const projectId = object.project.friendly_id;
        if (projectId) {
          dispatch(fetchProjectRealties(projectId, object.id));
          if (object.project.attributes && object.project.attributes[HAS_OPEN_DAYS]) {
            openHouseProjectId = projectId;
          }
        }
      }
      dispatch(fetchOpenHouseDetails(object.friendly_id, openHouseProjectId));
    });
}

export function fetchObjectDetails(id) {
  return (dispatch) => {
    dispatch(objectLoading(true));
    return objectDetails(dispatch, id).catch(processObjectError(dispatch));
  };
}

function projectDetails(dispatch, id) {
  return api
    .getProject(id)
    .then(processObjectResponse)
    .then((object) => {
      dispatch(fetchOpenHouseDetails(object.friendly_id));
      return dispatch({
        type: GET_PROJECT_DETAILS,
        object: mapObject(object),
      });
    })
    .then(({ object }) => {
      dispatch(fetchProjectRealties(object.friendly_id));
    });
}

export function fetchProjectDetails(id) {
  return (dispatch) => {
    dispatch(objectLoading(true));

    return projectDetails(dispatch, id).catch(processObjectError(dispatch));
  };
}

function modularHouseDetails(dispatch, id) {
  return api
    .getModularHouse(id)
    .then(processObjectResponse)
    .then((object) => {
      return dispatch({
        type: GET_MODULAR_HOUSE_DETAILS,
        object: mapObject(object),
      });
    });
}

export function fetchModularHouseDetails(id) {
  return (dispatch) => {
    dispatch(objectLoading(true));

    return modularHouseDetails(dispatch, id).catch(processObjectError(dispatch));
  };
}

export function fetchUnknownObjectDetails(id) {
  return (dispatch) => {
    dispatch(objectLoading(true));

    let resolved = false;
    let failed = 0;
    let error = {};
    const promises = [objectDetails(dispatch, id), projectDetails(dispatch, id), modularHouseDetails(dispatch, id)].map(
      (promise) =>
        promise
          .then(() => {
            resolved = true;
            return true;
          })
          .catch((err) => {
            failed += 1;
            processObjectError((action) => {
              if (!error.status || action.status !== 'error') {
                error = action;
              }
            })(err);

            return false;
          })
    );
    const interval = setInterval(() => {
      if (resolved || failed >= promises.length) {
        clearInterval(interval);
        dispatch(objectLoading(false));
        if (error.type) {
          dispatch(error);
        }
      }
    }, 50);

    return promises;
  };
}

export function fetchFavouriteCount(guid, objectType) {
  return (dispatch) => {
    api
      .getFavouriteCount({
        itemGlobalId: guid,
        itemType: favouriteTypeFromUnitType(objectType),
        v: Math.floor(new Date().setMinutes(0, 0, 0) / 1000),
      })
      .then((res) => {
        if (res.ok) {
          const pagination = parsePagination(res);
          dispatch({
            type: GET_OBJECT_FAVOURITE_COUNT,
            count: pagination.total,
          });
        }
      });
  };
}

export function setObjMediaFull(full) {
  return {
    type: SET_OBJECT_MEDIA_FULL,
    full,
  };
}

export function setSlideCount(value) {
  return {
    type: SET_SLIDE_COUNT,
    slideCount: value,
  };
}

export function setStreetViewAvailability(available) {
  return {
    type: SET_STREET_VIEW_AVAILABILITY,
    available,
  };
}

export function setObjectNavCount(listAction, count) {
  return {
    type: SET_OBJECT_NAV_COUNT,
    listAction,
    count,
  };
}

export function openBrokerContactModal(object, contactType = 'object') {
  return openModal({ name: ModalName.MessageBroker, object, contactType });
}

export function openDetailPreviewModal(id) {
  return {
    type: SET_DETAIL_PREVIEW_MODAL,
    opened: true,
    id,
  };
}

export function fetchInternalFeedArticles(locale) {
  return (dispatch) => {
    api
      .getPartnersNewsArticles(
        {
          portal: PORTAL,
          partner: 'internal',
        },
        {},
        locale.replace('-', '_')
      )
      .then((res) => (res.ok ? res.json() : []))
      .then((articles) => {
        dispatch({
          type: GET_INTERNAL_FEED_ARTICLES,
          articles: shuffleArray(articles),
        });
      });
  };
}

export function fetchCenuBankaData(location) {
  return (dispatch) => {
    api
      .getCenuBankaData(location)
      .then((res) => (res.ok ? res.json() : []))
      .then((cb) => {
        dispatch({
          type: GET_CENU_BANKA_DATA,
          data: cb,
        });
      });
  };
}
