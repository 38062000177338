import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';
import { useSelector } from 'react-redux';

import { convertIRI } from '@/api/City24Api';

import {
  FURNITURE,
  NUMBER_OF_BEDROOMS,
  NUMBER_OF_BATHROOMS,
  LIFT,
  STORAGE_ROOM,
  BALCONY_TYPE,
  SANITARY_ARRANGEMENTS,
  CONSTRUCTION_YEAR,
  ENERGY_CERTIFICATE_TYPE,
  ELECTRICITY,
  GAS,
  WATER,
  ROAD,
  HAS_SEWERAGE,
  HEATING_SYSTEM,
  VENTILATION_TYPE,
  MEETING_ROOM,
  IMMEDIATELY_AVAILABLE,
  COMING_SOON,
} from '@/constants/attributes';
import { OBJECT_TYPES } from '@/constants/object';
import { hasOpenDays, hasSpecialLabel } from '@/utils/objectStates';
import { applyImageFormat, addressComponent, locationComponent, urlFormatLocation } from '@/utils/formatting';
import { typeCombinationString } from '@/utils/route';
import { getLanguage } from '@/selectors/appSelectors';
import { getMetaDataFromObject, getObjectTitleFromMeta } from '@/utils/helpers';
import RealEstateObject from '../RealEstateObject';

function SimpleCommonObject({ object, imageFormat, getAttrValue, ObjectComponent, layout, ...props }) {
  const { openDays, t } = props;
  const lang = useSelector(getLanguage);
  const image = object.getIn(['main_image', 'url']);
  const alt = useMemo(() => getObjectTitleFromMeta(getMetaDataFromObject(object, lang, t), t), []);

  const transactionType = convertIRI(object.get('transaction_type'));

  const address = object.get('address');
  return (
    <ObjectComponent
      {...props}
      object={object}
      objectType={object.get('unit_type')}
      transactionType={transactionType}
      layout={layout}
      typeCombination={typeCombinationString(transactionType, object.get('unit_type'), 'url')}
      id={object.get('id')}
      friendlyId={object.get('friendly_id')}
      guid={object.get('guid')}
      isNew={object.get('new')}
      status={object.get('status_id')}
      image={image && applyImageFormat(image, imageFormat || (layout === 'grid' ? '432x288' : '342x228'))}
      imageAlt={alt}
      sliderImages={object.get('images')}
      address={address && addressComponent(address)}
      location={address && locationComponent(address)}
      latitude={object.get('latitude')}
      longitude={object.get('longitude')}
      urlLocation={urlFormatLocation(address)}
      slogans={object.get('slogans')}
      descriptions={object.get('descriptions')}
      level={object.get('ad_level')}
      favourite={object.get('favourite')}
      openDays={openDays || hasOpenDays(object)}
      bedrooms={object.getIn(['attributes', NUMBER_OF_BEDROOMS])}
      bathrooms={object.getIn(['attributes', NUMBER_OF_BATHROOMS])}
      elevator={object.getIn(['attributes', LIFT])}
      storage={object.getIn(['attributes', STORAGE_ROOM])}
      balcony={
        object.hasIn(['attributes', 'BALCONY_TYPE'])
          ? object.getIn(['attributes', 'BALCONY_TYPE']).includes(BALCONY_TYPE.BALCONY)
          : false
      }
      terrace={
        object.hasIn(['attributes', 'BALCONY_TYPE'])
          ? object.getIn(['attributes', 'BALCONY_TYPE']).includes(BALCONY_TYPE.TERRACE)
          : false
      }
      sauna={
        object.hasIn(['attributes', 'SANITARY_ARRANGEMENTS'])
          ? object.getIn(['attributes', 'SANITARY_ARRANGEMENTS']).includes(SANITARY_ARRANGEMENTS.SAUNA)
          : false
      }
      constructionYear={object.getIn(['attributes', CONSTRUCTION_YEAR])}
      energyCertificateType={object.get('attributes', ENERGY_CERTIFICATE_TYPE)}
      parking={object.getIn([
        'attributes',
        object.get('unit_type') === OBJECT_TYPES.Commercial ? 'PARKING_SELECTION_COMMERCIAL' : 'PARKING_SELECTION',
      ])}
      immediatelyAvailable={object.getIn(['attributes', IMMEDIATELY_AVAILABLE])}
      furniture={object.getIn(['attributes', FURNITURE])}
      purposeOfUse={object.getIn([
        'attributes',
        object.get('unit_type') === OBJECT_TYPES.Commercial ? 'PURPOSE_OF_USE_COMMERCIAL' : 'PURPOSE_OF_USE_LAND',
      ])}
      eletricity={object.getIn(['attributes', ELECTRICITY])}
      gas={object.getIn(['attributes', GAS])}
      water={object.getIn(['attributes', WATER])}
      road={object.getIn(['attributes', ROAD])}
      sewerage={object.getIn(['attributes', HAS_SEWERAGE])}
      heating={object.getIn(['attributes', HEATING_SYSTEM])}
      ventilation={object.getIn(['attributes', VENTILATION_TYPE])}
      meetingRoom={object.getIn(['attributes', MEETING_ROOM])}
      specialLabel={hasSpecialLabel(object)}
      // tetAvailability={object.getIn(['attributes', 'TET_OPTICS_AVAILABILITY'])}
      comingSoon={object.getIn(['attributes', COMING_SOON])}
    />
  );
}

SimpleCommonObject.propTypes = {
  object: PropTypes.instanceOf(Collection.Keyed).isRequired,
  getAttrValue: PropTypes.func.isRequired,
  imageFormat: PropTypes.string,
  ObjectComponent: PropTypes.elementType,
};
SimpleCommonObject.defaultProps = {
  imageFormat: null,
  ObjectComponent: RealEstateObject,
};
export default SimpleCommonObject;
