import React from 'react';
import type { TFunction } from 'next-i18next';
import ReactGA from '@/utils/ga';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { getModalState, isModalOpen } from '@/selectors/appSelectors';
import Button from '@/components/button/Button';
import SuccessIcon from '@/img/icons/success.svg';

import Modal from './Modal';
import FancySection from './ModalSections/FancySection';
import { closeModal } from './modalReducer';
import { ModalName, SimpleConfirmModalState } from './modalInterfaces';

function SimpleConfirmModal({ t }: { t: TFunction }) {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(getModalState);
  const isOpen = isModalOpen(modalState, ModalName.SimpleConfirm);
  const { title, gaCat } = modalState.state as SimpleConfirmModalState;
  const onRequestClose = () => {
    dispatch(closeModal());
    if (gaCat) {
      ReactGA.event({
        category: gaCat,
        action: 'close_button',
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <FancySection image={SuccessIcon} title={t(title)} />
      <Button primary extended text={t('common.close')} onClick={onRequestClose} />
    </Modal>
  );
}

export default SimpleConfirmModal;
