import { createResponsiveStateReducer } from 'redux-responsive';

const browser = createResponsiveStateReducer(
  {
    extraSmall: 480,
    small: 768,
    medium: 980,
    large: 1280,
    extraLarge: 1650,
  },
  {
    initialMediaType: 'extraLarge',
  }
);

export type BrowserState = ReturnType<typeof browser>;

export default browser;
