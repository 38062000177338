import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from '@/utils/ga';
import { Map } from 'immutable';
import { TFunction } from 'react-i18next';

import { AppDispatch, RootState } from '@/store';
import { objectTransactionString } from '@/utils/formatting';
import { NameValue } from '@/utils/collections';
import { validateEmail } from '@/utils/validation';

import { saveSearch, updateSavedSearch } from '@/actions/userActions';
import { getUserEmail } from '@/selectors/userSelectors';

import Button from '@/components/button/Button';
import Input from '@/components/input/Input';
import ButtonGroup from '@/components/button/ButtonGroup';
import { GA_SAVE_SEARCH } from '@/constants/ga';
import { EMAIL_ALERT_OFF } from '@/constants/savedSearches';
import { getTransactionType, getObjectType } from '@/components/search/searchSelectors';

import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import EmailAlert, { intervalOptions, emailIntervalOptions } from './EmailAlert/EmailAlert';

interface Props {
  edit?: boolean;
  isOpen?: boolean;
  item: Record<string, any>;
  objectType: Map<'name' | 'value', any>;
  transactionType: Map<'name' | 'value', any>;
  userEmail?: string;
  t: TFunction;
  dispatch: AppDispatch;
  closeModal: () => void;
}

interface State {
  title: string;
  alert: boolean;
  email: string;
  emailMissing: boolean;
  emailNotEmail: boolean;
  interval: any;
  priceChange: boolean;
}

class SaveSearchModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { edit, item, objectType, transactionType, userEmail, t } = props;

    // const searchTermItems = searchTerm.map(i => i.get('name'));
    // const date = new Date().toLocaleDateString('en-GB');

    const setInterval = () => {
      if (item && emailIntervalOptions[item.email_alert_type]) {
        return NameValue(emailIntervalOptions[item.email_alert_type]);
      }
      return intervalOptions.first();
    };

    const title = edit ? item.name : t(objectTransactionString(objectType.get('value'), transactionType.get('value'))!);
    const alert = edit ? item.email_alert_type !== EMAIL_ALERT_OFF : false;
    const email = edit ? item.email_address : userEmail;
    const priceChange = edit ? item.price_alert : false;

    this.state = {
      title,
      alert,
      email,
      emailMissing: false,
      emailNotEmail: false,
      interval: setInterval(),
      priceChange,
    };
  }

  handleSubmit = () => {
    const { dispatch, edit, closeModal, item } = this.props;
    const { title, alert, email, interval, priceChange } = this.state;

    let submitAllowed = true;
    if (alert) {
      if (email) {
        this.setState({ emailMissing: false });
      } else {
        this.setState({ emailMissing: true });
        submitAllowed = false;
      }

      if (validateEmail(email)) {
        this.setState({ emailNotEmail: false });
      } else {
        this.setState({ emailNotEmail: true });
        submitAllowed = false;
      }
    }

    if (submitAllowed) {
      if (edit) {
        dispatch(
          updateSavedSearch({
            ...item,
            name: title,
            price_alert: priceChange,
            email_alert_type: alert ? interval.value : EMAIL_ALERT_OFF,
          })
        );
        closeModal();
      } else {
        dispatch(saveSearch(title, alert, email, interval, priceChange));
      }
    }
  };

  gaEventFunction = (action: string, label?: string) =>
    ReactGA.event({
      category: GA_SAVE_SEARCH,
      action,
      label: label ? label.toString() : label,
    });

  render() {
    const { alert, email, emailMissing, emailNotEmail, interval, priceChange, title } = this.state;
    const { isOpen, closeModal, edit, item, t } = this.props;

    return (
      <Modal
        className="ss-modal"
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        gaEvent={() => this.gaEventFunction('close_button')}
      >
        <div>
          <ModalHeader closeModal={closeModal} title={edit ? item.name : t('common.saveSearch')} />
          <Input
            notched
            label={t('saveSearchModal.searchTitlePlaceholder')}
            value={title}
            getValue={(titleVal) => this.setState({ title: titleVal })}
            gaEvent={() => this.gaEventFunction('insert_searchTitle')}
          />
          <EmailAlert
            alert={alert}
            email={email}
            interval={interval}
            priceChange={priceChange}
            emailMissing={emailMissing}
            emailNotEmail={emailNotEmail}
            getEmail={(val) => this.setState({ email: val })}
            getAlert={(val) => this.setState({ alert: val })}
            getInterval={(val) => this.setState({ interval: val })}
            getPriceChange={(val) => this.setState({ priceChange: val })}
            gaEvent={this.gaEventFunction}
            t={t}
          />

          <ModalFooter>
            <ButtonGroup>
              <Button
                transparent
                text={t('common.cancel')}
                onClick={() => {
                  this.gaEventFunction('close_saveSearch_button');
                  closeModal();
                }}
              />
              <Button
                primary
                text={edit ? t('common.saveSearch') : t('common.signInAndSave')}
                onClick={() => {
                  const action = edit ? 'save_button' : 'open_signIn_button';
                  this.gaEventFunction(action);
                  this.handleSubmit();
                }}
              />
            </ButtonGroup>
          </ModalFooter>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    transactionType: getTransactionType(state),
    objectType: getObjectType(state),
    userEmail: getUserEmail(state),
  };
}

export default connect(mapStateToProps)(SaveSearchModal);
