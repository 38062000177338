import React from 'react';
import ReactGA from '@/utils/ga';
import Language from '@city24/common/enums/Language';
import { GA_DETAIL_VIEW } from '@/constants/ga';
import { ALTERO_LINKS, ALTERO_LOAN_LINKS } from '@/constants/integrations';
import { TFunction } from 'next-i18next';
import Anchor from '@/components/link/Anchor';
import Icon from '@/components/icon/Icon';

import css from '../SpecsItem.module.scss';

interface Props {
  language: Language;
  t: TFunction;
}

const AlteroSpecsItem = ({ language, t }: Props): React.ReactElement => {
  return (
    <div className={`${css.item} ${css.altero}`}>
      <div>
        <Icon name="altero" />
      </div>
      <div>
        <Anchor
          href={ALTERO_LINKS[language]}
          onClick={() => {
            ReactGA.event({
              category: GA_DETAIL_VIEW,
              action: 'AlteroLinkClick',
              label: 'altero',
            });
          }}
        >
          <span className={css.content}>{t(`objectSpec.altero`)}</span>
          <Icon name="angle-right" className={css.icon} />
        </Anchor>
        <Anchor
          href={ALTERO_LOAN_LINKS[language]}
          onClick={() => {
            ReactGA.event({
              category: GA_DETAIL_VIEW,
              action: 'AlteroLoanLinkClick',
              label: 'altero',
            });
          }}
        >
          <span className={css.content}>{t(`objectSpec.alteroLoan`)}</span>
          <Icon name="angle-right" className={css.icon} />
        </Anchor>
      </div>
    </div>
  );
};

export default AlteroSpecsItem;
