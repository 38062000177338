import React from 'react';
import classNames from 'classnames';

const CalculatedField = ({ label, value, bigValue = false }) => {
  return (
    <div className="calc-field">
      <div className="calc-field__label">{label}</div>
      <div className={classNames('calc-field__val', { h1: bigValue })}>{value}</div>
    </div>
  );
};

export default CalculatedField;
