import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DEFAULT_LANG, LANGUAGES } from '@/constants';
import { ROUTES } from '@/constants/routes';
import { getLanguage } from '@/selectors/appSelectors';
import { setLocale } from '@/actions/appActions';

import AppRoutes from '@/legacyPages';

function LangSwitch({ location, match: { url, params } }) {
  const [, i18n] = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector(getLanguage);
  const matchedLang = params[0];

  useEffect(() => {
    if (matchedLang && LANGUAGES.includes(matchedLang) && matchedLang !== lang) {
      dispatch(setLocale(matchedLang, true));
      i18n.changeLanguage(matchedLang);
    }
  }, [matchedLang, lang]);

  if (matchedLang === DEFAULT_LANG || (matchedLang && !LANGUAGES.includes(matchedLang))) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: location.pathname.replace(url, '') || ROUTES.root,
        }}
      />
    );
  }

  return <AppRoutes />;
}

LangSwitch.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default LangSwitch;
