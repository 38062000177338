import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

const defaultProps = {
  title: '',
};

const ModalHeader = (props) => {
  const { title } = props;

  return (
    <div className="modal-header">
      <h3 className="modal-header__title">{title}</h3>
    </div>
  );
};

ModalHeader.propTypes = propTypes;
ModalHeader.defaultProps = defaultProps;

export default ModalHeader;
