import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import { GAE_SELECT_PROPERTY_TYPE } from '@/constants/ga';
import Select from '@/components/select/Select';
import { Collection } from 'immutable';

interface Props {
  // Extend from Select component when ready
  main?: boolean;
}

function ObjectType({
  t,
  className,
  main = false,
  multiple = false,
  allSelectedText,
  selected,
  options,
  onChange,
}: Props) {
  return (
    <div className={className}>
      <Select
        t={t}
        multiple={multiple}
        native={isMobile}
        raised={main}
        allSelectedText={allSelectedText}
        options={options}
        selected={selected}
        empty={false}
        getValue={onChange}
        gaEvent={GAE_SELECT_PROPERTY_TYPE}
      />
    </div>
  );
}

ObjectType.propTypes = {
  main: PropTypes.bool,
  selected: PropTypes.instanceOf(Collection.Keyed).isRequired,
  options: PropTypes.instanceOf(Collection).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ObjectType;
