import { Collection } from 'immutable';
import currencyFormatter from 'currency-formatter';

import getImageFormat from '../constants/image';
import { OBJECT_TYPES, TRANSACTION_TYPES } from '../constants/object';
import { OBJECT_TRANSACTION_STRINGS, OBJECT_TRANSACTION_STRINGS_SINGULAR } from '../constants/filters';
import { fromISOString } from './date';

const id = (a) => a;

export function applyImageFormat(imageUrl, format = 'original', type = 'object') {
  if (!imageUrl) {
    return '';
  }
  if (format === 'original' && imageUrl.includes('/{fmt:em}/')) {
    // We assume this is new URL so original image is slightly different from before
    return imageUrl.replace('{fmt:em}', 'orig');
  }
  const formats = getImageFormat(type)[format];
  if (!formats) {
    console.warn(`Unknown format ${format} for type ${type}`);
    return imageUrl;
  }
  const [em, city24] = formats;
  return imageUrl.replace('{fmt:em}', em).replace('{fmt:c24}', city24);
}
/** @deprecated use ./utils/address.ts */
export function houseAndApartmentComponent(address) {
  return [address.get('house_number'), address.get('apartment_number')].filter(id).join('-');
}

/** @deprecated use ./utils/address.ts */
export function streetComponent(address, houseAndApartment = null) {
  return address.get('street_name') && address.get('street_name') + (houseAndApartment && ` ${houseAndApartment}`);
}

/** @deprecated use ./utils/address.ts */
export function addressComponent(address) {
  return [
    streetComponent(address, houseAndApartmentComponent(address)),
    address.get('location_name'),
    address.get('district_name'),
  ]
    .filter(id)
    .join(', ');
}

/** @deprecated use ./utils/address.ts */
export function locationComponent(address) {
  return [address.get('city_name'), address.get('parish_name'), address.get('county_name')].filter(id).join(', ');
}

/** @deprecated use ./utils/address.ts */
export function fullAddress(address) {
  return `${locationComponent(address)} - ${addressComponent(address)}`;
}

export function urlFormatLocation(address) {
  if (!address) return '';
  const addr = address instanceof Collection.Keyed ? address.toObject() : address;
  const locations = [addr.county_name, addr.parish_name, addr.city_name, addr.district_name, addr.street_name].filter(
    id
  );

  return locations.slice(-3).join('-').replace(/\s/g, '-');
}

export function formatDistance(distance, precision = 1) {
  const number = parseFloat(distance);
  if (number >= 1000) {
    return `${Math.ceil(number / 100) / 10} km`;
  }
  return `${Math.ceil(number / 10 ** precision) * 10 ** precision} m`;
}

/** @deprecated use common library and Intl.NumberFormat */
export function formatPrice(price, noUnit = false, precision = null) {
  return currencyFormatter
    .format(price, {
      code: 'EUR',
      symbol: noUnit ? '' : undefined,
      decimal: '.',
      precision: precision ?? (price % 1 === 0 ? 0 : null),
    })
    .trim();
}

export function formatPriceRange(_minPrice, _maxPrice, unit = '€') {
  const minPrice = _minPrice ? formatPrice(_minPrice, true, 0) : '...';
  const maxPrice = _maxPrice ? formatPrice(_maxPrice, true, 0) : '...';

  if (_minPrice || _maxPrice) {
    return `${minPrice} - ${maxPrice} ${unit}`;
  }
  return null;
}

export function formatFloorString(floor, totalFloors) {
  const floorValue = parseInt(floor, 10);
  const totalFloorsValue = parseInt(totalFloors, 10);
  return (floorValue || floorValue === 0) && totalFloorsValue ? `${floorValue}/${totalFloorsValue}` : '';
}

/** @deprecated use ./utils/realty/index.ts */
export function objectTransactionString(objectType, transactionType, singularForm = false) {
  const tokens = singularForm ? OBJECT_TRANSACTION_STRINGS_SINGULAR : OBJECT_TRANSACTION_STRINGS;

  if (objectType === OBJECT_TYPES.Apartment && transactionType === TRANSACTION_TYPES.SALE) {
    return tokens.apartmentsForSale;
  }

  if (objectType === OBJECT_TYPES.Apartment && transactionType === TRANSACTION_TYPES.RENT) {
    return tokens.apartmentsForRent;
  }

  if (objectType === OBJECT_TYPES.House && transactionType === TRANSACTION_TYPES.SALE) {
    return tokens.housesForSale;
  }

  if (objectType === OBJECT_TYPES.House && transactionType === TRANSACTION_TYPES.RENT) {
    return tokens.housesForRent;
  }

  if (objectType === OBJECT_TYPES.HouseShare && transactionType === TRANSACTION_TYPES.SALE) {
    return tokens.houseSharesForSale;
  }

  if (objectType === OBJECT_TYPES.HouseShare && transactionType === TRANSACTION_TYPES.RENT) {
    return tokens.houseSharesForRent;
  }

  if (objectType === OBJECT_TYPES.Commercial && transactionType === TRANSACTION_TYPES.SALE) {
    return tokens.commercialsForSale;
  }

  if (objectType === OBJECT_TYPES.Commercial && transactionType === TRANSACTION_TYPES.RENT) {
    return tokens.commercialsForRent;
  }

  if (objectType === OBJECT_TYPES.CottageOrVilla && transactionType === TRANSACTION_TYPES.SALE) {
    return tokens.summerCottagesForSale;
  }

  if (objectType === OBJECT_TYPES.CottageOrVilla && transactionType === TRANSACTION_TYPES.RENT) {
    return tokens.summerCottagesForRent;
  }

  if (objectType === OBJECT_TYPES.Land && transactionType === TRANSACTION_TYPES.SALE) {
    return tokens.landsForSale;
  }

  if (objectType === OBJECT_TYPES.Land && transactionType === TRANSACTION_TYPES.RENT) {
    return tokens.landsForRent;
  }

  if (objectType === OBJECT_TYPES.Garage && transactionType === TRANSACTION_TYPES.SALE) {
    return tokens.garagesForSale;
  }

  if (objectType === OBJECT_TYPES.Garage && transactionType === TRANSACTION_TYPES.RENT) {
    return tokens.garagesForRent;
  }

  if (objectType === OBJECT_TYPES.NewProject && transactionType === TRANSACTION_TYPES.SALE) {
    return tokens.newProjectsForSale;
  }

  if (objectType === OBJECT_TYPES.NewProject && transactionType === TRANSACTION_TYPES.RENT) {
    return tokens.newProjectsForRent;
  }

  if (objectType === OBJECT_TYPES.ModularHouse && transactionType === TRANSACTION_TYPES.SALE) {
    return tokens.modularHousesForSale;
  }

  if (objectType === OBJECT_TYPES.ModularHouse && transactionType === TRANSACTION_TYPES.RENT) {
    return tokens.modularHousesForRent;
  }

  return null;
}

/**
 *
 * @param {Date|string|number} date Date object, ISO8601 date string or Unix timestamp in seconds
 * @param {string|Intl.DateTimeFormatOptions} opts Basic option as string or options piped to Date.prototype.toLocaleString
 * @param {string} locale Locale of the formatted date
 * @deprecated use @city24/common package
 */
export function formatDate(date, opts = 'date', locale = 'et-EE') {
  if (!(date instanceof Date)) {
    return formatDate(/^\d+$/.test(date) ? new Date(date * 1000) : fromISOString(date), opts, locale);
  }

  if (typeof opts === 'string') {
    const formatOpts = { year: 'numeric', month: '2-digit', day: '2-digit' };
    if (opts === 'date') {
      return date.toLocaleString(locale, formatOpts);
    }
    formatOpts.hour = '2-digit';
    formatOpts.minute = '2-digit';
    if (opts === 'datetime_short') {
      return date.toLocaleString(locale, formatOpts);
    }
    formatOpts.second = '2-digit';
    if (opts === 'datetime') {
      return date.toLocaleString(locale, formatOpts);
    }
  }

  return date.toLocaleString(locale, opts);
}

export function formatPhone(number) {
  if (!number) {
    return '';
  }
  const nr = number.replace(/\s/g, '');
  const nrArr = nr.split('');

  let area = [];
  const end = nrArr.splice(nr.length - 4);

  if (nr.length > 8) {
    if (nrArr[0] === '+') {
      area = nrArr.splice(0, 4);
    } else {
      area = nrArr.splice(0, 3);
      area.unshift('+');
    }
    area = ['(', ...area, ')'];
  }

  const formatted = [area.join(''), nrArr.join(''), end.join('')];

  return formatted.join(' ');
}

export function formatName(firstName, lastName) {
  const name = [firstName, lastName];
  return name.join(' ');
}

export function formatMinMaxPrice(minPrice, maxPrice, unit = '€', missing = '...') {
  return `${minPrice ? formatPrice(minPrice, true) : missing} - ${
    maxPrice ? formatPrice(maxPrice, true) : missing
  } ${unit}`;
}

export function sortOptions(options, t) {
  return options.sort((a, b) => {
    const aText = t(a.get('name')).toLowerCase();
    const bText = t(b.get('name')).toLowerCase();

    return aText.localeCompare(bText);
  });
}

export function formatFilesize(size, decimals = 2, si = false) {
  if (!size) return '';
  let units = ['B', 'kB', 'MB', 'GB', 'TB'];
  let step = 1024;
  if (si) {
    units = ['b', 'kb', 'Mb', 'Gb', 'Tb'];
    step = 1000;
  }
  const power = Math.floor(Math.log(size) / Math.log(step));
  const calculatedSize = (size / step ** power).toFixed(decimals);
  return `${calculatedSize} ${units[power]}`;
}

export function formatOfficeAddress(address) {
  const addr = address.STREET || address.POSTAL;
  if (!addr) {
    return false;
  }
  const formattedAddr = [addr.street_name, addr.city].filter(id).join(', ');
  const formattedPostal = addr.postal_code !== null ? `${addr.postal_code}` : '';
  if (formattedAddr.length > 0 || formattedPostal.length > 0) {
    return `${formattedAddr} ${formattedPostal}`;
  }
  return false;
}

export function priceDropPercentage(newPrice, oldPrice) {
  return `-${Math.floor((1 - newPrice / oldPrice) * 100)}%`;
}

export function normalizeString(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
export function sanitizeWhitespaces(str) {
  return str.replace(/\s+/g, ' ');
}

export function getOpenDaysDate(details) {
  const { openDate } = details;
  if (openDate) {
    const [year, month, day] = openDate.split('-');
    return `${day}.${month}.${year}`;
  }
  return '';
}

export function substrWords(str, maxLen, separator = ' ') {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
}
