import React, { useContext } from 'react';
import AppContext from '@/components/appContext';
import { LinkProps } from './LinkInterface';
import Anchor from './Anchor';

interface Props extends LinkProps {
  href: string;
}
/**
 * A Link component where the target href is a NextJS page
 * but the component itself is used in both legacy and NextJS contexts.
 */
function LegacyToNextLink({ href, ...props }: Props): React.ReactElement {
  const { Link, isNextJs } = useContext(AppContext);
  return isNextJs ? <Link href={href} {...props} /> : <Anchor href={href} {...props} sameTab />;
}

export default LegacyToNextLink;
