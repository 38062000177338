import React from 'react';
import classNames from 'classnames';
import ListItem from './ListItem';

interface Props {
  items?: string[];
  className?: string;
  icon?: string;
  children?: React.ReactNode;
  color?: 'primary' | 'gray' | 'secondary';
  small?: boolean;
  separated?: boolean;
  bulleted?: boolean;
}

function List({ items, small, className, children, icon, color = 'primary', separated, bulleted }: Props) {
  const listStyles = classNames(
    'list',
    `list--${color}`,
    {
      'list--small': small,
      'list--separator': separated,
      'list--bulleted': bulleted,
    },
    className
  );

  return (
    <ul className={listStyles}>
      {items
        ? items.map((item) => (
            <ListItem key={item} icon={icon}>
              {item}
            </ListItem>
          ))
        : children}
    </ul>
  );
}

export default List;
