import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loading from '@/components/loading/Loading';
import Icon from '@/components/icon/Icon';
import Anchor from '@/components/link/Anchor';
import AppContext from '../appContext';

class Button extends React.PureComponent {
  showIcon(after = false) {
    const { icon, inlineIcon, loading } = this.props;

    if (icon) {
      const iconStyle = classNames(['btn__icon', 'icon--fw'], {
        'btn__icon--inline': inlineIcon,
        'btn__icon--after': after,
      });
      return <Icon name={icon} className={iconStyle} />;
    }
    return null;
  }

  renderContent = () => {
    const { text, google, suffix, inlineIcon, iconAfter, loading, children } = this.props;

    return (
      <>
        {/*google && (
          <span className="google-icon">
            <img src={GoogleIcon} alt="google icon" />
          </span>
        )*/}

        {!inlineIcon && !iconAfter && this.showIcon()}

        {(text || children) && (
          <span>
            {inlineIcon && !iconAfter && this.showIcon()}
            {children}
            {text}
            {inlineIcon && iconAfter && this.showIcon(true)}
          </span>
        )}
        {!inlineIcon && iconAfter && this.showIcon(true)}

        {suffix && <span className="btn__suffix">{suffix}</span>}
        {loading && <Loading small className="btn__loader" />}
      </>
    );
  };

  render() {
    const {
      primary,
      invertedPrimary,
      secondary,
      transparent,
      link,
      underlined,
      light,
      outline,
      small,
      extraSmall,
      large,
      extended,
      disabled,
      loading,
      onClick,
      text,
      className,
      icon,
      style,
      to,
      href,
      fileUpload,
      getFiles,
      newTab,
      google,
      facebook,
      twitter,
      draugiem,
      seb,
      regular,
      highlightOnFocus,
      inactive,
      children,
      newsShare,
      // remove 'inlineIcon' parameter from props passed to button
      inlineIcon,
      iconAfter,
      ...btnProps
    } = this.props;

    const btnStyle = classNames(
      'btn',
      {
        'btn--primary': primary,
        'btn--primary-inv': invertedPrimary,
        'btn--secondary': secondary,
        'btn--transparent': transparent,
        'btn--link': link,
        'btn--underlined': underlined,
        'btn--light': light,
        'btn--outline': outline,
        'btn--inactive': inactive,
        'btn--small': small,
        'btn--extra-small': extraSmall,
        'btn--large': large,
        'btn--extended': extended,
        'btn--disabled': disabled && !loading,
        'btn--google': google,
        'btn--fb': facebook,
        'btn--twitter': twitter,
        'btn--draugiem': draugiem,
        'btn--seb': seb,
        'btn--icon': !text && !children,
        'btn--highlight-on-focus': highlightOnFocus,
        'btn--loading': loading,
        'btn--regular': regular,
        'btn--news-share': newsShare,
      },
      className
    );

    if (to) {
      return (
        <AppContext.Consumer>
          {({ Link }) => (
            <Link
              style={style}
              className={btnStyle}
              href={to}
              onClick={onClick}
              target={newTab && '_blank'}
              rel={newTab && 'noopener noreferrer'}
            >
              {this.renderContent()}
            </Link>
          )}
        </AppContext.Consumer>
      );
    }
    if (href) {
      return (
        <Anchor href={href} style={style} className={btnStyle} onClick={onClick} sameTab={!newTab}>
          {this.renderContent()}
        </Anchor>
      );
    }
    if (fileUpload) {
      return (
        <div className={btnStyle}>
          {this.renderContent()}
          <input type="file" onChange={getFiles} />
        </div>
      );
    }
    return (
      // eslint-disable-next-line react/button-has-type
      <button disabled={disabled || loading} {...btnProps} style={style} className={btnStyle} onClick={onClick}>
        {this.renderContent()}
      </button>
    );
  }
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};
Button.defaultProps = {
  type: 'button',
};

export default Button;
