import React from 'react';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';

import { TOTAL_FLOORS } from '@/constants/attributes';
import SimpleCommonObject from './SimpleCommonObject';

function SimpleModularHouse({ object, ...props }) {
  const getAttrValue = (type) => object.getIn(['attributes', type]);

  return (
    <SimpleCommonObject
      {...props}
      object={object}
      getAttrValue={getAttrValue}
      modularHouse
      projectName={object.get('project_name')}
      minPrice={object.get('price_min')}
      maxPrice={object.get('price_max')}
      sizeMin={object.get('size_min')}
      sizeMax={object.get('size_max')}
      roomCountMin={object.get('room_count_min')}
      roomCountMax={object.get('room_count_max')}
      floors={object.getIn(['attributes', TOTAL_FLOORS])}
    />
  );
}

SimpleModularHouse.propTypes = {
  object: PropTypes.instanceOf(Collection.Keyed).isRequired,
};
// SimpleModularHouse.defaultProps = {};

export default SimpleModularHouse;
