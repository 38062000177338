import { freeze } from 'immer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Article, Tag } from '@city24/common/types/News';
import { partition } from '@city24/common/utils/array';

import hydrateAction from '@/actions/hydrateAction';
import { formatDate } from '@/utils/formatting';
import { DEFAULT_TAG } from '@/constants/news';
import { OpenDaysDetails } from '@/api/openDaysApi';

function mapTag(tag: Tag) {
  return {
    name: tag.name,
    value: tag.url_format,
    featuredTag: tag.featured_tag,
  };
}

function mapArticle(article: Article | null) {
  if (!article || !article.global_id) {
    return {} as typeof remappedArticle;
  }
  const remappedArticle = {
    ...article,
    picture: article.main_image,
    date: article.publish_date ? formatDate(article.publish_date) : '',
    isoDate: article.publish_date,
    tags: article.news_tag_links.map((tag) => ({
      ...mapTag(tag.tag),
      priorityTag: tag.priority_tag,
    })),
    hasGallery: article.has_gallery,
    hasOpenDays: article.has_open_days,
    hasVideo: article.has_video,
    keywords: [],
    author: {
      name: article.author ? article.author.name : '',
      title: article.author_description,
    },
  };

  return remappedArticle;
}

function mapShortArticle(article: Article) {
  if (!article || !article.global_id) {
    return {};
  }
  return {
    global_id: article.global_id,
    slug: article.slug,
    title: article.title,
    picture: article.main_image,
  };
}

export type ArticleInterface = ReturnType<typeof mapArticle>;
export type SmallArticleInterface = ReturnType<typeof mapShortArticle>;
export type TagInterface = ReturnType<typeof mapTag>;

const initialState: {
  articles: ArticleInterface[];
  totalArticles: number;
  editorPickArticles: SmallArticleInterface[];
  similarArticles: SmallArticleInterface[];
  popularArticles: SmallArticleInterface[];
  financeCenterArticles: ArticleInterface[];
  prevNextArticles: SmallArticleInterface[];
  objectsInArticle: { [key: string]: unknown };
  openDaysInArticle: { [key: string]: unknown };
  article: ArticleInterface;
  mainPageLatestArticles: { articles: ArticleInterface[]; total: number };
  contentMarketingArticles: ArticleInterface[];
  newDevArticles: ArticleInterface[];
  tags: TagInterface[];
  tag: TagInterface;
} = {
  articles: [],
  totalArticles: 0,
  editorPickArticles: [],
  similarArticles: [],
  popularArticles: [],
  financeCenterArticles: [],
  prevNextArticles: [],
  objectsInArticle: {},
  openDaysInArticle: {},
  article: {} as ArticleInterface,
  mainPageLatestArticles: { articles: [], total: 0 },
  contentMarketingArticles: [],
  newDevArticles: [],
  tags: [],
  tag: {} as TagInterface,
};

interface NewsListResult {
  articles: Article[];
  totalItems: number;
}

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    loadMoreNews(state, action: PayloadAction<NewsListResult>) {
      const { articles, totalItems } = action.payload;
      state.articles.push(...articles.map(mapArticle));
      state.totalArticles = totalItems;
    },
    getNews(state, action: PayloadAction<NewsListResult>) {
      const { articles, totalItems } = action.payload;
      state.articles = freeze(articles.map(mapArticle));
      state.totalArticles = totalItems;
    },
    getEditorPickArticles(state, action: PayloadAction<Article[]>) {
      state.editorPickArticles = freeze(action.payload.map(mapShortArticle));
    },
    getSimilarArticles(state, action: PayloadAction<Article[]>) {
      state.similarArticles = freeze(action.payload.map(mapShortArticle));
    },
    getPopularArticles(state, action: PayloadAction<Article[]>) {
      state.popularArticles = freeze(action.payload.map(mapShortArticle));
    },
    getArticle(state, action: PayloadAction<Article | null>) {
      state.article = freeze(mapArticle(action.payload));
    },
    getPrevNextArticles(state, action: PayloadAction<Article[]>) {
      state.prevNextArticles = freeze(action.payload.map(mapShortArticle));
    },
    getTags(state, action: PayloadAction<Tag[]>) {
      const sortedTags = partition(action.payload, (tag) => (tag.featured_tag ? 0 : 1))
        .flat(1)
        .map(mapTag);
      sortedTags.unshift(DEFAULT_TAG);
      state.tags = freeze(sortedTags);
    },
    getTag(state, action: PayloadAction<Tag>) {
      state.tag = freeze(mapTag(action.payload));
    },
    setObjectsForArticle(state, action: PayloadAction<{ reset?: boolean }>) {
      if (action.payload.reset) {
        state.objectsInArticle = freeze({});
        state.openDaysInArticle = freeze({});
      }
    },
    addObjectForArticle(state, action: PayloadAction<any>) {
      state.objectsInArticle[action.payload.friendly_id] = action.payload;
    },
    addOpenDaysForArticle(state, action: PayloadAction<{ friendlyId: string; openHouseDetails: OpenDaysDetails }>) {
      const { friendlyId, openHouseDetails } = action.payload;
      state.openDaysInArticle[friendlyId] = freeze(openHouseDetails);
    },
    getMainPageLatestNews(state, action: PayloadAction<{ articles: Article[]; total: number }>) {
      const { articles, total } = action.payload;
      state.mainPageLatestArticles.articles = freeze(articles.map(mapArticle));
      state.mainPageLatestArticles.total = total;
    },
    getContentMarketingNews(state, action: PayloadAction<Article[]>) {
      state.contentMarketingArticles = freeze(action.payload.map(mapArticle));
    },
    getFinanceCenterNews(state, action: PayloadAction<Article[]>) {
      state.financeCenterArticles = freeze(action.payload.map(mapArticle));
    },
    getNewDevNews(state, action: PayloadAction<Article[]>) {
      state.newDevArticles = freeze(action.payload.map(mapArticle));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrateAction, (state, action) => {
      const { news } = action.payload;
      state.articles = news.articles;
      state.totalArticles = news.totalArticles;
      state.tags = news.tags;
      state.editorPickArticles = news.editorPickArticles;
      state.popularArticles = news.popularArticles;
      state.tag = news.tag;
      state.article = news.article;
      state.openDaysInArticle = news.openDaysInArticle;
      state.objectsInArticle = news.objectsInArticle;
    });
  },
});

export const { actions: newsActions } = newsSlice;

export default newsSlice.reducer;
