import { defaultLocale } from '@city24/common/enums/Language';
import { MESSAGE_BROKER_ERROR } from '@/constants/actions';
import { GA_SEND_MESSAGE } from '@/constants/ga';
import { ViolationError } from '@/errors/app';
import { BadRequestError, httpErrorfromResponse } from '@/errors/http';
import type { AppDispatch } from '@/store';

import api from '@/api/City24Api';
import { ContactRequestPayload } from '@/types/api';

import { openModal } from '@/components/modals/modalReducer';
import { ModalName } from '@/components/modals/modalInterfaces';

export function sendMessage(payload: ContactRequestPayload) {
  return (dispatch: AppDispatch) => {
    const confirmModal = openModal({
      name: ModalName.SimpleConfirm,
      title: 'messageBrokerConfirmModal.title',
      gaCat: GA_SEND_MESSAGE,
    });
    return api
      .sendContactRequest(payload.objectType, payload.id, {
        contact_type: payload.contactType,
        broker: payload.brokerId,
        sender_name: payload.userFullName,
        sender_email: payload.userEmail,
        sender_phone: payload.userPhone,
        message: payload.message,
        token: payload.token,
        object_identifier: payload.objectIdentifier,
        price_offer: payload.priceOffer,
        rooms: payload.rooms,
        portal: PORTAL,
        locale_code: defaultLocale[payload.language],
      })
      .then((res) => {
        if (res.ok) {
          return dispatch(confirmModal);
        }
        return httpErrorfromResponse(res).then((err) => {
          if (err instanceof BadRequestError) {
            throw ViolationError.fromBadRequestError(err);
          }
          throw err;
        });
      })
      .catch((err) => {
        // Pretend it's working fine
        dispatch(confirmModal);
        dispatch({
          type: MESSAGE_BROKER_ERROR,
          error: (err instanceof ViolationError && 'violation') || 'error',
          violations: err.violations,
        });
      });
  };
}
