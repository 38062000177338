import { Map } from 'immutable';

export const PROPERTY_IMPRESSION = 0; //	Object page view in desktop
export const LEAD_CONTACT_LINK = 1; //	agent/owner contact request from site user(desktop+mobile) //E-mail
export const LEAD_REQUEST_VIEWING = 2; //	News article view
export const LEAD_REQUEST_BROCHURE = 3; //	Not used
export const LOGO_CLICKS = 4; //	Not used
export const SAVED_ALERTS = 5; //	Not used
export const SEND_TO_FRIEND = 6; //	Not used
export const PRICE_OFFER = 7; //	Not used
export const PROPERTY_IMPRESSION_MOBILE = 8; //	Object view page in mobile site
export const SAVE_FAVORITE = 9; //	Save favorite action
export const REMOVE_FAVORITE = 10; //	Remove favorite action
export const AGENT_SHOW_PHONE = 11; //	show agent phone number in business card action
export const AGENT_SHOW_CONTACT_DETAILS = 12; //	show agent details action in search result page

//  View types
export enum STATISTICS_VIEW {
  graph,
  table,
  messages,
}

export const STATISTICS_VIEW_OPTIONS = [
  Map({ icon: 'graph', name: 'statistics.graph', value: STATISTICS_VIEW.graph }),
  Map({ icon: 'menu', name: 'statistics.table', value: STATISTICS_VIEW.table }),
  Map({ icon: 'envelope', name: 'statistics.label.messages', value: STATISTICS_VIEW.messages }),
] as const;

export const DEFAULT_ACTIVE_STATS = new Set(['impressions', 'pageVisits', 'favourites', 'messages']);

export const STATISTICS_PERIODS = [
  Map({ name: 'statistics.daysCount', value: 30, count: 30 }),
  Map({ name: 'statistics.monthsCount', value: 90, count: 3 }),
  Map({ name: 'statistics.monthsCount', value: 180, count: 6 }),
] as const;

//  Max stat count per page
export const STATS_MAX_PER_PAGE = 20;

export const GRAPH_COLORS = [
  { stroke: '#9c27b0', fill: '#9c27b0' },
  { stroke: '#3f51b5', fill: '#3f51b5' },
  { stroke: '#03a9f4', fill: '#81d4fa' },
  { stroke: '#009688', fill: '#80cbc4' },
  { stroke: '#8bc34a', fill: '#8bc34a' },
  { stroke: '#ffeb3b', fill: '#ffeb3b' },
  { stroke: '#ff9800', fill: '#ff9800' },
  { stroke: '#795548', fill: '#bcaaa4' },
  { stroke: '#607d8b', fill: '#b0bec5' },
  { stroke: '#f44336', fill: '#ef9a9a' },
] as const;
