import React, { useContext } from 'react';
import AppContext from '@/components/appContext';
import css from './Breadcrumbs.module.scss';

export interface BreadcrumbProps {
  children: React.ReactNode;
  to: string;
  name?: string; // used for generating JSONLD
}

const Breadcrumb = ({ children, to, name }: BreadcrumbProps): React.ReactElement => {
  const { Link } = useContext(AppContext);
  if (to) {
    return (
      <Link href={to} className={css.breadcrumb} title={name}>
        {children}
      </Link>
    );
  }
  return <div className={css.breadcrumb}>{children}</div>;
};

export default Breadcrumb;
