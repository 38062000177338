import React from 'react';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import Anchor from './Anchor';

import { LinkComponent } from './LinkInterface';

const LegacyLink: LinkComponent = ({ href, children, locale, ...props }) => {
  let actualHref = href as string;
  if (typeof href !== 'string') {
    actualHref = href.query ? `${href.pathname}?${stringify(href.query)}` : href.pathname;
  }
  if (locale) {
    actualHref = `/${locale}${actualHref}`;
  } else {
    actualHref = actualHref;
  }

  if (/^(\/(en|et|ru|lv))?\/real-estate-news(\/|$)/.test(actualHref)) {
    // Matches routes that are served by NextJS without changing to anchor links in the legacy side
    return (
      <Anchor href={actualHref} sameTab {...props}>
        {children}
      </Anchor>
    );
  }

  return (
    <Link to={actualHref} {...props}>
      {children}
    </Link>
  );
};

export default LegacyLink;
