import React from 'react';
import { Collection, isImmutable } from 'immutable';
import { TFunction } from 'next-i18next';

import { identity } from '@city24/common/utils';
import { NameValue } from '@/types/collections';
import Radio from './Radio';

type SelectedOptionImmut = Collection.Keyed<string, any>;

interface Props {
  selected: SelectedOptionImmut | NameValue;
  options: Collection.Indexed<SelectedOptionImmut> | NameValue[];
  getValue: (selected: Props['selected']) => void;
  name: string;
  t?: TFunction;
  gaEvent?: (a: string) => void;
  luminor?: boolean;
}

function RadioGroup({ options, name, t = identity, luminor, selected, getValue, gaEvent }: Props) {
  const getRadioCheck = (check: boolean, value: string) => {
    if (check) {
      gaEvent?.(value);
      const selected = options.find((option) => {
        if (isImmutable(option)) {
          return option.get('value') === value;
        }
        return option.value === value;
      });

      getValue(selected!);
    }
  };

  const selectedValue = isImmutable(selected) ? selected.get('value') : selected.value;

  return (
    <div className="radio-group">
      {options.map((option, i) => {
        let opt = option as NameValue;
        if (isImmutable(option)) {
          opt = { value: option.get('value'), name: option.get('name') };
        }
        return (
          <div key={i} className="form-field">
            <div className="form-field__flex">
              <Radio
                id={`${opt.value}-${i}`}
                value={opt.value}
                checked={opt.value === selectedValue}
                name={name}
                getValue={getRadioCheck}
                luminor={luminor}
              />
              <label htmlFor={`${opt.value}-${i}`}>{t(opt.name)}</label>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RadioGroup;
