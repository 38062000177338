import React from 'react';
import ReactGA from '@/utils/ga';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { formatPrice } from '@city24/common/utils/formatting';
import { isImmutable } from 'immutable';

import { getUserFullName } from '@/utils/contacts';
import { validateEmail } from '@/utils/validation';
import { identity, stripHTML } from '@/utils/helpers';
import { GA_SEND_MESSAGE } from '@/constants/ga';
import { getUser } from '@/selectors/userSelectors';
import { getLanguage, getPrefixedRoutes } from '@/selectors/appSelectors';
import { sendMessage as postMessage } from './ContactBrokerFormActions';

import Alert from '@/components/alert/Alert';
import Input from '@/components/input/Input';
import TextArea from '@/components/textArea/TextArea';
import Button from '@/components/button/Button';
import ButtonGroup from '@/components/button/ButtonGroup';
import Recaptcha from '@/components/Recaptcha/Recaptcha';
import CheckboxField from '@/components/checkbox/CheckboxField';
import Rooms from '@/components/search/Rooms/Rooms';
import LegacyToNextLink from '@/components/link/LegacyToNextLink';

function gaEventHandler(action, label) {
  ReactGA.event({
    category: GA_SEND_MESSAGE,
    action,
    label,
  });
}

export const CONTACT_BROKER_LAYOUT = {
  BUSINESS_CARD: 'BUSINESS_CARD',
};

class ContactBrokerForm extends React.Component {
  constructor(props) {
    super(props);

    let email = '';
    let phone = '';
    let name = '';

    if (props.user.signedIn) {
      email = props.user.email ?? '';
      phone = props.user.phone ?? '';
      name = props.user.firstName ? getUserFullName(props.user) : '';
    }

    this.state = {
      userEmail: email,
      userPhone: phone,
      userFullName: name,
      priceOffer: '',
      rooms: [],
      userMessage: '',
      objectIdentifier: '',
      emailNotValid: false,
      contactMissing: false,
      messageMissing: false,
      verifyRecaptcha: false,
      verificationError: false,
      token: null,
      loading: false,
      showObjectUrlField: false,
    };
  }

  componentDidMount() {
    this.setState({ userMessage: this.setDefaultMessageText() });
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user.signedIn && !prevProps.user.signedIn) {
      this.setState({
        userFullName: user ? getUserFullName(user) : '',
        userEmail: user.email,
        userPhone: user.phone,
      });
    }
  }

  setDefaultMessageText() {
    const { t, objectFriendlyId, objectPrice, objectLocation: loc, contactType } = this.props;

    if (contactType === 'broker') {
      return t('contactBrokerForm.messageBrokerTemplate');
    }

    if (contactType === 'lead') {
      return t('contactBrokerForm.messageLeadTemplate', { friendlyId: objectFriendlyId });
    }

    const objectLocation = isImmutable(loc) ? loc.toJS() : loc;

    let street = objectLocation.street_name || objectLocation.location_name;
    if (objectLocation.house_number) {
      street = `${street} ${objectLocation.house_number}`;
    }

    return stripHTML(
      t('contactBrokerForm.messageTemplate', {
        friendlyId: objectFriendlyId,
        objectDetailsString: [
          street,
          objectLocation.district_name,
          objectLocation.city_name,
          objectLocation.county_name,
          objectPrice ? formatPrice(objectPrice) : null,
        ]
          .filter(identity)
          .join(', '),
      })
    );
  }

  setToken = (token) => {
    this.setState({ token });
  };

  sendMessage = (verified) => {
    const { objectId, objectGuid, objectUnitType, brokerId, brokerEmail, postMessage, contactType, language } =
      this.props;

    const {
      userFullName,
      userEmail,
      userPhone,
      priceOffer,
      rooms,
      userMessage,
      contactMissing,
      emailNotValid,
      messageMissing,
      token,
      objectIdentifier,
    } = this.state;

    if (verified) {
      if (!(contactMissing || emailNotValid || messageMissing)) {
        postMessage({
          id: objectId,
          guid: objectGuid,
          objectType: objectUnitType,
          contactType: contactType ?? 'contact',
          brokerId,
          brokerEmail,
          userEmail,
          userPhone: userPhone ?? null,
          message: userMessage,
          token,
          objectIdentifier: objectIdentifier ?? null,
          userFullName,
          priceOffer,
          rooms: rooms.map((r) => r.value).join(','),
          language,
        }).finally(() => {
          this.setState({ loading: false });
        });
      }
      this.setState({ verifyRecaptcha: false });
    } else {
      this.setState({ verificationError: true });
    }
  };

  validateForm() {
    const { contactType } = this.props;
    const { userEmail, userPhone, userMessage } = this.state;

    const newState = {
      // contactMissing: (userEmail.length || userPhone.length) < 1,
      emailNotValid: !userEmail.length || !validateEmail(userEmail),
      messageMissing: !userMessage,
    };

    if (!(newState.contactMissing || newState.emailNotValid || newState.messageMissing)) {
      newState.verifyRecaptcha = true;
      newState.loading = true;
    } else {
      newState.verifyRecaptcha = false;
      newState.loading = false;
    }
    this.setState(newState);
    return newState;
  }

  renderErrors() {
    const { t } = this.props;
    const { contactMissing, emailNotValid } = this.state;

    return (
      <>
        {contactMissing && <Alert error text={t('contactBrokerForm.contactMissing')} />}
        {emailNotValid && <Alert error text={t('contactBrokerForm.emailNotValidError')} />}
      </>
    );
  }

  renderLayoutDefault() {
    const { t, preview, contactType, routes } = this.props;
    const {
      userFullName,
      userEmail,
      userPhone,
      priceOffer,
      rooms,
      userMessage,
      objectIdentifier,
      messageMissing,
      verifyRecaptcha,
      verificationError,
      loading,
      showObjectUrlField,
    } = this.state;

    return (
      <ButtonGroup vertical wide>
        <Input
          notched
          label={t('common.yourFullName')}
          value={userFullName}
          getValue={(value) => this.setState({ userFullName: value })}
          gaEvent={() => gaEventHandler('insert_name')}
        />
        <Input
          notched
          label={t('common.yourEmail')}
          type="email"
          value={userEmail}
          getValue={(value) => this.setState({ userEmail: value })}
          gaEvent={() => gaEventHandler('insert_email')}
        />
        <Input
          notched
          label={t('common.yourPhone')}
          value={userPhone}
          getValue={(value) => this.setState({ userPhone: value })}
          gaEvent={() => gaEventHandler('insert_phone')}
        />
        {contactType === 'lead' && (
          <>
            <Input
              notched
              label={t('common.priceOffer')}
              value={priceOffer}
              getValue={(value) => this.setState({ priceOffer: value })}
              gaEvent={() => gaEventHandler('insert_price_offer')}
            />
            <Rooms
              footerHidden
              rooms={rooms}
              label={t('contactBrokerForm.rooms')}
              handleRooms={(value) => this.setState({ rooms: value.toJS() })}
              t={t}
            />
          </>
        )}
        <TextArea
          notched
          label={t('contactBrokerForm.messagePlaceholder')}
          value={userMessage}
          error={messageMissing && t('errors.fillField')}
          getValue={(value) => this.setState({ userMessage: value })}
          gaEvent={() => gaEventHandler('insert_message')}
        />
        {contactType === 'broker' && (
          <CheckboxField
            text={t('contactBrokerForm.hasObjectUrl')}
            id="has-object-url"
            getValue={(value) => {
              this.setState({ showObjectUrlField: value });
            }}
            checked={showObjectUrlField}
          />
        )}
        {showObjectUrlField && (
          <Input
            notched
            label={t('contactBrokerForm.objectIdentifier')}
            value={objectIdentifier}
            getValue={(value) => this.setState({ objectIdentifier: value })}
            gaEvent={() => gaEventHandler('insert_object_identifier')}
          />
        )}
        {contactType === 'lead' && (
          <div>
            {[
              t('contactBrokerForm.personalDataProcessing'),
              ' ',
              <LegacyToNextLink key="privacy" href={routes.privacyRegulations} className="inline-link" target="_blank">
                {t('footer.site.privacyPolicy')}
              </LegacyToNextLink>,
              '.',
            ]}
          </div>
        )}
        <Recaptcha
          verify={verifyRecaptcha}
          forceV2
          skipBackendVerification={this.setToken}
          onVerified={this.sendMessage}
        />
        {verificationError && <Alert error text={t('recaptcha.error')} />}
        <Button
          disabled={preview || loading}
          loading={loading}
          primary
          icon="envelope"
          text={t('broker.sendEmail')}
          onClick={() => {
            gaEventHandler('send_button');
            this.validateForm();
          }}
        />
      </ButtonGroup>
    );
  }

  renderLayoutBusinessCard() {
    const { t, preview, contactType, routes } = this.props;
    const {
      userFullName,
      userEmail,
      userPhone,
      userMessage,
      priceOffer,
      rooms,
      messageMissing,
      verifyRecaptcha,
      verificationError,
      loading,
    } = this.state;
    return (
      <div className="contact__form-helper">
        <div className="b-card__section--left">
          <TextArea
            notched
            label={t('contactBrokerForm.messagePlaceholder')}
            value={userMessage}
            error={messageMissing && t('errors.fillField')}
            getValue={(value) => this.setState({ userMessage: value })}
            gaEvent={() => gaEventHandler('insert_message')}
          />
        </div>
        <div className="b-card__section--right">
          <Input
            notched
            label={t('common.yourFullName')}
            value={userFullName}
            getValue={(value) => this.setState({ userFullName: value })}
            gaEvent={() => gaEventHandler('insert_name')}
          />
          <Input
            notched
            label={t('common.yourEmail')}
            type="email"
            value={userEmail}
            getValue={(value) => this.setState({ userEmail: value })}
            gaEvent={() => gaEventHandler('insert_email')}
          />
          <Input
            notched
            label={t('common.yourPhone')}
            value={userPhone}
            getValue={(value) => this.setState({ userPhone: value })}
            gaEvent={() => gaEventHandler('insert_phone')}
          />
          {contactType === 'lead' && (
            <>
              <Input
                notched
                label={t('common.priceOffer')}
                value={priceOffer}
                getValue={(value) => this.setState({ priceOffer: value })}
                gaEvent={() => gaEventHandler('insert_price_offer')}
              />
              <div className="input__container">
                <Rooms
                  footerHidden
                  rooms={rooms}
                  label={t('contactBrokerForm.rooms')}
                  handleRooms={(value) => this.setState({ rooms: value.toJS() })}
                  t={t}
                />
              </div>
              <div>
                {`${t('contactBrokerForm.personalDataProcessing')} `}
                <LegacyToNextLink
                  key="privacy"
                  href={routes.privacyRegulations}
                  className="inline-link"
                  target="_blank"
                >
                  {t('footer.site.privacyPolicy')}
                </LegacyToNextLink>
                .
              </div>
            </>
          )}
          <Recaptcha
            verify={verifyRecaptcha}
            onVerified={this.sendMessage}
            forceV2
            skipBackendVerification={this.setToken}
          />
          {verificationError && <Alert error text={t('recaptcha.error')} />}
          <Button
            extended
            primary
            disabled={preview || loading}
            loading={loading}
            icon="envelope"
            text={t('broker.sendEmail')}
            onClick={() => {
              gaEventHandler('send_button');
              this.validateForm();
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    const { layout } = this.props;

    const classes = classNames('contact__form', {
      'contact__form-bc': layout === CONTACT_BROKER_LAYOUT.BUSINESS_CARD,
    });

    return (
      <div className={classes}>
        {this.renderErrors()}
        {layout === CONTACT_BROKER_LAYOUT.BUSINESS_CARD ? this.renderLayoutBusinessCard() : this.renderLayoutDefault()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: getUser(state),
    routes: getPrefixedRoutes(state),
    language: getLanguage(state),
  };
}

const mapDispatchToProps = { postMessage };

export default connect(mapStateToProps, mapDispatchToProps)(ContactBrokerForm);
