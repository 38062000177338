import React from 'react';
import PropTypes from 'prop-types';
import { TFunction } from 'i18next';
import { OBJECT_TYPES, SIZE_UNIT_VALUES } from '@/constants/object';
import Icon from '@/components/icon/Icon';
import Tooltip from '@/components/tooltip/Tooltip';

type Props = {
  newProject?: boolean;
  modularHouse?: boolean;
  rooms?: number;
  roomCountMin?: number;
  roomCountMax?: number;
  floor?: number;
  size?: number;
  sizeMin?: number;
  sizeMax?: number;
  sizeUnit?: number;
  objectType?: string;
  lotSize?: number;
  lotSizeUnit?: number;
  video?: boolean;
  virtualTour?: boolean;
  tetAvailability?: number;
  t: TFunction;
};

function ObjectFeatures({
  newProject = false,
  modularHouse = false,
  rooms,
  roomCountMin,
  roomCountMax,
  floor,
  size,
  sizeMin,
  sizeMax,
  sizeUnit,
  objectType,
  lotSize,
  lotSizeUnit,
  video = false,
  virtualTour = false,
  tetAvailability,
  t,
}: Props): React.ReactElement {
  const renderSizeRange = (): React.ReactElement | null => {
    if (sizeMin && sizeMax) {
      return sizeUnit ? (
        <li>{`${sizeMin || '...'} - ${sizeMax || '...'} ${SIZE_UNIT_VALUES[sizeUnit]}`}</li>
      ) : (
        <li>{`${sizeMin || '...'} - ${sizeMax || '...'} m`}&sup2;</li>
      );
    }
    return null;
  };

  const renderSize = (): React.ReactElement | null => {
    if (lotSize && objectType === OBJECT_TYPES.Land) {
      return lotSizeUnit ? <li>{`${lotSize} ${SIZE_UNIT_VALUES[lotSizeUnit]}`}</li> : <li>{`${lotSize} m`}&sup2;</li>;
    }
    if (size) {
      return sizeUnit ? <li>{`${size} ${SIZE_UNIT_VALUES[sizeUnit]}`}</li> : <li>{`${size} m`}&sup2;</li>;
    }
    return null;
  };

  const renderLandSize = (): React.ReactElement | null => {
    if (lotSize && (objectType === OBJECT_TYPES.House || objectType === OBJECT_TYPES.Commercial)) {
      return lotSizeUnit ? (
        <li>
          <Icon name="land" /> {`${lotSize} ${SIZE_UNIT_VALUES[lotSizeUnit]}`}
        </li>
      ) : (
        <li>
          <Icon name="land" /> {`${lotSize} m`}&sup2;
        </li>
      );
    }
    return null;
  };

  const renderMediaIcons = (): React.ReactElement[] => {
    const icons = [];
    if (video) {
      icons.push(
        <li key="video" className="object__media">
          <Icon name="video" />
        </li>
      );
    }
    if (virtualTour) {
      icons.push(
        <li key="virtualTour" className="object__media">
          <Icon name="virtual-tour" />
        </li>
      );
    }
    return icons;
  };

  return (
    <>
      {renderSizeRange()}
      {renderSize()}
      {renderLandSize()}
      {rooms && (
        <li>
          <Icon name="door" /> {`${t('objectSpec.rooms_short', { number: rooms })}`}
        </li>
      )}
      {roomCountMin && roomCountMax && (
        <li>
          <Icon name="door" /> {`${t('objectSpec.rooms_range_short', { min: roomCountMin, max: roomCountMax })}`}
        </li>
      )}
      {floor ? (
        <li>
          <Icon name="stairs" /> {`${floor} ${t('objectSpec.floor_short')}`}
        </li>
      ) : null}
      {renderMediaIcons()}
      {tetAvailability && tetAvailability === 1 && (
        <li>
          <span role="tooltip" data-tooltip-id="tet-tooltip">
            <Icon name="tet-gray" />
          </span>
          <Tooltip id="tet-tooltip" content={t(`objectSpec.tetOptics`) as string} place="top" delayHide={50000} />
        </li>
      )}
    </>
  );
}

ObjectFeatures.propTypes = {
  newProject: PropTypes.bool,
  modularHouse: PropTypes.bool,
  rooms: PropTypes.number,
  roomCountMin: PropTypes.number,
  roomCountMax: PropTypes.number,
  floor: PropTypes.number,
  size: PropTypes.number,
  sizeMin: PropTypes.number,
  sizeMax: PropTypes.number,
  sizeUnit: PropTypes.number,
  objectType: PropTypes.number,
  lotSize: PropTypes.number,
  lotSizeUnit: PropTypes.number,
  video: PropTypes.bool,
  virtualTour: PropTypes.bool,
  t: PropTypes.func,
};

export default ObjectFeatures;
