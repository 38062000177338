import React from 'react';
import ReactGA from '@/utils/ga';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import produce from 'immer';

import { GAE_INSERT_AUTOCOMPLETE_TEXT, GA_SEARCH } from '@/constants/ga';
import { keywordTerm } from '@/utils/searchTerms';
import { getBrowser } from '@/selectors/appSelectors';

import AutocompleteInput from '@/components/input/Autocomplete/AutocompleteInput';
import Button from '@/components/button/Button';
import { setAreaSelectModalOpened } from '@/components/modals/ModalActions';

import { addAutocompleteTerm, doAutocompleteSearch, setSearchTerm } from './SearchActions';
import { getAutocompleteResults, getSearchTerm } from './searchSelectors';

class SearchTerm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { autocompleteValue: '' };
  }

  setAutocompleteValue = (val) => this.setState({ autocompleteValue: val });

  clearAutocompleteValue = () => this.setAutocompleteValue('');

  doAutocomplete = (value) => {
    const { doAutocompleteSearch } = this.props;
    doAutocompleteSearch(value);
    window.scrollTo(0, 0);
  };

  deleteSearchTerm = (key) => {
    const { searchTerms, setSearchTerm } = this.props;
    setSearchTerm(
      produce(searchTerms, (draft) => {
        draft.delete(key);
      })
    );
  };

  onTextSubmit = (searchTerm) => {
    const { searchTerms, setSearchTerm, onTermAdded } = this.props;

    if (searchTerm !== '') {
      const { autocompleteValue } = this.state;
      const value = keywordTerm(autocompleteValue);
      if (autocompleteValue) {
        setSearchTerm(
          produce(searchTerms, (draft) => {
            draft.set(value, { name: autocompleteValue, value, extra: {} });
          })
        );
      }
    }

    if (onTermAdded) onTermAdded(searchTerm);
    this.clearAutocompleteValue();
  };

  onAutocompleteSelect = (value) => {
    const { addAutocompleteTerm, onTermAdded } = this.props;
    addAutocompleteTerm(value);
    if (onTermAdded) onTermAdded(value);
    this.clearAutocompleteValue();
  };

  onAreaSelectClick = () => {
    const { setAreaSelectModalOpened } = this.props;
    setAreaSelectModalOpened(true);
    ReactGA.event({
      category: GA_SEARCH,
      action: 'select_area',
    });
  };

  gaAutoCompleteHandler = (action, label) => {
    ReactGA.event({
      category: GA_SEARCH,
      action: 'autocomplete_area',
      label,
    });
  };

  render() {
    const { main, autocompleteResults, searchTerms, t, browser } = this.props;
    const { autocompleteValue } = this.state;

    return (
      <div className="search__term">
        <AutocompleteInput
          type="text"
          autocomplete="search"
          placeholder={t('search.searchTermPlaceholder')}
          // blur={main && browser.greaterThan.medium}'
          large={main && browser.greaterThan.medium}
          raised={main && browser.greaterThan.medium}
          scrollTo
          scrollOffset={-32}
          // refocus={refocusSearchTerm}
          value={autocompleteValue}
          getValue={this.setAutocompleteValue}
          getSubmit={this.onTextSubmit}
          onAutocomplete={this.doAutocomplete}
          autocompleteResults={autocompleteResults}
          getAutocompleteValue={this.onAutocompleteSelect}
          cancelAutocomplete={this.clearAutocompleteValue}
          tokens={searchTerms}
          deleteToken={this.deleteSearchTerm}
          gaEvent={GAE_INSERT_AUTOCOMPLETE_TEXT}
          gaAutoCompleteHandler={this.gaAutoCompleteHandler}
          t={t}
        >
          <Button
            outline
            className="search__area-select"
            icon="area-select"
            iconAfter
            text={browser.greaterThan.large && t('common.areaSelect')}
            onClick={this.onAreaSelectClick}
          />
        </AutocompleteInput>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    browser: getBrowser(state),
    searchTerms: props.searchTerms || getSearchTerm(state),
    autocompleteResults: getAutocompleteResults(state),
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  const actions = bindActionCreators(
    {
      setSearchTerm,
      doAutocompleteSearch,
      addAutocompleteTerm,
      setAreaSelectModalOpened,
    },
    dispatch
  );
  if (ownProps.setSearchTerm) {
    actions.setSearchTerm = ownProps.setSearchTerm;
  }
  return actions;
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchTerm);
