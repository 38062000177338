import React, { useContext } from 'react';
import { AccordionContext } from './Accordion';

interface Props {
  children: React.ReactNode;
}

function AccordionBody({ children }: Props) {
  const { opened } = useContext(AccordionContext);

  return (
    <div className="accordion__body" hidden={!opened}>
      {children}
    </div>
  );
}

export default AccordionBody;
