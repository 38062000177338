import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import ReactGA from '@/utils/ga';
import Markdown from 'markdown-to-jsx';

import { formatPrice } from '@/utils/formatting';
import { TRANSACTION_TYPES } from '@/constants/object';
import { GA_SEB_CALCULATOR } from '@/constants/ga';
import {
  PERIOD_OPTIONS,
  DEFAULT_INTEREST,
  DEFAULT_SELF_FINANCE,
  SELF_FINANCE_UNIT_OPTIONS,
} from '@/constants/SEBCalculator';

import Input from '@/components/input/Input';
import ButtonGroup from '@/components/button/ButtonGroup';
import Select from '@/components/select/Select';
import InlineSelect from '@/components/select/InlineSelect';
import Button from '@/components/button/Button';

import { computeLoanSale, computeLoanRent } from './SEBCalculatorFunctions';

const propTypes = {
  price: PropTypes.string,
  transactionType: PropTypes.string,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      url: PropTypes.string,
      image: PropTypes.string,
    })
  ).isRequired,
  updateMonthlyPayment: PropTypes.func,
  fetchArticles: PropTypes.func.isRequired,
  calculatorTitle: PropTypes.string,
  infoTitle: PropTypes.string,
  ctaHref: PropTypes.string,
  t: PropTypes.func,
  locale: PropTypes.string,
};

const defaultProps = {
  price: null,
  transactionType: TRANSACTION_TYPES.SALE,
  updateMonthlyPayment: null,
  calculatorTitle: null,
  infoTitle: null,
  ctaHref: null,
  t: (a) => a,
};

class SEBCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      price: props.price,
      period: PERIOD_OPTIONS.find((option) => option.get('default')),
      interest: DEFAULT_INTEREST,
      selfFinance: DEFAULT_SELF_FINANCE,
      selfFinanceUnit: SELF_FINANCE_UNIT_OPTIONS.find((option) => option.get('default')),
      autoSelfFinance: null,
      loanAmount: null,
      monthlyPayment: null,
      commissionFee: null,
      totalAmount: null,
      apr: null,
    };
  }

  componentDidMount() {
    const { fetchArticles, locale } = this.props;
    // fetchArticles(locale); // articles are dropped
    this.calculate();
  }

  componentDidUpdate(prevProps, prevState) {
    const { price, period, interest, selfFinance, selfFinanceUnit } = this.state;
    const { fetchArticles, locale } = this.props;

    if (
      prevState.price !== price ||
      prevState.period.get('value') !== period.get('value') ||
      prevState.interest !== interest ||
      prevState.selfFinance !== selfFinance ||
      prevState.selfFinanceUnit.get('value') !== selfFinanceUnit.get('value')
    ) {
      this.calculate();
    }

    if (locale !== prevProps.locale) {
      // fetchArticles(locale);
    }
  }

  calculate = () => {
    const { transactionType, updateMonthlyPayment } = this.props;
    const { price, period, interest, selfFinance, selfFinanceUnit } = this.state;

    let val;

    if (transactionType === TRANSACTION_TYPES.SALE) {
      val = computeLoanSale(price, period.get('value'), interest, selfFinance, selfFinanceUnit.get('value'));
    } else {
      val = computeLoanRent(price, period.get('value'), interest);
    }

    this.setState({
      autoSelfFinance: val.autoSelfFinance,
      loanAmount: val.loanAmount,
      monthlyPayment: val.monthlyPayment,
      commissionFee: val.commissionFee,
      totalAmount: val.totalAmount,
      apr: val.apr,
      totalPrice: val.totalPrice,
    });

    if (transactionType === TRANSACTION_TYPES.SALE && updateMonthlyPayment) updateMonthlyPayment(val.monthlyPayment);
  };

  gaEventHandler = (action, label) => {
    ReactGA.event({
      category: GA_SEB_CALCULATOR,
      action,
      label,
    });
  };

  render() {
    const { transactionType, articles, t, calculatorTitle, infoTitle, ctaHref } = this.props;
    const {
      price,
      period,
      interest,
      selfFinance,
      autoSelfFinance,
      loanAmount,
      monthlyPayment,
      selfFinanceUnit,
      commissionFee,
      totalAmount,
      apr,
      totalPrice,
    } = this.state;

    return (
      <div className="seb-calc">
        <div className="seb-calc__calc">
          {calculatorTitle && <h3>{calculatorTitle}</h3>}
          <ButtonGroup vertical>
            <Input
              notched
              type="number"
              integer
              label={transactionType === TRANSACTION_TYPES.RENT ? t('loanCalc.monthlyRent') : t('loanCalc.price')}
              getValue={(priceVal) => {
                this.setState({ price: priceVal });
              }}
              value={price === '0' ? '' : parseInt(price, 0)}
              gaEvent={() => this.gaEventHandler('insert_price')}
            />
            {transactionType === TRANSACTION_TYPES.RENT && <p>{t('loanCalc.loanPromo')}</p>}
            <Select
              notched
              native={isMobile}
              label={t('loanCalc.period')}
              options={PERIOD_OPTIONS}
              selected={period}
              translateOption={(option) => t(option.get('name'), { count: parseInt(option.get('value'), 10) })}
              getValue={(newPeriod) => this.setState({ period: newPeriod })}
              t={t}
              gaEvent={(period) => this.gaEventHandler('select_period', period)}
            />

            <Input
              notched
              label={t('loanCalc.interest')}
              type="number"
              step="any"
              unit="%"
              value={interest}
              getValue={(interestVal) => this.setState({ interest: interestVal })}
              gaEvent={() => this.gaEventHandler('insert_interest')}
            />
            {transactionType === TRANSACTION_TYPES.SALE && (
              <ButtonGroup fixed>
                <Input
                  notched
                  type="number"
                  step="any"
                  className="flex-1"
                  label={t('loanCalc.selfFinance')}
                  getValue={(selfFinanceVal) => this.setState({ selfFinance: selfFinanceVal })}
                  value={selfFinance}
                  gaEvent={() => this.gaEventHandler('insert_selfFinance')}
                />
                <InlineSelect
                  className="flex-0"
                  options={SELF_FINANCE_UNIT_OPTIONS}
                  selected={selfFinanceUnit}
                  getValue={(newSelfFinanceUnit) => this.setState({ selfFinanceUnit: newSelfFinanceUnit })}
                  gaCategory={GA_SEB_CALCULATOR}
                  gaAction="select_selfFinance"
                  gaActions={{ eur: 'select_eur', percent: 'select_percent' }}
                />
              </ButtonGroup>
            )}
            {/* <Button seb outline text="Calculate" onClick={this.calculate} /> */}
          </ButtonGroup>
          <div className="seb-calc__result">
            <table>
              {transactionType === TRANSACTION_TYPES.SALE ? (
                <tbody>
                  <tr>
                    <td>{t('loanCalc.selfFinance')}</td>
                    <td>{formatPrice(autoSelfFinance)}</td>
                  </tr>
                  <tr>
                    <td>{t('loanCalc.loanAmount')}</td>
                    <td>{formatPrice(loanAmount)}</td>
                  </tr>
                  {PORTAL_LV && (
                    <>
                      <tr>
                        <td>{t('loanCalc.commision')}</td>
                        <td>{formatPrice(commissionFee)}</td>
                      </tr>
                      <tr>
                        <td>{t('loanCalc.totalAmount')}</td>
                        <td>{formatPrice(totalAmount)}</td>
                      </tr>
                      <tr>
                        <td>{t('loanCalc.apr')}</td>
                        <td>{apr ? `${parseFloat(apr).toFixed(1)} %` : '-'}</td>
                      </tr>
                    </>
                  )}
                  <tr className="seb-calc__main-result">
                    <td>{t('loanCalc.monthlyPayment')}</td>
                    <td>{monthlyPayment ? formatPrice(monthlyPayment) : '-'}</td>
                  </tr>
                </tbody>
              ) : (
                <tr className="seb-calc__main-result">
                  <td>{t('loanCalc.price')}</td>
                  <td>{formatPrice(totalPrice)}</td>
                </tr>
              )}
            </table>
          </div>
          <Button
            href={ctaHref}
            text={t('loanCalc.askForOffer')}
            seb
            extended
            newTab
            onClick={() => this.gaEventHandler('ask_offer_button')}
          />
        </div>
        <div className="seb-calc__info">
          {infoTitle && <h3>{infoTitle}</h3>}
          <div className="seb-calc__articles">
            {articles.map((article, index) => (
              <a
                key={article.global_id}
                href={article.url}
                className="seb-calc__article"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => this.gaEventHandler(`click_news${index + 1}`)}
              >
                <div className="seb-calc__article-img">
                  <img src={article.image} alt="SEB" />
                </div>
                <h4 className="seb-calc__article-title">{article.description}</h4>
              </a>
            ))}
          </div>
          <div className="seb-calc__disclaimer">
            <Markdown>{t('loanCalc.disclaimer.seb')}</Markdown>
          </div>
        </div>
      </div>
    );
  }
}

SEBCalculator.propTypes = propTypes;
SEBCalculator.defaultProps = defaultProps;

export default SEBCalculator;
