/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/nextjs';
import baseApiFetch from '../api';
import config from './config';
import { getUuid } from '../auth';
import auth from '@city24/common/api/city24/auth';

export function apiFetch(endpoint, query = {}, init = {}) {
  const anonToken = auth.getToken('anonToken');
  if (anonToken) {
    init.headers = init.headers || {};
    init.headers['X-Anon-Token'] = anonToken;
  }

  let debugEntry;
  const resp = baseApiFetch(config, endpoint, query, init).then((res) => {
    if (config.debugApiCalls) {
      debugEntry = {
        endpoint,
        status: res.status,
        headers: [...res.headers.values()],
      };
    }

    if (res.status >= 500) {
      config.addError500Count();
      config.hasErrors = true;
    } else if (config.hasErrors) {
      config.hasErrors = false;
      config.addError500Count(true);
    }
    return res;
  });

  resp
    .catch((e) => {
      if (config.debugApiCalls && endpoint.endsWith('/tokens')) {
        Sentry.configureScope((scope) => {
          scope.setTag('endpoint', endpoint);
          scope.setTag('uuid', getUuid());
          scope.setExtra('stack', config.requestStack);
          scope.setExtra('error', e);
          Sentry.captureException(new Error('Api call failed'));
        });
        config.setForceReload();
      }
    })
    .finally(() => {
      if (config.debugApiCalls) {
        if (debugEntry) {
          config.requestStack.push(debugEntry);
        }
      }
    });

  return resp;
}

function validatePaginationParam(query, param, minValue) {
  if (Number.isFinite(query[param])) {
    query[param] = Math.max(minValue, query[param]);
  } else {
    query[param] = undefined;
  }

  return query;
}

/**
 * Add pagination to query using 'page' parameter
 * @param {number} itemsPerPage
 * @param {number} page
 * @param {object} query
 */
export function paginatedQuery(itemsPerPage, page = 1, query = {}) {
  const newQuery = {
    ...query,
    itemsPerPage,
    page,
  };
  validatePaginationParam(newQuery, 'itemsPerPage', 0);
  return validatePaginationParam(newQuery, 'page', 1);
}

/**
 * Add pagination to query using 'from' parameter
 * @param {number} itemsPerPage
 * @param {number} from
 * @param {object} query
 */
export function paginatedFromQuery(itemsPerPage, from = 0, query = {}) {
  const newQuery = {
    ...query,
    itemsPerPage,
    from,
  };
  validatePaginationParam(newQuery, 'itemsPerPage', 0);
  return validatePaginationParam(newQuery, 'from', 0);
}

/**
 * Parse pagination from response
 * @param {Response} res
 */
export function parsePagination(res) {
  if (!res.headers.has('Content-Range')) {
    return { total: 0, start: 0, end: 0 };
  }

  const contentRange = res.headers.get('Content-Range');
  const rangeUnit = res.headers.get('Range-Unit') || 'items';
  const [itemRange, total] = contentRange.replace(rangeUnit, '').trim().split('/');
  const [start, end] = itemRange.split('-');

  return {
    total: total === '*' ? 0 : parseInt(total, 10),
    start: parseInt(start, 10),
    end: parseInt(end, 10),
  };
}

export function cacheReload(init = {}) {
  init.cache = 'reload';
  return init;
}

export function processDatetimeParam(datetime) {
  return /^[0-9]+$/.test(datetime) ? `@${datetime}` : datetime;
}
