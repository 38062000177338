import React from 'react';
import classNames from 'classnames';

import Icon from '@/components/icon/Icon';

import css from './Alert.module.scss';

interface Props {
  text?: React.ReactNode;
  children?: React.ReactNode;
  error?: boolean;
  naked?: boolean;
  className?: string;
}

const Alert = (props: Props) => {
  const { text, children, error, naked, className } = props;

  const alertStyles = classNames(css.alert, { [css.error]: error, [css.naked]: naked }, className);

  return (
    <div className={alertStyles}>
      <Icon name={error ? 'alert' : 'info'} className={css.icon} />
      <div className={css.content}>{text || children}</div>
    </div>
  );
};

export default Alert;
