export function isKeywordTerm(term: string): boolean {
  return term.startsWith('q|');
}
export function keywordTerm(value: string): string {
  return `q|${value}`;
}

export type AddressTypeName = 'county' | 'parish' | 'city' | 'district' | 'street';

export function addressTypeFromLevel(level: number): AddressTypeName {
  switch (level) {
    case 1:
      return 'county';
    case 2:
      return 'parish';
    case 3:
      return 'city';
    case 4:
      return 'district';
    case 7:
    default:
      return 'street';
  }
}

const addressRegex = /^address\.(county|parish|city|district|street|street_house)\|([0-9|]+)$/;

export function isAddressTerm(term: string, type = ''): boolean {
  if (type) {
    return term.startsWith(`address.${type}|`);
  }
  return addressRegex.test(term);
}
export function addressTerm(type: string, value: string): string {
  if (!type) {
    // eslint-disable-next-line no-console
    console.error('Missing type for address term');
  }
  return `address.${type}|${value}`;
}

export function parseAddressTerm(term: string): RegExpMatchArray {
  if (!term) {
    return [];
  }
  const match = term.match(addressRegex);
  if (!match) {
    return [];
  }
  return match;
}
