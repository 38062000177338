import React from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import Icon from '@/components/icon/Icon';
import { getBrowser } from '@/selectors/appSelectors';
import { useAppSelector } from '@/hooks';

interface Props extends ReactModal.Props {
  className?: string;
  children: React.ReactNode;
  fullScreen?: boolean;
  fullScreenMobile?: boolean;
  stickyFooter?: boolean;
  ontop?: boolean;
  fromBottom?: boolean;
  /** @deprecated use onRequestClose for this event */
  gaEvent?: () => void;
}

ReactModal.setAppElement('#__next');

const Modal = (props: Props) => {
  const {
    className,
    children,
    fullScreen,
    fullScreenMobile,
    stickyFooter,
    onRequestClose,
    gaEvent,
    ontop,
    fromBottom,
    ...modalProps
  } = props;
  const browser = useAppSelector(getBrowser);

  const modalStyle = classNames(
    'modal',
    {
      'modal--full-screen': fullScreen,
      'modal--full-screen-mobile': fullScreenMobile && browser.lessThan.medium,
      'modal--sticky-footer': stickyFooter,
    },
    className
  );

  const overlayStyle = classNames('modal__overlay', {
    'modal__overlay--ontop': ontop,
    'modal__overlay--from-bottom': fromBottom,
  });

  const closeModal: typeof onRequestClose = (event) => {
    onRequestClose?.(event);

    if (gaEvent) {
      gaEvent();
    }
  };

  return (
    <ReactModal {...modalProps} onRequestClose={closeModal} className={modalStyle} overlayClassName={overlayStyle}>
      <button type="button" onClick={closeModal} className="modal__close">
        <Icon name="close" />
      </button>
      {children}
    </ReactModal>
  );
};

export default Modal;
