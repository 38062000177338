import { isCurrentlyActiveDate } from '@/utils/date';

// TODO: replace with API integration
export const businessCentresDuration = null;

export function isCampaignRunning(duration) {
  if (duration === null) {
    return false;
  }
  return isCurrentlyActiveDate(duration.startDate, duration.endDate);
}
