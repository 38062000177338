import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const propTypes = {
  responsive: PropTypes.bool,
};

const defaultProps = {
  responsive: false,
};

const VideoPlayer = (props) => {
  const { responsive } = props;

  if (responsive) {
    return (
      <div className="video-player__responsive-wrap">
        <ReactPlayer {...props} className="video-player" />
      </div>
    );
  }
  return <ReactPlayer {...props} className="video-player" />;
};

VideoPlayer.propTypes = propTypes;
VideoPlayer.defaultProps = defaultProps;

export default VideoPlayer;
