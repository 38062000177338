import { CSSProperties } from 'react';

export function fixedBody(isFixed: boolean, suffix?: string) {
  const bodyClasses = document.body.classList;
  let fixedClass = 'body--fixed';
  if (suffix) {
    fixedClass += `-${suffix}`;
  }
  if (isFixed) {
    bodyClasses.add(fixedClass);
  } else if (!suffix) {
    bodyClasses.forEach((cl) => {
      if (cl.includes('body--fixed')) {
        bodyClasses.remove(cl);
      }
    });
  } else {
    bodyClasses.remove(fixedClass);
  }
}

export function hiddenHeader(isHidden: boolean) {
  const bodyClasses = document.body.classList;
  if (isHidden) {
    bodyClasses.add('body--header-hidden');
  } else {
    bodyClasses.remove('body--header-hidden');
  }
}

export function printView(print: boolean) {
  const bodyClasses = document.body.classList;
  if (print) {
    bodyClasses.add('body--print');
  } else {
    bodyClasses.remove('body--print');
  }
}

export function addTransformStyle(style: CSSProperties = {}, value: CSSProperties['transform']) {
  style.transform = value;
  style.msTransform = value;
  style.WebkitTransform = value;
  return style;
}
