import { Dispatch, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import produce, { Draft } from 'immer';

import type { AppDispatch, RootState } from '@/store';
import { AsyncData, createAsyncData } from '@/types/collections';
import { ModalState } from '@/components/modals/modalInterfaces';
import { getModalState, isModalOpen } from '@/selectors/appSelectors';

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useAsyncDataState<T>(initialData: T): [AsyncData<T>, Dispatch<(draft: Draft<AsyncData<T>>) => void>] {
  const [asyncData, setData] = useState(createAsyncData(initialData));
  const produceData = (f: (draft: Draft<AsyncData<T>>) => void) => setData(produce(asyncData, f));
  return [asyncData, produceData];
}

export function useModalState<T = ModalState>(name: ModalState['name']) {
  const modal = useAppSelector(getModalState);

  return {
    open: isModalOpen(modal, name),
    state: modal.state as T,
  };
}
