import React from 'react';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';
import classNames from 'classnames';
import produce from 'immer';
import { createSelector } from 'reselect';

import { getObjectListAttributes, OBJECT_TYPES } from '@/constants/object';
import { sortCompare } from '@/utils/helpers';
import Loading from '@/components/loading/Loading';

import ObjectListItem from './ObjectListItem';
import SimpleObject from '../SimpleObject/SimpleObject';

class ObjectList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sorting: {
        item: 'rooms',
        order: 'asc',
      },
    };

    const getSortedObjects = createSelector(
      (_, state) => state.sorting,
      (props) => props.objects,
      (sorting, objects) => {
        const newObjects = objects.slice();
        newObjects.sort((a, b) => {
          if (a && b) {
            const aType = a.get('unit_type');
            const bType = b.get('unit_type');

            switch (sorting.item) {
              case 'price':
                return sortCompare(a.get('price'), b.get('price'), sorting.order);

              case 'size':
                return sortCompare(
                  a.get(aType === OBJECT_TYPES.Land ? 'lot_size' : 'property_size'),
                  b.get(bType === OBJECT_TYPES.Land ? 'lot_size' : 'property_size'),
                  sorting.order
                );

              case 'rooms':
                return sortCompare(a.get('room_count'), b.get('room_count'), sorting.order);

              case 'floor':
                return sortCompare(a.getIn(['attributes', 'FLOOR']), b.getIn(['attributes', 'FLOOR']), sorting.order);

              case 'apartment':
                return sortCompare(
                  a.getIn(['address', 'apartment_number']),
                  b.getIn(['address', 'apartment_number']),
                  sorting.order
                );

              default:
                break;
            }
          }
          return 0;
        });
        return newObjects;
      }
    );
    this.getSortedObjects = () => getSortedObjects(this.props, this.state);
  }

  handleSort = (item) => {
    this.setState(
      produce((state) => {
        const { sorting } = state;
        if (sorting.item === item) {
          sorting.order = sorting.order === 'desc' ? 'asc' : 'desc';
        } else {
          sorting.item = item;
          sorting.order = 'asc';
        }
      })
    );
  };

  render() {
    const { className, onlyMainFeatures, t, onClick, itemsToShow, routes, newTab, gaEvent } = this.props;

    const { sorting } = this.state;

    const sortedObjects = this.getSortedObjects();
    const objects = (itemsToShow && sortedObjects.slice(0, itemsToShow)) || sortedObjects;
    const attributes = objects[0]
      ? getObjectListAttributes(objects[0].get('unit_type')).filter((o) => !onlyMainFeatures || o.isMainFeature)
      : [];

    return (
      <table className={`object-list ${className}`}>
        <thead>
          {attributes.map((o) => (
            <th key={o.value} onClick={() => this.handleSort(o.value)}>
              {t(o.name)}
              <span
                className={classNames('sorting-arrows', {
                  'sorting-arrows--desc': sorting.item === o.value && sorting.order === 'desc',
                  'sorting-arrows--asc': sorting.item === o.value && sorting.order === 'asc',
                })}
              />
            </th>
          ))}
        </thead>
        <tbody>
          {objects.map((object, i) => {
            if (!object) {
              return <Loading key={i} small />;
            }
            return (
              <SimpleObject
                key={object.get('friendly_id')}
                layout="list"
                ObjectComponent={ObjectListItem}
                attributes={attributes}
                object={object}
                routes={routes}
                onClick={onClick}
                index={i}
                onlyMainFeatures={onlyMainFeatures}
                newTab={newTab}
                t={t}
                gaEvent={gaEvent}
              />
            );
          })}
        </tbody>
      </table>
    );
  }
}

ObjectList.propTypes = {
  objects: PropTypes.arrayOf(PropTypes.instanceOf(Collection.Keyed)).isRequired,
  className: PropTypes.string,
  onlyMainFeatures: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  gaEvent: PropTypes.func,
  itemsToShow: PropTypes.number,
  routes: PropTypes.shape({
    projectDetail: PropTypes.string,
    modularHouseDetail: PropTypes.string,
    objectDetail: PropTypes.string,
  }).isRequired,
};

ObjectList.defaultProps = {
  className: '',
  onlyMainFeatures: false,
  itemsToShow: undefined,
  onClick: () => {},
  gaEvent: () => {},
};

export default ObjectList;
