// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://365a5b3b2b184444a8463848a6082600@sentry.efadm.eu/4',
  // TODO: May need to adjust the rate on production
  tracesSampleRate: DEBUG || process.env.NEXT_PUBLIC_APP_ENV === 'development' ? 1.0 : 0.25,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  debug: DEBUG,
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'Failed to fetch',
    'NetworkError when attempting to fetch resource.',
    'toggleFavButton is not defined',
    'Failed to initialize WebGL.',
    'cancelled',
    "Can't find variable: _AutofillCallbackHandler",
  ],
  // Ignore garbage errors from ad scripts
  denyUrls: [/https:\/\/ao\.city24\.lv/, /https:\/\/.*\.adocean\.pl/, /https:\/\/.*\.cookielaw.org/],
  normalizeDepth: 8,
});
