/* eslint-disable react/no-danger */
import React from 'react';

interface Props {
  inDescription?: boolean;
  largeSlogan?: boolean;
  className?: string;
  children: string;
}

function Slogan({ inDescription, largeSlogan, className: overrideClassName, children }: Props): React.ReactElement {
  if (inDescription) {
    const className = overrideClassName ?? 'object-description__slogan';
    return largeSlogan ? (
      <h2 className={className} dangerouslySetInnerHTML={{ __html: children }} />
    ) : (
      <h4 className={className} dangerouslySetInnerHTML={{ __html: children }} />
    );
  }
  return <div className={overrideClassName ?? 'object__slogan'} dangerouslySetInnerHTML={{ __html: children }} />;
}

export default Slogan;
