import React from 'react';
import PropTypes from 'prop-types';

import ReactGA from '@/utils/ga';
import Input from '../../input/Input';
import FilterFooter from '../Filter/FilterFooter';
import InputRange from '../../input/InputRange';
import { GA_SEARCH } from '@/constants/ga';

const propTypes = {
  footerHidden: PropTypes.bool,
  minSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSizeRange: PropTypes.func,
  handleMinSize: PropTypes.func,
  handleMaxSize: PropTypes.func,
};

const defaultProps = {
  footerHidden: false,
  minSize: '',
  maxSize: '',
  setSizeRange: null,
  handleMinSize: null,
  handleMaxSize: null,
};

const SizeRange = (props) => {
  const { minSize, maxSize, handleMinSize, handleMaxSize, t } = props;

  return (
    <div className="filter__price-range">
      <h4 className="filter__heading">{t('search.size')}</h4>
      <InputRange>
        <Input
          notched
          label="min"
          value={minSize}
          type="number"
          unit="m²"
          getValue={handleMinSize}
          gaEvent={() =>
            ReactGA.event({
              category: GA_SEARCH,
              action: 'select_size',
              label: 'rangeMin',
            })
          }
        />
        <Input
          notched
          label="max"
          value={maxSize}
          type="number"
          unit="m²"
          getValue={handleMaxSize}
          gaEvent={() =>
            ReactGA.event({
              category: GA_SEARCH,
              action: 'select_size',
              label: 'rangeMax',
            })
          }
        />
      </InputRange>
    </div>
  );
};

SizeRange.propTypes = propTypes;
SizeRange.defaultProps = defaultProps;

export default SizeRange;
