import React from 'react';
import { withRouter } from 'react-router-dom';
import { fixedBody, hiddenHeader } from '../../utils/styles';

function resetBodyAndHeader() {
  fixedBody(false);
  hiddenHeader(false);
}

class ScrollToTop extends React.Component {
  constructor(props) {
    super(props);

    this.scrollPositions = [];
    this.scroll = 0;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  componentDidUpdate(prevProps) {
    const { history, location } = this.props;

    const currentPage = location.state?.page ?? null;

    if (location.pathname !== prevProps.location.pathname) {
      if (history.action === 'PUSH') {
        if (currentPage === null) {
          window.scrollTo(0, 0);
        }
        this.scrollPositions.push(this.scroll);
        resetBodyAndHeader();
      }
      if (history.action === 'POP') {
        window.scrollTo(0, this.scrollPositions.pop() || 0);
        resetBodyAndHeader();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e) => {
    this.scroll = window.pageYOffset;
  };

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
