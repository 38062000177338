import React, { useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import ReactGA from '@/utils/ga';
import { TFunction } from 'next-i18next';

import { GA_REPORT_AD, GAL_REPORT_AD_REASON } from '@/constants/ga';
import { validateEmail } from '@/utils/validation';

import { useAppDispatch, useAppSelector, useModalState } from '@/hooks';
import { getUser } from '@/selectors/userSelectors';
import { getObjectDetailsJS } from '@/components/object/objectDetailSelectors';

import Button from '@/components/button/Button';
import Input from '@/components/input/Input';
import TextArea from '@/components/textArea/TextArea';
import ButtonGroup from '@/components/button/ButtonGroup';
import RadioGroup from '@/components/radio/RadioGroup';
import Alert from '@/components/alert/Alert';
import Recaptcha from '@/components/Recaptcha/Recaptcha';
import SuccessIcon from '@/img/icons/success.svg';

import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import { NameValue } from '@/types/collections';
import { sendReport } from './ReportAdModalActions';
import { closeModal } from '../modalReducer';
import { ConfirmModalState, ModalName } from '../modalInterfaces';
import FancySection from '../ModalSections/FancySection';

interface Props {
  t: TFunction;
}

function ReportAdModal({ t }: Props): React.ReactElement {
  const dispatch = useAppDispatch();
  const modal = useModalState<ConfirmModalState>(ModalName.ReportAd);
  const realty = useAppSelector(getObjectDetailsJS);
  const user = useAppSelector(getUser);
  const { confirmed } = modal.state;

  const reasonOptions: NameValue[] = useMemo(
    () => [
      { name: t('reportAdModal.wrongAdDescription'), value: 'wrong_description' },
      { name: t('reportAdModal.fraudulentAd'), value: 'fraud' },
      { name: t('reportAdModal.soldRented'), value: 'sold_rented' },
      { name: t('reportAdModal.other'), value: 'other' },
    ],
    [t]
  );

  const initialFormState = {
    emailNotValid: false,
    messageMissing: false,
    verificationError: false,
    verifyRecaptcha: false,
  };
  const [{ emailNotValid, messageMissing, verificationError, verifyRecaptcha }, setFormState] =
    useState(initialFormState);

  const initialValues = {
    email: user?.email || '',
    reason: reasonOptions[0],
    message: '',
  };
  const [{ reason, message, email }, setValues] = useState(initialValues);

  useEffect(() => {
    setValues({ email: user.email, reason, message });
  }, [user.signedIn]);

  const submitReport = (verified: boolean, token: string) => {
    if (verified) {
      dispatch(sendReport(realty.id, realty.unit_type, email, reason.value, message, token));
    }
    setFormState(
      produce((draft) => {
        draft.verifyRecaptcha = !verified;
      })
    );
  };

  const gaEvent = (action: string, label?: string) => {
    ReactGA.event({
      category: GA_REPORT_AD,
      action,
      label,
    });
  };

  const closeReportAdModal = () => {
    setFormState(initialFormState);
    setValues(initialValues);
    gaEvent('close_reportAd_button');
    dispatch(closeModal());
  };

  const validateReport = () => {
    setFormState(
      produce((draft) => {
        draft.emailNotValid = email.length > 0 && !validateEmail(email);
        draft.messageMissing = !message;
        draft.verifyRecaptcha = !(draft.emailNotValid || draft.messageMissing);
      })
    );
  };

  const renderContent = () => {
    if (confirmed) {
      return (
        <>
          <FancySection
            image={SuccessIcon}
            title={t('reportAdConfirmModal.title')}
            subtitle={t('reportAdConfirmModal.subtitle')}
          />
          <Button primary extended text={t('common.close')} onClick={closeReportAdModal} />
        </>
      );
    }

    return (
      <>
        <div>
          <ModalHeader title={t('reportAdModal.title')} />

          {verificationError && <Alert error text={t('recaptcha.error')} />}
          {emailNotValid && <Alert error text={t('messageBrokerModal.emailNotValidError')} />}

          <ButtonGroup vertical wide>
            <Input
              notched
              label={t('common.yourEmail')}
              value={email}
              getValue={(value: string) => setValues({ email: value, reason, message })}
              gaEvent={() => gaEvent('insert_email')}
            />
            <div>
              <h5 className="modal__section-title">{t('reportAdModal.reason')}</h5>
              <RadioGroup
                options={reasonOptions}
                name="alert-interval"
                selected={reason}
                getValue={(selected) =>
                  setValues({ email, reason: selected as (typeof reasonOptions)[number], message })
                }
                gaEvent={(selectedValue) =>
                  gaEvent('select_reason', GAL_REPORT_AD_REASON[selectedValue as keyof typeof GAL_REPORT_AD_REASON])
                }
              />
            </div>
            <TextArea
              notched
              label={t('reportAdModal.messagePlaceholder')}
              value={message}
              getValue={(value: string) => setValues({ email, reason, message: value })}
              gaEvent={() => gaEvent('insert_extraInformation')}
              error={messageMissing && t('errors.fillField')}
            />
          </ButtonGroup>
          <Recaptcha forceV2 verify={verifyRecaptcha} skipBackendVerification onVerified={submitReport} />
        </div>
        <ModalFooter>
          <ButtonGroup>
            <Button
              transparent
              text={t('common.cancel')}
              onClick={() => {
                gaEvent('close_reportAd_button');
                closeReportAdModal();
              }}
            />
            <Button
              primary
              text={t('reportAdModal.sendReport')}
              onClick={() => {
                gaEvent('send_report_button');
                validateReport();
              }}
            />
          </ButtonGroup>
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal isOpen={modal.open} onRequestClose={closeReportAdModal}>
      {renderContent()}
    </Modal>
  );
}

export default ReportAdModal;
