import { Set, fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { createStatsFilters } from '@/utils/appliedFilters';
import { addFavouriteToObject } from '@/utils/helpers';

import { getFavourites } from '@/selectors/userSelectors';
import { getCertificatesFromBusinessCard } from '@/components/broker/BrokerSearchSelectors';
import { RootState } from '@/store';

export const getOffice = (state: RootState) => state.office.details;

export const getOfficeBusinessCard = createSelector(getOffice, (office) => office.get('business_card'));
export const getOfficeAwards = createSelector(getOffice, (office) => fromJS(office.get('awards')));

export const getBrokersObjectCount = createSelector(
  getOffice,
  (office) => office.getIn(['statistics', 'brokers']) || {}
);

export const getOfficeBrokers = createSelector(getOffice, getBrokersObjectCount, (office, objectCounts) => {
  const brokers = office.get('active_brokers') || [];
  return brokers
    .filter((broker) => objectCounts[broker.id])
    .map((broker) => {
      return {
        ...broker,
        certificates: getCertificatesFromBusinessCard(broker.business_card),
        object_count: objectCounts[broker.id] || 0,
        office: { name: office.get('name') },
      };
    });
});

export const getBrandOptions = createSelector(getOffice, (office) => ({
  active_branding: true,
  color_hex_code: null,
  logo_url: office.getIn(['business_card', 'logo_url']),
  cover_url: office.getIn(['office_ads', 'cover_url']),
}));

export const getBrokerCertificates = createSelector(getOffice, (office) => Set(office.get('certificates')));

export const getOfficeFilterList = createSelector(
  getOffice,
  (office): Partial<ReturnType<typeof createStatsFilters>> => {
    const stats = office.getIn(['statistics', 'properties']);
    if (!stats) {
      return {};
    }
    return createStatsFilters(stats);
  }
);

export const getOfficeObjects = createSelector(
  (state: RootState) => state.office.objects.objects,
  getFavourites,
  (objects, favourites) => {
    if (!objects) {
      return objects;
    }

    return objects.map((object) => addFavouriteToObject(favourites, object));
  }
);

export const getOfficeObjectsCount = (state: RootState) => state.office.objects.total;
