import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { fixedBody } from '../../../utils/styles';

import Dropdown from '../../dropdown/Dropdown';
import Icon from '../../icon/Icon';

function Autocomplete(props) {
  const { showButton, inputValue, t, opened, fullscreen, withKeyboard, insertInputValue, children, placeholderToken } =
    props;

  useEffect(() => {
    if (fullscreen) {
      fixedBody(opened, 'autocomplete');
    }
  }, [opened, fullscreen]);

  if (!opened) {
    return null;
  }

  const autocompleteStyle = classNames('autocomplete', {
    'autocomplete--full': fullscreen,
    'autocomplete--with-keyboard': withKeyboard,
  });

  const element = (
    <div className={autocompleteStyle}>
      {showButton && (
        <button type="button" className="autocomplete__enter" onClick={insertInputValue} value={inputValue}>
          <span>{t('common.insert')}</span>
          <span className="autocomplete__enter-value">{inputValue}</span>
          {placeholderToken && <div>{t(placeholderToken)}</div>}
          <Icon name="angle-right" />
        </button>
      )}
      {children}
    </div>
  );

  return fullscreen ? (
    element
  ) : (
    <Dropdown extended opened>
      {element}
    </Dropdown>
  );
}

Autocomplete.propTypes = {
  showButton: PropTypes.bool,
  opened: PropTypes.bool,
  fullscreen: PropTypes.bool,
  withKeyboard: PropTypes.bool,
  t: PropTypes.func.isRequired,
  insertInputValue: PropTypes.func.isRequired,
  inputValue: PropTypes.node,
  children: PropTypes.node,
};
Autocomplete.defaultProps = {
  showButton: false,
  opened: false,
  fullscreen: false,
  withKeyboard: false,
  inputValue: null,
  children: null,
};

export default Autocomplete;
