import { Collection } from 'immutable';
import { BrokerPublic } from '@city24/common/types/public/RealtyPublic';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';

export enum ModalName {
  BusinessCard = 'businessCard',
  MessageBroker = 'messageBroker',
  MultiBank = 'multiBank',
  PasswordReset = 'passwordReset',
  Register = 'register',
  ReportAd = 'reportAd',
  Share = 'share',
  SignIn = 'signIn',
  SimpleConfirm = 'simpleConfirm',
  InbankCalculator = 'inbankCalculator',
}

export type MessageBrokerModalState =
  | { name: ModalName.MessageBroker; object: Collection.Keyed<string, any>; contactType: 'object' }
  | { name: ModalName.MessageBroker; object: BrokerPublic; contactType: 'broker' };

export interface MultiBankModalState {
  name: ModalName.MultiBank;
  price: string | number;
  unitType: UnitTypeLiteral;
  bank?: string;
  yearBuilt?: number;
}
export interface ShareModalState {
  name: ModalName.Share;
  id: string;
  sharePath: string;
  isRealtyShare: boolean;
}

export interface InbankCalculatorModalState {
  name: ModalName.InbankCalculator;
  loanAmount: number;
  period: number;
  totalAmount: number;
  gpl: number;
}

/** Modal state for simple confirm modal popup */
export interface SimpleConfirmModalState {
  name: ModalName.SimpleConfirm;
  title: string;
  /** Category for GA events */
  gaCat?: string;
}
export interface ConfirmModalState {
  name: ModalName.PasswordReset | ModalName.Register | ModalName.ReportAd;
  confirmed?: boolean;
}
export interface StatelessModal {
  name: ModalName.SignIn | ModalName.BusinessCard;
}

export type ModalState =
  | MessageBrokerModalState
  | MultiBankModalState
  | ShareModalState
  | SimpleConfirmModalState
  | ConfirmModalState
  | StatelessModal
  | InbankCalculatorModalState
  | { name: '' };
