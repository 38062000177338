import React from 'react';
import ReactGoogleLogin, { GoogleLoginProps } from 'react-google-login';
import * as Sentry from '@sentry/nextjs';

import { useAppDispatch } from '@/hooks';
import { signInByAccessToken, signInError } from '@/actions/userActions';

interface Props {
  returnedFromRedirect?: boolean;
  render: GoogleLoginProps['render'];
}

function GoogleLogin({ render, returnedFromRedirect }: Props) {
  const dispatch = useAppDispatch();

  const onSuccess: GoogleLoginProps['onSuccess'] = (response) => {
    if (response.accessToken) {
      dispatch(signInByAccessToken(GoogleLogin.provider, response.accessToken));
    } else {
      // eslint-disable-next-line no-console
      console.error('Failed to get access token', response);
      Sentry.withScope((scope) => {
        scope.setExtras(response as Record<string, any>);
        Sentry.captureMessage(`Google login: Failed to get access token`, 'error');
      });
    }
  };

  const onFailure = (response) => {
    // eslint-disable-next-line no-console
    console.error('Google login', response);
    dispatch(signInError(response.error, GoogleLogin.provider, response));
    Sentry.withScope((scope) => {
      scope.setExtras(response.details);
      Sentry.captureMessage(`Google login: ${response.error}`, 'error');
    });
  };

  return (
    <ReactGoogleLogin
      clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
      redirectUri={window.location.origin}
      isSignedIn={returnedFromRedirect}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy="single_host_origin"
      render={render}
    />
  );
}

GoogleLogin.provider = 'google';

export default GoogleLogin;
