import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { HeadProvider } from 'react-head';
import { enableMapSet } from 'immer';

import auth, { isTokenExpired, parseTokenPayload } from '@city24/common/api/city24/auth';

import { fetchEuriborRate } from '@/actions/calculatorsActions';
import makeStore from './store';
import { i18nInit } from './i18n';
import { LANGUAGES } from './constants';
import { bindError500Count, bindForceReload } from './api/City24Api/config';

import '../sentry.client.config';
import './styles/_main.scss';
import './fonts/c24-icons/style.css';
import './img/logo_EE_bg.png';
import './img/logo_EE_filled.png';
import './img/logo_LV_bg.png';
import './img/icons/star.png';
import './initExternals';

import { signInWithToken, signInWithRefreshToken } from './actions/userActions';

import App from './components/App';
import { appActions } from './reducers/app';

enableMapSet();

let locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
const match = window.location.pathname.match(/^\/(et|en|lv|ru)(\/|$)/);
if (match !== null && LANGUAGES.includes(match[1])) {
  locale = match[1];
}
i18nInit(locale);
const store = makeStore({ locale });

bindError500Count((reset = false) => {
  if (reset) {
    store.dispatch({ type: 'API_RESET_ERROR_COUNT' });
  } else {
    store.dispatch({ type: 'API_ADD_ERROR_COUNT' });
  }
});

bindForceReload(() => {
  store.dispatch(appActions.setForceReload());
});

function signIn() {
  const payload = parseTokenPayload(auth.getToken('token'));
  if (isTokenExpired(payload, 60)) {
    const refreshToken = auth.getToken('refreshToken');
    if (refreshToken) {
      return store.dispatch(signInWithRefreshToken(refreshToken));
    }
  } else if (payload) {
    return store.dispatch(signInWithToken(payload));
  }

  return Promise.resolve();
}

signIn().finally(() => {
  store.dispatch(fetchEuriborRate());

  ReactDOM.render(
    <Provider store={store}>
      <CookiesProvider>
        <HeadProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HeadProvider>
      </CookiesProvider>
    </Provider>,
    document.getElementById('__next')
  );
});
