import React from 'react';
import PropTypes from 'prop-types';
import { Collection, isImmutable } from 'immutable';
import List from '../List';
import ListItem from '../ListItem';

const awardIcon = (type) => {
  switch (type) {
    case 'HIGHLY_CERTIFIED':
      return 'star-o';
    case 'BROKER_OF_THE_YEAR':
      return 'cup';
    default:
      return 'cup';
  }
};

const AchievementsList = ({ t, awards, certificates, dealTypes }) => {
  return (
    <List color="secondary">
      {awards && (awards.size > 0 || awards.length > 0)
        ? awards.map((award) => {
            let type = award.certification_type || award.certificationType;
            let { year } = award;
            if (isImmutable(award)) {
              type = award.get('certification_type') || award.get('certificationType');
              year = award.get('year');
            }
            if (!type) return null;
            return (
              <ListItem icon={awardIcon(type)} className="a" key={type + year}>
                {t(`broker.awards.${type}`, { year })}
              </ListItem>
            );
          })
        : null}
      {certificates && certificates.length > 0 ? (
        <ListItem icon="badge">
          <div>
            {certificates.map((cert) => {
              if (Array.isArray(cert)) {
                return <div key={cert[0]}>{`${t(`broker.${cert[0]}`)}: ${cert[1]}`}</div>;
              }
              return <div key={cert}>{t(`broker.${cert}`)}</div>;
            })}
          </div>
        </ListItem>
      ) : null}
      {dealTypes && <ListItem icon="key">{dealTypes}</ListItem>}
    </List>
  );
};
AchievementsList.propTypes = {
  t: PropTypes.func.isRequired,
  awards: PropTypes.instanceOf(Collection),
  certificates: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
  dealTypes: PropTypes.string,
};
AchievementsList.defaultProps = {
  certificates: [],
  awards: null,
  dealTypes: null,
};

export default AchievementsList;
