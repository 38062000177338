import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getPrefixedRoutes, getSignedIn } from '@/selectors/appSelectors';
import { stringify } from '@/utils/queryString';

type Props = {
  path: string;
  children?: React.ReactNode;
  render?: (routeProps: any) => React.ReactNode;
  component?: React.ComponentType;
};

function PrivateRoute({ children = null, render, component: Component, ...props }: Props) {
  const routes = useSelector(getPrefixedRoutes);
  const signedIn = useSelector(getSignedIn);
  const renderContent = (routeProps: any) => {
    if (signedIn) {
      if (render) {
        return render(routeProps);
      }
      if (Component) {
        return <Component {...routeProps} />;
      }

      return children;
    }

    const { location } = routeProps;
    return <Redirect to={PrivateRoute.generateReturnPath(routes.login, location)} />;
  };
  return <Route {...props} render={renderContent} />;
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  render: PropTypes.func,
  component: PropTypes.elementType,
};

PrivateRoute.generateReturnPath = (mainRoute: string, location: Location) => {
  const queryParams = stringify({ backTo: `${location.pathname}${location.search}` });
  return `${mainRoute}?${queryParams}`;
};

export default PrivateRoute;
