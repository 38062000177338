import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

function LazyLoadSensor({ children, placeholder, onChange, onVisible, forceLoad, ...props }) {
  const [wasVisible, setVisible] = useState(false);
  if (forceLoad) {
    return <>{children || placeholder}</>;
  }
  return (
    <VisibilitySensor
      {...props}
      onChange={(visible) => {
        if (visible && !wasVisible) {
          setVisible(true);
          if (onVisible) onVisible();
        }
        if (onChange) onChange(visible);
      }}
    >
      {wasVisible ? children || <></> : placeholder}
    </VisibilitySensor>
  );
}

LazyLoadSensor.propTypes = {
  children: PropTypes.node,
  placeholder: PropTypes.node,
  onChange: PropTypes.func,
  onVisible: PropTypes.func,
  forceLoad: PropTypes.bool,
};
LazyLoadSensor.defaultProps = {
  children: <></>,
  placeholder: <div style={{ height: 1 }} />,
  onChange: undefined,
  onVisible: undefined,
  forceLoad: false,
};

export default LazyLoadSensor;
