import { isImmutable } from 'immutable';
import { RootState } from '@/store';

export const getCertificatesFromBusinessCard = (businessCard) => {
  type Cert =
    | 'certifiedBroker'
    | ['certifiedBroker', string]
    | 'educatedBroker'
    | 'memberOfEcreb'
    | 'memberOfRealEstateAgentsRegistry';
  const certs: Cert[] = [];

  if (!businessCard) return certs;

  if (isImmutable(businessCard)) {
    const cnr = businessCard.get('certificate_number');
    if (cnr) {
      certs.push(['certifiedBroker', cnr]);
    } else if (businessCard.get('certified_broker')) {
      certs.push('certifiedBroker');
    }

    if (businessCard.get('educated_broker')) {
      certs.push('educatedBroker');
    }

    if (businessCard.get('member_of_ecreb')) {
      certs.push('memberOfEcreb');
    }

    if (businessCard.get('member_real_estate_agents_registry')) {
      certs.push('memberOfRealEstateAgentsRegistry');
    }

    return certs;
  }
  const { certificate_number, certified_broker, educated_broker, member_of_ecreb, member_real_estate_agents_registry } =
    businessCard;
  if (certificate_number) {
    certs.push(['certifiedBroker', certificate_number]);
  } else if (certified_broker) {
    certs.push('certifiedBroker');
  }

  if (educated_broker) {
    certs.push('educatedBroker');
  }

  if (member_of_ecreb) {
    certs.push('memberOfEcreb');
  }

  if (member_real_estate_agents_registry) {
    certs.push('memberOfRealEstateAgentsRegistry');
  }

  return certs;
};
export const getBrokerSearch = (state: RootState) => state.broker.search;
export const getVipBroker = (state: RootState) => getBrokerSearch(state).vipBroker;
