import {
  GET_OFFICE_DETAILS,
  GET_OFFICE_STATISTICS,
  GET_OFFICE_OBJECTS,
  RESET_OFFICE_OBJECTS,
} from '@/constants/actions';
import { OBJECT_TYPES } from '@/constants/object';
import api, { apiFetch, paginatedQuery, parsePagination } from '@/api/City24Api';

export function fetchCompanyDetails(id) {
  return (dispatch) => {
    return api
      .getOfficeDetails(id)
      .then((res) => (res.ok ? res.json() : {}))
      .then((office) => {
        const statsIRI = office.statistics;
        office.statistics = {};
        dispatch({
          type: GET_OFFICE_DETAILS,
          details: office,
        });
        if (statsIRI) {
          return apiFetch(statsIRI)
            .then((res) => (res.ok ? res.json() : {}))
            .then((statistics) => {
              dispatch({
                type: GET_OFFICE_STATISTICS,
                statistics,
              });
            });
        }
      });
  };
}

function getCompanyObjectSearchAction(unitType) {
  if (unitType === OBJECT_TYPES.NewProject) {
    return [api.searchProjects, OBJECT_TYPES.NewProject];
  }
  if (unitType === OBJECT_TYPES.ModularHouse) {
    return [api.searchModularHouses, OBJECT_TYPES.ModularHouse];
  }
  if (unitType) {
    return [api.searchObjects, unitType];
  }
  return [api.searchAllObjects, 'realties'];
}

export function fetchCompanyObjects(
  id,
  transactionType = null,
  unitType = null,
  perPage,
  page,
  actionType = GET_OFFICE_OBJECTS
) {
  return (dispatch) => {
    const [action] = getCompanyObjectSearchAction(unitType);
    const query = {
      tsType: transactionType || undefined,
      unitType: unitType || undefined,
      office: id,
    };

    return action(paginatedQuery(perPage, page, query))
      .then((res) => (res.ok ? Promise.all([res.json(), parsePagination(res)]) : [[], {}]))
      .then(([objects, pagination]) => {
        return dispatch({
          type: actionType,
          transactionType,
          unitType,
          objects,
          total: pagination.total || 0,
        });
      });
  };
}

export function resetCompanyObjects() {
  return {
    type: RESET_OFFICE_OBJECTS,
  };
}
