const globalThousandsSeparator = ' ';
const adfromTrackingPoint = 'city24_calc_eng';

// function formatNumber(number) {
//   if (isNaN(number)) {
//     return number;
//   }
//   if (number >= 1000 || number <= -1000) {
//     const parts = RegExp('^(-?\\d{1,3})((\\d{3})+)$').exec(number); //separate begin [$1], middle [$2]
//     if (parts) {
//       //number >= 1000 || number <= -1000
//       return parts[1] + parts[2].replace(/\d{3}/g, globalThousandsSeparator + '$&');
//     } else {
//       return number;
//     }
//   }
//   return number;
// }

function APRPayment(paymentNumber, Amount) {
  this.PaymentNumber = paymentNumber;
  this.Amount = Amount;
}

/* FUNCTION USED FOR CALCULATE APR */
function APR(principle, compoundingPeriods) {
  /**
   * These are the default boundaries for the binary search algorithm.
   */
  const APR_ABS_MIN = -0.999999999999;

  const DEFAULT_MIN_APR = APR_ABS_MIN;

  const DEFAULT_MAX_APR = 2147483647;

  const DEFAULT_PRECISION = 5;

  /**
   * Failsafe, prevents the APR algorithm running away in an infinate loop
   */

  const MAX_ATTEMPTS = 1000;

  /**
   * The balance to finance
   * @const double
   */

  this.Principle = null;

  /**
   * Payments made
   * @const APRPayment[]
   */
  this.PaymentSchedule = [];

  /**
   * Compounding periods per year
   * @const int
   */

  // this.CompoundingPeriods = $('#arlig_betaling').val();
  this.CompoundingPeriods = null;

  /**
   * This determines the accuracy of the overall APR value, unless you need to show more than 2 decimal places
   * it is recommended you leave this at its default, otherwise increase its value
   * @const int
   */

  this.Precision = DEFAULT_PRECISION;

  /**
   *
   * @param float principle
   * @param float compoundingPeriods
   */

  if (principle) {
    this.Principle = principle;
  }
  if (compoundingPeriods) {
    this.CompoundingPeriods = compoundingPeriods;
  }

  /**
   * Adds a payment to the Payment Schedule
   * @param float PaymentNo
   * @param float Amount
   */

  this.AddPayment = function (PaymentNo, Amount) {
    const xx = APRPayment(PaymentNo, Amount);
    this.PaymentSchedule.push(xx);
  };
}

APR.ResolveAPR = (loan) => {
  // This routine uses a binary search to determine the APR value
  let repaymentTotal = 0;
  const APR_ABS_MIN = -0.999999999999;
  const DEFAULT_MIN_APR = APR_ABS_MIN;
  const DEFAULT_MAX_APR = 2147483647;
  const DEFAULT_PRECISION = 5;

  /**
   * Failsafe, prevents the APR algorithm running away in an infinate loop
   */
  const MAX_ATTEMPTS = 1000;

  let bsMinB = DEFAULT_MIN_APR;
  let bsMaxB = DEFAULT_MAX_APR;
  let APRRate = 1;
  let attempts = 0;

  // Added by javed to convert math round in decimal
  const round = (function () {
    const originalRound = Math.round;
    return function (number, precision) {
      precision = Math.abs(parseInt(precision)) || 0;
      const multiplier = Math.pow(10, precision);
      return originalRound(number * multiplier) / multiplier;
    };
  })();
  // end

  while (round(repaymentTotal, loan.Precision) !== loan.Principle) {
    if (attempts >= MAX_ATTEMPTS || (bsMaxB === bsMinB && bsMaxB === APRRate)) {
      console.log('error thrown');
      throw new Error('APR value could not be resolved', 1);
    }
    if (attempts > 1) {
      if (repaymentTotal < loan.Principle) {
        bsMaxB = APRRate;
      } else {
        bsMinB = APRRate;
      }
      APRRate = bsMaxB - (bsMaxB - bsMinB) / 2;
    }

    repaymentTotal = 0;
    // Calculate repayments for the schedule using the approximated rate
    const scrt = loan.PaymentSchedule;

    for (let k = 0; k < scrt.length; k += 1) {
      const PaymentNumber2 = scrt[k].PaymentNumber;
      const divider = loan.CompoundingPeriods;
      const result11 = PaymentNumber2 / divider;
      let rt = Math.pow(parseFloat(1 + APRRate), parseFloat(result11));
      rt = parseFloat(rt).toFixed(13);

      repaymentTotal += scrt[k].Amount / parseFloat(rt);
    }
    attempts++;
  }
  return parseFloat(APRRate * 100).toFixed(DEFAULT_PRECISION);
};

/**
 * Resolves the APR value for a loan
 * @param double principle Balance to finance
 * @param double repayment Amount repayed per month
 * @param int term Number of payments
 * @param double setupFee Additional fee charged on the first month
 * @param double backendFee Additional fee charged on the last payment
 * @param int compoundingPeriods Number of payments made per year
 * @return double APR Value or FALSE if it could not be determined
 */
APR.APR_Simple_Annuity = function (principle, repayment, term, setupFee, backendFee, compoundingPeriods) {
  if (typeof setupFee === 'undefined') setupFee = 0;
  if (typeof backendFee === 'undefined') backendFee = 0;
  if (typeof compoundingPeriods === 'undefined') compoundingPeriods = null;

  const apr = APR.GenerateAnnuity(principle, repayment, term, setupFee, backendFee, compoundingPeriods);

  if (apr) {
    try {
      return APR.ResolveAPR(apr);
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  return false;
};

/**
 * Determines the APR for a loan, it will throw an exception if the APR cannot be resolved.
 * On 32 bit systems the maximum APR this algorithm can genertate is 214,000,000,000% the minimum
 * is -100%
 * @param APR loan
 * @return float
 */

/**
 * Generates a simple Annuity
 * @param double principle Balance to finance
 * @param double repayment Amount repayed per month
 * @param int term Number of payments
 * @param double setupFee Additional fee charged on the first month
 * @param double backendFee Additional fee charged on the last payment
 * @param int compoundingPeriods Number of payments made per year
 * @return APR
 */

APR.GenerateAnnuity = function (principle, repayment, term, setupFee, backendFee, compoundingPeriods) {
  if (typeof setupFee === 'undefined') setupFee = 0;
  if (typeof backendFee === 'undefined') backendFee = 0;
  if (typeof compoundingPeriods === 'undefined') compoundingPeriods = null;

  if (principle > 0 && repayment > 0 && term > 0) {
    const loan = new APR(principle, compoundingPeriods);
    for (let i = 1; i <= term; i += 1) {
      let amount = repayment;
      if (i === 1) amount += setupFee;
      if (i === term) amount += backendFee;

      loan.PaymentSchedule.push(new APRPayment(i, amount));
    }
    return loan;
  }

  return false;
};

// COMPUTE LOAN SALE
export const computeLoanSale = (
  price,
  period,
  interest,
  selfFinance,
  selfFinanceUnit,
  commisionFeePercentage = 1.5,
  minCommissionFee = 0,
  maxCommisionFee = 0,
  extraMonthlyFees = 0,
  hasLoanRegistrationFee = false,
  loanRegistrationFeeAddition = 0
) => {
  let autoSelfFinance;
  let loanAmount;

  if (selfFinanceUnit === 'percent') {
    autoSelfFinance = (price * selfFinance) / 100;
    loanAmount = price - autoSelfFinance;
  } else {
    autoSelfFinance = selfFinance;
    loanAmount = price - selfFinance;
  }

  const interestRate = interest / 12 / 100;
  let ix = 1 + interestRate;
  ix **= period * 12;
  const PMT = (loanAmount * ix * interestRate) / (ix - 1);
  let monthlyPayment = Math.round(PMT * 100) / 100;

  autoSelfFinance = Math.round(autoSelfFinance);
  loanAmount = Math.round(loanAmount);
  const monthlyPaymentDec = monthlyPayment;
  monthlyPayment = Math.round(monthlyPayment);

  // const commissionThreshold = 5000;
  // const valForCommissionLess = 75;

  let commissionFee = (loanAmount * commisionFeePercentage) / 100;
  let additionalFee = 0; // additional fees for APR calculation, but displayed seperatley from commissionFee
  let loanRegistrationFee = 0;

  // if (loanAmount <= commissionThreshold) {
  //   commissionFee = valForCommissionLess;
  // } else {
  //   commissionFee = (loanAmount * commisionFeePercentage) / 100;
  // }

  if (commissionFee < minCommissionFee) {
    commissionFee = minCommissionFee;
  }
  if (maxCommisionFee && loanAmount > maxCommisionFee) {
    commissionFee = maxCommisionFee;
  }

  if (extraMonthlyFees) {
    additionalFee += extraMonthlyFees * 12 * period;
  }

  if (hasLoanRegistrationFee) {
    loanRegistrationFee = Number(loanAmount * 0.001) + Number(loanRegistrationFeeAddition);
  }

  const months = period * 12;
  const totalAmount = months * monthlyPayment + commissionFee + additionalFee + loanRegistrationFee;

  const apr = APR.APR_Simple_Annuity(
    loanAmount,
    monthlyPayment,
    months,
    commissionFee + additionalFee + loanRegistrationFee,
    0,
    12
  );

  return {
    autoSelfFinance,
    loanAmount,
    monthlyPayment,
    monthlyPaymentDec,
    commissionFee,
    totalAmount,
    apr,
    loanRegistrationFee,
    totalInterest: totalAmount - loanAmount,
  };
};

// COMPUTE LOAN RENT
export const computeLoanRent = (price, period, interest, selfFinance, selfFinanceUnit) => {
  const loanLength = period * 12;
  const interestRate = interest / 12 / 100;
  const temp = Math.pow(1 + interestRate, loanLength);
  const totalPrice = (price * (temp - 1)) / (temp * interestRate);

  return { totalPrice };
};
