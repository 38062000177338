import React, { useMemo } from 'react';
import ReactGA from '@/utils/ga';
import { fromJS } from 'immutable';
import { generatePath } from 'react-router-dom';
import classNames from 'classnames';
import type { TFunction } from 'next-i18next';

import { GA_SEND_MESSAGE } from '@/constants/ga';
import { COMING_SOON } from '@/constants/attributes';
import { getPrefixedRoutes } from '@/selectors/appSelectors';
import { useAppDispatch, useAppSelector, useModalState } from '@/hooks';
import { getCertificatesFromBusinessCard } from '@/components/broker/BrokerSearchSelectors';
import { sendStatistics } from '@/actions/statisticsActions';

import { getBrokerName, getContact } from '@/utils/contacts';
import { applyImageFormat } from '@/utils/formatting';

import ContactCard, { CONTACT_CARD_LAYOUT } from '@/components/ContactCard/ContactCard';
import ContactBrokerForm, { CONTACT_BROKER_LAYOUT } from '@/components/contact/ContactBrokerForm/ContactBrokerForm';
import LeadForm from '@/components/LeadForm/LeadForm';
import { closeModal } from '../modalReducer';
import Modal from '../Modal';
import ModalHeader from '../ModalHeader';

import { MessageBrokerModalState, ModalName } from '../modalInterfaces';

import css from './MessageBrokerModal.module.scss';
import StatisticsType from '@city24/common/enums/StatisticsType';

interface Props {
  t: TFunction;
}

// TODO: Refactor "object" and ContactCard when ImmutableJS is removed from caller components
function MessageBrokerModal({ t }: Props) {
  const dispatch = useAppDispatch();
  const routes = useAppSelector(getPrefixedRoutes);
  const modal = useModalState<MessageBrokerModalState>(ModalName.MessageBroker);
  const { object, contactType } = modal.state;

  const onClose = () => {
    gaEvent('close_button');
    dispatch(closeModal());
  };

  const gaEvent = (action: string, label?: string) => {
    ReactGA.event({
      category: GA_SEND_MESSAGE,
      action,
      label,
    });
  };

  const renderBrokerContactForm = () => {
    if (!modal.open) {
      return null;
    }
    if (contactType === 'broker') {
      return (
        <ContactBrokerForm
          t={t}
          objectId={object.id}
          objectGuid={object.slug}
          objectUnitType="broker"
          brokerId={object.id}
          brokerEmail={object.email_address}
          brokerPhone={object.phone_number}
          contactType={contactType}
        />
      );
    }
    return (
      <ContactBrokerForm
        t={t}
        objectId={object.get('id')}
        objectGuid={object.get('guid')}
        objectFriendlyId={object.get('friendly_id')}
        objectUnitType={object.get('unit_type')}
        objectPrice={object.get('price')}
        objectLocation={object.get('address')}
        brokerId={object.getIn(['broker', 'id'])}
        brokerEmail={object.getIn(['broker', 'email_address'])}
        brokerPhone={object.getIn(['broker', 'phone_number'])}
        contactType={contactType}
      />
    );
  };

  const [broker, office, isComingSoonObject] = useMemo(() => {
    let broker;
    let office;
    let isComingSoonObject = false;
    if (contactType === 'broker') {
      broker = fromJS(object);
      office = broker && broker.get('office') ? broker.get('office') : null;
    } else {
      broker = object?.get('broker');
      office = broker?.get('office')?.first();
      isComingSoonObject = object?.hasIn(['attributes', COMING_SOON]);
    }
    return [broker, office, isComingSoonObject];
  }, [object, contactType]);

  const sendPhoneStatistics = (action: StatisticsType) => {
    sendStatistics(action, contactType === 'object' ? object.get('guid') : object.guid);
  };

  const classes = classNames(css.modal, {
    [css.comingSoon]: isComingSoonObject,
  });

  return (
    <Modal className={classes} isOpen={modal.open} onRequestClose={onClose}>
      <div>
        {isComingSoonObject && contactType === 'object' ? (
          <LeadForm object={object} broker={broker} layout={CONTACT_BROKER_LAYOUT.BUSINESS_CARD} />
        ) : (
          <>
            <ModalHeader title={t('common.showInterest')} />
            <div className={css.cardContainer}>
              {broker && (
                <ContactCard
                  t={t}
                  layout={CONTACT_CARD_LAYOUT.VERTICAL}
                  image={applyImageFormat(broker.getIn(['business_card', 'picture_url']), 'photo', 'user')}
                  name={getBrokerName(broker)}
                  nameClickRoute={generatePath(routes.brokerDetail, {
                    id: broker.get('id'),
                    slug: broker.get('slug'),
                  })}
                  isVerifiedUser={broker.get('is_verified_user')}
                  company={office && office.get('name')}
                  rating={broker.get('average_rating')}
                  email={broker.get('email')}
                  phone={getContact(broker, 'phone')}
                  sendStatistics={sendPhoneStatistics}
                  awards={broker.get('awards')}
                  certificates={getCertificatesFromBusinessCard(broker.get('business_card'))}
                />
              )}
              {renderBrokerContactForm()}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default MessageBrokerModal;
