import { fromJS, Map } from 'immutable';
import Portal from '@city24/common/enums/Portal';
import { OBJECT_TYPES, OBJECT_TYPES_ID, TRANSACTION_TYPES_ID, TRANSACTION_TYPES } from './object';
import { omit } from '../utils/object';

export const PURPOSE_OF_USE_LAND = Object.freeze({
  COMMERCIAL_LAND: 'commercial_land',
  FOREST_LAND: 'forest_land',
  INDUSTRIAL_LAND: 'industrial_land',
  LAND_WITHOUT_PURPOSE_DETERMINED: 'land_without_purpose_determined',
  PROFIT_YIELDING_LAND: 'profit_yielding_land',
  RESIDENTAL_LAND: 'residental_land',
  SOCIAL_LAND: 'social_land',
  TRANSPORTATION_LAND: 'transportation_land',
  LAND_BY_WATERS: 'land_by_waters',
  PRODUCTION_LAND: 'production_land',
});

export const PURPOSE_OF_USE_COMMERCIAL = Object.freeze({
  FOOD_SERVICES: 'food_services',
  MERCHANT: 'merchant',
  NOT_DEFINED: 'not_defined',
  OFFICE: 'office',
  PRODUCTION: 'production',
  SERVICES: 'services',
  WAREHOUSE: 'warehouse',
  WAREHOUSE_PRODUCTION: 'warehouse_production',
  STOCK_OFFICE: 'stock_office',
  LOFT_OFFICE: 'loft_office',
  COWORKING_SPACE: 'coworking_space',
  INVESTMENT_OBJECT: 'investment_object',
});

export const BUILDING_MATERIAL = Object.freeze({
  LOG_HOUSE: 'log_house',
  BRICK: 'brick',
  LOG_STONE_HOUSE: 'log_stone_house',
  MONOLITH: 'monolith',
  PANEL_HOUSE: 'panel_house',
  STONE_HOUSE: 'stone_house',
  WOODEN_HOUSE: 'wooden_house',
});

export const ROOF_TYPE = Object.freeze({
  BITUMIN: 'bitumin',
  ETERNIT: 'eternit',
  PVC: 'PVC',
  ROLLED_MATERIAL: 'rolled_material',
  SLATE: 'slate',
  STONE: 'stone',
  TILING: 'tiling',
  TIN_PLATE: 'tin_plate',
  ZINC_PLATE: 'zinc_plate',
});

export const BALCONY_TYPE = Object.freeze({
  BALCONY: 'balcony',
  CLOSED_BALCONY: 'closed_balcony',
  FULLY_GLAZED_BALCONY: 'fully_glazed_balcony',
  TERRACE: 'terrace',
  FRENCH_BALCONY: 'french_balcony',
});

export const HEATING_SYSTEM = Object.freeze({
  CENTRAL_HEATING: 'central_heating',
  COMBINED_HEATING: 'combined_heating',
  ELECTRIC_HEATING: 'electric_heating',
  FIREPLACE: 'fireplace',
  FLOOR_HEATING: 'floor_heating',
  FURNACE_HEATING: 'furnace_heating',
  GAS_HEATING: 'gas_heating',
  GEOTHERMIC_HEATING: 'geothermic_heating',
  SOLID_FUEL: 'solid_fuel',
  AUTONOMIC_HEATING: 'autonomic_heating',
  HAS_AIR_HEAT_PUMP: 'has_air_heat_pump',
  HAS_AIR_WATER_PUMP: 'has_air_water_pump',
});

export const ENERGY_CERTIFICATE_TYPE = Object.freeze({
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  NO_CERTIFICATE: 'no_certificate',
});

export const CONDITION = Object.freeze({
  ESSENTIAL_REPAIR_IS_DONE: 'essential_repair_is_done',
  FOUNDATION: 'foundation',
  NEEDS_COMPLETE_RENEWAL: 'needs_complete_renewal',
  NEEDS_DECORATION: 'needs_decoration',
  NEW_BUILDING: 'new_building',
  NEWLY_DECORATED: 'newly_decorated',
  READY: 'ready',
  RENOVATED: 'renovated',
  UNDER_CONSTRUCTION: 'under_construction',
  FRAMEWORK: 'framework',
});

export const PARKING_SELECTION = Object.freeze({
  FREE_PARKING: 'free_parking',
  IN_GARAGE: 'in_garage',
  NOT_AVAILABLE: 'not_available',
  PARKING_FEE: 'parking_fee',
  UNDERGROUND_GARAGE: 'underground_garage',
  GARAGE: 'Garage',
  PARKING_LOT: 'Parking_lot',
  CAR_PORT: 'Car_port',
  LOT_IN_CAR_PORT: 'Lot_in_car_port',
  OTHER: 'Other',
  DESIGNATED_PARKING: 'designated_parking',
});

export const COMMUNICATIONS = Object.freeze({
  CABLE_TV: 'cable_TV',
  INTERNET: 'Internet',
  PHONE: 'phone',
  DIGI_TV: 'Digi_TV',
});

export const SECURITY = Object.freeze({
  LOCKED_STAIRCASE: 'locked_staircase',
  NEIGHBOURHOOD_WATCH: 'neighbourhood_watch',
  SECURE_GUARD: 'secure_guard',
  SECURITY_SYSTEM: 'security_system',
  STEEL_DOOR: 'steel_door',
  VIDEO_CAMERAS: 'video_cameras',
});

export const SECURITY_OPTIONS_PROJECT = Object.freeze({
  INTERCOM: 'intercom',
  ALARM_SYSTEM: 'alarm_system',
  CLOSED_TERITORY: 'closed_teritory',
  LIVE_SECURITY: 'live_security',
  VIDEO_SURVEILLANCE_SYSTEM: 'video_surveillance_system',
});

export const SANITARY_ARRANGEMENTS = Object.freeze({
  AIR_CONDITIONER: 'air_conditioner',
  BATH: 'bath',
  BOILER: 'boiler',
  SAUNA: 'sauna',
  SEPARATE_BATHROOM_WC: 'separate_bathroom_WC',
  SHOWER: 'shower',
  SWIMMING_POOL: 'swimming_pool',
  WASHING_MASCHINE: 'washing_maschine',
  HAS_LOCAL_SEWERAGE: 'has_local_sewerage',
  HAS_CENTRAL_SEWERAGE: 'has_central_sewerage',
});

export const COMMUNAL_COSTS = Object.freeze({
  ADDED_TO_PRICE: 'Added_to_price',
  PRICE_INCLUDES: 'Price_includes',
});

export const WASTE_WATER_SYSTEM = Object.freeze({ CENTRAL: 'central', LOCAL: 'local' });

export const OWNERSHIP = Object.freeze({
  APARTMENT_OWNERSHIP: 'apartment_ownership',
  COESTATIVE: 'coestative',
  COOPERATIVE_HOUSING: 'cooperative_housing',
  IMMOVABLE_PROPERTY: 'immovable_property',
  MOVABLE_PROPERTY: 'movable_property',
  REGISTERING_IN_THE_LAND_REGISTER: 'registering_in_the_land_register',
  RIGHT_OF_SUPERFICIES: 'right_of_superficies',
});

export const PHASE_OF_CONSTRUCTION = Object.freeze({
  PLANNING: 'planning',
  UNDER_CONSTRUCTION: 'under_construction',
  PLAN_TO_PUT_INTO_OPERATION: 'plan_to_put_into_operation',
  HAS_BEEN_PUT_INTO_OPERATION: 'has_been_put_into_operation',
  MOVE_IN_READY: 'move_in_ready',
});

export const VENTILATION_TYPE = Object.freeze({
  CLIMATE_CONTROL: 'climate_control',
  COMPULSORY_VENTILATION: 'compulsory_ventilation',
  AIR_CONDITIONER: 'air_conditioner',
});

export const PARKING_SELECTION_COMMERCIAL = Object.freeze({
  IN_THE_COURTYARD: 'in_the_courtyard',
  UNDERGROUND: 'underground',
  IN_FRONT_OF_THE_HOUSE: 'in_front_of_the_house',
  IN_FRONT_OF_THE_HOUSE_AND_COURTYARD: 'in_front_of_the_house_and_courtyard',
});

export const PARKING_SELECTION_PROJECT = Object.freeze({
  FREE_PARKING: 'free_parking',
  PARKING_FEE: 'parking_fee',
  POSSIBILITY_TO_BUY_PARKING_SPOT: 'possibility_to_buy_parking_spot',
  UNDERGROUND: 'underground',
  NEAR_THE_BUILDING: 'near_the_building',
});

export const LIMITATIONS = Object.freeze({
  IN_MORTGAGE: 'IN_MORTGAGE',
  PLEDGE: 'PLEDGE',
  PRESERVED_TERRITORY: 'PRESERVED_TERRITORY',
  RENT_AGREEMENT: 'RENT_AGREEMENT',
  UNDER_SEIZURE: 'UNDER_SEIZURE',
  OTHER_LIMITATIONS: 'OTHER_LIMITATIONS',
});

export const STOVE = Object.freeze({
  ELECTRIC_STOVE: 'ELECTRIC_STOVE',
  BY_FIREWOOD: 'BY_FIREWOOD',
  GAS_STOVE: 'GAS_STOVE',
});

export const RENT_COMMUNAL_COSTS = Object.freeze({
  ELECTRICITY_COST: 'electricity_cost',
  WATER_COST: 'water_cost',
  GAS_COST: 'gas_cost',
  HEATING_COST: 'heating_cost',
  ALL_COMMUNAL_COSTS: 'all_communal_costs',
});

export const HOUSE_TYPE = Object.freeze({
  '103_SERIES': '103_series',
  '104_SERIES': '104_series',
  '119_SERIES': '119_series',
  '467_SERIES': '467_series',
  '602_SERIES': '602_series',
  BREZHNEV_PROJECT: 'brezhnev_project',
  CZECH_PROJECT: 'czech_project',
  FRENCH_PROJECT: 'french_project',
  KHRUSHCHEV_PROJECT: 'khrushchev_project',
  NEW_PROJECT: 'new_project',
  LITHUANIAN_PROJECT: 'lithuanian_project',
  FAMILY_PROJECT: 'family_project',
  SPECIAL_PROJECT: 'special_project',
  STALIN_PROJECT: 'stalin_project',
  PREWAR_PROJECT: 'prewar_project',
});

export const NEARBY_SURROUNDINGS = Object.freeze({
  CLOSE_TO_THE_WATER: 'close_to_the_water',
  CLOSE_TO_THE_PARK: 'close_to_the_park',
  GREEN_CORTYARDS: 'green_cortyards',
  SPORTS_FIELD: 'sports_field',
  CLOSE_TO_THE_FOREST: 'close_to_the_forest',
  PLAYGROUND: 'playground',
});

export const UTILITIES = Object.freeze({
  VENTILATION: 'ventilation',
  HEATING: 'heating',
  AUTONOMIC_HEATING: 'autonomic_heating',
  CONDITIONING_SYSTEM: 'conditioning_system',
});

export const OUTDOOR_SPACES = Object.freeze({
  BALCONY: 'balcony',
  TERRACE: 'terrace',
  CLOSED_BALCONY: 'closed_balcony',
  FRENCH_BALCONY: 'french_balcony',
});

export const FINISH_STAGE = Object.freeze({
  WITHOUT_FINISHING: 'without_finishing',
  GRAY_FINISH: 'gray_finish',
  WHITE_FINISH: 'white_finish',
  FULLY_FINISHED: 'fully_finished',
});

export const ADD_CADASTER_IMG = 'ADD_CADASTER_IMG';
export const AMOUNT_OF_PROVISION = 'AMOUNT_OF_PROVISION';
export const AUCTION_DATE = 'AUCTION_DATE';
export const AUCTION_DEPOSIT_MONEY = 'AUCTION_DEPOSIT_MONEY';
export const AUCTION_LINK = 'AUCTION_LINK';
export const AUCTION_SOLD = 'AUCTION_SOLD';
export const AVAILABLE = 'AVAILABLE';
export const BALCONY_SIZE = 'BALCONY_SIZE';
export const BASEMENT = 'BASEMENT';
export const BROKERS_FEE = 'BROKERS_FEE';
export const CLOSED_YARD = 'CLOSED_YARD';
export const COMMUNAL_COSTS_ADDITIONAL_INFO = 'COMMUNAL_COSTS_ADDITIONAL_INFO';
export const CONSTRUCTION_YEAR = 'CONSTRUCTION_YEAR';
export const CONTRACTUAL = 'CONTRACTUAL';
export const COUNT_PRICE_PER_UNIT_AUTO = 'COUNT_PRICE_PER_UNIT_AUTO';
export const DEADLINE_FOR_DEPOSIT = 'DEADLINE_FOR_DEPOSIT';
export const DEPOSIT_MONEY = 'DEPOSIT_MONEY';
export const DETAILED_PLAN = 'DETAILED_PLAN';
export const ELECTRICITY = 'ELECTRICITY';
export const ELECTRICITY_COST = 'ELECTRICITY_COST';
export const FLOOR = 'FLOOR';
export const FOR_EV_100 = 'FOR_EV_100';
export const FURNISHING_POSSIBLE = 'FURNISHING_POSSIBLE';
export const FURNITURE = 'FURNITURE';
export const GAS = 'GAS';
// export const HAS_AUCTION = 'HAS_AUCTION';
export const HAS_BALCONY = 'HAS_BALCONY';
export const HAS_OPEN_DAYS = 'HAS_OPEN_DAYS';
export const HAS_SEWERAGE = 'HAS_SEWERAGE';
export const HAS_TV = 'HAS_TV';
export const HAS_REFRIGIRATOR = 'HAS_REFRIGIRATOR';
export const HAS_SPECIAL_LABEL = 'HAS_SPECIAL_LABEL';
export const HAS_VIRTUAL_TOUR = 'HAS_VIRTUAL_TOUR';
export const IMMEDIATELY_AVAILABLE = 'IMMEDIATELY_AVAILABLE';
export const INDUSTRIAL_POWER_SUPPLY = 'INDUSTRIAL_POWER_SUPPLY';
export const INITIAL_PRICE = 'INITIAL_PRICE';
export const KITCHEN_AREA = 'KITCHEN_AREA';
export const LIFT = 'LIFT';
export const ELEVATOR = 'ELEVATOR';
export const MAINTENANCE_COST = 'MAINTENANCE_COST';
export const MEETING_ROOM = 'MEETING_ROOM';
export const NUMBER_OF_BATHROOMS = 'NUMBER_OF_BATHROOMS';
export const NUMBER_OF_BEDROOMS = 'NUMBER_OF_BEDROOMS';
export const NO_OF_APARTMENTS = 'NO_OF_APARTMENTS';
export const NUMBER_OF_DESKS = 'NUMBER_OF_DESKS';
export const ON_LAST_FLOOR = 'ON_LAST_FLOOR';
export const OTHER_USE_POSSIBLE = 'OTHER_USE_POSSIBLE';
export const PARKING_FEE = 'PARKING_FEE';
export const PARKING_FREE = 'PARKING_FREE';
export const PARKING_PLACES = 'PARKING_PLACES';
export const PETS_ALLOWED = 'PETS_ALLOWED';
export const PRE_PAYMENT = 'PRE_PAYMENT';
export const PRICE_PER_UNIT_MEASUREMENT = 'PRICE_PER_UNIT_MEASUREMENT';
export const REFRIGERATOR = 'REFRIGERATOR';
export const RENT_TO_OWN = 'RENT_TO_OWN';
export const RENT_PER_DAY = 'RENT_PER_DAY';
export const RENT_SUMMER_COMMUNAL_COSTS = 'RENT_SUMMER_COMMUNAL_COSTS';
export const RENT_WINTER_COMMUNAL_COSTS = 'RENT_WINTER_COMMUNAL_COSTS';
export const ROAD = 'ROAD';
export const SEPARATE_ENTRANCE = 'SEPARATE_ENTRANCE';
export const STARTING_PRICE = 'STARTING_PRICE';
export const STORAGE_ROOM = 'STORAGE_ROOM';
export const TOTAL_FLOORS = 'TOTAL_FLOORS';
export const VAT = 'VAT';
export const WARDROBE = 'WARDROBE';
export const WATER = 'WATER';
export const WATER_COST = 'WATER_COST';
export const STORAGE_SPACE = 'STORAGE_SPACE';
export const ACHIEVEMENTS = 'ACHIEVEMENTS';
export const SMART_HOME_FEATURES = 'SMART_HOME_HEATURES';
export const SHARED_WAREHOUSE = 'SHARED_WAREHOUSE';
export const SHARED_LOUNGE = 'SHARED_LOUNGE';
export const GARDEN = 'GARDEN';
export const HEATED_FLOORS = 'HEATED_FLOORS';
export const SAUNA = 'SAUNA';
export const COMING_SOON = 'COMING_SOON';

/* ==============
OPTIONS
============== */

const EE = Portal.City24EE;
const LV = Portal.City24LV;

export const TRANSACTION_TYPES_OPTIONS = fromJS([
  {
    name: 'transactionType.sale',
    value: TRANSACTION_TYPES.SALE,
    default: true,
    id: TRANSACTION_TYPES_ID[TRANSACTION_TYPES.SALE],
  },
  {
    name: 'transactionType.rent',
    value: TRANSACTION_TYPES.RENT,
    id: TRANSACTION_TYPES_ID[TRANSACTION_TYPES.RENT],
  },
]);

const objectTypes = [
  {
    object: {
      id: OBJECT_TYPES_ID.Apartment,
      name: 'unitType.Apartment',
      value: OBJECT_TYPES.Apartment,
      icon: 'apartment-o',
      default: true,
    },
    portal: [EE, LV],
  },
  {
    object: {
      id: OBJECT_TYPES_ID.House,
      name: 'unitType.House',
      value: OBJECT_TYPES.House,
      icon: 'house-1',
    },
    portal: [EE, LV],
  },
  {
    object: {
      id: OBJECT_TYPES_ID.HouseShare,
      name: 'unitType.HouseShare',
      value: OBJECT_TYPES.HouseShare,
      icon: 'house-share',
    },
    portal: [EE],
  },
  {
    object: {
      id: OBJECT_TYPES_ID.CottageOrVilla,
      name: 'unitType.CottageOrVilla',
      value: OBJECT_TYPES.CottageOrVilla,
      icon: 'cottage',
    },
    portal: [EE],
  },
  {
    object: {
      id: OBJECT_TYPES_ID.Land,
      name: 'unitType.Land',
      value: OBJECT_TYPES.Land,
      icon: 'land',
    },
    portal: [EE, LV],
  },
  {
    object: {
      id: OBJECT_TYPES_ID.Commercial,
      name: 'unitType.Commercial',
      value: OBJECT_TYPES.Commercial,
      icon: 'commercial',
    },
    portal: [EE, LV],
  },
  {
    object: {
      id: OBJECT_TYPES_ID.Garage,
      name: 'unitType.Garage',
      value: OBJECT_TYPES.Garage,
      icon: 'garage',
    },
    portal: [EE, LV],
  },
  {
    object: {
      id: OBJECT_TYPES_ID.ModularHouse,
      name: PORTAL_EE ? 'unitType.ModularHouse' : 'unitType.ModularHouse.LV',
      value: OBJECT_TYPES.ModularHouse,
      icon: 'modular-house',
    },
    portal: [EE, LV],
  },
];

export const OBJECT_TYPE_OPTIONS = fromJS(
  objectTypes.filter(({ portal }) => portal.includes(PORTAL)).map(({ object }) => object)
);

export const OBJECT_TYPE_OPTION_PROJECT = Map({
  id: OBJECT_TYPES_ID.NewProject,
  name: 'unitType.NewProject',
  value: OBJECT_TYPES.NewProject,
  icon: 'new-project',
});

export const OBJECT_TYPE_OPTIONS_NP = OBJECT_TYPE_OPTIONS.filter((opt) =>
  [
    OBJECT_TYPES.Apartment,
    OBJECT_TYPES.House,
    OBJECT_TYPES.HouseShare,
    OBJECT_TYPES.CottageOrVilla,
    OBJECT_TYPES.Commercial,
    OBJECT_TYPES.Land,
    OBJECT_TYPES.Garage,
  ].includes(opt.get('value'))
);
export const CONSTRUCTION_PHASE_OPTIONS_NP = fromJS([
  {
    name: 'constructionPhase.all',
    value: 'all',
    default: true,
    id: '0',
  },
  {
    name: 'constructionPhase.ready',
    value: 'ready',
    default: false,
    id: '1',
  },
  {
    name: 'constructionPhase.comingSoon',
    value: 'comingSoon',
    default: false,
    id: '2',
  },
]);

export function getObjectTypeIcon(objectType) {
  if (objectType === OBJECT_TYPES.NewProject) {
    return OBJECT_TYPE_OPTION_PROJECT.get('icon');
  }
  const ot = OBJECT_TYPE_OPTIONS.find((o) => o.get('value') === objectType);
  return ot ? ot.get('icon') : null;
}

export const BUILDING_MATERIAL_OPTIONS = fromJS([
  { urlId: 1, name: 'BUILDING_MATERIAL.brick', value: BUILDING_MATERIAL.BRICK },
  { urlId: 2, name: 'BUILDING_MATERIAL.log_house', value: BUILDING_MATERIAL.LOG_HOUSE },
  { urlId: 3, name: 'BUILDING_MATERIAL.log_stone_house', value: BUILDING_MATERIAL.LOG_STONE_HOUSE },
  { urlId: 4, name: 'BUILDING_MATERIAL.monolith', value: BUILDING_MATERIAL.MONOLITH },
  { urlId: 5, name: 'BUILDING_MATERIAL.panel_house', value: BUILDING_MATERIAL.PANEL_HOUSE },
  { urlId: 6, name: 'BUILDING_MATERIAL.stone_house', value: BUILDING_MATERIAL.STONE_HOUSE },
  { urlId: 7, name: 'BUILDING_MATERIAL.wooden_house', value: BUILDING_MATERIAL.WOODEN_HOUSE },
]);

export const CONDITION_OPTIONS = fromJS([
  {
    urlId: 1,
    name: 'CONDITION.newly_decorated',
    value: CONDITION.NEWLY_DECORATED,
  },
  { urlId: 2, name: 'CONDITION.foundation', value: CONDITION.FOUNDATION },
  { urlId: 3, name: 'CONDITION.framework', value: CONDITION.FRAMEWORK },
  {
    urlId: 4,
    name: 'CONDITION.essential_repair_is_done',
    value: CONDITION.ESSENTIAL_REPAIR_IS_DONE,
  },
  {
    urlId: 5,
    name: 'CONDITION.needs_complete_renewal',
    value: CONDITION.NEEDS_COMPLETE_RENEWAL,
  },
  {
    urlId: 6,
    name: 'CONDITION.needs_decoration',
    value: CONDITION.NEEDS_DECORATION,
  },
  { urlId: 7, name: 'CONDITION.new_building', value: CONDITION.NEW_BUILDING },
  { urlId: 8, name: 'CONDITION.ready', value: CONDITION.READY },
  { urlId: 9, name: 'CONDITION.renovated', value: CONDITION.RENOVATED },
  {
    urlId: 10,
    name: 'CONDITION.under_construction',
    value: CONDITION.UNDER_CONSTRUCTION,
  },
]);

export const PURPOSE_OPTIONS = fromJS({
  [OBJECT_TYPES.Commercial]: [
    {
      urlId: 1,
      name: 'PURPOSE_OF_USE_COMMERCIAL.food_services',
      value: PURPOSE_OF_USE_COMMERCIAL.FOOD_SERVICES,
      portal: [EE],
    },
    {
      urlId: 2,
      name: 'PURPOSE_OF_USE_COMMERCIAL.merchant',
      value: PURPOSE_OF_USE_COMMERCIAL.MERCHANT,
      portal: [EE, LV],
    },
    {
      urlId: 4,
      name: 'PURPOSE_OF_USE_COMMERCIAL.office',
      value: PURPOSE_OF_USE_COMMERCIAL.OFFICE,
      portal: [EE, LV],
    },
    {
      urlId: 5,
      name: 'PURPOSE_OF_USE_COMMERCIAL.production',
      value: PURPOSE_OF_USE_COMMERCIAL.PRODUCTION,
      portal: [EE],
    },
    {
      urlId: 6,
      name: 'PURPOSE_OF_USE_COMMERCIAL.services',
      value: PURPOSE_OF_USE_COMMERCIAL.SERVICES,
      portal: [EE],
    },
    {
      urlId: 7,
      name: 'PURPOSE_OF_USE_COMMERCIAL.warehouse',
      value: PURPOSE_OF_USE_COMMERCIAL.WAREHOUSE,
      portal: [EE],
    },
    {
      urlId: 8,
      name: 'PURPOSE_OF_USE_COMMERCIAL.warehouse_production',
      value: PURPOSE_OF_USE_COMMERCIAL.WAREHOUSE_PRODUCTION,
      portal: [EE, LV],
    },
    {
      urlId: 12,
      name: 'PURPOSE_OF_USE_COMMERCIAL.loft_office',
      value: PURPOSE_OF_USE_COMMERCIAL.LOFT_OFFICE,
      portal: [EE],
    },
    {
      urlId: 9,
      name: 'PURPOSE_OF_USE_COMMERCIAL.stock_office',
      value: PURPOSE_OF_USE_COMMERCIAL.STOCK_OFFICE,
      portal: [EE],
    },
    {
      urlId: 10,
      name: 'PURPOSE_OF_USE_COMMERCIAL.coworking_space',
      value: PURPOSE_OF_USE_COMMERCIAL.COWORKING_SPACE,
      portal: [EE],
    },
    {
      urlId: 3,
      name: 'PURPOSE_OF_USE_COMMERCIAL.not_defined',
      value: PURPOSE_OF_USE_COMMERCIAL.NOT_DEFINED,
      portal: [EE, LV],
    },
    {
      urlId: 11,
      name: 'PURPOSE_OF_USE_COMMERCIAL.investment_object',
      value: PURPOSE_OF_USE_COMMERCIAL.INVESTMENT_OBJECT,
      portal: [EE, LV],
    },
  ]
    .filter(({ portal }) => portal.includes(PORTAL))
    .map((o) => omit(o, 'portal')),
  [OBJECT_TYPES.Land]: [
    {
      urlId: 1,
      name: 'PURPOSE_OF_USE_LAND.commercial_land',
      value: PURPOSE_OF_USE_LAND.COMMERCIAL_LAND,
    },
    { urlId: 2, name: 'PURPOSE_OF_USE_LAND.forest_land', value: PURPOSE_OF_USE_LAND.FOREST_LAND },
    {
      urlId: 3,
      name: 'PURPOSE_OF_USE_LAND.industrial_land',
      value: PURPOSE_OF_USE_LAND.INDUSTRIAL_LAND,
    },
    {
      urlId: 4,
      name: 'PURPOSE_OF_USE_LAND.land_by_waters',
      value: PURPOSE_OF_USE_LAND.LAND_BY_WATERS,
    },
    {
      urlId: 5,
      name: 'PURPOSE_OF_USE_LAND.land_without_purpose_determined',
      value: PURPOSE_OF_USE_LAND.LAND_WITHOUT_PURPOSE_DETERMINED,
    },
    {
      urlId: 6,
      name: 'PURPOSE_OF_USE_LAND.production_land',
      value: PURPOSE_OF_USE_LAND.PRODUCTION_LAND,
    },
    {
      urlId: 7,
      name: 'PURPOSE_OF_USE_LAND.profit_yielding_land',
      value: PURPOSE_OF_USE_LAND.PROFIT_YIELDING_LAND,
    },
    {
      urlId: 8,
      name: 'PURPOSE_OF_USE_LAND.residental_land',
      value: PURPOSE_OF_USE_LAND.RESIDENTAL_LAND,
    },
  ],
});

export const BALCONY_TYPE_OPTIONS = fromJS([
  { name: 'BALCONY_TYPE.balcony', value: BALCONY_TYPE.BALCONY, portal: [EE, LV] },
  { name: 'BALCONY_TYPE.closed_balcony', value: BALCONY_TYPE.CLOSED_BALCONY, portal: [EE] },
  {
    name: 'BALCONY_TYPE.fully_glazed_balcony',
    value: BALCONY_TYPE.FULLY_GLAZED_BALCONY,
    portal: [EE, LV],
  },
  { name: 'BALCONY_TYPE.terrace', value: BALCONY_TYPE.TERRACE, portal: [EE, LV] },
  { name: 'BALCONY_TYPE.french_balcony', value: BALCONY_TYPE.FRENCH_BALCONY, portal: [EE, LV] },
]).filter((o) => o.get('portal').includes(PORTAL));

export const ROOF_TYPE_OPTIONS = fromJS([
  { name: 'ROOF_TYPE.bitumin', value: ROOF_TYPE.BITUMIN },
  { name: 'ROOF_TYPE.eternit', value: ROOF_TYPE.ETERNIT },
  { name: 'ROOF_TYPE.PVC', value: ROOF_TYPE.PVC },
  { name: 'ROOF_TYPE.rolled_material', value: ROOF_TYPE.ROLLED_MATERIAL },
  { name: 'ROOF_TYPE.slate', value: ROOF_TYPE.SLATE },
  { name: 'ROOF_TYPE.stone', value: ROOF_TYPE.STONE },
  { name: 'ROOF_TYPE.tiling', value: ROOF_TYPE.TILING },
  { name: 'ROOF_TYPE.tin_plate', value: ROOF_TYPE.TIN_PLATE },
  { name: 'ROOF_TYPE.zinc_plate', value: ROOF_TYPE.ZINC_PLATE },
]);

export const ENERGY_CERTIFICATE_OPTIONS = fromJS([
  { urlId: 'A', name: 'ENERGY_CERTIFICATE_TYPE.A', value: ENERGY_CERTIFICATE_TYPE.A },
  { urlId: 'B', name: 'ENERGY_CERTIFICATE_TYPE.B', value: ENERGY_CERTIFICATE_TYPE.B },
  { urlId: 'C', name: 'ENERGY_CERTIFICATE_TYPE.C', value: ENERGY_CERTIFICATE_TYPE.C },
  { urlId: 'D', name: 'ENERGY_CERTIFICATE_TYPE.D', value: ENERGY_CERTIFICATE_TYPE.D },
  { urlId: 'E', name: 'ENERGY_CERTIFICATE_TYPE.E', value: ENERGY_CERTIFICATE_TYPE.E },
  { urlId: 'F', name: 'ENERGY_CERTIFICATE_TYPE.F', value: ENERGY_CERTIFICATE_TYPE.F },
  { urlId: 'G', name: 'ENERGY_CERTIFICATE_TYPE.G', value: ENERGY_CERTIFICATE_TYPE.G },
  { urlId: 'H', name: 'ENERGY_CERTIFICATE_TYPE.H', value: ENERGY_CERTIFICATE_TYPE.H },
  {
    urlId: 0,
    name: 'ENERGY_CERTIFICATE_TYPE.no_certificate',
    value: ENERGY_CERTIFICATE_TYPE.NO_CERTIFICATE,
  },
]);

export const PARKING_SELECTION_OPTIONS = fromJS([
  { name: 'PARKING_SELECTION.in_garage', value: PARKING_SELECTION.IN_GARAGE, portal: [EE] },
  { name: 'PARKING_SELECTION.free_parking', value: PARKING_SELECTION.FREE_PARKING, portal: [LV] },
  { name: 'PARKING_SELECTION.parking_fee', value: PARKING_SELECTION.PARKING_FEE, portal: [LV] },
  {
    name: 'PARKING_SELECTION.not_available',
    value: PARKING_SELECTION.NOT_AVAILABLE,
    portal: [EE, LV],
  },
  {
    name: 'PARKING_SELECTION.underground_garage',
    value: PARKING_SELECTION.UNDERGROUND_GARAGE,
    portal: [EE, LV],
  },
  { name: 'PARKING_SELECTION.Garage', value: PARKING_SELECTION.GARAGE, portal: [EE] },
  { name: 'PARKING_SELECTION.Parking_lot', value: PARKING_SELECTION.PARKING_LOT, portal: [EE] },
  { name: 'PARKING_SELECTION.Car_port', value: PARKING_SELECTION.CAR_PORT, portal: [EE] },
  {
    name: 'PARKING_SELECTION.Lot_in_car_port',
    value: PARKING_SELECTION.LOT_IN_CAR_PORT,
    portal: [EE],
  },
  { name: 'PARKING_SELECTION.Other', value: PARKING_SELECTION.OTHER, portal: [EE] },
  {
    name: 'PARKING_SELECTION.designated_parking',
    value: PARKING_SELECTION.DESIGNATED_PARKING,
    portal: [EE, LV],
  },
]).filter((o) => o.get('portal').includes(PORTAL));

export const PARKING_SELECTION_OPTIONS_COMMERCIAL = fromJS([
  {
    name: 'PARKING_SELECTION_COMMERCIAL.in_the_courtyard',
    value: PARKING_SELECTION_COMMERCIAL.IN_THE_COURTYARD,
  },
  {
    name: 'PARKING_SELECTION_COMMERCIAL.underground',
    value: PARKING_SELECTION_COMMERCIAL.UNDERGROUND,
  },
  {
    name: 'PARKING_SELECTION_COMMERCIAL.in_front_of_the_house',
    value: PARKING_SELECTION_COMMERCIAL.IN_FRONT_OF_THE_HOUSE,
  },
  {
    name: 'PARKING_SELECTION_COMMERCIAL.in_front_of_the_house_and_courtyard',
    value: PARKING_SELECTION_COMMERCIAL.IN_FRONT_OF_THE_HOUSE_AND_COURTYARD,
  },
]);

export const PARKING_COST_OPTIONS = fromJS([
  { name: 'PARKING_SELECTION.free_parking', value: PARKING_FREE },
  { name: 'PARKING_SELECTION.parking_fee', value: PARKING_FEE },
]);

export const RENT_COMMUNAL_COSTS_OPTIONS = fromJS([
  { name: 'RENT_COMMUNAL_COSTS.electricity_cost', value: RENT_COMMUNAL_COSTS.ELECTRICITY_COST },
  { name: 'RENT_COMMUNAL_COSTS.water_cost', value: RENT_COMMUNAL_COSTS.WATER_COST },
  { name: 'RENT_COMMUNAL_COSTS.gas_cost', value: RENT_COMMUNAL_COSTS.GAS_COST },
  { name: 'RENT_COMMUNAL_COSTS.heating_cost', value: RENT_COMMUNAL_COSTS.HEATING_COST },
  { name: 'RENT_COMMUNAL_COSTS.all_communal_costs', value: RENT_COMMUNAL_COSTS.ALL_COMMUNAL_COSTS },
]);

export const HEATING_SYSTEM_OPTIONS = fromJS([
  { name: 'HEATING_SYSTEM.central_heating', value: HEATING_SYSTEM.CENTRAL_HEATING },
  { name: 'HEATING_SYSTEM.combined_heating', value: HEATING_SYSTEM.COMBINED_HEATING },
  { name: 'HEATING_SYSTEM.electric_heating', value: HEATING_SYSTEM.ELECTRIC_HEATING },
  { name: 'HEATING_SYSTEM.fireplace', value: HEATING_SYSTEM.FIREPLACE },
  { name: 'HEATING_SYSTEM.floor_heating', value: HEATING_SYSTEM.FLOOR_HEATING },
  { name: 'HEATING_SYSTEM.furnace_heating', value: HEATING_SYSTEM.FURNACE_HEATING },
  { name: 'HEATING_SYSTEM.gas_heating', value: HEATING_SYSTEM.GAS_HEATING },
  { name: 'HEATING_SYSTEM.geothermic_heating', value: HEATING_SYSTEM.GEOTHERMIC_HEATING },
  { name: 'HEATING_SYSTEM.solid_fuel', value: HEATING_SYSTEM.SOLID_FUEL },
  { name: 'HEATING_SYSTEM.autonomic_heating', value: HEATING_SYSTEM.AUTONOMIC_HEATING },
  { name: 'HEATING_SYSTEM.has_air_heat_pump', value: HEATING_SYSTEM.HAS_AIR_HEAT_PUMP },
  { name: 'HEATING_SYSTEM.has_air_water_pump', value: HEATING_SYSTEM.HAS_AIR_WATER_PUMP },
]);

export const SANITARY_ARRANGEMENTS_OPTIONS = fromJS([
  { name: 'SANITARY_ARRANGEMENTS.air_conditioner', value: SANITARY_ARRANGEMENTS.AIR_CONDITIONER },
  { name: 'SANITARY_ARRANGEMENTS.bath', value: SANITARY_ARRANGEMENTS.BATH },
  { name: 'SANITARY_ARRANGEMENTS.boiler', value: SANITARY_ARRANGEMENTS.BOILER },
  { name: 'SANITARY_ARRANGEMENTS.sauna', value: SANITARY_ARRANGEMENTS.SAUNA },
  {
    name: 'SANITARY_ARRANGEMENTS.separate_bathroom_WC',
    value: SANITARY_ARRANGEMENTS.SEPARATE_BATHROOM_WC,
  },
  { name: 'SANITARY_ARRANGEMENTS.shower', value: SANITARY_ARRANGEMENTS.SHOWER },
  { name: 'SANITARY_ARRANGEMENTS.swimming_pool', value: SANITARY_ARRANGEMENTS.SWIMMING_POOL },
  {
    name: 'SANITARY_ARRANGEMENTS.washing_maschine',
    value: SANITARY_ARRANGEMENTS.WASHING_MASCHINE,
  },
  {
    name: 'SANITARY_ARRANGEMENTS.has_local_sewerage',
    value: SANITARY_ARRANGEMENTS.HAS_LOCAL_SEWERAGE,
  },
  {
    name: 'SANITARY_ARRANGEMENTS.has_central_sewerage',
    value: SANITARY_ARRANGEMENTS.HAS_CENTRAL_SEWERAGE,
  },
]);

export const STOVE_OPTIONS = fromJS([
  { name: 'STOVE.ELECTRIC_STOVE', value: STOVE.ELECTRIC_STOVE },
  { name: 'STOVE.BY_FIREWOOD', value: STOVE.BY_FIREWOOD },
  { name: 'STOVE.GAS_STOVE', value: STOVE.GAS_STOVE },
]);

export const VENTILATION_TYPE_OPTIONS = fromJS([
  { name: 'VENTILATION_TYPE.climate_control', value: VENTILATION_TYPE.CLIMATE_CONTROL },
  {
    name: 'VENTILATION_TYPE.compulsory_ventilation',
    value: VENTILATION_TYPE.COMPULSORY_VENTILATION,
  },
  { name: 'VENTILATION_TYPE.air_conditioner', value: VENTILATION_TYPE.AIR_CONDITIONER },
]);

export const EXTRA_SPACES_OPTIONS = fromJS([
  { name: 'objectSpec.basement', value: BASEMENT },
  { name: 'objectSpec.storageRoom', value: STORAGE_ROOM },
  { name: 'objectSpec.wardrobe', value: WARDROBE },
]);

export const COMMUNICATIONS_OPTIONS = fromJS([
  { name: 'COMMUNICATIONS.cable_TV', value: COMMUNICATIONS.CABLE_TV },
  { name: 'COMMUNICATIONS.Internet', value: COMMUNICATIONS.INTERNET },
  { name: 'COMMUNICATIONS.phone', value: COMMUNICATIONS.PHONE },
  { name: 'COMMUNICATIONS.Digi_TV', value: COMMUNICATIONS.DIGI_TV },
]);

export const MUNICIPAL_ENGINEERING_OPTIONS = fromJS([
  { name: 'HAS_SEWERAGE.HAS_SEWERAGE', value: HAS_SEWERAGE },
  { name: 'ELECTRICITY.ELECTRICITY', value: ELECTRICITY },
  { name: 'GAS.GAS', value: GAS },
  { name: 'WATER.WATER', value: WATER },
  { name: 'ROAD.ROAD', value: ROAD },
]);

export const SECURITY_OPTIONS = fromJS([
  { name: 'SECURITY.locked_staircase', value: SECURITY.LOCKED_STAIRCASE, portal: [EE, LV] },
  { name: 'SECURITY.neighbourhood_watch', value: SECURITY.NEIGHBOURHOOD_WATCH, portal: [EE] },
  { name: 'SECURITY.secure_guard', value: SECURITY.SECURE_GUARD, portal: [EE, LV] },
  { name: 'SECURITY.security_system', value: SECURITY.SECURITY_SYSTEM, portal: [EE, LV] },
  { name: 'SECURITY.steel_door', value: SECURITY.STEEL_DOOR, portal: [EE, LV] },
  { name: 'SECURITY.video_cameras', value: SECURITY.VIDEO_CAMERAS, portal: [EE, LV] },
]).filter((o) => o.get('portal').includes(PORTAL));

export const EXTRA_VALUES_OPTIONS = fromJS([
  {
    name: 'objectSpec.separateEntrance',
    value: SEPARATE_ENTRANCE,
    objectType: [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.Commercial],
    transactionType: [TRANSACTION_TYPES.SALE, TRANSACTION_TYPES.RENT],
  },
  {
    name: 'objectSpec.closedYard',
    value: CLOSED_YARD,
    objectType: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.CottageOrVilla,
    ],
    transactionType: [TRANSACTION_TYPES.SALE, TRANSACTION_TYPES.RENT],
  },
  {
    name: 'objectSpec.furniture',
    value: FURNITURE,
    objectType: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.CottageOrVilla,
    ],
    transactionType: [TRANSACTION_TYPES.SALE, TRANSACTION_TYPES.RENT],
  },
  {
    name: 'objectSpec.furnishingPossible',
    value: FURNISHING_POSSIBLE,
    objectType: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.CottageOrVilla,
    ],
    transactionType: [TRANSACTION_TYPES.SALE, TRANSACTION_TYPES.RENT],
  },
  {
    name: 'objectSpec.hasTv',
    value: HAS_TV,
    objectType: [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.CottageOrVilla],
    transactionType: [TRANSACTION_TYPES.RENT],
  },
  {
    name: 'objectSpec.hasRefrigirator',
    value: REFRIGERATOR,
    objectType: [OBJECT_TYPES.Apartment],
    transactionType: [TRANSACTION_TYPES.RENT],
  },
  {
    name: 'objectSpec.petsAllowed',
    value: PETS_ALLOWED,
    objectType: [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.CottageOrVilla],
    transactionType: [TRANSACTION_TYPES.RENT],
  },
  {
    name: 'objectSpec.industrialPowerSupply',
    value: INDUSTRIAL_POWER_SUPPLY,
    objectType: [OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.Commercial, OBJECT_TYPES.CottageOrVilla],
    transactionType: [TRANSACTION_TYPES.SALE, TRANSACTION_TYPES.RENT],
  },
  {
    name: 'objectSpec.meetingRoom',
    value: MEETING_ROOM,
    objectType: [OBJECT_TYPES.Commercial],
    transactionType: [TRANSACTION_TYPES.SALE, TRANSACTION_TYPES.RENT],
  },
]);

export const OWNERSHIP_OPTIONS = fromJS([
  { name: 'OWNERSHIP.apartment_ownership', value: OWNERSHIP.APARTMENT_OWNERSHIP, portal: [EE] },
  { name: 'OWNERSHIP.coestative', value: OWNERSHIP.COESTATIVE, portal: [EE] },
  { name: 'OWNERSHIP.cooperative_housing', value: OWNERSHIP.COOPERATIVE_HOUSING, portal: [EE] },
  { name: 'OWNERSHIP.immovable_property', value: OWNERSHIP.IMMOVABLE_PROPERTY, portal: [EE] },
  { name: 'OWNERSHIP.movable_property', value: OWNERSHIP.MOVABLE_PROPERTY, portal: [EE] },
  {
    name: 'OWNERSHIP.registering_in_the_land_register',
    value: OWNERSHIP.REGISTERING_IN_THE_LAND_REGISTER,
    portal: [EE, LV],
  },
  {
    name: 'OWNERSHIP.right_of_superficies',
    value: OWNERSHIP.RIGHT_OF_SUPERFICIES,
    portal: [EE, LV],
  },
]).filter((o) => o.get('portal').includes(PORTAL));

export const LIMITATION_OPTIONS = fromJS([
  { name: 'LIMITATIONS.IN_MORTGAGE', value: LIMITATIONS.IN_MORTGAGE },
  { name: 'LIMITATIONS.PLEDGE', value: LIMITATIONS.PLEDGE },
  { name: 'LIMITATIONS.PRESERVED_TERRITORY', value: LIMITATIONS.PRESERVED_TERRITORY },
  { name: 'LIMITATIONS.RENT_AGREEMENT', value: LIMITATIONS.RENT_AGREEMENT },
  { name: 'LIMITATIONS.UNDER_SEIZURE', value: LIMITATIONS.UNDER_SEIZURE },
  { name: 'LIMITATIONS.OTHER_LIMITATIONS', value: LIMITATIONS.OTHER_LIMITATIONS },
]);

export const PROJECT_TYPE_OPTIONS = fromJS([
  { name: 'PROJECT_TYPE.103_series', value: HOUSE_TYPE['103_SERIES'] },
  { name: 'PROJECT_TYPE.104_series', value: HOUSE_TYPE['104_SERIES'] },
  { name: 'PROJECT_TYPE.119_series', value: HOUSE_TYPE['119_SERIES'] },
  { name: 'PROJECT_TYPE.467_series', value: HOUSE_TYPE['467_SERIES'] },
  { name: 'PROJECT_TYPE.602_series', value: HOUSE_TYPE['602_SERIES'] },
  { name: 'PROJECT_TYPE.Brezhnev_p.', value: HOUSE_TYPE.BREZHNEV_PROJECT },
  { name: 'PROJECT_TYPE.Czech_p.', value: HOUSE_TYPE.CZECH_PROJECT },
  { name: 'PROJECT_TYPE.French_p.', value: HOUSE_TYPE.FRENCH_PROJECT },
  { name: 'PROJECT_TYPE.Hruschov_t._p.', value: HOUSE_TYPE.KHRUSHCHEV_PROJECT },
  { name: 'PROJECT_TYPE.Lithuanian_p.', value: HOUSE_TYPE.LITHUANIAN_PROJECT },
  { name: 'PROJECT_TYPE.New_project', value: HOUSE_TYPE.NEW_PROJECT },
  { name: 'PROJECT_TYPE.Pre_WWII_p.', value: HOUSE_TYPE.PREWAR_PROJECT },
  { name: 'PROJECT_TYPE.Small_families_p.', value: HOUSE_TYPE.FAMILY_PROJECT },
  { name: 'PROJECT_TYPE.Special_p.', value: HOUSE_TYPE.SPECIAL_PROJECT },
  { name: 'PROJECT_TYPE.Stalin_t._p.', value: HOUSE_TYPE.STALIN_PROJECT },
]);
