import React from 'react';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';

import SimpleCommonObject from './SimpleCommonObject';
import { OBJECT_TYPES } from '../../../constants/object';

function SimpleProject({ object, ...props }) {
  const getAttrValue = (type) => object.getIn(['attributes', type]);

  return (
    <SimpleCommonObject
      {...props}
      object={object}
      getAttrValue={getAttrValue}
      newProject
      objectType={OBJECT_TYPES.NewProject}
      projectName={object.get('project_name')}
      price={object.get('price')}
      minPrice={object.get('price_min')}
      maxPrice={object.get('price_max')}
      sizeMin={object.get('size_min')}
      sizeMax={object.get('size_max')}
      sizeUnit={object.get('size_unit_id')}
      realtiesCount={object.get('realties_count')}
    />
  );
}

SimpleProject.propTypes = {
  object: PropTypes.instanceOf(Collection.Keyed).isRequired,
};
// SimpleProject.defaultProps = {};

export default SimpleProject;
