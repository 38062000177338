import React from 'react';
import type { TFunction } from 'next-i18next';
import { identity } from '@city24/common/utils';
import RouteType from '@city24/common/enums/nearbyPlace/RouteType';
import { getRouteTypeName } from '@/constants/map';

import css from './Transport.module.scss';

interface Props {
  type: RouteType;
  lines: string[];
  t: TFunction;
}

function Transport({ type, lines, t }: Props) {
  if (!lines || lines.length === 0) {
    return null;
  }

  return (
    <div className={css.item}>
      <div className={css.type}>{t(`objectDetail.nearbyPlaces.${getRouteTypeName(type)}`)}</div>
      {lines.filter(identity).map((line) => {
        return (
          <div key={line} className={css.line}>
            {line}
          </div>
        );
      })}
    </div>
  );
}

export default Transport;
