import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  value: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string,
  handleChange: PropTypes.func,
  gaEvent: PropTypes.func,
};

const defaultProps = {
  value: '',
  checked: false,
  id: '',
  handleChange: null,
  gaEvent: null,
};
class Toggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };
  }

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  handleChange = (event) => {
    const { handleChange, gaEvent } = this.props;
    const { target } = event;
    if (handleChange) handleChange(target.checked, target.value);
    if (target.checked && gaEvent) gaEvent();
  };

  render() {
    const { focused } = this.state;
    const { id, value, hover, checked } = this.props;

    const toggleStyle = classNames({
      toggle: true,
      'toggle--focused': focused,
      'toggle--checked': checked,
      'toggle--hover': hover,
    });

    return (
      <div className={toggleStyle}>
        <div className="toggle__bg">
          <div className="toggle__fill" />
        </div>
        <input
          id={id}
          type="checkbox"
          value={value}
          checked={checked}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

Toggle.propTypes = propTypes;
Toggle.defaultProps = defaultProps;

export default Toggle;
