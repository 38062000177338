import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Map, Seq } from 'immutable';
import { TFunction } from 'next-i18next';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';

import { formatPrice } from '@/utils/formatting';
import { openModal } from '@/components/modals/modalReducer';
import { ModalName } from '@/components/modals/modalInterfaces';
import { MultiBankCalculatorDefaults } from '@/types/finance';
import Select from '@/components/select/Select';
import Button from '@/components/button/Button';
import Icon from '@/components/icon/Icon';

type Props = {
  t: TFunction;
  calcState: any;
  setCalcState: (field: string, value: any) => void;
  results: any;
  defaults: MultiBankCalculatorDefaults;
  links: { button: string; link: string };
  bank: string;
  accentColor: string;
  unitType: UnitTypeLiteral;
  periodOptions: Seq.Indexed<Map<string, string>>;
  withButton?: boolean;
  gaEventHandler: (action: string, label?: string) => void;
};

const CompactLoanCalculator = ({
  t,
  calcState,
  setCalcState,
  results,
  defaults,
  links,
  bank,
  accentColor,
  unitType,
  periodOptions,
  withButton = false,
  gaEventHandler,
}: Props): React.ReactElement => {
  const dispatch = useDispatch();

  return (
    <div className={classNames('hlcm', { 'hlcm--with-button': withButton })}>
      <div>
        <div className="hlcm__header">
          <h2 className="hlcm__monthly-payment">
            {formatPrice(results.monthlyPayment)}
            <span className="hlcm__per-month">/{t('loanCalc.perMonth')}</span>{' '}
            <Button
              link
              onClick={() => {
                dispatch(openModal({ name: ModalName.MultiBank, price: calcState.price, unitType, bank }));
                gaEventHandler('open_modal_from_compact', bank);
              }}
            >
              <Icon name="question" />
            </Button>
          </h2>
        </div>
        <div className="hlcm__body">
          <div className="hlcm__self-financing">
            <span>{t('luminor.calc.selfFinancing')}</span>
            <strong>{formatPrice(results.autoSelfFinance)}</strong>
            <span>({calcState.selfFinancing}%)</span>
          </div>
          <div className="hlcm__period">
            <div className="hlcm__period-label">{t('luminor.calc.period')}</div>
            <Select
              className="hlcm__period-select"
              extraSmall
              options={
                defaults.maxPeriod
                  ? periodOptions.filter((option) => Number(option.get('value')) <= Number(defaults.maxPeriod))
                  : periodOptions
              }
              selected={calcState.period}
              getValue={(val) => setCalcState('period', val)}
              translateOption={(option) => t(option.get('name'), { count: parseInt(option.get('value'), 10) })}
              gaEvent={() => gaEventHandler('update_field_value', 'period')}
            />
          </div>
        </div>
      </div>
      <Button
        style={!withButton ? { color: accentColor } : { backgroundColor: accentColor }}
        link={!withButton}
        primary={withButton}
        text={t(`loanCalc.cta.${bank}`)}
        href={links.button}
        className="hlcm__cta"
        newTab
        onClick={() => gaEventHandler('button_click', bank)}
      />
    </div>
  );
};

export default CompactLoanCalculator;
