import { apiFetch } from './basics';
import config from './config';
import { authorizedFetch } from '@/api/City24Api/user';

function getNews(query, init, locale = null) {
  return apiFetch(`/${locale ?? config.locale}/news`, query, init);
}
function getSimilarArticles(id, query, init) {
  return apiFetch(`/${config.locale}/news/${id}/similar`, query, init);
}
function getPopularArticles(query, init) {
  return apiFetch(`/${config.locale}/popular_news`, query, init);
}
function getArticle(id, init, isPreview) {
  return isPreview ? authorizedFetch(`/admin/news/${id}`, {}, init) : apiFetch(`/news/${id}`, { globalId: true }, init);
}
function getPrevNextArticles(id, init) {
  return apiFetch(`/news/${id}/prevnext`, { globalId: true }, init);
}
function getTags(query, init) {
  return apiFetch(`/${config.locale}/news_tags`, query, init);
}
export default {
  getNews,
  getSimilarArticles,
  getPopularArticles,
  getArticle,
  getPrevNextArticles,
  getTags,
};
