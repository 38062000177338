import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import ReactGA from '@/utils/ga';
import { ContainerQuery } from 'react-container-query';
import { isMobile } from 'react-device-detect';
import Markdown from 'markdown-to-jsx';
import type { TFunction } from 'next-i18next';
import Portal from '@city24/common/enums/Portal';

import { RESULTS_VIEW, DEFAULT_LANG } from '@/constants';
import { SLUGS } from '@/constants/links';
import { LINKS } from '@/constants/routes';
import { SORTING_VALUES } from '@/constants/filters';
import { OBJECT_TYPES, TRANSACTION_TYPES } from '@/constants/object';
import { GA_FOOTER_MENU } from '@/constants/ga';
import { D_SPECIAL, D_SPECIAL_STICKY } from '@/constants/ad';
import { useAppDispatch, useAppSelector } from '@/hooks';

import { composeSearchURLFromParams } from '@/utils/search';
import { getBreakpointsMin } from '@/utils/ContainerBreakpoints';

import { getLanguage, getPrefixedRoutes } from '@/selectors/appSelectors';
import { setSorting } from '@/components/search/SearchActions';

import SocialMediaLinks from '@/components/socialMediaLinks/SocialMediaLinks';
import Container from '@/components/container/Container';
import Ad from '@/components/Ad/Ad';
import Anchor from '@/components/link/Anchor';

import logoBCG from '@/img/logo_BCG.svg';

import SiteLinks from '@/components/siteLinks/SiteLinks';
import FooterSection from './FooterSection';
import FooterSectionItem from './FooterSectionItem';
import AppContext from '@/components/appContext';
import Image from '../img/Image';

export interface FooterProps {
  bottomNav?: boolean;
  hideStickyBanner?: boolean;
  disclaimers?: { key: string; value: string }[];
  onFooterLinkClick?: () => void;
}
export interface FooterWrapperProps extends FooterProps {
  t: TFunction;
}

function Footer(props: FooterWrapperProps) {
  const { t, bottomNav, hideStickyBanner, disclaimers = [], onFooterLinkClick = () => {} } = props;
  const dispatch = useAppDispatch();
  const language = useAppSelector(getLanguage);
  const routes = useAppSelector(getPrefixedRoutes);
  const { isNextJs } = useContext(AppContext);

  const setSearchresultsSorting = (sorting = SORTING_VALUES.default) => {
    dispatch(setSorting(sorting));
  };

  const gaEventHandler = (action: string, label?: string) => {
    ReactGA.event({
      category: GA_FOOTER_MENU,
      action,
      label,
    });
  };

  const getDomainLink = (tld: Portal) => {
    let domain = 'https://www.city24.';
    if (tld === Portal.City24EE) {
      domain += 'ee';
    } else {
      domain += 'lv';
    }
    return language === DEFAULT_LANG ? domain : `${domain}/${language}`;
  };

  return (
    <>
      <ContainerQuery query={getBreakpointsMin('footer')}>
        {(params) => (
          <footer
            className={classNames('footer', {
              'footer--bottom-nav': bottomNav,
              ...params,
            })}
          >
            <Container center>
              <SiteLinks t={t} onFooterLinkClick={onFooterLinkClick} />
            </Container>
            <Container center>
              <div className="footer__sections">
                <FooterSection title={t('footer.search.title')}>
                  {PORTAL_EE ? (
                    <>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.SALE, {
                          locationIds: '181-parish',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForSaleTallinn');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.apartmentSaleTallinn')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.SALE, {
                          locationIds: '20277-parish',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForSaleTartu');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.apartmentSaleTartu')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.SALE, {
                          locationIds: '117-parish',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForSaleNarva');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.apartmentSaleNarva')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.RENT, {
                          locationIds: '181-parish',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForRentTallinn');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.rentalApartmentTallinn')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.RENT, {
                          locationIds: '20277-parish',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForRentTartu');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.rentalApartmentTartu')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.RENT, {
                          locationIds: '20409-city',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForRentPärnu');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.rentalApartmentParnu')}
                      </FooterSectionItem>
                    </>
                  ) : (
                    <>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.SALE, {
                          locationIds: '25110-county',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForSaleRiga');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.apartmentSaleRiga')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.SALE, {
                          locationIds: '25114-county',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForSaleJurmala');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.apartmentSaleJurmala')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.SALE, {
                          locationIds: '25892-city',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForSaleCentralRiga');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.apartmentSaleCentralRiga')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.RENT, {
                          locationIds: '25110-county',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForRentRiga');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.rentalApartmentRiga')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.RENT, {
                          locationIds: '25114-county',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForRentJurmala');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.rentalApartmentJurmala')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.RENT, {
                          locationIds: '25892-city',
                        })}
                        onClick={() => {
                          gaEventHandler('click_apartmentsForRentCentralRiga');
                          onFooterLinkClick();
                        }}
                      >
                        {t('footer.search.rentalApartmentCentralRiga')}
                      </FooterSectionItem>
                    </>
                  )}
                  <FooterSectionItem
                    to={composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.RENT)}
                    onClick={() => {
                      gaEventHandler('click_latestRentals');
                      setSearchresultsSorting(SORTING_VALUES.latest);
                      onFooterLinkClick();
                    }}
                  >
                    {t('footer.search.LatestRentals')}
                  </FooterSectionItem>
                  <FooterSectionItem to={routes.newProjects} onClick={() => gaEventHandler('click_newProjects')}>
                    {t('footer.search.newProjects')}
                  </FooterSectionItem>
                  <FooterSectionItem
                    to={routes.companySearch}
                    external
                    onClick={() => gaEventHandler('click_realEstateAgenciesBrokers')}
                  >
                    {t('footer.search.agenciesAndBrokers')}
                  </FooterSectionItem>
                  <FooterSectionItem
                    to={{
                      pathname: composeSearchURLFromParams(routes, OBJECT_TYPES.Apartment, TRANSACTION_TYPES.RENT),
                      query: { loadView: RESULTS_VIEW.map, key: 'footerMap' },
                    }}
                    onClick={() => {
                      gaEventHandler('click_mapSearch');
                      onFooterLinkClick();
                    }}
                  >
                    {t('footer.search.mapSearch')}
                  </FooterSectionItem>
                </FooterSection>
                <FooterSection title={t('footer.news.title')}>
                  {/* PORTAL_EE && isNativeLanguage && (
                    <FooterSectionLink
                      to={url(language, L.marketOverview)}
                      external
                      onClick={() => gaEventHandler('click_news_kinnisvaraturuYlevaated')}
                    >
                      {t('footer.news.marketOverview')}
                    </FooterSectionLink>
                  ) */}
                  {SLUGS.NEW_PROJECT[language] && (
                    <FooterSectionItem
                      to={generatePath(routes.news, { tag: SLUGS.NEW_PROJECT[language] })}
                      onClick={() =>
                        gaEventHandler(PORTAL_EE ? 'click_news_uusarendused' : 'click_news_jaunieProjekti')
                      }
                    >
                      {t('footer.news.newProject')}
                    </FooterSectionItem>
                  )}
                  {SLUGS.COMMERCIAL[language] && (
                    <FooterSectionItem
                      to={generatePath(routes.news, { tag: SLUGS.COMMERCIAL[language] })}
                      onClick={() => gaEventHandler(PORTAL_EE ? 'click_news_arikinnisvara' : 'click_news_biroji')}
                    >
                      {t('footer.news.commercial')}
                    </FooterSectionItem>
                  )}
                  {PORTAL_EE && SLUGS.RENTAL[language] && (
                    <FooterSectionItem
                      to={generatePath(routes.news, { tag: SLUGS.RENTAL[language] })}
                      onClick={() => gaEventHandler('click_news_yyrimine')}
                    >
                      {t('footer.news.rental')}
                    </FooterSectionItem>
                  )}
                  {SLUGS.LOAN[language] && (
                    <FooterSectionItem
                      to={generatePath(routes.news, { tag: SLUGS.LOAN[language] })}
                      onClick={() =>
                        gaEventHandler(PORTAL_EE ? 'click_news_kodulaen' : 'click_news_investicijasUnKrediti')
                      }
                    >
                      {t('footer.news.loan')}
                    </FooterSectionItem>
                  )}
                  {PORTAL_EE && SLUGS.ADVICE[language] && (
                    <FooterSectionItem
                      to={generatePath(routes.news, { tag: SLUGS.ADVICE[language] })}
                      onClick={() => gaEventHandler('click_news_nouanne')}
                    >
                      {t('footer.news.advice')}
                    </FooterSectionItem>
                  )}
                  {PORTAL_EE && SLUGS.LEGISLATION[language] && (
                    <FooterSectionItem
                      to={generatePath(routes.news, { tag: SLUGS.LEGISLATION[language] })}
                      onClick={() => gaEventHandler('click_news_seadusandlus')}
                    >
                      {t('footer.news.legislation')}
                    </FooterSectionItem>
                  )}
                  {PORTAL_EE && SLUGS.INVESTMENT[language] && (
                    <FooterSectionItem
                      to={generatePath(routes.news, { tag: SLUGS.INVESTMENT[language] })}
                      onClick={() => gaEventHandler('click_news_investeerimine')}
                    >
                      {t('footer.news.investment')}
                    </FooterSectionItem>
                  )}
                  {PORTAL_LV && SLUGS.PRICE_CHANGE[language] && (
                    <FooterSectionItem
                      to={generatePath(routes.news, { tag: SLUGS.PRICE_CHANGE[language] })}
                      onClick={() => gaEventHandler('click_news_dzivokluCenas')}
                    >
                      {t('footer.news.priceChange')}
                    </FooterSectionItem>
                  )}
                  {PORTAL_LV && SLUGS.CONSTRUCTION[language] && (
                    <FooterSectionItem
                      to={generatePath(routes.news, { tag: SLUGS.CONSTRUCTION[language] })}
                      onClick={() => gaEventHandler('click_news_buvnieciba')}
                    >
                      {t('footer.news.construction')}
                    </FooterSectionItem>
                  )}
                  {PORTAL_LV && SLUGS.INTERIOR_DESIGN[language] && (
                    <FooterSectionItem
                      to={generatePath(routes.news, { tag: SLUGS.INTERIOR_DESIGN[language] })}
                      onClick={() => gaEventHandler('click_news_IinterjeraDizains')}
                    >
                      {t('footer.news.interiorDesign')}
                    </FooterSectionItem>
                  )}
                  <FooterSectionItem
                    to={generatePath(routes.news)}
                    onClick={() => gaEventHandler('click_realEstateNews')}
                  >
                    {t('footer.news.realestateNews')}
                  </FooterSectionItem>
                </FooterSection>
                <FooterSection title={t('footer.site.title')}>
                  <FooterSectionItem
                    to={generatePath(routes.myObject, { id: 'new' })}
                    onClick={() => gaEventHandler('click_loginToPrivateUserPage')}
                  >
                    {t('footer.site.insertAdPrivate')}
                  </FooterSectionItem>
                  <FooterSectionItem
                    to={LINKS.insertAdBroker}
                    external
                    onClick={() => gaEventHandler('click_loginToBrokerPage')}
                  >
                    {t('footer.site.insertAdBroker')}
                  </FooterSectionItem>
                  <FooterSectionItem
                    to={generatePath(routes.pricelist)}
                    onClick={() => gaEventHandler('click_priceListPrivateUser')}
                    external={!isNextJs}
                    sameTab={!isNextJs}
                  >
                    {t('footer.site.standardUserPriceList')}
                  </FooterSectionItem>
                  <FooterSectionItem
                    to={generatePath(routes.businessClientPricelist)}
                    onClick={() => gaEventHandler('click_priceListBrokers')}
                    external={!isNextJs}
                    sameTab={!isNextJs}
                  >
                    {t('footer.site.businessClientPriceList')}
                  </FooterSectionItem>
                  <FooterSectionItem
                    to={
                      PORTAL_EE
                        ? generatePath(routes.bannersPricelist)
                        : `${process.env.NEXT_PUBLIC_STATIC_HOST_URL}/media/baneru_izvietojumi_city24.pdf`
                    }
                    onClick={() => gaEventHandler('click_priceListBanners')}
                    external={PORTAL_LV || !isNextJs}
                    sameTab={PORTAL_EE && !isNextJs}
                  >
                    {t('footer.site.bannersPriceList')}
                  </FooterSectionItem>
                  {PORTAL_EE && (
                    <FooterSectionItem
                      to={generatePath(routes.joiningCity24)}
                      onClick={() => gaEventHandler('click_joinForCompany')}
                      external={!isNextJs}
                      sameTab={!isNextJs}
                    >
                      {t('footer.site.joinForCompany')}
                    </FooterSectionItem>
                  )}
                  <FooterSectionItem
                    to={generatePath(routes.contacts)}
                    onClick={() => gaEventHandler('click_contacts')}
                    external={!isNextJs}
                    sameTab={!isNextJs}
                  >
                    {t('footer.site.contacts')}
                  </FooterSectionItem>
                  <FooterSectionItem
                    to={generatePath(routes.termsOfUse)}
                    onClick={() => gaEventHandler('click_conditions')}
                    external={!isNextJs}
                    sameTab={!isNextJs}
                  >
                    {t('footer.site.conditions')}
                  </FooterSectionItem>
                  {PORTAL_EE ? (
                    <>
                      <FooterSectionItem
                        to={generatePath(routes.privacyRegulations)}
                        onClick={() => gaEventHandler('click_privacyPolicy')}
                        external={!isNextJs}
                        sameTab={!isNextJs}
                      >
                        {t('footer.site.privacyPolicy')}
                      </FooterSectionItem>
                      <FooterSectionItem
                        to={generatePath(routes.cookiePolicy)}
                        onClick={() => gaEventHandler('click_cookies')}
                        external={!isNextJs}
                        sameTab={!isNextJs}
                      >
                        {t('footer.site.cookies')}
                      </FooterSectionItem>
                    </>
                  ) : (
                    <FooterSectionItem
                      to={generatePath(routes.privacyRegulations)}
                      onClick={() => gaEventHandler('click_privacyPolicy')}
                      external={!isNextJs}
                      sameTab={!isNextJs}
                    >
                      {t('footer.site.privacyAndCookies')}
                    </FooterSectionItem>
                  )}
                  <li>
                    <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                      {t('footer.site.cookieSettings')}
                    </a>
                  </li>
                </FooterSection>
                <nav className="footer__section">
                  <SocialMediaLinks className="footer__social" t={t} gaCategory={GA_FOOTER_MENU} />
                  <nav className="footer__bcg-links">
                    <Anchor className="footer__bcg-logo" href="https://balticclassifieds.com">
                      <Image src={logoBCG} alt="BCG logo" />
                    </Anchor>
                    <ul className="footer__domains">
                      {PORTAL_LV && (
                        <li>
                          <Anchor href={getDomainLink(Portal.City24LV)}>City24.lv</Anchor>
                        </li>
                      )}
                      <li>
                        <Anchor href={getDomainLink(Portal.City24EE)}>City24.ee</Anchor>
                      </li>
                      {!PORTAL_LV && (
                        <li>
                          <Anchor href={getDomainLink(Portal.City24LV)}>City24.lv</Anchor>
                        </li>
                      )}
                      <li>
                        <Anchor href="https://www.skelbiu.lt">Skelbiu.lt</Anchor>
                      </li>
                      <li>
                        <Anchor href="https://www.autoplius.lt">Autoplius.lt</Anchor>
                      </li>
                      <li>
                        <Anchor href="https://www.aruodas.lt">Aruodas.lt</Anchor>
                      </li>
                      <li>
                        <Anchor href="https://www.cvbankas.lt">CVbankas.lt</Anchor>
                      </li>
                      <li>
                        <Anchor href="https://www.kainos.lt">Kainos.lt</Anchor>
                      </li>
                      <li>
                        <Anchor href="https://www.paslaugos.lt">Paslaugos.lt</Anchor>
                      </li>
                      <li>
                        <Anchor href="https://www.auto24.ee">auto24.ee</Anchor>
                      </li>
                      <li>
                        <Anchor href="https://www.kv.ee">KV.ee</Anchor>
                      </li>
                      <li>
                        <Anchor href="https://www.osta.ee">Osta.ee</Anchor>
                      </li>
                      <li>
                        <Anchor href="https://www.kuldnebors.ee">KuldneBörs.ee</Anchor>
                      </li>
                      {PORTAL_LV && (
                        <li>
                          <Anchor href="https://getapro.lv">GetaPro.lv</Anchor>
                        </li>
                      )}
                      <li>
                        <Anchor href="https://getapro.ee">GetaPro.ee</Anchor>
                      </li>
                      {!PORTAL_LV && (
                        <li>
                          <Anchor href="https://getapro.lv">GetaPro.lv</Anchor>
                        </li>
                      )}
                    </ul>
                  </nav>
                </nav>
              </div>
              <div className="footer__text">
                {disclaimers.length ? disclaimers.map((item) => <p key={item.key}>{item.value}</p>) : null}
                <p>
                  <Markdown>{t(`footer.slogan.${PORTAL_LV ? 'lv' : 'ee'}`)}</Markdown>
                </p>
                <p>
                  <Markdown>
                    {t(`footer.version.${PORTAL_LV ? 'lv' : 'ee'}`, {
                      version: APP_VERSION,
                      year: new Date().getFullYear(),
                    })}
                  </Markdown>
                </p>
              </div>
            </Container>
          </footer>
        )}
      </ContainerQuery>
      {!isMobile && !hideStickyBanner && <Ad master={D_SPECIAL} id={D_SPECIAL_STICKY} />}
    </>
  );
}

Footer.propTypes = {
  bottomNav: PropTypes.bool,
  hideStickyBanner: PropTypes.bool,
  disclaimers: PropTypes.array,
  onFooterLinkClick: PropTypes.func,
};

Footer.defaultProps = {
  bottomNav: false,
  hideStickyBanner: false,
  disclaimers: [],
  onFooterLinkClick: () => {},
};

export default Footer;
