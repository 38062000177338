import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { Collection } from 'immutable';
import { withTranslation } from 'react-i18next';
import ReactGA from '@/utils/ga';
import * as userActions from '../../../actions/userActions';

import Modal from '../Modal';
import ModalFooter from '../ModalFooter';
import Button from '../../button/Button';
import ButtonGroup from '../../button/ButtonGroup';
import ModalHeader from '../ModalHeader';
import { GA_MY_SEARCHES } from '@/constants/ga';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  removeSavedSearch: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  emailAlerts: PropTypes.bool,
};

const defaultProps = {
  id: null,
  name: null,
  emailAlerts: false,
};

const SavedSearchRemoveModal = (props) => {
  const { isOpen, closeModal, removeSavedSearch, id, name, emailAlerts, t } = props;

  const gaEventFunction = (action, label) =>
    ReactGA.event({
      category: GA_MY_SEARCHES,
      action,
      label: label ? label.toString() : label,
    });

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} gaEvent={() => gaEventFunction('close_button')}>
      <ModalHeader title={[t('common.remove'), ' ', name, '?']} />
      {emailAlerts && <p>{t('removeSavedSearchModal.notification')}</p>}
      <ModalFooter>
        <ButtonGroup>
          <Button
            transparent
            text={t('common.cancel')}
            onClick={() => {
              gaEventFunction('cancel_button');
              closeModal();
            }}
          />
          <Button
            primary
            text={t('common.remove')}
            onClick={() => {
              gaEventFunction('remove_submit_button');
              removeSavedSearch(id);
              closeModal();
            }}
          />
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

SavedSearchRemoveModal.propTypes = propTypes;
SavedSearchRemoveModal.defaultProps = defaultProps;

// function mapStateToProps(state) {
//   return {
//     searchFilters: getSearchFilters(state)
//   };
// }

const mapDispatchToProps = { ...userActions };

export default compose(withTranslation(), connect(null, mapDispatchToProps))(SavedSearchRemoveModal);
