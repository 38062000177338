import React, { useContext } from 'react';
import { LinkProps } from '@/components/link/LinkInterface';
import AppContext from '@/components/appContext';

interface Props {
  title: string;
  children: React.ReactNode;
  link?: LinkProps['href'];
  onClick?: LinkProps['onClick'];
}

function FooterSection({ title, children, link, onClick }: Props) {
  const { Link } = useContext(AppContext);
  return (
    <nav className="footer__section">
      <div className="h2 footer__title">
        {link ? (
          <Link href={link} onClick={onClick}>
            {title}
          </Link>
        ) : (
          title
        )}
      </div>
      <ul className="footer__links">{children}</ul>
    </nav>
  );
}

export default FooterSection;
