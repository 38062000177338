import React from 'react';
import Immutable, { Collection } from 'immutable';
import PropTypes from 'prop-types';

import Input from '../../input/Input';
import InlineSelect from '../../select/InlineSelect';
import FilterFooter from '../Filter/FilterFooter';
import InputRange from '../../input/InputRange';
import { LOT_SIZE_TYPE_OPTIONS } from '@/constants/filters';
import ReactGA from '@/utils/ga';
import { GA_SEARCH } from '@/constants/ga';

const propTypes = {
  footerHidden: PropTypes.bool,
  minLotSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxLotSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lotSizeType: PropTypes.instanceOf(Collection),
  setLotSizeRange: PropTypes.func,
  handleMinLotSize: PropTypes.func,
  handleMaxLotSize: PropTypes.func,
  handleLotSizeType: PropTypes.func,
};

const defaultProps = {
  footerHidden: false,
  minLotSize: '',
  maxLotSize: '',
  lotSizeType: '',
  handleMinLotSize: null,
  handleMaxLotSize: null,
  handleLotSizeType: null,
  setLotSizeRange: null,
};

const LotSizeRange = (props) => {
  const { minLotSize, maxLotSize, lotSizeType, handleMinLotSize, handleMaxLotSize, handleLotSizeType, t } = props;

  return (
    <div className="filter__price-range">
      <div className="filter__heading">
        <h4 className="filter__title">{t('search.lotSize')}</h4>
        <InlineSelect
          small
          options={LOT_SIZE_TYPE_OPTIONS}
          minimal
          selected={lotSizeType}
          getValue={handleLotSizeType}
          gaCategory={GA_SEARCH}
          gaAction="select_lotSize"
          gaLabels={{
            ha: 'sizeHa',
            m2: 'sizeM2',
          }}
        />
      </div>
      <InputRange>
        <Input
          notched
          label="min"
          value={minLotSize}
          type="number"
          unit={lotSizeType.get('name')}
          getValue={handleMinLotSize}
          gaEvent={() =>
            ReactGA.event({
              category: GA_SEARCH,
              action: 'select_lotSize',
              label: 'rangeMin',
            })
          }
        />
        <Input
          notched
          label="max"
          value={maxLotSize}
          type="number"
          unit={lotSizeType.get('name')}
          getValue={handleMaxLotSize}
          gaEvent={() =>
            ReactGA.event({
              category: GA_SEARCH,
              action: 'select_lotSize',
              label: 'rangeMax',
            })
          }
        />
      </InputRange>
    </div>
  );
};

LotSizeRange.propTypes = propTypes;
LotSizeRange.defaultProps = defaultProps;

export default LotSizeRange;
