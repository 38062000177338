import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import OBJECT_SPEC_TYPES, { ObjectSpecType } from './specTypes';

const OBJECT_MAIN_SPECS: Readonly<Partial<Record<UnitTypeLiteral, ReadonlyArray<ObjectSpecType>>>> = {
  [UnitTypeLiteral.APARTMENT]: [OBJECT_SPEC_TYPES.property_size, OBJECT_SPEC_TYPES.room_count, OBJECT_SPEC_TYPES.FLOOR],
  [UnitTypeLiteral.HOUSE]: [
    OBJECT_SPEC_TYPES.property_size,
    OBJECT_SPEC_TYPES.room_count,
    OBJECT_SPEC_TYPES.FLOOR,
    OBJECT_SPEC_TYPES.lot_size,
  ],
  [UnitTypeLiteral.HOUSE_SHARE]: [
    OBJECT_SPEC_TYPES.property_size,
    OBJECT_SPEC_TYPES.room_count,
    OBJECT_SPEC_TYPES.FLOOR,
    OBJECT_SPEC_TYPES.lot_size,
  ],
  [UnitTypeLiteral.COMMERCIAL]: [
    OBJECT_SPEC_TYPES.property_size,
    OBJECT_SPEC_TYPES.room_count,
    OBJECT_SPEC_TYPES.FLOOR,
    OBJECT_SPEC_TYPES.lot_size,
  ],
  [UnitTypeLiteral.LAND]: [OBJECT_SPEC_TYPES.lot_size],
  [UnitTypeLiteral.COTTAGE_OR_VILLA]: [
    OBJECT_SPEC_TYPES.property_size,
    OBJECT_SPEC_TYPES.room_count,
    OBJECT_SPEC_TYPES.FLOOR,
    OBJECT_SPEC_TYPES.lot_size,
  ],
  [UnitTypeLiteral.GARAGE]: [
    OBJECT_SPEC_TYPES.property_size,
    OBJECT_SPEC_TYPES.room_count,
    OBJECT_SPEC_TYPES.FLOOR,
    OBJECT_SPEC_TYPES.lot_size,
  ],
  [UnitTypeLiteral.NEW_PROJECT]: [
    OBJECT_SPEC_TYPES.available_units,
    OBJECT_SPEC_TYPES.property_size,
    OBJECT_SPEC_TYPES.room_count,
    OBJECT_SPEC_TYPES.TOTAL_FLOORS,
  ],
  [UnitTypeLiteral.MODULAR_HOUSE]: [OBJECT_SPEC_TYPES.property_size, OBJECT_SPEC_TYPES.room_count],
};

export type MainSpecType = NonNullable<(typeof OBJECT_MAIN_SPECS)[UnitTypeLiteral]>[number];

export default OBJECT_MAIN_SPECS;
