import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  text: PropTypes.string.isRequired,
};

const defaultProps = {};

const Caption = (props) => {
  const { text } = props;

  return <div className="caption">{text}</div>;
};

Caption.propTypes = propTypes;
Caption.defaultProps = defaultProps;

export default Caption;
