import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Language from '@city24/common/enums/Language';
import hydrateAction from '@/actions/hydrateAction';

export const appInitialState: {
  locale: Language;
  error500Count: number;
  forceReload: boolean;
  headerHidden: boolean;
} = {
  locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
  error500Count: 0,
  forceReload: false,
  headerHidden: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    setLocale(state, action: PayloadAction<Language>) {
      state.locale = action.payload;
    },
    setForceReload(state, action) {
      state.forceReload = true;
    },
    toggleHeader(state, action: PayloadAction<boolean>) {
      state.headerHidden = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase('API_ADD_ERROR_COUNT', (state, action) => {
        state.error500Count += 1;
      })
      .addCase('API_RESET_ERROR_COUNT', (state, action) => {
        state.error500Count = 0;
      })
      .addCase(hydrateAction, (state, action) => {
        state.locale = action.payload.app.locale;
      });
  },
});

export const { actions: appActions } = appSlice;

export default appSlice.reducer;
