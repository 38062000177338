/* eslint-disable react/no-danger */
import React from 'react';
import classNames from 'classnames';

import Button from '@/components/button/Button';
import { scrollToElement } from '@/utils/helpers';
import { TFunction } from 'react-i18next';
import Slogan from './Slogan';

interface Props {
  t: TFunction;
  maxHeight: number;
  slogan?: string;
  showSlogan?: boolean;
  description?: string;
  introduction?: string;
  largeSlogan?: boolean;
  opened?: boolean;
  gaEvent: (label: string) => void;
}

interface State {
  opened: boolean;
}

class Description extends React.Component<Props, State> {
  descriptionRef: React.RefObject<HTMLDivElement>;

  descriptionContentRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      opened: false,
    };

    this.descriptionRef = React.createRef();
    this.descriptionContentRef = React.createRef();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.description !== this.props.description) {
      this.setState({ opened: false });
    }
  }

  getOverflow() {
    const content = this.descriptionContentRef.current;

    const offset = 100;
    if (content) {
      const cHeight = content.getBoundingClientRect().height;
      return cHeight - offset > this.props.maxHeight;
    }
    return false;
  }

  toggleDescription = () => {
    const { gaEvent } = this.props;
    const { opened } = this.state;

    if (opened) {
      scrollToElement({ el: this.descriptionRef.current, ifTopHidden: true });
    }

    this.setState({ opened: !opened });
    if (gaEvent) gaEvent(opened ? 'click_readLess' : 'click_readMore');
  };

  render() {
    const { slogan, largeSlogan, description, introduction, opened: forceOpen, t, showSlogan } = this.props;
    const opened = forceOpen || this.state.opened;

    const isOverFlow = opened ? false : this.getOverflow();

    const objectDescriptionStyle = classNames('object-description', {
      'object-description--overflow': isOverFlow,
      'object-description--opened': opened,
      'object-description--large-slogan': largeSlogan,
    });

    return (
      <div className={objectDescriptionStyle} ref={this.descriptionRef}>
        {showSlogan && slogan && (
          <Slogan inDescription largeSlogan={largeSlogan}>
            {slogan}
          </Slogan>
        )}
        {description && (
          <>
            <div
              className="object-description__description"
              style={{ maxHeight: isOverFlow ? `${this.props.maxHeight}px` : 'none' }}
            >
              <div ref={this.descriptionContentRef}>
                {introduction && <p dangerouslySetInnerHTML={{ __html: introduction }} />}
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>

            {isOverFlow || opened ? (
              <Button
                link
                icon={opened ? 'angle-up' : 'angle-down'}
                text={opened ? t('objectDetail.readLess') : t('objectDetail.readMore')}
                onClick={this.toggleDescription}
              />
            ) : null}
          </>
        )}
      </div>
    );
  }
}

export default Description;
