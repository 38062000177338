import React, { useContext } from 'react';
import { LinkProps } from '@/components/link/LinkInterface';
import { buttonStyle, ButtonStyleProps } from './NextButton';
import ButtonContent from './ButtonContent';
import AppContext from '@/components/appContext';

interface Props extends ButtonStyleProps, Omit<LinkProps, 'children' | 'className'> {
  children?: React.ReactNode;
  newTab?: boolean;
}

function LinkButton({ children, href, newTab, ...otherProps }: Props): React.ReactElement {
  const { Link } = useContext(AppContext);
  const { icon, iconPosition } = otherProps;
  const [linkStyle, linkProps] = buttonStyle(otherProps, children, true);
  return (
    <Link {...linkProps} href={href} className={linkStyle} target={newTab ? '_blank' : undefined}>
      <ButtonContent icon={icon} iconPosition={iconPosition}>
        {children}
      </ButtonContent>
    </Link>
  );
}

export default LinkButton;
