import React from 'react';
import { TFunction, Trans, withTranslation } from 'react-i18next';
import * as Sentry from '@sentry/nextjs';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getError500Count, getForceReload } from '@/selectors/appSelectors';
import Empty from '@/components/empty/Empty';
import ButtonGroup from '@/components/button/ButtonGroup';
import Button from '@/components/button/Button';
import Header from '@/components/header/Header';
import Footer from '@/components/Footer/Footer';
import errorImage from '@/img/icons/something-went-wrong.svg';
import Markdown from 'markdown-to-jsx';
import { getUuid } from '@/api/auth';

type Props = {
  t: TFunction<'local'>;
  children: React.ReactNode;
  error500Count: number;
  forceReload: boolean;
};

class ErrorHandler extends React.PureComponent<Props> {
  reloadTimeout?: number;

  uuid: string | null;

  constructor(props: Props) {
    super(props);
    this.reloadTimeout = undefined;
    this.uuid = getUuid();
  }

  //   static getDerivedStateFromError(error) {
  //     // Update state so the next render will show the fallback UI.
  //     return { hasError: true };
  //   }

  //   componentDidCatch(error, info) {
  //     // You can also log the error to an error reporting service
  //     console.error(error, info);
  //     Sentry.withScope((scope) => {
  //       scope.setExtras(info);
  //       Sentry.captureException(error);
  //     });
  //   }

  reloadPage() {
    clearTimeout(this.reloadTimeout);
    this.reloadTimeout = window.setTimeout(() => {
      // reload the page only when page is visible
      if (!document.hidden) {
        window.location.reload();
      }
    }, 15 * 1000);
  }

  render() {
    const { children, error500Count, forceReload, t } = this.props;

    if (error500Count >= 3 || forceReload) {
      this.reloadPage();
      return (
        <Empty
          image={errorImage}
          title={t('serverMaintenance.title', { portal: PORTAL_EE ? 'City24.ee' : 'City24.lv' })}
          subtitle={
            <>
              <Markdown>
                {t('serverMaintenance.subtitle', { email: PORTAL_EE ? 'klienditugi@city24.ee' : 'info@city24.lv' })}
              </Markdown>
              <div style={{ marginTop: '1rem' }}>
                <small>{this.uuid}</small>
              </div>
            </>
          }
        />
      );
    }

    return (
      <Sentry.ErrorBoundary
        fallback={
          <>
            <Header t={t} />
            <Empty
              image={errorImage}
              title={t('errorPage.somethingWentWrong.title')}
              subtitle={t('errorPage.somethingWentWrong.subtitle')}
            >
              <ButtonGroup center>
                <Button primary text={t('errorPage.reloadPage')} onClick={() => window.location.reload()} />
              </ButtonGroup>
            </Empty>
            <Footer />
          </>
        }
      >
        {children}
      </Sentry.ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    error500Count: getError500Count(state),
    forceReload: getForceReload(state),
  };
}

export default compose(withTranslation('local'), connect(mapStateToProps))(ErrorHandler);
