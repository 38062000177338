import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from '@/utils/ga';

import { GA_SEARCH } from '@/constants/ga';
import { shouldSearchByTotalFloors } from '@/constants/filters';

import Input from '@/components/input/Input';
import InputRange from '@/components/input/InputRange';
import ToggleField from '@/components/toggle/ToggleField';

const propTypes = {
  minFloor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxFloor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onlyLastFloor: PropTypes.bool.isRequired,
  handleMinFloor: PropTypes.func,
  handleMaxFloor: PropTypes.func,
  handleOnlyLastFloor: PropTypes.func,
};

const defaultProps = {
  minFloor: '',
  maxFloor: '',
  handleMinFloor: null,
  handleMaxFloor: null,
  handleOnlyLastFloor: null,
};
const FloorRange = (props) => {
  const gaEvent = (label, checked) => {
    ReactGA.event({
      category: GA_SEARCH,
      action: checked || checked === undefined ? 'select_floors' : 'unselect_floors',
      label,
    });
  };

  const { minFloor, maxFloor, onlyLastFloor, handleMinFloor, handleMaxFloor, handleOnlyLastFloor, objectType, t } =
    props;

  return (
    <div className="filter__price-range">
      <div className="filter__heading">
        <h4 className="filter__title">{t('search.floor')}</h4>
      </div>
      <InputRange>
        <Input
          notched
          label="min"
          value={minFloor}
          type="number"
          getValue={handleMinFloor}
          gaEvent={() => gaEvent('rangeMin')}
        />
        <Input
          notched
          label="max"
          value={maxFloor}
          type="number"
          getValue={handleMaxFloor}
          gaEvent={() => gaEvent('rangeMax')}
        />
      </InputRange>
      {!shouldSearchByTotalFloors(objectType.get('value')) && (
        <ToggleField
          id="only-last-floor"
          title={t('search.onlyLastFloor')}
          value="onlyLastFloor"
          checked={onlyLastFloor}
          getValue={handleOnlyLastFloor}
          gaEvent={(checked) => gaEvent('lastFloor', checked)}
        />
      )}
    </div>
  );
};

FloorRange.propTypes = propTypes;
FloorRange.defaultProps = defaultProps;

export default FloorRange;
