import produce from 'immer';
import {
  LOAD_AD_SLAVE,
  LOAD_AD_MASTER,
  ADD_AD_MASTER,
  SET_AD_KEY_VARS,
  REMOVE_AD_SLAVE,
  REMOVE_AD_MASTER,
  SET_AD_BANNER,
} from '@/constants/actions';
import { AD_COMMON_VARS } from '@/constants/ad';

export type AdSlave = {
  script: boolean;
  height: number;
};

export type AdMaster = {
  script: boolean;
  keys: string[];
  vars: { [key: string]: any };
};

const initialState: {
  masters: { [masterId: string]: AdMaster };
  slaves: { [masterId: string]: { [slaveId: string]: AdSlave } };
} = {
  masters: {},
  slaves: {},
};

const adReducer = produce((state, action) => {
  const useMaster = (masterId: string) => {
    let master = state.masters[masterId];
    if (!master) {
      master = { script: false, keys: [], vars: {} };
      state.masters[action.masterId] = master;
      state.slaves[action.masterId] = {};
    }
    return master;
  };
  switch (action.type) {
    case ADD_AD_MASTER: {
      useMaster(action.masterId);
      state.slaves[action.masterId][action.slaveId] = { script: false, height: 0 };
      break;
    }
    case REMOVE_AD_MASTER:
      delete state.masters[action.masterId];
      delete state.slaves[action.masterId];
      break;

    case LOAD_AD_MASTER: {
      const master = useMaster(action.masterId);
      master.script = true;
      master.keys = action.keys;
      master.vars = action.vars;
      break;
    }

    case LOAD_AD_SLAVE:
      useMaster(action.masterId);
      state.slaves[action.masterId][action.slaveId].script = true;
      break;

    case REMOVE_AD_SLAVE: {
      const slaves = state.slaves[action.masterId];
      if (slaves) {
        delete slaves[action.slaveId];
        // if last slave of master, remove master
        if (Object.keys(slaves).length === 0) {
          delete state.masters[action.masterId];
          delete state.slaves[action.masterId];
        }
      }
      break;
    }

    case SET_AD_KEY_VARS: {
      const master = useMaster(action.masterId);
      if (action.keys) {
        master.keys = action.keys;
      }
      if (action.vars) {
        master.vars = { ...AD_COMMON_VARS, ...action.vars };
      }
      break;
    }

    case SET_AD_BANNER: {
      useMaster(action.masterId);
      const slave = state.slaves[action.masterId][action.slaveId];
      if (slave) {
        slave.height = action.height;
      }
      break;
    }
    default:
      break;
  }
}, initialState);

export default adReducer;
