import { createStructuredSelector } from 'reselect';
import { RootState } from '@/store';

const getAreas = (state: RootState) => state.locationView.get('areas');

export const getAreaOptions = createStructuredSelector({
  counties: (state: RootState) => getAreas(state).get('counties'),
  citiesParishes: (state: RootState) => getAreas(state).get('citiesParishes'),
  districtsSettlements: (state: RootState) => getAreas(state).get('districtsSettlements'),
});
