import { createSelector } from 'reselect';

import { RootState } from '@/store';
import { AdIds, OBJECT_TYPE_NUMBERS, TRANSACTION_TYPE_NUMBERS } from '@/constants/ad';
import { accessNested } from '@/utils/object';
import { getLanguage } from '@/selectors/appSelectors';
import { getMaxPrice, getMinPrice, getObjectType, getTransactionType } from '@/components/search/searchSelectors';
import type { AdSlave } from './AdReducer';

export const getAdMasters = (state: RootState) => state.ad.masters;

export const getAdSlaves = (state: RootState) => state.ad.slaves;

export const getAdSlave = createSelector(getAdSlaves, getLanguage, (slaves, lang) => {
  return (master: AdIds, id: AdIds) => {
    return accessNested(slaves, master[lang], id[lang]);
  };
});

export const getAdVars = createSelector(
  getObjectType,
  getTransactionType,
  getMinPrice,
  getMaxPrice,
  (ot, tt, minPrice, maxPrice) => {
    const objectType = ot?.get('value');
    const transactionType = tt?.get('value');
    return {
      price_min: minPrice || '0',
      price_max: maxPrice || '0',
      o_type: objectType && OBJECT_TYPE_NUMBERS[objectType],
      t_type: transactionType && TRANSACTION_TYPE_NUMBERS[transactionType],
    };
  }
);

export const slaveLoaded = (slave: AdSlave) => {
  return (slave && slave.script) || false;
};
