import React from 'react';
import classNames from 'classnames';
import { NameValueExtra } from '@/types/collections';

export type TokensProps = {
  tokens: Map<string, NameValueExtra<any>>;
  outside: boolean;
  light: boolean;
  noWrap: boolean;
  deleteToken?: (token: any) => void;
  gaEvent?: () => void;
};

class Tokens extends React.Component<TokensProps> {
  deleteToken: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const { deleteToken, gaEvent } = this.props;
    const token = e.currentTarget.value;

    if (deleteToken) deleteToken(token);
    if (gaEvent) gaEvent();
  };

  renderToken = (token: NameValueExtra<any>): React.ReactElement => {
    return (
      <div key={token.value} className="tokens__token">
        <span>{token.name}</span>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button type="button" className="icon icon-close" value={token.value} onClick={this.deleteToken} />
      </div>
    );
  };

  render(): React.ReactNode {
    const { tokens, outside, light, noWrap } = this.props;

    const tokensStyle = classNames('tokens', {
      'tokens--outside': outside,
      'tokens--light': light,
    });

    const renderedTokens = tokens && Array.from(tokens.values()).map(this.renderToken);

    if (noWrap) {
      return renderedTokens;
    }
    return <div className={tokensStyle}>{renderedTokens}</div>;
  }
}

export default Tokens;
