import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseFooter, { FooterProps } from './BaseFooter';

function Footer(props: FooterProps): React.ReactElement {
  const [t] = useTranslation();
  return <BaseFooter t={t} {...props} />;
}

export default Footer;
