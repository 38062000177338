import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Map, List, fromJS, Collection } from 'immutable';
import Multiselect from '../multiselect/Multiselect';
import Dropdown from '../dropdown/Dropdown';
import Overlay from '../common/Overlay';
import { NameValue } from '../../utils/collections';
import { camelCase } from '../../utils/helpers';
import Icon from '../icon/Icon';
import ErrorMessage from '../errorMessage/ErrorMessage';

const propTypes = {
  native: PropTypes.bool,
  multiple: PropTypes.bool,
  notched: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.instanceOf(Collection).isRequired,
  selected: PropTypes.oneOfType([PropTypes.instanceOf(Collection), PropTypes.instanceOf(NameValue)]),
  maxSelectedVisible: PropTypes.number,
  empty: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  getValue: PropTypes.func,
  t: PropTypes.func,
  translateOption: PropTypes.func,
  className: PropTypes.string,
  gaEvent: PropTypes.func,
  gaEventSelect: PropTypes.bool,
};

const defaultProps = {
  native: false,
  multiple: false,
  notched: false,
  label: '',
  selected: Map(),
  maxSelectedVisible: 3,
  empty: false,
  getValue: null,
  t: (a) => a,
  translateOption: null,
  className: '',
  gaEvent: null,
  gaEventSelect: true,
};

class ListSelect extends React.Component {
  handleClick = (item) => {
    const { getValue, selected } = this.props;

    let val = item;

    if (!List.isList(selected) && val.get('value') === selected.get('value')) {
      val = Map();
    }

    if (getValue) getValue(val);
  };

  render() {
    const { options, selected, arrows, multiselect, disabled, error, className } = this.props;

    const listSelectStyle = classNames(
      {
        'list-select': true,
        'list-select--has-selected': selected.size,
        'list-select--disabled': disabled,
        'list-select--multiselect': multiselect,
        'list-select--arrows': arrows,
      },
      className
    );

    return (
      <>
        <div className={listSelectStyle}>
          {multiselect ? (
            <Multiselect options={options} selected={selected} getValue={this.handleClick} />
          ) : (
            <ul className="list-select__options">
              {options.map((o) => {
                const isSelected = o.get('value') === selected.get('value');

                const listSelectOptionStyle = classNames({
                  'list-select__option': true,
                  'list-select__option--selected': isSelected,
                });

                const count = o.getIn(['extra', 'count']);
                return (
                  <li key={o.get('value')}>
                    <button type="button" className={listSelectOptionStyle} onClick={() => this.handleClick(o)}>
                      <span>
                        <span>{o.get('name')}</span>
                        {count && <span className="list-select__count">({count})</span>}
                      </span>
                      {arrows && (
                        <>
                          {isSelected ? (
                            <Icon name="close" className="list-select__icon" />
                          ) : (
                            <Icon name="angle-right" className="list-select__icon" />
                          )}
                        </>
                      )}
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        {error && <ErrorMessage text={error} />}
      </>
    );
  }
}

ListSelect.propTypes = propTypes;
ListSelect.defaultProps = defaultProps;

export default ListSelect;
