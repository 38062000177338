import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TextArea extends React.PureComponent {
  constructor(props) {
    super(props);

    this.notchRef = React.createRef();

    this.state = {
      focused: false,
    };
  }

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    const { value, gaEvent } = this.props;

    this.setState({ focused: false });

    if (gaEvent) {
      gaEvent(value);
    }
  };

  onChange = (e) => {
    const { getValue } = this.props;
    getValue(e.target.value);
  };

  render() {
    const { id, placeholder, label, small, large, notched, error, value, maxLength } = this.props;
    const { focused } = this.state;

    const textAreaStyle = classNames('text-area', {
      'text-area--focused': focused,
      'text-area--notched': notched,
      'text-area--error': error,
      'text-area--small': small,
      'text-area--large': large,
    });

    const labelStyle = classNames('notch', {
      'label--floating': focused || value !== '',
    });

    return (
      <div className={textAreaStyle}>
        <textarea
          id={id}
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        {notched ? (
          <div className="notch__base">
            <div className="leading" />
            <div className={labelStyle} ref={this.notchRef}>
              <label htmlFor={id}>{label}</label>
            </div>
            <div className="trailing" />
          </div>
        ) : null}
      </div>
    );
  }
}
TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  getValue: PropTypes.func.isRequired,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  gaEvent: PropTypes.func,
};
TextArea.defaultProps = {
  id: '',
  placeholder: '',
  label: '',
  gaEvent: null,
};

export default TextArea;
