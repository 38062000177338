import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactGA from '@/utils/ga';
import { isMobile } from 'react-device-detect';

import { getHeaderHidden } from '@/selectors/appSelectors';
import { scrollToElement } from '@/utils/helpers';
import { useAppSelector } from '@/hooks';

export const AccordionContext = React.createContext({
  opened: false,
  toggleOpen: () => {},
});

interface Props {
  children: React.ReactNode;
  sticky?: boolean;
  extended?: boolean;
  rounded?: boolean;
  className?: string;
  opened?: boolean;
  defaultOpened?: boolean;
  scrollToItem?: any;
  scrollToOffset?: number;
  getOpened?: any;
  name?: string;
  gaCategory?: string;
  gaLabel?: string;
}

function Accordion({
  children,
  sticky,
  extended,
  rounded,
  opened: externalOpened,
  defaultOpened = false,
  className,
  scrollToItem,
  scrollToOffset,
  getOpened,
  name,
  gaCategory,
  gaLabel,
}: Props) {
  const headerHidden = useAppSelector(getHeaderHidden);
  const accordionRef = useRef<HTMLDivElement | null>(null);
  const [opened, setOpened] = useState(defaultOpened);
  const [stuck, setStuck] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const el = accordionRef.current!;
      const offset = el.getBoundingClientRect().top;
      setStuck(offset < 0);
    };

    if (sticky) window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      if (sticky) window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (externalOpened !== undefined) {
      setOpened(externalOpened);
    }
  }, [externalOpened]);

  const toggleOpen = () => {
    if (opened && isMobile) {
      scrollToElement({
        el: scrollToItem ?? accordionRef.current,
        offset: scrollToOffset,
      });
    }

    setOpened(!opened);
    gaEventFunction();
    if (getOpened) getOpened(!opened, name);
  };

  const gaEventFunction = () => {
    if (gaCategory && gaLabel) {
      const prefix = opened ? 'close' : 'open';
      ReactGA.event({
        category: gaCategory,
        action: `${prefix}_${gaLabel}`,
      });
    }
  };

  const accordionStyle = classNames(
    'accordion',
    {
      'accordion--opened': opened,
      'accordion--extended': extended,
      'accordion--sticky': sticky,
      'accordion--stuck': stuck && opened,
      'accordion--header-hidden': headerHidden,
      'accordion--rounded': rounded,
    },
    className
  );

  return (
    <AccordionContext.Provider
      value={{
        opened,
        toggleOpen,
      }}
    >
      <div ref={accordionRef} className={accordionStyle}>
        {children}
      </div>
    </AccordionContext.Provider>
  );
}

export default Accordion;
