import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Collection } from 'immutable';
import ReactGA from '@/utils/ga';

import { SEARCH_URL_LABELS } from '@/constants/filters';
import { OBJECT_TYPES, TRANSACTION_TYPES } from '@/constants/object';
import { GA_SEARCH } from '@/constants/ga';
import { getAppliedFilters } from '@/utils/appliedFilters';
import { composeSearchURLFromFilters } from '@/utils/search';
import {
  setSearchTermDraft,
  clearSearchTermDraft,
  setSearchURL,
  resetSearchForm,
  updateSearchFilters,
} from '@/components/search/SearchActions';
import { formInitialState } from '@/components/search/searchInitialStates';

import { getBrowser, getPrefixedRoutes } from '@/selectors/appSelectors';

import { getSearchFilters, getSearchTermDraft } from '@/components/search/searchSelectors';
import Button from '@/components/button/Button';
import SearchVertical from '@/components/search/SearchVertical/SearchVertical';

import Modal from '../Modal';
import ModalFooter from '../ModalFooter';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  searchFilters: PropTypes.shape({
    objectType: PropTypes.instanceOf(Collection).isRequired,
    transactionType: PropTypes.instanceOf(Collection).isRequired,
    searchTerm: PropTypes.instanceOf(Map).isRequired,
    minPrice: PropTypes.string.isRequired,
    maxPrice: PropTypes.string.isRequired,
    priceType: PropTypes.node.isRequired,
    minSize: PropTypes.string.isRequired,
    maxSize: PropTypes.string.isRequired,
    minLotSize: PropTypes.string.isRequired,
    maxLotSize: PropTypes.string.isRequired,
    lotSizeType: PropTypes.node.isRequired,
    minFloor: PropTypes.string.isRequired,
    maxFloor: PropTypes.string.isRequired,
    onlyLastFloor: PropTypes.bool.isRequired,
    rooms: PropTypes.node.isRequired,
    fromOwner: PropTypes.bool.isRequired,
    condition: PropTypes.instanceOf(Collection).isRequired,
    material: PropTypes.instanceOf(Collection).isRequired,
    extras: PropTypes.instanceOf(Collection).isRequired,
    projectType: PropTypes.instanceOf(Collection).isRequired,
    purpose: PropTypes.instanceOf(Collection).isRequired,
    dateAdded: PropTypes.instanceOf(Collection).isRequired,
    virtualTour: PropTypes.bool.isRequired,
    openHouse: PropTypes.bool.isRequired,
    specialOffers: PropTypes.bool.isRequired,
    auction: PropTypes.bool.isRequired,
    pricedrop: PropTypes.bool.isRequired,
    rentToOwn: PropTypes.bool.isRequired,
  }).isRequired,
  t: PropTypes.func,
};

const defaultProps = { t: (a) => a };

class SearchModal extends React.Component {
  constructor(props) {
    super(props);

    const { searchFilters } = props;

    this.state = {
      isCleared: false,
      ...searchFilters,
    };
  }

  componentDidMount() {
    const { searchFilters, setSearchTermDraft } = this.props;

    if (setSearchTermDraft) setSearchTermDraft(searchFilters.searchTerm);
  }

  searchObjects = () => {
    const { searchTermDraft, updateSearchFilters, setSearchURL, closeModal, history, routes, main } = this.props;

    const {
      transactionType,
      objectType,
      subObjectTypes,
      constructionPhase,
      minPrice,
      maxPrice,
      priceType,
      minSize,
      maxSize,
      minLotSize,
      maxLotSize,
      lotSizeType,
      minFloor,
      maxFloor,
      onlyLastFloor,
      minYearBuilt,
      maxYearBuilt,
      energyCertificate,
      rooms,
      fromOwner,
      condition,
      material,
      extras,
      projectType,
      purpose,
      virtualTour,
      openHouse,
      specialOffers,
      auction,
      pricedrop,
      dateAdded,
      rentToOwn,
    } = this.state;

    const filters = {
      transactionType,
      objectType,
      subObjectTypes,
      constructionPhase,
      minPrice,
      maxPrice,
      priceType,
      searchTerm: searchTermDraft,
      minSize,
      maxSize,
      minLotSize,
      maxLotSize,
      lotSizeType,
      rooms,
      fromOwner,
      minFloor,
      maxFloor,
      onlyLastFloor,
      minYearBuilt,
      maxYearBuilt,
      energyCertificate,
      condition,
      material,
      extras: extras.get(objectType.get('value')),
      purpose: purpose.get(objectType.get('value')),
      projectType,
      virtualTour,
      openHouse,
      auction,
      specialOffers,
      pricedrop,
      dateAdded,
      rentToOwn,
    };

    updateSearchFilters(filters);

    if (
      main &&
      objectType.get('value') === OBJECT_TYPES.NewProject &&
      transactionType.get('value') === TRANSACTION_TYPES.SALE &&
      getAppliedFilters(filters, formInitialState) === 0
    ) {
      // Direct to new projects landing page if filters are unchanged
      history.push(routes.newProjects);
    } else {
      const url = composeSearchURLFromFilters(SEARCH_URL_LABELS, filters);
      setSearchURL(url);
      history.push(routes.objectResults + url);
    }
    closeModal();
  };

  clearForm = () => {
    const { resetSearchForm } = this.props;

    ReactGA.event({
      category: GA_SEARCH,
      action: 'clear_searchForm',
    });

    // reset redux
    resetSearchForm();
    // reset verticalSearch state
    this.setState({ isCleared: true, ...formInitialState.toObject() });
  };

  closeModal = () => {
    const { searchFilters, closeModal, clearSearchTermDraft } = this.props;

    this.setState({ ...searchFilters });
    clearSearchTermDraft();
    closeModal();
  };

  getExtras = (newExtras) => {
    const { objectType, extras } = this.state;
    const newExtrasList = extras.setIn([objectType.get('value')], newExtras);

    this.setState({ extras: newExtrasList });
  };

  getPurpose = (newPurpose) => {
    const { objectType, purpose } = this.state;
    const newPurposeList = purpose.setIn([objectType.get('value')], newPurpose);

    this.setState({ purpose: newPurposeList });
  };

  render() {
    const { isOpen, t, searchTermDraft, setSearchTermDraft } = this.props;
    const {
      isCleared,
      transactionType,
      objectType,
      subObjectTypes,
      constructionPhase,
      minPrice,
      maxPrice,
      priceType,
      minSize,
      maxSize,
      minLotSize,
      maxLotSize,
      lotSizeType,
      minFloor,
      maxFloor,
      onlyLastFloor,
      minYearBuilt,
      maxYearBuilt,
      energyCertificate,
      rooms,
      fromOwner,
      condition,
      material,
      extras,
      projectType,
      purpose,
      dateAdded,
      virtualTour,
      openHouse,
      specialOffers,
      auction,
      pricedrop,
      rentToOwn,
    } = this.state;

    return (
      <Modal
        className="search-modal"
        fullScreen
        stickyFooter
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        contentLabel="Example Modal"
        gaEvent={() =>
          ReactGA.event({
            category: GA_SEARCH,
            action: 'close_button',
          })
        }
      >
        <div className="modal__inner">
          <Button link text={t('common.clear')} onClick={this.clearForm} />
          <SearchVertical
            t={t}
            transactionType={transactionType}
            objectType={objectType}
            subObjectTypes={subObjectTypes}
            constructionPhase={constructionPhase}
            searchTerm={searchTermDraft}
            minPrice={minPrice}
            maxPrice={maxPrice}
            priceType={priceType}
            minSize={minSize}
            maxSize={maxSize}
            minLotSize={minLotSize}
            maxLotSize={maxLotSize}
            lotSizeType={lotSizeType}
            minFloor={minFloor}
            maxFloor={maxFloor}
            onlyLastFloor={onlyLastFloor}
            minYearBuilt={minYearBuilt}
            maxYearBuilt={maxYearBuilt}
            energyCertificate={energyCertificate}
            rooms={rooms}
            fromOwner={fromOwner}
            condition={condition}
            material={material}
            extras={extras}
            projectType={projectType}
            purpose={purpose}
            dateAdded={dateAdded}
            virtualTour={virtualTour}
            openHouse={openHouse}
            specialOffers={specialOffers}
            pricedrop={pricedrop}
            auction={auction}
            rentToOwn={rentToOwn}
            getTransactionType={(transactionTypeVal) => this.setState({ transactionType: transactionTypeVal })}
            getObjectType={(objectTypeVal) => this.setState({ objectType: objectTypeVal })}
            getSubObjectTypes={(val) => this.setState({ subObjectTypes: val })}
            getConstructionPhase={(val) => this.setState({ constructionPhase: val })}
            getSearchTerm={setSearchTermDraft}
            getMinPrice={(minPriceVal) => this.setState({ minPrice: minPriceVal })}
            getMaxPrice={(maxPriceVal) => this.setState({ maxPrice: maxPriceVal })}
            getPriceType={(priceTypeVal) => this.setState({ priceType: priceTypeVal })}
            getMinSize={(minSizeVal) => this.setState({ minSize: minSizeVal })}
            getMaxSize={(maxSizeVal) => this.setState({ maxSize: maxSizeVal })}
            getMinLotSize={(minLotSizeVal) => this.setState({ minLotSize: minLotSizeVal })}
            getMaxLotSize={(maxLotSizeVal) => this.setState({ maxLotSize: maxLotSizeVal })}
            getLotSizeType={(lotSizeTypeVal) => this.setState({ lotSizeType: lotSizeTypeVal })}
            getMinFloor={(minFloorVal) => this.setState({ minFloor: minFloorVal })}
            getMaxFloor={(maxFloorVal) => this.setState({ maxFloor: maxFloorVal })}
            getOnlyLastFloor={(onlyLastFloorVal) => this.setState({ onlyLastFloor: onlyLastFloorVal })}
            getMinYearBuilt={(minYearBuiltVal) => this.setState({ minYearBuilt: minYearBuiltVal })}
            getMaxYearBuilt={(maxYearBuiltVal) => this.setState({ maxYearBuilt: maxYearBuiltVal })}
            getEnergyCertificate={(energyCertificateVal) => this.setState({ energyCertificate: energyCertificateVal })}
            getRooms={(roomsVal) => this.setState({ rooms: roomsVal })}
            getFromOwner={(fromOwnerVal) => this.setState({ fromOwner: fromOwnerVal })}
            getCondition={(conditionVal) => this.setState({ condition: conditionVal })}
            getMaterial={(materialVal) => this.setState({ material: materialVal })}
            getExtras={this.getExtras}
            getProjectType={(projectTypeVal) => this.setState({ projectType: projectTypeVal })}
            getPurpose={this.getPurpose}
            getVirtualTour={(virtualTourVal) => this.setState({ virtualTour: virtualTourVal })}
            getOpenHouse={(openHouseVal) => this.setState({ openHouse: openHouseVal })}
            getSpecialOffers={(specialOffersVal) => this.setState({ specialOffers: specialOffersVal })}
            getAuction={(auctionVal) => this.setState({ auction: auctionVal })}
            getPricedrop={(pricedropVal) => this.setState({ pricedrop: pricedropVal })}
            getDateAdded={(dateAddedVal) => this.setState({ dateAdded: dateAddedVal })}
            getRentToOwn={(rentToOwnVal) => this.setState({ rentToOwn: rentToOwnVal })}
            isCleared={isCleared}
            isClearedCallback={(clear) => this.setState({ isCleared: clear })}
          />
        </div>
        <ModalFooter sticky>
          <Button
            primary
            extended
            text={t('search.showResults')}
            onClick={() => {
              this.searchObjects();
              ReactGA.event({
                category: GA_SEARCH,
                action: 'search_button',
              });
            }}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

SearchModal.propTypes = propTypes;
SearchModal.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    routes: getPrefixedRoutes(state),
    searchTermDraft: getSearchTermDraft(state),
    searchFilters: getSearchFilters(state),
    browser: getBrowser(state),
  };
}

const mapDispatchToProps = {
  setSearchTermDraft,
  clearSearchTermDraft,
  setSearchURL,
  resetSearchForm,
  updateSearchFilters,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SearchModal);
