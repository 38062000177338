import React from 'react';
import type { LinkComponent } from './link/LinkInterface';
import { useTranslation } from 'react-i18next';

const AppContext = React.createContext<{
  isNextJs?: boolean;
  Link: LinkComponent | string;
  /** useTranslation hook based on NextJS's usage. Use this sparingly */
  useTranslation: typeof useTranslation;
}>({
  isNextJs: false,
  Link: 'a',
  useTranslation,
});

export default AppContext;
