import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  value: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string,
  handleChange: PropTypes.func,
};

const defaultProps = {
  value: '',
  checked: false,
  id: '',
  handleChange: null,
};
class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };
  }

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  handleChange = (event) => {
    const { handleChange } = this.props;
    const { target } = event;
    if (handleChange) handleChange(target.checked, target.value);
  };

  render() {
    const { focused } = this.state;
    const { id, value, checked, error, luminor } = this.props;

    const checkStyle = classNames({
      checkbox: true,
      'checkbox--focused': focused,
      'checkbox--checked': checked,
      'checkbox--error': error,
      'checkbox--luminor': luminor,
    });

    return (
      <div className={checkStyle}>
        <input
          id={id}
          type="checkbox"
          value={value}
          checked={checked}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.handleChange}
        />
        <div className="checkbox__bg" />
      </div>
    );
  }
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
