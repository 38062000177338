import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { generatePath } from 'react-router-dom';

import { getBrokerFilterList } from '@/legacyPages/brokerDetail/brokerDetailSelectors';
import { getOfficeFilterList, getOfficeBusinessCard } from '@/legacyPages/companyDetail/companyDetailSelectors';
import { getCertificatesFromBusinessCard } from '@/components/broker/BrokerSearchSelectors';

import { hasBrandedLevels, getProductLevels } from '@/utils/agent';
import { getBrokerName, getContact } from '@/utils/contacts';
import { applyImageFormat, formatOfficeAddress } from '@/utils/formatting';

import Icon from '@/components/icon/Icon';
import Avatar from '@/components/Avatar/Avatar';
import Rating from '@/components/rating/Rating';
import AchievementsList from '@/components/list/AchievementsList/AchievementsList';
import ObjectFilterList from '@/components/ObjectFilterList/ObjectFilterList';
import ContactBrokerForm, { CONTACT_BROKER_LAYOUT } from '@/components/contact/ContactBrokerForm/ContactBrokerForm';
import ContactPhone from '@/components/contact/ContactPhone/ContactPhone';
import { CLASSIFIED_PRODUCT_LEVEL } from '@/constants/office';
import { useAppDispatch, useAppSelector } from '@/hooks';
import AppContext from '../appContext';
import { fetchBrokerDetails } from '@/legacyPages/brokerDetail/brokerDetailActions';
import { fetchCompanyDetails } from '@/legacyPages/companyDetail/companyDetailActions';
import { TFunction } from 'next-i18next';
import { ObjectDetailContextProps } from '../object/ObjectDetailContext';
import { linkUrl } from '@/utils/helpers';
import VerifiedBadge from '@/components/user/VerifiedBadge';

interface Props {
  t: TFunction;
  broker: any;
  office: any;
  type?: 'private' | 'company';
  routes: any;
  lessInfo?: boolean;
  object: any;
  preview?: boolean;
  showNr?: boolean;
  contactOnly?: boolean;
  hideContacts?: boolean;
  sendStatistics?: ObjectDetailContextProps['sendStatistics'];
  gaEventHandler?: (action: string, label?: string) => void;
  onLinkClick?: () => void;
}

// TODO: Lots of refactoring once parent components are also refactored
function BusinessCard({
  t,
  broker,
  office,
  type = 'private',
  routes,
  lessInfo,
  object,
  preview,
  showNr,
  contactOnly,
  hideContacts,
  sendStatistics,
  gaEventHandler,
  onLinkClick,
}: Props) {
  const { Link } = useContext(AppContext);
  const dispatch = useAppDispatch();

  const brokerList = useAppSelector(getBrokerFilterList);
  const company = useAppSelector(getOfficeBusinessCard);
  const companyList = useAppSelector(getOfficeFilterList);
  // TODO: Refactor this type idea when other parent components are refactored
  const [cardType, setType] = useState(type);

  useEffect(() => {
    dispatch(fetchBrokerDetails(broker.get('id')));
    if (type === 'company' && office.get('id')) {
      dispatch(fetchCompanyDetails(office.get('id')));
    }
  }, []);

  useEffect(() => {
    if (preview && office && cardType !== 'company') {
      if (office.get('id')) {
        dispatch(fetchCompanyDetails(office.get('id')));
      }
      setType('company');
    }
  }, [preview, office]);

  if (!broker.has('id')) {
    return null;
  }

  const broker_phone = getContact(broker, 'phone');
  const broker_url = broker && generatePath(routes.brokerDetail, { id: broker.get('id'), slug: broker.get('slug') });
  const broker_avatar_url = broker && applyImageFormat(broker.getIn(['business_card', 'picture_url']), 'photo', 'user');

  const office_url =
    type === 'company' &&
    office &&
    generatePath(routes.companyDetail, { id: office.get('id'), slug: office.get('slug') });
  const office_address = type === 'company' && company && company.addresses && formatOfficeAddress(company.addresses);
  const office_avatar_url =
    type === 'company' &&
    office &&
    applyImageFormat(office.getIn(['business_card', 'logo_url']), 'thumb130x136', 'officeMainLogo');

  const productLevels = getProductLevels(office);
  const branded = hasBrandedLevels(productLevels);

  const classes = classNames('b-card', {
    'b-card--loaded': type === 'private' || company,
    'b-card--less': lessInfo,
    'b-card--branded': branded || broker.get('is_vip'),
    'b-card--vip': broker.get('is_vip'),
    'b-card--private': type === 'private',
    'b-card--company': type === 'company',
    'no-c-avatar': office_avatar_url.length < 1,
    'no-b-avatar': broker_avatar_url.length < 1,
  });

  return (
    <div className={classes}>
      {/* Broker card */}
      {!hideContacts && (
        <div className="b-card__broker">
          <div className="b-card__container">
            <div className="b-card__broker--profile">
              {(!lessInfo || branded) && broker_avatar_url && <Avatar imageUrl={broker_avatar_url} />}
              <div className="b-card__broker--info">
                <div className="b-card__section">
                  <div className="b-card__header">
                    {broker.get('is_vip') && (
                      <div className="b-card__partner--vip">
                        {office.get('developer') ? t('common.vip_developer_badge') : t('common.vip_agent_badge')}
                      </div>
                    )}
                    {branded && !broker.get('is_vip') && (
                      <div className="b-card__partner--premium">
                        {company && productLevels.has(CLASSIFIED_PRODUCT_LEVEL.GOLD)
                          ? t('common.partnerGold')
                          : t('common.partnerPro')}
                      </div>
                    )}
                    <h2>
                      {getBrokerName(broker)} {broker.get('is_verified_user') ? <VerifiedBadge light={broker.get('is_vip')} /> : null}
                    </h2>
                    {office && (
                      <Link className="b-card__header--link" href={preview ? '#' : broker_url} onClick={onLinkClick}>
                        {t('common.viewProfile')}
                        <Icon name="angle-right" />
                      </Link>
                    )}
                  </div>
                  {office && (
                    <div className="b-card__broker--company">{office.getIn(['business_card', 'office_name'])}</div>
                  )}
                  {((!lessInfo && type !== 'private') || branded) &&
                    broker.get('average_rating') &&
                    broker.get('average_rating') >= 4 && <Rating rating={broker.get('average_rating')} showNr />}
                </div>
                <div className="b-card__broker--info-list">
                  <div className="b-card__broker--info-list-sub">
                    {broker_phone && broker_phone.size > 0 && (
                      <div className="b-card__broker--phone">
                        <Icon name="phone" />
                        <ContactPhone
                          t={t}
                          phone={broker_phone}
                          single={lessInfo}
                          showNr={showNr}
                          sendStatistics={sendStatistics}
                          gaEvent={gaEventHandler}
                        />
                      </div>
                    )}
                  </div>
                  {!lessInfo && (
                    <div className="b-card__broker--info-list-sub">
                      <AchievementsList
                        t={t}
                        awards={broker.get('awards')}
                        certificates={getCertificatesFromBusinessCard(broker.get('business_card'))}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="b-card__broker--contact">
              <ContactBrokerForm
                t={t}
                preview={preview}
                layout={CONTACT_BROKER_LAYOUT.BUSINESS_CARD}
                objectId={object.get('id')}
                objectGuid={object.get('guid')}
                objectFriendlyId={object.get('friendly_id')}
                objectUnitType={object.get('unit_type')}
                objectPrice={object.get('price')}
                objectLocation={object.get('address')}
                brokerId={broker.get('id')}
                brokerEmail={broker.get('email')}
                brokerPhone={broker.get('phone')}
              />
            </div>
            {type === 'company' && branded && !contactOnly && (
              <div className="b-card__broker--objects">
                <ObjectFilterList
                  t={t}
                  preview={preview}
                  list={brokerList}
                  route={routes.brokerDetail}
                  ownerId={broker.get('id')}
                  slug={broker.get('slug')}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {/* Company card */}
      {office && company && !contactOnly && (
        <div className="b-card__company">
          <div className="b-card__company--contact">
            <div className="b-card__container">
              {branded && office_avatar_url && (
                <div className="b-card__company--avatar">
                  <Avatar imageUrl={office_avatar_url} />
                </div>
              )}
              <div className="b-card__company--info">
                <div className="b-card__header">
                  <h2>{office.getIn(['business_card', 'office_name'])}</h2>
                  <Link className="b-card__header--link" href={preview ? '#' : office_url} onClick={onLinkClick}>
                    {t('common.viewProfile')}
                    <Icon name="angle-right" />
                  </Link>
                </div>
                <div className="b-card__company--contacts">
                  {office_address && (
                    <div>
                      <Icon name="map-pin" />
                      {office_address}
                    </div>
                  )}
                  {company.phone_numbers.length > 0 && (
                    <a href={preview ? '#' : `tel:(${company.phone_numbers[0]})`}>
                      <Icon name="phone" />
                      {company.phone_numbers[0]}
                    </a>
                  )}
                  {!lessInfo &&
                    company.email_addresses.length > 0 &&
                    company.email_addresses.map((mail) => (
                      <a key={mail} href={`mailto:${mail}`}>
                        <Icon name="envelope" />
                        {mail}
                      </a>
                    ))}
                  {!lessInfo && company.web_addresses.length > 0 && (
                    <a href={linkUrl(company.web_addresses[0])} target="_blank" rel="noopener noreferrer nofollow">
                      <Icon name="house" />
                      {company.web_addresses[0]}
                    </a>
                  )}
                  {!lessInfo && company.facebook_url && (
                    <a href={company.facebook_url} target="_blank" rel="noopener noreferrer nofollow">
                      <Icon name="facebook" />
                      {t('common.facebook')}
                    </a>
                  )}
                  {!lessInfo && company.linkedin_url && (
                    <a href={company.linkedin_url} target="_blank" rel="noopener noreferrer nofollow">
                      <Icon name="linked-in" />
                      {t('common.linkedin')}
                    </a>
                  )}
                  {!lessInfo && company.twitter_url && (
                    <a href={company.twitter_url} target="_blank" rel="noopener noreferrer nofollow">
                      <Icon name="twitter" />
                      {t('common.twitter')}
                    </a>
                  )}
                  {!lessInfo && company.skype_name && (
                    <a href="#">
                      <Icon name="skype" />
                      {company.skype_name}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          {type === 'company' && branded && (
            <div className="b-card__company--objects">
              <div className="b-card__container">
                <ObjectFilterList
                  t={t}
                  preview={preview}
                  list={companyList}
                  route={routes.companyDetail}
                  ownerId={office.get('id')}
                  slug={office.get('slug')}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default BusinessCard;
