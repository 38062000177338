import React, { useContext } from 'react';
import ReactGA from '@/utils/ga';
import Markdown from 'markdown-to-jsx';
import { Map, Seq } from 'immutable';
import { formatPrice } from '@/utils/formatting';
import Select from '@/components/select/Select';
import CalculatedField from '@/components/finance/Calculators/CalculatedField';
import Button from '@/components/button/Button';
import AppContext from '@/components/appContext';

type Props = {
  calcState: any;
  setCalcState: (field: string, value: any) => void;
  results: any;
  links: { button: string; link: string };
  bank: string;
  accentColor: string;
  periodOptions: Seq.Indexed<Map<string, string>>;
  amountOptions: Seq.Indexed<Map<string, string>>;
};

const ExtendedLoanCalculator = ({
  calcState,
  setCalcState,
  results,
  links,
  bank,
  accentColor,
  periodOptions,
  amountOptions,
}: Props): React.ReactElement => {
  const { useTranslation } = useContext(AppContext);
  const [t] = useTranslation();

  const gaEvent = (label: string) => {
    ReactGA.event({
      category: 'single_bank_calculator',
      action: 'update_field_value',
      label,
    });
  };

  return (
    <div className="calc">
      <div className="calc__inputs">
        <Select
          notched
          native
          label={t('loanCalc.amount')}
          options={amountOptions}
          selected={calcState.amount}
          getValue={(val) => setCalcState('amount', val)}
          translateOption={(option) => t(option.get('name'), { count: parseInt(option.get('value'), 10) })}
          gaEvent={() => gaEvent('amount')}
        />
        <Select
          notched
          native
          label={t('loanCalc.period')}
          options={periodOptions}
          selected={calcState.period}
          getValue={(val) => setCalcState('period', val)}
          translateOption={(option) => t(option.get('name'), { count: parseInt(option.get('value'), 10) })}
          gaEvent={() => gaEvent('period')}
        />
      </div>
      <div className="calc__summary">
        <CalculatedField label={t('loanCalc.loanAmount')} value={formatPrice(results.loanAmount)} />
        <CalculatedField label={t('loanCalc.monthlyPayment')} value={formatPrice(results.monthlyPayment)} bigValue />

        <Button
          style={{ backgroundColor: accentColor }}
          primary
          extended
          className="calc__cta"
          text={t(`loanCalc.cta.${bank}`)}
          href={links.button}
          newTab
          onClick={() => {
            ReactGA.event({
              category: 'single_bank_calculator',
              action: 'button_click',
              label: bank,
            });
          }}
        />
        <div className="seb-calc__info">
          <div className="seb-calc__disclaimer">
            <Markdown>
              {t(`loanCalc.disclaimer.${bank}`, { href: links.link, interpolation: { escapeValue: false } })}
            </Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendedLoanCalculator;
