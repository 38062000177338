import React, { useContext, useMemo } from 'react';
import { Collection, isImmutable } from 'immutable';

import { RealEstateListingShort } from '@city24/common/types/Realty';
import { getPrimaryPrice } from '@/utils/realty';
import ContactBrokerForm from '@/components/contact/ContactBrokerForm/ContactBrokerForm';
import { ObjectDetailsState } from '@/components/object/types';
import AppContext from '@/components/appContext';

interface Props {
  layout?: string;
  object: RealEstateListingShort | Collection.Keyed<string, any>;
  broker: NonNullable<ObjectDetailsState['broker']>;
}

function LeadForm({ layout, object: rawObject, broker }: Props) {
  const { useTranslation } = useContext(AppContext);
  const [t] = useTranslation();

  // TODO: Remove immutable check when immutable is removed from caller components
  const object = useMemo(() => {
    return isImmutable(rawObject) ? (rawObject.toJS() as unknown as RealEstateListingShort) : rawObject;
  }, [rawObject]);

  return (
    <div className="lead-form">
      <div className="lead-form__header">
        <h3>{t('contactBrokerForm.leadForm.title')}</h3>
        <div>{t('contactBrokerForm.leadForm.subtitle')}</div>
      </div>
      <ContactBrokerForm
        t={t}
        contactType="lead"
        layout={layout}
        objectId={object.id}
        objectGuid={object.guid}
        objectFriendlyId={object.friendly_id}
        objectUnitType={object.unit_type}
        objectPrice={getPrimaryPrice(object)}
        objectLocation={object.address}
        brokerId={broker.id}
        brokerEmail={broker.email_address}
      />
    </div>
  );
}

export default LeadForm;
