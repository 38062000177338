import { createSelector } from 'reselect';

import { defaultLocaleForLanguage, DEFAULT_LANG } from '@/constants';
import { ROUTES } from '@/constants/routes';
import { RootState } from '@/store';
import { ModalState } from '@/components/modals/modalInterfaces';

export const getAppState = (state: RootState) => state.app;

export const getLanguage = (state: RootState) => state.app.locale;
export const getLocale = createSelector(getLanguage, (lang) => defaultLocaleForLanguage(lang));
export const getLocaleCountry = createSelector(getLocale, (locale) => locale.split('-')[1]);
export const getPrefixedRoutes = createSelector(getLanguage, (lang) =>
  lang === DEFAULT_LANG
    ? ROUTES
    : (Object.keys(ROUTES) as (keyof typeof ROUTES)[]).reduce((mapped, route) => {
        // eslint-disable-next-line no-param-reassign
        mapped[route] = `/${lang}${ROUTES[route]}`;
        return mapped;
      }, {} as typeof ROUTES)
);
export const getError500Count = (state: RootState) => state.app.error500Count;
export const getForceReload = (state: RootState) => state.app.forceReload;

export const getBrowser = (state: RootState) => state.browser;

export const getModalState = (state: RootState) => state.modals;

export function isModalOpen(modal: ReturnType<typeof getModalState>, name: ModalState['name']) {
  return modal.open && modal.state.name === name;
}
export const getSearchModalOpened = (state: RootState) => state.modals.searchModalOpened;
export const getSaveSearchModal = (state: RootState) => state.modals.saveSearchModal;
export const getAreaSelectModalOpened = (state: RootState) => state.modals.areaSelectModalOpened;
export const getSaveSearchConfirmModalOpened = (state: RootState) => state.modals.saveSearchConfirmModalOpened;
export const getReportAdModalOpened = (state: RootState) => state.modals.reportAdModalOpened;
export const getReportAdConfirmModalOpened = (state: RootState) => state.modals.reportAdConfirmModalOpened;
export const getSaveFavouriteModalOpened = (state: RootState) => state.modals.saveFavouriteModalOpened;
export const getSavedSearchRemoveModal = (state: RootState) => state.modals.savedSearchRemoveModal;
export const getShareModal = (state: RootState) => state.modals.shareModal;
export const getDeleteMyObjectModal = (state: RootState) => state.modals.deleteMyObjectModal;
export const getInvoicesSentModalOpened = (state: RootState) => state.modals.invoicesSentModalOpened;
export const getSuccessfulActionModal = (state: RootState) => state.modals.successfulActionModal;
export const getSEBCalculatorModal = (state: RootState) => state.modals.SEBCalculatorModal;
export const getDetailPreviewModal = (state: RootState) => state.modals.detailPreviewModal;
export const getBusinessCardModal = (state: RootState) => state.modals.businessCardModal;
export const getObjectListModal = (state: RootState) => state.modals.objectListModal;
export const getMultiBankCalcModal = (state: RootState) => state.modals.multiBankCalcModal;

export const getHeaderHidden = (state: RootState) => state.app.headerHidden;

export const getSignedIn = (state: RootState) => state.user.signedIn;
