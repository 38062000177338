import produce from 'immer';
import { fromJS, Map } from 'immutable';
import { DELETE_MY_OBJECT, GET_MY_OBJECT_LIST, SET_OBJECT_BOOKING, UNPUBLISH_MY_OBJECT } from '@/constants/actions';

const initialState: {
  totalItems: number;
  objects: Map<string, any>[];
} = {
  totalItems: 0,
  objects: [],
};

const myObjects = produce((state, action) => {
  switch (action.type) {
    case GET_MY_OBJECT_LIST:
      state.objects = action.objects.map((o) => fromJS(o));
      state.totalItems = action.totalItems;
      break;

    case UNPUBLISH_MY_OBJECT:
    case DELETE_MY_OBJECT: {
      const index = state.objects.findIndex((obj) => obj.get('id') === action.id);
      if (index !== -1) {
        state.objects[index] = state.objects[index].set('status_id', action.status_id);
      }
      break;
    }

    case SET_OBJECT_BOOKING: {
      const index = state.objects.findIndex((obj) => obj.get('id') === action.id);
      if (index !== -1) {
        state.objects[index] = state.objects[index].set('booked', fromJS(action.booked));
      }
      break;
    }

    default:
      break;
  }
}, initialState);

export default myObjects;
