const originalSize = '1600x1200';

type ImageFormats = Readonly<{
  original: [string, string];
  [formatName: string]: [string, string];
}>;

const OBJECT_IMAGE: ImageFormats = Object.freeze({
  original: ['1', originalSize],
  largeImage: ['24', '1110x2000'],
  '213x160': ['10', '213x160'],
  '640x2000': ['11', '640x2000'],
  '960x640': ['12', '960x640'],
  '432x288': ['13', '432x288'],
  '342x228': ['14', '342x228'],
  '225x150': ['15', '225x150'],
  '156x104': ['16', '156x104'],
  '100x66': ['17', '100x66'],
  watermarkEE: ['18', '1200x628'],
  watermarkLV: ['19', '1200x628'],
  largeWithWatermarkEE: ['25', '960x640'],
  largeWithWatermarkLV: ['26', '960x640'],
});
const USER_IMAGE: ImageFormats = Object.freeze({
  original: ['1', originalSize],
  photo: ['10', '234x312'],
  thumb200x110: ['11', '200x110'],
  // thumb512x768: ['12', '512x768']
});
const USER_COVER_IMAGE: ImageFormats = Object.freeze({
  original: ['1', originalSize],
  thumb1400x350: ['10', '1400x350'],
});
const USER_LOGO_IMAGE: ImageFormats = Object.freeze({
  original: ['1', originalSize],
  thumb320x480: ['10', '320x480'],
});
const USER_BRANDING_IMAGE: ImageFormats = Object.freeze({
  original: ['1', originalSize],
  thumb512x768: ['10', '512x768'],
});
const OFFICE_COVER_IMAGE: ImageFormats = Object.freeze({
  original: ['1', originalSize],
  thumb1400x350: ['10', '1400x350'],
});
const OFFICE_MAIN_LOGO_IMAGE: ImageFormats = Object.freeze({
  original: ['1', originalSize],
  thumb130x136: ['10', '130x136'],
});
const OFFICE_LOGO_IMAGE: ImageFormats = Object.freeze({
  original: ['1', originalSize],
  thumb160x130: ['10', '160x130'],
});
const NEWS_IMAGE: ImageFormats = Object.freeze({
  original: ['1', originalSize],
  photo: ['10', '342x228'],
  leadPhoto: ['15', '760x506'],
  thumb126x84: ['12', '126x84'],
  watermarkEE: ['13', '1200x628'],
  watermarkLV: ['14', '1200x628'],
  leadWithWatermarkEE: ['16', '760x506'],
  leadWithWatermarkLV: ['17', '760x506'],
});

export default function getImageFormat(type = 'object'): ImageFormats {
  switch (type) {
    case 'user':
      return USER_IMAGE;
    case 'userCover':
      return USER_COVER_IMAGE;
    case 'userLogo':
      return USER_LOGO_IMAGE;
    case 'userBranding':
      return USER_BRANDING_IMAGE;
    case 'officeCover':
      return OFFICE_COVER_IMAGE;
    case 'officeMainLogo':
      return OFFICE_MAIN_LOGO_IMAGE;
    case 'officeLogo':
      return OFFICE_LOGO_IMAGE;
    case 'news':
      return NEWS_IMAGE;
    case 'object':
    default:
      return OBJECT_IMAGE;
  }
}
