import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import adOcean from '@/adOcean';
import * as adActions from './AdActions';
import { getAdMasters, getAdSlaves } from './AdSelectors';

class AdMaster extends React.Component {
  componentDidUpdate(prevProps) {
    const { masters, slaves } = this.props;
    if (prevProps.masters !== masters) {
      this.loadScripts(prevProps.masters);
    }
    if (prevProps.slaves !== slaves) {
      Object.keys(slaves).forEach((mId) => {
        this.loadSlaves(slaves[mId], mId);
      });
    }
  }

  loadScripts(prevMasters) {
    const { masters, removeAdMaster } = this.props;

    Object.keys(masters).forEach((mId) => {
      const master = masters[mId];
      if (!master.script) {
        this.addMaster(mId, master);
      } else {
        // check if keys or vars has changed
        const { vars: oldVars = {}, keys: oldKeys = {} } = prevMasters[mId] || {};
        const { vars, keys } = master;

        if (oldVars !== vars || oldKeys !== keys) {
          removeAdMaster(mId);
          this.addMaster(mId, master);
        }
      }
    });
  }

  addSlave(id, masterId) {
    const { loadAdSlave, setAdLoaded } = this.props;
    loadAdSlave(masterId, id);
    adOcean.slave(id, {
      myMaster: masterId,

      onLoad: () => {
        const elem = document.querySelector(`[id^=${id}]`);
        // this is container div containing adocean script tag of wrapper div of banner
        const childElem = elem ? elem.querySelector('div') : null;
        const adWrapper = elem ? elem.closest('div.ad-wrapper') : null;

        // Note: this is temporary solution to remove duplicate banners inside childElem container (if there are any)
        if (childElem) {
          // Get all direct children
          const banners = childElem.childNodes;
          banners.forEach((banner, index) => {
            // first one should stay
            if (index > 0) {
              banner.remove();
            }
          });
        }

        setTimeout(() => {
          const childHeight = childElem ? parseFloat(getComputedStyle(childElem, null).height.replace('px', '')) : 0;

          if (!childElem && adWrapper) {
            // hide adWrapper if no banner content is loaded
            adWrapper.style.display = 'none';
          }

          setAdLoaded(masterId, id, childHeight);
        }, 500);
      },
    });
  }

  addMaster(masterId, master) {
    const { loadAdMaster } = this.props;
    const { keys, vars } = master;

    loadAdMaster(masterId, keys, vars);
    adOcean.master({
      id: masterId,
      server: 'ao.city24.lv',
      keys,
      vars,
    });
  }

  loadSlaves(slaves, masterId) {
    Object.keys(slaves).forEach((key) => {
      const slave = slaves[key];
      if (!slave.script) {
        this.addSlave(key, masterId);
      }
    });
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    masters: getAdMasters(state),
    slaves: getAdSlaves(state),
  };
}

const adDispachToProps = adActions;

AdMaster.propTypes = {
  masters: PropTypes.objectOf(PropTypes.object).isRequired,
  loadAdMaster: PropTypes.func.isRequired,
  removeAdMaster: PropTypes.func.isRequired,
  loadAdSlave: PropTypes.func.isRequired,
  setAdLoaded: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, adDispachToProps)(AdMaster);
