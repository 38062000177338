import { twoZeroFill, zeroFill } from './string';

/**
 * Converts Date to ISO 8601string with timezone
 * @param {Date} date Date to convert
 * @param {string} format ISO8601 output format to use ('full', 'date')
 * @return {string}
 */
export function toISOString(date, format = 'full') {
  const dateString = `${zeroFill(date.getFullYear(), 4)}-${twoZeroFill(date.getMonth() + 1)}-${twoZeroFill(
    date.getDate()
  )}`;
  if (format === 'date') {
    return dateString;
  }

  const timeString = `${twoZeroFill(date.getHours())}:${twoZeroFill(date.getMinutes())}:${twoZeroFill(
    date.getSeconds()
  )}`;
  const offset = date.getTimezoneOffset();
  if (offset === 0) {
    return `${dateString}T${timeString}Z`;
  }
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  return `${dateString}T${timeString}${offset > 0 ? '-' : '+'}${twoZeroFill(hours)}:${twoZeroFill(minutes)}`;
}

/**
 * Converts from ISO 8601 string to Date
 * @param {string} dateString
 * @param {boolean} localTZ If dateString ends with Z, date is considered UTC. When true, Date will be created in local timezone instead
 * @return {Date}
 */
export function fromISOString(dateString, localTZ = false) {
  const match = dateString.match(
    /^([0-9]{4})-([0-9]{2})-([0-9]{2})(?:T([0-9]{2}):([0-9]{2}):([0-9]{2})(Z|([+-])([0-9]{2}):([0-9]{2})))?$/
  );
  if (!match) {
    throw new Error(`Invalid ISO8601 date string '${dateString}'`);
  }

  const convertToInt = (a) => parseInt(a, 10);
  const [year, monthNr, day] = match.slice(1, 4).map(convertToInt);
  const month = monthNr - 1;
  // Time string is not present
  if (!match[4]) {
    if (localTZ) {
      return new Date(year, month, day);
    }
    return new Date(Date.UTC(year, month, day));
  }

  const [hours, minutes, seconds] = match.slice(4, 7).map(convertToInt);
  if (match[7] === 'Z') {
    if (localTZ) {
      return new Date(year, month, day, hours, minutes, seconds);
    }
    return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
  }

  const tzModifier = match[8] === '-' ? -1 : 1;
  const tzHours = convertToInt(match[9]) * tzModifier;
  const tzMinutes = convertToInt(match[10]) * tzModifier;
  return new Date(Date.UTC(year, month, day, hours - tzHours, minutes - tzMinutes, seconds));
}

export function isDateBetween(value, start, end) {
  return start <= value && value <= end;
}

export function daysBetweenDates(start, end) {
  return (end - start) / (24 * 3600 * 1000);
}

/**
 * Add days to date
 * @param {Date} date
 * @param {number} days
 * @returns date
 */
export function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}
/**
 * Whether current date is within start and end date
 * @param {Date} startDate
 * @param {Date|null} endDate If null, it is undetermined end date and thus can be active
 * @returns boolean
 */
export function isCurrentlyActiveDate(startDate, endDate) {
  if (startDate === null) {
    return false;
  }
  const currentDate = new Date();
  return startDate <= currentDate && (!endDate || currentDate <= endDate);
}
