import { isMobile } from 'react-device-detect';

export const M_TEST = PORTAL_EE ? { et: 'Hg8HbFXNN8g3OFX_n0YISgUPruUtuRseD1zoWUqPSbP.P7' } : {};
export const S_TEST = PORTAL_EE ? { et: 'adoceancity24lvkcgkhvmpyo.P7' } : {};

export const OBJECT_TYPE_NUMBERS = {
  Apartment: 1,
  House: 2,
  ModularHouse: 2, // same as house
  HouseShare: 3,
  Commercial: 4,
  Land: 5,
  CottageOrVilla: 6,
  Garage: 7,
} as const;

export const TRANSACTION_TYPE_NUMBERS = {
  sale: 1,
  rent: 2,
} as const;

export const AD_COMMON_VARS = {
  device: isMobile ? 'mobile' : 'deskdop',
};

/* MASTERS */
// M_NAME = { LANGUAGE: 'masterID'}
// M_paage_name

export type AdIds =
  | {
      et: string;
      en: string;
      ru: string;
    }
  | {
      lv: string;
      en: string;
      ru: string;
    }
  | {};

// New mobile  main page EE
export const M_MAIN: AdIds = PORTAL_EE
  ? {
      et: '46E7plnwOFYRZhMbThpBU7wFDcbOIaewm3TVG.zl4XX.B7',
      en: 'HaKXnuO0N77gPlseiAokyfnFcoHLPX3rLdBt23.1leb.K7',
      ru: 'P2sFyHjW7eIIido9Id_zo4mtPN7wt3BsqBrMiYAXXKf.57',
    }
  : {
      lv: 'lcmgVhAglynPzKKBitpfOmxXrgUuiRsuzhgIUlVlOyz.F7',
      en: 'lcmgVhAglynPzKKBitpfOmxXrgUuiRsuzhgIUlVlOyz.F7',
      ru: 'jv81hWkl3cYTmHBUzzYgAzaCTttV_VrXjbXYeEEQ_W7.P7',
    };

// New desktop main page
export const D_MAIN: AdIds = PORTAL_EE
  ? {
      et: '.bBHgmOYh1bwBkwKT0G7N.0rXkj2fPrnztV4P3Qbmhj.D7',
      en: 'Q8fxy0nvDDpMX9K34jT_PBl7s5YA8DHf8mOZkVup84T.a7',
      ru: 'fTab2MLISL4QuLjzpXLKRyt.oWkjEUdOOoO6AA11jDv.N7',
    }
  : {
      lv: 'pv6bUvsiSGvvb6LdLgohnOgQPANYAn.UofisNnXHPPr.g7',
      en: 'pv6bUvsiSGvvb6LdLgohnOgQPANYAn.UofisNnXHPPr.g7',
      ru: 'zQ71k_NDjbWRhfBeOqCmP9OjHcUaAEdgruNYtn_kzIP.c7',
    };

// New mobile  search results
export const M_SEARCH: AdIds = PORTAL_EE
  ? {
      et: 'Cwb3y6do11f6x0Z7iCv4A7GaLUOnXudy6beS2HYG59T.k7',
      en: 'BOtAKI0AhvlN6ZdOAcS1ulMtrgQq5xsY8Vy73hJ4dZr.i7',
      ru: 'AW6WAS7_kuFqhwAnAx_IG53OXrlxqvtHyS9oa08mS6v._7',
    }
  : {
      lv: 'tKqWhF2QkmxXPaYBVhI2QUHdwfN.gJ4d5vGQIbzStg3.w7',
      en: 'tKqWhF2QkmxXPaYBVhI2QUHdwfN.gJ4d5vGQIbzStg3.w7',
      ru: 'tnpQdTLD5w.A2cI0dfQ.hW1f4k82Ad3P2ypdJlHQMwj.S7',
    };
export const M_NP_SEARCH: AdIds = PORTAL_EE
  ? {
      et: '6jn7NnS36Fs47PNSNDkhlileHw8AkK7H5RkE2ehZaPP.y7',
      en: 'P1ummHlW8fwNdRaP2gHmNNUULz2Nmk_HP4x3FHolBxP.Z7',
      ru: 'Fr9LUyNrmDYwRbg1PWqd1KV_USftRycs_RuKmekmCu3.V7',
    }
  : {
      lv: 'oTv6Fz_nQ0b69_huMGG1ZFN_cM0qSQMWdzjDj2hHFoL.L7',
      en: 'oTv6Fz_nQ0b69_huMGG1ZFN_cM0qSQMWdzjDj2hHFoL.L7',
      ru: 'dEmrgS0vqNeHHi6AcvSq4rpJTJcr1RB4SleMWRJj7Uz.E7',
    };

// New desktop search results
export const D_SEARCH: AdIds = PORTAL_EE
  ? {
      et: 'kDfnItw_d_Yej5u2c7yzRCe.zxQgj4_M2f.hbSP6fkH.L7',
      en: '0HBABTvHh13uNPKS6eQUk8l.sBCQdYOIzyCghEMMq3b.57',
      ru: '8fz2qKcHMj8Ku5sWWpeBQG4RA5fRcVK0KYhvU2lDA_f.j7',
    }
  : {
      lv: 'yLg6yu6BkzwVpfHh_Ws9o5iL.HY4UPBm5cU8BwOu.tD.u7',
      en: 'yLg6yu6BkzwVpfHh_Ws9o5iL.HY4UPBm5cU8BwOu.tD.u7',
      ru: 'KfdVUbSanTX_TBqmwtSiQGQq7teCa5r6bIM7PjKlVXn.C7',
    };
// New desktop NP search results
export const D_NP_SEARCH: AdIds = PORTAL_EE
  ? {
      et: 'eb4A7GGaN61XvGyOG4gAit25Y4d2JRJZu0tJ2UvBZNH.g7',
      en: 'ttTgUTO6dw9lArxgP.1K19IYPglPdbt.Tq8Iz0BNuSj.I7',
      ru: 'E_IKldoSoxrB.oI9_HPdM9mdDjpgIdswz8Xb8nz7h7X.T7',
    }
  : {
      lv: 'yzBQqjbR53KEvQexR5PEXzDWU37njHH99ll55BfcNhv.o7',
      en: 'yzBQqjbR53KEvQexR5PEXzDWU37njHH99ll55BfcNhv.o7',
      ru: 'SgQ6nmS4k2pp_KJ.ljsNdyUj7tb9Hpr6Ga14Hwj.Sbv.X7',
    };

// New projects landing page
export const D_NP_LANDING: AdIds = PORTAL_EE
  ? {
      et: 'CZCWGMFukip9SKYhjI072kIHHvp.7q95NEAUSqt9usz.n7',
      en: 'OJhAAZ4gh2CWGGz9johHQveH7ynQIc9qd1wEBtuW7DP.n7',
      ru: 'KxWqACd9AwWKOmfuiDWQI98e7iKZFJsM4NlbgoraNaj.47',
    }
  : {
      lv: 'yzBQqjbR53KEvQexR5PEXzDWU37njHH99ll55BfcNhv.o7',
      en: 'yzBQqjbR53KEvQexR5PEXzDWU37njHH99ll55BfcNhv.o7',
      ru: 'SgQ6nmS4k2pp_KJ.ljsNdyUj7tb9Hpr6Ga14Hwj.Sbv.X7',
    };

// New mobile  detail view
export const M_DETAIL: AdIds = PORTAL_EE
  ? {
      et: '5b78rjCsjPdpea5gXBbipUY27FNVnlB7jVvvnrDpDvv.j7',
      en: '6K.llZ62LTG2TsprPo39j3UDPK4dD3AWbGnMLOduXCD.F7',
      ru: 'CyZB7ab_vEZqMjwfSAHEuIGtrupXrRtcj.PoCwhzS63.U7',
    }
  : {
      lv: 'tJ_cHlzo7eK9drjTEeRmz.lxfa4wVgdRP92YtENW.1..h7',
      en: 'tJ_cHlzo7eK9drjTEeRmz.lxfa4wVgdRP92YtENW.1..h7',
      ru: '9jjmEmMc0h4AUCTyRsd.6iwDAJUOcaMPtZaj6EyUJ8f.27',
    };
// New Project mobile  detail view
export const M_NP_DETAIL: AdIds = PORTAL_EE
  ? {
      et: '30qQw4lpN_0IRtJs7sO.T_vHAwhuVlJEuK5W0nqwSP3.e7',
      en: 'j18GT2jlkupD2PuDsuXCAFOf4SEqrseRUd9N9WHY9lz.27',
      ru: 'lNasN62kTTv85Z79T6pJ2eLiPGjqjnCIx7.MagIE3aT.B7',
    }
  : {
      lv: 'HMDl9XA0LUGFD_.UtClQj50lEFPWNUOFRQoqfwFGPWb.H7',
      en: 'HMDl9XA0LUGFD_.UtClQj50lEFPWNUOFRQoqfwFGPWb.H7',
      ru: 'hD6VyO2.7PIyXvC_t6.wLQHK87LvibHwLss5eqohpdz.J7',
    };

// New desktop  detail view
export const D_DETAIL: AdIds = PORTAL_EE
  ? {
      et: 'hcI75F.nOI7_9mhcruEcWIDOgpFXAh6xeSbgk2Cqdu7.J7',
      en: '56GcTita7SIfrGRUwYeb5AAkAHx.J6NpiWez16uYSTz.97',
      ru: 'eZCl3GGxTPNSYJ8Nv0ROPVZePvslGu7mLaeUPre6Krv.B7',
    }
  : {
      lv: 'qgw17ESX3T_Zlqp3rMv4vOL64pPq995dJwh9TngSc07.37',
      en: 'qgw17ESX3T_Zlqp3rMv4vOL64pPq995dJwh9TngSc07.37',
      ru: 'pS6QLwBbNy80Mj1lo.HtX9_dzbSZ8ieUdlMlqUX58z7.67',
    };
// New desktop NP detail view
export const D_NP_DETAIL: AdIds = PORTAL_EE
  ? {
      et: 'JQAKvnBao_uEfPOQlkKUItmZTL1guB.c6EpPxoCJX_3.y7',
      en: 'qTWlodGRTUx3Yqqgxb4YUzcjQZsgwYci1F_NknOkKer.F7',
      ru: 'kpo8C8bQ3YYKIy4LimsHKHbZ7NDAMVAM6gJclOSifBz.R7',
    }
  : {
      lv: 'IMUGvTxckmMpsJUIfPD_rB4jTN2x1hAcuQpsTYlVLL..n7',
      en: 'IMUGvTxckmMpsJUIfPD_rB4jTN2x1hAcuQpsTYlVLL..n7',
      ru: 'sjXwRXcE15ibuHdA.5FE0DJk7HH.vFB1wY9csJOBzu..Z7',
    };

// Finance center
export const D_FINANCE_CENTER: AdIds = PORTAL_EE
  ? {
      et: 'G1tMnmdTPY2kbzQVhvELgDh0XQXIGcb21c0lL2m_gT..M7',
      en: '6fihS3PgzEb5dtyUjAZPU2ck.OzAKfBgRO9ZGpkaw.z.o7',
      ru: 'XkrmqIZo0oNnLiWEReU_PoPjo25SwJJz4p3pFreaNnn.Y7',
    }
  : {};

// Special banners
export const D_SPECIAL: AdIds = PORTAL_EE
  ? {
      et: 'NMGhG81WzAeMopb1rJk5TO.lwJb.zCM_E6oTVbau_Zv.87',
      en: 'VagMJ_Cf7Zl_fblTqIUrlI.ocgxPnn4HVoVA0XO4Vn..47',
      ru: '6e2g1QFyl0ZygpJFj8NelpirPHQ48XB6T0QmozgYxJb.r7',
    }
  : {
      lv: 'xmVbjvOG_ApFYIgJ4th8PEOkIKJaluOENG4zWE.oZ8..U7',
      en: 'xmVbjvOG_ApFYIgJ4th8PEOkIKJaluOENG4zWE.oZ8..U7',
      ru: 'K5w6oCcZk6GKgaJxUjsHAM8A3F3JRLBB41ec0XjwXsb.r7',
    };

// NEWS PAGE
export const D_NEWS: AdIds = PORTAL_EE
  ? {
      et: '7VIKk2Mco_SWsdIM40XHGvnUsOPL64OgXeAzPVnMSZD.W7',
      en: 'hggMt8MQ7VjgN13vEHdnARgYQTEoDIcWct_NUWauKZb.37',
      ru: 'XNnqo6Cu420as5OlCZuk2ULccD1avQN2UaTw.BgYy8D.h7',
    }
  : {
      lv: 'p8GQXvrjNv9kwNH8E7N0G4MLobN6dUcaRvS65DHArb7.O7',
      en: 'p8GQXvrjNv9kwNH8E7N0G4MLobN6dUcaRvS65DHArb7.O7',
      ru: 'KNc2lt4XguIQDyYos3Yra03dAXMBlwdPuVzdm7_0qYD.D7',
    };

export const D_CAMPAIGN_LANDING: AdIds = PORTAL_EE
  ? {}
  : {
      lv: 'GIg6kB35k3FgGPKBrXbYJVwW7sZez5thaElb2xeRNwv.q7',
      ru: 'smWcl3bK7VjwrLmcrO1muoxLU77_E3JN2C62lJGsOiL.q7',
      en: 'GIg6kB35k3FgGPKBrXbYJVwW7sZez5thaElb2xeRNwv.q7',
    };

/* SLAVES */

// NEW DESKTOP
// Main page header
export const D_MAIN_HEADER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvpdrjmsqglh',
      en: 'adoceancity24lvnilqqrlszh',
      ru: 'adoceancity24lvrlhkowsuir',
    }
  : {
      lv: 'adoceancity24lvldejikmkif',
      en: 'adoceancity24lvldejikmkif',
      ru: 'adoceancity24lvmhhjjmnrbk',
    };

// Main page before TOP12
export const D_MAIN_BEFORE_TOP12: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvuojsjwqkhi',
      en: 'adoceancity24lvsdejovlgbm',
      ru: 'adoceancity24lvqiopcvgspm',
    }
  : {
      lv: 'adoceancity24lvonighurnnq',
      en: 'adoceancity24lvonighurnnq',
      ru: 'adoceancity24lvmcdnltmjwu',
    };

// Main page middle 1 - after TOP12
export const D_MAIN_MIDDLE_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmeiqppklgc',
      en: 'adoceancity24lvkjchepfhag',
      ru: 'adoceancity24lvomoqbumjnq',
    }
  : {
      lv: 'adoceancity24lvydlplxfpwa',
      en: 'adoceancity24lvydlplxfpwa',
      ru: 'adoceancity24lvzhopmjhgkj',
    };

// Main page middle 2 - after Special offers
export const D_MAIN_MIDDLE_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvzepgdneqbx',
      en: 'adoceancity24lvqheknurnpm',
      ru: 'adoceancity24lvlnfhfrgotl',
    }
  : {};

// Main page middle 3 - after New projects
export const D_MAIN_MIDDLE_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvwfgngkouls',
      en: 'adoceancity24lvxjjnhmplob',
      ru: 'adoceancity24lvynmnioqshg',
    }
  : {
      lv: 'adoceancity24lvvecgpuptrv',
      en: 'adoceancity24lvvecgpuptrv',
      ru: 'adoceancity24lvwifgqwqkue',
    };

// Main page middle 4 - after From owner
export const D_MAIN_MIDDLE_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvtgntjxhjur',
      en: 'adoceancity24lvukqtkjjqyw',
      ru: 'adoceancity24lvvodemlkhxf',
    }
  : {
      lv: 'adoceancity24lvsfjmcsjiwu',
      en: 'adoceancity24lvsfjmcsjiwu',
      ru: 'adoceancity24lvtjmmdukppz',
    };

// Main page middle 5 - after Apartments for sale
export const D_MAIN_MIDDLE_5: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvrpqintkpcd',
      en: 'adoceancity24lvpelpbtflwh',
      ru: 'adoceancity24lvnjfggsqgul',
    }
  : {
      lv: 'adoceancity24lvpgqsfpdngp',
      en: 'adoceancity24lvpgqsfpdngp',
      ru: 'adoceancity24lvqkdtgreuku',
    };

// Main page middle 6 - after Houses for sale
export const D_MAIN_MIDDLE_6: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvoaipqqeuiy',
      en: 'adoceancity24lvmfcgfqpprc',
      ru: 'adoceancity24lvkkmmjpkleg',
    }
  : {
      lv: 'adoceancity24lvlopmkrlsxl',
      en: 'adoceancity24lvlopmkrlsxl',
      ru: 'adoceancity24lvzcktoqgovp',
    };

// Main page middle 7 - after Commercial for sale
export const D_MAIN_MIDDLE_7: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvlbpfeooinx',
      en: 'adoceancity24lvzfjminjuqx',
      ru: 'adoceancity24lvxkdtmmeqob',
    }
  : {
      lv: 'adoceancity24lvyogtnofhrk',
      en: 'adoceancity24lvyogtnofhrk',
      ru: 'adoceancity24lvwdrjcoqsqk',
    };

// Main page middle 8
export const D_MAIN_MIDDLE_8: AdIds = PORTAL_EE
  ? {}
  : {
      lv: 'adoceancity24lvvpnjbmplmf',
      en: 'adoceancity24lvvpnjbmplmf',
      ru: 'adoceancity24lvteiqflkhvj',
    };

// Main page footer
export const D_MAIN_FOOTER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvybgmhlinxs',
      en: 'adoceancity24lvwgqslkdjkw',
      ru: 'adoceancity24lvulkjqjouyw',
    }
  : {
      lv: 'adoceancity24lvsafqejjqsa',
      en: 'adoceancity24lvsafqejjqsa',
      ru: 'adoceancity24lvqfpgjiemqe',
    };

// Main page - Overlay banner
export const D_MAIN_OVERLAY: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvvcnskisrwn',
      en: 'adoceancity24lvthhjpxmnur',
      ru: 'adoceancity24lvrmrpdxhjsv',
    }
  : {
      lv: 'adoceancity24lvpbmgiwsurv',
      en: 'adoceancity24lvpbmgiwsurv',
      ru: 'adoceancity24lvnggnmvnqpz',
    };

export const M_MAIN_OVERLAY: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvtnknhmoilv',
      en: 'adoceancity24lvqortkjinvq',
      ru: 'adoceancity24lvnpikowrrfl',
    }
  : {};

// Search page header
export const D_SEARCH_HEADER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvrjchkkftuj',
      en: 'adoceancity24lvokjnnxohsi',
      ru: 'adoceancity24lvllqtquimcd',
    }
  : {
      lv: 'adoceancity24lvqgdkjjgnni',
      en: 'adoceancity24lvqgdkjjgnni',
      ru: 'adoceancity24lvnhkqmwprbd',
    };

// Search page middle 1 - after 3/5 object
export const D_SEARCH_MIDDLE_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvylhkessqxy',
      en: 'adoceancity24lvvmoqhpmfrx',
      ru: 'adoceancity24lvsnfhlmgkxs',
    }
  : {
      lv: 'adoceancity24lvujptgonpas',
      en: 'adoceancity24lvujptgonpas',
      ru: 'adoceancity24lvrkgkklhugn',
    };

// Search page middle 2 - after 10 object
export const D_SEARCH_MIDDLE_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvzpkkfudilh',
      en: 'adoceancity24lvmpdecxjtci',
      ru: 'adoceancity24lvpomnojqown',
    }
  : {
      lv: 'adoceancity24lvlmehbwknvh',
      en: 'adoceancity24lvlmehbwknvh',
      ru: 'adoceancity24lvolnqniripm',
    };

// Search page middle 3 - after 15 object
export const D_SEARCH_MIDDLE_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvwacrirnmgc',
      en: 'adoceancity24lvtbjhmohrbx',
      ru: 'adoceancity24lvqcqnplrfkw',
    }
  : {
      lv: 'adoceancity24lvppqqokspir',
      en: 'adoceancity24lvppqqokspir',
      ru: 'adoceancity24lvsojklniljw',
    };

// Search page middle 4 - after 20 object
export const D_SEARCH_MIDDLE_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvxefrjtotzh',
      en: 'adoceancity24lvkeokgwepam',
      ru: 'adoceancity24lvndhedjlkbr',
    }
  : {
      lv: 'adoceancity24lvtcnkmpjsnb',
      en: 'adoceancity24lvtcnkmpjsnb',
      ru: 'adoceancity24lvwbgejspndg',
    };

// Search page middle 5 - after 25 object
export const D_SEARCH_MIDDLE_5: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvufmhnqiieg',
      en: 'adoceancity24lvrgdoqnsmob',
      ru: 'adoceancity24lvohkeelmruw',
    }
  : {
      lv: 'adoceancity24lvxfjekuquwl',
      en: 'adoceancity24lvxfjekuquwl',
      ru: 'adoceancity24lvkfcogxgqxq',
    };

// Search page middle 6 - after 30 object
export const D_SEARCH_MIDDLE_6: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvwkdpfiiqci',
      en: 'adoceancity24lvzjmiclolhn',
      ru: 'adoceancity24lvmjfsonehxs',
    }
  : {
      lv: 'adoceancity24lvljfohjihlz',
      en: 'adoceancity24lvljfohjihlz',
      ru: 'adoceancity24lvoiohemosga',
    };

// Search page middle 7 - after 35 object
export const D_SEARCH_MIDDLE_7: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvupnfkxslem',
      en: 'adoceancity24lvxogpgkjhfr',
      ru: 'adoceancity24lvkopidnpsls',
    }
  : {
      lv: 'adoceancity24lvpmrhfopjjj',
      en: 'adoceancity24lvpmrhfopjjj',
      ru: 'adoceancity24lvslkrbrffzo',
    };

// Search page middle 8 - after 40 object
export const D_SEARCH_MIDDLE_8: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvseimownhnq',
      en: 'adoceancity24lvvdrfljeter',
      ru: 'adoceancity24lvyckphmkojw',
    }
  : {
      lv: 'adoceancity24lvtpnrctgmst',
      en: 'adoceancity24lvtpnrctgmst',
      ru: 'adoceancity24lvwoglpvmhxy',
    };

export const D_SEARCH_GRID_MIDDLE_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvkgmsevsjnc',
      en: 'adoceancity24lvsmegqoroqw',
      ru: 'adoceancity24lvkdnjliqteq',
    }
  : {
      lv: 'adoceancity24lvpkcslsrsyb',
      en: 'adoceancity24lvpkcslsrsyb',
      ru: 'adoceancity24lvxalfhmqhwz',
    };
export const D_SEARCH_GRID_MIDDLE_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvxgdjismoix',
      en: 'adoceancity24lvpnlmdmltlr',
      ru: 'adoceancity24lvxdeqovjinp',
    }
  : {
      lv: 'adoceancity24lvmljipplhda',
      en: 'adoceancity24lvmljipplhda',
      ru: 'adoceancity24lvubcmkjkmru',
    };
export const D_SEARCH_GRID_MIDDLE_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvuhkplpgtss',
      en: 'adoceancity24lvmoctgjfiqq',
      ru: 'adoceancity24lvuelgctdntk',
    }
  : {
      lv: 'adoceancity24lvzlqocnfmnv',
      en: 'adoceancity24lvzlqocnfmnv',
      ru: 'adoceancity24lvrcjsnwdrqp',
    };
export const D_SEARCH_GRID_MIDDLE_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvrirfpmqhmr',
      en: 'adoceancity24lvzojjkwomel',
      ru: 'adoceancity24lvrfcnfqnrdf',
    }
  : {
      lv: 'adoceancity24lvwmhfgkpqiq',
      en: 'adoceancity24lvwmhfgkpqiq',
      ru: 'adoceancity24lvodqibunfvo',
    };
export const D_SEARCH_GRID_MIDDLE_5: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvojimckkmsm',
      en: 'adoceancity24lvwpqpntirog',
      ru: 'adoceancity24lvogjtinhgxe',
    }
  : {
      lv: 'adoceancity24lvtnoljxifrp',
      en: 'adoceancity24lvtnoljxifrp',
      ru: 'adoceancity24lvlehperhkqj',
    };
export const D_SEARCH_GRID_MIDDLE_6: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvlkpsfxdrrh',
      en: 'adoceancity24lvtaigbrsfpf',
      ru: 'adoceancity24lvlhqjmkrkhz',
    }
  : {
      lv: 'adoceancity24lvqofsmusjbk',
      en: 'adoceancity24lvqofsmusjbk',
      ru: 'adoceancity24lvyeofiorope',
    };
export const D_SEARCH_GRID_MIDDLE_7: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvykgjjunflg',
      en: 'adoceancity24lvqbpmeomkza',
      ru: 'adoceancity24lvyhhqpxkpgu',
    }
  : {
      lv: 'adoceancity24lvnpmiqrmowf',
      en: 'adoceancity24lvnpmiqrmowf',
      ru: 'adoceancity24lvvffmllltkz',
    };
export const D_SEARCH_GRID_MIDDLE_8: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvvlnpmrhkvb',
      en: 'adoceancity24lvncgthlgpuv',
      ru: 'adoceancity24lvviogdveump',
    }
  : {
      lv: 'adoceancity24lvkaepdpgtna',
      en: 'adoceancity24lvkaepdpgtna',
      ru: 'adoceancity24lvsgmsoifiey',
    };

// Search page Overlay banner
export const D_SEARCH_OVERLAY: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvqjctcwitmq',
      en: 'adoceancity24lvtilmpiporv',
      ru: 'adoceancity24lvwhegmlfksa',
    }
  : {
      lv: 'adoceancity24lvxcklqxnobd',
      en: 'adoceancity24lvxcklqxnobd',
      ru: 'adoceancity24lvkcdfnkekyi',
    };

// Search page footer
export const D_SEARCH_FOOTER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvoomjhvdpku',
      en: 'adoceancity24lvrnftdikkaz',
      ru: 'adoceancity24lvumomqkqfue',
    }
  : {
      lv: 'adoceancity24lvlggfomfrrn',
      en: 'adoceancity24lvlggfomfrrn',
      ru: 'adoceancity24lvofpokplmls',
    };

// Search page panoramic banner under pagination
export const D_SEARCH_PANORAMIC: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvolmgmwmnrw',
      en: 'adoceancity24lvlmdnptgsmr',
      ru: 'adoceancity24lvymktcrqgvq',
    }
  : {};

// NP Search page panoramic banner under pagination
export const D_NP_SEARCH_PANORAMIC: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvqklkdiqoda',
      en: 'adoceancity24lvxmqnnpnmgp',
      ru: 'adoceancity24lvopfrhxkkqe',
    }
  : {};

// NP landing page header
export const D_NP_LANDING_HEADER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvscclfmsmvo',
      en: 'adoceancity24lvnidinihnon',
      ru: 'adoceancity24lvvollisfsvh',
    }
  : {
      lv: 'adoceancity24lvwefncpstno',
      en: 'adoceancity24lvwefncpstno',
      ru: 'adoceancity24lvtfmtfmmihn',
    };

// NP Landing after featured block
export const D_NP_LANDING_MIDDLE_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvpdjrijmrfj',
      en: 'adoceancity24lvkjkoqvqrci',
      ru: 'adoceancity24lvspcslppgpg',
    }
  : {};
// NP Landing after open days block
export const D_NP_LANDING_MIDDLE_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmeqhmwfgzi',
      en: 'adoceancity24lvxjreetkghh',
      ru: 'adoceancity24lvpakipmjlvb',
    }
  : {};
// NP Landing after new on market block
export const D_NP_LANDING_MIDDLE_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvzehoptpkyd',
      en: 'adoceancity24lvukilhqelcc',
      ru: 'adoceancity24lvmbrockdqqw',
    }
  : {};
// NP Landing after Tallinn block
export const D_NP_LANDING_MIDDLE_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvwfoedrjpey',
      en: 'adoceancity24lvrlprknopbx',
      ru: 'adoceancity24lvzbifgxmupr',
    }
  : {};
// NP Landing after Tartu block
export const D_NP_LANDING_MIDDLE_5: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvtgflgoduzt',
      en: 'adoceancity24lvomgiokiuhs',
      ru: 'adoceancity24lvwcpljugjjq',
    }
  : {};
// NP Landing after Parnu block
export const D_NP_LANDING_MIDDLE_6: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvqhmrjlniis',
      en: 'adoceancity24lvlnnobisibr',
      ru: 'adoceancity24lvtdgsmrqntl',
    }
  : {};

// NP Search page header
export const D_NP_SEARCH_HEADER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvskgobqdudg',
      en: 'adoceancity24lvplnefnnixf',
      ru: 'adoceancity24lvmmelikhnsa',
    }
  : {
      lv: 'adoceancity24lvwefncpstno',
      en: 'adoceancity24lvwefncpstno',
      ru: 'adoceancity24lvtfmtfmmihn',
    };

// NP Search page middle 1 - after 3/5 object
export const D_NP_SEARCH_MIDDLE_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvtojocselgp',
      en: 'adoceancity24lvwncipukglu',
      ru: 'adoceancity24lvzmlrlxqrvv',
    }
  : {
      lv: 'adoceancity24lvkirgqtjggc',
      en: 'adoceancity24lvkirgqtjggc',
      ru: 'adoceancity24lvxiindrdlbx',
    };

// NP Search page middle 2 - after 10 object
export const D_NP_SEARCH_MIDDLE_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvqpqegpopqk',
      en: 'adoceancity24lvnailjmiuwf',
      ru: 'adoceancity24lvkbprmjsife',
    }
  : {
      lv: 'adoceancity24lvvnceiqogzb',
      en: 'adoceancity24lvvnceiqogzb',
      ru: 'adoceancity24lvymlnetesuc',
    };

// NP Search page middle 3 - after 15 object
export const D_NP_SEARCH_MIDDLE_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvrdefhrpgpt',
      en: 'adoceancity24lvucnodufsku',
      ru: 'adoceancity24lvxbgiqwlnpz',
    }
  : {
      lv: 'adoceancity24lvzapnfvfjil',
      en: 'adoceancity24lvzapnfvfjil',
      ru: 'adoceancity24lvmaihcimukm',
    };

// NP Search page middle 4 - after 20 object
export const D_NP_SEARCH_MIDDLE_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvoellkojlzo',
      en: 'adoceancity24lvlfcsnldquj',
      ru: 'adoceancity24lvyfjibjnuee',
    }
  : {
      lv: 'adoceancity24lvnelhdknlnv',
      en: 'adoceancity24lvnelhdknlnv',
      ru: 'adoceancity24lvqderpmdhsa',
    };

// NP Search page middle 5 - after 25 object
export const D_NP_SEARCH_MIDDLE_5: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvpiollqksst',
      en: 'adoceancity24lvshhfitqniy',
      ru: 'adoceancity24lvvgqoewgjnd',
    }
  : {
      lv: 'adoceancity24lvrhhrqoeolf',
      en: 'adoceancity24lvrhhrqoeolf',
      ru: 'adoceancity24lvugqknrkjqk',
    };

// NP Search page middle 6 - after 30 object
export const D_NP_SEARCH_MIDDLE_6: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvnnisppfoqx',
      en: 'adoceancity24lvqmrlmsljvc',
      ru: 'adoceancity24lvtlkfjvrubd',
    }
  : {
      lv: 'adoceancity24lvvkdlotlqup',
      en: 'adoceancity24lvvkdlotlqup',
      ru: 'adoceancity24lvyjmelwrlzu',
    };

// NP Search page middle 7 - after 35 object
export const D_NP_SEARCH_MIDDLE_7: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvwinnmnmfnk',
      en: 'adoceancity24lvnlcrgvjtnv',
      ru: 'adoceancity24lvunhebnhrxk',
    }
  : {
      lv: 'adoceancity24lvznpemidtoz',
      en: 'adoceancity24lvznpemidtoz',
      ru: 'adoceancity24lvmnioiljoee',
    };

// NP Search page grid middle 8 - after 40 object
export const D_NP_SEARCH_MIDDLE_8: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvtjeeqkgktf',
      en: 'adoceancity24lvkmjhksdisu',
      ru: 'adoceancity24lvrookekrfgj',
    }
  : {
      lv: 'adoceancity24lvnbmojnkfsn',
      en: 'adoceancity24lvnbmojnkfsn',
      ru: 'adoceancity24lvqafigqqqyo',
    };

// NP Search page grid middle 1 - after 3/5 object
export const D_NP_SEARCH_GRID_MIDDLE_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvscdidihlbq',
      en: 'adoceancity24lvkjllorfqik',
      ru: 'adoceancity24lvspdpjlefvi',
    }
  : {
      lv: 'adoceancity24lvphdjcwomzt',
      en: 'adoceancity24lvphdjcwomzt',
      ru: 'adoceancity24lvxnlmnpnrgn',
    };

// NP Search page grid middle 2 - after 10 object
export const D_NP_SEARCH_GRID_MIDDLE_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvpdkogvqppl',
      en: 'adoceancity24lvxjcsbppusf',
      ru: 'adoceancity24lvpalfniojqd',
    }
  : {
      lv: 'adoceancity24lvmikpftirjo',
      en: 'adoceancity24lvmikpftirjo',
      ru: 'adoceancity24lvuoctqmhglm',
    };

// NP Search page grid middle 3 - after 15 object
export const D_NP_SEARCH_GRID_MIDDLE_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmerekskukg',
      en: 'adoceancity24lvukjifmjjxe',
      ru: 'adoceancity24lvmbcmqvhoay',
    }
  : {
      lv: 'adoceancity24lvzirfjqsfsn',
      en: 'adoceancity24lvzirfjqsfsn',
      ru: 'adoceancity24lvoarphxkpqc',
    };

// NP Search page grid middle 4 - after 20 object
export const D_NP_SEARCH_GRID_MIDDLE_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvzeilnpejef',
      en: 'adoceancity24lvrlqoijdohz',
      ru: 'adoceancity24lvzbjsdtrszt',
    }
  : {
      lv: 'adoceancity24lvwjimmnmkni',
      en: 'adoceancity24lvwjimmnmkni',
      ru: 'adoceancity24lvlbiglueuwx',
    };

// NP Search page grid middle 5 - after 25 object
export const D_NP_SEARCH_GRID_MIDDLE_5: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvwfprqmonda',
      en: 'adoceancity24lvomhfmwmsru',
      ru: 'adoceancity24lvwcqihqlhes',
    }
  : {
      lv: 'adoceancity24lvtkpspkgpxd',
      en: 'adoceancity24lvtkpspkgpxd',
      ru: 'adoceancity24lvvcgtbpinar',
    };

// NP Search page grid middle 6 - after 30 object
export const D_NP_SEARCH_GRID_MIDDLE_6: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvtggiekisuv',
      en: 'adoceancity24lvlnolptghlt',
      ru: 'adoceancity24lvtdhpknfmzn',
    }
  : {
      lv: 'adoceancity24lvqlgjdiqtdy',
      en: 'adoceancity24lvqlgjdiqtdy',
      ru: 'adoceancity24lvsdnjfmsrkm',
    };

// NP Search page grid middle 7 - after 35 object
export const D_NP_SEARCH_GRID_MIDDLE_7: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvqhnohxrgsu',
      en: 'adoceancity24lvynfscrqlvo',
      ru: 'adoceancity24lvpeeqijmgpl',
    }
  : {
      lv: 'adoceancity24lvnmnpgvjimx',
      en: 'adoceancity24lvnmnpgvjimx',
      ru: 'adoceancity24lvmnioiljoee',
    };

// NP Search page grid middle 8 - after 40 object
export const D_NP_SEARCH_GRID_MIDDLE_8: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvnieflullyp',
      en: 'adoceancity24lvvomigokqqj',
      ru: 'adoceancity24lvnffmbijfzh',
    }
  : {
      lv: 'adoceancity24lvknegksdnss',
      en: 'adoceancity24lvknegksdnss',
      ru: 'adoceancity24lvmflgmwflzg',
    };

// NP Search page Overlay banner
export const D_NP_SEARCH_OVERLAY: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvxlhgnngrlf',
      en: 'adoceancity24lvklqpjqmmqk',
      ru: 'adoceancity24lvnkjjgtshgp',
    }
  : {
      lv: 'adoceancity24lvreiihsrhbx',
      en: 'adoceancity24lvreiihsrhbx',
      ru: 'adoceancity24lvudrrdvhtwy',
    };

// NP Search page footer
export const D_NP_SEARCH_FOOTER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvvacnbnrmuj',
      en: 'adoceancity24lvypkgophioo',
      ru: 'adoceancity24lvlpdqksntup',
    }
  : {
      lv: 'adoceancity24lvvhesexikkh',
      en: 'adoceancity24lvvhesexikkh',
      ru: 'adoceancity24lvygnlbkpfam',
    };

export const D_SEARCH_SIDEBAR_ID: AdIds[] = PORTAL_EE
  ? [
      {
        et: 'adoceancity24lvtcmqknfhzp',
        en: 'adoceancity24lvxfikismjiz',
        ru: 'adoceancity24lvljeegxdmnj',
      },
      {
        et: 'adoceancity24lvqddhokpluk',
        en: 'adoceancity24lvugpqlpgosu',
        ru: 'adoceancity24lvyjlkjunqbe',
      },
      {
        et: 'adoceancity24lvneknbijqpf',
        en: 'adoceancity24lvrhghpmqsnp',
        ru: 'adoceancity24lvvkcrmrhfgd',
      },
      {
        et: 'adoceancity24lvkfrtevsuda',
        en: 'adoceancity24lvoinnckkhso',
        ru: 'adoceancity24lvsljhqorjqy',
      },
      {
        et: 'adoceancity24lvrpoinlmglf',
        en: 'adoceancity24lvoagpqiglra',
        ru: 'adoceancity24lvlbnfewppbv',
      },
    ]
  : [
      {
        lv: 'adoceancity24lvpbnhmnkkwp',
        en: 'adoceancity24lvpbnhmnkkwp',
        ru: 'adoceancity24lvtejrjsrmuz',
      },
      {
        lv: 'adoceancity24lvmceopkeprk',
        en: 'adoceancity24lvmceopkeprk',
        ru: 'adoceancity24lvqfqhnplrpu',
      },
      {
        lv: 'adoceancity24lvzclediotbf',
        en: 'adoceancity24lvzclediotbf',
        ru: 'adoceancity24lvnghoqmfgut',
      },
      {
        lv: 'adoceancity24lvwdclgvhive',
        en: 'adoceancity24lvwdclgvhive',
        ru: 'adoceancity24lvkhoeekpkpo',
      },
      {
        lv: 'adoceancity24lvqiiidxqgdy',
        en: 'adoceancity24lvqiiidxqgdy',
        ru: 'adoceancity24lvkkgfkreqto',
      },
      {
        lv: 'adoceancity24lvnjpoguklnt',
        en: 'adoceancity24lvnjpoguklnt',
        ru: 'adoceancity24lvxknlnoouhj',
      },
    ];

export const D_NP_SEARCH_SIDEBAR_ID: AdIds[] = PORTAL_EE
  ? [{}]
  : [
      {
        lv: 'adoceancity24lvxhflhxipoj',
        en: '',
        ru: 'adoceancity24lvllrefmqrit',
      },
      {
        lv: 'adoceancity24lvuimrkustne',
        en: 'adoceancity24lvuimrkustne',
        ru: 'adoceancity24lvylilijkgcs',
      },
      {
        lv: 'adoceancity24lvrjdiormidd',
        en: 'adoceancity24lvrjdiormidd',
        ru: 'adoceancity24lvvmprlwdlbn',
      },
      {
        lv: 'adoceancity24lvokkobpgnyy',
        en: 'adoceancity24lvokkobpgnyy',
        ru: 'adoceancity24lvsngiptnpwi',
      },
      {
        lv: 'adoceancity24lvulesqlijmi',
        en: 'adoceancity24lvulesqlijmi',
        ru: 'adoceancity24lvoncphwlsry',
      },
      {
        lv: 'adoceancity24lvrmliejsnhd',
        en: 'adoceancity24lvrmliejsnhd',
        ru: 'adoceancity24lvlojfltfhwx',
      },
    ];

// DETAIL VIEW
// Detail page header
export const D_DETAIL_HEADER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvkpdmdortiw',
      en: 'adoceancity24lvnomfqqhpnb',
      ru: 'adoceancity24lvqnfpmtnksg',
    }
  : {
      lv: 'adoceancity24lvuheonsmkno',
      en: 'adoceancity24lvuheonsmkno',
      ru: 'adoceancity24lvrilebqgptj',
    };

// Detail page - Under contacts
export const D_DETAIL_CONTACT: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmahnqvhjaa',
      en: 'adoceancity24lvzaotdtrnzv',
      ru: 'adoceancity24lvwbfkhqlsfq',
    }
  : {
      lv: 'adoceancity24lvzghrglspuj',
      en: 'adoceancity24lvzghrglspuj',
      ru: 'adoceancity24lvwhohkimupe',
    };

// Detail page - Right side banner 1
export const D_DETAIL_RS_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvvefjlkgubv',
      en: 'adoceancity24lvydoshnmpva',
      ru: 'adoceancity24lvldhmeqskwf',
    }
  : {
      lv: 'adoceancity24lvvlhoounrgt',
      en: 'adoceancity24lvvlhoounrgt',
      ru: 'adoceancity24lvykqhlxdnwy',
    };

// Detail page - Right side banner 2
export const D_DETAIL_RS_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvtjpppjrpdz',
      en: 'adoceancity24lvwiijmmhlee',
      ru: 'adoceancity24lvzhrsipngyj',
    }
  : {
      lv: 'adoceancity24lvzodimjfuld',
      en: 'adoceancity24lvzodimjfuld',
      ru: 'adoceancity24lvmomrimlpqi',
    };

// Detail page - Right side banner 3
export const D_DETAIL_RS_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvrojgejmlxd',
      en: 'adoceancity24lvuncqqlsgri',
      ru: 'adoceancity24lvxmljnoisxj',
    }
  : {
      lv: 'adoceancity24lvncqrjomger',
      en: 'adoceancity24lvncqrjomger',
      ru: 'adoceancity24lvqbjlgrsrks',
    };
// Detail page - Right side banner 4
export const D_DETAIL_RS_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvxkihnnjkih',
      en: 'adoceancity24lvulpnqkdpsc',
      ru: 'adoceancity24lvrmgeeintyx',
    }
  : {};

// Detail page - Right side above services
export const D_DETAIL_SERVICES_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvvefjlkgubv',
      en: 'adoceancity24lvydoshnmpva',
      ru: 'adoceancity24lvldhmeqskwf',
    }
  : {
      lv: 'adoceancity24lvvlhoounrgt',
      en: 'adoceancity24lvvlhoounrgt',
      ru: 'adoceancity24lvykqhlxdnwy',
    };
export const D_DETAIL_SERVICES_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvtjpppjrpdz',
      en: 'adoceancity24lvwiijmmhlee',
      ru: 'adoceancity24lvzhrsipngyj',
    }
  : {
      lv: 'adoceancity24lvzodimjfuld',
      en: 'adoceancity24lvzodimjfuld',
      ru: 'adoceancity24lvmomrimlpqi',
    };
export const D_DETAIL_SERVICES_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvrojgejmlxd',
      en: 'adoceancity24lvuncqqlsgri',
      ru: 'adoceancity24lvxmljnoisxj',
    }
  : {
      lv: 'adoceancity24lvncqrjomger',
      en: 'adoceancity24lvncqrjomger',
      ru: 'adoceancity24lvqbjlgrsrks',
    };
export const D_DETAIL_SERVICES_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvxkihnnjkih',
      en: 'adoceancity24lvulpnqkdpsc',
      ru: 'adoceancity24lvrmgeeintyx',
    }
  : {};

export const D_DETAIL_INFO: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvwjihfoslja',
      en: 'adoceancity24lvtkpnilmqtv',
      ru: 'adoceancity24lvqlgemigfju',
    }
  : {
      lv: 'adoceancity24lvuocojnnhhe',
      en: 'adoceancity24lvuocojnnhhe',
      ru: 'adoceancity24lvrpjenkhmcz',
    };

// Detail page - Gallery banner after 10 image
export const D_DETAIL_GALLERY_10: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmeltlvqluc',
      en: 'adoceancity24lvpdeniihhgh',
      ru: 'adoceancity24lvvbgqbodorn',
    }
  : {
      lv: 'adoceancity24lvrfmlhtdjyb',
      en: 'adoceancity24lvrfmlhtdjyb',
      ru: 'adoceancity24lvueffewjuec',
    };

// Detail page - After similar objects block
export const D_DETAIL_MIDDLE_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvkjfkqulhdg',
      en: 'adoceancity24lvniotmxrsnh',
      ru: 'adoceancity24lvqhhnjkiozm',
    }
  : {
      lv: 'adoceancity24lvviiffillsl',
      en: 'adoceancity24lvviiffillsl',
      ru: 'adoceancity24lvyhroblrgmq',
    };

// Detail page - After last viewed objects block
export const D_DETAIL_MIDDLE_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvymioljipqg',
      en: 'adoceancity24lvynpqeugtgg',
      ru: 'adoceancity24lvlnikbxmohl',
    }
  : {
      lv: 'adoceancity24lvzlepcnsnqv',
      en: 'adoceancity24lvzlepcnsnqv',
      ru: 'adoceancity24lvmlnippijga',
    };

// Detail page - Overlay banner
export const D_DETAIL_OVERLAY: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvwbdfqidlkk',
      en: 'adoceancity24lvzamomljgep',
      ru: 'adoceancity24lvmafijoprvq',
    }
  : {
      lv: 'adoceancity24lvnpqiqrjqzf',
      en: 'adoceancity24lvnpqiqrjqzf',
      ru: 'adoceancity24lvqojsmuplek',
    };

export const M_DETAIL_OVERLAY: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmlfknuqkmg',
      en: 'adoceancity24lvzlmqqrkplb',
      ru: 'adoceancity24lvwmdhepeucw',
    }
  : {};

// Detail page - Footer
export const D_DETAIL_FOOTER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvugnleiogxo',
      en: 'adoceancity24lvxfgfblesop',
      ru: 'adoceancity24lvkfponnkniu',
    }
  : {
      lv: 'adoceancity24lvrcnsnwqsip',
      en: 'adoceancity24lvrcnsnwqsip',
      ru: 'adoceancity24lvubgmkjhouu',
    };

// NP Detail page header
export const D_NP_DETAIL_HEADER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvocqfbtigmk',
      en: 'adoceancity24lvuacjkifniq',
      ru: 'adoceancity24lvxpksgllirv',
    }
  : {
      lv: 'adoceancity24lvlkjrhkkixd',
      en: 'adoceancity24lvlkjrhkkixd',
      ru: 'adoceancity24lvojclenqtde',
    };

// NP Detail page - Under contacts
export const D_NP_DETAIL_CONTACT: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvvnrjgoklql',
      en: 'adoceancity24lvsoiqjleqlg',
      ru: 'adoceancity24lvpppgniouvb',
    }
  : {
      lv: 'adoceancity24lvtifonvfjjd',
      en: 'adoceancity24lvtifonvfjjd',
      ru: 'adoceancity24lvqjmebtpney',
    };

// NP Detail page - Right side banner 1 (after contacts block)
export const D_NP_DETAIL_RS_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmhkmfsdsak',
      en: 'adoceancity24lvpgdgcvjnqp',
      ru: 'adoceancity24lvsfmpoxpizu',
    }
  : {
      lv: 'adoceancity24lvpnflfprkgn',
      en: 'adoceancity24lvpnflfprkgn',
      ru: 'adoceancity24lvsmoecshgws',
    };

// NP Detail page - Right side banner 2
export const D_NP_DETAIL_RS_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvkmetjronno',
      en: 'adoceancity24lvnlnmguejdt',
      ru: 'adoceancity24lvqkggdxkuju',
    }
  : {
      lv: 'adoceancity24lvtacfduinlx',
      en: 'adoceancity24lvtacfduinlx',
      ru: 'adoceancity24lvwpkopwoijc',
    };

// NP Detail page - Right side banner 3
export const D_NP_DETAIL_RS_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvyapjoqjjls',
      en: 'adoceancity24lvlaitktpurt',
      ru: 'adoceancity24lvopqmhwfqwy',
    }
  : {
      lv: 'adoceancity24lvxdooqiqpyh',
      en: 'adoceancity24lvxdooqiqpyh',
      ru: 'adoceancity24lvkdhinlglkm',
    };
// NP Detail page - Right side banner 4
export const D_NP_DETAIL_RS_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvqidedwlmus',
      en: 'adoceancity24lvnjkkgtfren',
      ru: 'adoceancity24lvkkrqjqpfnm',
    }
  : {};

// NP Detail page - Right side above services
export const D_NP_DETAIL_SERVICES_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmhkmfsdsak',
      en: 'adoceancity24lvpgdgcvjnqp',
      ru: 'adoceancity24lvsfmpoxpizu',
    }
  : {
      lv: 'adoceancity24lvpnflfprkgn',
      en: 'adoceancity24lvpnflfprkgn',
      ru: 'adoceancity24lvsmoecshgws',
    };
export const D_NP_DETAIL_SERVICES_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvkmetjronno',
      en: 'adoceancity24lvnlnmguejdt',
      ru: 'adoceancity24lvqkggdxkuju',
    }
  : {
      lv: 'adoceancity24lvtacfduinlx',
      en: 'adoceancity24lvtacfduinlx',
      ru: 'adoceancity24lvwpkopwoijc',
    };
export const D_NP_DETAIL_SERVICES_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvyapjoqjjls',
      en: 'adoceancity24lvlaitktpurt',
      ru: 'adoceancity24lvopqmhwfqwy',
    }
  : {
      lv: 'adoceancity24lvxdooqiqpyh',
      en: 'adoceancity24lvxdooqiqpyh',
      ru: 'adoceancity24lvkdhinlglkm',
    };
export const D_NP_DETAIL_SERVICES_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvqidedwlmus',
      en: 'adoceancity24lvnjkkgtfren',
      ru: 'adoceancity24lvkkrqjqpfnm',
    }
  : {};

// NP Detail page - Gallery banner after 10 image
export const D_NP_DETAIL_GALLERY_10: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvtgqggnojer',
      en: 'adoceancity24lvwfjqcqefuw',
      ru: 'adoceancity24lvzeckpskqpx',
    }
  : {
      lv: 'adoceancity24lvlhkionhsdr',
      en: 'adoceancity24lvlhkionhsdr',
      ru: 'adoceancity24lvogdskqnniw',
    };

// NP Detail page - After similar objects block
export const D_NP_DETAIL_MIDDLE_1: AdIds = PORTAL_EE
  ? {
      et: '',
      en: '',
      ru: '',
    }
  : {
      lv: 'adoceancity24lvpkgslsoubb',
      en: 'adoceancity24lvpkgslsoubb',
      ru: 'adoceancity24lvsjpliveqrg',
    };

// NP Detail page - After last viewed objects block
export const D_NP_DETAIL_MIDDLE_2: AdIds = PORTAL_EE
  ? {
      et: '',
      en: '',
      ru: '',
    }
  : {
      lv: 'adoceancity24lvtncmjxfhfp',
      en: 'adoceancity24lvtncmjxfhfp',
      ru: 'adoceancity24lvwmlfgkmslq',
    };

// NP Detail page - Overlay banner
export const D_NP_DETAIL_OVERLAY: AdIds = PORTAL_EE
  ? {
      et: '',
      en: '',
      ru: '',
    }
  : {
      lv: 'adoceancity24lvxapfhmnjzz',
      en: 'adoceancity24lvxapfhmnjzz',
      ru: 'adoceancity24lvkaipdpdfae',
    };

export const M_NP_DETAIL_OVERLAY: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvviqgdndnyr',
      en: 'adoceancity24lvsjhngknrim',
      ru: 'adoceancity24lvpkotjxggrl',
    }
  : {};

// NP Detail page - Footer
export const D_NP_DETAIL_FOOTER: AdIds = PORTAL_EE
  ? {
      et: '',
      en: '',
      ru: '',
    }
  : {
      lv: 'adoceancity24lvlelperemtj',
      en: 'adoceancity24lvlelperemtj',
      ru: 'adoceancity24lvodejbukhjo',
    };

// NEW MOBILE  MAIN PAGE.
// After Apartment for rent block
export const S_MAIN_RENT: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvylroexlrkq',
      en: 'adoceancity24lvsnpllrpkzk',
      ru: 'adoceancity24lvzpepfjnijz',
    }
  : {
      lv: 'adoceancity24lvqjmhdlstki',
      en: 'adoceancity24lvqjmhdlstki',
      ru: 'adoceancity24lvxlrknsprnx',
    };

// After New Project block
export const S_MAIN_NEW: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvllkibksmwv',
      en: 'adoceancity24lvpogsoojpuf',
      ru: 'adoceancity24lvwamfjwgneu',
    }
  : {
      lv: 'adoceancity24lvnkdogimiph',
      en: 'adoceancity24lvnkdogimiph',
      ru: 'adoceancity24lvklkekvfnzc',
    };

// After New Project block
export const S_MAIN_HOUSE_RENT: AdIds = PORTAL_EE
  ? {
      et: '',
      en: 'adoceancity24lvsacscsfimw',
      ru: 'adoceancity24lvpbjigppmwr',
    }
  : {
      lv: 'adoceancity24lvmcqojmjrgm',
      en: 'adoceancity24lvmcqojmjrgm',
      ru: 'adoceancity24lvzchfnjdgll',
    };

// NEW MOBILE  SEARCH RESULTS
// 1st banner after 5th object
export const S_SEARCH_5: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvkbjgnoljow',
      en: 'adoceancity24lvvjjmoxspkd',
      ru: 'adoceancity24lvxbqmqlfonr',
    }
  : {
      lv: 'adoceancity24lvoogohknpim',
      en: 'adoceancity24lvoogohknpim',
      ru: 'adoceancity24lvmdremjilrq',
    };

// 2nd banner after 10th object
export const S_SEARCH_10: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvnacqjrrujx',
      en: 'adoceancity24lvskqsbvmufy',
      ru: 'adoceancity24lvuchtdjpsim',
    }
  : {
      lv: 'adoceancity24lvlpnelxgush',
      en: 'adoceancity24lvlpnelxgush',
      ru: 'adoceancity24lvzdilpwrpfl',
    };

// 3rd banner after 20th object

export const S_SEARCH_20: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmiffnosqgk',
      en: 'adoceancity24lvzimlqlmfpj',
      ru: 'adoceancity24lvwjdsdjgkve',
    }
  : {
      lv: 'adoceancity24lvlgklmofmwn',
      en: 'adoceancity24lvlgklmofmwn',
      ru: 'adoceancity24lvygrrplpqki',
    };

// 4rd banner after 30th object
export const S_SEARCH_30: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvqpkjguhqoc',
      en: 'adoceancity24lvplhjfsgjvx',
      ru: 'adoceancity24lvrdojhwihcl',
    }
  : {
      lv: 'adoceancity24lvypelouqibg',
      en: 'adoceancity24lvypelouqibg',
      ru: 'adoceancity24lvweprculuag',
    };

// 5th banner after 40th object
export const S_SEARCH_40: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvtodtcxnlth',
      en: 'adoceancity24lvmmopipqnus',
      ru: 'adoceancity24lvoefqktslmg',
    }
  : {
      lv: 'adoceancity24lvvamrbsknhb',
      en: 'adoceancity24lvvamrbsknhb',
      ru: 'adoceancity24lvtfgigrfjqf',
    };

// After pagination
export const S_SEARCH_PAGINATION: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvwnmmpjehjm',
      en: 'adoceancity24lvzmfgmmkspn',
      ru: 'adoceancity24lvlfmgoqmqhb',
    }
  : {
      lv: 'adoceancity24lvsbdifpesnw',
      en: 'adoceancity24lvsbdifpesnw',
      ru: 'adoceancity24lvpckoimogwv',
    };

// 1st banner after 5th object
export const S_SEARCH_NP_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvlbmhcxkqxp',
      en: 'adoceancity24lvwgnektpquo',
      ru: 'adoceancity24lvxkqelvqhxx',
    }
  : {
      lv: 'adoceancity24lvqfmihrqtpw',
      en: 'adoceancity24lvqfmihrqtpw',
      ru: 'adoceancity24lvllnfpnfuiv',
    };

// 2nd banner after 10th object
export const S_SEARCH_NP_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvybdofuefro',
      en: 'adoceancity24lvthelnqjfzn',
      ru: 'adoceancity24lvulhloskmss',
    }
  : {
      lv: 'adoceancity24lvngdpkokiuv',
      en: 'adoceancity24lvngdpkokiuv',
      ru: 'adoceancity24lvylemclpicu',
    };

// 4rd banner after 15th object
export const S_SEARCH_NP_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvvckejrojbj',
      en: 'adoceancity24lvqilrqndkji',
      ru: 'adoceancity24lvrmorbqernn',
    }
  : {
      lv: 'adoceancity24lvkhkfolenpq',
      en: 'adoceancity24lvkhkfolenpq',
      ru: 'adoceancity24lvvmlsfijnmp',
    };

// 3rd banner after 20th object
export const S_SEARCH_NP_4: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvsdrkmoiole',
      en: 'adoceancity24lvnjcielnopd',
      ru: 'adoceancity24lvonfifnofsm',
    }
  : {
      lv: 'adoceancity24lvxhrlbjorol',
      en: 'adoceancity24lvxhrlbjorol',
      ru: 'adoceancity24lvsncjjvsrwk',
    };

// 5th banner after 25th object
export const S_SEARCH_NP_5: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvpeirplssvz',
      en: 'adoceancity24lvkkjohihtzy',
      ru: 'adoceancity24lvlomoikikch',
    }
  : {
      lv: 'adoceancity24lvuiisewhgik',
      en: 'adoceancity24lvuiisewhgik',
      ru: 'adoceancity24lvpojpmsmgqj',
    };

// After pagination
export const S_SEARCH_NP_PAGINATION: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmfphdjmhly',
      en: 'adoceancity24lvzfgogwfmkt',
      ru: 'adoceancity24lvyodfmxrobc',
    }
  : {
      lv: 'adoceancity24lvrjpiitrksf',
      en: 'adoceancity24lvrjpiitrksf',
      ru: 'adoceancity24lvokgplqlpna',
    };

// DETAIL VIEW
// After contact block
export const S_DETAIL_CONTACT: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmahnqvhjaa',
      en: 'adoceancity24lvzaotdtrnzv',
      ru: 'adoceancity24lvwbfkhqlsfq',
    }
  : {
      lv: 'adoceancity24lvkillqidhpu',
      en: 'adoceancity24lvkillqidhpu',
      ru: 'adoceancity24lvvnmiivhhmt',
    };
export const S_DETAIL_NP_CONTACT: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvvnrjgoklql',
      en: 'adoceancity24lvsoiqjleqlg',
      ru: 'adoceancity24lvpppgniouvb',
    }
  : {
      lv: 'adoceancity24lvqcnpnunnuo',
      en: 'adoceancity24lvqcnpnunnuo',
      ru: 'adoceancity24lvufjjljfqzy',
    };

// banner in gallery after 10th image
export const S_DETAIL_GALLERY_10: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvnnchquooop',
      en: 'adoceancity24lvkojndsitjk',
      ru: 'adoceancity24lvxoqtgpshhj',
    }
  : {
      lv: 'adoceancity24lvxicsdwmlop',
      en: 'adoceancity24lvxicsdwmlop',
      ru: 'adoceancity24lvymfseiossu',
    };

export const S_DETAIL_NP_GALLERY: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvzifhqsisub',
      en: 'adoceancity24lvqlkkkkgqeq',
      ru: 'adoceancity24lvxnpnesdosf',
    }
  : {
      lv: 'adoceancity24lvndegbshslj',
      en: 'adoceancity24lvndegbshslj',
      ru: 'adoceancity24lvxecthmlled',
    };

// banner in gallery after 20th image
export const S_DETAIL_GALLERY_20: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvuphkkmmmne',
      en: 'adoceancity24lvrapqnjgriz',
      ru: 'adoceancity24lvobghbxpfny',
    }
  : {
      lv: 'adoceancity24lvujjihtgqjk',
      en: 'adoceancity24lvujjihtgqjk',
      ru: 'adoceancity24lvlmolbleotz',
    };

// banner in gallery after 30th image
export const S_DETAIL_GALLERY_30: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvlcnneujkxt',
      en: 'adoceancity24lvyceeirdpso',
      ru: 'adoceancity24lvvdlklontrj',
    }
  : {
      lv: 'adoceancity24lvrkqokqquif',
      en: 'adoceancity24lvrkqokqquif',
      ru: 'adoceancity24lvolhfonkjye',
    };

// mobile  detail view Gallery last banner
export const S_DETAIL_GALLERY_LAST: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvnnchquooop',
      en: 'adoceancity24lvkojndsitjk',
      ru: 'adoceancity24lvxoqtgpshhj',
    }
  : {
      lv: 'adoceancity24lvxicsdwmlop',
      en: 'adoceancity24lvxicsdwmlop',
      ru: 'adoceancity24lvymfseiossu',
    };

// NP detail view Gallery last banner
export const S_DETAIL_NP_GALLERY_LAST: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvzifhqsisub',
      en: 'adoceancity24lvqlkkkkgqeq',
      ru: 'adoceancity24lvxnpnesdosf',
    }
  : {
      lv: 'adoceancity24lvndegbshslj',
      en: 'adoceancity24lvndegbshslj',
      ru: 'adoceancity24lvxecthmlled',
    };

// mobile  detail view Header banner
export const S_DETAIL_HEADER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvzclfkkhozx',
      en: 'adoceancity24lvwdcmnxqsys',
      ru: 'adoceancity24lvtejsqukhsr',
    }
  : {
      lv: 'adoceancity24lvqfqiesemym',
      en: 'adoceancity24lvqfqiesemym',
      ru: 'adoceancity24lvnghphpoqih',
    };

// mobile NP detail view Header banner
export const S_DETAIL_NP_HEADER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvuimsbxloww',
      en: 'adoceancity24lvrjdjfuftcr',
      ru: 'adoceancity24lvokkpirphlq',
    }
  : {
      lv: 'adoceancity24lvkhoflmifng',
      en: 'adoceancity24lvkhoflmifng',
      ru: 'adoceancity24lvxhfmojsjmb',
    };

// desktop header banner - main page, results, detailed view
export const S_HEADER_DESK: AdIds = PORTAL_EE
  ? {}
  : {
      lv: 'adoceancity24lvpeipewihyh',
      en: 'adoceancity24lvpeipewihyh',
      ru: 'adoceancity24lvpeipewihyh',
    };

// finance center middle banner
export const D_FINANCE_CENTER_MIDDLE: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvnpcrgslurl',
      en: 'adoceancity24lvubiebkjsma',
      ru: 'adoceancity24lvodgrhumlfu',
    }
  : {};

// All pages, sticky
export const D_SPECIAL_STICKY: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvznmfdpgqkd',
      en: 'adoceancity24lvmnfpprmlpi',
      ru: 'adoceancity24lvpmoimusgun',
    }
  : {
      lv: 'adoceancity24lvsgqsoisjwy',
      en: 'adoceancity24lvsgqsoisjwy',
      ru: 'adoceancity24lvphhjcwloct',
    };

// NEWS
export const D_NEWS_HEADER: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvterhcwmgeb',
      en: 'adoceancity24lvqfioftglzw',
      ru: 'adoceancity24lvngpejqqpjr',
    }
  : {
      lv: 'adoceancity24lvkglfhjoloa',
      en: 'adoceancity24lvkglfhjoloa',
      ru: 'adoceancity24lvtahjdvnhud',
    };
export const D_NEWS_AFTER_ARTICLE: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvkhglmnkuem',
      en: 'adoceancity24lvxhnrpkejnl',
      ru: 'adoceancity24lvuieidiontg',
    }
  : {
      lv: 'adoceancity24lvriqibrljcp',
      en: 'adoceancity24lvriqibrljcp',
      ru: 'adoceancity24lvmorfjnqjko',
    };
export const D_NEWS_CENTER_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvokcfksrgxa',
      en: 'adoceancity24lvlljlnpllhv',
      ru: 'adoceancity24lvrjlogvhssb',
    }
  : {
      lv: 'adoceancity24lvxgcmkwhqnv',
      en: 'adoceancity24lvxgcmkwhqnv',
      ru: 'adoceancity24lvzoimmkkouj',
    };
export const D_NEWS_CENTER_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvylqrqmfqgq',
      en: 'adoceancity24lvvmhiekpuml',
      ru: 'adoceancity24lvsnoohxijvk',
    }
  : {
      lv: 'adoceancity24lvuhjsntrumq',
      en: 'adoceancity24lvuhjsntrumq',
      ru: 'adoceancity24lvwppspxdtte',
    };
export const D_NEWS_TOP_TOWER_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvpofflusnqf',
      en: 'adoceancity24lvzpdsbpghfz',
      ru: 'adoceancity24lvyirihqsjwi',
    }
  : {
      lv: 'adoceancity24lvojhpeofoxk',
      en: 'adoceancity24lvojhpeofoxk',
      ru: 'adoceancity24lvxddtqjfkdn',
    };
export const D_NEWS_TOP_TOWER_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvmpmlormsaa',
      en: 'adoceancity24lvwalifmqlau',
      ru: 'adoceancity24lvvjipknmord',
    }
  : {
      lv: 'adoceancity24lvlkofilpshf',
      en: 'adoceancity24lvlkofilpshf',
      ru: 'adoceancity24lvuekjexooni',
    };
export const D_NEWS_RIGHT_TOWER_1: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvrgmfnifmtt',
      en: 'adoceancity24lvkehscrhoue',
      ru: 'adoceancity24lvtbcpijkqvp',
    }
  : {
      lv: 'adoceancity24lvykfmlijhbe',
      en: 'adoceancity24lvykfmlijhbe',
      ru: 'adoceancity24lvqbopgshmey',
    };
export const D_NEWS_RIGHT_TOWER_2: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvohdmqvoqso',
      en: 'adoceancity24lvxeoigorstz',
      ru: 'adoceancity24lvqcjfmwdfpo',
    }
  : {
      lv: 'adoceancity24lvvlmsovslpz',
      en: 'adoceancity24lvvlmsovslpz',
      ru: 'adoceancity24lvncfgkprqzt',
    };
export const D_NEWS_RIGHT_TOWER_3: AdIds = PORTAL_EE
  ? {
      et: 'adoceancity24lvliksdtifxn',
      en: 'adoceancity24lvuffpjllhyy',
      ru: 'adoceancity24lvndqlptnjoj',
    }
  : {
      lv: 'adoceancity24lvsmdjctmqgu',
      en: 'adoceancity24lvsmdjctmqgu',
      ru: 'adoceancity24lvkdmmnmlfts',
    };

export const D_CAMPAIGN_LANDING_BLOCKS: AdIds[] = [
  PORTAL_EE
    ? {}
    : {
        lv: 'adoceancity24lvypmsgiqjxi',
        en: 'adoceancity24lvypmsgiqjxi',
        ru: 'adoceancity24lvwehjlxkfgm',
      },
  PORTAL_EE
    ? {}
    : {
        lv: 'adoceancity24lvsblpnsdtny',
        en: 'adoceancity24lvsblpnsdtny',
        ru: 'adoceancity24lvqgfgcsoowc',
      },
  PORTAL_EE
    ? {}
    : {
        lv: 'adoceancity24lvpccgbqnhdx',
        en: 'adoceancity24lvpccgbqnhdx',
        ru: 'adoceancity24lvnhmmfpitgx',
      },
];
