import React, { useContext } from 'react';
import ReactGA from '@/utils/ga';
import { GA_DETAIL_VIEW } from '@/constants/ga';
import { useAppSelector } from '@/hooks';
import { getBrowser } from '@/selectors/appSelectors';
import { getGetAProServices } from '@/selectors/integrationsSelectors';
import Anchor from '@/components/link/Anchor';
import Icon from '@/components/icon/Icon';
import AppContext from '@/components/appContext';

const GetAProServices = (): React.ReactElement | null => {
  const { useTranslation } = useContext(AppContext);
  const [t] = useTranslation('integrationsLV');
  const services = useAppSelector(getGetAProServices);
  const browser = useAppSelector(getBrowser);
  if (!services) {
    return null;
  }

  const renderItems = (start: number, end: number) => {
    return services.slice(start, end).map((item: any) => (
      <tr key={item.label}>
        <td>{t(`getaproLV.${item.label}`)}</td>
        <td className="td--strong">
          <div className="full-specs__value full-specs__value-lowercase">
            <div>
              <Anchor
                href={item.link}
                onClick={() => {
                  ReactGA.event({
                    category: GA_DETAIL_VIEW,
                    action: 'GetAProFullSpecsClick',
                    label: item.label,
                  });
                }}
              >
                {t(`getaproLV.cta.${item.label}`)}
              </Anchor>
            </div>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div className="getapro">
      <div className="h5 full-specs__title">
        <Icon name="get-a-pro" />
        {t('getaproLV.servicesTitle')}
      </div>
      <div className="full-specs__wrap full-specs__wrap--large">
        <table className="full-specs">
          <tbody>{renderItems(0, browser.lessThan.medium ? 6 : 3)}</tbody>
        </table>
        {browser.greaterThan.small && (
          <table className="full-specs">
            <tbody>{renderItems(3, 6)}</tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default GetAProServices;
