// DEPRECATED: only use for migration to new auth

import { localStorage } from '@city24/common/browserStorage';

export function removeToken() {
  localStorage.removeItem('token');
}

export function getToken() {
  return localStorage.getItem('token') || null;
}

export function removeRefreshToken() {
  localStorage.removeItem('refreshToken');
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken') || null;
}

export function getAnonToken() {
  return localStorage.getItem('anonToken') || null;
}

export function persistUuid(token) {
  localStorage.setItem('uuid', token);
}

export function getUuid() {
  return localStorage.getItem('uuid') || null;
}
