import api from '../../api/City24Api';
import { GET_SEB_NEWS_ARTICLES } from '../../constants/actions';

export function fetchSEBCalculatorArticles(locale) {
  return (dispatch) => {
    api
      .getPartnersNewsArticles(
        {
          portal: PORTAL,
          partner: 'seb',
        },
        {},
        locale ? locale.replace('-', '_') : null
      )
      .then((res) => (res.ok ? res.json() : []))
      .then((articles) => {
        dispatch({
          type: GET_SEB_NEWS_ARTICLES,
          articles,
        });
      });
  };
}
