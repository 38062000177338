import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import currencyFormatter from 'currency-formatter';

import ErrorMessage from '@/components/errorMessage/ErrorMessage';
import Caption from '@/components/caption/Caption';
import Icon from '@/components/icon/Icon';
import Tooltip from '@/components/tooltip/Tooltip';
import InputLabel from './InputLabel';

function formatCurrency(nr) {
  if (/[,.]$/.test(nr)) {
    return nr;
  }
  const match = nr.toString().match(/\.([0-9]+)$/);
  const precision = match ? Math.min(match[1].length, 2) : 0;
  return currencyFormatter
    .format(nr, {
      thousand: ' ',
      symbol: '',
      decimal: '.',
      precision,
    })
    .trim();
}

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  type: PropTypes.string,
  gaEvent: PropTypes.func,
  onBlur: PropTypes.func,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowAutocomplete: PropTypes.bool,
};

const defaultProps = {
  type: 'text',
  id: '',
  name: '',
  placeholder: '',
  value: '',
  label: '',
  gaEvent: null,
  onBlur: undefined,
  step: undefined,
  allowAutocomplete: false,
};

class Input extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      random: Math.random(),
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { refocus } = this.props;
    if (refocus) {
      this.inputRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { refocus } = this.props;

    if (prevProps.refocus !== refocus && refocus) {
      this.inputRef.current.focus();
    }
  }

  onFocus = (event) => {
    const { onFocus } = this.props;

    this.setState({ focused: true });
    if (onFocus) onFocus(event);
  };

  onBlur = (event) => {
    const { value, gaEvent, onBlur } = this.props;
    this.setState({ focused: false });

    if (gaEvent && value !== '') {
      gaEvent();
    }

    if (onBlur) onBlur(event, value);
  };

  onChange = (e) => {
    const { getValue, type, disabled } = this.props;

    if (!disabled) {
      let newValue = e.target.value;

      if (['number', 'currency', 'float'].includes(type) && newValue !== '') {
        newValue = this.validateFloatNumber(newValue);
        newValue = this.validateMinMaxRange(newValue);
      }

      getValue(newValue);
    }
  };

  validateMinMaxRange = (value) => {
    const { min, max } = this.props;

    if (!/[,.]$/.test(value) && (min !== undefined || max !== undefined)) {
      if (min > value) {
        return min;
      }
      if (max < value) {
        return max;
      }
    }

    return value;
  };

  validateFloatNumber = (origValue) => {
    const [, integer, point, decimal] = origValue
      .toString()
      .replace(/[^0-9,.]+/g, '')
      .replace(/,/g, '.')
      .match(/^([0-9]+)(\.)?([0-9]*)/);

    if (decimal) {
      return Number(`${integer}.${decimal.replace(/\./g, '')}`);
    }
    if (point) {
      return `${integer}.`;
    }
    return Number(integer);
  };

  // scrollTo = offset => {
  //   const { scrollOffset, id } = this.props;

  //   scroller.scrollTo(id, {
  //     duration: 200,
  //     delay: 0,
  //     smooth: true,
  //     offset
  //   });
  // };

  handleSubmit = (event) => {
    const { getSubmit } = this.props;

    if (getSubmit) {
      getSubmit(this.inputRef.current.value);
    }

    event.preventDefault();
  };

  isLabelFloating() {
    const { value } = this.props;
    const { focused } = this.state;
    return focused || (value && value !== '');
  }

  render() {
    const {
      id,
      name,
      type,
      className,
      value,
      min,
      max,
      step,
      error,
      caption,
      placeholder,
      label,
      notched,
      large,
      unit,
      tooltip,
      onTooltipToggle,
      blur,
      raised,
      italic,
      disabled,
      luminor,
      children,
      buttons,
      preInput,
      postInput,
      onKeyDown,
      allowAutocomplete,
    } = this.props;
    const { focused, random } = this.state;

    const inputStyle = classNames(
      'input',
      {
        'input--large': large,
        'input--focused': focused,
        'input--blur': blur,
        'input--raised': raised,
        'input--notched': notched,
        'input--unit': unit,
        'input--error': error,
        'input--italic': italic,
        'input--luminor': luminor,
      },
      className
    );

    let inputType = type;
    let inputMode;
    let formattedValue = value || '';
    if (['currency', 'float'].includes(type)) {
      inputType = 'text';
      inputMode = 'decimal';
    }
    if (type === 'currency' && formattedValue) {
      formattedValue = formatCurrency(value);
    }

    const tooltipId = id ? `${id}-tooltip` : `input-${random}-tooltip`;
    return (
      <form onSubmit={this.handleSubmit} className="input__container">
        <div className="input__wrap">
          <div className={inputStyle} name={id}>
            {preInput}
            <input
              id={id}
              name={name}
              autoComplete={allowAutocomplete ? name : 'off'}
              type={inputType}
              inputMode={inputMode}
              min={min}
              max={max}
              step={step}
              placeholder={placeholder}
              value={formattedValue}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onKeyDown={onKeyDown}
              ref={this.inputRef}
              disabled={disabled}
            />
            {notched ? (
              <InputLabel id={id} floating={this.isLabelFloating()}>
                {label}
              </InputLabel>
            ) : null}
            {(unit || tooltip) && (
              <div className="input__suffix">
                {unit && <div className="input__unit">{unit}</div>}
                {tooltip && (
                  <a role="tooltip" data-tooltip-id={tooltipId}>
                    <Icon name="question" />
                  </a>
                )}
              </div>
            )}
            {postInput}
          </div>
          {buttons}
        </div>
        {caption && <Caption text={caption} />}
        {error && <ErrorMessage text={error} />}
        {children}
        {tooltip && <Tooltip id={tooltipId} content={tooltip} afterShow={onTooltipToggle} />}
      </form>
    );
  }
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
