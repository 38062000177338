import { isMobile } from 'react-device-detect';

export function getSwedbankCalculatorCid(placementName: string): string {
  if (isMobile) {
    switch (placementName) {
      case 'detailView_sidebar_compact':
        return 'allyear_S-financing-HK-pieteikties-konsultacijai-kalkulatirs_city24_mobile_lv_lat_home-loan';
      case 'detailView_sidebar_full':
        return 'allyear_S-financing-HK-pieteikties-konsultacijai-lielais-kalkulators_city24_mobile_lv_lat_home-loan';
    }
  }

  switch (placementName) {
    case 'detailView_sidebar_compact':
    case 'detailView_bottom':
      return 'allyear_S-financing-HK-pieteikties-konsultacijai-kalkulatirs_city24_desktop_lv_lat_home-loan';
    case 'detailView_gallery_tab':
    case 'detailView_gallery_inner':
    case 'detailView_sidebar_full':
    case 'modal':
      return 'allyear_S-financing-HK-pieteikties-konsultacijai-lielais-kalkulators_city24_desktop_lv_lat_home-loan';
  }

  return '';
}
