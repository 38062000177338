import { createSelector } from 'reselect';
import { Map, fromJS } from 'immutable';
import { DefaultLocale } from '@city24/common/enums/Language';
import { RealtyListingShort } from '@city24/common/types/Realty';

import { RootState } from '@/store';
import { applyImageFormat } from '@/utils/formatting';
import { addFavouriteToObject } from '@/utils/helpers';
import { createObjectRoute } from '@/utils/realty';
import { getFavourites } from '@/selectors/userSelectors';
import { getLocale } from '@/selectors/appSelectors';
import { getSearchPrevNext } from '@/components/search/searchSelectors';
import { createAsyncData } from '@/types/collections';

export const getObjectDetailsJS = createSelector(
  (state: RootState) => state.objectDetails.details,
  getFavourites,
  getLocale,
  (object, favourites, locale) => {
    if (object.friendly_id) {
      const apiLocale = locale.replace('-', '_') as DefaultLocale;
      const getLocaleOption = <T extends Partial<Record<DefaultLocale, any>>>(items: T): T[keyof T] =>
        items ? items[apiLocale] ?? items[Object.keys(items)[0] as keyof T] : undefined;
      const desc = getLocaleOption(object.descriptions);

      const modified = {
        ...object,
        slogan: getLocaleOption(object.slogans)?.slogan,
        description: desc?.introduction,
        introduction: desc?.description,
      };

      return addFavouriteToObject(favourites, modified) as typeof object;
    }

    return object;
  }
);

/** @deprecated remove and replace with getObjectDetailsJS */
export const getObjectDetails = createSelector(getObjectDetailsJS, (object) => {
  return fromJS(object).set('streetview_params', object.streetview_params);
});

export const getObjectDetailsBroker = createSelector(getObjectDetails, (details) => {
  const broker = details.get('broker');
  if (!broker) {
    return Map();
  }

  const businessCard = broker.get('business_card');

  const awards = broker.get('awards') || [];

  if (businessCard) {
    return broker.set(
      'business_card',
      businessCard
        ? businessCard.set('awards', awards)
        : Map({
            first_name: broker.get('first_name'),
            last_name: broker.get('last_name'),
            certificates: [],
            awards,
          })
    );
  }
  return broker;
});

export const getObjectDetailsOffice = createSelector(getObjectDetails, (details) => details.get('office'));

export const getProjectRealtiesJS = (state: RootState) => state.objectDetails.projectRealties.items;
export const getProjectRealties = createSelector(getProjectRealtiesJS, (realties) =>
  createAsyncData(
    realties.data.map((realty) => fromJS(realty)),
    realties.status,
    realties.error
  )
);
export const getOpenDaysDetails = (state: RootState) => state.objectDetails.openDaysDetails;
export const getObjectLoading = (state: RootState) => state.objectDetails.loading;
export const getObjectStatus = (state: RootState) => state.objectDetails.status;
export const getObjectDetailsView = (state: RootState) => state.objectDetails.view;
export const getObjMediaFull = (state: RootState) => getObjectDetailsView(state).fullscreen;
export const getSlideCount = (state: RootState) => state.objectDetails.slideCount;
export const getObjectFavouriteCount = (state: RootState) => state.objectDetails.favouriteCount;
export const getStreetViewAvailability = (state: RootState) => state.objectDetails.streetViewAvailable;

export const getSEBCalculatorNewsArticles = (state: RootState) => state.objectDetails.SEBCalculatorArticles;

export const getObjectNavCount = (state: RootState) => state.objectDetails.objectNavCount;

const mapGalleryImage = (url: string, i: number) => ({
  fullscreen: applyImageFormat(url),
  original: applyImageFormat(url, 'largeImage'),
  thumbnail: applyImageFormat(url, '100x66'),
  gridViewThumbnail: applyImageFormat(url, '342x228'),
  watermarked: applyImageFormat(url, PORTAL_EE ? 'largeWithWatermarkEE' : 'largeWithWatermarkLV'),
  imageSet: [
    {
      srcSet: applyImageFormat(url, '640x2000'),
      media: '(max-width: 640px)',
    },
    {
      srcSet: applyImageFormat(url, 'largeImage'),
      media: '(min-width: 641px)',
    },
  ],
});

export const getObjectDetailsGalleryImages = createSelector(getObjectDetailsJS, (details) => {
  return details.images?.map(mapGalleryImage) ?? [];
});

export const getObjectDetailsPlanImages = createSelector(getObjectDetailsJS, (details) => {
  return details.other_images?.map(mapGalleryImage) ?? [];
});

export const getPrevNextObjects = createSelector(
  getObjectDetailsJS,
  getSearchPrevNext,
  (currentObject, { objects, pagination: { start, total } }) => {
    const currentIndex = objects.findIndex((obj) => obj.get('friendly_id') === currentObject.friendly_id);

    const prev = currentIndex <= 0 ? undefined : objects.get(currentIndex - 1);
    const next = objects.get(currentIndex + 1);

    const createRoute = (obj) => {
      return createObjectRoute({
        friendly_id: obj.get('friendly_id') as RealtyListingShort['friendly_id'],
        unit_type: obj.get('unit_type') as RealtyListingShort['unit_type'],
        transaction_type: obj.get('transaction_type') as RealtyListingShort['transaction_type'],
        address: obj.get('address').toJS() as RealtyListingShort['address'],
      });
    };

    // if (currentIndex === -1) {
    //   return {
    //     total: total,
    //     currentNr: start,
    //     prev: undefined,
    //     next: objects.get(0),
    //   };
    // }

    return {
      total,
      currentNr: start + currentIndex + 1,
      prev: (prev && createRoute(prev)) as string | undefined,
      next: (next && createRoute(next)) as string | undefined,
    };
  }
);

export const getAdditionalServices = (state: RootState) => state.objectDetails.internalFeedArticles;
export const getCenuBanka = (state: RootState) => state.objectDetails.cenuBankaData;
