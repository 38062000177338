import { SelectionAttribute, StandaloneAttribute } from '@city24/common/enums/realty/Attribute';

const OBJECT_SPEC_TYPES = {
  id: { value: 'id', name: 'objectSpec.id' },
  price: { value: 'price', name: 'objectSpec.price' },
  price_per_unit: { value: 'price_per_unit', name: 'objectSpec.pricePerUnit' },
  year_built: { value: 'year_built', name: 'objectSpec.yearBuilt' },
  CONSTRUCTION_YEAR: { value: StandaloneAttribute.CONSTRUCTION_YEAR, name: 'objectSpec.yearBuilt' },
  lot_size: { value: 'lot_size', name: 'objectSpec.lotSize' },
  room_count: { value: 'room_count', name: 'objectSpec.rooms' },
  object_id: { value: 'friendly_id', name: 'objectSpec.id' },
  property_size: { value: 'property_size', name: 'objectSpec.size' },
  lot_size_min: { value: 'lot_size_min', name: 'objectSpec.lotSizeMin' },
  lot_size_max: { value: 'lot_size_max', name: 'objectSpec.lotSizeMax' },
  available_units: { value: 'available_units', name: 'objectSpec.available_units' },
  info: { value: 'info', name: 'objectSpec.additionalInfo' },
  cadaster_number: { value: 'cadaster_number', name: 'objectSpec.cadasterNumber' },
  estate_register_number: {
    value: 'estate_register_number',
    name: 'objectSpec.estateRegisterNumber',
  },
  distance_from_capital: {
    value: 'distance_from_capital',
    name: 'objectSpec.distanceFromCapital',
  },
  BUILDING_MATERIAL: { value: SelectionAttribute.BUILDING_MATERIAL, name: 'objectSpec.buildingMaterial' },
  PURPOSE_OF_USE_LAND: { value: SelectionAttribute.PURPOSE_OF_USE_LAND, name: 'objectSpec.purposeOfUse' },
  PURPOSE_OF_USE_COMMERCIAL: {
    value: SelectionAttribute.PURPOSE_OF_USE_COMMERCIAL,
    name: 'objectSpec.purposeOfUse',
  },
  OTHER_USE_POSSIBLE: { value: StandaloneAttribute.OTHER_USE_POSSIBLE, name: 'objectSpec.otherUsePossible' },
  DETAILED_PLAN: { value: StandaloneAttribute.DETAILED_PLAN, name: 'objectSpec.detailedPlan' },
  HOUSE_TYPE: { value: SelectionAttribute.HOUSE_TYPE, name: 'objectSpec.houseType' },
  HOUSE_CLASS: { value: SelectionAttribute.HOUSE_CLASS, name: 'objectSpec.houseClass' },
  MOVE_IN_READY_YEAR: { value: StandaloneAttribute.MOVE_IN_READY_YEAR, name: 'objectSpec.moveInReadyYear' },
  LIFT: { value: StandaloneAttribute.LIFT, name: 'objectSpec.elevator' },
  ENERGY_CERTIFICATE_TYPE: {
    value: SelectionAttribute.ENERGY_CERTIFICATE_TYPE,
    name: 'objectSpec.energyCertificate',
  },
  ENERGY_SOURCE: { value: SelectionAttribute.ENERGY_SOURCE, name: 'objectSpec.energySource' },
  ROOF_TYPE: { value: SelectionAttribute.ROOF_TYPE, name: 'objectSpec.roofType' },
  PARKING_SELECTION: { value: SelectionAttribute.PARKING_SELECTION, name: 'objectSpec.parkingSelection' },
  PARKING_SELECTION_COMMERCIAL: {
    value: SelectionAttribute.PARKING_SELECTION_COMMERCIAL,
    name: 'objectSpec.parkingSelection',
  },
  PARKING_SELECTION_PROJECT: {
    value: SelectionAttribute.PARKING_SELECTION_PROJECT,
    name: 'objectSpec.parkingSelection',
  },
  PARKING_PLACES: { value: StandaloneAttribute.PARKING_PLACES, name: 'objectSpec.parkingPlaces' },
  PARKING_FEE: { value: StandaloneAttribute.PARKING_FEE, name: 'objectSpec.parkingFee' },
  PARKING_FREE: { value: StandaloneAttribute.PARKING_FREE, name: 'objectSpec.parkingFree' },
  EXTRA_SPACES: {
    value: [
      { value: StandaloneAttribute.BASEMENT, name: 'objectSpec.basement' },
      { value: StandaloneAttribute.STORAGE_ROOM, name: 'objectSpec.storageRoom' },
      { value: StandaloneAttribute.WARDROBE, name: 'objectSpec.wardrobe' },
    ],
    name: 'objectSpec.extraSpaces',
  },
  COMMUNICATIONS: { value: SelectionAttribute.COMMUNICATIONS, name: 'objectSpec.communications' },
  OWNERSHIP: { value: SelectionAttribute.OWNERSHIP, name: 'objectSpec.ownership' },
  CONDITION: { value: SelectionAttribute.CONDITION, name: 'objectSpec.condition' },
  NUMBER_OF_BEDROOMS: { value: StandaloneAttribute.NUMBER_OF_BEDROOMS, name: 'objectSpec.numberOfBedrooms' },
  NUMBER_OF_BATHROOMS: { value: StandaloneAttribute.NUMBER_OF_BATHROOMS, name: 'objectSpec.numberOfBathrooms' },
  NUMBER_OF_DESKS: { value: StandaloneAttribute.NUMBER_OF_DESKS, name: 'objectSpec.numberOfDesks' },
  KITCHEN_AREA: { value: StandaloneAttribute.KITCHEN_AREA, name: 'objectSpec.kitchenArea' },
  BALCONY_TYPE: { value: SelectionAttribute.BALCONY_TYPE, name: 'objectSpec.balconyType' },
  BALCONY_SIZE: { value: StandaloneAttribute.BALCONY_SIZE, name: 'objectSpec.balconySize' },
  FLOOR: { value: StandaloneAttribute.FLOOR, name: 'objectSpec.floor' },
  TOTAL_FLOORS: { value: StandaloneAttribute.TOTAL_FLOORS, name: 'objectSpec.totalFloors' },
  EXTRA_VALUES: {
    value: [
      { value: StandaloneAttribute.SEPARATE_ENTRANCE, name: 'objectSpec.separateEntrance' },
      { value: StandaloneAttribute.CLOSED_YARD, name: 'objectSpec.closedYard' },
      { value: StandaloneAttribute.FURNITURE, name: 'objectSpec.furniture' },
      { value: StandaloneAttribute.FURNISHING_POSSIBLE, name: 'objectSpec.furnishingPossible' },
      { value: StandaloneAttribute.PETS_ALLOWED, name: 'objectSpec.petsAllowed' },
      { value: StandaloneAttribute.INDUSTRIAL_POWER_SUPPLY, name: 'objectSpec.industrialPowerSupply' },
      { value: StandaloneAttribute.MEETING_ROOM, name: 'objectSpec.meetingRoom' },
      { value: StandaloneAttribute.HAS_TV, name: 'objectSpec.hasTv' },
      { value: StandaloneAttribute.REFRIGERATOR, name: 'objectSpec.hasRefrigirator' },
      { value: StandaloneAttribute.DISHWASHER, name: 'objectSpec.dishwasher' },
      { value: StandaloneAttribute.KITCHEN, name: 'objectSpec.kitchen' },
      { value: StandaloneAttribute.KITCHEN_FURNITURE, name: 'objectSpec.kitchenFurniture' },
    ],
    name: 'objectSpec.extraValues',
  },
  LIMITATIONS: { value: SelectionAttribute.LIMITATIONS, name: 'objectSpec.limitations' },
  HEATING_SYSTEM: { value: SelectionAttribute.HEATING_SYSTEM, name: 'objectSpec.heatingSystem' },
  HEATING_TYPE: { value: SelectionAttribute.HEATING_TYPE, name: 'objectSpec.heatingType' },
  SANITARY_ARRANGEMENTS: {
    value: SelectionAttribute.SANITARY_ARRANGEMENTS,
    name: 'objectSpec.sanitaryArrangements',
  },
  VENTILATION_TYPE: { value: SelectionAttribute.VENTILATION_TYPE, name: 'objectSpec.ventilationType' },
  STOVE: { value: SelectionAttribute.STOVE, name: 'objectSpec.stove' },
  RENT_PER_DAY: { value: StandaloneAttribute.RENT_PER_DAY, name: 'objectSpec.rentPerDay' },
  BROKERS_FEE: { value: StandaloneAttribute.BROKERS_FEE, name: 'objectSpec.brokersFee' },
  DEPOSIT_MONEY: { value: StandaloneAttribute.DEPOSIT_MONEY, name: 'objectSpec.deposit' },
  COMMUNAL_COSTS: { value: SelectionAttribute.COMMUNAL_COSTS, name: 'objectSpec.communalCosts' },
  RENT_COMMUNAL_COSTS: { value: SelectionAttribute.RENT_COMMUNAL_COSTS, name: 'objectSpec.rentCommunalCosts' },
  RENT_SUMMER_COMMUNAL_COSTS: {
    value: StandaloneAttribute.RENT_SUMMER_COMMUNAL_COSTS,
    name: 'objectSpec.rentSummerCommunalCosts',
  },
  RENT_WINTER_COMMUNAL_COSTS: {
    value: StandaloneAttribute.RENT_WINTER_COMMUNAL_COSTS,
    name: 'objectSpec.rentWinterCommunalCosts',
  },
  AVAILABLE: { value: StandaloneAttribute.AVAILABLE, name: 'objectSpec.available' },
  SECURITY: { value: SelectionAttribute.SECURITY, name: 'objectSpec.securityArrangements' },
  VAT: { value: StandaloneAttribute.VAT, name: `objectSpec.VAT.${PORTAL}` },
  FINISH_STAGE: { value: SelectionAttribute.FINISH_STAGE, name: 'objectSpec.finishStage' },
  OUTDOOR_SPACES: { value: SelectionAttribute.OUTDOOR_SPACES, name: 'objectSpec.outdoorSpaces' },
  ADVANTAGES: {
    value: [
      { value: StandaloneAttribute.ELEVATOR, name: 'objectSpec.elevator' },
      { value: StandaloneAttribute.STORAGE_SPACE, name: 'objectSpec.storageSpace' },
      { value: StandaloneAttribute.GARDEN, name: 'objectSpec.garden' },
      { value: StandaloneAttribute.SHARED_WAREHOUSE, name: 'objectSpec.sharedWarehouse' },
      { value: StandaloneAttribute.SHARED_LOUNGE, name: 'objectSpec.sharedLounge' },
      { value: StandaloneAttribute.SMART_HOME_FEATURES, name: 'objectSpec.smartHome' },
      { value: StandaloneAttribute.HEATED_FLOORS, name: 'objectSpec.heatedFloors' },
      { value: StandaloneAttribute.SAUNA, name: 'objectSpec.sauna' },
    ],
    name: 'objectSpec.advantages',
  },
  UTILITIES: { value: SelectionAttribute.UTILITIES, name: 'objectSpec.utilities' },
  SECURITY_OPTIONS_PROJECT: {
    value: SelectionAttribute.SECURITY_OPTIONS_PROJECT,
    name: 'objectSpec.securityArrangements',
  },
  NEARBY_SURROUNDINGS: { value: SelectionAttribute.NEARBY_SURROUNDINGS, name: 'objectSpec.nearbySurroundings' },
  LUMINOR_MONTHLY_PAYMENT: {
    value: 'luminor_monthly_payment',
    name: 'objectSpec.luminorMonthlyPayment',
  },
  LUMINOR_CONSUMER_LOAN: { value: 'luminor_consumer_loan', name: 'objectSpec.luminorConsumerLoan' },
  SEB_MONTHLY_PAYMENT: {
    value: 'seb_monthly_payment',
    name: 'objectSpec.sebMonthlyPayment',
  },
  NO_OF_LAND_LOTS: { value: StandaloneAttribute.NO_OF_LAND_LOTS, name: 'objectSpec.numberOfLandLots' },
  MUNICIPALITY_UTILITIES: {
    value: [
      { value: StandaloneAttribute.ELECTRICITY, name: 'objectSpec.electricity' },
      { value: StandaloneAttribute.GAS, name: 'objectSpec.gas' },
      { value: StandaloneAttribute.WATER_AND_SEWAGE_CONNECTION, name: 'objectSpec.waterAndSewage' },
      { value: StandaloneAttribute.INTERNET, name: 'objectSpec.internet' },
      { value: StandaloneAttribute.ROAD, name: 'objectSpec.road' },
    ],
    name: 'objectSpec.municipalityUtilities',
  },
} as const;

export type ObjectSpecType = (typeof OBJECT_SPEC_TYPES)[keyof typeof OBJECT_SPEC_TYPES];

export default OBJECT_SPEC_TYPES;
