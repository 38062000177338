import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import ReactGA from '@/utils/ga';
import { Trans } from 'react-i18next';
import type { TFunction } from 'next-i18next';

import { AppDispatch, RootState } from '@/store';
import { validateEmail } from '@/utils/validation';
import { SITE_NAME } from '@/constants';
import { GA_NEW_ACCOUNT } from '@/constants/ga';

import { createAccount } from '@/actions/userActions';
import { getModalState, getPrefixedRoutes, isModalOpen } from '@/selectors/appSelectors';
import { getUserViolations } from '@/selectors/userSelectors';

import AppContext from '@/components/appContext';
import Button from '@/components/button/NextButton';
import Input from '@/components/input/Input';
import ButtonGroup from '@/components/button/ButtonGroup';
import CheckboxField from '@/components/checkbox/CheckboxField';
import Recaptcha, { RecaptchaProps } from '@/components/Recaptcha/Recaptcha';

import Shield from '@/img/icons/shield.svg';

import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import FancySection from '../ModalSections/FancySection';
import { RegisterModalState } from '../modalInterfaces';
import { closeModal } from '../modalReducer';

interface Props extends PropsFromRedux {
  t: TFunction;
  dispatch: AppDispatch;
}

interface State {
  email: string;
  emailMissing: boolean;
  emailNotEmail: boolean;
  username: string;
  usernameMissing: boolean;
  phone: string;
  phoneMissing: boolean;
  password: string;
  passwordMissing: boolean;
  passwordTooShort: boolean;
  agree: boolean;
  notAgreed: boolean;
  subscribeToNewsletter: boolean;
  captcha: { verified: boolean; token: string; version: number };
}

class RegisterModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      emailMissing: false,
      emailNotEmail: false,
      username: '',
      usernameMissing: false,
      phone: '',
      phoneMissing: false,
      password: '',
      passwordMissing: false,
      passwordTooShort: false,
      agree: false,
      notAgreed: false,
      subscribeToNewsletter: false,
      captcha: { verified: false, token: '', version: 3 },
    };
  }

  createAccount = () => {
    const { dispatch, routes } = this.props;
    const { email, password, username, phone, agree, subscribeToNewsletter, captcha } = this.state;

    const formErrors = {
      emailMissing: !email,
      emailNotEmail: !validateEmail(email),
      usernameMissing: !username,
      phoneMissing: !phone,
      passwordMissing: !password,
      passwordTooShort: password.length > 0 && password.length < 8,
      notAgreed: !agree,
    };

    if (
      captcha.verified &&
      !formErrors.emailMissing &&
      !formErrors.emailNotEmail &&
      !formErrors.usernameMissing &&
      !formErrors.phoneMissing &&
      !formErrors.passwordMissing &&
      !formErrors.passwordTooShort &&
      !formErrors.notAgreed
    ) {
      dispatch(
        createAccount(
          email,
          password,
          username,
          phone,
          routes.accountVerification.replace(':hash', ''),
          subscribeToNewsletter,
          captcha
        )
      );
    }

    this.setState(formErrors);

    ReactGA.event({
      category: GA_NEW_ACCOUNT,
      action: 'create_account_button',
    });
  };

  onCaptchaVerified: RecaptchaProps['onVerified'] = (verified, token, version) => {
    this.setState({ captcha: { verified, token, version } });
  };

  render() {
    const { dispatch, modal, violations, t, routes } = this.props;
    const {
      email,
      emailMissing,
      emailNotEmail,
      username,
      usernameMissing,
      phone,
      phoneMissing,
      password,
      passwordMissing,
      passwordTooShort,
      notAgreed,
      agree,
      subscribeToNewsletter,
      captcha,
    } = this.state;
    const { confirmed } = modal.state as RegisterModalState;

    let emailInUse: string;

    violations.forEach((violation) => {
      if (violation.message === 'errors.email.unique') {
        emailInUse = violation.message;
      }
    });

    const closeRegisterModal = () => {
      dispatch(closeModal());
      ReactGA.event({
        category: GA_NEW_ACCOUNT,
        action: 'close_button',
      });
    };

    const renderContent = () => {
      if (confirmed) {
        return (
          <>
            <FancySection
              image={Shield}
              title={t('registerConfirmModal.title')}
              subtitle={t('registerConfirmModal.subtitle')}
            />
            <Button btnType="primary" extended onClick={closeRegisterModal}>
              {t('common.close')}
            </Button>
          </>
        );
      }
      return (
        <AppContext.Consumer>
          {({ Link }) => (
            <div>
              <ModalHeader title={t('newAccountModal.title')} />
              <ButtonGroup vertical wide>
                <Input
                  notched
                  label={t('common.email')}
                  value={email}
                  type="email"
                  getValue={(value: string) => this.setState({ email: value })}
                  error={(() => {
                    if (emailMissing) {
                      return t('errors.fillField');
                    }
                    if (emailNotEmail) {
                      return t('errors.email.notEmail');
                    }
                    if (emailInUse) {
                      return t(emailInUse);
                    }
                    return null;
                  })()}
                  gaEvent={() =>
                    ReactGA.event({
                      category: GA_NEW_ACCOUNT,
                      action: 'insert_email',
                    })
                  }
                  onBlur={() => {
                    this.setState({ emailMissing: false, emailNotEmail: false });
                  }}
                />
                <Input
                  notched
                  label={t('common.fullName')}
                  value={username}
                  getValue={(value: string) => this.setState({ username: value })}
                  error={usernameMissing && t('errors.fillField')}
                  gaEvent={() =>
                    ReactGA.event({
                      category: GA_NEW_ACCOUNT,
                      action: 'insert_fullname',
                    })
                  }
                  onBlur={() => {
                    this.setState({ usernameMissing: false });
                  }}
                />
                <Input
                  notched
                  label={t('common.phone')}
                  value={phone}
                  getValue={(value: string) => this.setState({ phone: value })}
                  error={phoneMissing && t('errors.fillField')}
                  gaEvent={() =>
                    ReactGA.event({
                      category: GA_NEW_ACCOUNT,
                      action: 'insert_phone',
                    })
                  }
                  onBlur={() => {
                    this.setState({ phoneMissing: false });
                  }}
                />
                <Input
                  type="password"
                  notched
                  label={t('common.password')}
                  value={password}
                  getValue={(value: string) => this.setState({ password: value })}
                  error={(() => {
                    if (passwordTooShort) {
                      return t('errors.passwordTooShort');
                    }
                    if (passwordMissing) {
                      return t('errors.fillField');
                    }
                    return null;
                  })()}
                  caption={t('registration.passwordMinLength')}
                  gaEvent={() =>
                    ReactGA.event({
                      category: GA_NEW_ACCOUNT,
                      action: 'insert_password',
                    })
                  }
                  onBlur={() => {
                    this.setState({ passwordTooShort: false, passwordMissing: false });
                  }}
                />
                <CheckboxField
                  text={
                    <Trans
                      t={t}
                      i18nKey="registration.agreeToTerms"
                      values={{ portal: SITE_NAME }}
                      components={{
                        privacyLink: (
                          <Link key="privacy" href={routes.privacyRegulations} className="inline-link" target="_blank">
                            {routes.privacyRegulations}
                          </Link>
                        ),
                        termsLink: (
                          <Link key="terms" href={routes.termsOfUse} className="inline-link" target="_blank">
                            {routes.termsOfUse}
                          </Link>
                        ),
                      }}
                    />
                  }
                  id="agree-to-terms"
                  getValue={(agree: boolean) => {
                    this.setState({ agree });
                    ReactGA.event({
                      category: GA_NEW_ACCOUNT,
                      action: 'agreePrivacyPolicyTerms',
                    });
                  }}
                  checked={agree}
                  error={notAgreed && t('errors.agreeToTerms')}
                />
                <CheckboxField
                  text={t('common.subscribeToNewsletter')}
                  id="subscribe-to-newsletter"
                  getValue={(subscribeToNewsletter: boolean) => {
                    this.setState({ subscribeToNewsletter });
                    ReactGA.event({
                      category: GA_NEW_ACCOUNT,
                      action: 'subscribeToNewsletter',
                    });
                  }}
                  checked={subscribeToNewsletter}
                />
                <Recaptcha onVerified={this.onCaptchaVerified} skipBackendVerification />
                <Button btnType="primary" onClick={this.createAccount} disabled={!captcha.verified}>
                  {t('newAccountModal.createAccount')}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </AppContext.Consumer>
      );
    };

    return (
      <Modal
        isOpen={isModalOpen(modal, 'register')}
        onRequestClose={closeRegisterModal}
        contentLabel={confirmed ? t('registerConfirmModal.title') : t('newAccountModal.title')}
      >
        {renderContent()}
      </Modal>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    modal: getModalState(state),
    violations: getUserViolations(state),
    routes: getPrefixedRoutes(state),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RegisterModal);
