import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';
import { formatPrice } from '@/utils/formatting';
import SizeUnit, { getSizeUnitString } from '@city24/common/enums/realty/SizeUnit';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import { getObjectDetailRoute } from '@/utils/route';
import Icon from '@/components/icon/Icon';
import LegacyToNextLink from '@/components/link/LegacyToNextLink';

function ObjectListItem({
  id,
  friendlyId,
  objectType,
  price,
  size,
  sizeUnit,
  lotSize,
  lotSizeUnit,
  floor,
  rooms,
  heating,
  condition,
  parking,
  index,
  onlyMainFeatures,
  objectRoute,
  object,
  t,
  typeCombination,
  location,
  urlLocation,
  newTab,
  attributes,
  ...props
}) {
  const route = getObjectDetailRoute(objectRoute, friendlyId, typeCombination, urlLocation);

  const renderAdditionalFeature = (name, value) => {
    if (!value || onlyMainFeatures) return '';
    let values = value;

    if (Array.isArray(value)) {
      values = value.join(', ');
    }

    return (
      <span className="object-list__feature object-list__feature--additional">
        <span className="object-list__feature-name">{`${name}:`}</span>
        {` ${values}`}
      </span>
    );
  };

  const handleClick = (event) => {
    props.onClick(index, event);
  };

  const linkWrapper = (children) => {
    return (
      <LegacyToNextLink href={route} onClick={handleClick} target={newTab ? '_blank' : undefined}>
        {children}
      </LegacyToNextLink>
    );
  };

  const renderSize = () => {
    if (lotSize && objectType === UnitTypeLiteral.LAND) {
      return <td>{linkWrapper(`${lotSize} ${getSizeUnitString(lotSizeUnit ?? SizeUnit.SquareMeter)}`)}</td>;
    }
    if (size) {
      return <td>{linkWrapper(`${size} ${getSizeUnitString(sizeUnit ?? SizeUnit.SquareMeter)}`)}</td>;
    }
    return <td />;
  };

  return (
    <tr
      // className={classNames('object-list__item', {
      //   'object-list__item--main': onlyMainFeatures
      // })}
      className="object-list__item"
      to={getObjectDetailRoute(objectRoute, friendlyId, typeCombination, urlLocation)}
    >
      <td className="object-list__highlight">{linkWrapper(formatPrice(price))}</td>
      {renderSize()}

      {attributes.some((a) => a.value === 'rooms') && (
        <td>
          {linkWrapper(
            rooms && (
              <>
                <Icon name="door" className="object-list__icon" />
                {rooms}
              </>
            )
          )}
        </td>
      )}

      {attributes.some((a) => a.value === 'floor') && (
        <td>
          {linkWrapper(
            floor && (
              <>
                <Icon name="stairs" className="object-list__icon" />
                {floor}
              </>
            )
          )}
        </td>
      )}

      {attributes.some((a) => a.value === 'apartment') && (
        <td>{object && linkWrapper(object.getIn(['address', 'apartment_number']))}</td>
      )}

      {/* <td>
        <div className="object-list__wrapper">
          {renderAdditionalFeature(t('objectDetail.heating'), heating)}
          {renderAdditionalFeature(t('objectDetail.condition'), condition)}
          {renderAdditionalFeature(t('objectDetail.parking'), parking)}
        </div>
      </td> */}
      <td>{linkWrapper(<Icon name={newTab ? 'newtab' : 'angle-right'} className="object-list__arrow" />)}</td>
    </tr>
  );
}

ObjectListItem.propTypes = {
  id: PropTypes.number.isRequired,
  friendlyId: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  size: PropTypes.number,
  floor: PropTypes.string,
  rooms: PropTypes.number,
  heating: PropTypes.oneOfType([PropTypes.instanceOf(Collection), PropTypes.string]),
  condition: PropTypes.oneOfType([PropTypes.instanceOf(Collection), PropTypes.string]),
  parking: PropTypes.string,
  index: PropTypes.number.isRequired,
  onlyMainFeatures: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func,
  objectRoute: PropTypes.string.isRequired,
  typeCombination: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  urlLocation: PropTypes.string.isRequired,
};

ObjectListItem.defaultProps = {
  size: undefined,
  floor: undefined,
  rooms: undefined,
  heating: undefined,
  condition: undefined,
  parking: undefined,
  t: (a) => a,
};

export default ObjectListItem;
