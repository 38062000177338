import api from '@/api/City24Api';
import { defaultLocaleForLanguage } from '@/constants';
import { appActions } from '@/reducers/app';
import Language from '@city24/common/enums/Language';

export function setLocale(rawLocale: string, isLanguage = true) {
  api.changeLocale(isLanguage ? defaultLocaleForLanguage(rawLocale) : rawLocale);

  return appActions.setLocale(rawLocale as Language);
}

export const { toggleHeader } = appActions;
