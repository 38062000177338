import React from 'react';
import PropTypes from 'prop-types';

const LoadingPlaceholderBox = ({ size }) => {
  return <span className="empty-space">{new Array(size).join('m')}</span>;
};

LoadingPlaceholderBox.propTypes = {
  size: PropTypes.number.isRequired,
};

LoadingPlaceholderBox.defaultProps = {};

export default LoadingPlaceholderBox;
