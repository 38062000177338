import React from 'react';
import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { Map, Seq } from 'immutable';
import { TFunction } from 'next-i18next';
import { formatPrice } from '@/utils/formatting';
import { MultiBankCalculatorDefaults } from '@/types/finance';
import {
  CUSTOM_LV_BIGBANK_CAMPAIGN_RUNNING,
  CUSTOM_LV_LUMINOR_CAMPAIGN_RUNNING,
  SELF_FINANCE_UNIT,
  SELF_FINANCE_UNIT_OPTIONS,
} from '@/constants/finance';
import Input from '@/components/input/Input';
import Select from '@/components/select/Select';
import ButtonGroup from '@/components/button/ButtonGroup';
import InlineSelect from '@/components/select/InlineSelect';
import CalculatedField from '@/components/finance/Calculators/CalculatedField';
import Button from '@/components/button/Button';

type Props = {
  t: TFunction;
  calcState: any;
  euriborRate: number;
  setCalcState: (field: string, value: any) => void;
  results: any;
  defaults: MultiBankCalculatorDefaults;
  links: { button: string; link: string };
  bank: string;
  accentColor: string;
  periodOptions: Seq.Indexed<Map<string, string>>;
  compact?: boolean;
  gaEventHandler: (action: string, label?: string) => void;
};

const ExtendedLoanCalculator = ({
  t,
  calcState,
  euriborRate,
  setCalcState,
  results,
  defaults,
  links,
  bank,
  accentColor,
  periodOptions,
  compact = false,
  gaEventHandler,
}: Props): React.ReactElement => {
  const calcStyle = classNames('calc', { 'calc--compact': compact });

  return (
    <div className={calcStyle}>
      <div className="calc__inputs">
        <Input
          notched
          type="number"
          label={t('loanCalc.price')}
          unit="€"
          min={0}
          value={Number(calcState.price).toFixed(0)}
          getValue={(val: string) => setCalcState('price', val)}
          gaEvent={() => gaEventHandler('update_field_value', 'price')}
        />
        <Select
          notched
          label={t('loanCalc.period')}
          options={
            defaults.maxPeriod
              ? periodOptions.filter((option) => Number(option.get('value')) <= Number(defaults.maxPeriod))
              : periodOptions
          }
          selected={calcState.period}
          getValue={(val) => setCalcState('period', val)}
          translateOption={(option) => t(option.get('name'), { count: parseInt(option.get('value'), 10) })}
          gaEvent={() => gaEventHandler('update_field_value', 'period')}
        />
        <Input
          notched
          type="float"
          step="any"
          label={t('loanCalc.interest')}
          unit="%"
          min={0}
          max={30}
          value={calcState.interest}
          getValue={(val: number) => setCalcState('interest', val)}
          gaEvent={() => gaEventHandler('update_field_value', 'interest')}
        />
        <Input
          notched
          type="float"
          step="any"
          label={t('loanCalc.euribor')}
          unit="%"
          value={euriborRate}
          disabled
          getValue={() => null}
          tooltip={<Markdown>{t('loanCalc.euriborDisclaimer')}</Markdown>}
        />
        <ButtonGroup fixed tight>
          <Input
            notched
            type="currency"
            min={0}
            max={calcState.selfFinancingUnit?.get('value') === SELF_FINANCE_UNIT.percent ? 80 : undefined}
            className="flex-1"
            label={t('loanCalc.selfFinance')}
            getValue={(val: number) => setCalcState('selfFinancing', val)}
            value={calcState.selfFinancing}
            gaEvent={() => gaEventHandler('update_field_value', 'selfFinance')}
          />
          <InlineSelect
            className="flex-0"
            options={SELF_FINANCE_UNIT_OPTIONS}
            selected={calcState.selfFinancingUnit}
            getValue={(val: any) => {
              setCalcState('selfFinancingUnit', val);
              if (calcState.selfFinancingUnit !== val && calcState.selfFinancing && calcState.price) {
                const newVal = Math.floor(
                  val.get('value') === SELF_FINANCE_UNIT.percent
                    ? (calcState.selfFinancing / calcState.price) * 100
                    : (calcState.price * calcState.selfFinancing) / 100
                );
                setCalcState('selfFinancing', newVal);
              }
            }}
            // gaAction="insert_selfFinance"
            // gaCategory={gaCategory}
          />
        </ButtonGroup>
      </div>
      <div className="calc__summary">
        <CalculatedField label={t('loanCalc.selfFinance')} value={formatPrice(results.autoSelfFinance)} />
        <CalculatedField label={t('loanCalc.loanAmount')} value={formatPrice(results.loanAmount)} />
        {PORTAL_LV && <CalculatedField label={t('loanCalc.commision')} value={formatPrice(results.commissionFee)} />}
        <CalculatedField label={t('loanCalc.totalAmount')} value={formatPrice(results.totalAmount)} />
        {PORTAL_EE && (
          <CalculatedField label={t('loanCalc.totalInterest')} value={formatPrice(results.totalInterest)} />
        )}
        {PORTAL_LV && <CalculatedField label={t('loanCalc.apr')} value={`${Number(results.apr).toFixed(2)} %`} />}
        {PORTAL_LV && (
          <CalculatedField
            label={t('loanCalc.monthlyFee')}
            value={defaults.accountMonthlyFee > 0 ? formatPrice(defaults.accountMonthlyFee) : '-'}
          />
        )}
        {PORTAL_LV && (
          <CalculatedField
            label={t('loanCalc.additionalFee')}
            value={results.loanRegistrationFee > 0 ? formatPrice(results.loanRegistrationFee) : '-'}
          />
        )}
        <CalculatedField label={t('loanCalc.monthlyPayment')} value={formatPrice(results.monthlyPayment)} bigValue />

        <Button
          style={{ backgroundColor: accentColor }}
          primary
          extended
          className="calc__cta"
          text={t(`loanCalc.cta.${bank}`)}
          href={links.button}
          newTab
          onClick={() => gaEventHandler('button_click', bank)}
        />
        <div className="seb-calc__info">
          <div className="seb-calc__disclaimer">
            {PORTAL_LV && bank === 'bigbank' && CUSTOM_LV_BIGBANK_CAMPAIGN_RUNNING && (
              <div style={{ color: 'red' }}>{t(`loanCalc.bigbankOffer`)}</div>
            )}
            {PORTAL_LV && bank === 'luminor' && CUSTOM_LV_LUMINOR_CAMPAIGN_RUNNING && (
              <div style={{ color: 'red' }}>{t(`loanCalc.luminorOffer`)}</div>
            )}
            <Markdown>
              {t(`loanCalc.multiBankDisclaimer.${PORTAL}`, { href: links.link, interpolation: { escapeValue: false } })}
            </Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendedLoanCalculator;
