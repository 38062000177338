import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import ReactGA from '@/utils/ga';

import { GA_SEARCH } from '@/constants/ga';

import { pick } from '@/utils/object';
import { getSearchModalOpened } from '@/selectors/appSelectors';

import * as modalActions from '../ModalActions';
import * as searchActions from '../../search/SearchActions';

import Modal from '../Modal';
import Button from '../../button/Button';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import AreaSelect from '../../search/AreaSelect/AreaSelect';

const propTypes = {
  isOpen: PropTypes.bool,
  setAreaSelect: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func,
};

const defaultProps = {
  isOpen: false,
  t: (a) => a,
};

class AreaSelectModal extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  applyAreaSelect = (county, parish, cityOrDistrict) => {
    const { setAreaSelect, closeModal, searchModalOpened } = this.props;
    const isDraft = searchModalOpened;
    setAreaSelect(county, parish, cityOrDistrict, isDraft);
    closeModal();
  };

  renderApplyButton = (text, onSubmit) => {
    return (
      <ModalFooter>
        <Button extended primary text={text} onClick={onSubmit} />
      </ModalFooter>
    );
  };

  render() {
    const { isOpen, closeModal, t } = this.props;

    return (
      <Modal
        fullScreen
        className="as-modal"
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        ontop
        gaEvent={() =>
          ReactGA.event({
            category: GA_SEARCH,
            action: 'close_button',
          })
        }
      >
        <ModalHeader title={t('areaSelectModal.title')} />
        <AreaSelect t={t} onSubmit={this.applyAreaSelect} renderButton={this.renderApplyButton} />
      </Modal>
    );
  }
}

AreaSelectModal.propTypes = propTypes;
AreaSelectModal.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    searchModalOpened: getSearchModalOpened(state),
  };
}

const mapDispatchToProps = {
  ...modalActions,
  ...pick(searchActions, 'setAreaSelect'),
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaSelectModal);
