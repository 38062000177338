import { combineReducers } from 'redux';
import produce, { freeze } from 'immer';
import {
  MAP_OBJECT_SELECTED,
  MAP_INFO_CLOSE,
  MAP_OBJECT_UPDATE,
  MAP_LOAD_NEARBY_PLACES,
  MAP_CLEAR_OBJECTS,
  SEARCH_OBJECTS,
  MAP_CLEAR_SELECTED_ZOOM,
  SET_MAP_BOUNDS,
} from '@/constants/actions';

import mapInfoReducer from '../MapInfo/mapInfoReducers';
import { NearbyPlace, TransportRoute } from '@city24/common/types/NearbyPlace';
import RouteType from '@city24/common/enums/nearbyPlace/RouteType';

const initialMarkersState = {
  markers: [],
  selected: null,
};

const markers = produce((state, action) => {
  switch (action.type) {
    case MAP_OBJECT_UPDATE: {
      const { markers: newMarkers } = action;
      const { selected } = state;
      if (
        selected !== null &&
        !newMarkers.find((m) => selected.latitude === m.latitude && selected.longitude === m.longitude)
      ) {
        // If selected marker is not included in new markers
        newMarkers.push(selected);
      }
      state.markers = newMarkers;
      break;
    }
    case MAP_OBJECT_SELECTED:
      state.selected = action.marker;
      break;
    case MAP_CLEAR_SELECTED_ZOOM:
      if (state.selected) {
        delete state.selected.zoom;
      }
      break;
    case MAP_INFO_CLOSE:
    case SEARCH_OBJECTS:
      state.selected = null;
      break;
    case MAP_CLEAR_OBJECTS:
      state.selected = null;
      state.markers = [];
      break;
    default:
      break;
  }
}, initialMarkersState);

export interface NearbyPlaceUi {
  id: number;
  name: string;
  distance: string;
  coordinates: [string, string];
}

export interface TransportPlace extends NearbyPlaceUi {
  routes: Partial<Record<RouteType, TransportRoute[]>>;
}

export interface NearbyPlacesState {
  transport: TransportPlace[];
  school: NearbyPlaceUi[];
  kindergarden: NearbyPlaceUi[];
}

const initialNearbyPlacesState: NearbyPlacesState = {
  transport: [],
  school: [],
  kindergarden: [],
};

function mapNearbyPlace(place: NearbyPlace): NearbyPlaceUi {
  return {
    id: place.place_id,
    name: place.name,
    distance: place.distance,
    coordinates: [place.coordinates.longitude, place.coordinates.latitude],
  };
}

const nearbyPlaces = produce((state, action) => {
  if (action.type === MAP_LOAD_NEARBY_PLACES) {
    if (action.group === 'transport') {
      state.transport = freeze(
        (action.places as NearbyPlace[]).map((place) => {
          const mapped = mapNearbyPlace(place) as TransportPlace;
          mapped.routes = place.extra_data
            ? Object.values(place.extra_data.routes).reduce((routeTypes, route) => {
                const routeName = route.route_type;
                routeTypes[routeName] = routeTypes?.[routeName] || [];
                routeTypes[routeName]!.push(route);
                return routeTypes;
              }, {} as TransportPlace['routes'])
            : {};
          return mapped;
        })
      );
    } else {
      state[action.group as keyof typeof initialNearbyPlacesState] = freeze(action.places.map(mapNearbyPlace));
    }
  }
}, initialNearbyPlacesState);

const initialBoundsState = {
  n: null,
  e: null,
  s: null,
  w: null,
  zoom: null,
};

const bounds = produce((state, action) => {
  switch (action.type) {
    case SET_MAP_BOUNDS:
      state.n = action.bounds.n;
      state.e = action.bounds.e;
      state.s = action.bounds.s;
      state.w = action.bounds.w;
      state.zoom = action.zoom;
      break;
    default:
      break;
  }
}, initialBoundsState);

export default combineReducers({
  markers,
  info: mapInfoReducer,
  nearbyPlaces,
  bounds,
});
