import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Map, List, fromJS, Collection } from 'immutable';

import { NameValue } from '@/utils/collections';
import { camelCase } from '@/utils/helpers';
import { getBrowser } from '@/selectors/appSelectors';

import Multiselect from '@/components/multiselect/Multiselect';
import Dropdown from '@/components/dropdown/Dropdown';
import Overlay from '@/components/common/Overlay';
import Icon from '@/components/icon/Icon';
import ErrorMessage from '@/components/errorMessage/ErrorMessage';
import Button from '@/components/button/Button';
import AutocompleteSelect from './AutocompleteSelect';

const propTypes = {
  autocomplete: PropTypes.bool,
  native: PropTypes.bool,
  multiple: PropTypes.bool,
  notched: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.instanceOf(Collection).isRequired,
  selected: PropTypes.oneOfType([PropTypes.instanceOf(Collection), PropTypes.instanceOf(NameValue)]),
  maxSelectedVisible: PropTypes.number,
  empty: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  getValue: PropTypes.func,
  t: PropTypes.func,
  namespace: PropTypes.string,
  translateOption: PropTypes.func,
  className: PropTypes.string,
  gaEvent: PropTypes.func,
  gaEventSelect: PropTypes.bool,
  extraSmall: PropTypes.bool,
};

const defaultProps = {
  autocomplete: false,
  native: false,
  multiple: false,
  notched: false,
  label: '',
  selected: Map(),
  maxSelectedVisible: 3,
  empty: false,
  getValue: null,
  t: (a) => a,
  namespace: '',
  translateOption: null,
  className: '',
  gaEvent: null,
  gaEventSelect: true,
  extraSmall: false,
};

class Select extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focused: '',
      opened: false,
    };

    this.selectRef = React.createRef();
    this.labelRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  getLabelFloating = () => {
    const { selected, empty } = this.props;
    const { focused } = this.state;

    if (this.isOptionGroup()) {
      if (selected.isEmpty() || selected.get('value') === '') {
        return false;
      }
      return selected.getIn([0, 'value']) !== '';
    }
    if (selected.isEmpty()) {
      if (focused) {
        return true;
      }
      return false;
    }
    return selected.get('value') !== '';
  };

  isOptionGroup = () => {
    const { options } = this.props;
    return options.hasIn([0, 'options']);
  };

  translateOption = (option) => {
    const { t, namespace, translateOption } = this.props;
    if (option.getIn(['extra', 'translated'])) {
      return option.get('name');
    }
    return (
      (translateOption && translateOption(option)) ||
      t(namespace ? `${namespace}:${option.get('name')}` : option.get('name'))
    );
  };

  renderCount = (option) => {
    const count = option.getIn(['extra', 'count']);
    if (count) {
      return ` (${count})`;
    }
    return '';
  };

  getOptionsList = () => {
    const { options, empty, multiple } = this.props;

    if (empty && !multiple) {
      const emptyOption = this.createEmptyOption();
      return options.unshift(emptyOption);
    }

    return options;
  };

  combineOptionGroups = () => {
    const { options } = this.props;

    if (this.isOptionGroup()) {
      return options.toSeq().flatMap((optionGroup) => optionGroup.get('options'));
    }
    return options;
  };

  getMultiSelected = (selected) => {
    const { getValue } = this.props;

    getValue(selected);
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = () => {
    // this.closeDropdown();
  };

  handleChange = (e) => {
    const { getValue, native, multiple, autocomplete, gaEventSelect } = this.props;
    let value;
    if (autocomplete) {
      value = e.value;
    } else if (native) {
      value = e.target.value;
    } else {
      value = e.target.getAttribute('value');
    }

    if (native && multiple) {
      const selectedValues = Array.from(e.target.options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      getValue(
        this.combineOptionGroups()
          .filter((option) => selectedValues.includes(option.get('value')))
          .toList()
      );
    } else {
      let selectedOption = this.createEmptyOption();
      if (value !== '') {
        selectedOption = this.combineOptionGroups().find((opt) => opt.get('value') === value, selectedOption);
      }
      getValue(selectedOption);
    }

    this.closeDropdown();
    if (gaEventSelect) {
      this.gaEventHandler(value);
    }
  };

  handleClick = (e) => {
    if (this.selectRef.current.contains(e.target)) {
      this.openDropdown();
    } else {
      this.closeDropdown();
    }
  };

  handleKeyDown = () => {
    this.openDropdown();
  };

  handleClickOutside = (e) => {
    if (this.selectRef.current && !this.selectRef.current.contains(e.target)) {
      this.closeDropdown();
    }
  };

  handleOptionClick = (e) => {
    this.handleChange(e);
    this.closeDropdown();
  };

  handleKeyPress = (e) => {
    const { focused } = this.state;

    const spaceKey = 32;
    const enterKey = 13;

    if (focused && [spaceKey, enterKey].includes(e.charCode)) {
      this.setState({ opened: true });
    }
  };

  closeDropdown = () => {
    const { onDropdownClose } = this.props;
    this.setState({
      opened: false,
      focused: false,
    });

    if (onDropdownClose) onDropdownClose();
  };

  renderOptions = () => {
    const { multiple, native, selected, t, gaEvent } = this.props;

    // ADD EMPTY OPTION AS THE FIRST OPTION WHEN NEEDED
    const optionsList = this.getOptionsList();

    // MULTISELECT OPTIONS
    if (multiple && !native) {
      return (
        <Multiselect
          options={optionsList}
          selected={selected}
          translateOption={this.translateOption}
          getValue={this.getMultiSelected}
          gaEvent={gaEvent}
          t={t}
        />
      );
    }

    // NATIVE OPTIONS
    if (native) {
      return optionsList.map((option) => {
        if (option.get('options')) {
          return (
            <optgroup key={option.get('value')} label={this.translateOption(option)}>
              {option.get('options').map(this.renderOption)}
            </optgroup>
          );
        }
        return this.renderOption(option);
      });
    }

    // REGULAR OPTIONS
    return optionsList.map((option) => {
      if (option.get('options')) {
        return (
          <div key={option.get('value')}>
            <div className="opt-group__name">{this.translateOption(option)}</div>
            {option.get('options').map(this.renderOption)}
          </div>
        );
      }
      return this.renderOption(option);
    });
  };

  renderOption = (option) => {
    const { native, selected } = this.props;
    const { focused } = this.state;

    const value = option.get('value');

    const getSelected = () => {
      if (List.isList(selected)) {
        const val = selected.find((i) => i.get('value') === value);
        return !!val;
      }
      return selected.get('value') === value;
    };

    if (native) {
      return (
        <option key={value} value={value} selected={getSelected()}>
          {`${this.translateOption(option)}${this.renderCount(option)}`}
        </option>
      );
    }
    const OptionsStyle = classNames({
      select__option: true,
      'select__option--focused': focused,
      'select__option--selected': getSelected(),
    });

    return (
      <div
        role="button"
        tabIndex="0"
        key={option.get('value')}
        className={OptionsStyle}
        value={option.get('value')}
        onClick={this.handleOptionClick}
        onFocus={this.handleFocus}
        onChange={this.handleChange}
        onKeyPress={this.handleKeyPress}
      >
        {`${this.translateOption(option)}${this.renderCount(option)}`}
      </div>
    );
  };

  gaEventHandler = (label) => {
    const { gaEvent } = this.props;
    if (gaEvent && label) {
      gaEvent(camelCase(label));
    }
  };

  createEmptyOption() {
    const { empty, t } = this.props;
    let emptyName = empty;
    if (empty === true) {
      emptyName = t('select.pleaseSelect');
    } else if (empty === false) {
      emptyName = '';
    }
    return Map({ name: emptyName, value: '', extra: Map({ translated: true }) });
  }

  openDropdown() {
    const { onDropdownOpen } = this.props;
    this.setState({
      opened: true,
    });
    this.gaEventHandler('open_dropdown');

    if (onDropdownOpen) onDropdownOpen();
  }

  renderDropDown() {
    const { t, native, multiple, selected, disabled, browser, id } = this.props;
    const { opened } = this.state;
    // RENDER STYLED DROPDOWN
    if (!native) {
      return (
        <div className="dropdown-wrap">
          {opened ? <Overlay onClick={this.closeDropdown} /> : null}
          <Dropdown opened={opened} closeDropdown={this.closeDropdown}>
            {this.renderOptions()}
            {multiple && browser.lessThan.large && (
              <div className="dropdown__done">
                <Button outline text={t('common.done')} onClick={this.closeDropdown} />
              </div>
            )}
          </Dropdown>
        </div>
      );
    }

    // RENDER NATIVE DROPDOWN
    return (
      <select
        id={id}
        disabled={disabled}
        value={selected.get('value')}
        multiple={multiple}
        ref={this.nativeSelectRef}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onKeyPress={this.handleKeyPress}
        onClick={() => this.gaEventHandler('open_dropdown')}
      >
        {this.renderOptions()}
      </select>
    );
  }

  renderType() {
    const { notched, label, id } = this.props;
    const { focused } = this.state;

    if (notched) {
      return (
        <div className="notch__base">
          <div className="leading" />
          <div
            className={classNames('notch', {
              'label--floating': focused || this.getLabelFloating(),
            })}
          >
            <label htmlFor={id} ref={this.labelRef} className="select__label">
              {label}
            </label>
          </div>
          <div className="trailing" />
        </div>
      );
    }
    return <div className="select__bg" />;
  }

  renderValue = () => {
    const { multiple, maxSelectedVisible, showCount, selected, t } = this.props;

    if (multiple) {
      if (selected.isEmpty()) {
        return this.translateOption(this.createEmptyOption());
      }

      const { options, allSelectedText } = this.props;
      if (allSelectedText && selected.size === options.size) {
        return allSelectedText;
      }

      if (selected.size > maxSelectedVisible) {
        return `${selected.size} ${t('common.selected')}`;
      }
      return selected.map(this.translateOption).join(', ');
    }

    const count = showCount ? this.renderCount(selected) : '';

    if (!selected.size) {
      return this.translateOption(this.createEmptyOption()) + count;
    }

    return this.translateOption(selected) + count;
  };

  renderAutocomplete() {
    const { t, options, selected, disabled } = this.props;

    return (
      <AutocompleteSelect
        t={t}
        translateOption={this.translateOption}
        selected={selected}
        options={options}
        onChange={this.handleChange}
        disabled={disabled || options.size === 0}
      />
    );
  }

  render() {
    const { autocomplete, native, extended, raised, large, disabled, error, notched, blur, className, extraSmall } =
      this.props;
    const { focused, opened } = this.state;

    const SelectStyle = classNames(
      'select',
      {
        'select--large': large,
        'select--extra-small': extraSmall,
        'select--blur': blur,
        'select--raised': raised,
        'select--extended': extended,
        'select--native': native,
        'select--focused': focused,
        'select--disabled': disabled,
        'select--error': error,
        'select--opened': opened,
        'select--notched': notched,
        'select--floating': focused || this.getLabelFloating(),
      },
      className
    );

    return (
      <>
        <div className={SelectStyle} ref={this.selectRef}>
          {autocomplete ? (
            this.renderAutocomplete()
          ) : (
            <>
              {this.renderType()}
              <button
                type="button"
                disabled={disabled}
                className="select__value"
                tabIndex={native ? -1 : 0}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onKeyDown={this.handleKeyDown}
                onClick={this.handleClick}
              >
                {this.renderValue()}
              </button>

              {this.renderDropDown()}
              <Icon name="angle-down" className="select__arrow icon--fw" />
            </>
          )}
        </div>
        {error && <ErrorMessage text={error} />}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    browser: getBrowser(state),
  };
}

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default connect(mapStateToProps, null)(Select);
