import React from 'react';
import classNames from 'classnames';

interface Props {
  text?: string;
  m32?: boolean;
  className?: string;
}

function Separator({ text, m32, className }: Props) {
  const separatorStyle = classNames(
    'separator',
    {
      'separator--m32': m32,
    },
    className
  );
  return <div className={separatorStyle}>{text ? <span>{text}</span> : null}</div>;
}

export default Separator;
