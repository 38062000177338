import React, { useMemo } from 'react';
import classNames from 'classnames';
import { ListItem } from 'schema-dts';

import { identity } from '@city24/common/utils';
import { BreadcrumbProps } from './Breadcrumb';
import css from './Breadcrumbs.module.scss';

interface Props {
  children: React.ReactNode;
  minimal?: boolean;
  textStyle?: 'light' | 'contrast';
}

const Breadcrumbs = ({ children, minimal = false, textStyle }: Props): React.ReactElement => {
  const breadcrumbsStyle = classNames(
    css.breadcrumbs,
    {
      [css.minimal]: minimal,
    },
    textStyle ? css[textStyle] : null
  );

  const linkedData = useMemo(() => {
    return JSON.stringify(
      React.Children.map(children, (item, idx) => {
        const breadcrumb = item as React.ReactElement<BreadcrumbProps> | undefined;
        if (breadcrumb?.props) {
          const { to, name } = breadcrumb.props;
          return {
            '@type': 'ListItem',
            position: idx + 1,
            item: { '@id': `${process.env.NEXT_PUBLIC_ORIGIN_URL}${to}`, name },
          } as ListItem;
        }
      })?.filter(identity) as ListItem[]
    );
  }, []);

  return (
    <>
      <script key="structured-data" type="application/ld+json" dangerouslySetInnerHTML={{ __html: linkedData }} />
      <div className={breadcrumbsStyle}>{children}</div>
    </>
  );
};

export default Breadcrumbs;
