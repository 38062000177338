import React from 'react';
import css from './InputRangeSlider.module.scss';

interface Props {
  value: number;
  min: number;
  max: number;
  step: number;
  getValue: (val: string) => void;
  className?: string;
}

const InputRangeSlider = ({ value, min, max, step, getValue, className = '' }: Props): React.ReactElement => {
  return (
    <input
      type="range"
      name="val"
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={(e) => getValue(e.currentTarget.value)}
      className={`${css.range} ${className}`}
    />
  );
};

export default InputRangeSlider;
