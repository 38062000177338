import { createSelector } from 'reselect';
import { GET_A_PRO_FS_LINKS, GET_A_PRO_LINKS } from '@/constants/integrations';
import { RESULTS_VIEW } from '@/constants';
import { OBJECT_TYPES } from '@/constants/object';
import { getObjectDetails } from '@/components/object/objectDetailSelectors';
import { getLanguage } from '@/selectors/appSelectors';
import { getMapOpened, getResultLayout } from '@/components/search/searchSelectors';
import { GoogleAnalyticsParams } from '@/utils/ga';
import { getUser } from '@/selectors/userSelectors';

export const getGetAProVariant = createSelector(getLanguage, getObjectDetails, (language: string, object: any) => {
  const links = GET_A_PRO_LINKS[language] ?? null;
  if (!links || !links.length) {
    return false;
  }

  let index;
  if (object.get('unit_type') === OBJECT_TYPES.NewProject) {
    index = 2;
  } else {
    index = Math.floor(Math.random() * links.length);
  }
  return {
    link: links[index],
    text: `getaproLV.variants.${index}`,
    variant: index,
  };
});

export const getGetAProServices = createSelector(getLanguage, (language) => {
  return (GET_A_PRO_FS_LINKS && GET_A_PRO_FS_LINKS[language]) ?? null;
});

export const getGaParams = createSelector(getUser, getResultLayout, getMapOpened, (user, resultLayout, isMapOpened) => {
  return {
    user_properties: {
      is_authenticated: user.signedIn,
      is_broker: user.isBroker,
      search_view_type: isMapOpened ? RESULTS_VIEW.map : resultLayout?.get('value'),
    },
  } as GoogleAnalyticsParams;
});
