import React, { useContext } from 'react';
import type { TFunction } from 'next-i18next';
import { PartnerNewsArticle } from '@city24/common/types/integrations';

import ItemSlider from '@/components/itemSlider/ItemSlider';
import AdditionalService from './AdditionalService';
import ObjectDetailContext from '../ObjectDetailContext';

import css from './AdditionalServices.module.scss';

interface Props {
  slider?: boolean;
  services: PartnerNewsArticle[];
  t: TFunction;
}

function AdditionalServices({ slider, services, t }: Props) {
  const { gaEvent } = useContext(ObjectDetailContext);
  const renderService = (service: PartnerNewsArticle) => {
    const { url, image, description, body } = service;

    return (
      <AdditionalService
        key={description}
        title={description}
        subtitle={body}
        link={url}
        img={image}
        gaEvent={gaEvent}
      />
    );
  };

  return (
    <div className={css.container}>
      <div className="h3">{t('common.additionalServicesTitle')}</div>
      {slider ? (
        <ItemSlider>{services.map(renderService)}</ItemSlider>
      ) : (
        <ul className={css.list}>
          {services.map((i) => (
            <li key={i.description}>{renderService(i)}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default AdditionalServices;
