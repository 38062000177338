import React from 'react';
import type { TFunction } from 'next-i18next';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { getUserFullName } from '@/utils/contacts';
import { getUser } from '@/selectors/userSelectors';
import SuccessIcon from '@/img/success.svg';
import Image from '@/components/img/Image';

import { getNotifications } from './notificationSelectors';
import Notification from './Notification';
import { closeNotification } from './notificationReducer';
import { NotificationName } from './notificationInterfaces';
import { getBrowser } from '@/selectors/appSelectors';

interface Props {
  t: TFunction;
}

function BaseNotifications({ t }: Props): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const { open, name } = useAppSelector(getNotifications);
  const user = useAppSelector(getUser);
  const browser = useAppSelector(getBrowser);

  if (!open) {
    return null;
  }

  const icon = <Image src={SuccessIcon} alt="✓" />;
  const onComplete = () => dispatch(closeNotification());

  let text: string;
  let duration: number;
  if (name === NotificationName.CopyLink) {
    text = t('notification.linkCopied');
    duration = 2000;
  } else if (name === NotificationName.SignIn) {
    text = `${t('notification.signedIn')} ${getUserFullName(user)}`;
    duration = 3000;
  } else if (name === NotificationName.MyObjectSaved) {
    text = t('notification.myObjectSaved');
    duration = 2000;
  } else {
    return null;
  }

  return <Notification rawIcon={icon} text={text} duration={duration} onComplete={onComplete} browser={browser} />;
}

export default BaseNotifications;
