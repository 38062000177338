import { useAppSelector } from '@/hooks/index';
import { getGaParams } from '@/selectors/integrationsSelectors';
import { useEffect } from 'react';
import GA from '@/utils/ga';

/**
 * Watch for changes in parameters used for GA custom dimensions and update GA config on change.
 * This is test-comment to test push to repo
 */
function useGaCustomDefinitions() {
  const gaProps = useAppSelector(getGaParams);
  useEffect(() => GA.setUserProperties(gaProps.user_properties), [gaProps]);
}

export default useGaCustomDefinitions;
