import React, { useContext } from 'react';
import ReactGA from '@/utils/ga';
import { Map, Seq } from 'immutable';

import { formatPrice } from '@/utils/formatting';
import Select from '@/components/select/Select';
import Button from '@/components/button/Button';
import Icon from '@/components/icon/Icon';
import Tooltip from '@/components/tooltip/Tooltip';
import AppContext from '@/components/appContext';

type Props = {
  calcState: any;
  setCalcState: (field: string, value: any) => void;
  results: any;
  links: { button: string; link: string };
  bank: string;
  accentColor: string;
  amountOptions: Seq.Indexed<Map<string, string>>;
  periodOptions: Seq.Indexed<Map<string, string>>;
  logo: any;
};

const CompactLoanCalculator = ({
  calcState,
  setCalcState,
  results,
  links,
  bank,
  accentColor,
  logo,
  amountOptions,
  periodOptions,
}: Props): React.ReactElement => {
  const { useTranslation } = useContext(AppContext);
  const [t] = useTranslation();

  const tooltipId = 'payment-tooltip';
  return (
    <div className="sbcc">
      <div>
        <div className="sbcc__header">
          <div className="sbcc__title">
            {logo}
            <span>{t(`loanCalc.singleBankTitle.${bank}`)}</span>
          </div>
          <h2 className="sbcc__monthly-payment">
            {formatPrice(results.monthlyPayment)}
            <span className="sbcc__per-month">/{t('loanCalc.perMonth')}</span>{' '}
            <a role="tooltip" className="tooltip" data-tooltip-id={tooltipId}>
              <Icon name="question" />
            </a>
            <Tooltip
              id={tooltipId}
              className="sbcc__tooltip-content"
              html={
                t(`loanCalc.disclaimer.${bank}`, { href: links.link, interpolation: { escapeValue: false } }) as string
              }
            />
          </h2>
        </div>
        <div className="sbcc__body">
          <div className="sbcc__amount">
            <div className="sbcc__period-label">{t('loanCalc.amount')}</div>
            <Select
              native
              className="sbcc__period-select"
              extraSmall
              options={amountOptions}
              selected={calcState.amount}
              getValue={(val) => setCalcState('amount', val)}
              translateOption={(option) => t(option.get('name'), { count: parseInt(option.get('value'), 10) })}
              gaEvent={() =>
                ReactGA.event({
                  category: 'single_bank_calculator',
                  action: 'update_field_value',
                  label: 'amount',
                })
              }
            />
          </div>
          <div className="sbcc__period">
            <div className="sbcc__period-label">{t('luminor.calc.period')}</div>
            <Select
              native
              className="sbcc__period-select"
              extraSmall
              options={periodOptions}
              selected={calcState.period}
              getValue={(val) => setCalcState('period', val)}
              translateOption={(option) => t(option.get('name'), { count: parseInt(option.get('value'), 10) })}
              gaEvent={() =>
                ReactGA.event({
                  category: 'single_bank_calculator',
                  action: 'update_field_value',
                  label: 'period',
                })
              }
            />
          </div>
        </div>
      </div>
      <Button
        style={{ color: accentColor }}
        link
        text={t(`loanCalc.cta.${bank}`)}
        href={links.button}
        className="sbcc__cta"
        newTab
        onClick={() => {
          ReactGA.event({
            category: 'single_bank_calculator',
            action: 'button_click',
            label: bank,
          });
        }}
      />
    </div>
  );
};

export default CompactLoanCalculator;
