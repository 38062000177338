import { configureStore, Action, StoreEnhancer, ThunkAction } from '@reduxjs/toolkit';
import { createResponsiveStoreEnhancer } from 'redux-responsive';
import { ConnectedProps as ReduxConnectedProps } from 'react-redux';
import * as Sentry from '@sentry/nextjs';
import type { AppContext } from 'next/app';
import type { Context } from 'next-redux-wrapper';

import reducer from './reducers';
import Language from '@city24/common/enums/Language';
import { appInitialState } from './reducers/app';

const enhancers: StoreEnhancer[] = [createResponsiveStoreEnhancer({ calculateInitialState: false })];
if (Sentry.createReduxEnhancer) {
  enhancers.push(Sentry.createReduxEnhancer());
}

const makeStore = (context?: Context) => {
  const preloadedState = {} as Partial<NonNullable<Parameters<typeof reducer>[0]>>;

  const pageContext = (context as AppContext)?.ctx || context;
  const locale = pageContext?.locale;
  if (locale) {
    preloadedState.app = { ...appInitialState, locale: locale as Language };
  }
  return configureStore({
    preloadedState,
    reducer,
    enhancers,
    middleware(getDefaultMiddleware) {
      // serialization check disabled while ImmutableJS is still in use
      return getDefaultMiddleware({ serializableCheck: false });
    },
  });
};

export type RootStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<RootStore['getState']>;
export type AppDispatch = RootStore['dispatch'];
export type AppThunk<RType = any, ActionName = string> = ThunkAction<RType, RootState, unknown, Action<ActionName>>;
export type ConnectedProps<TConnector> = ReduxConnectedProps<TConnector> & { dispatch: AppDispatch };

export default makeStore;
