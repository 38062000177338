import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collection } from 'immutable';

import { TRANSACTION_TYPES_OPTIONS } from '@/constants/attributes';
import InlineSelect from '@/components/select/InlineSelect';

function TransactionType({ t, className, main, tabs, selected, onChange, gaCategory }) {
  return (
    <div className={className}>
      <InlineSelect
        large={main}
        raised={main}
        className={classNames({
          'search__transaction-type-tabs': tabs,
        })}
        tabs={tabs}
        t={t}
        extended
        options={TRANSACTION_TYPES_OPTIONS}
        selected={selected}
        getValue={onChange}
        gaCategory={gaCategory}
      />
    </div>
  );
}

TransactionType.propTypes = {
  main: PropTypes.bool,
  tabs: PropTypes.bool,
  selected: PropTypes.instanceOf(Collection.Keyed).isRequired,
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
TransactionType.defaultProps = {
  main: false,
  tabs: false,
};

export default TransactionType;
