import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultProps = {};

// const newArray = () => {
//   let items = [];
//   for (let i = 0; i < props.numTimes; i++) {
//     items.push(props.children(i));
//   }
//   return items.map(item => item);
// };

const InputRange = (props) => {
  const { children } = props;

  return (
    <div className="input-range">
      {children[0]}
      <div className="input-range__dash">-</div>
      {children[1]}
    </div>
  );
};

InputRange.propTypes = propTypes;
InputRange.defaultProps = defaultProps;

export default InputRange;
