import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  getValue: PropTypes.func.isRequired,
};

const defaultProps = {
  id: '',
  checked: false,
  value: null,
  name: '',
};

class Radio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };
  }

  onFocus = () => {
    this.setState({ focused: true });
  };

  onChange = () => {
    const { checked } = this.state;
    const { value, getValue } = this.props;

    getValue(!checked, value);
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  render() {
    const { id, value, name, checked } = this.props;
    const { focused } = this.state;

    const radioStyle = classNames({
      radio: true,
      'radio--focused': focused,
      'radio--checked': checked,
    });

    return (
      <div className={radioStyle}>
        <input
          id={id}
          type="radio"
          value={value}
          name={name}
          checked={checked}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        <div className="radio__bg" />
      </div>
    );
  }
}

Radio.propTypes = propTypes;
Radio.defaultProps = defaultProps;

export default Radio;
