import { apiFetch } from './basics';
import { authorizedFetch } from './user';

function getCounty(id, query, init) {
  return apiFetch(`/counties/${id}`, query, init);
}

function getCounties(query, init) {
  return apiFetch(`/regional_units/countries/${PORTAL}/counties`, query, init);
}
function getCountiesWithCount(query, init) {
  return apiFetch(`/locations/countries/${PORTAL}/counties`, query, init);
}

function getParish(id, query, init) {
  return apiFetch(`/parishes/${id}`, query, init);
}

function getParishesCities(county, query, init) {
  return apiFetch(`/regional_units/counties/${county}/parishes_cities`, query, init);
}

function getParishesWithCount(county, query, init) {
  return apiFetch(`/locations/counties/${county}/parishes`, query, init);
}

function getCity(id, query, init) {
  return apiFetch(`/cities/${id}`, query, init);
}

function getCitiesDistricts(parishCity, query, init) {
  return apiFetch(`/regional_units/parishes_cities/${parishCity}/cities_districts`, query, init);
}

function getCitiesWithCount(parish, query, init) {
  return apiFetch(`/locations/parishes/${parish}/cities`, query, init);
}

function getCitiesByCountyWithCount(county, query, init) {
  return apiFetch(`/locations/counties/${county}/cities`, query, init);
}

function getDistrictsWithCount(parentCity, query, init) {
  return apiFetch(`/locations/cities/${parentCity}/districts`, query, init);
}

function getDistrictsByParishWithCount(parish, query, init) {
  return apiFetch(`/locations/parishes/${parish}/districts`, query, init);
}

function getRegionalUnit(id, query, init) {
  return apiFetch(`/regional_units/${id}`, query, init);
}

function getPopularAreas(query = {}, init) {
  return apiFetch('/popular_locations', { ...query, portal: PORTAL }, init);
}

function doLocationsAutocomplete(query, init) {
  return apiFetch('/autocomplete/locations', query, init);
}

function geocodeAddress(query, init) {
  return authorizedFetch('/geocode', query, init);
}

function doFullAddressAutocomplete(query, init) {
  return apiFetch('/address_autocomplete', query, init);
}

export default {
  getCounty,
  getCounties,
  getCountiesWithCount,
  getParish,
  getParishesCities,
  getParishesWithCount,
  getCity,
  getCitiesDistricts,
  getCitiesWithCount,
  getCitiesByCountyWithCount,
  getDistrictsWithCount,
  getDistrictsByParishWithCount,
  getRegionalUnit,
  getPopularAreas,
  doLocationsAutocomplete,
  geocodeAddress,
  doFullAddressAutocomplete,
};
