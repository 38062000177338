import { fromJS, List, Map } from 'immutable';
import { combineReducers } from 'redux';
import produce from 'immer';

import {
  GET_BROKER_DETAILS,
  GET_BROKER_STATISTICS,
  GET_BROKER_OBJECTS,
  GET_BROKER_RECENT_OBJECTS,
  GET_BROKER_OFFICE,
} from '@/constants/actions';

const initialState = Map();

function brokerDetail(state = initialState, action) {
  switch (action.type) {
    case GET_BROKER_DETAILS:
      return state
        .merge(action.details)
        .update('business_card', (businessCard) => businessCard && fromJS(businessCard));
    default:
      return state;
  }
}

const objectsInitialState = { objects: List(), total: 0, transactionType: null, unitType: null, recentObjects: [] };

function brokerObjects(state = objectsInitialState, action) {
  if (action.type === GET_BROKER_OBJECTS) {
    return produce(state, (newState) => {
      newState.transactionType = action.transactionType;
      newState.unitType = action.unitType;
      newState.objects = action.objects.map((obj) => fromJS(obj));
      newState.total = action.total;
    });
  }
  if (action.type === GET_BROKER_RECENT_OBJECTS) {
    return produce(state, (newState) => {
      newState.recentObjects = action.objects;
    });
  }

  return state;
}

const statisticsInitialState = {
  id: 0,
  user: '',
  properties: {},
};

function brokerStatistics(state = statisticsInitialState, action) {
  switch (action.type) {
    case GET_BROKER_STATISTICS:
      return action.statistics;
    case GET_BROKER_OBJECTS:
      if (action.transactionType && action.unitType) {
        return produce(state, (newState) => {
          const { properties } = newState;
          properties[action.transactionType] = state.properties[action.transactionType] || {};
          properties[action.transactionType][action.unitType] = action.total;
        });
      }
      return state;
    default:
      return state;
  }
}

const officeInitialState = Map();

function brokerOffice(state = officeInitialState, action) {
  if (action.type === GET_BROKER_OFFICE) {
    return Map(action.office);
  }

  return state;
}

export default combineReducers({
  info: brokerDetail,
  objects: brokerObjects,
  statistics: brokerStatistics,
  office: brokerOffice,
});
