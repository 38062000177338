import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import ReactGA from '@/utils/ga';

import { GA_SEARCH } from '@/constants/ga';
import { PRICE_TYPE_OPTIONS } from '@/constants/filters';
import Input from '@/components/input/Input';
import InputRange from '@/components/input/InputRange';
import InlineSelect from '@/components/select/InlineSelect';

const propTypes = {
  minPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  priceType: PropTypes.instanceOf(Immutable.Map).isRequired,
  handleMinPrice: PropTypes.func,
  handleMaxPrice: PropTypes.func,
  handlePriceType: PropTypes.func,
};

const defaultProps = {
  minPrice: '',
  maxPrice: '',
  handleMinPrice: null,
  handleMaxPrice: null,
  handlePriceType: null,
};
const PriceRange = (props) => {
  const { minPrice, maxPrice, priceType, handleMinPrice, handleMaxPrice, handlePriceType, t } = props;

  return (
    <div className="filter__price-range">
      <div className="filter__heading">
        <h4 className="filter__title">{t('search.price')}</h4>
        <InlineSelect
          small
          minimal
          className="filter__price-type"
          options={PRICE_TYPE_OPTIONS}
          selected={priceType}
          getValue={handlePriceType}
          gaCategory={GA_SEARCH}
          gaAction="select_price"
          gaLabels={{
            eur: 'priceTotal',
            m2: 'priceM2',
          }}
        />
      </div>
      <InputRange>
        <Input
          notched
          label="min"
          value={minPrice}
          type="number"
          getValue={handleMinPrice}
          unit={priceType.get('name')}
          useLocaleString
          gaEvent={() =>
            ReactGA.event({
              category: GA_SEARCH,
              action: 'select_price',
              label: 'rangeMin',
            })
          }
        />
        <Input
          notched
          label="max"
          value={maxPrice}
          type="number"
          getValue={handleMaxPrice}
          unit={priceType.get('name')}
          useLocaleString
          gaEvent={() =>
            ReactGA.event({
              category: GA_SEARCH,
              action: 'select_price',
              label: 'rangeMax',
            })
          }
        />
      </InputRange>
    </div>
  );
};

PriceRange.propTypes = propTypes;
PriceRange.defaultProps = defaultProps;

export default PriceRange;
