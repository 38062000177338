import { LANGUAGES } from '@/constants';
import Language from '@city24/common/enums/Language';

export const getLangPrefixlessPath = (location: { pathname: string; search?: string; hash?: string }) => {
  let path = location.pathname;
  const langCheck = path.split('/')[1];
  if (LANGUAGES.includes(langCheck as Language)) {
    path = path.replace(`/${langCheck}`, '');
  }
  return `${path}${location.search}${location.hash}`;
};
