import {
  GET_BROKER_DETAILS,
  GET_BROKER_OBJECTS,
  GET_BROKER_STATISTICS,
  GET_BROKER_OFFICE,
  GET_BROKER_RECENT_OBJECTS,
} from '@/constants/actions';
import { OBJECT_TYPES, TRANSACTION_TYPES } from '@/constants/object';
import { COMPANY_OBJECTS_PER_PAGE } from '@/constants/companySearch';
import api, { apiFetch, paginatedQuery, parsePagination } from '@/api/City24Api';
import { omit } from '@/utils/object';

function setBrokerStatistics(statistics) {
  return {
    type: GET_BROKER_STATISTICS,
    statistics,
  };
}

function setBrokerOffice(office) {
  return {
    type: GET_BROKER_OFFICE,
    office,
  };
}

export function fetchBrokerStatistics(dispatch, iri) {
  return apiFetch(iri)
    .then((res) => (res.ok ? res.json() : {}))
    .then((statistics) => {
      const { properties } = statistics;
      if (Array.isArray(properties[TRANSACTION_TYPES.SALE])) {
        properties[TRANSACTION_TYPES.SALE] = {};
      }
      if (Array.isArray(properties[TRANSACTION_TYPES.RENT])) {
        properties[TRANSACTION_TYPES.RENT] = {};
      }
      return dispatch(setBrokerStatistics(statistics));
    });
}

function fetchBrokerOffice(dispatch, iri) {
  return apiFetch(iri, { g: ['simpleOfficeInfo'] })
    .then((res) => (res.ok ? res.json() : {}))
    .then((office) => {
      return dispatch(setBrokerOffice(office));
    });
}

export function fetchBrokerDetails(id) {
  return (dispatch) => {
    return api
      .getBrokerDetails(id)
      .then((res) => (res.ok ? res.json() : {}))
      .then((broker) => {
        dispatch({
          type: GET_BROKER_DETAILS,
          details: omit({ ...broker, statistics: {} }, 'office'),
        });

        const additionalQueries = [];
        const statsIRI = broker.statistics;
        if (statsIRI) {
          additionalQueries.push(fetchBrokerStatistics(dispatch, statsIRI));
        } else {
          dispatch(setBrokerStatistics({}));
        }
        const officeIRI = broker.office && broker.office.shift();
        if (officeIRI) {
          additionalQueries.push(fetchBrokerOffice(dispatch, officeIRI));
        } else {
          dispatch(setBrokerOffice({}));
        }

        return Promise.all(additionalQueries);
      });
  };

  // return {
  //   type: GET_BROKER_DETAILS,
  //   details: fromJS(Brokers[0])
  // };
}

// export function fetchBrokersRealties(itemsPerPage) {
//   return dispatch => {
//     return dispatch(fetchApartmentsForSale(itemsPerPage));
//   };
// }

function getBrokerObjectSearchAction(unitType) {
  if (unitType === OBJECT_TYPES.NewProject) {
    return [api.searchProjects, OBJECT_TYPES.NewProject];
  }
  if (unitType === OBJECT_TYPES.ModularHouse) {
    return [api.searchModularHouses, OBJECT_TYPES.ModularHouse];
  }
  if (unitType) {
    return [api.searchObjects, unitType];
  }
  return [api.searchAllObjects, 'realties'];
}

export function fetchBrokersRealties(id, transactionType, unitType = null, page) {
  return (dispatch) => {
    const [action] = getBrokerObjectSearchAction(unitType);
    const query = {
      tsType: transactionType,
      unitType: unitType || undefined,
      broker: id,
    };

    return action(paginatedQuery(COMPANY_OBJECTS_PER_PAGE, page, query))
      .then((res) => (res.ok ? Promise.all([res.json(), parsePagination(res)]) : [[], {}]))
      .then(([objects, pagination]) => {
        return dispatch({
          type: GET_BROKER_OBJECTS,
          transactionType,
          unitType,
          objects,
          total: pagination.total || 0,
        });
      });
  };
}

export function fetchBrokerRecentRealties(id, noItems = 2) {
  return (dispatch) => {
    const query = {
      order: { datePublished: 'desc' },
      broker: id,
    };

    return api
      .searchObjects(paginatedQuery(noItems, 0, query))
      .then((res) => (res.ok ? res.json() : {}))
      .then((objects) => {
        return dispatch({
          type: GET_BROKER_RECENT_OBJECTS,
          objects,
        });
      });
  };
}

export function saveBrokerRating(body) {
  return api.createBrokerRating(body).then((res) => res.ok);
}
