const getBreakpoints = (baseClass) => {
  const xs = `${baseClass}--xs`;
  const sm = `${baseClass}--sm`;
  const md = `${baseClass}--md`;
  const lg = `${baseClass}--lg`;
  const xl = `${baseClass}--xl`;

  const object = {
    [xs]: {
      maxWidth: 480,
    },
    [sm]: {
      minWidth: 481,
      maxWidth: 768,
    },
    [md]: {
      minWidth: 769,
      maxWidth: 980,
    },
    [lg]: {
      minWidth: 981,
      maxWidth: 1280,
    },
    [xl]: {
      minWidth: 1281,
    },
  };

  return object;
};

export const getBreakpointsMin = (baseClass) => {
  const smOnly = `${baseClass}--sm`;
  const sm = `${baseClass}--sm-up`;
  const md = `${baseClass}--md-up`;
  const lg = `${baseClass}--lg-up`;
  const xl = `${baseClass}--xl-up`;

  const object = {
    [smOnly]: {
      maxWidth: 480,
    },
    [sm]: {
      minWidth: 481,
    },
    [md]: {
      minWidth: 769,
    },
    [lg]: {
      minWidth: 981,
    },
    [xl]: {
      minWidth: 1281,
    },
  };

  return object;
};

// export const getBreakpointsMinNew = (width, baseClass) => {
//   const sm = `${baseClass}--sm-up`;
//   const md = `${baseClass}--md-up`;
//   const lg = `${baseClass}--lg-up`;
//   const xl = `${baseClass}--xl-up`;

//   const classes = [];

//   if (width > 481) {
//     classes.push(sm);
//   }
//   if (width > 769) {
//     classes.push(md);
//   }
//   if (width > 981) {
//     classes.push(lg);
//   }
//   if (width > 1281) {
//     classes.push(xl);
//   }

//   return classes;
// };

export default getBreakpoints;
