import React from 'react';
import ReactGA from '@/utils/ga';
import { TFunction } from 'next-i18next';

import { LINKS } from '@/constants/routes';
import Icon from '@/components/icon/Icon';
import Anchor from '@/components/link/Anchor';

interface Props {
  t: TFunction;
  className?: string;
  gaCategory?: string;
}

const SocialMediaLinks = ({ t, className = '', gaCategory }: Props): React.ReactElement => {
  const gaEvent = (label: string) => {
    if (gaCategory) {
      ReactGA.event({
        category: gaCategory,
        action: 'click_socialMedia',
        label,
      });
    }
  };

  const renderLink = (name: keyof typeof LINKS) => {
    return (
      <li>
        <Anchor onClick={() => gaEvent(name)} href={LINKS[name]}>
          <Icon name={name} />
        </Anchor>
      </li>
    );
  };

  return (
    <div className={`social-media-links ${className}`}>
      <div className="h5 social-media-links__title">{t('socialMediaLinks.title')}</div>
      <ul className="social-media-links__links">
        {renderLink('facebook')}
        {PORTAL_EE && renderLink('instagram')}
      </ul>
    </div>
  );
};

export default SocialMediaLinks;
