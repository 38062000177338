import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

const defaultProps = {};

const ErrorMessage = (props) => {
  const { text } = props;

  return <div className="error-msg">{text}</div>;
};

ErrorMessage.propTypes = propTypes;
ErrorMessage.defaultProps = defaultProps;

export default ErrorMessage;
