import React, { useContext } from 'react';
import classNames from 'classnames';

import AppContext from '@/components/appContext';
import { TabsContext } from './Tabs';

interface Props {
  children: React.ReactNode;
  className?: string;
  value: string;
  to?: string;
  done?: boolean;
  gaEvent?: () => void;
}

function Tab({ children, className, value, to, done, gaEvent }: Props) {
  const { Link } = useContext(AppContext);
  const { selectTab, progress, getSelectedTabRef, disabled, selected } = useContext(TabsContext);

  const isSelected = selected === value;

  const tabRefCallback = (el: HTMLElement | null) => {
    if (el && isSelected) {
      getSelectedTabRef(el);
    }
  };

  const onClick = () => {
    if (selectTab) selectTab(value);
    if (gaEvent) gaEvent();
  };

  const tabStyle = classNames(
    'tabs__tab',
    {
      'tabs__tab--selected': isSelected,
      'tabs__tab--done': done,
      'tabs__tab--disabled': disabled || (progress && !done && !isSelected),
    },
    className
  );

  if (to) {
    const progressSVGBefore = progress ? (
      <svg className="progress-bar-before" height="100%" viewBox="0 0 9 48" fill="none">
        <path d="M8 0H0L8 24L0 48H8H9V0H8Z" fill="#281C37" preserveAspectRatio="none" />
      </svg>
    ) : null;

    const progressSVGAfter = progress ? (
      <svg className="progress-bar-after" height="100%" viewBox="0 0 9 48" fill="none">
        <path d="M1 0H0V48H1L9 24L1 0Z" fill="#281C37" preserveAspectRatio="none" />
      </svg>
    ) : null;
    return (
      <Link ref={tabRefCallback} className={tabStyle} href={to} onClick={onClick}>
        {progressSVGBefore}
        <span>{children}</span>
        {progressSVGAfter}
      </Link>
    );
  }
  return (
    <button ref={tabRefCallback} type="button" className={tabStyle} onClick={onClick} value={value}>
      <span>{children}</span>
    </button>
  );
}

export default Tab;
