export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateUrl(url: string): boolean {
  const re =
    /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/;
  return re.test(String(url).toLocaleLowerCase());
}

export function validateCadasterNr(cadasterNr: string): boolean {
  const re = PORTAL_LV ? /^\d+$/ : /^([\d]{5}):([\d]{3}):([\d]{4})$/;
  const nrLength = PORTAL_LV ? 11 : 14;
  return re.test(String(cadasterNr)) && cadasterNr.length === nrLength;
}
