import React from 'react';
import type { TFunction } from 'next-i18next';
import BusinessCard from '@/components/BusinessCard/BusinessCard';
import {
  getObjectDetails,
  getObjectDetailsBroker,
  getObjectDetailsOffice,
} from '@/components/object/objectDetailSelectors';
import { getModalState, getPrefixedRoutes, isModalOpen } from '@/selectors/appSelectors';
import Modal from '../Modal';
import { useAppDispatch, useAppSelector } from '@/hooks';

import css from './BusinessCardModal.module.scss';
import { ModalName } from '../modalInterfaces';
import { closeModal } from '../modalReducer';

function BusinessCardModal({ t }: { t: TFunction }) {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(getModalState);
  const office = useAppSelector(getObjectDetailsOffice);
  const broker = useAppSelector(getObjectDetailsBroker);
  const routes = useAppSelector(getPrefixedRoutes);
  const object = useAppSelector(getObjectDetails);

  const closeThisModal = () => dispatch(closeModal());
  return (
    <Modal
      fullScreen
      fromBottom
      isOpen={isModalOpen(modalState, ModalName.BusinessCard)}
      onRequestClose={closeThisModal}
      className={css.modal}
    >
      <h3>{t('broker.sendEmail')}</h3>
      <BusinessCard
        t={t}
        type={office ? 'company' : 'private'}
        broker={broker}
        office={office}
        routes={routes}
        showNr
        object={object}
        contactOnly
        onLinkClick={closeThisModal}
      />
    </Modal>
  );
}

export default BusinessCardModal;
