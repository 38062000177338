import config, { getLocale, changeLocale } from './config';
import { TRANSACTION_TYPES, OBJECT_TYPES } from '@/constants/object';
import { apiFetch } from './basics';
import {
  persistUuid,
  getUuid,
  getAnonToken,
  getToken,
  getRefreshToken,
  removeToken,
  removeRefreshToken,
} from '../auth';

import address from './address';
import realty from './realty';
import user, { authorizedFetch } from './user';
import publishing from './publishing';
import news from './news';
import { isOrIncludes } from '@/utils/helpers';
import { generateAnonToken, uuidv4 } from '@/utils/auth';
import auth from '@city24/common/api/city24/auth';

if (typeof window !== 'undefined') {
  const token = getToken() || null;
  // Move storage-based tokens to cookie-based for SSR
  if (token && !auth.getToken('token')) {
    auth.setToken('token', token);
    removeToken();
  }
  const refreshToken = getRefreshToken() || null;
  if (!auth.getToken('refreshToken')) {
    auth.setToken('refreshToken', refreshToken);
    removeRefreshToken();
  }
  if (!auth.getToken('anonToken')) {
    auth.setToken('anonToken', getAnonToken() || generateAnonToken());
  }

  // Fix token caching issue as described in CNP-3097
  // Regenerate all tokens older than 2023-01-20 (release of removal of setting tokens from API), using Unix time encoded into the token
  if (auth.getToken('anonToken') < '1674172800') {
    auth.setToken('anonToken', generateAnonToken());
  }
  if (!getUuid()) {
    persistUuid(uuidv4());
  }
}

export * from './basics';

export function convertIRI(iri) {
  if (iri) {
    let match = iri.match(/^\/transaction_types\/([12])$/);
    if (match) {
      switch (match[1]) {
        case '2':
          return TRANSACTION_TYPES.RENT;
        case '1':
        default:
          return TRANSACTION_TYPES.SALE;
      }
    }
    match = iri.match(/^\/(counties|parishes|cities|regional_units)\/([0-9]+)$/);
    if (match) {
      return parseInt(match[2], 10);
    }
  }

  return iri;
}

function getObjectTypeRouteName(objectType) {
  if (isOrIncludes(objectType, OBJECT_TYPES.NewProject)) {
    return 'projects';
  }
  if (isOrIncludes(objectType, OBJECT_TYPES.ModularHouse)) {
    return 'modular_houses';
  }
  if (isOrIncludes(objectType, 'broker')) {
    return 'broker';
  }
  return 'realties';
}

function searchAllObjects(query, init) {
  return apiFetch(`/${config.locale}/search/all_realties`, query, init);
}

function searchObjects(query, init) {
  return apiFetch(`/${config.locale}/search/realties`, query, init);
}

function searchProjects(query, init) {
  return apiFetch(`/${config.locale}/search/projects`, query, init);
}

function searchModularHouses(query, init) {
  return apiFetch(`/${config.locale}/search/modular_houses`, query, init);
}

function countSearchResults(query, init) {
  return apiFetch(`/${config.locale}/search/count`, query, init);
}

function doAutocompleteSearch(query, init) {
  return apiFetch(`/${config.locale}/search/autocomplete`, query, init);
}

function searchBrokers(query, init) {
  return apiFetch('/search/brokers', query, init);
}

function searchCompanies(query, init) {
  return apiFetch('/search/companies', query, init);
}

export function getSearchAction(query) {
  if (isOrIncludes(query.unitType, OBJECT_TYPES.NewProject)) {
    return [searchProjects, OBJECT_TYPES.NewProject];
  }
  if (isOrIncludes(query.unitType, OBJECT_TYPES.ModularHouse)) {
    return [searchModularHouses, OBJECT_TYPES.ModularHouse];
  }
  return [searchObjects, 'realties'];
}

function getMainCampaignsInfo(query, init) {
  return apiFetch('/main_campaigns', query, init);
}

function getCampaignOffers(type, query, init) {
  return apiFetch(`/${config.locale}/offers/${type}`, query, init);
}

function getRealtiesOffers(query = {}, init) {
  return apiFetch('/realties/offers', query, init);
}

function getSpecialOffers(query = {}, init) {
  return apiFetch('/offers/special', query, init);
}

function getFromOwnerOffers(query = {}, init) {
  return apiFetch('/realties/offers/from_owner', query, init);
}

/* eslint-disable no-param-reassign */
function applyDefaults(query) {
  if (query.short) {
    delete query.short;
    query.g = ['listingShort', 'addressNamesOnly'];
  }
  if (query.friendlyId !== undefined) {
    if (!query.friendlyId) {
      delete query.friendlyId;
    }
  } else if (query.guid !== undefined) {
    if (!query.guid) {
      delete query.guid;
    }
  } else {
    query.friendlyId = true;
  }

  return query;
}
/* eslint-enable no-param-reassign */

function getObject(id, query = {}, init) {
  return apiFetch(`/realties/${id}`, applyDefaults(query), init);
}

function getSimilarObjects(id, query = {}, init) {
  return apiFetch(`/realties/${id}/similar`, applyDefaults(query), init);
}

function getProject(id, query = {}, init) {
  return apiFetch(`/projects/${id}`, applyDefaults(query), init);
}

function getProjectRealties(id, query = {}, init) {
  return apiFetch(`/projects/${id}/realties`, applyDefaults(query), init);
}

function getModularHouse(id, query = {}, init) {
  return apiFetch(`/modular_houses/${id}`, applyDefaults(query), init);
}

function searchMapObjects(objectType, query, init) {
  return apiFetch(`/${config.locale}/search/map/${getObjectTypeRouteName(objectType)}`, query, init);
}

function getTranslations(locale, domain, query, init) {
  // Allow locale allow fetching more than the currently set locale (e.g. fallback locale)
  return apiFetch(`/language/${locale}/${domain}/tokens`, query, init);
}

function getObjectsByPlacement(placement, query = {}, init) {
  return apiFetch('/placements', { ...query, placementType: placement }, init);
}

function searchNearbyPlaces(query, init) {
  return apiFetch(
    '/nearby_places',
    {
      ...query,
      pagination: false,
      isActive: true,
    },
    init
  );
}

// User-related actions
function getFavouriteCount(query, init = {}) {
  return authorizedFetch('/user_favourites/count', query, {
    ...init,
    method: 'HEAD',
  });
}

// Misc actions

function saveStatistics(body, query, init = {}) {
  return apiFetch(`/unit_counters`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function saveItemsCounterStatistics(body, query, init = {}) {
  return apiFetch(`/items_counters`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function reportAd(objectType, id, body, query, init = {}) {
  return apiFetch(`/${getObjectTypeRouteName(objectType)}/${id}/report`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function sendContactRequest(objectType, id, body, query, init = {}) {
  return apiFetch(`/${getObjectTypeRouteName(objectType)}/${id}/contact_request`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function sendBrokerRequest(body, query, init) {
  return apiFetch('/user_requests', query, {
    ...init,
    method: 'POST',
    body,
  });
}

function createBrokerRating(body, query, init) {
  return apiFetch('/broker_ratings', query, {
    ...init,
    method: 'POST',
    body,
  });
}

function getPartnersNewsArticles(query, init, locale = null) {
  return apiFetch(`/${locale ?? config.locale}/partners_news_feed`, query, init);
}

function getEuriborRate() {
  return apiFetch(`/euribor`, {}, {});
}

function getOfficeDetails(id, query, init) {
  return apiFetch(`/offices/${id}`, query, init);
}

function getBrokerDetails(id, query, init) {
  return apiFetch(`/broker/${id}`, query, init);
}

function sendLuminorLoanApplication(body, query, init = {}) {
  return apiFetch(`/${config.locale}/luminor_loan_applications`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function sendLuminorLoanFeedback(body, query, init = {}) {
  return apiFetch(`/${config.locale}/luminor_loan_applications/feedback`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function getCenuBankaData(location, init) {
  return apiFetch(`/cenu_banka`, { location }, init);
}

function getStarOfTheYearBrokers(query, init) {
  return apiFetch('/star_of_the_years', query, init);
}

export default {
  getLocale,
  changeLocale,
  searchAllObjects,
  searchObjects,
  searchProjects,
  searchModularHouses,
  countSearchResults,
  doAutocompleteSearch,
  searchBrokers,
  searchCompanies,
  getMainCampaignsInfo,
  getCampaignOffers,
  getRealtiesOffers,
  getSpecialOffers,
  getFromOwnerOffers,
  getObjectsByPlacement,
  getObject,
  getProject,
  getProjectRealties,
  getModularHouse,
  getSimilarObjects,
  searchMapObjects,
  getTranslations,
  searchNearbyPlaces,
  saveStatistics,
  saveItemsCounterStatistics,
  getFavouriteCount,
  getOfficeDetails,
  getBrokerDetails,
  sendLuminorLoanApplication,
  sendLuminorLoanFeedback,
  address,
  user,
  reportAd,
  sendContactRequest,
  sendBrokerRequest,
  createBrokerRating,
  getPartnersNewsArticles,
  getEuriborRate,
  realty,
  publishing,
  news,
  getCenuBankaData,
  getStarOfTheYearBrokers,
};
