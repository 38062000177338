import React, { useContext } from 'react';

import Icon from '@/components/icon/Icon';
import LegacyToNextLink from '@/components/link/LegacyToNextLink';
import AppContext from '@/components/appContext';

interface ItemProps {
  to?: string;
  children?: React.ReactNode;
  icon?: string;
  onClick?: React.MouseEventHandler;
  renderLink?: (className: string, children: React.ReactNode) => React.ReactNode;
  isNextJSPage?: boolean;
}

function ActionListItem({ to, children, icon, onClick, renderLink, isNextJSPage }: ItemProps) {
  const { Link } = useContext(AppContext);
  const renderAction = (
    <>
      {icon && <Icon className="action-list__icon" name={icon} />}
      <span>{children}</span>
    </>
  );

  const renderChildren = () => {
    if (renderLink) {
      return renderLink('action-list__action', renderAction);
    }

    if (to) {
      if (isNextJSPage) {
        return (
          <LegacyToNextLink href={to} className="action-list__action">
            {renderAction}
          </LegacyToNextLink>
        );
      }
      return (
        <Link href={to} className="action-list__action">
          {renderAction}
        </Link>
      );
    }

    return to ? (
      <Link href={to} className="action-list__action">
        {renderAction}
      </Link>
    ) : (
      <button type="button" onClick={onClick} className="action-list__action">
        {renderAction}
      </button>
    );
  };
  return <li>{renderChildren()}</li>;
}

interface Props {
  children: React.ReactElement<ItemProps>[] | React.ReactNode;
}

function ActionList({ children }: Props) {
  return <ul className="action-list">{children}</ul>;
}
ActionList.Item = ActionListItem;

export default ActionList;
