import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  dark: PropTypes.bool,
};

const defaultProps = {
  dark: false,
};

const Overlay = (props) => {
  const { dark, onClick } = props;

  const overlayStyle = classNames({
    overlay: true,
    'overlay--dark': dark,
  });
  return <div className={overlayStyle} onClick={onClick} />;
};

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;

export default Overlay;
