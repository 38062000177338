import React, { useContext } from 'react';
import classNames from 'classnames';

import Icon from '@/components/icon/Icon';
import AppContext from '@/components/appContext';
import Anchor from '@/components/link/Anchor';

interface Props {
  children?: React.ReactNode;
  icon?: string;
  link?: { to: string } | { href: string };
}

function ListItem({ children, icon, link }: Props) {
  const { Link } = useContext(AppContext);
  const wrapContent = (content: React.ReactNode) => {
    if (!link) {
      return content;
    }

    if ('to' in link) {
      const { to, ...restLink } = link;
      return (
        <Link href={link.to} {...restLink}>
          {content}
        </Link>
      );
    }

    if ('href' in link) {
      return <Anchor {...link}>{content}</Anchor>;
    }

    return content;
  };

  const styles = classNames('list__item', {
    'list__item--icon': icon,
  });
  return (
    <li className={styles}>
      {wrapContent(
        icon ? (
          <>
            <Icon name={icon} className="list__icon" />
            {children}
          </>
        ) : (
          children
        )
      )}
    </li>
  );
}

export default ListItem;
