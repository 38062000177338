import React, { useState } from 'react';
import ReactGA from '@/utils/ga';
import type { TFunction } from 'next-i18next';

import { useAppDispatch, useAppSelector, useModalState } from '@/hooks';
import { hasPasswordResetError } from '@/selectors/userSelectors';
import { GA_PASSWORD_RESET } from '@/constants/ga';

import Button from '@/components/button/NextButton';
import Input from '@/components/input/Input';
import ButtonGroup from '@/components/button/ButtonGroup';
import { getPrefixedRoutes } from '@/selectors/appSelectors';
import SuccessIcon from '@/img/icons/success.svg';

import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import FancySection from '../ModalSections/FancySection';
import { resetPassword } from './PasswordResetModalActions';
import { ConfirmModalState, ModalName } from '../modalInterfaces';
import { closeModal } from '../modalReducer';

interface Props {
  t: TFunction;
}

function PasswordResetModal({ t }: Props) {
  const dispatch = useAppDispatch();
  const returnPath = useAppSelector(getPrefixedRoutes).passwordReset;
  const hasError = useAppSelector(hasPasswordResetError);
  const modal = useModalState<ConfirmModalState>(ModalName.PasswordReset);
  const { confirmed } = modal.state;
  const [email, changeEmail] = useState('');

  const closeResetModal = () => {
    dispatch(closeModal());
    ReactGA.event({
      category: GA_PASSWORD_RESET,
      action: 'close_button',
    });
  };

  const renderContent = () => {
    if (confirmed) {
      return (
        <>
          <FancySection
            image={SuccessIcon}
            title={t('passwordResetConfirmModal.title')}
            subtitle={t('passwordResetConfirmModal.subtitle')}
          />
          <Button btnType="primary" extended onClick={closeResetModal}>
            {t('common.close')}
          </Button>
        </>
      );
    }
    return (
      <>
        <ModalHeader title={t('passwordResetModal.title')} />
        <div>
          <Input
            type="email"
            notched
            label={t('common.yourEmail')}
            value={email}
            getValue={changeEmail}
            gaEvent={() =>
              ReactGA.event({
                category: GA_PASSWORD_RESET,
                action: 'insert_email',
              })
            }
            error={hasError && t('passwordReset.error.invalidUser')}
          />
        </div>
        <ModalFooter>
          <ButtonGroup>
            <Button
              bgColor="transparent"
              onClick={() => {
                closeResetModal();
                ReactGA.event({
                  category: GA_PASSWORD_RESET,
                  action: 'close_passwordReset_button',
                });
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button
              btnType="primary"
              onClick={() => {
                dispatch(resetPassword(email, returnPath.replace(':hash', '')));
                ReactGA.event({
                  category: GA_PASSWORD_RESET,
                  action: 'reset_password_button',
                });
              }}
            >
              {t('common.resetPassword')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal
      isOpen={modal.open}
      onRequestClose={closeResetModal}
      contentLabel={confirmed ? t('passwordResetConfirmModal.title') : t('passwordResetModal.title')}
    >
      {renderContent()}
    </Modal>
  );
}

export default PasswordResetModal;
