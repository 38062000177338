import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from '@/utils/ga';
import { generatePath, Link } from 'react-router-dom';
import type { TFunction } from 'react-i18next';

import { useAppSelector } from '@/hooks';
import { LOAN_APPLICATION_TYPE } from '@/constants/finance';
import { formatPrice } from '@/utils/formatting';
import { getPrefixedRoutes } from '@/selectors/appSelectors';
import { getLuminorLinks } from '@/selectors/calculatorsSelectors';

import Anchor from '@/components/link/Anchor';
import LuminorTheme from '../LuminorTheme/LuminorTheme';
import { defaultLuminorMonthly } from '../Calculators/loanCalculation';

type Props = {
  price: number;
  id: string;
  t: TFunction;
};

function LuminorHomeLoanLink({ price, id, t }: Props): React.ReactElement {
  const { askOfferDetailsLink } = useAppSelector(getLuminorLinks);
  //   const routes = useAppSelector(getPrefixedRoutes);

  const onClick = () => {
    ReactGA.event({
      category: 'luminorMonthlyPaymentHome_objFullDesc',
      action: 'click_getLoanOffer_url',
    });
  };
  return (
    <LuminorTheme>
      <div>{`${formatPrice(Math.round(defaultLuminorMonthly(price)))}/${t('loanCalc.perMonth')}`}</div>
      <Anchor className="luminor-theme__link" href={`${askOfferDetailsLink}&utm_term=text_link`} onClick={onClick}>
        {t('luminor.homeLoanCalc.cta')}
      </Anchor>
      {/* <Link
        className="luminor-theme__link"
        to={generatePath(routes.loanApplication, {
          type: LOAN_APPLICATION_TYPE.homeLoan,
          id,
        })}
        onClick={onClick}
      >
        {t('luminor.homeLoanCalc.cta')}
      </Link> */}
    </LuminorTheme>
  );
}

LuminorHomeLoanLink.propTypes = {
  price: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
// LuminorHomeLoanLink.defaultProps = {};

export default LuminorHomeLoanLink;
