import React, { useRef } from 'react';

import { useAppDispatch } from '@/hooks';

import css from './CopyURL.module.scss';
import { openNotification } from '@/components/notifications/notificationReducer';
import { NotificationName } from '@/components/notifications/notificationInterfaces';

interface Props {
  url?: string;
  children: React.ReactNode;
  onClick?: () => void;
  gaEvent?: (event: string) => void;
}

function CopyURL({ url, children, onClick, gaEvent }: Props) {
  const dispatch = useAppDispatch();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const legacyCopyToClipboard: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
    const el = textAreaRef.current;
    if (!el || !document.queryCommandSupported('copy')) {
      return;
    }
    const range = document.createRange();

    range.selectNodeContents(el);

    let s = window.getSelection();
    if (!s) {
      return;
    }
    s.removeAllRanges();
    s.addRange(range);

    el.select();
    el.setSelectionRange(0, 999999); // select for iphone

    document.execCommand('copy');
    e.currentTarget.focus();
    dispatch(openNotification(NotificationName.CopyLink));
  };

  const copyToClipboard: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url ? url : document.URL)
        .then(() => {
          dispatch(openNotification(NotificationName.CopyLink));
        })
        .catch(() => {
          legacyCopyToClipboard(e);
        });
    } else {
      legacyCopyToClipboard(e);
    }
  };

  if (typeof window === 'undefined' || !(document.queryCommandSupported('copy') || navigator.clipboard)) {
    return null;
  }

  return (
    <div className={css.copyURL}>
      <button
        type="button"
        onClick={(event) => {
          copyToClipboard(event);
          if (gaEvent) {
            gaEvent('copy_link');
          }
          if (onClick) {
            onClick();
          }
        }}
      >
        {children}
      </button>
      <textarea readOnly ref={textAreaRef} value={url ? url : document.URL} />
    </div>
  );
}

export default CopyURL;
