import { Collection, isImmutable } from 'immutable';
import { CLASSIFIED_PRODUCT_LEVEL } from '@/constants/office';

export type ClassifiedProduct = {
  product_level: string;
  is_big_np_branded_block_enabled: boolean;
  np_friendly_id: string;
};
export type Office = {
  [key: string]: any;
  company: { classified_products: [ClassifiedProduct] };
  classified_products: [ClassifiedProduct];
};
type OfficeWrapper = Collection.Keyed<string, any> | Office;

export function getProductLevels(office: OfficeWrapper | undefined): Set<string> {
  const productLevels = new Set<string>();
  if (office) {
    const officeJs = (isImmutable(office) ? office.toJS() : office) as Office;

    if (officeJs.classified_products) {
      officeJs.classified_products.forEach((a: ClassifiedProduct) => productLevels.add(a.product_level));
    }
    if (officeJs.company && officeJs.company.classified_products) {
      officeJs.company.classified_products.forEach((a: ClassifiedProduct) => productLevels.add(a.product_level));
    }
  }
  return productLevels;
}

export function hasBrandedLevels(productLevels: Set<string>): boolean {
  return productLevels.has(CLASSIFIED_PRODUCT_LEVEL.GOLD) || productLevels.has(CLASSIFIED_PRODUCT_LEVEL.PRO);
}
