import React from 'react';
import Image, { ImageProps } from '@/components/img/Image';

interface Props {
  imageUrl: ImageProps['src'];
  placeholderUrl?: ImageProps['placeholder'];
  altText?: string;
  className?: string;
}

function Avatar({ imageUrl, placeholderUrl, altText, className }: Props) {
  return <Image src={imageUrl} placeholder={placeholderUrl} alt={altText} className={className} />;
}

export default Avatar;
