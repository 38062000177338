import React from 'react';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';

import { formatPhone } from '@/utils/formatting';
import { AGENT_SHOW_PHONE } from '@/constants/statistics';

class ContactPhone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  showNumber = (e) => {
    const { sendStatistics } = this.props;
    e.preventDefault();
    this.setState({ show: true });
    if (sendStatistics) {
      sendStatistics(AGENT_SHOW_PHONE);
    }
  };

  renderNumbers() {
    const { t, phone, single, disabled, gaEvent, showNr } = this.props;
    const { show } = this.state;

    if (phone.size || phone.length) {
      const renderNr = (nr, index = 0) => {
        const nrString = showNr || show ? formatPhone(nr) : `${formatPhone(nr).slice(0, -4)} ****`;
        const showBtn = index < 1 && !showNr && !show && (
          <button type="button" onClick={this.showNumber}>
            {t('common.showNr')}
          </button>
        );
        return disabled ? (
          <div key={nr}>
            {nrString}
            {showBtn}
          </div>
        ) : (
          <a
            key={nr}
            href={showNr || show ? `tel:${nr}` : '#'}
            onClick={(event) => {
              if (!show) {
                this.showNumber(event);
              }
              if (gaEvent) {
                gaEvent('call_number');
              }
            }}
          >
            <span>{nrString}</span>
            {showBtn}
          </a>
        );
      };

      if (phone.map) {
        if (single) {
          return renderNr(Array.isArray(phone) ? phone[0] : phone.first());
        }
        return phone.map(renderNr);
      }

      return renderNr(phone);
    }
    return null;
  }

  render() {
    return <div className="contact-phone">{this.renderNumbers()}</div>;
  }
}

ContactPhone.propTypes = {
  phone: PropTypes.oneOfType([
    PropTypes.instanceOf(Collection.Indexed),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  showNr: PropTypes.bool,
  single: PropTypes.bool,
  disabled: PropTypes.bool,
  gaEvent: PropTypes.func,
};
ContactPhone.defaultProps = {
  showNr: false,
  single: false,
  disabled: false,
  gaEvent: null,
};

export default ContactPhone;
