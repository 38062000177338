import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  rating: PropTypes.string.isRequired,
  showNr: PropTypes.bool,
};

const defaultProps = {
  showNr: false,
};

function Rating({ rating, showNr, className = '' }) {
  const ratingFill = 100 - parseFloat(rating) * 10 * 2;
  const ratingStyle = classNames('rating', className);

  return (
    <div className={ratingStyle}>
      <div className="rating__stars">
        <svg x="0px" y="0px" width="100" height="20" viewBox="0 0 160 32" className="rating__bg">
          <polygon
            className="rating__star"
            points="15.2,1.5 19.5,11.8 30.4,12.6 22.1,19.7 24.7,30.5 15.2,24.7
	5.8,30.5 8.4,19.7 0,12.6 11,11.8 "
          />
          <polygon
            className="rating__star"
            points="47.6,1.5 51.8,11.8 62.8,12.6 54.4,19.7 57,30.5 47.6,24.7
	38.2,30.5 40.8,19.7 32.4,12.6 43.4,11.8 "
          />
          <polygon
            className="rating__star"
            points="80,1.5 84.2,11.8 95.2,12.6 86.8,19.7 89.4,30.5 80,24.7
	70.6,30.5 73.2,19.7 64.8,12.6 75.8,11.8 "
          />
          <polygon
            className="rating__star"
            points="112.4,1.5 116.6,11.8 127.6,12.6 119.2,19.7 121.8,30.5
	112.4,24.7 103,30.5 105.6,19.7 97.2,12.6 108.2,11.8 "
          />
          <polygon
            className="rating__star"
            points="144.8,1.5 149,11.8 160,12.6 151.6,19.7 154.2,30.5 144.8,24.7
	135.3,30.5 137.9,19.7 129.6,12.6 140.5,11.8 "
          />
        </svg>
        <svg
          x="0px"
          y="0px"
          width="100"
          height="20"
          viewBox="0 0 160 32"
          className="rating__fill"
          style={{ clipPath: `inset(0 ${ratingFill}% 0 0)` }}
        >
          <polygon
            className="rating__star"
            points="15.2,1.5 19.5,11.8 30.4,12.6 22.1,19.7 24.7,30.5 15.2,24.7
	5.8,30.5 8.4,19.7 0,12.6 11,11.8 "
          />
          <polygon
            className="rating__star"
            points="47.6,1.5 51.8,11.8 62.8,12.6 54.4,19.7 57,30.5 47.6,24.7
	38.2,30.5 40.8,19.7 32.4,12.6 43.4,11.8 "
          />
          <polygon
            className="rating__star"
            points="80,1.5 84.2,11.8 95.2,12.6 86.8,19.7 89.4,30.5 80,24.7
	70.6,30.5 73.2,19.7 64.8,12.6 75.8,11.8 "
          />
          <polygon
            className="rating__star"
            points="112.4,1.5 116.6,11.8 127.6,12.6 119.2,19.7 121.8,30.5
	112.4,24.7 103,30.5 105.6,19.7 97.2,12.6 108.2,11.8 "
          />
          <polygon
            className="rating__star"
            points="144.8,1.5 149,11.8 160,12.6 151.6,19.7 154.2,30.5 144.8,24.7
	135.3,30.5 137.9,19.7 129.6,12.6 140.5,11.8 "
          />
        </svg>
        {showNr ? <span className="rating__number">{parseFloat(rating).toFixed(1)}</span> : null}
      </div>
    </div>
  );
}

Rating.propTypes = propTypes;
Rating.defaultProps = defaultProps;

export default Rating;
