import { COMPANY_SEARCH_SORTING } from '@/constants/companySearch';
import { UPDATE_COMPANY_SEARCH, SEARCH_COMPANIES, SEARCH_BRANDED_COMPANIES } from '@/constants/actions';
import produce, { freeze } from 'immer';

const initialState = {
  name: '',
  sorting: COMPANY_SEARCH_SORTING.find((o) => o.get('default')),
  results: {
    total: 0,
    pages: 0,
    items: [],
  },
  perPage: 18,
  page: 1,
  seed: Math.round(Math.random() * 1000),
  brandedCompanies: [],
};

const companySearch = produce((state, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_SEARCH:
      Object.keys(action.params).forEach((key) => {
        state[key] = action.params[key];
      });
      break;

    case SEARCH_COMPANIES: {
      const { results, page } = action;
      results.pages = Math.ceil(results.total / state.perPage);
      state.results = freeze(results);
      state.page = page;
      break;
    }

    case SEARCH_BRANDED_COMPANIES:
      state.brandedCompanies = freeze(action.companies);
      break;

    default:
      break;
  }
}, initialState);

export default companySearch;
