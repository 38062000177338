import { getMapDefaultBounds } from '../constants/map';

export function extendBounds(north, south, west, east, extendBy = 1) {
  const latDiff = (north - south) * extendBy;
  const lngDiff = (east - west) * extendBy;

  return [north + latDiff, south - latDiff, west - lngDiff, east + lngDiff];
}

export function getGoogleMaps() {
  if (window.google) {
    return Promise.resolve(window.google);
  }

  return new Promise((resolve) => {
    // Add a global handler for when the API finishes loading
    window.resolveGoogleMapsPromise = () => {
      // Resolve the promise
      resolve(window.google);

      // Tidy up
      delete window.resolveGoogleMapsPromise;
    };

    // Load the Google Maps API
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_STREETVIEW_ACCESS_TOKEN}&callback=resolveGoogleMapsPromise`;
    script.async = true;
    document.body.appendChild(script);
  });
}

/**
 *
 * @param {number} lat Latitude
 * @param {number} lng Longitude
 * @param {(Array.<{lat: number, lng: number}>|null)} bounds Map bounds to fit in. `null` restricts by maximum possible values
 * @returns
 */
export function validateCoordinates(lat, lng, bounds = getMapDefaultBounds()) {
  const validatedLat = !lat ? lat === 0.0 : true;
  const validatedLng = !lng ? lng === 0.0 : true;
  if (bounds === null) {
    return {
      lat: validatedLat && lat >= -90.0 && lat <= 90.0,
      lng: validatedLng && lng >= -180.0 && lng <= 180.0,
    };
  }
  const [sw, ne] = bounds;
  return {
    lat: validatedLat && lat >= sw.lat && lat <= ne.lat,
    lng: validatedLng && lng >= sw.lng && lng <= ne.lng,
  };
}
