import { List, fromJS } from 'immutable';
import * as Sentry from '@sentry/nextjs';

import { localStorage } from '@city24/common/browserStorage';
import auth, { parseTokenPayload } from '@city24/common/api/city24/auth';

import { PORTAL_NAME } from '@/constants';
import {
  SIGN_IN,
  CREATE_ACCOUNT,
  SAVE_SEARCH_SETTINGS_DRAFT,
  UPDATE_SAVED_SEARCH,
  SAVE_FAVOURITE,
  REMOVE_FAVOURITE,
  SET_SAVE_FAVOURITE_MODAL,
  REMOVE_SAVED_SEARCH,
  SIGN_IN_ERROR,
  SIGN_OUT,
  CREATE_ACCOUNT_ERROR,
  SOCIAL_LOGIN_INIT,
  GET_FAVOURITES,
  GET_FAVOURITES_SHORT_DETAILS,
  GET_INVOICES,
  GET_COUNTERS,
  GET_SAVED_SEARCHES,
  ADD_SAVED_SEARCH,
  SET_LATEST_SEARCHES,
  SET_PASSWORD_UPDATED,
  ACCOUNT_VERIFICATION_ERROR,
  SET_ACCOUNT_VERIFIED,
  SET_UUID_VERIFIED,
  PASSWORD_UPDATE_ERROR,
  SET_RECENTLY_VIEWED_OBJECTS,
  CLEAR_SEARCH_SETTINGS_DRAFT,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS_ERROR,
  UUID_VERIFICATION_ERROR,
  IMPERSONATE_USER,
  GET_UNSUBSCRIBE_SAVED_SEARCHES,
} from '@/constants/actions';
import {
  UnauthorizedError,
  BadRequestError,
  NotFoundError,
  HttpError,
  httpErrorfromResponse,
  UnauthenticatedError,
} from '@/errors/http';
import { ViolationError } from '@/errors/app';
import { getFavourites, hasSearchesChanged, hasFavouritesChanged } from '@/selectors/userSelectors';
import { MY_OBJECTS_PER_PAGE, OBJECT_TYPES } from '@/constants/object';
import { makeFavourite } from '@/utils/helpers';
import { pick } from '@/utils/object';
import { Favourite, NameValue } from '@/utils/collections';
import { getSearchFilters, getFiltersByObjectType } from '@/components/search/searchSelectors';
import { EMAIL_ALERT_OFF, SEARCH_MODE_NEW_PROJECTS, SEARCH_MODE_LIST } from '@/constants/savedSearches';
import { composeSearchQuery, getLatestSearchFromStorage } from '@/utils/search';
import { fetchFavouriteCount } from '@/components/object/ObjectDetailsActions';
import {
  setSignInModalOpened,
  setSaveSearchModal,
  setSaveSearchConfirmModalOpened,
  setRegisterModalOpened,
  setRegisterConfirmModalOpened,
  setInvoicesSentModal,
  setSuccessfulActionModal,
} from '@/components/modals/ModalActions';
import { setSearchFromLocalState } from '@/components/search/SearchActions';
import { getFilters } from '@/constants/filters';
import { fromISOString } from '@/utils/date';
import { setCategory } from '@/legacyPages/MyObject/MyObjectActions';
import * as stats from '../constants/statistics';
import api, {
  processDatetimeParam,
  cacheReload,
  getSearchAction,
  parsePagination,
  paginatedQuery,
} from '../api/City24Api';
import { sendStatistics } from './statisticsActions';
import { openNotification } from '@/components/notifications/notificationReducer';
import { NotificationName } from '@/components/notifications/notificationInterfaces';

export function saveSearch(name, alert, email, interval, priceChange) {
  return (dispatch, getState) => {
    const state = getState();

    const filters = getSearchFilters(state);
    const criteria = composeSearchQuery(filters, getFiltersByObjectType(state));

    const objectType = filters.objectType && filters.objectType.get('value');
    if (objectType === OBJECT_TYPES.NewProject) {
      criteria.unitTypes = criteria.unitType;
    } else {
      criteria.unitTypes = [criteria.unitType];
    }
    criteria.searchMode = objectType === OBJECT_TYPES.NewProject ? SEARCH_MODE_NEW_PROJECTS : SEARCH_MODE_LIST;
    delete criteria.unitType;

    api.user
      .saveSearch({
        email_alert_type: alert ? interval.value : EMAIL_ALERT_OFF,
        name,
        email_address: email,
        portal: PORTAL,
        criteria,
        price_alert: priceChange,
      })
      .then((res) => (res.ok ? res.json() : {}))
      .then((search) => {
        dispatch({ type: ADD_SAVED_SEARCH, search });
        dispatch(setSaveSearchModal(false));
        dispatch(setSaveSearchConfirmModalOpened(true));
      });
  };
}

function clearSearchSettingsDraft() {
  localStorage.removeItem('saveSearchDraft');
  return {
    type: CLEAR_SEARCH_SETTINGS_DRAFT,
  };
}

function processCreateAccount(action, provider = '') {
  return (dispatch) =>
    action
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        if (res.status >= 500) {
          throw new HttpError(`Failed to create account with provider ${provider}`);
        }

        return res.json().then((body) => {
          // NOTE: Not sure 400 and 422 should be treated in a similar way
          if (res.status === 400 || res.status === 422) {
            throw new ViolationError(body.detail, body.violations);
          }
        });
      })
      .then((user) => {
        dispatch({
          type: CREATE_ACCOUNT,
          user,
        });

        // Modals used for regular registration only
        if (provider === '') {
          dispatch(setRegisterModalOpened(true, true));
        }
        return user;
      })
      .catch((err) => {
        const errorType = (err instanceof ViolationError && 'violation') || 'error';
        dispatch({
          type: CREATE_ACCOUNT_ERROR,
          error: errorType,
          violations: err.violations,
          provider,
        });

        if (errorType === 'error') {
          throw err;
        }
      });
}

export function createAccount(email, password, name, phone, returnPath, subscribeToNewsletter, captcha) {
  const nameParts = name
    .trim()
    .split(' ')
    .map((part) => part.trim())
    .filter((part) => part);

  return processCreateAccount(
    api.user.createAccount(
      {
        email_address: email,
        first_name: nameParts.length === 1 ? nameParts[0] : nameParts.slice(0, nameParts.length - 1).join(' '),
        last_name: nameParts.length === 1 ? '' : nameParts[nameParts.length - 1],
        phone_number: phone,
        password,
        locale_code: api.getLocale(),
        default_portal: PORTAL_NAME,
        activation_path: returnPath,
        subscribe_to_newsletter: subscribeToNewsletter,
      },
      {
        token: captcha.token,
        version: captcha.version,
      }
    )
  );
}

function createAccountSocial(provider, accessToken) {
  return processCreateAccount(
    api.user.createAccountSocial(provider, {
      access_token: accessToken,
      locale_code: api.getLocale(),
      default_portal: PORTAL_NAME,
    }),
    provider
  );
}

export function loadFavouriteObjects(favourites) {
  const query = { short: true, guid: true };
  const resultAction = (f) => {
    switch (f.type) {
      case 'PROJECT':
        return [api.getProject(f.id, query), OBJECT_TYPES.NewProject];
      case 'MODULAR_HOUSE':
        return [api.getModularHouse(f.id, query), OBJECT_TYPES.ModularHouse];
      case 'REALTY':
      default:
        return [api.getObject(f.id, query), null];
    }
  };

  return (dispatch) => {
    favourites.forEach((f) => {
      const [action, objectType] = resultAction(f);
      return action
        .then((res) => (res.ok ? res.json() : null))
        .then((object) => {
          if (object) {
            // eslint-disable-next-line no-param-reassign
            object.unit_type = objectType || object.unit_type;

            dispatch({
              type: GET_FAVOURITES_SHORT_DETAILS,
              favourite: f,
              object,
            });
          } else {
            dispatch({
              type: REMOVE_FAVOURITE,
              favourite: f,
            });
          }
        });
    });
  };
}

export function fetchFavourites(loadObjects = false, force = false) {
  return (dispatch, getState) => {
    const changed = force || hasFavouritesChanged(getState());
    return api.user
      .getFavourites({ identifier: auth.getToken('anonToken') }, changed ? cacheReload() : undefined)
      .then((res) => (res.ok ? res.json() : []))
      .then((favourites) => {
        const fs = favourites.map((f) => [Favourite({ id: f.item_global_id, type: f.item_type }), f.id]);
        dispatch({
          type: GET_FAVOURITES,
          favourites: fs,
        });

        if (loadObjects) {
          dispatch(loadFavouriteObjects(fs.map(([fav]) => fav)));
        }
      });
  };
}

export function fetchInvoices(reload = false, page = 1) {
  return (dispatch) => {
    const { userId } = parseTokenPayload();
    const query = paginatedQuery(MY_OBJECTS_PER_PAGE, page, {
      order: {
        invoiceDate: 'DESC',
      },
    });
    return api.user
      .getUserInvoices(userId, query, reload ? cacheReload() : {})
      .then((res) => (res.ok ? Promise.all([res.json(), parsePagination(res)]) : [[], null]))
      .then(([invoices, pagination]) => {
        return dispatch({
          type: GET_INVOICES,
          invoices,
          totalItems: pagination.total,
        });
      });
  };
}

export function fetchInfoCounters() {
  return (dispatch) => {
    const { userId } = parseTokenPayload();
    return api.user
      .getInfoCounters(userId, {})
      .then((res) => (res.ok ? res.json() : []))
      .then((counters) => {
        dispatch({
          type: GET_COUNTERS,
          counters,
        });
      });
  };
}

export function saveFavourite(id, guid, objectType, itemCount = false) {
  sendStatistics(stats.SAVE_FAVORITE, guid);
  return (dispatch, getState) => {
    const favourites = getFavourites(getState());

    const favourite = makeFavourite(guid, objectType);
    api.user
      .saveFavourite({
        item_id: String(id),
        item_type: favourite.type,
        portal_name: PORTAL_NAME,
      })
      .then((res) => (res.ok ? res.json() : {}))
      .then((fav) => {
        dispatch({
          type: SAVE_FAVOURITE,
          favourite,
          favouriteId: fav.id,
        });

        if (itemCount) {
          dispatch(fetchFavouriteCount(favourite.id, favourite.type));
        }
      });

    if (favourites.size < 1) {
      dispatch({
        type: SET_SAVE_FAVOURITE_MODAL,
        saveFavouriteModalOpened: true,
      });
    }
  };
}

export function removeFavourite(favouriteId, favourite, itemCount = false) {
  sendStatistics(stats.REMOVE_FAVORITE, favourite.id);
  return (dispatch) => {
    api.user.deleteFavourite(favouriteId).then((res) => {
      if (res.ok) {
        dispatch({
          type: REMOVE_FAVOURITE,
          favourite,
        });

        if (itemCount) {
          dispatch(fetchFavouriteCount(favourite.id, favourite.type));
        }
      }
    });
  };
}

export function fetchSavedSearches() {
  return (dispatch, getState) => {
    const changed = hasSearchesChanged(getState());
    return api.user
      .getSavedSearches(
        { identifier: auth.getToken('anonToken'), order: { dateCreated: 'desc' } },
        changed ? cacheReload() : undefined
      )
      .then((res) => (res.ok ? res.json() : []))
      .then((searches) => {
        dispatch({
          type: GET_SAVED_SEARCHES,
          searches,
        });
      });
  };
}

export function fetchSavedSearchForUnsubscribe(query) {
  return (dispatch) => {
    return api.user
      .getSavedSearches(query, undefined, true)
      .then((res) => (res.ok ? res.json() : []))
      .then((searches) => {
        dispatch({
          type: GET_UNSUBSCRIBE_SAVED_SEARCHES,
          search: searches.at(0),
        });
      });
  };
}

export function unsubscribeFromSavedSearch(id, query) {
  return api.user.unsubscribeSearch(id, query).then((res) => {
    return res.ok;
  });
}

export function saveSearchDraft(title, alert, interval, email, priceChange) {
  return (dispatch) => {
    dispatch({
      type: SAVE_SEARCH_SETTINGS_DRAFT,
      title,
      alert,
      interval,
      email,
      priceChange,
    });
    // store draft to local storage in case login requires redirect
    localStorage.setItem('saveSearchDraft', JSON.stringify({ title, alert, interval, email, priceChange }));

    dispatch(setSaveSearchModal(false));
    dispatch(setSignInModalOpened(true));
  };
}

export function updateSavedSearch(item) {
  return (dispatch) => {
    const updateItem = pick(item, 'name', 'email_alert_type', 'last_run', 'price_alert');
    api.user
      .updateSearch(item.id, {
        ...updateItem,
        last_run: processDatetimeParam(updateItem.last_run),
      })
      .then((res) =>
        res.ok
          ? dispatch({
              type: UPDATE_SAVED_SEARCH,
              id: item.id,
              ...updateItem,
            })
          : null
      );
  };
}

export function removeSavedSearch(id) {
  return (dispatch) => {
    api.user.deleteSearch(id).then((res) => (res.ok ? dispatch({ type: REMOVE_SAVED_SEARCH, id }) : null));
  };
}

export function setLatestSearches(latestSearches) {
  return (dispatch) => {
    // Query only the last one for the time being
    const selectedSearches = latestSearches.toArray().slice(-1);
    Promise.all(
      selectedSearches.map((search) => {
        const filters = search.get('filters').toObject();
        const query = composeSearchQuery(filters, getFilters(filters.objectType));

        const searchDateString = search.get('date');
        try {
          const searchDate = fromISOString(searchDateString, true);
          query.datePublished = { gte: Math.floor(searchDate.getTime() / 1000) };
        } catch (exc) {
          return;
        }

        const [action] = getSearchAction(query);
        return action(query, { method: 'HEAD' }).then((res) => {
          if (res.ok) {
            const { total } = parsePagination(res);
            return search.set('new_ads_count', total);
          }
          return search.set('new_ads_count', 0);
        });
      })
    ).then((searches) => {
      dispatch({
        type: SET_LATEST_SEARCHES,
        latestSearches: List(searches),
      });
    });
  };
}

export function setPasswordUpdated(passwordUpdated) {
  return {
    type: SET_PASSWORD_UPDATED,
    passwordUpdated,
  };
}

export function updatePassword(uuid, newPassword) {
  return (dispatch) => {
    api.user
      .passwordResetConfirm({
        password: newPassword,
        uuid,
      })
      .then((res) => {
        if (res.ok) {
          dispatch(setPasswordUpdated(true));
        } else if (res.status === 400) {
          return res.json().then((body) => {
            const message = body.message || (body.error && body.error.message);
            throw new ViolationError(message, body.violations);
          });
        } else if (res.status === 404) {
          throw new ViolationError(null, [
            {
              message: 'errors.passwordReset.expired',
            },
          ]);
        } else {
          throw new NotFoundError('errors.passwordReset.expired');
        }
      })
      .catch((error) => {
        dispatch({
          type: PASSWORD_UPDATE_ERROR,
          error,
        });
      });
  };
}

export function verifyAccount(uuid) {
  return (dispatch) => {
    return api.user
      .verifyAccount(uuid)
      .then((res) => {
        if (res.ok) {
          return res.json().then((user) => {
            return dispatch({
              type: SET_ACCOUNT_VERIFIED,
              user,
            });
          });
        }
        if (res.status === 400) {
          return res.json().then((body) => {
            const message = body.message || (body.error && body.error.message);
            throw new ViolationError(message, body.violations);
          });
        }
        throw new NotFoundError('errors.verification.notFound');
      })
      .catch((error) => {
        return dispatch({
          type: ACCOUNT_VERIFICATION_ERROR,
          error,
        });
      });
  };
}

export function confirmAccount(user, form) {
  return (dispatch) => {
    return api.user
      .confirmAccount(user.id, form)
      .then((res) => {
        if (res.ok) {
          return res.json().then((user) => {
            return dispatch({
              type: SET_ACCOUNT_VERIFIED,
              user,
            });
          });
        }
        if (res.status === 400 || res.status === 422) {
          return res.json().then((body) => {
            const message = body.message || (body.error && body.error.message);
            throw new ViolationError(message, body.violations);
          });
        }
      })
      .catch((error) => {
        return dispatch({
          type: ACCOUNT_VERIFICATION_ERROR,
          error,
        });
      });
  };
}

export function verifyUuid(uuid) {
  return (dispatch) => {
    return api.user
      .verifyUuid({ uuid })
      .then((res) => {
        if (res.ok) {
          return dispatch({
            type: SET_UUID_VERIFIED,
            valid: true,
          });
        }

        throw new ViolationError('errors.uuid.invalid');
      })
      .catch((error) => {
        dispatch({
          type: UUID_VERIFICATION_ERROR,
          error,
        });
      });
  };
}

export function fetchRecentlyViewedObjects(ids) {
  const query = { short: true };
  const resultAction = (id) => {
    if (id.startsWith('p-')) {
      return api.getProject(id.replace('p-', ''), query);
    }
    if (id.startsWith('mh-')) {
      return api.getModularHouse(id.replace('mh-', ''), query);
    }

    return api.getObject(id.replace('o-', ''), query);
  };

  return (dispatch) => {
    ids.forEach((id) => {
      const action = resultAction(id);
      return action.then((res) =>
        res.ok
          ? res.json().then((object) => {
              dispatch({
                type: SET_RECENTLY_VIEWED_OBJECTS,
                id,
                object,
              });
            })
          : {}
      );
    });
  };
}

function runAfterLogin() {
  return (dispatch) => {
    // continue saving search
    const storageDraft = localStorage.getItem('saveSearchDraft');

    // if save => reload search filters from
    if (storageDraft) {
      const draft = JSON.parse(storageDraft);

      if (draft.title) {
        const interval = NameValue(draft.interval);
        // get last search data from local storage
        const lastSearch = getLatestSearchFromStorage().last();
        // dispatch  it to store
        dispatch(setSearchFromLocalState(lastSearch.get('filters')));
        dispatch(saveSearch(draft.title, draft.alert, draft.email, interval, draft.priceChange));
        // clear draft
        dispatch(clearSearchSettingsDraft());
      }
    }

    // continue object insertion
    const objInsertDraft = localStorage.getItem('objectInsert');
    if (objInsertDraft) {
      dispatch(setCategory(fromJS(JSON.parse(objInsertDraft))));
      localStorage.removeItem('objectInsert');
    }

    dispatch(fetchFavourites(false, true));
    dispatch(fetchSavedSearches());
  };
}

function signInAction(userDetails) {
  return {
    type: SIGN_IN,
    userDetails: fromJS(userDetails),
  };
}

export function signInError(error, provider = '', errorData) {
  return {
    type: SIGN_IN_ERROR,
    provider,
    error,
    errorData,
  };
}

function processSigningIn(action, provider = '') {
  return (dispatch) =>
    action
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return res.json().then((body) => {
          const message = body.message || body.error.message;
          if (res.status === 401) {
            throw new UnauthenticatedError(message);
          } else {
            throw new BadRequestError(message);
          }
        });
      })
      .then((result) => {
        auth.setToken('token', result.token);
        auth.setToken('refreshToken', result.refresh_token);

        const payload = parseTokenPayload(result.token);

        return Promise.all([payload, api.user.getUserInfo(payload.userId).then((res) => (res.ok ? res.json() : {}))]);
      })
      .then(([payload, userInfo]) => {
        const userDetails = {
          email: userInfo.email_address,
          roles: payload.roles,
          ...userInfo,
        };
        dispatch(signInAction(userDetails));

        dispatch(setSignInModalOpened(false));
        dispatch(openNotification(NotificationName.SignIn));

        dispatch(runAfterLogin());

        Sentry.setUser({ id: userDetails.id, username: userDetails.username });

        return payload;
      });
}

function signInErrorFromResponseError(err, provider = '') {
  return signInError(
    // Invalid email or password
    ((err instanceof UnauthorizedError || err instanceof UnauthenticatedError) && 'unauthorized') ||
      // Missing fields
      (err instanceof BadRequestError && 'badRequest') ||
      // Connection or API error
      'error',
    provider,
    err?.message
  );
}

export function signIn(email, password) {
  return (dispatch) =>
    dispatch(processSigningIn(api.user.login({ username: email, password, default_portal: PORTAL_NAME }))).catch(
      (err) => {
        return dispatch(signInErrorFromResponseError(err));
      }
    );
}

export function signInByAccessToken(provider, accessToken) {
  return (dispatch) => {
    dispatch(setSignInModalOpened(false));
    dispatch({
      type: SOCIAL_LOGIN_INIT,
      provider,
      accessToken,
    });
    return dispatch(processSigningIn(api.user.loginSocial(provider, { access_token: accessToken }), provider)).catch(
      (err) => {
        if (err instanceof UnauthorizedError || err instanceof UnauthenticatedError) {
          return dispatch(createAccountSocial(provider, accessToken)).then(() => {
            // Retry login
            dispatch(signInByAccessToken(provider, accessToken));
          });
        }

        return dispatch(signInErrorFromResponseError(err, provider));
      }
    );
  };
}

export function signInWithToken(payload) {
  return (dispatch) => {
    return api.user
      .getUserInfo(payload.userId)
      .then((res) => (res.ok ? res.json() : {}))
      .then((userInfo) => {
        const userDetails = {
          email: userInfo.email_address,
          roles: payload.roles,
          ...userInfo,
        };
        return dispatch(signInAction(userDetails));
      });
  };
}

export function signInWithRefreshToken(refreshToken) {
  return (dispatch) => {
    return api.user
      .refreshToken({ refresh_token: refreshToken, default_portal: PORTAL_NAME })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        if (res.status === 401) {
          throw new UnauthorizedError('Token expired');
        }

        return {};
      })
      .then(({ token }) => {
        if (token) {
          auth.setToken('token', token);
          const payload = parseTokenPayload(token);
          return dispatch(signInWithToken(payload));
        }

        return null;
      })
      .catch((err) => {
        if (err instanceof UnauthorizedError || err instanceof UnauthenticatedError) {
          auth.setToken('token', null);
          auth.setToken('refreshToken', null);
        }
      });
  };
}

export function signOut() {
  auth.setToken('token', null);
  auth.setToken('refreshToken', null);
  Sentry.setUser(null);
  return { type: SIGN_OUT };
}

export function sendInvoicesToEmail(invoiceIds) {
  return (dispatch) => {
    api.user
      .emailUserInvoices({
        sendToEmail: true,
        ids: invoiceIds.toArray(),
        portal: PORTAL,
      })
      .then((res) => (res.ok ? dispatch(setInvoicesSentModal(true)) : null));
  };
}

export function updateUserSettings(settings, showModal = true, returnPath = null) {
  const { userId } = parseTokenPayload();

  return (dispatch) => {
    return api.user
      .updateUserInfo(userId, {
        ...settings,
        activation_path: returnPath,
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        if (res.status === 400) {
          return res.json().then((body) => {
            throw new ViolationError(body.detail, body.violations);
          });
        }

        return httpErrorfromResponse(res, { settings }).then((err) => {
          throw err;
        });
      })
      .then((userDetails) => {
        if (settings.newEmail) {
          // user has changed his email address, must be signed out
          dispatch(signOut());
          return dispatch(setSuccessfulActionModal(true, 'changeEmail'));
        }
        if (showModal) {
          dispatch(setSuccessfulActionModal(true));
        }
        return dispatch({
          type: UPDATE_USER_SETTINGS,
          userDetails,
        });
      })
      .catch((err) => {
        const errorType = (err instanceof ViolationError && 'violation') || 'error';
        dispatch({
          type: UPDATE_USER_SETTINGS_ERROR,
          error: errorType,
          violations: err.violations,
        });

        if (errorType === 'error') {
          throw err;
        }
      });
  };
}

export function getUserInfo(id) {
  return (dispatch) => {
    return api.user
      .getUserInfo(id)
      .then((res) => (res.ok ? res.json() : {}))
      .then((userInfo) => {
        const userDetails = {
          email: userInfo.email_address,
          // roles: payload.roles,
          ...userInfo,
        };
        return dispatch(signInAction(userDetails));
      });
  };
}

export function getAdminUserInfo(username) {
  return (dispatch) => {
    const query = {
      username,
    };
    return api.user
      .searchUsers(query)
      .then((res) => (res.ok ? res.json() : []))
      .then((users) => {
        const user = users.find((u) => {
          return u.username === username;
        });
        if (user) {
          localStorage.setItem('impersonateUser', JSON.stringify({ user: username, active: true, id: user.id }));
          dispatch(getUserInfo(user.id));
        }
        return dispatch({
          type: IMPERSONATE_USER,
          status: typeof user !== 'undefined',
        });
      });
  };
}
