import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../button/Button';

import { FilterContext } from './Filter';

const propTypes = {
  showClear: PropTypes.bool,
  applyFilter: PropTypes.func,
};

const defaultProps = {
  showClear: false,
  applyFilter: null,
};

class FilterFooter extends React.Component {
  applyFilter = (closeFilter) => {
    const { applyFilter } = this.props;

    closeFilter();
    applyFilter();
  };

  renderClear() {
    const { clear, showClear, t } = this.props;
    return <Button disabled={!showClear} link text={t('common.clear')} className="filter__clear" onClick={clear} />;
  }

  render() {
    const { t } = this.props;
    return (
      <FilterContext.Consumer>
        {({ closeFilter }) => (
          <div className="filter__footer">
            {this.renderClear()}
            <Button
              primary
              text={t('common.apply')}
              className="filter__apply"
              onClick={() => this.applyFilter(closeFilter)}
            />
          </div>
        )}
      </FilterContext.Consumer>
    );
  }
}

FilterFooter.propTypes = propTypes;
FilterFooter.defaultProps = defaultProps;

export default FilterFooter;
