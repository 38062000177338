export const ROLE_CUSTOMER_SUPPORT = 'ROLE_CUSTOMER_SUPPORT';

// From @city24/common
export enum UserStatus {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
  NEW_USER = 'NEW_USER',
}

// From @city24/common
export enum UserRole {
  USER = 'ROLE_USER',
  ADMIN = 'ROLE_ADMIN',
  MANAGER = 'ROLE_MANAGER',
  AGENT = 'ROLE_AGENT',
  CUSTOMER_SUPPORT = 'ROLE_CUSTOMER_SUPPORT',
}
