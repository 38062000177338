export const TRANSACTION_TYPES = Object.freeze({
  SALE: 'sale',
  RENT: 'rent',
});

export const TRANSACTION_TYPES_ID = Object.freeze({
  [TRANSACTION_TYPES.SALE]: 1,
  [TRANSACTION_TYPES.RENT]: 2,
});

export const SIZE_UNIT_VALUES = Object.freeze({
  1: 'm²',
  4: 'acre',
  5: 'ha',
});

export const INPUT_MAXLENGTH = Object.freeze({
  slogan: 60,
  description: 2000,
});

export const OBJECT_TYPES_ID = Object.freeze({
  Apartment: 1,
  SemiDetachedHouse: 2,
  House: 3,
  CottageOrVilla: 4,
  Farm: 7,
  TerracedHouse: 10,
  DetachedHouse: 14,
  Plot: 15,
  Parking: 16,
  Commercial: 17,
  HouseShare: 18,
  Land: 19,
  Garage: 20,
  NewProject: 1000,
  ModularHouse: 1001,
});

export const OBJECT_TYPES = Object.freeze({
  Apartment: 'Apartment',
  SemiDetachedHouse: 'SemiDetachedHouse',
  House: 'House',
  CottageOrVilla: 'CottageOrVilla',
  Farm: 'Farm',
  TerracedHouse: 'TerracedHouse',
  DetachedHouse: 'DetachedHouse',
  Plot: 'Plot',
  Parking: 'Parking',
  Commercial: 'Commercial',
  HouseShare: 'HouseShare',
  Land: 'Land',
  Garage: 'Garage',
  NewProject: 'NewProject',
  ModularHouse: 'ModularHouse',
});

export const OBJECT_PLACEMENTS = Object.freeze({
  TOP_OFFERS: 'TOP_OFFERS',
  TOP_PROJECTS: 'TOP_PROJECTS',
  HOT_OFFERS_HOUSE_LAND: 'HOT_OFFERS_HOUSE_LAND',
  OPEN_DAYS: 'OPEN_DAYS',
});

// SECONDARY SPECS TO RENDER
const secondarySpecs = [
  {
    value: 'finishStage',
    objectTypes: [OBJECT_TYPES.NewProject],
  },
  {
    value: 'elevator',
    objectTypes: [OBJECT_TYPES.Apartment, OBJECT_TYPES.Commercial, OBJECT_TYPES.NewProject],
  },
  {
    value: 'outdoorSpaces',
    objectTypes: [OBJECT_TYPES.NewProject],
  },
  {
    value: 'storageSpace',
    objectTypes: [OBJECT_TYPES.NewProject],
  },
  {
    value: 'garden',
    objectTypes: [OBJECT_TYPES.NewProject],
  },
  {
    value: 'sharedWarehouse',
    objectTypes: [OBJECT_TYPES.NewProject],
  },
  {
    value: 'sharedLounge',
    objectTypes: [OBJECT_TYPES.NewProject],
  },
  {
    value: 'smartHome',
    objectTypes: [OBJECT_TYPES.NewProject],
  },
  {
    value: 'heatedFloors',
    objectTypes: [OBJECT_TYPES.NewProject],
  },
  {
    value: 'nearbySurroundings',
    objectTypes: [OBJECT_TYPES.NewProject],
  },
  {
    value: 'achievements',
    objectTypes: [OBJECT_TYPES.NewProject],
  },
  {
    value: 'bedrooms',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Commercial,
    ],
  },
  {
    value: 'bathrooms',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Commercial,
    ],
  },
  {
    value: 'balcony',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Commercial,
    ],
  },
  {
    value: 'terrace',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Commercial,
    ],
  },
  {
    value: 'sauna',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Commercial,
    ],
  },
  {
    value: 'constructionYear',
    objectTypes: [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.CottageOrVilla],
  },
  {
    value: 'storage',
    objectTypes: [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.CottageOrVilla],
  },
  {
    value: 'energyCertificate',
    objectTypes: [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.CottageOrVilla],
  },
  {
    value: 'parking',
    objectTypes: [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.Commercial],
  },
  {
    value: 'immediatelyAvailable',
    objectTypes: [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.CottageOrVilla],
  },
  {
    value: 'furniture',
    objectTypes: [OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.CottageOrVilla, OBJECT_TYPES.Commercial],
  },
  {
    value: 'ventilation',
    objectTypes: [OBJECT_TYPES.Commercial],
  },
  {
    value: 'heating',
    objectTypes: [OBJECT_TYPES.Commercial],
  },
  {
    value: 'meetingRoom',
    objectTypes: [OBJECT_TYPES.Commercial],
  },
  {
    value: 'purposeOfUse',
    objectTypes: [OBJECT_TYPES.Land, OBJECT_TYPES.Commercial],
  },
  {
    value: 'electricity',
    objectTypes: [OBJECT_TYPES.Land, OBJECT_TYPES.NewProject],
  },
  {
    value: 'gas',
    objectTypes: [OBJECT_TYPES.Land],
  },
  {
    value: 'water',
    objectTypes: [OBJECT_TYPES.Land],
  },
  {
    value: 'road',
    objectTypes: [OBJECT_TYPES.Land],
  },
  {
    value: 'sewerage',
    objectTypes: [OBJECT_TYPES.Land],
  },
  {
    value: 'evCharger',
    objectTypes: [
      OBJECT_TYPES.NewProject,
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Commercial,
    ],
  },
  {
    value: 'permissionToBuild',
    objectTypes: [OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.Commercial],
  },
  {
    value: 'permissionToUse',
    objectTypes: [OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.Commercial],
  },
  {
    value: 'runningBusiness',
    objectTypes: [OBJECT_TYPES.Commercial],
  },
];

export function getSecondarySpecs(objectType) {
  return secondarySpecs.filter((spec) => spec.objectTypes.includes(objectType)).map(({ value }) => value);
}

const objectListAttributes = [
  {
    name: 'objectSpec.price',
    value: 'price',
    isMainFeature: true,
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.NewProject,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.Land,
    ],
  },
  {
    name: 'objectSpec.size',
    value: 'size',
    isMainFeature: true,
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.NewProject,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.Land,
    ],
  },
  {
    name: 'objectSpec.rooms',
    value: 'rooms',
    isMainFeature: true,
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.NewProject,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.CottageOrVilla,
    ],
  },
  {
    name: 'objectSpec.floor',
    value: 'floor',
    isMainFeature: true,
    objectTypes: [OBJECT_TYPES.Apartment],
  },
  {
    name: 'objectSpec.apartment',
    value: 'apartment',
    isMainFeature: true,
    objectTypes: [OBJECT_TYPES.Apartment],
  },
];

export function getObjectListAttributes(objectType) {
  return objectListAttributes.filter((spec) => spec.objectTypes.includes(objectType));
}

export default {
  TRANSACTION_TYPES,
  OBJECT_TYPES,
};

export const MY_OBJECTS_PER_PAGE = 20;
export const LOCATION_NAME_RIGA = 'Rīga';
export const CB_LOCATION_MAP = [
  { name: 'center', locations: ['Centrs', 'Pētersala', 'Vecrīga', 'Grīziņkalns', 'Avoti', 'Brasa', 'Skanste'] },
  {
    name: 'left',
    locations: [
      'Bolderāja',
      'Pārdaugava',
      'Pleskodāle-Šampēteris',
      'Torņakalns',
      'Zasulauks',
      'Ziepniekkalns',
      'Zolitūde',
      'Bišumuiža',
      'Bieriņi',
      'Daugavgrīva',
      'Beberbeķi',
      'Buļļi',
      'Dzirciems',
      'Iļģuciems',
      'Mūkusala',
      'Katlakalns',
      'Klīversala',
      'Kleisti',
      'Atgāzene',
      'Spilve',
      'Voleri',
    ],
  },
  {
    name: 'right',
    locations: [
      'Bukulti',
      'Čiekurkalns',
      'Mežciems',
      'Rumbula',
      'Sarkandaugava',
      'Šķirotava',
      'Vecāķi',
      'Vecdaugava',
      'Vecmīlgrāvis',
      'Berģi',
      'Dārzciems',
      'Dreiliņi',
      'Dārziņi',
      'Jugla',
      'Jaunmīlgrāvis',
      'Aplokciems',
      'Jaunciems',
      'Trīsciems',
      'Krasta masīvs',
      'Maskavas priekšpilsēta',
      'Mangaļu pussala',
      'Suži',
      'Brekši',
      'Kundziņsala',
      'Mīlgrāvis',
    ],
  },
  {
    name: 'agenskalns',
    locations: ['Āgenskalns'],
  },
  {
    name: 'imanta',
    locations: ['Imanta'],
  },
  {
    name: 'kengarags',
    locations: ['Ķengarags'],
  },
  {
    name: 'plavnieki',
    locations: ['Pļavnieki'],
  },
  {
    name: 'purvciems',
    locations: ['Purvciems'],
  },
  {
    name: 'teika',
    locations: ['Teika'],
  },
];
