import React, { useContext } from 'react';
import Icon from '@/components/icon/Icon';
import AppContext from '@/components/appContext';
import Tooltip from '@/components/tooltip/Tooltip';

interface Props {
  light?: boolean;
}

const VerifiedBadge = ({ light = false }: Props): React.ReactElement => {
  const { useTranslation } = useContext(AppContext);
  const [t] = useTranslation();

  return (
    <>
      <span className={light ? 'text-white' : 'text-green'} data-tooltip-id="verified-user-badge">
        <Icon name="verified-user-badge" />
      </span>
      <Tooltip id="verified-user-badge" className="tooltip">
        {t('common.verifiedUser')}
      </Tooltip>
    </>
  );
};

export default VerifiedBadge;
