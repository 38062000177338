import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { STREET_VIEW_RADIUS } from '@/constants/map';
import { getGoogleMaps } from '@/utils/map';
import { areValidStreetviewParams } from '@/utils/objectStates';

import Icon from '@/components/icon/Icon';

class Streetview extends React.Component {
  constructor(props) {
    super(props);

    this.timer = 0;
    this.state = {
      status: null,
      init: false,
    };
    this.streetviewRef = React.createRef();
  }

  componentDidMount() {
    this.updateStreetView();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.position.lat !== this.props.position.lat || prevProps.position.lng !== this.props.position.lng) {
      this.updateStreetView();
    }
  }

  handlePovChange(pov) {
    const { povChange } = this.props;
    if (!this.state.init) {
      this.setState({ init: true });
    } else if (povChange) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        povChange(pov);
      }, 500);
    }
  }

  updateStreetView() {
    const { pov, position, myObject } = this.props;

    // Once the Google Maps API has finished loading, initialize the map
    getGoogleMaps().then((google) => {
      this.google = google;

      const svService = new google.maps.StreetViewService();
      const location = new google.maps.LatLng(position.lat, position.lng);

      svService.getPanoramaByLocation(location, STREET_VIEW_RADIUS, (data, status) => {
        if (status === google.maps.StreetViewStatus.OK) {
          //  Panorama was found
          const panorama = new google.maps.StreetViewPanorama(this.streetviewRef.current, {
            disableDefaultUI: true,
            source: google.maps.StreetViewSource.OUTDOOR,
          });
          //  Set found panorama
          panorama.setPano(data.location.pano);

          //  Set POV
          if (areValidStreetviewParams(pov)) {
            panorama.setPov(pov);
          } else {
            //  Compute heading from closest panorama to marker
            const compHeading = google.maps.geometry.spherical.computeHeading(data.location.latLng, location);
            panorama.setPov({
              heading: compHeading,
              pitch: 0,
            });
          }
          panorama.setVisible(true);
          if (myObject) {
            this.handlePovChange(panorama.getPov());
            panorama.addListener('pov_changed', () => this.handlePovChange(panorama.getPov()));
          }
          //  Set status
          this.setState({ status: google.maps.StreetViewStatus.OK });
        } else {
          this.setState({ status: google.maps.StreetViewStatus.ZERO_RESULTS });
        }
      });
    });
  }

  render() {
    const { className } = this.props;
    const { status } = this.state;

    const streetViewStyle = classNames(
      'street-view',
      {
        'street-view--hidden': status === 'ZERO_RESULTS',
      },
      className
    );

    return (
      <div className={streetViewStyle}>
        <div ref={this.streetviewRef} className="street-view__box" />
        {status === 'ZERO_RESULTS' && (
          <div className="street-view__no-results">
            <Icon name="street-view" />
            <div>Street view not available at this location</div>
          </div>
        )}
      </div>
    );
  }
}

Streetview.propTypes = {
  position: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }).isRequired,
  pov: PropTypes.shape({
    heading: PropTypes.number,
    pitch: PropTypes.number,
    zoom: PropTypes.number,
  }),
  className: PropTypes.string,
  myObject: PropTypes.bool,
  povChange: PropTypes.func,
};
Streetview.defaultProps = {
  pov: null,
  className: undefined,
  myObject: false,
  povChange: undefined,
};
export default Streetview;
