import { immerable } from 'immer';
import { Record, Map } from 'immutable';
import FavouriteType from '@city24/common/enums/realty/FavouriteType';
import { SortingOrder } from '@/types/collections';

/** @deprecated convert to standard JS */
export const NameValue = Record({ name: '', value: '', extra: Map() });
/** @deprecated convert to standard JS */
export const Favourite = Record({ id: '', type: FavouriteType.REALTY });

/** @deprecated use plain object with types/collections.ts -> AsyncData interface */
export class DataLoadProxy<T> {
  [immerable] = true;
  loading: boolean;

  loaded: boolean;

  data: T;

  constructor(data: T, loading = false, loaded = false) {
    this.loading = loading;
    this.loaded = loaded;
    this.data = data;
  }

  static loading<T>(data: T): DataLoadProxy<T> {
    return new DataLoadProxy(data, true, false);
  }

  static loaded<T>(data: T): DataLoadProxy<T> {
    return new DataLoadProxy(data, false, true);
  }

  isUnloaded() {
    return !this.loading && !this.loaded;
  }
}

export function createRange(start: number, end: number, step = 1): number[] {
  const nrs = [];
  for (let nr = start; step < 0 ? nr >= end : nr < end; nr += step) {
    nrs.push(nr);
  }
  return nrs;
}

export function getNextOrderForSorting(current: SortingOrder, noDefault = false): SortingOrder {
  switch (current) {
    case SortingOrder.asc:
      return SortingOrder.desc;
    case SortingOrder.desc:
      return noDefault ? SortingOrder.asc : SortingOrder.default;
    default:
      return SortingOrder.asc;
  }
}
