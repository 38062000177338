import React from 'react';
import { Collection, List } from 'immutable';
import PropTypes from 'prop-types';

import CheckboxField from '../checkbox/CheckboxField';

const propTypes = {
  options: PropTypes.instanceOf(Collection).isRequired,
  selected: PropTypes.instanceOf(Collection),
  getValue: PropTypes.func,
  t: PropTypes.func,
  namespace: PropTypes.string,
  translateOption: PropTypes.func,
};

const defaultProps = {
  selected: List(),
  getValue: null,
  t: (a) => a,
  namespace: '',
  translateOption: null,
};

function renderCount(option) {
  const count = option.getIn(['extra', 'count']);
  if (count) {
    return ` (${count})`;
  }
  return '';
}

class Multiselect extends React.Component {
  getValue = (checked, selectedVal) => {
    const { getValue, selected, gaEvent } = this.props;

    if (gaEvent) gaEvent(selectedVal, checked);

    if (getValue) {
      const combinedOptions = this.combineOptionGroups();

      let newSelected = selected.filter((option) => option.get('value') !== selectedVal);
      if (checked) {
        newSelected = newSelected.push(
          combinedOptions.find((currentOption) => currentOption.get('value') === selectedVal)
        );
      }

      getValue(newSelected);
    }
  };

  combineOptionGroups() {
    const { options } = this.props;

    if (this.isOptionGroup()) {
      return options.flatMap((optionGroup) => optionGroup.get('options'));
    }
    return options;
  }

  isOptionGroup() {
    const { options } = this.props;
    return options.getIn([0, 'options']);
  }

  translateOption(option) {
    const { t, translateOption, namespace } = this.props;
    return (
      (translateOption && translateOption(option)) ||
      t(namespace ? `${namespace}:${option.get('name')}` : option.get('name'))
    );
  }

  renderOption = (option) => {
    const { selected } = this.props;

    return (
      <CheckboxField
        key={option.get('value')}
        id={option.get('value')}
        className="multiselect__option"
        value={option.get('value')}
        text={`${this.translateOption(option)}${renderCount(option)}`}
        checked={!!selected.find((selectedVal) => selectedVal.get('value') === option.get('value'))}
        getValue={this.getValue}
      />
    );
  };

  render() {
    const { options, t } = this.props;

    return (
      <div className="multiselect">
        {options.map((option) => {
          if (option.get('options')) {
            return (
              <div key={option.get('value')}>
                <div className="multiselect__option-group">{t(option.get('name'))}</div>
                {option.get('options').map(this.renderOption)}
              </div>
            );
          }
          return this.renderOption(option);
        })}
      </div>
    );
  }
}

Multiselect.propTypes = propTypes;
Multiselect.defaultProps = defaultProps;

export default Multiselect;
