export const languagesPrefix = '/(et|en|lv|ru)?';
const myObjectRoute = '/my-object/:id';
const infoRoute = '/info';

export const ROUTES = {
  root: '/',
  login: '/login',
  registration: '/registration',
  objectResults: '/real-estate-search',
  objectDetail: '/real-estate/:typeCombination/:location/:id',
  objectDetailShort: '/real-estate/:id',
  projectDetail: '',
  modularHouseDetail: '',
  projectDetailShort: '/new-project/:id',
  modularHouseDetailShort: '/modular-house/:id',
  myObjects: '/my-objects',
  favourites: '/favourites',
  mySearches: '/my-searches',
  brokerSearch: '/broker-search',
  companySearch: '/company-search',
  newProjects: '/new-projects',
  companyNewProjects: '/company/:id/new-projects',
  passwordReset: '/password-reset/:hash',
  accountVerification: '/account-verification/:hash',
  brokerDetail: '/broker-detail/:id/:slug?/:subpage?',
  companyDetail: '/company-detail/:id/:slug?/:subpage?',
  myObject: myObjectRoute,
  myObjectCategory: `${myObjectRoute}/category`,
  myObjectLocation: `${myObjectRoute}/location`,
  myObjectInfo: `${myObjectRoute}/info`,
  myObjectImages: `${myObjectRoute}/images`,
  myObjectPackages: `${myObjectRoute}/packages`,
  myObjectConfirm: `${myObjectRoute}/confirm`,
  myObjectComplete: `${myObjectRoute}/complete`,
  payment: '/payment',
  invoices: `/invoices`,
  infoPages: infoRoute,
  brokerage: `${infoRoute}/brokerage`,
  energyCertificate: `${infoRoute}/energy-certificate`,
  termsOfUse: `${infoRoute}/terms-of-use`,
  privacyRegulations: `${infoRoute}/privacy-regulations`,
  personalDataProcessing: `${infoRoute}/personal-data-processing`,
  pricelist: `${infoRoute}/pricelist`,
  businessClientPricelist: `${infoRoute}/pricelist/business-client`,
  bannersPricelist: `${infoRoute}/bannerid`,
  services: `${infoRoute}/services`,
  goodBroker: `${infoRoute}/good-broker`,
  joiningCity24: `${infoRoute}/joining-city24`,
  faq: `${infoRoute}/faq`,
  cookiePolicy: `${infoRoute}/cookie-policy`,
  contacts: `${infoRoute}/contacts`,
  city24Statistics: `${infoRoute}/city24-statistics`,
  virtualTourPromo: '/virtual-tour',
  accountPromo: '/city24-account',
  news: '/real-estate-news/:tag?',
  newsArticle: '/real-estate-news-story/:id/:slug?/:preview?',
  brokerRequest: '/broker-request',
  rateBroker: '/rate-broker/:id',
  settings: '/settings',
  adminTools: '/admin-tools',
  financeCenter: '/finance-center',
  loanApplication: '/finance-center/apply-for-loan/:type/:id?',
  loanFeedback: '/finance-center/feedback',
  starOfTheYear: '/parim-maakler/:year?',
  newsletterUnsubscribe: '/unsubscribe/newsletter',
  searchesUnsubscribe: '/unsubscribe/searches',
  hotOffersHousesAndLands: '/majas-un-zemes',
  openDays: '/atverto-durvju-dienas',
};

ROUTES.projectDetail = ROUTES.objectDetail;
ROUTES.modularHouseDetail = ROUTES.objectDetail;

export const LINKS = {
  facebook: PORTAL_EE ? 'https://www.facebook.com/city24' : 'https://www.facebook.com/City24.lv',
  twitter: PORTAL_EE ? '' : 'https://twitter.com/city24lv',
  instagram: PORTAL_EE ? 'https://www.instagram.com/city24.ee_kinnisvaraportaal' : '',
  cadasterSearch: PORTAL_EE ? 'https://xgis.maaamet.ee/ky/' : 'https://www.kadastrs.lv/',
  estateRegistrySearch: 'https://kinnistusraamat.rik.ee/detailparing/Avaleht.aspx',
  energyCertificateSearch: 'https://www.ehr.ee/app/esileht?0',
  insertAdBroker: PORTAL_EE ? 'https://pro.city24.ee' : 'https://pro.city24.lv',
};

const routeConfig = (Object.keys(ROUTES) as Array<keyof typeof ROUTES>).reduce((mapped, route) => {
  // eslint-disable-next-line no-param-reassign
  mapped[route] = `${languagesPrefix}${ROUTES[route]}`;
  return mapped;
}, {} as typeof ROUTES);

export function isPageviewTrackingEnabled(route: string) {
  return !/^\/(password-reset|account-verification|unsubscribe)\//.test(route);
}

export default routeConfig;
