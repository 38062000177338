import React from 'react';

import tetIcon from '@/img/tet.svg';
import tetElectricityIcon from '@/img/tet-e.svg';
import tetInternetIcon from '@/img/tet-i.svg';
import tetGrayIcon from '@/img/tet-gray.svg';
import teliaIcon from '@/img/telia.svg';
import getAProIcon from '@/img/getapro.svg';
import elektrumIcon from '@/img/elektrum.svg';
import alteroIcon from '@/img/altero.svg';
import googleIcon from '@/img/google_icon.svg';
import alexelaIcon from '@/img/alexela_logo.svg';
import inbankIcon from '@/img/inbank-logo-black.svg';
import estoIcon from '@/img/esto-logo.svg';

export const imageIcons = {
  tet: tetIcon,
  'tet-e': tetElectricityIcon,
  'tet-i': tetInternetIcon,
  'tet-gray': tetGrayIcon,
  telia: teliaIcon,
  'get-a-pro': getAProIcon,
  elektrum: elektrumIcon,
  altero: alteroIcon,
  'google-color': googleIcon,
  alexela: alexelaIcon,
  inbank: inbankIcon,
  esto: estoIcon,
};

interface Props {
  name: string;
  className?: string;
}

const Icon = ({ name, className = '' }: Props) => {
  if (name in imageIcons) {
    const image = imageIcons[name as keyof typeof imageIcons];
    const imageUrl = image.src ?? image;
    return (
      <span className={`image-icon icon icon-${name} ${className}`} style={{ backgroundImage: `url(${imageUrl})` }} />
    );
  }

  return <span className={`icon icon-${name} ${className}`} />;
};

export default Icon;
