import api from '@/api/City24Api';
import {
  SET_CONSUMER_LOAN_CALC,
  SET_HOME_LOAN_CALC,
  SET_MAX_LOAN_CAPABILITY_CALC,
  SET_NOTARIOUS_COST_CALC,
} from '@/constants/actions';
import { actions } from '@/reducers/calculators';

export const setHomeLoanCalc = (payload) => {
  return {
    type: SET_HOME_LOAN_CALC,
    payload,
  };
};

export const setConsumerLoanCalc = (payload) => {
  return {
    type: SET_CONSUMER_LOAN_CALC,
    payload,
  };
};

export const setMaxLoanCapabilityCalc = (payload) => {
  return {
    type: SET_MAX_LOAN_CAPABILITY_CALC,
    payload,
  };
};

export const setNotariousCostCalc = (payload) => {
  return {
    type: SET_NOTARIOUS_COST_CALC,
    payload,
  };
};

export const fetchEuriborRate = () => {
  return (dispatch) => {
    return api
      .getEuriborRate()
      .then((res) => (res.ok ? res.json() : []))
      .then((rate) => {
        return dispatch(actions.setEuriborRate(Number(rate.value ?? 0).toFixed(2)));
      });
  };
};
