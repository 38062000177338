import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

class LabelGroup extends React.Component {
  render() {
    const { children, className } = this.props;

    const labelGroupStyle = classNames(
      {
        'label-group': true,
      },
      className
    );

    return <div className={labelGroupStyle}>{children}</div>;
  }
}

LabelGroup.propTypes = propTypes;
LabelGroup.defaultProps = defaultProps;

export default LabelGroup;
