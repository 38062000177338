import React, { useCallback, useEffect, useState } from 'react';
import ReactRecaptcha from 'react-google-recaptcha';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Trans, useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/nextjs';

import api from '@/api/City24Api';
import { identity } from '@/utils/helpers';
import Anchor from '@/components/link/Anchor';

export interface RecaptchaProps {
  badge?: boolean;
  verify?: boolean;
  onVerified: (verified: boolean, token: string, version: number) => void;
  skipBackendVerification?: boolean | ((token: string) => void);
  forceV2?: boolean;
}

function Recaptcha({
  badge = false,
  verify = true,
  onVerified,
  skipBackendVerification = false,
  forceV2 = false,
}: RecaptchaProps): React.ReactElement {
  const [t] = useTranslation();
  const [reVerify, setReverification] = useState(false);

  useEffect(() => {
    if (badge) {
      document.body.classList.add('show-recaptcha');
      return () => document.body.classList.remove('show-recaptcha');
    }
    return identity;
  }, [badge]);

  const verifyVersion2 = useCallback(
    (token: string) => {
      const callback = (verified: boolean) => onVerified(verified, token, 2);
      if (skipBackendVerification) {
        if (typeof skipBackendVerification === 'function') {
          skipBackendVerification(token);
        }
        callback(true);
      } else {
        api.user.verifyReCaptcha({ token, version: 2 }).then((res) => {
          if (res.ok) {
            res.json().then((result) => {
              if (result.success) {
                callback(true);
              } else {
                callback(false);
                Sentry.withScope((scope) => {
                  scope.setTag('recaptcha', 'googleV2');
                  scope.setExtras({ response: result });
                  Sentry.captureMessage('Google Recaptcha V2 failed verification', 'error');
                });
              }
            });
          } else {
            // If verification request fails, assume verified
            callback(true);
          }
        });
      }
    },
    [onVerified]
  );

  const verifyVersion3 = useCallback(
    (token: string) => {
      const callback = (verified: boolean) => onVerified(verified, token, 3);
      if (skipBackendVerification) {
        if (typeof skipBackendVerification === 'function') {
          skipBackendVerification(token);
        }
        callback(true);
      } else {
        api.user.verifyReCaptcha({ token, version: 3 }).then((res) => {
          if (res.ok) {
            res.json().then((result) => {
              if (result.success && result.score >= Recaptcha.BOT_SCORE) {
                callback(true);
              } else {
                setReverification(true);
                Sentry.withScope((scope) => {
                  scope.setTag('recaptcha', 'googleV3');
                  scope.setExtras({ response: result });
                  Sentry.captureMessage('Google Recaptcha V3 failed verification', 'error');
                });
              }
            });
          } else {
            // If verification request fails, assume verified
            callback(true);
          }
        });
      }
    },
    [onVerified]
  );

  return (
    <div className="recaptcha__block">
      {!reVerify && !badge && !forceV2 && (
        <Trans
          t={t}
          i18nKey="recaptcha.inlineInfo"
          components={{
            privLink: <Anchor href="https://policies.google.com/privacy">Privacy Policy</Anchor>,
            tosLink: <Anchor href="https://policies.google.com/terms">Terms of Service</Anchor>,
          }}
        />
      )}
      {verify && !forceV2 && <GoogleReCaptcha onVerify={verifyVersion3} />}
      {verify && (reVerify || forceV2) && (
        <ReactRecaptcha onChange={verifyVersion2} sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_V2_SITE_KEY} />
      )}
    </div>
  );
}
Recaptcha.BOT_SCORE = 0.3;

export default Recaptcha;
