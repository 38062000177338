import { formatDate } from './formatting';

export function getFormattedPublishingRange(days: number, startDate = new Date()): string {
  if (days <= 0) {
    return '';
  }
  const endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + days);
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}
