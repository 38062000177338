import React from 'react';
import PropTypes from 'prop-types';
import { fromJS, Map, Collection } from 'immutable';
import ReactGA from '@/utils/ga';

import RadioGroup from '../../radio/RadioGroup';
import FilterFooter from '../Filter/FilterFooter';
import { DATE_ADDED_OPTIONS } from '@/constants/filters';
import { GA_SEARCH } from '@/constants/ga';

const propTypes = {
  footerHidden: PropTypes.bool,
  dateAdded: PropTypes.instanceOf(Collection),
  setDateAdded: PropTypes.func,
  handleDateAdded: PropTypes.func,
};

const defaultProps = {
  footerHidden: false,
  dateAdded: '',
  setDateAdded: null,
  handleDateAdded: null,
};
class DateAdded extends React.Component {
  getDateAdded = (dateAdded) => {
    const { handleDateAdded } = this.props;
    this.gaEventHandler(dateAdded.get('gaLabel'));
    if (handleDateAdded) handleDateAdded(dateAdded);
  };

  gaEventHandler = (label) => {
    ReactGA.event({
      category: GA_SEARCH,
      action: 'select_dateAdded',
      label,
    });
  };

  render() {
    const { dateAdded, t } = this.props;

    return (
      <>
        <h4 className="filter__heading">{t('search.dateAdded')}</h4>
        <RadioGroup
          options={DATE_ADDED_OPTIONS}
          selected={dateAdded}
          name="date-added"
          getValue={this.getDateAdded}
          t={t}
        />
      </>
    );
  }
}

DateAdded.propTypes = propTypes;
DateAdded.defaultProps = defaultProps;

export default DateAdded;
