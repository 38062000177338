import React from 'react';
import Immutable, { Collection } from 'immutable';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { linkUrl } from '@/utils/helpers';
import List from '@/components/list/List';
import ListItem from '@/components/list/ListItem';
import ContactPhone from '../ContactPhone/ContactPhone';

const propTypes = {
  t: PropTypes.func.isRequired,
  address: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.instanceOf(Collection),
  ]),
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Collection)]),
  www: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Collection)]),
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  instagram: PropTypes.string,
  linkedin: PropTypes.string,
  gaEvent: PropTypes.func,
  className: PropTypes.string,
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.instanceOf(Collection)]),
  sendStatistics: PropTypes.func,
  showPhone: PropTypes.bool, // this prop controls whether to show number or hide it with asterisks
  renderPhone: PropTypes.bool, // this prop controls whether to render phone number at all
};

const defaultProps = {
  address: '',
  phone: '',
  www: '',
  facebook: '',
  twitter: '',
  instagram: '',
  linkedin: '',
  gaEvent: null,
  email: '',
  className: false,
  sendStatistics: null,
  showPhone: false,
  renderPhone: true,
};

function convertToArray(value) {
  if (!value) {
    return [];
  }
  if (Array.isArray(value)) {
    return value;
  }

  if (Immutable.isList(value)) {
    return value.toArray();
  }

  return [value];
}

const formatValue = (value) => {
  if (Array.isArray(value) || Immutable.isList(value)) {
    return <div className="list__sub-list">{convertToArray(value)}</div>;
  }
  return value;
};

function renderContact(icon, value) {
  if (!value || value.length === 0) {
    return null;
  }

  return <ListItem icon={icon}>{formatValue(value)}</ListItem>;
}

const Contact = (props) => {
  const {
    address,
    www,
    facebook,
    twitter,
    instagram,
    linkedin,
    className,
    email,
    renderEmail,
    phone,
    showPhone,
    renderPhone,
    disableLinks,
    gaEvent,
    t,
    sendStatistics,
  } = props;

  return (
    <div className={classNames('contact', className)}>
      <List color="gray">
        {renderContact('map-pin', address)}
        {renderContact(
          'phone',
          renderPhone && (phone.size || phone.length) && (
            <ContactPhone
              t={t}
              phone={phone}
              showNr={showPhone}
              disabled={disableLinks}
              gaEvent={gaEvent}
              sendStatistics={sendStatistics}
            />
          )
        )}
        {renderContact(
          'envelope',
          (email.size || email.length) &&
            convertToArray(email).map(
              renderEmail ||
                ((mail) =>
                  disableLinks ? (
                    <div key={mail}>{mail}</div>
                  ) : (
                    <a key={mail} target="_blank" rel="noopener noreferrer" href={`mailto:${mail}`}>
                      {mail}
                    </a>
                  ))
            )
        )}
        {renderContact(
          'house',
          www.length &&
            convertToArray(www).map((url) => (
              <a key={url} target="_blank" rel="noopener noreferrer nofollow" href={linkUrl(url)}>
                {url}
              </a>
            ))
        )}
        {renderContact(
          'facebook',
          facebook && (
            <a target="_blank" rel="noopener noreferrer nofollow" href={linkUrl(facebook)}>
              {t('common.facebook')}
            </a>
          )
        )}
        {renderContact(
          'twitter',
          twitter && (
            <a target="_blank" rel="noopener noreferrer nofollow" href={linkUrl(twitter)}>
              {t('common.twitter')}
            </a>
          )
        )}
        {renderContact(
          'instagram',
          instagram && (
            <a target="_blank" rel="noopener noreferrer nofollow" href={linkUrl(instagram)}>
              {t('common.instagram')}
            </a>
          )
        )}
        {renderContact(
          'linked-in',
          linkedin && (
            <a target="_blank" rel="noopener noreferrer nofollow" href={linkUrl(linkedin)}>
              {t('common.linkedin')}
            </a>
          )
        )}
      </List>
    </div>
  );
};

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact;
