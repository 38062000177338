import React from 'react';
import { Collection, List } from 'immutable';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ReactGA from '@/utils/ga';
import Icon from '../icon/Icon';

const propTypes = {
  extended: PropTypes.bool,
  small: PropTypes.bool,
  multiple: PropTypes.bool,
  options: PropTypes.instanceOf(Collection.Indexed).isRequired,
  selected: PropTypes.instanceOf(Collection),
  getValue: PropTypes.func.isRequired,
  t: PropTypes.func,
  gaCategory: PropTypes.string,
  gaAction: PropTypes.string,
  gaLabels: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
};

const defaultProps = {
  extended: false,
  small: false,
  multiple: false,
  selected: null,
  t: (a) => a,
  gaCategory: '',
  gaAction: '',
  gaLabels: null,
  onClick: (a) => a,
};

class InlineSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: '',
    };
  }

  getNewSelectedOption(option) {
    const { multiple, selected } = this.props;

    if (multiple) {
      let selectedList = selected;
      if (!(selected instanceof Collection.Indexed)) {
        selectedList = List(selected);
      }
      const selectedIndex = selectedList.findIndex((selectedOpt) => selectedOpt === option);
      if (selectedIndex === -1) {
        return selectedList.push(option);
      }
      return selectedList.delete(selectedIndex);
    }

    return option;
  }

  handleFocus = (e) => {
    const value = e.target.getAttribute('value');
    this.setState({ focused: value });
  };

  handleBlur = () => {
    this.setState({ focused: '' });
  };

  handleClick = (option) => {
    const { getValue, multiple, onClick } = this.props;
    const selected = this.getNewSelectedOption(option);
    this.gaEvent(option.get('value'), multiple && !selected.includes(option));
    getValue(selected);
    this.setState({ focused: '' });
    onClick();
  };

  translateOption = (option) => {
    const { translateOption, t } = this.props;
    return translateOption ? translateOption(option) : t(option.get('name'));
  };

  gaEvent(name, removed) {
    const { gaCategory, gaAction, gaLabels } = this.props;

    if (gaCategory) {
      let action = gaAction || 'select';
      if (removed) {
        action = `un${action}`;
      }

      ReactGA.event({
        category: gaCategory,
        action,
        label: gaLabels ? gaLabels[name] : name,
      });
    }
  }

  renderOption = (option) => {
    const { multiple, selected, noLabels, grid } = this.props;
    const { focused } = this.state;

    let isSelected = false;
    if (selected && !selected.isEmpty()) {
      if (multiple) {
        isSelected = !!selected.find((selectedOption) => selectedOption.get('value') === option.get('value'));
      } else {
        isSelected = option.get('value') === selected.get('value');
      }
    }

    const inlineSelectOptionStyle = classNames('inline-select__option', {
      'inline-select__option--selected': isSelected,
      'inline-select__option--focused': focused === option.get('value'),
    });

    return (
      <button
        type="button"
        key={option.get('value')}
        className={inlineSelectOptionStyle}
        value={option.get('value')}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onClick={() => {
          this.handleClick(option);
        }}
      >
        {option.get('icon') && <Icon name={option.get('icon')} className={classNames({ 'icon--fw': !grid })} />}
        <span className="inline-select__text">{noLabels ? '' : this.translateOption(option)}</span>
      </button>
    );
  };

  render() {
    const { extended, multiple, small, large, raised, blur, minimal, tabs, grid, className, options, luminor } =
      this.props;

    const inlineSelectStyle = classNames(
      {
        'inline-select': true,
        'inline-select--sm': small,
        'inline-select--lg': large,
        'inline-select--extended': extended,
        'inline-select--multiple': multiple,
        'inline-select--raised': raised,
        'inline-select--blur': blur,
        'inline-select--minimal': minimal,
        'inline-select--tabs': tabs,
        'inline-select--grid': grid,
        'inline-select--luminor': luminor,
      },
      className
    );

    return <div className={inlineSelectStyle}>{options.map(this.renderOption)}</div>;
  }
}

InlineSelect.propTypes = propTypes;
InlineSelect.defaultProps = defaultProps;

export default InlineSelect;
