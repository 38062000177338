import React, { useEffect, useRef } from 'react';
import type { TFunction } from 'next-i18next';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { formatDistance } from '@/utils/formatting';
import { getMapNearbyPlaces } from '@/components/map/mapSelectors';

import Icon from '@/components/icon/Icon';
import Transport from '@/components/Transport/Transport';
import AccordionHead from '@/components/accordion/AccordionHead';
import AccordionBody from '@/components/accordion/AccordionBody';
import Accordion from '@/components/accordion/Accordion';

import css from './NearbyPlaces.module.scss';
import { fetchNearbyPlaces } from '@/components/map/Map/mapActions';

interface Props {
  t: TFunction;
  latitude?: number;
  longitude?: number;
  maxNrToShow?: number;
  isPrint?: boolean;
}

function NearbyPlaces({
  t,
  latitude,
  longitude,
  maxNrToShow = NearbyPlaces.MAX_CATEGORY_ITEMS,
  isPrint = false,
}: Props) {
  const dispatch = useAppDispatch();
  const nearbyPlaces = useAppSelector(getMapNearbyPlaces);
  const refs = useRef<Record<keyof typeof nearbyPlaces, React.Ref<HTMLDivElement> | null>>({
    transport: null,
    school: null,
    kindergarden: null,
  });

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(fetchNearbyPlaces(latitude, longitude));
    }
  }, [latitude, longitude]);

  const renderCategoryItem = (place: (typeof nearbyPlaces)[keyof typeof nearbyPlaces][number]) => {
    const { id, name, distance } = place;
    return (
      <div key={id} className={css.poi}>
        <p className={css.name}>{name}</p>
        <span className={css.distance}>{formatDistance(distance, 0)}</span>
        {'routes' in place && (
          <div className={css.routes}>
            {(Object.keys(place.routes) as (keyof typeof place.routes)[]).map((routeType) => {
              const lineNrs = place.routes[routeType]!.map((line) => line.line_nr);

              return <Transport key={routeType} type={routeType} lines={lineNrs} t={t} />;
            })}
          </div>
        )}
      </div>
    );
  };

  const takeNr = Math.min(maxNrToShow, NearbyPlaces.MAX_CATEGORY_ITEMS);

  const renderCategory = (cat: keyof typeof nearbyPlaces) => {
    const items = nearbyPlaces[cat];
    const head = items.slice(0, takeNr).map(renderCategoryItem);

    if (items.length > NearbyPlaces.MAX_CATEGORY_ITEMS) {
      const divRef = refs.current[cat];
      return (
        <div ref={divRef}>
          {head}
          {!isPrint && (
            <Accordion className={css.accordion} scrollToItem={divRef} scrollToOffset={150}>
              <AccordionBody>{items.slice(takeNr + 1).map(renderCategoryItem)}</AccordionBody>

              <AccordionHead>
                <div className={css.more}>{t('common.showMore')}</div>
                <div className={css.less}>{t('common.showLess')}</div>
              </AccordionHead>
            </Accordion>
          )}
        </div>
      );
    }

    return head;
  };

  return (
    <>
      {(Object.keys(nearbyPlaces) as (keyof typeof nearbyPlaces)[])
        .filter((category) => nearbyPlaces[category].length > 0)
        .map((category) => {
          return (
            <div key={category} className={`${css.group}${isPrint ? ' is-print' : ''}`}>
              <div className={css.groupTitle}>
                <Icon name={NearbyPlaces.iconName[category]} className={`${css.groupIcon} ${css[category]}`} />
                {t(`objectDetail.nearbyPlaces.${category}`)}
              </div>
              {renderCategory(category)}
            </div>
          );
        })}
    </>
  );
}

NearbyPlaces.MAX_CATEGORY_ITEMS = 5;
NearbyPlaces.iconName = {
  transport: 'transport',
  school: 'school',
  kindergarden: 'kindergarden',
};

export default NearbyPlaces;
