import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { GAE_CLOSE_SEARCH_OPTIOINS } from '@/constants/ga';
import { getBrowser } from '@/selectors/appSelectors';

import Button from '@/components/button/Button';
import Tokens, { TokensProps } from '@/components/tokens/Tokens';

import Input from '../Input';
import Autocomplete from './Autocomplete';
import SearchAutocompleteResults, { SearchAutocompleteResultsProps } from './SearchAutocompleteResults';
import SimpleAutocompleteResults, { SimpleAutocompleteResultsProps } from './SimpleAutocompleteResults';

export type AutocompleteInputProps = (
  | ({
      autocomplete: 'simple';
      autocompleteResults: SimpleAutocompleteResultsProps['values'];
    } & Omit<SimpleAutocompleteResultsProps, 'values' | 'getValue'>)
  | ({
      autocomplete: 'search';
      autocompleteResults: SearchAutocompleteResultsProps['values'];
    } & Omit<SearchAutocompleteResultsProps, 'values' | 'getValue'>)
) & {
  onAutocomplete: (value: string) => void;
  getAutocompleteValue: (option: any) => void;
  gaAutoCompleteHandler?: (action?: string | null, label?: string | null) => void;
  gaEvent?: (action?: string | null, label?: string | null) => void;
  noFreeText: boolean;
  autocompletePlaceholderText?: string;
  cancelAutocomplete: () => void;
  children: React.ReactNode;
  getValue: (value: string) => void;
  clearButton?: boolean;
  minCharacters?: number;
  tokens?: TokensProps['tokens'];
  deleteToken?: TokensProps['deleteToken'];
  // <Tokens> props
  // <Input> props
};

function AutocompleteInput({
  autocomplete = 'search',
  onAutocomplete,
  getAutocompleteValue,
  autocompleteResults,
  gaAutoCompleteHandler,
  noFreeText,
  autocompletePlaceholderText,
  cancelAutocomplete,
  tokens,
  deleteToken,
  children,
  clearButton = false,
  minCharacters = 3,
  ...inputProps
}: AutocompleteInputProps): React.ReactElement {
  const { t, value, gaEvent, getValue, getSubmit, blur } = inputProps;

  const [autocompleteOpened, openAutocomplete] = useState(false);
  const closeAutocomplete = () => openAutocomplete(false);
  const [focused, focusInput] = useState(false);
  const browser = useSelector(getBrowser);

  let Results = SearchAutocompleteResults;
  if (autocomplete === 'simple') {
    Results = SimpleAutocompleteResults;
  }

  const tokensOutside = tokens && tokens.size > 0 && browser.lessThan.large;
  const tokensEl = tokens && (
    <Tokens
      light={blur}
      noWrap={!tokensOutside}
      outside={tokensOutside}
      tokens={tokens}
      deleteToken={deleteToken}
      gaEvent={GAE_CLOSE_SEARCH_OPTIOINS}
    />
  );

  const autocompleteCancelClick = useCallback(() => {
    cancelAutocomplete();
    gaAutoCompleteHandler?.(null, 'close_button');
    closeAutocomplete();
  }, [cancelAutocomplete, gaAutoCompleteHandler]);

  const inputElement = (
    <Input
      {...inputProps}
      className="input--tokens"
      onFocus={() => focusInput(true)}
      onBlur={() => focusInput(false)}
      refocus={autocompleteOpened}
      getValue={(val) => {
        getValue(val);
        if (val.length >= minCharacters) {
          openAutocomplete(true);
          onAutocomplete(val);
        } else if (browser.greaterThan.medium) {
          closeAutocomplete();
        }
      }}
      getSubmit={(val) => {
        getSubmit(val);
        closeAutocomplete();
      }}
      onKeyDown={(event) => {
        if (!value && event.key === 'Backspace' && tokens?.size) {
          const last = Array.from(tokens.values()).pop()!;
          deleteToken?.(last.value);
        }
      }}
      preInput={!tokensOutside && tokensEl}
      buttons={
        autocompleteOpened &&
        browser.lessThan.large && (
          <Button link className="input__cancel" text={t('common.cancel')} onClick={autocompleteCancelClick} />
        )
      }
      postInput={
        clearButton &&
        value.length > 0 && (
          <Button
            icon="close"
            className="autocomplete__clear"
            extraSmall
            onClick={browser.lessThan.large ? cancelAutocomplete : autocompleteCancelClick}
          />
        )
      }
    >
      <Autocomplete
        t={t}
        fullscreen={browser.lessThan.large}
        opened={autocompleteOpened}
        inputValue={value}
        withKeyboard={focused && isMobile}
        insertInputValue={(event) => {
          gaAutoCompleteHandler?.(null, 'select_keyword');
          getSubmit(event.currentTarget.value);
          closeAutocomplete();
          gaEvent?.();
          event.preventDefault();
        }}
        showButton={!noFreeText && autocompleteResults.length === 0}
        placeholderToken={autocompletePlaceholderText}
      >
        <Results
          t={t}
          values={autocompleteResults}
          close={closeAutocomplete}
          getValue={getAutocompleteValue}
          gaEvent={gaAutoCompleteHandler}
        />
      </Autocomplete>
    </Input>
  );

  if (autocompleteOpened && browser.lessThan.large) {
    return ReactDOM.createPortal(
      <div className="input__autocomplete-wrap">{inputElement}</div>,
      document.getElementById('__next')
    );
  }
  return (
    <>
      {children}
      {inputElement}
      {tokensOutside && tokensEl}
    </>
  );
}

export default AutocompleteInput;
