import { isImmutable, Map } from 'immutable';
import queryString from 'query-string';

import { RESULTS_VIEW } from '@/constants';
import {
  CLEAR_SEARCH_TERM_DRAFT,
  SET_RESULT_LAYOUT,
  SET_SORTING,
  SET_OBJECT_RESULTS_PAGE,
  RESET_FILTERS,
  SET_FILTER_COUNT,
  SEARCH_OBJECTS,
  SET_AREA_SELECT,
  SEARCH_OBJECTS_INIT,
  DO_AUTOCOMPLETE,
  REMOVE_AUTOCOMPLETE_TERM,
  ADD_AUTOCOMPLETE_TERM,
  SET_SEARCH_URL,
  SET_RESULTS_LOADING,
  SET_SEARCH_LAST_RUN,
  SET_SEARCH_FORM,
  SEARCH_PREV_NEXT_OBJECTS,
  SET_MAP_OPENED,
  UPDATE_SEARCH_FILTERS,
  SET_SEARCH_RESULTS_PER_PAGE,
  SET_SEARCH_LAST_QUERY,
} from '@/constants/actions';
import { OBJECT_TYPES } from '@/constants/object';
import {
  SEARCH_URL_LABELS,
  PRICE_TYPE_OPTIONS,
  LOT_SIZE_TYPE_OPTIONS,
  ROOMS_OPTIONS,
  DATE_ADDED_OPTIONS,
  EXTRAS_OPTIONS,
} from '@/constants/filters';
import {
  OBJECT_TYPE_OPTIONS,
  TRANSACTION_TYPES_OPTIONS,
  BUILDING_MATERIAL_OPTIONS,
  CONDITION_OPTIONS,
  PURPOSE_OPTIONS,
  PROJECT_TYPE_OPTIONS,
  ENERGY_CERTIFICATE_OPTIONS,
  OBJECT_TYPE_OPTIONS_NP,
  OBJECT_TYPE_OPTION_PROJECT,
  CONSTRUCTION_PHASE_OPTIONS_NP,
} from '@/constants/attributes';
import SEO from '@/constants/seo.json';

import {
  composeAutocompleteQuery,
  composeSearchQuery,
  createLocationSearchTerm,
  getPaginationFromUrl,
} from '@/utils/search';
import { NameValue } from '@/utils/collections';
import { keywordTerm } from '@/utils/searchTerms';
import { parseUrlTypeCombination } from '@/utils/route';

import api, { paginatedQuery, parsePagination, getSearchAction, paginatedFromQuery } from '@/api/City24Api';
import { NotFoundError } from '@/errors/http';
import { composeBoundingBox } from '@/components/map/Map/mapActions';
import { getSearchFilters, getFiltersByObjectType, getObjectResultsPage } from './searchSelectors';
import { getActiveMapBounds } from '@/components/map/mapSelectors';

import { formInitialState } from './searchInitialStates';
import produce from 'immer';

const { SEO_TYPE_COMBINATIONS } = SEO;

export function updateSearchFilters(filters, resetPageNumber = true) {
  return {
    type: UPDATE_SEARCH_FILTERS,
    filters,
    resetPageNumber,
  };
}

export function setSearchResultsPerPage(resultsPerPage) {
  return {
    type: SET_SEARCH_RESULTS_PER_PAGE,
    resultsPerPage,
  };
}

export function setTransactionType(transactionType) {
  return updateSearchFilters({ transactionType });
}

export function setObjectType(objectType) {
  return updateSearchFilters({ objectType });
}

export function setSearchTerm(searchTerm) {
  return updateSearchFilters({ searchTerm });
}

export function setSearchTermDraft(searchTerm) {
  return updateSearchFilters({ searchTermDraft: searchTerm });
}

export function clearSearchTermDraft() {
  return {
    type: CLEAR_SEARCH_TERM_DRAFT,
  };
}

export function addAutocompleteTerm(term, resetPageNumber = true) {
  return {
    type: ADD_AUTOCOMPLETE_TERM,
    term,
    resetPageNumber,
  };
}

export function removeAutocompleteTerm(termKey) {
  return {
    type: REMOVE_AUTOCOMPLETE_TERM,
    termKey,
  };
}

export function setSearchTermLocation(id, type) {
  return (dispatch) => {
    return api.address
      .getRegionalUnit(id)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw new NotFoundError(`No ${type} with ID ${id} found`);
      })
      .then((mainUnit) => {
        dispatch(addAutocompleteTerm(createLocationSearchTerm(type, mainUnit), false));
      });
  };
}

export function setPriceRange(minPrice, maxPrice, priceType) {
  return updateSearchFilters({ minPrice, maxPrice, priceType });
}

export function setSizeRange(minSize, maxSize) {
  return updateSearchFilters({ minSize, maxSize });
}

export function setLotSizeRange(minLotSize, maxLotSize, lotSizeType) {
  return updateSearchFilters({ minLotSize, maxLotSize, lotSizeType });
}

export function setRooms(rooms) {
  return updateSearchFilters({ rooms });
}

export function setFromOwner(fromOwner) {
  return updateSearchFilters({ fromOwner });
}

export function setFloorRange(minFloor, maxFloor, onlyLastFloor) {
  return updateSearchFilters({ minFloor, maxFloor, onlyLastFloor });
}

export function setYearBuiltRange(minYearBuilt, maxYearBuilt) {
  return updateSearchFilters({ minYearBuilt, maxYearBuilt });
}

export function setEnergyCertificate(energyCertificate) {
  return updateSearchFilters({ energyCertificate });
}

export function setCondition(condition) {
  return updateSearchFilters({ condition });
}

export function setMaterial(material) {
  return updateSearchFilters({ material });
}

export function setExtras(extras) {
  return updateSearchFilters({ extras });
}

export function setProjectType(projectType) {
  return updateSearchFilters({ projectType });
}

export function setPurpose(purpose) {
  return updateSearchFilters({ purpose });
}

export function setVirtualTour(virtualTour) {
  return updateSearchFilters({ virtualTour });
}

export function setRentToOwn(rentToOwn) {
  return updateSearchFilters({ rentToOwn });
}

export function setOpenHouse(openHouse) {
  return updateSearchFilters({ openHouse });
}

export function setSpecialOffers(specialOffers) {
  return updateSearchFilters({ specialOffers });
}

export function setAuction(auction) {
  return updateSearchFilters({ auction });
}

export function setPricedrop(pricedrop) {
  return updateSearchFilters({ pricedrop });
}

export function setDateAdded(dateAdded) {
  return updateSearchFilters({ dateAdded });
}

export function setFilterCount(filterCount) {
  return {
    type: SET_FILTER_COUNT,
    filterCount,
  };
}

export function setResultLayout(layout, mobile) {
  return {
    type: SET_RESULT_LAYOUT,
    layout,
    mobile,
  };
}

export function setObjectResultsPage(objectResultsPage) {
  return {
    type: SET_OBJECT_RESULTS_PAGE,
    objectResultsPage,
  };
}

export function setSorting(sorting) {
  if (!isImmutable(sorting)) {
    sessionStorage.setItem('searchSort', JSON.stringify(sorting));
    return {
      type: SET_SORTING,
      sorting: Map(sorting),
    };
  }
  sessionStorage.setItem('searchSort', JSON.stringify(sorting.toJS()));
  return {
    type: SET_SORTING,
    sorting,
  };
}

export function setAreaSelect(county, parish, city, isDraft) {
  return {
    type: SET_AREA_SELECT,
    county,
    parish,
    city,
    isDraft,
  };
}

export function setLastRun(lastRun) {
  return {
    type: SET_SEARCH_LAST_RUN,
    lastRun,
  };
}

export function setResultsLoading(resultsLoading) {
  return {
    type: SET_RESULTS_LOADING,
    resultsLoading,
  };
}

export function setMapOpened(mapOpened) {
  return {
    type: SET_MAP_OPENED,
    mapOpened,
  };
}

export function searchObjects(itemsPerPage = null) {
  return (dispatch, getState) => {
    const state = getState();
    const filters = getSearchFilters(state);
    const bounds = getActiveMapBounds(state);
    const query = composeSearchQuery(filters, getFiltersByObjectType(state));
    const page = getObjectResultsPage(state) || 1;

    const [action, resultsType] = getSearchAction(query);

    dispatch({
      type: SEARCH_OBJECTS_INIT,
      searchFilters: filters,
    });

    const apiQuery = { ...query, adReach: true };
    if (bounds.n && bounds.w && bounds.s && bounds.e) {
      apiQuery.boundingBox = composeBoundingBox(bounds.n, bounds.w, bounds.s, bounds.e);
    }

    const fetchPaginated = (pg) => {
      return action(paginatedQuery(itemsPerPage || filters.objectResultsToShow, pg, apiQuery))
        .then((res) => (res.ok ? Promise.all([res.json(), parsePagination(res)]) : [[], null]))
        .then(([objects, pagination]) => {
          sessionStorage.setItem('searchQuery', JSON.stringify({ query, pagination }));
          // When requested page has no results but the total nr of objects is not zero,
          // create a new request for first page
          if (pg > 1 && pagination && pagination.total > 0 && objects.length === 0) {
            return fetchPaginated(1);
          }
          return dispatch({
            type: SEARCH_OBJECTS,
            objects,
            resultsType,
            pagination,
            lastQuery: query,
            page: pg,
          });
        })
        .catch((err) => {
          // In case of error, stop loading and rethrow
          dispatch(setResultsLoading(false));
          throw err;
        });
    };
    return fetchPaginated(page);
  };
}

function simpleSearchQuery(query) {
  const [action, resultsType] = getSearchAction(query);
  return action(query).then((res) =>
    res.ok ? Promise.all([res.json(), parsePagination(res), resultsType]) : [[], null, resultsType]
  );
}

export function searchObjectsOnPage(lastQuery, itemsPerPage, page) {
  return (dispatch) => {
    const query = paginatedQuery(itemsPerPage, page, { ...lastQuery, adReach: true });
    dispatch({ type: SEARCH_OBJECTS_INIT });
    return simpleSearchQuery(query).then(([objects, pagination, resultsType]) => {
      sessionStorage.setItem('searchQuery', JSON.stringify({ query: lastQuery, pagination }));
      return dispatch({
        type: SEARCH_OBJECTS,
        objects,
        resultsType,
        pagination,
        lastQuery,
        page,
      });
    });
  };
}

/**
 *
 * @param {object} query Search query for finding objects
 * @param {number} itemsPerPage How many objects to get
 * @param {number} from From which number to find objects
 * @param {boolean} afterFrom Should we go up or down from 'from' point
 */
export function searchPrevNextObjects(query, itemsPerPage, from = 0, afterFrom = true) {
  const fromParam = afterFrom ? from : from - itemsPerPage;
  return (dispatch) => {
    return simpleSearchQuery(
      paginatedFromQuery(itemsPerPage, fromParam, {
        ...query,
        g: ['minimalInfo', 'addressNamesOnly'],
      })
    ).then(([objects, pagination]) => {
      return dispatch({
        type: SEARCH_PREV_NEXT_OBJECTS,
        objects,
        pagination,
        afterFrom,
      });
    });
  };
}

export function doAutocompleteSearch(keyword) {
  return (dispatch, getState) => {
    const state = getState();
    const filters = getSearchFilters(state);
    const query = composeAutocompleteQuery(filters, getFiltersByObjectType(state));

    return api
      .doAutocompleteSearch({ ...query, q: keyword })
      .then((res) => (res.ok ? res.json() : []))
      .then((results) => {
        dispatch({
          type: DO_AUTOCOMPLETE,
          results,
        });
      });
  };
}

export function resetSearchForm(filters) {
  return {
    type: RESET_FILTERS,
    filters,
  };
}

// Only for transitional period to SSR
export function setLastQuery(query) {
  return {
    type: SET_SEARCH_LAST_QUERY,
    query,
  };
}

export function setSearchURL(searchParamsURL) {
  sessionStorage.setItem('searchURL', searchParamsURL);
  return {
    type: SET_SEARCH_URL,
    searchParamsURL,
  };
}

function decodeUrlAttribute(attr) {
  // hind
  // hind=90000-12000
  // hind=m2-1200-1500 // =option-min-max
  // otsisona=rahumae,liiva,jarve
  const tokens = attr.split('=');
  const key = tokens.shift();
  // boolean attribute
  if (tokens.length === 0) {
    return { key, values: true };
  }
  const values = tokens[0].split(',');

  // Don't parse these values
  if (
    [
      SEARCH_URL_LABELS.keyword,
      SEARCH_URL_LABELS.locationId,
      SEARCH_URL_LABELS.dateAdded,
      SEARCH_URL_LABELS.objectTypes,
      SEARCH_URL_LABELS.constructionPhase,
    ].includes(key)
  ) {
    return { key, values };
  }

  return {
    key,
    values: values.map((value) => {
      // check numeric values
      const number = value.split('-');
      // not a number value
      if (number.length === 1) {
        return number[0];
      }

      const max = number.pop() || null;
      const min = number.pop() || null;
      const option = number.pop() || null;
      return { max, min, option };
    }),
  };
}

function parseURLvalue(value, name) {
  return value === 'na' ? formInitialState.get(name) : value;
}

export function setSearchFromURL(url, rawQuery, loadView) {
  const query = queryString.parse(rawQuery);
  const searchFilters = url.split('/');
  const filters = searchFilters.reduce(
    (obj, filter) => {
      const { key, values } = decodeUrlAttribute(filter);
      // eslint-disable-next-line no-param-reassign
      obj[key] = values;
      return obj;
    },
    {
      typeCombination: searchFilters[0],
    }
  );

  return (dispatch) => {
    let objectType;
    const filtersToUpdate = {};

    const [transactionTypeValue, objectTypeValue] = parseUrlTypeCombination(filters.typeCombination);
    if (transactionTypeValue && objectTypeValue) {
      objectType =
        OBJECT_TYPE_OPTION_PROJECT.get('value') === objectTypeValue
          ? OBJECT_TYPE_OPTION_PROJECT
          : OBJECT_TYPE_OPTIONS.find((option) => option.get('value') === objectTypeValue);

      filtersToUpdate.objectType = objectType;
      // SET MOBILE RESULTS VIEW
      if (loadView) {
        dispatch(setResultLayout(loadView));
      } else if (objectTypeValue === OBJECT_TYPES.NewProject) {
        dispatch(setResultLayout(RESULTS_VIEW.grid));
      } else {
        dispatch(setResultLayout(RESULTS_VIEW.list));
      }

      if (!objectType) {
        objectType = OBJECT_TYPE_OPTIONS.first();
        console.warn(`Type combination ${filters.typeCombination} is unsupported`);
      }

      let transactionType = TRANSACTION_TYPES_OPTIONS.find((option) => {
        return option.get('value') === transactionTypeValue;
      });
      if (!transactionType) {
        transactionType = TRANSACTION_TYPES_OPTIONS.first();
        console.warn(`Type combination ${filters.typeCombination} is unsupported`);
      }
      filtersToUpdate.transactionType = transactionType;
    }

    if (objectType && objectType.get('value') === OBJECT_TYPES.NewProject) {
      if (filters[SEARCH_URL_LABELS.objectTypes]) {
        const combinations = SEO_TYPE_COMBINATIONS.en.all;
        const urlTypes = Object.keys(combinations).reduce((obj, otKey) => {
          obj[combinations[otKey].url] = otKey;
          return obj;
        }, {});

        const objTypes = filters[SEARCH_URL_LABELS.objectTypes].map((value) => urlTypes[value]);
        filtersToUpdate.subObjectTypes = OBJECT_TYPE_OPTIONS_NP.filter((ot) => objTypes.includes(ot.get('value')));
      } else {
        // if sub object types are missing, assume all selected
        filtersToUpdate.subObjectTypes = OBJECT_TYPE_OPTIONS_NP;
      }
      if (filters[SEARCH_URL_LABELS.constructionPhase]) {
        filtersToUpdate.constructionPhase = CONSTRUCTION_PHASE_OPTIONS_NP.find((cp) =>
          filters[SEARCH_URL_LABELS.constructionPhase].includes(cp.get('value'))
        );
      } else {
        // if missing, assume first
        filtersToUpdate.constructionPhases = CONSTRUCTION_PHASE_OPTIONS_NP.first();
      }
    }

    // SET LOCATION BY ID
    if (filters[SEARCH_URL_LABELS.locationId]) {
      const values = filters[SEARCH_URL_LABELS.locationId];
      values.forEach((value) => {
        const [id, type] = value.split('-');
        dispatch(setSearchTermLocation(id, type));
      });
    }

    // SET SEARCH TERM
    if (filters[SEARCH_URL_LABELS.keyword]) {
      filtersToUpdate.searchTerm = produce(formInitialState.get('searchTerm'), (draft) => {
        filters[SEARCH_URL_LABELS.keyword].forEach((value) => {
          const keyValue = keywordTerm(value);
          draft.set(keyValue, { name: value.replace(/-/g, ' '), value: keyValue, extra: {} });
        });
      });
    }

    // SET PRICE RANGE
    if (filters[SEARCH_URL_LABELS.price]) {
      const values = filters[SEARCH_URL_LABELS.price];
      const { min, max, option } = values[0];

      filtersToUpdate.minPrice = parseURLvalue(min, 'minPrice');
      filtersToUpdate.maxPrice = parseURLvalue(max, 'maxPrice');
      filtersToUpdate.priceType = PRICE_TYPE_OPTIONS.find((type) => type.get('value') === option);
    }

    // SET ROOMS
    if (filters[SEARCH_URL_LABELS.rooms]) {
      const values = filters[SEARCH_URL_LABELS.rooms];
      filtersToUpdate.rooms = ROOMS_OPTIONS.filter((option) => values.includes(option.get('value')));
    }

    // SET SIZE RANGE
    if (filters[SEARCH_URL_LABELS.size]) {
      const values = filters[SEARCH_URL_LABELS.size];
      const { min, max } = values[0];
      filtersToUpdate.minSize = parseURLvalue(min, 'minSize');
      filtersToUpdate.maxSize = parseURLvalue(max, 'maxSize');
    }

    // SET LOT SIZE RANGE
    if (filters[SEARCH_URL_LABELS.lotSize]) {
      const values = filters[SEARCH_URL_LABELS.lotSize];
      const { min, max, option } = values[0];
      filtersToUpdate.minLotSize = parseURLvalue(min, 'minLotSize');
      filtersToUpdate.maxLotSize = parseURLvalue(max, 'maxLotSize');
      filtersToUpdate.lotSizeType = LOT_SIZE_TYPE_OPTIONS.find((type) => type.get('value') === option);
    }

    // SET FLOOR RANGE
    if (filters[SEARCH_URL_LABELS.floor]) {
      const values = filters[SEARCH_URL_LABELS.floor];
      const { min, max, option } = values[0];
      filtersToUpdate.minFloor = parseURLvalue(min, 'minFloor');
      filtersToUpdate.maxFloor = parseURLvalue(max, 'maxFloor');
      filtersToUpdate.onlyLastFloor = option === 'last';
    }

    // SET YEAR BUILT RANGE
    if (filters[SEARCH_URL_LABELS.built]) {
      const values = filters[SEARCH_URL_LABELS.built];
      const { min, max } = values[0];
      filtersToUpdate.minYearBuilt = parseURLvalue(min, 'minYearBuilt');
      filtersToUpdate.maxYearBuilt = parseURLvalue(max, 'maxYearBuilt');
    }

    // SET CONDITION
    if (filters[SEARCH_URL_LABELS.condition]) {
      const values = filters[SEARCH_URL_LABELS.condition];
      filtersToUpdate.condition = CONDITION_OPTIONS.filter((option) => values.includes(option.get('urlId').toString()));
    }

    // SET ENERGY CERTIFICATE
    if (filters[SEARCH_URL_LABELS.energyCertificate]) {
      const values = filters[SEARCH_URL_LABELS.energyCertificate];
      filtersToUpdate.energyCertificate = ENERGY_CERTIFICATE_OPTIONS.filter((option) =>
        values.includes(option.get('urlId').toString())
      );
    }

    // SET MATERIAL
    if (filters[SEARCH_URL_LABELS.material]) {
      const values = filters[SEARCH_URL_LABELS.material];
      filtersToUpdate.material = BUILDING_MATERIAL_OPTIONS.filter((option) =>
        values.includes(option.get('urlId').toString())
      );
    }

    // SET PURPOSE
    if (objectType && filters[SEARCH_URL_LABELS.purpose]) {
      const values = filters[SEARCH_URL_LABELS.purpose];
      const options = PURPOSE_OPTIONS.get(objectType.get('value'));
      if (options) {
        filtersToUpdate.purpose = options.filter((variant) => values.includes(variant.get('urlId').toString()));
      }
    }

    // SET FROM OWNER
    if (filters[SEARCH_URL_LABELS.fromOwner] || query.bat === 'PRIVATE_USER') {
      // query bat=PRIVATE_USER set from old desktop
      filtersToUpdate.fromOwner = true;
    }

    // SET OPEN HOUSE
    if (filters[SEARCH_URL_LABELS.openHouse]) {
      filtersToUpdate.openHouse = true;
    }

    // SET SPECIAL OFFERS
    if (filters[SEARCH_URL_LABELS.specialOffers]) {
      filtersToUpdate.specialOffers = true;
    }

    // SET VIRTUAL TOUR
    if (filters[SEARCH_URL_LABELS.virtualTour]) {
      filtersToUpdate.virtualTour = true;
    }

    // SET PRICEDROP
    if (filters[SEARCH_URL_LABELS.pricedrop]) {
      filtersToUpdate.pricedrop = true;
    }

    // SET EXTRAS
    if (objectType && filters[SEARCH_URL_LABELS.extras]) {
      const values = filters[SEARCH_URL_LABELS.extras];
      const extrasOptions = EXTRAS_OPTIONS.get(objectType.get('value'));
      if (extrasOptions) {
        const extras = extrasOptions.filter((variant) => values.includes(variant.get('urlId').toString()));

        filtersToUpdate.extras = extras;
      }
    }

    // SET DATE
    if (filters[SEARCH_URL_LABELS.dateAdded]) {
      filtersToUpdate.dateAdded = DATE_ADDED_OPTIONS.find(
        (variant) => variant.get('value') === filters[SEARCH_URL_LABELS.dateAdded][0]
      );
    }

    if (filters[SEARCH_URL_LABELS.projectType]) {
      filtersToUpdate.projectType = PROJECT_TYPE_OPTIONS.filter((option) =>
        filters[SEARCH_URL_LABELS.projectType].includes(option.get('value'))
      );
    }

    if (filters[SEARCH_URL_LABELS.auction]) {
      filtersToUpdate.auction = true;
    }

    if (query.lastRun) {
      filtersToUpdate.lastRun = parseInt(query.lastRun, 10);
    }

    const urlPagination = getPaginationFromUrl(url);
    dispatch(updateSearchFilters(filtersToUpdate, false));
    dispatch(setObjectResultsPage(urlPagination || 1));
  };
}

export function setSearchFromLocalState(filters) {
  return {
    type: SET_SEARCH_FORM,
    searchForm: filters,
  };
}
