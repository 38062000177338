import React from 'react';
import { generatePath } from 'react-router-dom';
import classNames from 'classnames';

import { getObjectTypeIcon } from '@/constants/attributes';
import { TRANSACTION_TYPES } from '@/constants/object';
import List from '@/components/list/List';
import ListItem from '@/components/list/ListItem';
import Tabs from '@/components/tabs/Tabs';
import Tab from '@/components/tabs/Tab';

class ObjectFilterList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: TRANSACTION_TYPES.SALE,
    };
  }

  renderListItem(item) {
    const { t, route, ownerId, preview, slug } = this.props;
    const { tab } = this.state;

    const { name, value, count } = item;
    const icon = getObjectTypeIcon(value);
    const url = generatePath(route, { id: ownerId, slug }) + `/${tab}/ot=${value}`;

    return (
      <ListItem icon={icon || ''} link={{ to: preview ? '#' : url }}>
        {t(name)}
        {count ? <span>({count})</span> : null}
      </ListItem>
    );
  }

  renderList() {
    const { list } = this.props;
    const { tab } = this.state;

    const data = list[tab];

    return data && data.map((item, i) => i > 0 && this.renderListItem(item));
  }

  render() {
    const { t, list } = this.props;
    const { tab } = this.state;

    const saleLinks = list[TRANSACTION_TYPES.SALE] && list[TRANSACTION_TYPES.SALE].length > 1;
    const rentLinks = list[TRANSACTION_TYPES.RENT] && list[TRANSACTION_TYPES.RENT].length > 1;

    const tabs = classNames('of__list', {
      'of__list--single': !saleLinks || !rentLinks,
    });

    return (
      <div className={tabs}>
        <Tabs minimal selected={tab} onChange={(val) => this.setState({ tab: val })}>
          {saleLinks && <Tab value={TRANSACTION_TYPES.SALE}>{t('common.forSale')}</Tab>}
          {rentLinks && <Tab value={TRANSACTION_TYPES.RENT}>{t('common.forRent')}</Tab>}
        </Tabs>
        <List>{this.renderList()}</List>
      </div>
    );
  }
}

export default ObjectFilterList;
