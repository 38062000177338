import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import ButtonContent, { ButtonContentProps } from './ButtonContent';

import css from './Button.module.scss';

export interface ButtonStyleProps extends Omit<ButtonContentProps, 'children'> {
  btnType?: 'primary' | 'primary-inv' | 'secondary' | 'regular' | 'link' | 'inactive';
  bgColor?: 'transparent' | 'light' | 'fb' | 'google';
  outline?: boolean;
  extended?: boolean;
  decoration?: 'underlined';
  size?: 'xs' | 'sm' | 'lg';
  className?: string;
  disabled?: boolean;
  highlightOnFocus?: boolean;
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonStyleProps {}

export function buttonStyle<StyleProps extends ButtonStyleProps>(
  props: StyleProps,
  children: React.ReactNode,
  extractOtherProps?: false
): string;
export function buttonStyle<StyleProps extends ButtonStyleProps>(
  props: StyleProps,
  children: React.ReactNode,
  extractOtherProps?: true
): readonly [string, Omit<StyleProps, keyof ButtonStyleProps>];
export function buttonStyle<StyleProps extends ButtonStyleProps>(
  props: StyleProps,
  children: React.ReactNode,
  extractOtherProps = false
) {
  const {
    className = '',
    btnType = '',
    bgColor,
    outline = false,
    extended = false,
    size = '',
    decoration,
    icon,
    iconPosition,
    disabled,
    highlightOnFocus,
    ...otherProps
  } = props;
  const classNameResult = classNames(
    'btn',
    {
      [`btn--${btnType}`]: btnType,
      [`btn--${bgColor}`]: bgColor,
      [`btn--${decoration}`]: decoration,
      'btn--outline': outline,
      'btn--small': size === 'sm',
      'btn--extra-small': size === 'xs',
      'btn--large': size === 'lg',
      'btn--extended': extended,
      'btn--disabled': disabled,
      'btn--icon': !children && icon,
      [css.highlightOnFocus]: highlightOnFocus,
    },
    className
  );
  return extractOtherProps ? ([classNameResult, otherProps] as const) : classNameResult;
}

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (props, ref): React.ReactElement => {
  const {
    children,
    className,
    type = 'button',
    btnType,
    bgColor,
    outline,
    extended,
    size,
    decoration,
    icon,
    iconPosition,
    disabled,
    ...htmlProps
  } = props;
  const btnStyle = buttonStyle(props, children);

  return (
    <button ref={ref} type={type} className={btnStyle} {...htmlProps}>
      <ButtonContent icon={icon} iconPosition={iconPosition}>
        {children}
      </ButtonContent>
    </button>
  );
};

export default React.forwardRef(Button);
