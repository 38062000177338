import React from 'react';
import Markdown from 'markdown-to-jsx';

import Anchor from '@/components/link/Anchor';
import css from './AdditionalServices.module.scss';

interface Props {
  img: string;
  link: string;
  title: string;
  subtitle?: string;
  gaEvent: (action: string, label?: string) => void;
}

function AdditionalService({ img, link, title, subtitle, gaEvent }: Props) {
  return (
    <Anchor
      href={link}
      className={css.service}
      onClick={() => {
        gaEvent('click', title);
      }}
    >
      <div className={css.cover}>
        <img src={img} className={css.img} alt={title} />
      </div>
      <div className={`h4 ${css.title}`}>{title}</div>
      {subtitle && (
        <p className={css.subtitle}>
          <Markdown>{subtitle}</Markdown>
        </p>
      )}
    </Anchor>
  );
}

export default AdditionalService;
