import config from './config';
import { authorizedFetch } from './user';

function getMyObjects(userId, query, init) {
  return authorizedFetch(`/users/${userId}/realties`, query, init);
}

function getObjectStatistics(id, query, init) {
  return authorizedFetch(`/realties/${id}/statistics`, query, init, true);
}

function createPublishing(body, query, init = {}) {
  return authorizedFetch('/media_publishings', query, {
    ...init,
    method: 'POST',
    body,
  });
}

function applyPromoCode(mediaPublishingId, body, query, init = {}) {
  return authorizedFetch(`/media_publishings/${mediaPublishingId}/promoCode`, query, {
    ...init,
    method: 'PUT',
    body,
  });
}

function getPublishings(query, init) {
  return authorizedFetch('/media_publishings', query, init);
}

function getPaymentRequestDetails(id, query, init) {
  return authorizedFetch(`/${config.locale}/payments/${id}`, query, init, true);
}

function changePublishingStatus(id, query, init = {}) {
  return authorizedFetch(`/realties/${id}/status`, query, {
    ...init,
    method: 'PUT',
    body: { id: `/realties/${id}` },
  });
}

export default {
  getMyObjects,
  getObjectStatistics,
  createPublishing,
  applyPromoCode,
  getPublishings,
  getPaymentRequestDetails,
  changePublishingStatus,
};
