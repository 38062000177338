import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from '@/utils/ga';
import { useAppSelector } from '@/hooks';
import { SINGLE_BANK_LOAN_CALCULATOR } from '@/constants/finance';
import { GA_SINGLE_BANK_CALCULATOR } from '@/constants/ga';
import { getLanguage } from '@/selectors/appSelectors';

import { computeLoanSale } from '@/components/SEBCalculator/SEBCalculatorFunctions';
import Img from '@/components/img/Img';
import ExtendedLoanCalculator from '@/components/finance/Calculators/SingleBankCalculator/ExtendedLoanCalculator';

import CompactLoanCalculator from '@/components/finance/Calculators/SingleBankCalculator/CompactLoanCalculator';
import tbbLogo from '@/img/calculators/tbb.svg';
import coopLogo from '@/img/calculators/coop.svg';

interface Props {
  selectedBank: (typeof SINGLE_BANK_LOAN_CALCULATOR)[number]['name'];
  compact?: boolean;
  placementName: string;
}

const SingleBankCalculator = ({ selectedBank, compact = false, placementName }: Props): React.ReactElement | null => {
  const lang = useAppSelector(getLanguage);
  const activeBank = SINGLE_BANK_LOAN_CALCULATOR.find((item) => item.name === selectedBank)!;

  const { defaults } = activeBank;

  const [calcState, setCalcState] = useState({
    amount: defaults.amountRange.find((option) => Number(option.get('value')) === defaults.amountDefault),
    period: defaults.periodRange.find((option) => Number(option.get('value')) === defaults.periodDefault),
  });

  const gaEventHandler = (action: string, label?: string) => {
    ReactGA.event({
      category: GA_SINGLE_BANK_CALCULATOR,
      action,
      label,
      placement_name: placementName,
    });
  };

  useEffect(() => {
    gaEventHandler('show_calculator', activeBank.name);
  }, [activeBank]);

  const results = useMemo(() => {
    const period = defaults.periodInMonths
      ? Number(calcState.period?.get('value')) / 12
      : calcState.period?.get('value');

    return computeLoanSale(calcState.amount?.get('value'), period, defaults.fixedInterestRate, 0, 'percent');
  }, [calcState]);

  const links = useMemo(() => {
    return {
      button: activeBank.links.button[lang],
      link: activeBank.links.link[lang],
    };
  }, [activeBank, lang]);

  const setCalcStateValue = (field: string, value: any) => {
    setCalcState((prevState) => ({ ...prevState, [field]: value }));
  };

  const getBankLogo = () => {
    switch (selectedBank) {
      case 'tbb':
        return <Img src={tbbLogo} alt={selectedBank} />;
      case 'coop':
        return <Img src={coopLogo} alt={selectedBank} />;
      default:
    }
    return selectedBank;
  };

  return (
    <div className="multi-bank-calc">
      {defaults && (
        <>
          {compact ? (
            <CompactLoanCalculator
              calcState={calcState}
              setCalcState={setCalcStateValue}
              results={results}
              links={links}
              bank={activeBank.name}
              accentColor={activeBank.color}
              logo={getBankLogo()}
              amountOptions={defaults.amountRange}
              periodOptions={defaults.periodRange}
            />
          ) : (
            <ExtendedLoanCalculator
              calcState={calcState}
              setCalcState={setCalcStateValue}
              results={results}
              links={links}
              bank={activeBank.name}
              accentColor={activeBank.color}
              amountOptions={defaults.amountRange}
              periodOptions={defaults.periodRange}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SingleBankCalculator;
