import React, { AnchorHTMLAttributes } from 'react';
import PropTypes from 'prop-types';

export interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  sameTab?: boolean;
  children?: React.ReactNode;
}

function Anchor({ href, sameTab = false, target, rel, children, onClick, ...props }: AnchorProps): React.ReactElement {
  // const onClickCallback: AnchorProps['onClick'] =
  //   onClick &&
  //   ((event) => {
  //     if (sameTab) {
  //       event.preventDefault();
  //       onClick(event);
  //       if (href) {
  //         window.location.href = href;
  //       }
  //     } else {
  //       onClick(event);
  //     }
  //   });
  return (
    <a
      href={href}
      target={target || (!sameTab && '_blank') || undefined}
      rel={rel || (!sameTab && 'noopener noreferrer') || undefined}
      onClick={onClick}
      {...props}
    >
      {children}
    </a>
  );
}

Anchor.propTypes = {
  target: PropTypes.string,
  rel: PropTypes.string,
  sameTab: PropTypes.bool,
};

export default Anchor;
