import produce, { freeze } from 'immer';

import { UPDATE_BROKER_SEARCH, SEARCH_BROKERS, SEARCH_VIP_BROKERS } from '@/constants/actions';

const initialState = {
  brokerName: '',
  companyName: '',
  sorting: null,
  results: {
    total: 0,
    pages: 0,
    items: [],
  },
  vipBroker: null,
  perPage: 18,
  page: 1,
  seed: Math.round(Math.random() * 1000),
};

/* eslint-disable consistent-return */
const brokerSearch = produce((state, action) => {
  switch (action.type) {
    case UPDATE_BROKER_SEARCH:
      return { ...state, ...(action.params as Partial<typeof initialState>) };

    case SEARCH_BROKERS: {
      const { results, page } = action;
      results.pages = Math.ceil(results.total / state.perPage);
      state.results = freeze(results);
      state.page = page;
      break;
    }
    case SEARCH_VIP_BROKERS:
      state.vipBroker = freeze(action.broker);
      break;
    default:
      break;
  }
}, initialState);
export default brokerSearch;
