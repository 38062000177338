import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../icon/Icon';

const propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  rawIcon: PropTypes.object,
  duration: PropTypes.number,
  onComplete: PropTypes.func.isRequired,
};

const defaultProps = {
  icon: null,
  rawIcon: null,
  duration: 4000,
};

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closing: false,
    };
  }

  componentDidMount() {
    const { duration, onComplete } = this.props;

    const animationDuration = 500;

    setTimeout(() => {
      this.setState({ closing: true });
      setTimeout(() => {
        onComplete();
      }, animationDuration);
    }, duration);
  }

  render() {
    const { text, icon, rawIcon, browser } = this.props;
    const { closing } = this.state;

    const notificationStyle = classNames({
      notification: true,
      'notification-mobile': browser.lessThan.medium,
      'notification--closing': closing,
    });

    return (
      <div className={notificationStyle}>
        {icon && <Icon name={icon} />}
        {rawIcon}
        {text}
      </div>
    );
  }
}

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default Notification;
