import Language from '@city24/common/enums/Language';

export const NEWS_PER_PAGE = 13;
export const EDITORS_PICK_ARTICLES_NO = 3;
export const SIMILAR_ARTICLES_NO = 4;
export const POPULAR_ARTICLES_NO = 5;
export const EDITORS_PICK_TAG: Partial<Record<Language, string>> = {
  [Language.et]: 'toimetaja-valik',
  [Language.ru]: 'vybor-redaktora',
  [Language.lv]: 'redaktora-izvele',
};
export const CONTENT_MARKETING_TAG = {
  et_EE: 'sisuturundus',
  ru_RU: 'kontent-marketing',
  lv_LV: 'satura-marketings',
  en_GB: null,
};
export const LUMINOR_TAG = {
  et_EE: 'luminor',
  ru_RU: 'luminor',
  en_GB: 'luminor',
};
export const FINANCE_CENTER_TAG = {
  et_EE: 'kodulaen',
  ru_RU: 'kodulaen',
  en_GB: 'kodulaen',
};
export const NEW_DEV_TAG: Partial<Record<Language, string>> = {
  [Language.et]: 'uusarendused',
  [Language.ru]: 'novye-proekty',
  [Language.en]: 'new-projects',
};
export const DEFAULT_TAG = {
  name: 'news.allArticlesTag',
  value: '',
  featuredTag: false,
};
