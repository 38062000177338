import { generatePath } from 'react-router-dom';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import {
  SET_SEARCH_MODAL,
  SET_SAVE_SEARCH_MODAL,
  SET_SIGN_IN_MODAL,
  SET_REGISTER_MODAL,
  SET_SAVE_SEARCH_CONFIRM_MODAL,
  SET_REPORT_AD_MODAL,
  SET_REPORT_AD_CONFIRM_MODAL,
  SET_SAVE_FAVOURITE_MODAL,
  SET_AREA_SELECT_MODAL,
  SET_SAVED_SEARCH_REMOVE_MODAL,
  SET_PASSWORD_RESET_MODAL,
  SET_DELETE_MY_OBJECT_MODAL,
  SET_INVOICES_SENT_MODAL,
  SET_SUCCESSFUL_ACTION_MODAL,
  SET_SEB_CALCULATOR_MODAL,
  SET_DETAIL_PREVIEW_MODAL,
  SET_OBJECT_LIST_MODAL,
} from '@/constants/actions';
import { openModal } from './modalReducer';
import { ROUTES } from '@/constants/routes';
import { ModalName } from './modalInterfaces';

export function setSearchModalOpened(searchModalOpened) {
  return {
    type: SET_SEARCH_MODAL,
    searchModalOpened,
  };
}

export function setSaveSearchModal(opened, item, edit) {
  return {
    type: SET_SAVE_SEARCH_MODAL,
    opened,
    item,
    edit,
  };
}

export function setSaveSearchConfirmModalOpened(saveSearchConfirmModalOpened) {
  return {
    type: SET_SAVE_SEARCH_CONFIRM_MODAL,
    saveSearchConfirmModalOpened,
  };
}

export function setSavedSearchRemoveModal(opened, id, name, emailAlerts) {
  return {
    type: SET_SAVED_SEARCH_REMOVE_MODAL,
    opened,
    id,
    name,
    emailAlerts,
  };
}

/** @deprecated */
export function setSignInModalOpened(signInModalOpened) {
  return {
    type: SET_SIGN_IN_MODAL,
    signInModalOpened,
  };
}

/** @deprecated */
export function setRegisterModalOpened(registerModalOpened, confirmed) {
  return {
    type: SET_REGISTER_MODAL,
    registerModalOpened,
    confirmed,
  };
}

/** @deprecated */
export function setReportAdModalOpened(reportAdModalOpened) {
  return {
    type: SET_REPORT_AD_MODAL,
    reportAdModalOpened,
  };
}

export function setReportAdConfirmModalOpened(reportAdConfirmModalOpened) {
  return {
    type: SET_REPORT_AD_CONFIRM_MODAL,
    reportAdConfirmModalOpened,
  };
}

export function setSaveFavouriteModalOpened(saveFavouriteModalOpened) {
  return {
    type: SET_SAVE_FAVOURITE_MODAL,
    saveFavouriteModalOpened,
  };
}

export function setAreaSelectModalOpened(areaSelectModalOpened) {
  return {
    type: SET_AREA_SELECT_MODAL,
    areaSelectModalOpened,
  };
}

/** @deprecated */
export function setPasswordResetModalOpened(passwordResetModalOpened, confirmed) {
  return {
    type: SET_PASSWORD_RESET_MODAL,
    passwordResetModalOpened,
    confirmed,
  };
}
/** @deprecated use modalReducer.openModal */
export function setShareModal(opened, id, unitType = null, useCurrentLocation = false) {
  let sharePath = `${location.origin}${location.pathname}`;
  if (id && !useCurrentLocation) {
    switch (unitType) {
      case UnitTypeLiteral.NEW_PROJECT:
        sharePath = `${location.origin}${generatePath(ROUTES.projectDetailShort, { id })}`;
        break;
      case UnitTypeLiteral.MODULAR_HOUSE:
        sharePath = `${location.origin}${generatePath(ROUTES.modularHouseDetailShort, { id })}`;
        break;
      case 'Broker':
        sharePath = `${location.origin}${generatePath(ROUTES.brokerDetail, { id })}`;
        break;
      default:
        sharePath = `${location.origin}${generatePath(ROUTES.objectDetailShort, { id })}`;
    }
  }
  return openModal({
    name: ModalName.Share,
    id,
    isRealtyShare: unitType !== 'Broker',
    sharePath,
  });
}

export function setDeleteMyObjectModal(opened, action, id, friendlyId, address, location) {
  return {
    type: SET_DELETE_MY_OBJECT_MODAL,
    opened,
    action,
    id,
    friendlyId,
    address,
    location,
  };
}

export function setInvoicesSentModal(invoicesSentModalOpened) {
  return {
    type: SET_INVOICES_SENT_MODAL,
    invoicesSentModalOpened,
  };
}

export function setSuccessfulActionModal(opened, actionType = null) {
  return {
    type: SET_SUCCESSFUL_ACTION_MODAL,
    opened,
    actionType,
  };
}

export function setSEBCalculatorModalOpened(opened, price = null, transactionType = null) {
  return {
    type: SET_SEB_CALCULATOR_MODAL,
    opened,
    price,
    transactionType,
  };
}

export function setDetailPreviewModalOpened(opened, id) {
  return {
    type: SET_DETAIL_PREVIEW_MODAL,
    opened,
    id: id ? id : null,
  };
}

export function setObjectListModal(opened, objectId, projectName) {
  return {
    type: SET_OBJECT_LIST_MODAL,
    opened,
    objectId,
    projectName,
  };
}
