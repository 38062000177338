import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import adOcean from '@/adOcean';
import {
  D_DETAIL_FOOTER,
  D_DETAIL_OVERLAY,
  D_MAIN_FOOTER,
  D_MAIN_OVERLAY,
  D_NP_DETAIL_FOOTER,
  D_NP_DETAIL_OVERLAY,
  D_NP_SEARCH_FOOTER,
  D_NP_SEARCH_OVERLAY,
  D_SEARCH_FOOTER,
  D_SEARCH_OVERLAY,
  D_SPECIAL_STICKY,
  M_DETAIL_OVERLAY,
  M_MAIN_OVERLAY,
  M_NP_DETAIL_OVERLAY,
} from '@/constants/ad';
import { getLanguage } from '@/selectors/appSelectors';
import { getAdSlave, slaveLoaded } from './AdSelectors';
import { addAdMaster, removeAdSlave } from './AdActions';

class Ad extends React.Component {
  adRef = React.createRef();

  componentDidMount() {
    // this.registerSlave();
    setTimeout(() => {
      this.registerSlave();
    }, 500);
  }

  componentDidUpdate(prevProps) {
    const { master, id, language, removeAdSlave } = this.props;
    if (prevProps.language !== language) {
      removeAdSlave(master[prevProps.language], id[prevProps.language]);
      this.removeAdContents(id);
      this.registerSlave();
    }
  }

  componentWillUnmount() {
    const { master, id, language, removeAdSlave } = this.props;
    removeAdSlave(master[language], id[language]);
    this.removeAdContents(master[language], id);
  }

  removeAdContents = (mId, id) => {
    // if ([D_MAIN_FOOTER, D_SEARCH_FOOTER, D_NP_SEARCH_FOOTER, D_DETAIL_FOOTER, D_NP_DETAIL_FOOTER].includes(id)) {
    //   adOcean.refresh(mId);
    // }

    if (
      [
        D_MAIN_OVERLAY,
        // D_MAIN_FOOTER,
        D_SEARCH_OVERLAY,
        D_NP_SEARCH_OVERLAY,
        D_DETAIL_OVERLAY,
        D_NP_DETAIL_OVERLAY,
        M_MAIN_OVERLAY,
        M_DETAIL_OVERLAY,
        M_NP_DETAIL_OVERLAY,
      ].includes(id)
    ) {
      const overlayBannersContent = document.querySelectorAll('[id^="corner_"]');
      const intersBannersContent = document.querySelectorAll('[id^="inters."]');
      const scrollerBannersContent = document.querySelectorAll('[id^="footer.outside"]');
      if (overlayBannersContent.length) {
        overlayBannersContent.forEach((banner) => {
          banner.remove();
        });
      }
      if (intersBannersContent.length) {
        intersBannersContent.forEach((banner) => {
          banner.remove();
        });
      }
      if (scrollerBannersContent.length) {
        scrollerBannersContent.forEach((banner) => {
          banner.remove();
        });
      }
      if (overlayBannersContent.length || intersBannersContent.length || scrollerBannersContent.length) {
        adOcean.refresh(mId);
      }
    } else if (id === D_SPECIAL_STICKY) {
      const stickyBannersContent = document.querySelectorAll('[id$=".layer"]');
      if (stickyBannersContent.length) {
        stickyBannersContent.forEach((banner) => {
          banner.remove();
        });
        adOcean.refresh(mId);
      }
    }
  };

  registerSlave() {
    const { id, master, addAdMaster, language, adSlave } = this.props;
    const localId = id[language];
    const localMaster = master[language];
    if (this.adRef.current && localId && localMaster && !slaveLoaded(adSlave)) {
      addAdMaster(localMaster, localId);
    }
  }

  render() {
    const {
      id,
      language,
      className,
      spacing,
      spacingTop,
      spacingBottom,
      spacingX,
      spacingY,
      spacingYSmall,
      adSlave,
      fixedHeaderHeight,
      sticky,
      minHeight = 0,
    } = this.props;
    const spacingClass = classNames({
      'ad--spacing': spacing,
      'ad--spacing-top': spacingTop,
      'ad--spacing-bottom': spacingBottom,
      'ad--spacing-x': spacingX,
      'ad--spacing-y': spacingY,
      'ad--spacing-y-sm': spacingYSmall,
    });
    const adStyle = classNames(
      'ad',
      spacingClass,
      {
        'ad--sticky': sticky,
      },
      className
    );

    const height = (adSlave && adSlave.height) || 0;
    const wrapInFixedHeight = (child) => {
      return fixedHeaderHeight ? (
        <div className={classNames('ad ad--fixed-header', spacingClass, className)}>{child}</div>
      ) : (
        child
      );
    };
    return wrapInFixedHeight(
      <div
        ref={this.adRef}
        className={adStyle}
        id={id[language]}
        style={{ minHeight: `${minHeight}px`, height: height ? `${height}px` : undefined }}
      />
    );
  }
}

const idShape = PropTypes.shape({
  et: PropTypes.string,
  en: PropTypes.string,
  ru: PropTypes.string,
});

Ad.propTypes = {
  id: idShape.isRequired,
  master: idShape.isRequired,
  language: PropTypes.string.isRequired,
  addAdMaster: PropTypes.func.isRequired,
  removeAdSlave: PropTypes.func.isRequired,
  className: PropTypes.string,
  spacing: PropTypes.bool,
  spacingTop: PropTypes.bool,
  spacingBottom: PropTypes.bool,
  spacingX: PropTypes.bool,
  spacingY: PropTypes.bool,
  fixedHeaderHeight: PropTypes.bool,
  sticky: PropTypes.bool,
};

Ad.defaultProps = {
  className: '',
  spacing: false,
  spacingTop: false,
  spacingBottom: false,
  spacingX: false,
  spacingY: false,
  fixedHeaderHeight: false,
  sticky: false,
};

function mapStateToProps(state, { master, id }) {
  const adSlave = getAdSlave(state)(master, id);
  return {
    language: getLanguage(state),
    adSlave,
  };
}

const mapDispatchToProps = { removeAdSlave, addAdMaster };

export default connect(mapStateToProps, mapDispatchToProps)(Ad);
