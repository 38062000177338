import { apiFetch } from './basics';
import config from './config';
import { authorizedFetch } from '@city24/common/api/city24/auth';

export { authorizedFetch };

// Authorization actions
function login(body, query, init = {}) {
  return apiFetch('/token', query, {
    ...init,
    method: 'POST',
    body,
  });
}

function loginSocial(provider, body, query, init = {}) {
  return apiFetch(`/token/${provider}`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function refreshToken(body, query, init = {}) {
  return apiFetch('/token/refresh', query, {
    ...init,
    method: 'POST',
    body,
  });
}

function createAccount(body, query, init) {
  return apiFetch('/users', query, {
    ...init,
    method: 'POST',
    body,
  });
}

function createAccountSocial(provider, body, query, init) {
  return apiFetch(`/users/${provider}`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function verifyAccount(uuid, query, init = {}) {
  return apiFetch(`/users/verify/${uuid}`, query, {
    ...init,
    method: 'POST',
  });
}

function confirmAccount(id, body, query, init = {}) {
  return apiFetch(`/users/${id}/confirm`, query, {
    ...init,
    method: 'PUT',
    body,
  });
}

function verifyUuid(body, init = {}) {
  return apiFetch(
    `/users/uuid/verify`,
    {},
    {
      ...init,
      method: 'POST',
      body,
    }
  );
}

function passwordReset(body, query, init = {}) {
  return apiFetch('/users/password/reset', query, {
    ...init,
    method: 'POST',
    body,
  });
}

function passwordResetConfirm(body, query, init = {}) {
  return apiFetch('/users/password/confirm', query, {
    ...init,
    method: 'POST',
    body,
  });
}

function getUserInfo(userId, query, init) {
  return authorizedFetch(`/users/${userId}`, query, init);
}

function updateUserInfo(userId, body, query, init = {}) {
  return authorizedFetch(`/users/${userId}`, query, {
    ...init,
    method: 'PUT',
    body,
  });
}

function getUserInvoices(userId, query, init) {
  return authorizedFetch(`/users/${userId}/invoices`, query, init);
}

function getInfoCounters(userId, query, init) {
  return authorizedFetch(`/users/${userId}/counters`, query, init);
}

function emailUserInvoices(query, init) {
  return authorizedFetch(`/invoices/email`, query, init);
}

function getFavourites(query, init) {
  return authorizedFetch('/user_favourites', query, init);
}

function saveFavourite(body, query, init = {}) {
  return authorizedFetch('/user_favourites', query, {
    ...init,
    method: 'POST',
    body,
  });
}

function deleteFavourite(id, query, init = {}) {
  return authorizedFetch(`/user_favourites/${id}`, query, { ...init, method: 'DELETE' });
}

function getSavedSearches(query, init, anonymous = false) {
  return anonymous ? apiFetch('/realty_searches', query, init) : authorizedFetch('/realty_searches', query, init);
}

function saveSearch(body, query, init = {}) {
  return authorizedFetch(`/${config.locale}/realty_searches/search_query`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function updateSearch(id, body, query, init = {}) {
  return authorizedFetch(`/realty_searches/${id}`, query, {
    ...init,
    method: 'PUT',
    body,
  });
}

function unsubscribeSearch(id, query, init = {}) {
  return apiFetch(`/realty_searches/${id}/unsubscribe`, query, {
    ...init,
    method: 'PUT',
    body: {},
  });
}

function deleteSearch(id, query, init = {}) {
  return authorizedFetch(`/realty_searches/${id}`, query, {
    ...init,
    method: 'DELETE',
  });
}

function verifyReCaptcha(query, init = {}) {
  return apiFetch('/verify_recaptcha', query, {
    ...init,
    method: 'POST',
  });
}

function searchUsers(query, init) {
  return authorizedFetch(`/users`, query, init);
}

export default {
  login,
  loginSocial,
  refreshToken,
  createAccount,
  createAccountSocial,
  verifyAccount,
  confirmAccount,
  verifyUuid,
  passwordReset,
  passwordResetConfirm,
  getUserInfo,
  updateUserInfo,
  getUserInvoices,
  emailUserInvoices,
  getFavourites,
  saveFavourite,
  deleteFavourite,
  getSavedSearches,
  saveSearch,
  updateSearch,
  unsubscribeSearch,
  deleteSearch,
  verifyReCaptcha,
  getInfoCounters,
  searchUsers,
};
