import Immutable from 'immutable';
import produce, { freeze } from 'immer';
import {
  MAP_OBJECT_SELECTED,
  MAP_INFO_CLOSE,
  MAP_LOAD_OBJECT_DETAILS_INIT,
  MAP_LOAD_OBJECT_DETAILS,
  MAP_LOAD_OBJECT_DETAILS_FINISHED,
  MAP_CLEAR_OBJECTS,
  SEARCH_OBJECTS,
  OBJECT_TO_MAP_MARKER,
  CLEAR_OBJECT_TO_MAP_MARKER,
  HIGHLIGHT_OBJECT_MAP_MARKER,
} from '@/constants/actions';

const initialState = {
  isOpen: false,
  isLoading: true,
  selectedObjectIds: [],
  objects: {},
  highlightedMapMarker: null,
  objectToMapMarker: {
    id: null,
    latitude: null,
    longitude: null,
    zoom: 18,
  },
};

const mapInfoReducer = produce((state, action) => {
  switch (action.type) {
    case MAP_OBJECT_SELECTED:
      state.isOpen = true;
      state.isLoading = true;
      break;
    case MAP_LOAD_OBJECT_DETAILS_INIT:
      state.isLoading = true;
      state.selectedObjectIds = freeze(action.ids);
      state.objects = {};
      break;
    case MAP_LOAD_OBJECT_DETAILS:
      state.objects[action.id] = Immutable.fromJS(action.object);
      break;
    case MAP_LOAD_OBJECT_DETAILS_FINISHED:
      state.isLoading = false;
      break;
    case MAP_INFO_CLOSE:
    case MAP_CLEAR_OBJECTS:
    case SEARCH_OBJECTS:
      state.isOpen = false;
      break;
    case OBJECT_TO_MAP_MARKER:
      state.objectToMapMarker = freeze(action.object);
      break;
    case CLEAR_OBJECT_TO_MAP_MARKER:
      state.objectToMapMarker = freeze(initialState.objectToMapMarker);
      break;
    case HIGHLIGHT_OBJECT_MAP_MARKER:
      state.highlightedMapMarker = freeze(action.id);
      break;
    default:
      break;
  }
}, initialState);

export default mapInfoReducer;
