import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import LinesEllipsis from 'react-lines-ellipsis';
import ReactGA from '@/utils/ga';
import VisibilitySensor from 'react-visibility-sensor';

import { OBJECT_TYPES, SIZE_UNIT_VALUES } from '@/constants/object';
import { RENT_TO_OWN } from '@/constants/attributes';
import { GA_SEARCH_PAGE } from '@/constants/ga';
import { PROPERTY_IMPRESSION, PROPERTY_IMPRESSION_MOBILE } from '@/constants/statistics';
import { showMultiBankCalculator } from '@/constants/finance';
import { getPricePerUnitType, showSlogan } from '@/utils/object';
import { getObjectDetailRoute } from '@/utils/route';
import { getMultibankCalculatorUnitType, identity, makeFavourite } from '@/utils/helpers';
import { getBrokerName, getContact } from '@/utils/contacts';
import { isInactive } from '@/utils/objectStates';

import { saveFavourite, removeFavourite } from '@/actions/userActions';
import { setObjectToMarker } from '@/components/map/Map/mapActions';
import { sendStatistics } from '@/actions/statisticsActions';
import { getBrowser, getLocale } from '@/selectors/appSelectors';
import { getMapOpened } from '@/components/search/searchSelectors';
import { getEuriborRate, getLuminorLinks } from '@/selectors/calculatorsSelectors';
import Image from '@/components/img/Image';
import { openModal } from '@/components/modals/modalReducer';
import { ModalName } from '@/components/modals/modalInterfaces';

import ObjectPlaceholder from '@/img/placeholder/object_placeholder.svg';
import Icon from '@/components/icon/Icon';
import Button from '@/components/button/Button';
import LoadingPlaceholderBox from '@/components/loading/LoadingPlaceholderBox';
import ObjectImageSlider from '@/components/objectImageSlider/objectImageSlider';
import Label from '@/components/label/Label';
import { multibankMonthlyPayment } from '@/components/finance/Calculators/loanCalculation';
import ButtonGroup from '@/components/button/ButtonGroup';
import LinkedObjects from '@/components/linkedObjects/LinkedObjects';
import LegacyToNextLink from '@/components/link/LegacyToNextLink';
import ObjectPrice from './ObjectPrice/ObjectPrice';
import ObjectFeatures from './ObjectFeatures/ObjectFeatures';
import SecondarySpecs from './SecondarySpecs/SecondarySpecs';
import { openBrokerContactModal, openDetailPreviewModal, setObjectNavCount } from './ObjectDetailsActions';
import Slogan from './Description/Slogan';
import OpenDaysLabel from './OpenDaysLabel';
import AppContext from '../appContext';

const propTypes = {
  layout: PropTypes.oneOf(['list', 'grid']),
  objectRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  favourite: PropTypes.number,
  t: PropTypes.func,
  showInterestButton: PropTypes.bool,
  showInterestLabel: PropTypes.bool,
  gaEvent: PropTypes.func,
  hideAddress: PropTypes.bool,
  hideSlogan: PropTypes.bool,
  preview: PropTypes.bool,
  containerParams: PropTypes.object,
};

const defaultProps = {
  layout: 'list',
  objectRoute: true,
  t: (a) => a,
  gaEvent: null,
  favourite: null,
  showInterestButton: false,
  showInterestLabel: false,
  preview: false,
  hideAddress: false,
  hideSlogan: false,
  containerParams: null,
};

class RealEstateObject extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { openDaysDetails: {}, monthlyLoanPayment: null, isVisible: false };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.price !== this.props.price ||
      prevProps.minPrice !== this.props.minPrice ||
      prevProps.objectType !== this.props.objectType ||
      prevProps.transactionType !== this.props.transactionType ||
      prevProps.euriborRate !== this.props.euriborRate ||
      prevState.isVisible !== this.state.isVisible
    ) {
      this.calculateMonthlyLoanPayment();
    }
  }

  gaEventHandler = (id) => {
    const { gaEvent } = this.props;
    if (gaEvent) {
      gaEvent(id);
    }
  };

  handleObjectLoanClick = (e) => {
    e.stopPropagation();
    const gaCategory = PORTAL_EE ? 'luminorFavourites' : 'multiBankCaluclator';

    ReactGA.event({
      category: isMobile ? `${gaCategory}_mob` : `${gaCategory}_desk`,
      action: 'ask_offer_button',
    });

    const { openModal, price, minPrice, object } = this.props;
    e.preventDefault();
    openModal({
      name: ModalName.MultiBank,
      price: price || minPrice,
      unitType: getMultibankCalculatorUnitType(object),
      yearBuilt: object.get('year_built') ?? undefined,
    });
  };

  getPath() {
    const { objectRoute, friendlyId, typeCombination, urlLocation } = this.props;
    return getObjectDetailRoute(objectRoute, friendlyId, typeCombination, urlLocation);
  }

  getIsVip() {
    const { object } = this.props;
    return !!(object?.getIn(['broker', 'is_vip']) || object?.get('is_vip'));
  }

  saveFavourite = (e) => {
    const { saveFavourite, id, guid, objectType } = this.props;
    if (e) {
      e.preventDefault();
    }

    if (saveFavourite) saveFavourite(id, guid, objectType);
  };

  removeFavourite = (e) => {
    const { removeFavourite, favourite, guid, objectType } = this.props;
    if (e) {
      e.preventDefault();
    }

    if (removeFavourite) removeFavourite(favourite, makeFavourite(guid, objectType));
  };

  openBrokerContactModal = (e) => {
    const { object, openBrokerContactModal } = this.props;
    if (e) {
      e.preventDefault();
    }
    openBrokerContactModal(object);
  };

  openDetailPreviewModal = (e) => {
    const { object, openDetailPreviewModal } = this.props;
    if (e) {
      e.preventDefault();
    }
    openDetailPreviewModal(object.get('id'));
  };

  calculateMonthlyLoanPayment() {
    const { transactionType, objectType, minPrice, price, showLoanCta, result, euriborRate, object } = this.props;
    let monthlyLoanPayment = null;

    // monthly payment is shown only on search page & favourites, do not calculate it otherwise
    if (!showLoanCta && !result) {
      return;
    }

    if (showMultiBankCalculator(transactionType, objectType)) {
      monthlyLoanPayment = multibankMonthlyPayment(
        minPrice || price,
        getMultibankCalculatorUnitType(object),
        euriborRate
      );
    }

    this.setState({ monthlyLoanPayment });
  }

  showOnMap(event) {
    const { friendlyId, setObjectToMarker, latitude, longitude } = this.props;
    event.preventDefault();

    setObjectToMarker(friendlyId, latitude, longitude);
  }

  renderSlogan(displayOnLayout) {
    const { layout, locale, slogans, loading, result, object, single, hideSlogan } = this.props;

    if (hideSlogan || displayOnLayout !== layout) {
      return null;
    }

    // eslint-disable-next-line react/no-danger
    const renderSlogan = (s) => <Slogan>{s}</Slogan>;

    let { slogan } = this.props;

    if (slogan) {
      return renderSlogan(slogan);
    }

    slogan = slogans && slogans.size > 0 && (slogans.get(locale.replace('-', '_')) || slogans.first()).get('slogan');

    if (result && showSlogan(object)) {
      if (loading) {
        return renderSlogan(<LoadingPlaceholderBox size={5} />);
      }

      if (slogan) {
        return renderSlogan(slogan);
      }
      return null;
    }
    if (single && slogan) {
      return renderSlogan(slogan);
    }
    return null;
  }

  renderDescription(layout) {
    const { descriptions, locale } = this.props;

    const texts = descriptions && descriptions.getIn([locale.replace('-', '_')]);

    if (texts) {
      const regex = /(<([^>]+)>)/gi;

      const intro = texts.get('introduction') && texts.get('introduction').replace(regex, '');
      const description = texts.get('description') && texts.get('description').replace(regex, '');

      return (
        layout === 'list' && (
          <div className="object__description">
            <LinesEllipsis
              style={{ whiteSpace: 'pre-wrap' }}
              text={intro + description}
              maxLine="3"
              ellipsis="..."
              basedOn="letters"
            />
          </div>
        )
      );
    }
    return null;
  }

  renderLabels() {
    const { openDays, status, showInterestButton, friendlyId, projectFriendlyId, t } = this.props;

    if (openDays || isInactive(status)) {
      return (
        <ul className={classNames('object__labels', { 'object__labels--mb': showInterestButton })}>
          {status && isInactive(status) && (
            <li>
              <Label red>{t('objectDetail.label.archived')}</Label>
            </li>
          )}
          {openDays && (
            <OpenDaysLabel t={t} friendlyId={friendlyId} projectFriendlyId={projectFriendlyId}>
              {openDays}
            </OpenDaysLabel>
          )}
        </ul>
      );
    }
    return null;
  }

  renderTags(above = false) {
    const { isNew, specialLabel, comingSoon, openDays, openDaysTag, t } = this.props;
    const tags = [];

    if (specialLabel) {
      tags.push(
        <span key="special" className="object__tags-special">
          {t('objectSpec.projectOfTheMonth')}
        </span>
      );
    }

    if (!above) {
      if (comingSoon) {
        tags.push(
          <span key="comingSoon" className="object__tags-cs">
            {t('objectSpec.comingSoon')}
          </span>
        );
      }
      if (isNew) {
        tags.push(
          <span key="new" className="object__tags-new">
            {t('common.new')}
          </span>
        );
      }
      if (openDays && openDaysTag) {
        tags.push(
          <span key="openDays" className="object__tags-open">
            <Icon name="open-house" />
            <span>{t('objectSpec.hasOpenDays')}</span>
          </span>
        );
      }
    }

    if (tags.length > 0) {
      return <div className={classNames('object__tags', { 'object__tags--above': above })}>{tags}</div>;
    }
    return null;
  }

  // renderSizeMinMax() {
  //   const { sizeMin, sizeMax, sizeUnit } = this.props;
  //
  //   if (sizeMin && sizeMax) {
  //     return sizeUnit ? (
  //       <li>{`${sizeMin || '...'} - ${sizeMax || '...'} ${SIZE_UNIT_VALUES[sizeUnit]}`}</li>
  //     ) : (
  //       <li>{`${sizeMin || '...'} - ${sizeMax || '...'} m`}&sup2;</li>
  //     );
  //   }
  //   return null;
  // }

  // renderSize() {
  //   const { size, sizeUnit } = this.props;
  //
  //   if (size) {
  //     return sizeUnit ? <li>{`${size} ${SIZE_UNIT_VALUES[sizeUnit]}`}</li> : <li>{`${size} m`}&sup2;</li>;
  //   }
  //   return null;
  // }

  // renderLandSize() {
  //   const { object } = this.props;
  //
  //   if (
  //     object &&
  //     (object.get('unit_type') === OBJECT_TYPES.House || object.get('unit_type') === OBJECT_TYPES.Commercial) &&
  //     object.get('lot_size')
  //   ) {
  //     return object.get('lot_size_unit_id') ? (
  //       <li>
  //         <Icon name="land" /> {`${object.get('lot_size')} ${SIZE_UNIT_VALUES[object.get('lot_size_unit_id')]}`}
  //       </li>
  //     ) : (
  //       <li>
  //         <Icon name="land" /> {`${object.get('lot_size')} m`}&sup2;
  //       </li>
  //     );
  //   }
  //   return null;
  // }

  // renderMediaIcons() {
  //   const { video, virtualTour } = this.props;
  //
  //   const icons = [];
  //   if (video) {
  //     icons.push(
  //       <li key="video" className="object__media">
  //         <Icon name="video" />
  //       </li>
  //     );
  //   }
  //   if (virtualTour) {
  //     icons.push(
  //       <li key="virtualTour" className="object__media">
  //         <Icon name="virtual-tour" />
  //       </li>
  //     );
  //   }
  //   return icons;
  // }

  renderBrokerContactButton() {
    const { result, object, comingSoon, browser, t } = this.props;
    if (!result) {
      return null;
    }

    return this.getIsVip() && browser.greaterThan.small ? (
      <div className="object--vip__contacts">
        <span className="badge">
          {object.getIn(['broker', 'office', 0, 'developer'])
            ? t(`common.vip_developer_badge.${PORTAL_LV ? 'lv' : 'ee'}`)
            : t(`common.vip_agent_badge.${PORTAL_LV ? 'lv' : 'ee'}`)}
        </span>
        <span>{comingSoon ? t('common.comingSoonContact') : getBrokerName(object.get('broker'))}</span>
        <Button
          outline
          small
          icon="envelope"
          onClick={(e) => {
            this.openBrokerContactModal(e, object);
          }}
        />
      </div>
    ) : (
      <button
        type="button"
        className="object__broker-contact--btn"
        onClick={(e) => {
          this.openBrokerContactModal(e, object);
        }}
      >
        <Icon name="envelope" />
        <u>{comingSoon ? t('common.comingSoonContact') : getBrokerName(object.get('broker'))}</u>
      </button>
    );
  }

  renderLinkWrapper(props, children) {
    const { friendlyId, urlQuery, linkTo, result, objectRoute, newTab, setObjectNavCount } = this.props;

    const { className, title } = props;

    if (objectRoute === false) {
      return (
        <div className={className} title={title}>
          {children}
        </div>
      );
    }

    if (linkTo) {
      return (
        <a
          className={className}
          title={title}
          href={linkTo}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            this.gaEventHandler(friendlyId);
          }}
        >
          {children}
        </a>
      );
    }
    let path = this.getPath();
    if (urlQuery) {
      path += `?${urlQuery}`;
    }

    return (
      <LegacyToNextLink
        href={path}
        className={className}
        title={title}
        target={newTab && '_blank'}
        onClick={() => {
          this.gaEventHandler(friendlyId);
          setObjectNavCount('push', result ? 1 : 0);
        }}
      >
        {children}
      </LegacyToNextLink>
    );
  }

  renderLocationWrapper(children) {
    const { result } = this.props;
    return result ? (
      <h3 className="object__address">{children}</h3>
    ) : (
      <div className="h3 object__address">{children}</div>
    );
  }

  renderObjectLoan(monthlyLoanPayment) {
    const { t } = this.props;
    return (
      <div className="object__loan">
        <div>
          <div className="object__loan-label">{t('header.homeLoan')}</div>
          <div className="object__loan-payment">{`${monthlyLoanPayment} €/${t('loanCalc.perMonth')}`}</div>
        </div>
        <Button light outline small text={t('loanCalc.askForOffer')} newTab onClick={this.handleObjectLoanClick} />
      </div>
    );
  }

  renderMainFeatures() {
    const {
      objectType,
      newProject,
      modularHouse,
      rooms,
      roomCountMin,
      roomCountMax,
      floor,
      size,
      sizeMin,
      sizeMax,
      sizeUnit,
      lotSize,
      lotSizeUnit,
      video,
      virtualTour,
      t,
      tetAvailability,
    } = this.props;

    return (
      <ObjectFeatures
        objectType={objectType}
        newProject={newProject}
        modularHouse={modularHouse}
        rooms={rooms}
        roomCountMin={roomCountMin}
        roomCountMax={roomCountMax}
        floor={floor}
        size={size}
        sizeMin={sizeMin}
        sizeMax={sizeMax}
        sizeUnit={sizeUnit}
        lotSize={lotSize}
        lotSizeUnit={lotSizeUnit}
        video={video}
        virtualTour={virtualTour}
        // tetAvailability={tetAvailability}
        t={t}
      />
    );

    // return (
    //   <>
    //     {this.renderSizeMinMax()}
    //     {this.renderSize()}
    //     {this.renderLandSize()}
    //     {!newProject && !modularHouse && rooms && (
    //       <li>
    //         <Icon name="door" />
    //         {`${t('objectSpec.rooms_short', { number: rooms })}`}
    //       </li>
    //     )}
    //     {newProject && roomCountMin && roomCountMax && (
    //       <li>
    //         <Icon name="door" />
    //         {`${t('objectSpec.rooms_range_short', { min: roomCountMin, max: roomCountMax })}`}
    //       </li>
    //     )}
    //     {!modularHouse && floor && (
    //       <li>
    //         <Icon name="stairs" />
    //         {`${floor} ${t('objectSpec.floor_short')}`}
    //       </li>
    //     )}
    //     {this.renderMediaIcons()}
    //   </>
    // );
  }

  renderObjectPrice(monthlyLoanPayment) {
    const {
      friendlyId,
      objectType,
      transactionType,
      object,
      price,
      oldPrice,
      minPrice,
      maxPrice,
      priceSqrm,
      priceSqrmMin,
      priceSqrmMax,
      loading,
      minimal,
      result,
      myObject,
      priceElement,
      layout,
      routes,
      t,
    } = this.props;
    return (
      <ObjectPrice
        t={t}
        friendlyId={friendlyId}
        routes={routes}
        objectType={objectType || (object && object.get('unit_type'))}
        transactionType={transactionType}
        price={price}
        oldPrice={oldPrice}
        minPrice={minPrice}
        maxPrice={maxPrice}
        pricePerUnit={priceSqrm}
        pricePerUnitMin={priceSqrmMin}
        pricePerUnitMax={priceSqrmMax}
        pricePerUnitType={
          object &&
          getPricePerUnitType(object.get('unit_type'), object.get('size_unit_id'), object.get('lot_size_unit_id'))
        }
        loading={loading}
        hidePricePerUnit={minimal}
        hideOldPrice={!result}
        enablePlaceholderPrice={myObject}
        priceElement={priceElement}
        rentToOwn={
          !minimal && layout === 'grid' && object && object.getIn(['attributes', RENT_TO_OWN]) ? (
            <div className="object__rent-to-own">{t('objectSpec.rentToOwn')}</div>
          ) : null
        }
        monthlyLoanPayment={result && (PORTAL_EE || (PORTAL_LV && layout !== 'grid')) ? monthlyLoanPayment : null}
        rentToOwnLabelAsBlock={layout === 'grid'}
      />
    );
  }

  renderProjectLinkedObjects() {
    const { object, layout, browser } = this.props;

    if (!object || !this.getIsVip()) {
      return null;
    }

    const projectId =
      object.get('unit_type') === OBJECT_TYPES.NewProject
        ? object.get('friendly_id')
        : object.getIn(['project', 'friendly_id']);
    const projectName =
      object.get('unit_type') === OBJECT_TYPES.NewProject
        ? object.get('project_name')
        : object.getIn(['project', 'project_name']);
    const realtiesCount =
      object.get('unit_type') === OBJECT_TYPES.NewProject
        ? object.get('realties_count')
        : object.getIn(['project', 'realties_count']);

    if (realtiesCount === 0) {
      return;
    }

    return (
      <LinkedObjects
        projectId={projectId}
        objectId={object.get('id')}
        projectName={projectName}
        realtiesCount={realtiesCount}
        realtiesCountTotal={object.getIn(['attributes', 'NO_OF_APARTMENTS'])}
        asPopup={layout === 'grid' && browser.greaterThan.small}
        compact={layout === 'grid' || browser.lessThan.medium}
      />
    );
  }

  render() {
    const {
      objectType,
      favourite,
      layout,
      result,
      loading,
      image,
      imageAlt,
      sliderImages,
      level,
      address,
      location,
      purposeOfUse,
      newProject,
      projectName,
      swipeable,
      erase,
      hideFavourite,
      showLevel,
      showLoanCta,
      realtiesCount,
      minimal,
      myObject,
      responsiveParams,
      object,
      preview,
      dealTypeString,
      mapOpened,
      latitude,
      longitude,
      specialLabel,
      t,
      className,
      objectRoute,
      light,
      raised,
      showInterestButton,
      showInterestLabel,
      children,
      hideAddress,
      browser,
      containerParams,
      booked,
    } = this.props;

    const { monthlyLoanPayment } = this.state;

    const linkClassName = classNames(
      'object',
      {
        'object--loading': loading,
        'object--np': newProject,
        'object--raised': raised,
        [`object--${layout}`]: layout,
        'object--light': light,
        'object--result': result,
        'object--no-link': !objectRoute,
        'object--vip': result && object && this.getIsVip(),
      },
      className
    );

    const addressTitle = projectName ? `${[projectName, address].filter((i) => i).join(', ')}` : address;

    const hasCoordinates = !!(latitude && longitude);

    return (
      <VisibilitySensor
        onChange={(isVisible) => {
          if (isVisible) {
            this.setState({ isVisible: true });
          }
        }}
        partialVisibility
      >
        <>
          <div className={linkClassName}>
            {this.renderLinkWrapper(
              { className: 'object__image' },
              <>
                {this.renderTags(true)}
                {this.renderTags()}
                {level && showLevel && (
                  <span className="object__level">
                    <Icon name="star" />
                    <span className="object__level-nr">{level}</span>
                  </span>
                )}
                {object &&
                  object.getIn(['project', 'friendly_id']) &&
                  this.getIsVip() &&
                  layout === 'grid' &&
                  result && (
                    <span className="object__project-icon">
                      <Icon name="new-project" />
                    </span>
                  )}
                <div className="object__thumb">
                  {(() => {
                    if (loading) {
                      return <Image src={ObjectPlaceholder} alt="loading" />;
                    }
                    if (
                      result &&
                      (layout === 'grid' ||
                        (object && this.getIsVip() && layout === 'list' && browser.greaterThan.medium)) &&
                      sliderImages &&
                      sliderImages.size > 1
                    ) {
                      return (
                        <ObjectImageSlider
                          maxSlides={1}
                          images={sliderImages}
                          imageAlt={imageAlt}
                          loop
                          onFirstInteraction={() => {
                            ReactGA.pageview(this.getPath());
                            ReactGA.event({
                              category: GA_SEARCH_PAGE,
                              action: 'search_results_gallery_view',
                              label: object.get('friendly_id'),
                            });
                            sendStatistics(
                              isMobile ? PROPERTY_IMPRESSION_MOBILE : PROPERTY_IMPRESSION,
                              object.get('guid')
                            );
                          }}
                        />
                      );
                    }
                    return (
                      <Image
                        lazyload
                        placeholder={ObjectPlaceholder}
                        alt={imageAlt}
                        src={image}
                        width="432"
                        height="288"
                      />
                    );
                  })()}
                  {booked && <div className="object__thumb-booked">{t('objectSpec.booked')}</div>}
                </div>
                {erase && (
                  <button
                    type="button"
                    className={classNames('object__erase', {
                      'object__erase--visible': browser.lessThan.medium,
                    })}
                    onClick={this.removeFavourite}
                  >
                    <Icon name="trash" className="icon--fw" />
                  </button>
                )}
                <div
                  className={classNames('object__action-btns', {
                    'object__action-btns--visible ': browser.lessThan.medium,
                  })}
                >
                  {!hideFavourite && !loading && (
                    <button
                      type="button"
                      className={classNames('object__action-btn object__save', {
                        'object__save--saved': favourite,
                        'object__save--swipeable': swipeable,
                      })}
                      onClick={favourite ? this.removeFavourite : this.saveFavourite}
                    >
                      <Icon name={favourite ? 'heart' : 'heart-o'} className="icon--fw" />
                    </button>
                  )}
                  {browser.greaterThan.small && result && (
                    <button
                      type="button"
                      className="object__action-btn object__broker-contact"
                      onClick={(e) => {
                        this.openBrokerContactModal(e, object);
                      }}
                    >
                      <Icon name="envelope" />
                    </button>
                  )}
                </div>
                {showLoanCta && monthlyLoanPayment ? this.renderObjectLoan(monthlyLoanPayment) : null}
              </>
            )}
            <div className="object__info">
              {object &&
                result &&
                layout === 'grid' &&
                (object.get('unit_type') === OBJECT_TYPES.NewProject || object.get('project')) &&
                this.renderProjectLinkedObjects()}
              {myObject && (
                <div className="object__info-row">
                  <div className="object__category">{t(dealTypeString)}</div>
                  <div className="object__id">{`ID: ${object.get('friendly_id')}`}</div>
                </div>
              )}
              <div className="object__header">
                {this.renderLinkWrapper(
                  {
                    className: 'object__attributes',
                    title: [addressTitle, location].filter(identity).join(','),
                  },
                  <>
                    {this.renderLocationWrapper(
                      <>
                        {browser.greaterThan.small && !loading && result && mapOpened && hasCoordinates && (
                          <Button link onClick={(event) => this.showOnMap(event)}>
                            <Icon name="map-pin" />
                          </Button>
                        )}
                        {(() => {
                          if (loading) {
                            return <LoadingPlaceholderBox size={8} />;
                          }
                          if (addressTitle && !hideAddress) {
                            return `${addressTitle}${layout === 'grid' && location ? ', ' : ''}`;
                          }
                          if (myObject) {
                            return '...... ...... ......';
                          }
                          return null;
                        })()}
                        {specialLabel && (
                          <span className="object__tags-special object__tags-special--info">
                            {t('objectSpec.projectOfTheMonth')}
                          </span>
                        )}
                      </>
                    )}
                    {!hideAddress && (
                      <div className="object__area">{loading ? <LoadingPlaceholderBox size={3} /> : location}</div>
                    )}
                    {browser.lessThan.small && this.renderObjectPrice(monthlyLoanPayment)}
                    <div className="object__features">
                      {this.renderSlogan('list')}
                      {layout === 'list' && (
                        <>
                          {purposeOfUse && (
                            <div className="object__purpose">
                              {purposeOfUse
                                .filter((i) => i !== 'not_defined')
                                .map((i) =>
                                  t(
                                    `${
                                      objectType === OBJECT_TYPES.Land
                                        ? 'PURPOSE_OF_USE_LAND'
                                        : 'PURPOSE_OF_USE_COMMERCIAL'
                                    }.${i}`
                                  )
                                )
                                .join(', ')}
                            </div>
                          )}
                          <ul className="object__main-features">
                            {loading ? <LoadingPlaceholderBox size={12} /> : this.renderMainFeatures()}
                          </ul>
                        </>
                      )}
                      <SecondarySpecs
                        row
                        className="object__secondary-features"
                        rentToOwn={object && object.getIn(['attributes', RENT_TO_OWN])}
                        t={t}
                        minimal
                      />
                      {!minimal && (
                        <>
                          {newProject && realtiesCount > 0 && (
                            <div className="object__realties-count">
                              {t('objectSpec.realtiesCount', {
                                count: realtiesCount,
                              })}
                            </div>
                          )}
                        </>
                      )}
                      {layout === 'list' && this.renderLabels()}
                      {preview && <Button outline text={t('common.preview')} onClick={this.openDetailPreviewModal} />}
                    </div>
                  </>
                )}
                <div className="object__specs">
                  {!browser.lessThan.small && this.renderObjectPrice(monthlyLoanPayment)}
                  {layout === 'grid' && (
                    <>
                      <ul className="object__main-features">
                        {loading ? <LoadingPlaceholderBox size={12} /> : this.renderMainFeatures()}
                      </ul>
                      {this.renderLabels()}
                    </>
                  )}
                  {!(
                    layout === 'list' &&
                    object &&
                    this.getIsVip() &&
                    (browser.lessThan.small || (containerParams && containerParams['results--sm']))
                  ) && this.renderBrokerContactButton()}
                </div>
              </div>
              {children}
              {responsiveParams &&
                responsiveParams['object--lg-up'] &&
                layout !== 'grid' &&
                this.renderDescription(layout)}
              {!(result && !this.getIsVip()) ? this.renderSlogan('grid') : null}
              {showInterestButton && (
                <Button
                  extraSmall
                  className="object__show-interest--btn"
                  icon="send"
                  text={t('common.showInterest')}
                  onClick={(e) => {
                    this.openBrokerContactModal(e, object);
                  }}
                />
              )}
              {showInterestLabel &&
                this.renderLinkWrapper(
                  {},
                  <span className="object__interest-label">{t('common.showInterestLabel')}</span>
                )}
            </div>
            {layout === 'list' &&
              object &&
              this.getIsVip() &&
              (browser.lessThan.small || (containerParams && containerParams['results--sm'])) &&
              this.renderBrokerContactButton()}
          </div>
          {object &&
            result &&
            layout === 'list' &&
            (object.get('unit_type') === OBJECT_TYPES.NewProject || object.get('project')) &&
            this.renderProjectLinkedObjects()}
        </>
      </VisibilitySensor>
    );
  }
}

RealEstateObject.propTypes = propTypes;
RealEstateObject.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    locale: getLocale(state),
    mapOpened: getMapOpened(state),
    browser: getBrowser(state),
    luminorLinks: getLuminorLinks(state),
    euriborRate: getEuriborRate(state),
  };
}

const mapDispatchToProps = {
  setObjectNavCount,
  openBrokerContactModal,
  openDetailPreviewModal,
  saveFavourite,
  removeFavourite,
  setObjectToMarker,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RealEstateObject);
