import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RealtyListingShort } from '@city24/common/types/Realty';
import { ProjectPublic } from '@city24/common/types/public/RealtyPublic';

const initialState: {
  project?: ProjectPublic;
  realties: RealtyListingShort[];
} = {
  project: undefined,
  realties: [],
};

const promoPackageBlockSlice = createSlice({
  name: 'promoPackageBlock',
  initialState,
  reducers: {
    getProject(state, action: PayloadAction<ProjectPublic>) {
      state.project = action.payload;
    },
    getProjectRealties(state, action: PayloadAction<RealtyListingShort[]>) {
      state.realties = action.payload;
    },
  },
});

export const { actions } = promoPackageBlockSlice;

export default promoPackageBlockSlice.reducer;
