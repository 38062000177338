import { isImmutable, List } from 'immutable';
import { identity } from './helpers';

export function getBrokerLogoUrl(broker) {
  let url;
  if (isImmutable(broker)) {
    const businesCard = broker.get('business_card');
    url = (businesCard && businesCard.get('logo_url')) || broker.get('logo_url') || '';
  } else {
    const { business_card } = broker;
    if (business_card) {
      url = business_card.logo_url;
    } else {
      url = broker.logo_url;
    }
  }
  return url;
}

export function getBrokerName(broker) {
  let firstName;
  let lastName;
  if (isImmutable(broker)) {
    const businesCard = broker.get('business_card');
    firstName = (businesCard && businesCard.get('first_name')) || broker.get('first_name') || '';
    lastName = (businesCard && businesCard.get('last_name')) || broker.get('last_name') || '';
  } else {
    const { business_card } = broker;
    if (business_card) {
      firstName = business_card.first_name;
      lastName = business_card.last_name ?? '';
    } else {
      firstName = broker.first_name;
      lastName = broker.last_name ?? '';
    }
  }
  if (firstName) {
    return `${firstName} ${lastName}`;
  }
  return lastName;
}

export function getUserFullName(user) {
  return user.firstName && `${user.firstName} ${user.lastName}`;
}

export function getContact(broker, type) {
  if (isImmutable(broker)) {
    const businessCard = broker.get('business_card');

    if (businessCard) {
      let result = List();
      const contacts = businessCard.get('additional_contacts');
      if (contacts) {
        if (type === 'phone') {
          result = contacts.filter((i) => i.get('dtype') === 'PHONE');
        } else if (type === 'email') {
          result = contacts.filter((i) => i.get('dtype') === 'EMAIL');
        }
      }
      return result.map((i) => i.get('value'));
    }
    if (type === 'phone') {
      return List([broker.get('phone_number'), broker.get('phone_number2')]).filter(identity);
    }
    if (type === 'email') {
      return List([broker.get('email_address')]);
    }
    return List();
  }
  const { business_card } = broker;
  const { additional_contacts } = business_card || {};

  if (business_card) {
    let result = [];
    if (additional_contacts) {
      if (type === 'phone') {
        result = additional_contacts.filter((i) => i.dtype === 'PHONE');
      } else if (type === 'email') {
        result = additional_contacts.filter((i) => i.dtype === 'EMAIL');
      }
    }
    return result.map((i) => i.value).filter(identity);
  }
  if (type === 'phone') {
    return [broker.phone_number, broker.phone_number2].filter(identity);
  }
  if (type === 'email') {
    return [broker.email_address];
  }
  return [];
}

export function getBrokerBrandOptions(businessCard) {
  if (!businessCard) {
    return { active_branding: false };
  }

  return isImmutable(businessCard)
    ? {
        active_branding: businessCard.get('active_branding'),
        color_hex_code: businessCard.get('color_hex_code'),
        cover_url: businessCard.get('cover_url'),
        logo_url: businessCard.get('logo_url'),
      }
    : {
        active_branding: businessCard.active_branding,
        color_hex_code: businessCard.color_hex_code,
        cover_url: businessCard.cover_url,
        logo_url: businessCard.logo_url,
      };
}
