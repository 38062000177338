import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import Icon from '../../icon/Icon';

export const FilterContext = React.createContext({});

const propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  appliedFilterCount: PropTypes.number,
  value: PropTypes.string,
  hideArrow: PropTypes.bool,
};

const defaultProps = {
  type: '',
  value: '',
  children: null,
  appliedFilterCount: null,
  hideArrow: false,
};

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      opened: false,
    };
  }

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  handleClick = () => {
    const { type } = this.props;
    const { opened } = this.state;

    if (type !== 'checkbox' && !opened) {
      this.openDropDown();
    } else {
      this.closeDropDown();
    }
    this.toggleCheck();
  };

  handleClickOutside = () => {
    const { opened } = this.state;
    if (opened) {
      this.closeDropDown();
    }
  };

  handleKeypress = (e) => {
    const { focused } = this.state;

    const spaceKey = 32;
    const enterKey = 13;

    if (focused && [spaceKey, enterKey].includes(e.charCode)) {
      this.setState({ opened: true });
    }
  };

  toggleCheck() {
    const { checked, value, getValue } = this.props;

    if (getValue) getValue(!checked, value);
  }

  openDropDown() {
    this.setState({ opened: true });
  }

  closeDropDown() {
    this.setState({ opened: false });
  }

  renderIcon() {
    const { type, checked, icon } = this.props;
    if (type === 'checkbox') {
      if (checked) {
        return <Icon name="check" />;
      }
    } else if (icon) {
      return <Icon name={icon} />;
    }
    return null;
  }

  renderCount() {
    const { appliedFilterCount } = this.props;
    if (appliedFilterCount) {
      return <span className="filter__applied-count">{appliedFilterCount}</span>;
    }
    return null;
  }

  renderLabel() {
    const { label } = this.props;

    return (
      <>
        {this.renderIcon()}
        {this.renderCount()}
        {label}
      </>
    );
  }

  renderDropdown() {
    const { type, children } = this.props;
    const { opened } = this.state;
    if (type !== 'checkbox' && opened) {
      return (
        <div className="filter__dropdown">
          <div className="filter__content">{children}</div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { focused, opened } = this.state;
    const { blur, active, hideArrow } = this.props;

    const filterSelectStyle = classNames({
      filter__select: true,
      'filter__select--blur': blur,
      'filter__select--focused': focused,
      'filter__select--active': opened || active,
    });

    return (
      <FilterContext.Provider
        value={{
          closeFilter: () => this.closeDropDown({ apply: true }),
        }}
      >
        <OutsideClickHandler disabled={!opened} onOutsideClick={this.handleClickOutside}>
          <div className="filter">
            <div
              role="button"
              tabIndex="0"
              className={filterSelectStyle}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyPress={this.handleKeypress}
              onClick={this.handleClick}
            >
              {this.renderLabel()}
              {!hideArrow && <Icon name="angle-down" />}
            </div>
            {this.renderDropdown()}
          </div>
        </OutsideClickHandler>
      </FilterContext.Provider>
    );
  }
}

Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;

export default Filter;
