import React from 'react';
import { TFunction } from 'next-i18next';
import { composeSearchURLFromParams, createLocationSearchTermFromAddress } from '@/utils/search';
import { addressComponent, houseAndApartmentComponent, streetComponent } from '@/utils/realty/address';
import { objectTransactionString } from '@/utils/realty';

import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb';
import LoadingPlaceholderBox from '@/components/loading/LoadingPlaceholderBox';
import { useAppSelector } from '@/hooks';
import { getObjectDetailsJS } from './objectDetailSelectors';

interface Props {
  routes: Parameters<typeof composeSearchURLFromParams>[0];
  currentPath: string;
  loading?: boolean;
  t: TFunction;
}

const ObjectBreadcrumbs = ({ routes, currentPath, loading = false, t }: Props): React.ReactElement | null => {
  const object = useAppSelector(getObjectDetailsJS);

  if (loading) {
    return (
      <Breadcrumbs minimal>
        <Breadcrumb to="#">
          <LoadingPlaceholderBox size={8} />
        </Breadcrumb>
        <Breadcrumb to="#">
          <LoadingPlaceholderBox size={8} />
        </Breadcrumb>
        <Breadcrumb to="#">
          <LoadingPlaceholderBox size={8} />
        </Breadcrumb>
        <Breadcrumb to="#">
          <LoadingPlaceholderBox size={8} />
        </Breadcrumb>
      </Breadcrumbs>
    );
  }

  if (!object?.address) {
    return null;
  }

  const transactionString = t(objectTransactionString(object.unit_type, object.transaction_type, true)!);

  return (
    <Breadcrumbs minimal>
      <Breadcrumb
        to={composeSearchURLFromParams(routes, object.unit_type, object.transaction_type)}
        name={transactionString}
      >
        <u>{transactionString}</u>
      </Breadcrumb>
      {(['county', 'parish', 'city'] as ('county' | 'parish' | 'city')[])
        .filter((key) => object.address[key])
        .slice(-2)
        .map((key) => {
          const component = object.address[key]!;
          return (
            <Breadcrumb
              key={component.id}
              to={composeSearchURLFromParams(routes, object.unit_type, object.transaction_type, {
                searchTerm: createLocationSearchTermFromAddress(key, object.address),
              })}
              name={component.name}
            >
              <u>{component.name}</u>
            </Breadcrumb>
          );
        })}
      <Breadcrumb to={currentPath} name={addressComponent(object.address)}>
        {streetComponent(object.address, houseAndApartmentComponent(object.address))}
      </Breadcrumb>
    </Breadcrumbs>
  );
};

export default ObjectBreadcrumbs;
