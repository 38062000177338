import React, { useMemo } from 'react';
import Select, { components } from 'react-select';
import Icon from '../icon/Icon';
import { normalizeString } from '../../utils/formatting';

const filterOption = (option, input) => {
  if (input.length > 0) {
    const comparableSection = option.label.slice(0, input.length);
    if (normalizeString(comparableSection.toLowerCase()) === normalizeString(input.toLowerCase())) {
      return true;
    }
  } else {
    return true;
  }
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="angle-down" className="select__arrow" />
    </components.DropdownIndicator>
  );
};

function AutocompleteSelect({
  t,
  translateOption,
  options,
  selected,
  multi,
  disabled,
  scrollToFocus = false,
  onChange,
}) {
  const [selectOptions, selectedOption] = useMemo(() => {
    let selectedOpt;
    const createOptions = (opt) => {
      if (opt.get('options')) {
        const opts = opt.get('options').map(createOptions).toArray();
        return {
          label: t(opt.get('name')),
          options: opts,
        };
      }
      const newOpt = {
        label: (translateOption && translateOption(opt)) || opt.get('name'),
        value: opt.get('value'),
      };
      if (!selectedOpt) {
        selectedOpt = newOpt.value === selected.get('value') ? newOpt : null;
      }
      return newOpt;
    };
    const opts = options.map(createOptions).toArray();
    return [opts, selectedOpt];
  }, [options, selected, translateOption]);

  return (
    <Select
      disabled
      classNamePrefix="autocomplete-select"
      multi={multi}
      value={selectedOption}
      options={selectOptions}
      onChange={onChange}
      placeholder={t('select.pleaseSelect')}
      noOptionsMessage={() => t('select.noOptions')}
      components={{ DropdownIndicator }}
      isDisabled={disabled}
      menuShouldScrollIntoView={scrollToFocus}
      filterOption={filterOption}
    />
  );
}

export default AutocompleteSelect;
