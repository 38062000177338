import React from 'react';
import { TFunction } from 'next-i18next';

import RegisterModal from './RegisterModal/RegisterModal';
import SignInModal from './SignInModal/SignInModal';
import PasswordResetModal from './PasswordResetModal/PasswordResetModal';

interface Props {
  t: TFunction;
}

function AuthModals({ t }: Props): React.ReactElement {
  return (
    <>
      <SignInModal t={t} />
      <RegisterModal t={t} />
      <PasswordResetModal t={t} />
    </>
  );
}

export default AuthModals;
