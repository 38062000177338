import React, { useMemo } from 'react';
import { Collection, fromJS, isImmutable } from 'immutable';
import type { TFunction } from 'next-i18next';

import { NameValue } from '@/types/collections';
import { ROOMS_OPTIONS } from '@/constants/filters';
import { GA_SEARCH } from '@/constants/ga';
import InlineSelect from '@/components/select/InlineSelect';

interface Props {
  t: TFunction;
  rooms: Collection.Indexed<NameValue> | NameValue[];
  handleRooms: (value: any) => void;
  label?: string;
}

const Rooms = ({ rooms, handleRooms, label, t }: Props) => {
  const selected = useMemo(() => {
    return isImmutable(rooms) ? rooms : (fromJS(rooms) as Collection.Indexed<NameValue>);
  }, [rooms]);

  return (
    <>
      <div className="filter__heading">
        <h4 className="filter__title">{label || t('objectSpec.rooms')}</h4>
      </div>
      <InlineSelect
        multiple
        extended
        options={ROOMS_OPTIONS}
        selected={selected}
        getValue={handleRooms}
        gaCategory={GA_SEARCH}
        gaAction="select_rooms"
      />
    </>
  );
};

export default Rooms;
