import React, { useContext, useEffect, useMemo } from 'react';
import { fromJS } from 'immutable';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { TFunction } from 'next-i18next';
import ReactGA from '@/utils/ga';
import Language from '@city24/common/enums/Language';

import { NameValue } from '@/types/collections';
import { ROUTES } from '@/constants/routes';
import { GA_HEADER_MENU } from '@/constants/ga';
import { fetchFavourites, fetchSavedSearches } from '@/actions/userActions';

import AppContext from '@/components/appContext';
import Button from '@/components/button/NextButton';
import ButtonGroup from '@/components/button/ButtonGroup';
import SocialMediaLinks from '@/components/socialMediaLinks/SocialMediaLinks';
import Overlay from '@/components/common/Overlay';
import Icon from '@/components/icon/Icon';
import InlineSelect from '@/components/select/InlineSelect';
import MobileAppLinks from '@/components/mobileAppLinks/MobileAppLinks';
import { getUserFullName } from '@/utils/contacts';
import LinkButton from '@/components/button/LinkButton';
import LegacyToNextLink from '@/components/link/LegacyToNextLink';
import { HeaderComponentProps } from './BaseHeader';

interface Props extends Pick<HeaderComponentProps, 'user' | 'favourites' | 'savedSearches' | 'language'> {
  isOpen?: boolean;
  isClosing?: boolean;
  closeMobMenu: () => void;
  signIn: () => void;
  signOut: () => void;
  t: TFunction;
  langOpts: NameValue<Language>[];
  routes: typeof ROUTES;
  changeLanguage: (lang: Language) => void;
}

function MobNav({
  user,
  favourites,
  savedSearches,
  closeMobMenu,
  signIn,
  signOut,
  isOpen,
  isClosing,
  t,
  language,
  langOpts,
  routes,
  changeLanguage,
}: Props) {
  const dispatch = useDispatch();
  const { Link } = useContext(AppContext);
  useEffect(() => {
    dispatch(fetchFavourites());
    dispatch(fetchSavedSearches());
  }, []);
  const mobNavStyle = classNames('mob-nav-wrap', {
    'mob-nav-wrap--open': isOpen,
    'mob-nav-wrap--closing': isClosing,
  });

  const langSelectOpts = useMemo(() => fromJS(langOpts), [langOpts]);

  return (
    <div className={mobNavStyle}>
      <Overlay dark onClick={closeMobMenu} />
      <Button
        className="mob-nav__close"
        bgColor="transparent"
        size="sm"
        icon="close"
        onClick={() => {
          closeMobMenu();
          ReactGA.event({
            category: GA_HEADER_MENU,
            action: 'close_button',
          });
        }}
      />
      <div className="mob-nav">
        <div className="mob-nav__section">
          {user.signedIn ? <h2>{getUserFullName(user)}</h2> : null}
          <ul className="mob-nav__links">
            {/*
            // NOT IN PHASE 1
            {user.signedIn ? (
              <li>
                <Link
                  onClick={() => {
                    ReactGA.event({
                      category: GA_HEADER_MENU,
                      action: 'click_discover'
                    });
                  }}
                  to="/discover"
                >
                  <span className="icon icon-chest" />
                  {t('mobNav.discover')}
                </Link>
              </li>
            ) : null} */}
            <li>
              <Link
                onClick={() => {
                  ReactGA.event({
                    category: GA_HEADER_MENU,
                    action: 'click_favourites',
                  });
                }}
                href="/favourites"
              >
                <Icon name="heart-o" />
                <span className="mob-nav__text">{t('mobNav.favourites')}</span>
                {favourites.size > 0 && <span className="mob-nav__count">{favourites.size}</span>}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  ReactGA.event({
                    category: GA_HEADER_MENU,
                    action: 'click_searches',
                  });
                }}
                href="/my-searches"
              >
                <Icon name="my-searches" />
                <span className="mob-nav__text">{t('mobNav.mySearches')}</span>
                {savedSearches.size > 0 && <span className="mob-nav__count">{savedSearches.size}</span>}
              </Link>
            </li>
            {user.signedIn ? (
              <>
                <li>
                  <Link
                    onClick={() => {
                      ReactGA.event({
                        category: GA_HEADER_MENU,
                        action: 'click_myObjects',
                      });
                    }}
                    href="/my-objects"
                  >
                    <span className="icon icon-house" />
                    {t('mobNav.myObjects')}
                  </Link>
                </li>
                {/*
                <li>
                  <Link
                    onClick={() => {
                      ReactGA.event({
                        category: GA_HEADER_MENU,
                        action: 'click_inbox'
                      });
                    }}
                    to="/inbox"
                  >
                    <span className="icon icon-envelope" />
                    {t('mobNav.inbox')}
                  </Link>
                </li>
                 */}
                <li>
                  <Link
                    onClick={() => {
                      ReactGA.event({
                        category: GA_HEADER_MENU,
                        action: 'click_invoices',
                      });
                    }}
                    href="/invoices"
                  >
                    <span className="icon icon-receipt" />
                    {t('mobNav.invoices')}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      ReactGA.event({
                        category: GA_HEADER_MENU,
                        action: 'click_settings',
                      });
                    }}
                    href="/settings"
                  >
                    <span className="icon icon-settings" />
                    {t('mobNav.settings')}
                  </Link>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      signOut();
                      ReactGA.event({
                        category: GA_HEADER_MENU,
                        action: 'click_signOut',
                      });
                    }}
                  >
                    <Icon name="sign-out" />
                    {t('common.signOut')}
                  </button>
                </li>
              </>
            ) : null}
          </ul>
          <ButtonGroup vertical className="mob-nav__btns">
            {user.signedIn ? null : (
              <Button
                outline
                extended
                onClick={() => {
                  signIn();
                  ReactGA.event({
                    category: GA_HEADER_MENU,
                    action: 'open_signIn_button',
                  });
                }}
              >
                {t('common.signIn')}
              </Button>
            )}

            <LinkButton
              href={generatePath(routes.myObject, { id: 'new' })}
              btnType="primary"
              extended
              onClick={() => {
                ReactGA.event({
                  category: GA_HEADER_MENU,
                  action: 'click_placeAd',
                });
              }}
            >
              {t('common.placeAd')}
            </LinkButton>
          </ButtonGroup>
        </div>
        {langSelectOpts && (
          <div className="mob-nav__section">
            <InlineSelect
              small
              minimal
              options={langSelectOpts}
              selected={langSelectOpts.find((opt) => opt.get('value') === language)}
              getValue={(opt) => changeLanguage(opt.get('value'))}
            />
          </div>
        )}
        <div className="mob-nav__section">
          <ul className="mob-nav__links">
            <li>
              <Link
                onClick={() => {
                  ReactGA.event({
                    category: GA_HEADER_MENU,
                    action: 'click_newProjects',
                  });
                }}
                href={routes.newProjects}
              >
                {t('mobNav.newProjects')}
              </Link>
            </li>

            {
              // NOT IN PHASE 1
              /* <li>
              <Link
                onClick={() => {
                  ReactGA.event({
                    category: GA_HEADER_MENU,
                    action: 'click_loan'
                  });
                }}
                to="/new-projects"
              >
                {t('mobNav.homeLoan')}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  ReactGA.event({
                    category: GA_HEADER_MENU,
                    action: 'click_insurance'
                  });
                }}
                to="/new-projects"
              >
                {t('mobNav.homeInsurance')}
              </Link>
            </li> */
            }
            <li>
              <Link
                onClick={() => {
                  ReactGA.event({
                    category: GA_HEADER_MENU,
                    action: 'click_news',
                  });
                }}
                href={generatePath(routes.news)}
              >
                {t('mobNav.news')}
              </Link>
            </li>
            {PORTAL_EE && (
              <li>
                <Link
                  onClick={() => {
                    ReactGA.event({
                      category: GA_HEADER_MENU,
                      action: 'click_home_loan',
                    });
                  }}
                  href={routes.financeCenter}
                >
                  {t('mobNav.homeLoan')}
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="mob-nav__section">
          <ul className="mob-nav__links">
            <li>
              <LegacyToNextLink
                onClick={() => {
                  ReactGA.event({
                    category: GA_HEADER_MENU,
                    action: 'click_priceListPrivateUser',
                  });
                }}
                href={routes.pricelist}
              >
                {t('mobNav.priceList')}
              </LegacyToNextLink>
            </li>
            <li>
              <LegacyToNextLink
                onClick={() => {
                  ReactGA.event({
                    category: GA_HEADER_MENU,
                    action: 'click_contacts',
                  });
                }}
                href={routes.contacts}
              >
                {t('mobNav.contacts')}
              </LegacyToNextLink>
            </li>
            <li>
              <LegacyToNextLink
                onClick={() => {
                  ReactGA.event({
                    category: GA_HEADER_MENU,
                    action: 'click_privacyPolicy',
                  });
                }}
                href={routes.privacyRegulations}
              >
                {PORTAL_EE ? t('footer.site.privacyPolicy') : t('footer.site.privacyAndCookies')}
              </LegacyToNextLink>
            </li>
            {PORTAL_EE && (
              <li>
                <LegacyToNextLink
                  onClick={() => {
                    ReactGA.event({
                      category: GA_HEADER_MENU,
                      action: 'click_cookies',
                    });
                  }}
                  href={routes.cookiePolicy}
                >
                  {t('footer.site.cookies')}
                </LegacyToNextLink>
              </li>
            )}
          </ul>
        </div>
        <div className="mob-nav__section">
          <SocialMediaLinks gaCategory={GA_HEADER_MENU} t={t} />
        </div>
        {/*<div className="mob-nav__section">
          <MobileAppLinks t={t} />
        </div>*/}
      </div>
    </div>
  );
}

export default MobNav;
