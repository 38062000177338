import React, { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import { getOpenDaysDate } from '@/utils/formatting';

import Icon from '@/components/icon/Icon';
import openDaysApi, { OpenDaysDetails } from '@/api/openDaysApi';

interface Props {
  t: TFunction;
  friendlyId?: string;
  projectFriendlyId?: string;
  children?: string | boolean;
}

function OpenDaysLabel({ t, friendlyId, projectFriendlyId, children }: Props): React.ReactElement | null {
  const [details, setDetails] = useState<OpenDaysDetails | null>(null);

  const fetchOpenDaysDetails = () => {
    if (friendlyId) {
      const fetchResults = (id: string) =>
        openDaysApi.getOpenDays(id).then((res) => res.json()) as Promise<OpenDaysDetails[]>;
      fetchResults(friendlyId)
        .then((details) => {
          // When result set is empty but this object belongs to a project, check for project's details as well
          if (details.length === 0 && projectFriendlyId) {
            return fetchResults(projectFriendlyId);
          }
          return details;
        })
        .then((details) => {
          setDetails((Array.isArray(details) && details[0]) || null);
        });
    }
  };

  const useDefaultText = !children || children === true;

  useEffect(() => {
    if (useDefaultText && friendlyId) {
      const fetchResults = (id: string) =>
        openDaysApi.getOpenDays(id).then((res) => res.json()) as Promise<OpenDaysDetails[]>;
      fetchResults(friendlyId)
        .then((details) => {
          // When result set is empty but this object belongs to a project, check for project's details as well
          if (details.length === 0 && projectFriendlyId) {
            return fetchResults(projectFriendlyId);
          }
          return details;
        })
        .then((details) => {
          setDetails((Array.isArray(details) && details[0]) || null);
        });
      fetchOpenDaysDetails();
    }
  }, [useDefaultText, friendlyId]);

  let openDaysText = children;
  if (useDefaultText) {
    openDaysText = t('objectSpec.hasOpenDays') as string;
    if (details) {
      const date = getOpenDaysDate(details);
      if (date) {
        openDaysText += ` ${date}`;
      }
    }
  }

  if (!openDaysText) {
    return null;
  }
  return (
    <li className="object__labels-open">
      <Icon name="open-house" />
      <span>{openDaysText}</span>
    </li>
  );
}

export default OpenDaysLabel;
