import React from 'react';
import classNames from 'classnames';

class ButtonGroup extends React.PureComponent {
  render() {
    const { vertical, className, children, wide, tight, fixed, extended, center, right } = this.props;

    const btnGroupStyle = classNames(
      {
        'btn-group': true,
        'btn-group--fixed': fixed,
        'btn-group--extended': extended,
        'btn-group--vertical': vertical,
        'btn-group--wide': wide,
        'btn-group--tight': tight,
        'btn-group--center': center,
        'btn-group--right': right,
      },
      className
    );

    return <div className={btnGroupStyle}>{children}</div>;
  }
}

export default ButtonGroup;
