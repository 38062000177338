import { Map, List } from 'immutable';

import {
  GET_OM_COUNTY_OPTIONS,
  GET_OM_CITY_PARISH_OPTIONS,
  GET_OM_DISTRICT_SETTLEMENT_OPTIONS,
} from '../../constants/actions';
import { DataLoadProxy } from '../../utils/collections';

const initialState = Map({
  areas: Map({
    counties: new DataLoadProxy(List()),
    citiesParishes: new DataLoadProxy(List()),
    districtsSettlements: new DataLoadProxy(List()),
  }),
});

export default function locationView(state = initialState, action) {
  switch (action.type) {
    case GET_OM_COUNTY_OPTIONS:
      return state.setIn(['areas', 'counties'], action.counties);

    case GET_OM_CITY_PARISH_OPTIONS:
      return state.setIn(['areas', 'citiesParishes'], action.citiesParishes);

    case GET_OM_DISTRICT_SETTLEMENT_OPTIONS:
      return state.setIn(['areas', 'districtsSettlements'], action.settlements);

    default:
      return state;
  }
}
