import React from 'react';
import { useTranslation } from 'react-i18next';

import { AsyncDataStatus } from '@/types/collections';
import { useAppSelector } from '@/hooks';
import { getLinkedObjects } from '@/components/search/searchSelectors';
import { getBrowser, getPrefixedRoutes } from '@/selectors/appSelectors';

import ObjectList from '@/components/object/ObjectsList/ObjectList';
import Modal from '@/components/modals/Modal';

type Props = {
  isOpen: boolean;
  objectId: string;
  projectName?: string;
  closeModal: () => void;
};

const ObjectListModal = ({ isOpen, objectId: projectId, projectName, closeModal }: Props): React.ReactElement => {
  const [t] = useTranslation();
  const browser = useAppSelector(getBrowser);
  const routes = useAppSelector(getPrefixedRoutes);
  const realtiesProxy = useAppSelector(getLinkedObjects(projectId));

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className="object-list-modal">
      {projectName && <h4>{projectName}</h4>}
      {realtiesProxy.status === AsyncDataStatus.Loaded && realtiesProxy.data.length > 0 && (
        <ObjectList
          objects={realtiesProxy.data}
          routes={routes}
          onlyMainFeatures={browser.lessThan.medium}
          newTab
          t={t}
        />
      )}
    </Modal>
  );
};

export default ObjectListModal;
