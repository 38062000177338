import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Loading(props) {
  const { className, style, text, inline, small, large, full } = props;

  const loaderStyle = classNames(
    'loader',
    {
      'loader--small': small,
      'loader--large': large,
      'loader--full': full,
      'loader--inline': inline,
    },
    className
  );

  return (
    <div className={loaderStyle} style={style}>
      <div className="loader__anim">Loading...</div>
      <div className="loader__text">{text}</div>
    </div>
  );
}

Loading.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  full: PropTypes.bool,
};
Loading.defaultProps = {
  className: '',
  style: undefined,
  text: '',
  small: false,
  large: false,
  full: false,
};

export default Loading;
