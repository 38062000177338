import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Modal from '../Modal';
import SEBCalculator from '@/components/SEBCalculator/SEBCalculator';
import { getSEBCalculatorNewsArticles } from '@/components/object/objectDetailSelectors';
import { fetchSEBCalculatorArticles } from '@/components/SEBCalculator/SEBCalculatorActions';
import { getLocale } from '@/selectors/appSelectors';
import ModalLogo from '@/img/SEB/seb_green_bg_logo.svg';
import { defaultSebParams } from '@/components/finance/Calculators/loanCalculation';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const defaultProps = {};

const SEBCalculatorModal = (props) => {
  const { isOpen, closeModal, t, SEBCalculatorArticles, fetchSEBCalculatorArticles, price, transactionType, locale } =
    props;

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} className="seb-calculator-modal">
      <SEBCalculator
        price={price}
        transactionType={transactionType}
        articles={SEBCalculatorArticles}
        fetchArticles={fetchSEBCalculatorArticles}
        calculatorTitle={t('loanCalc.calculatorTitle')}
        infoTitle={t('loanCalc.infoTitle')}
        ctaHref={locale === 'ru-RU' ? defaultSebParams.modalCalculatorLink.ru : defaultSebParams.modalCalculatorLink.lv}
        t={t}
      />
      <img src={ModalLogo} alt="SEB" className="seb-calculator-modal__logo" />
    </Modal>
  );
};

SEBCalculatorModal.propTypes = propTypes;
SEBCalculatorModal.defaultProps = defaultProps;
function mapStateToProps(state) {
  return {
    SEBCalculatorArticles: getSEBCalculatorNewsArticles(state),
    locale: getLocale(state),
  };
}
const mapDispatchToProps = {
  fetchSEBCalculatorArticles,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SEBCalculatorModal);
