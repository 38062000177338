import { authorizedFetch } from './user';

function getPrivateRealtyDetails(id, query, init) {
  return authorizedFetch(`/realties/${id}/private`, query, init);
}

function createNewRealty(body, query, init = {}) {
  return authorizedFetch('/realties', query, { ...init, method: 'POST', body });
}

function updateRealty(id, body, query, init = {}) {
  return authorizedFetch(`/realties/${id}`, query, { ...init, method: 'PUT', body });
}

function getImages(id, query, init) {
  return authorizedFetch(`/realties/${id}/private/images`, query, init);
}

function addImage(realtyId, body, query, init = {}) {
  return authorizedFetch(`/realties/${realtyId}/images`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function updateImage(id, body, query, init = {}) {
  return authorizedFetch(`/realty_images/${id}`, query, {
    ...init,
    method: 'POST',
    body,
  });
}

function updateImages(realtyId, body, query, init = {}) {
  return authorizedFetch(`/realties/${realtyId}/images`, query, {
    ...init,
    method: 'PATCH',
    body,
  });
}

function deleteImage(id, query, init = {}) {
  return authorizedFetch(`/realty_images/${id}`, query, { ...init, method: 'DELETE' });
}

function getPublishingPrices(id, query, init) {
  return authorizedFetch(`/realties/${id}/pricing`, query, init, true);
}

function setBooking(body, query, init = {}) {
  return authorizedFetch('/bookings', query, { ...init, method: 'POST', body });
}

function getContactRequests(query, init) {
  return authorizedFetch('/contact_requests', query, init);
}

export default {
  getPrivateRealtyDetails,
  createNewRealty,
  updateRealty,
  getImages,
  addImage,
  updateImage,
  updateImages,
  deleteImage,
  getPublishingPrices,
  setBooking,
  getContactRequests,
};
