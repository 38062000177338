import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import FancySection from '../ModalSections/FancySection';

import Success from '../../../img/icons/success.svg';
import Button from '../../button/Button';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const defaultProps = {};

const SuccessfulActionModal = (props) => {
  const { isOpen, closeModal, t, actionType } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <FancySection
        image={Success}
        title={t('successfulAction.title')}
        subtitle={actionType ? t(`successfulAction.${actionType}`) : t('successfulAction.subtitle')}
      />
      <Button primary extended text={t('common.close')} onClick={closeModal} />
    </Modal>
  );
};

SuccessfulActionModal.propTypes = propTypes;
SuccessfulActionModal.defaultProps = defaultProps;

export default SuccessfulActionModal;
