import React, { useContext } from 'react';

import { AccordionContext } from './Accordion';

interface Props {
  children: React.ReactNode;
  className?: string;
}

function AccordionHead({ children, className }: Props) {
  const { toggleOpen } = useContext(AccordionContext);

  return (
    <button role="tab" className={`accordion__head ${className}`} onClick={toggleOpen}>
      {children}
    </button>
  );
}

export default AccordionHead;
