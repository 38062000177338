import { fromJS } from 'immutable';
import { NameValue } from '@/utils/collections';
import { OBJECT_TYPES } from './object';
import {
  BASEMENT,
  SANITARY_ARRANGEMENTS,
  PARKING_SELECTION,
  FURNITURE,
  HEATING_SYSTEM,
  LIFT,
  MEETING_ROOM,
  VENTILATION_TYPE,
  HAS_SEWERAGE,
  ELECTRICITY,
  WATER,
  SECURITY,
  HAS_BALCONY,
  HAS_VIRTUAL_TOUR,
  HAS_OPEN_DAYS,
} from './attributes';
import {
  SEO_URL_CONDITION,
  SEO_URL_FLOOR,
  SEO_URL_LAST_FLOOR,
  SEO_URL_LOT,
  SEO_URL_MATERIAL,
  SEO_URL_OPEN_HOUSE,
  SEO_URL_SPECIAL_OFFERS,
  SEO_URL_OWNER,
  SEO_URL_PRICE,
  SEO_URL_PURPOSE,
  SEO_URL_ROOMS,
  SEO_URL_SEARCH_TERM,
  SEO_URL_SIZE,
  SEO_URL_VIRTUAL,
  SEO_URL_YEAR_BUILT,
  SEO_URL_ENERGY_CERTIFICATE,
} from './seo';
import Portal from '@city24/common/enums/Portal';

// PRICE TYPE OPTIONS
export const PRICE_TYPE_OPTIONS = fromJS([
  { name: '€', value: 'eur', default: true },
  { name: '€/m²', value: 'm2' },
]);

export const SIZE_ID_SQUARE_METER = 1;
export const SIZE_ID_ACRE = 4;
export const SIZE_ID_HECTARE = 5;

// LOT SIZE TYPE OPTIONS
export const LOT_SIZE_TYPE_OPTIONS = fromJS([
  { name: 'm²', value: 'm2', default: true, id: SIZE_ID_SQUARE_METER },
  { name: 'ha', value: 'ha', id: SIZE_ID_HECTARE },
]);

// ROOMS OPTIONS
export const ROOMS_OPTIONS = fromJS([
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5+', value: '5+' },
]);

// EXTRAS
export const EXTRAS_OPTIONS = fromJS({
  [OBJECT_TYPES.Apartment]: [
    { urlId: 1, name: 'objectSpec.elevator', value: LIFT },
    { urlId: 2, name: 'search.hasBalcony', value: HAS_BALCONY },
    { urlId: 3, name: 'objectSpec.basement', value: BASEMENT },
    {
      urlId: 4,
      name: 'SANITARY_ARRANGEMENTS.sauna',
      value: SANITARY_ARRANGEMENTS.SAUNA,
      group: 'SANITARY_ARRANGEMENTS',
    },
    {
      urlId: 5,
      name: 'SANITARY_ARRANGEMENTS.bath',
      value: SANITARY_ARRANGEMENTS.BATH,
      group: 'SANITARY_ARRANGEMENTS',
    },
    {
      urlId: 6,
      name: 'PARKING_SELECTION.Garage',
      value: PARKING_SELECTION.GARAGE,
      group: 'PARKING_SELECTION',
    },
    { urlId: 7, name: 'objectSpec.furniture', value: FURNITURE },
    {
      urlId: 8,
      name: 'HEATING_SYSTEM.fireplace',
      value: HEATING_SYSTEM.FIREPLACE,
      group: 'HEATING_SYSTEM',
    },
  ],
  [OBJECT_TYPES.House]: [
    {
      urlId: 1,
      name: 'HEATING_SYSTEM.fireplace',
      value: HEATING_SYSTEM.FIREPLACE,
      group: 'HEATING_SYSTEM',
    },
    {
      urlId: 2,
      name: 'SANITARY_ARRANGEMENTS.sauna',
      value: SANITARY_ARRANGEMENTS.SAUNA,
      group: 'SANITARY_ARRANGEMENTS',
    },
    { urlId: 3, name: 'objectSpec.basement', value: BASEMENT },
    {
      urlId: 4,
      name: 'PARKING_SELECTION.Garage',
      value: PARKING_SELECTION.GARAGE,
      group: 'PARKING_SELECTION',
    },
    { urlId: 5, name: 'objectSpec.furniture', value: FURNITURE },
  ],
  [OBJECT_TYPES.HouseShare]: [
    {
      urlId: 1,
      name: 'HEATING_SYSTEM.fireplace',
      value: HEATING_SYSTEM.FIREPLACE,
      group: 'HEATING_SYSTEM',
    },
    {
      urlId: 2,
      name: 'SANITARY_ARRANGEMENTS.sauna',
      value: SANITARY_ARRANGEMENTS.SAUNA,
      group: 'SANITARY_ARRANGEMENTS',
    },
    { urlId: 3, name: 'objectSpec.basement', value: BASEMENT },
    {
      urlId: 4,
      name: 'PARKING_SELECTION.Garage',
      value: PARKING_SELECTION.GARAGE,
      group: 'PARKING_SELECTION',
    },
    { urlId: 5, name: 'objectSpec.furniture', value: FURNITURE },
  ],
  [OBJECT_TYPES.Commercial]: [
    { urlId: 1, name: 'objectSpec.elevator', value: LIFT },
    { urlId: 2, name: 'objectSpec.meetingRoom', value: MEETING_ROOM },
    {
      urlId: 3,
      name: 'VENTILATION_TYPE.climate_control',
      value: VENTILATION_TYPE.CLIMATE_CONTROL,
      group: 'VENTILATION_TYPE',
    },
    {
      urlId: 4,
      name: 'VENTILATION_TYPE.compulsory_ventilation',
      value: VENTILATION_TYPE.COMPULSORY_VENTILATION,
      group: 'VENTILATION_TYPE',
    },
    {
      urlId: 5,
      name: 'VENTILATION_TYPE.air_conditioner',
      value: VENTILATION_TYPE.AIR_CONDITIONER,
      group: 'VENTILATION_TYPE',
    },
    { urlId: 6, name: 'objectSpec.furniture', value: FURNITURE },
  ],
  [OBJECT_TYPES.Land]: [
    { urlId: 1, name: 'search.hasSewerage', value: HAS_SEWERAGE },
    { urlId: 2, name: 'search.electricity', value: ELECTRICITY },
    { urlId: 3, name: 'search.water', value: WATER },
  ],
  [OBJECT_TYPES.CottageOrVilla]: [
    {
      urlId: 1,
      name: 'HEATING_SYSTEM.fireplace',
      value: HEATING_SYSTEM.FIREPLACE,
      group: 'HEATING_SYSTEM',
    },
    { urlId: 2, name: 'objectSpec.basement', value: BASEMENT },
    {
      urlId: 3,
      name: 'SANITARY_ARRANGEMENTS.sauna',
      value: SANITARY_ARRANGEMENTS.SAUNA,
      group: 'SANITARY_ARRANGEMENTS',
    },
    {
      urlId: 4,
      name: 'PARKING_SELECTION.Garage',
      value: PARKING_SELECTION.GARAGE,
      group: 'PARKING_SELECTION',
    },
  ],
  [OBJECT_TYPES.Garage]: [
    {
      urlId: 1,
      name: 'SECURITY.security_system',
      value: SECURITY.SECURITY_SYSTEM,
      group: 'SECURITY',
    },
  ],
});

export const DATE_ADDED_OPTIONS = fromJS([
  { name: 'dateAdded.allTime', value: 'all_time', gaLabel: 'allTime', default: true },
  { name: 'dateAdded.today', value: 'today', gaLabel: 'today' },
  { name: 'dateAdded.thisWeek', value: 'this_week', gaLabel: 'thisWeek' },
  { name: 'dateAdded.thisMonth', value: 'this_month', gaLabel: 'thisMonth' },
  { name: 'dateAdded.lastThreeMonths', value: 'last_three_months', gaLabel: 'last3Months' },
]);

export const VIRTUAL_TOUR = NameValue({ name: 'Virtual tour', value: HAS_VIRTUAL_TOUR });
export const OPEN_HOUSE = NameValue({ name: 'Open house', value: HAS_OPEN_DAYS });
export const AUCTION = NameValue({ name: 'Auction', value: 'auction' });
export const SPECIAL_OFFERS = NameValue({ name: 'Special offers', value: 'specialOffers' });
export const PRICEDROP = NameValue({ name: 'Price drop', value: 'pricedrop' });
export const RENT_TO_OWN = NameValue({ name: 'Rent to own', value: 'rentToOwn' });

const EE = Portal.City24EE;
const LV = Portal.City24LV;

// FILTER ORDER AND VISIBILITY
const filterList = [
  {
    value: 'purpose',
    objectTypes: [OBJECT_TYPES.Commercial, OBJECT_TYPES.Land],
    portal: [EE, LV],
  },
  {
    value: 'price',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.Land,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Garage,
    ],
    portal: [EE, LV],
  },
  {
    value: 'size',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Garage,
    ],
    portal: [EE, LV],
  },
  {
    value: 'lotSize',
    objectTypes: [
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.Land,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Garage,
    ],
    portal: [EE, LV],
  },
  {
    value: 'rooms',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.ModularHouse,
    ],
    portal: [EE, LV],
  },
  {
    value: 'floor',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.ModularHouse,
    ],
    portal: [EE, LV],
  },
  {
    value: 'projectType',
    objectTypes: [OBJECT_TYPES.Apartment],
    portal: [LV],
  },
  {
    value: 'yearBuilt',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Garage,
    ],
    portal: [EE],
  },
  {
    value: 'energyCertificate',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.CottageOrVilla,
    ],
    portal: [EE],
  },
  {
    value: 'condition',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Garage,
    ],
    portal: [EE, LV],
  },
  {
    value: 'extras',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Garage,
    ],
    portal: [EE],
  },
  {
    value: 'material',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Garage,
    ],
    portal: [EE],
  },
  {
    value: 'dateAdded',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.Land,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Garage,
    ],
    portal: [EE, LV],
  },
  {
    value: 'additional',
    objectTypes: [
      OBJECT_TYPES.Apartment,
      OBJECT_TYPES.House,
      OBJECT_TYPES.HouseShare,
      OBJECT_TYPES.Commercial,
      OBJECT_TYPES.Land,
      OBJECT_TYPES.CottageOrVilla,
      OBJECT_TYPES.Garage,
    ],
    portal: [EE, LV],
  },
];

export const SORTING_VALUES = {
  default: { name: 'sorting.sortBy', value: 'relevance', default: true },
  latest: {
    name: 'sorting.latestFirst',
    value: 'datePublished-desc',
  },
  oldest: {
    name: 'sorting.oldestFirst',
    value: 'datePublished-asc',
  },
};

const SORTING_OPTIONS = fromJS([SORTING_VALUES.default, SORTING_VALUES.latest, SORTING_VALUES.oldest]);

const REALTY_SORTING_OPTIONS = SORTING_OPTIONS.concat(
  fromJS([
    { name: 'sorting.priceHighToLow', value: 'price-desc' },
    { name: 'sorting.priceLowToHigh', value: 'price-asc' },
    { name: 'sorting.pricePerUnitHighToLow', value: 'pricePerUnit-desc' },
    { name: 'sorting.pricePerUnitLowToHigh', value: 'pricePerUnit-asc' },
  ])
);

const PROJECT_SORTING_OPTIONS = SORTING_OPTIONS.concat(
  fromJS([
    { name: 'sorting.priceHighToLow', value: 'priceMin-desc' },
    { name: 'sorting.priceLowToHigh', value: 'priceMin-asc' },
    { name: 'sorting.pricePerUnitHighToLow', value: 'pricePerUnitMin-desc' },
    { name: 'sorting.pricePerUnitLowToHigh', value: 'pricePerUnitMin-asc' },
  ])
);

export function getSortingOptions(objectType = null) {
  if (objectType && [OBJECT_TYPES.NewProject, OBJECT_TYPES.ModularHouse].includes(objectType)) {
    return PROJECT_SORTING_OPTIONS;
  }
  return REALTY_SORTING_OPTIONS;
}

const currentFilters = filterList.filter(({ portal }) => portal.includes(PORTAL));

export function getFilters(objectType) {
  return currentFilters.filter((filter) => filter.objectTypes.includes(objectType)).map(({ value }) => value);
}

export const SEARCH_URL_LABELS = {
  location: 'location',
  locationId: 'id',
  keyword: SEO_URL_SEARCH_TERM.en,
  price: SEO_URL_PRICE.en,
  size: SEO_URL_SIZE.en,
  lotSize: SEO_URL_LOT.en,
  floor: SEO_URL_FLOOR.en,
  onlyLastFloor: SEO_URL_LAST_FLOOR.en,
  rooms: SEO_URL_ROOMS.en,
  condition: SEO_URL_CONDITION.en,
  material: SEO_URL_MATERIAL.en,
  purpose: SEO_URL_PURPOSE.en,
  fromOwner: SEO_URL_OWNER.en,
  extras: 'extras',
  projectType: 'project',
  virtualTour: SEO_URL_VIRTUAL.en,
  openHouse: SEO_URL_OPEN_HOUSE.en,
  specialOffers: SEO_URL_SPECIAL_OFFERS.en,
  auction: 'auction',
  pricedrop: 'pricedrop',
  dateAdded: 'date',
  typeCombinationId: 'tc',
  built: SEO_URL_YEAR_BUILT.en,
  energyCertificate: SEO_URL_ENERGY_CERTIFICATE.en,
  objectTypes: 't',
  constructionPhase: 'cp',
};

export const OBJECT_TRANSACTION_STRINGS = {
  apartmentsForSale: 'common.apartmentsForSale',
  apartmentsForRent: 'common.apartmentsForRent',
  housesForSale: 'common.housesForSale',
  housesForRent: 'common.housesForRent',
  houseSharesForSale: 'common.houseSharesForSale',
  houseSharesForRent: 'common.houseSharesForRent',
  commercialsForSale: 'common.commercialsForSale',
  commercialsForRent: 'common.commercialsForRent',
  summerCottagesForSale: 'common.summerCottagesForSale',
  summerCottagesForRent: 'common.summerCottagesForRent',
  landsForSale: 'common.landsForSale',
  landsForRent: 'common.landsForRent',
  garagesForSale: 'common.garagesForSale',
  garagesForRent: 'common.garagesForRent',
  newProjectsForSale: 'common.newProjectsForSale',
  newProjectsForRent: 'common.newProjectsForRent',
  modularHousesForSale: 'common.modularHousesForSale',
  modularHousesForRent: 'common.modularHousesForRent',
};

export const OBJECT_TRANSACTION_STRINGS_SINGULAR = {
  apartmentsForSale: 'common.apartmentForSale',
  apartmentsForRent: 'common.apartmentForRent',
  housesForSale: 'common.houseForSale',
  housesForRent: 'common.houseForRent',
  houseSharesForSale: 'common.houseShareForSale',
  houseSharesForRent: 'common.houseShareForRent',
  commercialsForSale: 'common.commercialForSale',
  commercialsForRent: 'common.commercialForRent',
  summerCottagesForSale: 'common.summerCottageForSale',
  summerCottagesForRent: 'common.summerCottageForRent',
  landsForSale: 'common.landForSale',
  landsForRent: 'common.landForRent',
  garagesForSale: 'common.garageForSale',
  garagesForRent: 'common.garageForRent',
  newProjectsForSale: 'common.newProjectForSale',
  newProjectsForRent: 'common.newProjectForRent',
  modularHousesForSale: PORTAL_EE ? 'common.modularHouseForSale' : 'common.modularHouseForSale.LV',
  modularHousesForRent: PORTAL_EE ? 'common.modularHouseForRent' : 'common.modularHouseForRent.LV',
};

export function shouldSearchByTotalFloors(objectType) {
  return [OBJECT_TYPES.House, OBJECT_TYPES.CottageOrVilla, OBJECT_TYPES.ModularHouse].includes(objectType);
}
