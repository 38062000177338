import { MULTI_BANK_HOME_LOAN_CALCULATOR, SELF_FINANCE_UNIT } from '@/constants/finance';
import { computeLoanSale } from '@/components/SEBCalculator/SEBCalculatorFunctions';
import { identity } from '@/utils/helpers';

export function luminorCoefficient(interestRate: number, payments: number): number {
  const ir = interestRate / 12 / 100;
  return (ir * (ir + 1) ** payments) / ((ir + 1) ** payments - 1);
}

export function loanAmount(price: number, selfFinancing: number, selfFinancingUnit: string): number {
  const selfFinancingValue =
    selfFinancingUnit === SELF_FINANCE_UNIT.percent ? selfFinancing / 100 : selfFinancing / price;

  return price * (1 - selfFinancingValue);
}

export type BankLoanParams = {
  selfFinancing: number;
  selfFinancingUnit: string;
  interest: number;
  period: number;
};

export const defaultLuminorParams: Readonly<BankLoanParams> = {
  selfFinancing: 15,
  selfFinancingUnit: SELF_FINANCE_UNIT.percent,
  interest: 2.0,
  period: 30,
};

export const defaultSebParams: Readonly<
  BankLoanParams & {
    objectLink: { lv: string; ru: string };
    detailsLink: { lv: string; ru: string };
    fullSpecsLink: { lv: string; ru: string };
    calculatorLink: { lv: string; ru: string };
    modalCalculatorLink: { lv: string; ru: string };
  }
> = {
  selfFinancing: 15,
  selfFinancingUnit: SELF_FINANCE_UNIT.percent,
  interest: 4,
  period: 30,
  objectLink: {
    lv: 'https://www.seb.lv/privatpersonam/krediti/majokla-kredits?utm_source=city24&utm_medium=banner&utm_campaign=home_loan&utm_content=objekts_finansejums&utm_segment=Private&utm_language=LV#appform_loan_private_home',
    ru: 'https://www.seb.lv/ru/chastnym-licam/kredity/ipotechnyy-kredit-dlya-pokupki-stroitelstva-i-remonta-zhilya?utm_source=city24&utm_medium=banner&utm_campaign=home_loan&utm_content=objekts_finansejums&utm_segment=Private&utm_language=RU#appform_loan_private_home',
  },
  detailsLink: {
    lv: 'https://www.seb.lv/privatpersonam/krediti/majokla-kredits?utm_source=city24&utm_medium=banner&utm_campaign=home_loan&utm_content=objekts_finansejums&utm_segment=Private&utm_language=LV#appform_loan_private_home',
    ru: 'https://www.seb.lv/ru/chastnym-licam/kredity/ipotechnyy-kredit-dlya-pokupki-stroitelstva-i-remonta-zhilya?utm_source=city24&utm_medium=banner&utm_campaign=home_loan&utm_content=objekts_finansejums&utm_segment=Private&utm_language=RU#appform_loan_private_home',
  },
  fullSpecsLink: {
    lv: 'https://www.seb.lv/privatpersonam/krediti/majokla-kredits?utm_source=city24&utm_medium=banner&utm_campaign=home_loan&utm_content=objekts_finansejums&utm_segment=Private&utm_language=LV#appform_loan_private_home',
    ru: 'https://www.seb.lv/ru/chastnym-licam/kredity/ipotechnyy-kredit-dlya-pokupki-stroitelstva-i-remonta-zhilya?utm_source=city24&utm_medium=banner&utm_campaign=home_loan&utm_content=objekts_finansejums&utm_segment=Private&utm_language=RU#appform_loan_private_home',
  },
  calculatorLink: {
    lv: 'https://www.seb.lv/privatpersonam/krediti/majokla-kredits?utm_source=city24&utm_medium=banner&utm_campaign=home_loan&utm_content=objekts_finansejums&utm_segment=Private&utm_language=LV#appform_loan_private_home',
    ru: 'https://www.seb.lv/ru/chastnym-licam/kredity/ipotechnyy-kredit-dlya-pokupki-stroitelstva-i-remonta-zhilya?utm_source=city24&utm_medium=banner&utm_campaign=home_loan&utm_content=objekts_finansejums&utm_segment=Private&utm_language=RU#appform_loan_private_home',
  },
  modalCalculatorLink: {
    lv: 'https://www.seb.lv/privatpersonam/krediti/majokla-kredits?utm_source=city24&utm_medium=banner&utm_campaign=home_loan&utm_content=objekts_finansejums&utm_segment=Private&utm_language=LV#appform_loan_private_home',
    ru: 'https://www.seb.lv/ru/chastnym-licam/kredity/ipotechnyy-kredit-dlya-pokupki-stroitelstva-i-remonta-zhilya?utm_source=city24&utm_medium=banner&utm_campaign=home_loan&utm_content=objekts_finansejums&utm_segment=Private&utm_language=RU#appform_loan_private_home',
  },
};

export function defaultLuminorMonthly(price: number): number {
  return (
    loanAmount(price, defaultLuminorParams.selfFinancing, defaultLuminorParams.selfFinancingUnit) *
    luminorCoefficient(defaultLuminorParams.interest, defaultLuminorParams.period * 12)
  );
}

export function luminorMonthlyWithDefaults(price: number, params: Partial<BankLoanParams> = {}): number {
  return (
    loanAmount(
      price,
      params.selfFinancing ?? defaultLuminorParams.selfFinancing,
      params.selfFinancingUnit ?? defaultLuminorParams.selfFinancingUnit
    ) *
    luminorCoefficient(
      params.interest ?? defaultLuminorParams.interest,
      (params.period ?? defaultLuminorParams.period) * 12
    )
  );
}

export function luminorIsSuitableHomeLoanValue(loanValue: number): boolean {
  return loanValue >= 10000;
}

export function multibankMonthlyPayment(price: number, objectType: string, euriborRate: number): number {
  // calculate monthly payment for each bank
  // return the lowest payment
  const payments = MULTI_BANK_HOME_LOAN_CALCULATOR.map((bank) => {
    if (objectType in bank.defaults) {
      const loanDefaults = bank.defaults[objectType];
      return computeLoanSale(
        price,
        loanDefaults.period,
        (Number(loanDefaults.fixedInterestRate) + Number(euriborRate)).toFixed(2),
        loanDefaults.selfFinancing,
        'percent'
      ).monthlyPayment;
    }
    return 0;
  }).filter(identity);

  return Math.min(...payments);
}

export function defaultSebMonthly(price: number): number {
  return (
    loanAmount(price, defaultSebParams.selfFinancing, defaultSebParams.selfFinancingUnit) *
    luminorCoefficient(defaultSebParams.interest, defaultSebParams.period * 12)
  );
}

export function sebMonthlyWithDefaults(price: number, params: Partial<BankLoanParams> = {}): number {
  return (
    loanAmount(
      price,
      params.selfFinancing ?? defaultSebParams.selfFinancing,
      params.selfFinancingUnit ?? defaultSebParams.selfFinancingUnit
    ) *
    luminorCoefficient(params.interest ?? defaultSebParams.interest, (params.period ?? defaultSebParams.period) * 12)
  );
}

export function PV(rate: number, nper: number, pmt: number): number {
  return (pmt / rate) * (1 - (1 + rate) ** -nper);
}
