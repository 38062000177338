import { isMobile } from 'react-device-detect';
import { createSelector } from 'reselect';
import { RootState } from '@/store';

import { getObjectDetailsJS } from '@/components/object/objectDetailSelectors';
import { OBJECT_TYPES, TRANSACTION_TYPES } from '@/constants/object';
import { getLanguage } from './appSelectors';

export const getHomeLoanCalc = (state: RootState) => state.calculators.homeLoan;
export const getConsumerLoanCalc = (state: RootState) => state.calculators.consumerLoan;
export const getMaxLoanCapabilityCalc = (state: RootState) => state.calculators.maxLoanCapability;
export const getNotariousCostCalc = (state: RootState) => state.calculators.notariousCost;
export const getEuriborRate = (state: RootState) => state.calculators.multiBank.euriborRate;
export const getMultiBankCalcOrder = (state: RootState) => state.calculators.multiBank.bankOrder;

export const getSingleBankCalculatorVariant = createSelector(getObjectDetailsJS, (object) => {
  if (!object) {
    return null;
  }

  // if (PORTAL_EE && object.unit_type === OBJECT_TYPES.Commercial) {
  //   return 'tbb';
  // }
  // if (
  //   PORTAL_EE &&
  //   object.transaction_type === TRANSACTION_TYPES.RENT &&
  //   [OBJECT_TYPES.Apartment, OBJECT_TYPES.House, OBJECT_TYPES.HouseShare, OBJECT_TYPES.Land].includes(object.unit_type)
  // ) {
  //   return 'coop';
  // }

  return null;
});

export const getLuminorLinks = createSelector(getLanguage, (language: string) => {
  const altVersion = Math.random() < 0.5;
  let links: {
    [key: string]: string;
  };
  if (language === 'en') {
    links = {
      homeLoan: 'https://luminor.ee/retail/mortgage-loan?',
      renLoan: 'https://luminor.ee/partners-offers?',
      consLoan: 'https://luminor.ee/retail/consumer-loan?',
      askOffer: altVersion ? 'https://luminor.ee/askforloanoffer?' : 'https://luminor.ee/askforloanoffer-city24',
      consultation: 'https://luminor.ee/loanconsultation?',
    };
  } else if (language === 'ru') {
    links = {
      homeLoan: 'https://luminor.ee/chastnye/zhilishchnyy-kredit?',
      renLoan: 'https://luminor.ee/partners-offers?',
      consLoan: 'https://luminor.ee/chastnye/consumer-loan-ru?',
      askOffer: altVersion
        ? 'https://luminor.ee/sprosite-predlozheniye?'
        : 'https://luminor.ee/sprosite-predlozheniye?',
      consultation: 'https://luminor.ee/kreditnij-konsalting?',
    };
  } else {
    links = {
      homeLoan: 'https://luminor.ee/era/kodulaen?',
      renLoan: 'https://luminor.ee/partnerite-pakkumised?',
      consLoan: 'https://luminor.ee/era/vaikelaen?',
      askOffer: altVersion ? 'https://luminor.ee/küsilaenupakkumist?' : 'https://luminor.ee/küsilaenupakkumist-city24?',
      consultation: 'https://luminor.ee/laenukonsultatsioon?',
    };
  }

  const utm = (medium: string, campaign: string, content: string) =>
    `utm_source=city24&utm_medium=${medium}&utm_campaign=${campaign}&utm_content=${content}`;
  const fcUtm = 'finance_center';
  const linkUtm = 'link';
  const mortgageUtm = 'mortgage';
  const consumerLoanUtm = 'consumerloan';
  const askOfferContent = altVersion ? 'original' : 'variant1';

  return {
    homeLoan: `${links.homeLoan}${utm(fcUtm, mortgageUtm, 'read_more_link')}`,
    renLoan: `${links.renLoan}${utm(fcUtm, consumerLoanUtm, 'read_more_link')}`,
    consLoan: `${links.consLoan}${utm(fcUtm, consumerLoanUtm, 'small_amount_apply_consumerloan')}`,
    askOffer: `${links.askOffer}${utm(fcUtm, mortgageUtm, askOfferContent)}`,
    footerLoan: `${links.homeLoan}${utm(linkUtm, mortgageUtm, 'footer_disclaimer_link')}`,
    renLoanOffer: `${links.renLoan}${utm(fcUtm, consumerLoanUtm, askOfferContent)}`,
    renLoanDetailsOffer: `${links.renLoan}${utm(linkUtm, consumerLoanUtm, 'detail_view_ask_offer')}`,
    consultation: `${links.consultation}${utm('consultation', mortgageUtm, 'send_contact')}&utm_term=button`,
    askOfferDetailsLink: `${links.askOffer}${utm('object_view', mortgageUtm, askOfferContent)}`,
    askOfferApplication: `${links.askOffer}${utm('application', mortgageUtm, 'loan_application')}`,
    askOfferFavourites: `${links.askOffer}${utm('favourites_view', mortgageUtm, askOfferContent)}&utm_term=button`,
    askOfferSearch: `${links.askOffer}${utm('search_view', mortgageUtm, askOfferContent)}&utm_term=button`,
  } as const;
});

export const getAlteroLinks = createSelector(getLanguage, (language: string) => {
  const baseURL = 'https://www.altero.lv/widgets/city24';
  switch (language) {
    case 'ru': {
      const horizontal = `${baseURL}/adjusted/ru.html?utm_source=city24&utm_medium=calculator_slider_ru&loan_period=84&loan_amount=`;
      if (isMobile) {
        return {
          sidebar: `${baseURL}/altero-with-form/ru-mobile.html?utm_source=city24&utm_medium=calculator_mob_ru&loan_period=84&loan_amount=`,
          horizontal,
        };
      }
      return {
        sidebar: `${baseURL}/altero-with-form/ru.html?utm_source=city24&utm_medium=calculator_deskt_ru&loan_period=84&loan_amount=`,
        horizontal,
      };
    }
    default: {
      const horizontal = `${baseURL}/adjusted/lv.html?utm_source=city24&utm_medium=calculator_slider_lv&loan_period=84&loan_amount=`;
      if (isMobile) {
        return {
          sidebar: `${baseURL}/altero-with-form/lv-mobile.html?utm_source=city24&utm_medium=calculator_mob_lv&loan_period=84&loan_amount=`,
          horizontal,
        };
      }
      return {
        sidebar: `${baseURL}/altero-with-form/lv.html?utm_source=city24&utm_medium=calculator_desk_lv&loan_period=84&loan_amount=`,
        horizontal,
      };
    }
  }
});
