import type Language from '@city24/common/enums/Language';

export const SLUGS: {
  [slug: string]: Partial<Record<Language, string>>;
} = {
  REALESTATE_NEWS: {
    et: 'kinnisvarauudised',
    lv: 'nekustama-ipasuma-zinas',
    en: 'real-estate-news',
    ru: 'novosti-rynka-nedvizhimosti',
  },
  MARKET_OVERVIEW: {
    et: 'kinnisvaraturu-ulevaated',
  },
  NEW_PROJECT: { et: 'uusarendused', lv: 'jaunie-projekti' },
  COMMERCIAL: { et: 'arikinnisvara', lv: 'komercplatibas' },
  RENTAL: { et: 'uurimine' },
  LOAN: { et: 'kodulaen', lv: 'investicijas-un-krediti' },
  ADVICE: { et: 'nouanne' },
  LEGISLATION: { et: 'seadusandlus' },
  INVESTMENT: { et: 'investeerimine' },

  PRICE_CHANGE: { lv: 'cenu-izmainas' },
  PRICE_LIST: {
    et: 'prices',
    lv: 'cenas',
    en: 'prices',
    ru: 'ceni',
  },
  PRICE_LIST_BUSINESS: {
    et: 'hinnakiri-ariklient',
    lv: 'cenu-lapa-kompanijam',
    en: 'business-client-price-list',
    ru: 'business-client-price-list',
  },
  CONSTRUCTION: { lv: 'buvnieciba' },
  INTERIOR_DESIGN: { lv: 'interjers-un-dizains', ru: 'interer-i-dizajn' },
  INSERT_ADD_PRIVAVATE: {
    et: 'myCity24/objects',
    lv: 'myCity24/objects',
    en: 'myCity24/objects',
    ru: 'myCity24/objects',
  },
  INSERT_ADD_BROKER: {
    et: 'loginGoDomePro',
    lv: 'loginGoDomePro',
    en: 'loginGoDomePro',
    ru: 'loginGoDomePro',
  },
  SUB_PAGES: {
    et: 'sub+pages',
    lv: 'sub+pages',
    en: 'sub+pages',
    ru: 'sub+pages',
  },
  CONTACTS: {
    et: 'contacts',
    lv: 'kontakti',
    en: 'contacts',
    ru: 'contacts',
  },
  CONDITIONS: {
    et: 'terms+of+use',
    lv: 'terms+of+use',
    en: 'terms+of+use',
    ru: 'terms+of+use',
  },
  PRIVACY_POLICY: {
    et: 'privacy+regulations',
    lv: 'privacy+regulations',
    en: 'privacy+regulations',
    ru: 'privacy+regulations',
  },
  COOKIES: {
    et: 'cookies',
    lv: 'cookies',
    en: 'cookies',
    ru: 'cookies',
  },
  JOINING: {
    et: 'joining',
    lv: 'joining',
    en: 'joining',
    ru: 'joining',
  },
  BROKER_SEARCH: {
    et: 'kinnisvara-firmad',
    lv: 'nekustamo-ipasumu-kompanijas',
    en: 'brokerSearch',
    ru: 'kompanii-nedvizhimosti',
  },
};
