import { List } from 'immutable';
import SizeUnit, { getSizeUnitString } from '@city24/common/enums/realty/SizeUnit';
import { OBJECT_TYPES, SIZE_UNIT_VALUES } from '../constants/object';

export function pick<T extends { [key: string]: any }>(object: T, ...props: (keyof T)[]): T {
  return props.reduce((obj, prop) => {
    obj[prop] = object[prop];
    return obj;
  }, {} as T);
}

export function omit<T extends { [key: string]: any }>(object: T, ...props: (keyof T)[]) {
  const obj = { ...object };
  props.forEach((prop) => {
    delete obj[prop];
  });
  return obj;
}

export function accessNested<T extends { [key: string]: any }>(object: T, ...path: string[]) {
  return path.reduce((obj, nextKey) => obj?.[nextKey], object);
}

export function isEquivalent(a, b) {
  if (a === null || b === null) {
    return a === b;
  }
  if (typeof a !== typeof b) {
    return false;
  }
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i += 1) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}

export function groupBy(items, callback) {
  return items.reduce((obj, item) => {
    const key = callback(item);
    obj[key] = obj[key] || [];
    obj[key].push(item);
    return obj;
  }, {});
}

/**
 * Get a unit type value for the price per unit
 * @deprecated use ./utils/realty/index.ts
 */
export function getPricePerUnitType(
  unitType: string,
  sizeUnitId: keyof typeof SIZE_UNIT_VALUES | SizeUnit | null = null,
  lotSizeUnitId: keyof typeof SIZE_UNIT_VALUES | SizeUnit | null = null
) {
  return getSizeUnitString(
    unitType === OBJECT_TYPES.Land ? lotSizeUnitId || SizeUnit.Hectare : sizeUnitId || SizeUnit.SquareMeter
  );
}

/** @deprecated use ./utils/realty/index.ts */
export function showSlogan(object) {
  if (PORTAL_EE && object.get('ad_level') > 0) {
    return true;
  }
  if (PORTAL_LV) {
    if (object.get('ad_level') > 0) {
      return true;
    }
    return object.getIn(['broker', 'office'], List()).some((office) => {
      const companyProducts = office.getIn(['company', 'classified_products'], List());
      const hasLevel = (cp) => cp.get('product_level') === 'GOLD' || cp.get('product_level') === 'PRO';

      return companyProducts.some(hasLevel) || office.get('classified_products', List()).some(hasLevel);
    });
  }

  return false;
}

/**
 * copy of @city24/common/utils/object.ts mapValuesToUndefined
 */
export function mapValuesToUndefined<T extends { [key: string]: any }>(object: T, value: any) {
  return Object.keys(object).reduce((obj, key) => {
    obj[key as keyof T] = value === object[key] ? undefined : object[key];
    return obj;
  }, {} as T);
}
