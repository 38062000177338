import React from 'react';
import classNames from 'classnames';
import { TFunction } from 'next-i18next';

import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import SizeUnit, { getSizeUnitString } from '@city24/common/enums/realty/SizeUnit';
import OBJECT_MAIN_SPECS, { MainSpecType } from '@/constants/object/mainSpecs';
import { formatFloorString } from '@/utils/formatting';
import Icon from '@/components/icon/Icon';

interface Props {
  t: TFunction;
  objectType: UnitTypeLiteral;
  className?: string;
  totalArea?: number | string | null;
  numberOfRooms?: number | string | null;
  floor?: number;
  totalFloors?: number;
  lotSize?: number;
  availableUnits?: number;
  totalUnits?: number;
  totalAreaUnit?: SizeUnit | null;
  lotSizeUnit?: SizeUnit | null;
}

function MainSpecs(props: Props) {
  const {
    t,
    objectType,
    className,
    totalArea = '',
    numberOfRooms = '',
    floor = 0,
    totalFloors = 0,
    lotSize = 0,
    availableUnits = 0,
    totalUnits = 0,
  } = props;
  const getSpecValue = (specType: MainSpecType['value']) => {
    switch (specType) {
      case 'property_size':
        if (totalArea) {
          const totalAreaUnit = props.totalAreaUnit ?? SizeUnit.SquareMeter;
          return (
            <>
              <span>{totalArea}</span>
              <span className="main-specs__unit">{getSizeUnitString(totalAreaUnit)}</span>
            </>
          );
        }
        return 0;

      case 'FLOOR': {
        const floorValue = formatFloorString(floor, totalFloors);
        if (floorValue) {
          return (
            <>
              <Icon name="stairs" />
              <span>{floorValue}</span>
            </>
          );
        }
        return 0;
      }

      case 'TOTAL_FLOORS': {
        if (totalFloors) {
          return (
            <>
              <Icon name="stairs" />
              <span>{totalFloors}</span>
            </>
          );
        }
        return 0;
      }

      case 'room_count':
        if (numberOfRooms) {
          return (
            <>
              <Icon name="door" />
              <span>{numberOfRooms}</span>
            </>
          );
        }
        return 0;

      case 'lot_size':
        if (lotSize) {
          const lotSizeUnit = props.lotSizeUnit ?? SizeUnit.SquareMeter;
          return (
            <>
              <span>{lotSize}</span>
              <span className="main-specs__unit">{getSizeUnitString(lotSizeUnit)}</span>
            </>
          );
        }
        return 0;

      case 'available_units': {
        if (availableUnits > 0 && totalUnits > 0) {
          return (
            <>
              <span>{t('objectSpec.availableUnits', { available: availableUnits, total: totalUnits })}</span>
            </>
          );
        }
        return 0;
      }

      default:
        return '';
    }
  };
  const renderSpec = (specType: MainSpecType) => {
    const specValue = getSpecValue(specType.value);
    if (specValue) {
      return (
        <li key={specType.value as string} className="main-specs__spec">
          <div className="main-specs__value">{specValue}</div>
        </li>
      );
    }
    return null;
  };

  const mainSpecsStyle = classNames('main-specs', className);

  return <ul className={mainSpecsStyle}>{(OBJECT_MAIN_SPECS[objectType] ?? []).map(renderSpec)}</ul>;
}

export default MainSpecs;
