import React from 'react';
import { Collection, List } from 'immutable';
import ReactGA from '@/utils/ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import {
  GA_SEARCH,
  GAL_SEARCH_EXTRAS,
  GAL_SEARCH_PURPOSE,
  GAL_SEARCH_MATERIAL,
  GAL_SEARCH_CONDITION,
} from '@/constants/ga';
import {
  CONDITION_OPTIONS,
  BUILDING_MATERIAL_OPTIONS,
  PURPOSE_OPTIONS,
  ENERGY_CERTIFICATE_OPTIONS,
  PROJECT_TYPE_OPTIONS,
  OBJECT_TYPE_OPTIONS_NP,
  CONSTRUCTION_PHASE_OPTIONS_NP,
} from '@/constants/attributes';
import { EXTRAS_OPTIONS, VIRTUAL_TOUR, OPEN_HOUSE, SPECIAL_OFFERS, PRICEDROP, RENT_TO_OWN } from '@/constants/filters';
import { sortOptions } from '@/utils/formatting';

import { setAreaSelectModalOpened } from '@/components/modals/ModalActions';
import { getBrowser } from '@/selectors/appSelectors';

import Select from '@/components/select/Select';
import ToggleFieldGroup from '@/components/toggle/ToggleFieldGroup';
import { OBJECT_TYPES } from '@/constants/object';
import ButtonGroup from '@/components/button/ButtonGroup';
import ToggleField from '@/components/toggle/ToggleField';

import * as searchActions from '../SearchActions';
import { getFiltersToRender, getAdditionalFilters, getObjectTypeOptions } from '../searchSelectors';

import PriceRange from '../PriceRange/PriceRange';
import FloorRange from '../FloorRange/FloorRange';
import SizeRange from '../SizeRange/SizeRange';
import LotSizeRange from '../LotSizeRange/LotSizeRange';
import Rooms from '../Rooms/Rooms';
import DateAdded from '../DateAdded/DateAdded';
import YearBuiltRange from '../YearBuiltRange/YearBuiltRange';
import TransactionType from '../TransactionType';
import ObjectType from '../ObjectType';
import SearchTerm from '../SearchTerm';

const propTypes = {
  objectType: PropTypes.instanceOf(Collection).isRequired,
  transactionType: PropTypes.instanceOf(Collection).isRequired,
  searchTerm: PropTypes.instanceOf(Collection).isRequired,
  minPrice: PropTypes.string.isRequired,
  maxPrice: PropTypes.string.isRequired,
  priceType: PropTypes.instanceOf(Collection).isRequired,
  minSize: PropTypes.string.isRequired,
  maxSize: PropTypes.string.isRequired,
  minLotSize: PropTypes.string.isRequired,
  maxLotSize: PropTypes.string.isRequired,
  lotSizeType: PropTypes.instanceOf(Collection).isRequired,
  minFloor: PropTypes.string.isRequired,
  maxFloor: PropTypes.string.isRequired,
  onlyLastFloor: PropTypes.bool.isRequired,
  rooms: PropTypes.instanceOf(Collection).isRequired,
  fromOwner: PropTypes.bool.isRequired,
  condition: PropTypes.instanceOf(Collection).isRequired,
  material: PropTypes.instanceOf(Collection).isRequired,
  extras: PropTypes.instanceOf(Collection).isRequired,
  projectType: PropTypes.instanceOf(Collection).isRequired,
  purpose: PropTypes.instanceOf(Collection).isRequired,
  dateAdded: PropTypes.instanceOf(Collection).isRequired,
  getMinPrice: PropTypes.func,
  getMaxPrice: PropTypes.func,
  getPriceType: PropTypes.func,
  getMinSize: PropTypes.func,
  getMaxSize: PropTypes.func,
  getMinLotSize: PropTypes.func,
  getMaxLotSize: PropTypes.func,
  getLotSizeType: PropTypes.func,
  getMinFloor: PropTypes.func,
  getMaxFloor: PropTypes.func,
  getOnlyLastFloor: PropTypes.func,
  getRooms: PropTypes.func,
  getFromOwner: PropTypes.func,
  getCondition: PropTypes.func,
  getMaterial: PropTypes.func,
  getPurpose: PropTypes.func,
  getExtras: PropTypes.func,
  getProjectType: PropTypes.func,
  getDateAdded: PropTypes.func,
  getMinYearBuilt: PropTypes.func,
  getMaxYearBuilt: PropTypes.func,
  filtersToRender: PropTypes.arrayOf(PropTypes.string).isRequired,
  browser: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.string, PropTypes.number])
  ).isRequired,
  t: PropTypes.func,
};

const defaultProps = {
  t: (a) => a,
  getMinPrice: null,
  getMaxPrice: null,
  getPriceType: null,
  getMinSize: null,
  getMaxSize: null,
  getMinLotSize: null,
  getMaxLotSize: null,
  getLotSizeType: null,
  getMinFloor: null,
  getMaxFloor: null,
  getOnlyLastFloor: null,
  getRooms: null,
  getFromOwner: null,
  getCondition: null,
  getMaterial: null,
  getPurpose: null,
  getExtras: null,
  getProjectType: null,
  getDateAdded: null,
  getMinYearBuilt: null,
  getMaxYearBuilt: null,
};

class SearchVertical extends React.Component {
  searchTermInputRef = React.createRef();

  componentDidUpdate() {
    const { isCleared, isClearedCallback } = this.props;
    if (isCleared) {
      // callback
      isClearedCallback(false);
    }
  }

  getObjectType = (objectType) => {
    const { setObjectType, getObjectType, getSubObjectTypes } = this.props;

    // this.setState({ objectType });
    if (setObjectType) setObjectType(objectType);
    if (getObjectType) getObjectType(objectType);
    if (getSubObjectTypes) {
      if (objectType.get('value') === OBJECT_TYPES.NewProject) {
        // set to all by default
        getSubObjectTypes(OBJECT_TYPE_OPTIONS_NP);
      } else {
        // clear all if main type changes
        getSubObjectTypes(List());
      }
    }
  };

  renderFilters() {
    const {
      filtersToRender,
      objectType,
      minPrice,
      maxPrice,
      priceType,
      pricedrop,
      minSize,
      maxSize,
      minLotSize,
      maxLotSize,
      lotSizeType,
      minFloor,
      maxFloor,
      onlyLastFloor,
      minYearBuilt,
      maxYearBuilt,
      energyCertificate,
      rooms,
      condition,
      material,
      extras,
      projectType,
      dateAdded,
      getMinPrice,
      getMaxPrice,
      getPriceType,
      getPricedrop,
      getMinSize,
      getMaxSize,
      getMinLotSize,
      getMaxLotSize,
      getLotSizeType,
      getMinFloor,
      getMaxFloor,
      getOnlyLastFloor,
      getMinYearBuilt,
      getMaxYearBuilt,
      getEnergyCertificate,
      getRooms,
      getCondition,
      getMaterial,
      getExtras,
      getProjectType,
      getDateAdded,
      t,
    } = this.props;
    const objectTypeValue = objectType.get('value');
    return filtersToRender.map((filter) => {
      switch (filter) {
        case 'price':
          return (
            <li key={filter}>
              <PriceRange
                footerHidden
                minPrice={minPrice}
                maxPrice={maxPrice}
                priceType={priceType}
                handleMinPrice={getMinPrice}
                handleMaxPrice={getMaxPrice}
                handlePriceType={getPriceType}
                t={t}
              />
              {objectTypeValue !== OBJECT_TYPES.NewProject && objectTypeValue !== OBJECT_TYPES.ModularHouse && (
                <ToggleField
                  id="pricedrop"
                  title={t('search.pricedrop.title')}
                  // subtitle={t('search.pricedrop.subtitle')}
                  value={PRICEDROP.value}
                  checked={pricedrop}
                  getValue={getPricedrop}
                  gaEvent={(checked) =>
                    ReactGA.event({
                      category: GA_SEARCH,
                      action: checked ? 'select_pricedrop' : 'unselect_pricedrop',
                      label: 'pricedrop',
                    })
                  }
                />
              )}
            </li>
          );
        case 'size':
          return (
            <li key={filter}>
              <SizeRange
                footerHidden
                minSize={minSize}
                maxSize={maxSize}
                handleMinSize={getMinSize}
                handleMaxSize={getMaxSize}
                t={t}
              />
            </li>
          );

        case 'lotSize':
          return (
            <li key={filter}>
              <LotSizeRange
                footerHidden
                minLotSize={minLotSize}
                maxLotSize={maxLotSize}
                lotSizeType={lotSizeType}
                handleMinLotSize={getMinLotSize}
                handleMaxLotSize={getMaxLotSize}
                handleLotSizeType={getLotSizeType}
                t={t}
              />
            </li>
          );

        case 'floor': {
          return (
            <li key={filter}>
              <FloorRange
                footerHidden
                minFloor={minFloor}
                maxFloor={maxFloor}
                onlyLastFloor={onlyLastFloor}
                handleMinFloor={getMinFloor}
                handleMaxFloor={getMaxFloor}
                handleOnlyLastFloor={getOnlyLastFloor}
                objectType={objectType}
                t={t}
              />
            </li>
          );
        }
        case 'yearBuilt': {
          return (
            <li key={filter}>
              <YearBuiltRange
                minYearBuilt={minYearBuilt}
                maxYearBuilt={maxYearBuilt}
                handleMinYearBuilt={getMinYearBuilt}
                handleMaxYearBuilt={getMaxYearBuilt}
                t={t}
              />
            </li>
          );
        }
        case 'energyCertificate': {
          return (
            <li key={filter}>
              <div className="filter__heading">
                <h4 className="filter__title">{t('search.energyCertificate')}</h4>
              </div>
              <Select
                t={t}
                multiple
                native={isMobile}
                empty={t('search.allEnergyCertificates')}
                options={ENERGY_CERTIFICATE_OPTIONS}
                selected={energyCertificate}
                getValue={getEnergyCertificate}
                // gaEvent={(label, selected) =>
                //   ReactGA.event({
                //     category: GA_SEARCH,
                //     action:
                //       selected || selected === undefined
                //         ? 'select_energyCertificate'
                //         : 'unselect_energyCertificate',
                //     label: selected === undefined ? 'open_dropdown' : GAL_SEARCH_MATERIAL[label]
                //   })
                // }
              />
            </li>
          );
        }
        case 'rooms': {
          return (
            <li key={filter}>
              <Rooms footerHidden rooms={rooms} handleRooms={getRooms} t={t} />
            </li>
          );
        }
        case 'condition': {
          return (
            <li key={filter}>
              <div className="filter__heading">
                <h4 className="filter__title">{t('search.condition')}</h4>
              </div>
              <Select
                t={t}
                multiple
                native={isMobile}
                empty={t('search.allConditions')}
                options={CONDITION_OPTIONS}
                selected={condition}
                getValue={getCondition}
                gaEvent={(label, selected) => {
                  ReactGA.event({
                    category: GA_SEARCH,
                    action: selected || selected === undefined ? 'select_condition' : 'unselect_condition',
                    label: selected === undefined ? 'open_dropdown' : GAL_SEARCH_CONDITION[label],
                  });
                }}
              />
            </li>
          );
        }
        case 'material': {
          return (
            <li key={filter}>
              <div className="filter__heading">
                <h4 className="filter__title">{t('search.material')}</h4>
              </div>
              <Select
                t={t}
                multiple
                native={isMobile}
                empty={t('search.allMaterials')}
                options={BUILDING_MATERIAL_OPTIONS}
                selected={material}
                getValue={getMaterial}
                gaEvent={(label, selected) =>
                  ReactGA.event({
                    category: GA_SEARCH,
                    action: selected || selected === undefined ? 'select_houseMaterial' : 'unselect_houseMaterial',
                    label: selected === undefined ? 'open_dropdown' : GAL_SEARCH_MATERIAL[label],
                  })
                }
              />
            </li>
          );
        }
        case 'extras': {
          return (
            <li key={filter}>
              <div className="filter__heading">
                <h4 className="filter__title">{t('search.extras')}</h4>
              </div>
              <Select
                t={t}
                multiple
                native={isMobile}
                empty={t('search.allExtras')}
                options={EXTRAS_OPTIONS.get(objectTypeValue)}
                selected={extras.get(objectTypeValue)}
                getValue={getExtras}
                gaEvent={(label, selected) => {
                  ReactGA.event({
                    category: GA_SEARCH,
                    action: selected || selected === undefined ? 'select_extras' : 'unselect_extras',
                    label: selected === undefined ? 'open_dropdown' : GAL_SEARCH_EXTRAS[label],
                  });
                }}
              />
            </li>
          );
        }
        case 'projectType': {
          return (
            <li key={filter}>
              <div className="filter__heading">
                <h4 className="filter__title">{t('search.projectType')}</h4>
              </div>
              <Select
                t={t}
                multiple
                native={isMobile}
                empty={t('search.allProjectTypes')}
                options={PROJECT_TYPE_OPTIONS}
                selected={projectType}
                getValue={getProjectType}
              />
            </li>
          );
        }
        case 'dateAdded': {
          return (
            <li key={filter}>
              <DateAdded footerHidden dateAdded={dateAdded} handleDateAdded={getDateAdded} t={t} />
            </li>
          );
        }
        default:
          return null;
      }
    });
  }

  render() {
    const {
      browser,
      t,
      transactionType,
      objectType,
      searchTerm,
      subObjectTypes,
      constructionPhase,
      virtualTour,
      openHouse,
      purpose,
      rentToOwn,
      // auction,
      getTransactionType,
      getSearchTerm,
      getSubObjectTypes,
      getConstructionPhase,
      getPurpose,
      getVirtualTour,
      getOpenHouse,
      specialOffers,
      getSpecialOffers,
      fromOwner,
      getFromOwner,
      getRentToOwn,
      // getAuction,
    } = this.props;

    return (
      <div className="search-vertical">
        {browser.lessThan.medium ? (
          <ButtonGroup vertical>
            <TransactionType
              extended
              t={t}
              selected={transactionType}
              onChange={getTransactionType}
              gaCategory={GA_SEARCH}
            />
            <ObjectType
              t={t}
              options={getObjectTypeOptions(true)}
              selected={objectType}
              onChange={this.getObjectType}
            />
            {objectType.get('value') === OBJECT_TYPES.NewProject && (
              <ObjectType
                t={t}
                multiple
                options={OBJECT_TYPE_OPTIONS_NP}
                selected={subObjectTypes}
                onChange={getSubObjectTypes}
              />
            )}
            {[OBJECT_TYPES.Commercial, OBJECT_TYPES.Land].includes(objectType.get('value')) && (
              <div>
                <Select
                  t={t}
                  multiple
                  notched
                  label={t('search.purpose')}
                  native={isMobile}
                  empty={t('search.allPurposes')}
                  options={sortOptions(PURPOSE_OPTIONS.get(objectType.get('value')), t)}
                  selected={purpose.get(objectType.get('value'))}
                  getValue={getPurpose}
                  gaEvent={(label, selected) =>
                    ReactGA.event({
                      category: GA_SEARCH,
                      action: selected || selected === undefined ? 'select_purpose' : 'unselect_purpose',
                      label: selected === undefined ? 'open_dropdown' : GAL_SEARCH_PURPOSE[label],
                    })
                  }
                />
              </div>
            )}
            <div>
              <SearchTerm t={t} searchTerms={searchTerm} setSearchTerm={getSearchTerm} />
            </div>
            {objectType.get('value') === OBJECT_TYPES.NewProject && (
              <ObjectType
                t={t}
                multiple
                options={CONSTRUCTION_PHASE_OPTIONS_NP}
                selected={constructionPhase}
                onChange={getConstructionPhase}
              />
            )}
          </ButtonGroup>
        ) : null}
        <ul>{this.renderFilters()}</ul>
        {objectType.get('value') !== OBJECT_TYPES.NewProject && objectType.get('value') !== OBJECT_TYPES.ModularHouse && (
          <div>
            <div className="filter__heading">
              <h4 className="filter__title">{t('search.additional')}</h4>
            </div>
            {/* <Multiselect
                type="multiselect"
                label="Additional"
                options={ADDITIONAL}
                selected={additional}
                getValue={this.getAdditional}
              /> */}
            <ToggleFieldGroup>
              <ToggleField
                title={t('search.fromOwner')}
                subtitle={t('search.fromOwner.label')}
                id="private_user_ads"
                value="From Owner"
                checked={fromOwner}
                getValue={getFromOwner}
                gaEvent={(checked) =>
                  ReactGA.event({
                    category: GA_SEARCH,
                    action: checked ? 'select_otherFilters' : 'unselect_otherFilters',
                    label: 'fromOwner',
                  })
                }
              />
              <ToggleField
                title={t('search.virtualTour.title')}
                subtitle={t('search.virtualTour.subtitle')}
                id="virtual_tour"
                value={VIRTUAL_TOUR.value}
                checked={virtualTour}
                getValue={getVirtualTour}
                gaEvent={(checked) =>
                  ReactGA.event({
                    category: GA_SEARCH,
                    action: checked ? 'select_otherFilters' : 'unselect_otherFilters',
                    label: 'virtualTour',
                  })
                }
              />
              <ToggleField
                title={t('search.openHouse.title')}
                subtitle={t('search.openHouse.subtitle')}
                id="open_house"
                value={OPEN_HOUSE.value}
                checked={openHouse}
                getValue={getOpenHouse}
                gaEvent={(checked) =>
                  ReactGA.event({
                    category: GA_SEARCH,
                    action: checked ? 'select_otherFilters' : 'unselect_otherFilters',
                    label: 'openDays',
                  })
                }
              />
              <ToggleField
                title={t('search.specialOffers.title')}
                subtitle={t('search.specialOffers.subtitle')}
                id="special_offers"
                value={SPECIAL_OFFERS.value}
                checked={specialOffers}
                getValue={getSpecialOffers}
                gaEvent={(checked) =>
                  ReactGA.event({
                    category: GA_SEARCH,
                    action: checked ? 'select_otherFilters' : 'unselect_otherFilters',
                    label: 'specialOffers',
                  })
                }
              />
              <ToggleField
                title={t('search.rentToOwn.title')}
                subtitle={t('search.rentToOwn.subtitle')}
                id="rent_to_own"
                value={RENT_TO_OWN.value}
                checked={rentToOwn}
                getValue={getRentToOwn}
                gaEvent={(checked) =>
                  ReactGA.event({
                    category: GA_SEARCH,
                    action: checked ? 'select_otherFilters' : 'unselect_otherFilters',
                    label: 'rentToOwn',
                  })
                }
              />
              {/* <ToggleField
                  title={t('search.auction.title')}
                  id="auction"
                  value={AUCTION.value}
                  checked={auction}
                  getValue={getAuction}
                  gaEvent={checked =>
                    ReactGA.event({
                      category: GA_SEARCH,
                      action: checked ? 'select_otherFilters' : 'unselect_otherFilters',
                      label: 'auction'
                    })
                  }
                /> */}
            </ToggleFieldGroup>
          </div>
        )}
        {objectType.get('value') === OBJECT_TYPES.NewProject && (
          <ToggleField
            title={t('search.openHouse.title')}
            subtitle={t('search.openHouse.subtitle')}
            id="open_house"
            value={OPEN_HOUSE.value}
            checked={openHouse}
            getValue={getOpenHouse}
            gaEvent={(checked) =>
              ReactGA.event({
                category: GA_SEARCH,
                action: checked ? 'select_otherFilters' : 'unselect_otherFilters',
                label: 'openDays',
              })
            }
          />
        )}
        {/* {browser.greaterThan.medium && <FilterFooter applyFilter={this.applyFilters} />} */}
      </div>
    );
  }
}

SearchVertical.propTypes = propTypes;
SearchVertical.defaultProps = defaultProps;

function mapStateToProps(state) {
  const browser = getBrowser(state);
  return {
    browser,
    filtersToRender: browser.lessThan.large ? getFiltersToRender(state) : getAdditionalFilters(state),
  };
}

const mapDispatchToProps = { ...searchActions, setAreaSelectModalOpened };

export default connect(mapStateToProps, mapDispatchToProps)(SearchVertical);
