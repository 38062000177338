import React from 'react';
import ReactGA from '@/utils/ga';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getSecondarySpecs, OBJECT_TYPES, TRANSACTION_TYPES } from '@/constants/object';
import {
  TET_ELECTRICITY_LINK,
  TET_INTERNET_LINK,
  TET_OPTICS_LINK,
  TELIA_COMMERCIAL_LINK,
  TELIA_HOME_LINK,
  ALEXELA_PRIVATE_LINK,
  ALEXELA_BUSINESS_LINK,
  ESTO_LINK,
} from '@/constants/integrations';
import { NEARBY_SURROUNDINGS } from '@/constants/attributes';
import { GA_DETAIL_VIEW } from '@/constants/ga';

import Icon from '@/components/icon/Icon';
import Tooltip from '@/components/tooltip/Tooltip';
import Anchor from '@/components/link/Anchor';
import ElektrumSpecsItem from '@/components/integrations/Elektrum/ElektrumSpecsItem';
import AlteroSpecsItem from '@/components/integrations/Altero/AlteroSpecsItem';
import InbankSpecsItem from '@/components/integrations/Inbank/InbankSpecsItem';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  objectType: PropTypes.string,
  minimal: PropTypes.bool,
};

const defaultProps = {
  children: null,
  className: '',
  objectType: undefined,
  minimal: false,
};

class SecondarySpecs extends React.Component {
  renderSingleSpec = (
    specKey,
    specClass,
    iconName,
    content,
    minimal = this.props.minimal,
    link = null,
    linkAction = null
  ) => {
    return minimal ? (
      <li key={specKey} className={specClass} role="tooltip" data-tooltip-id={`${specKey}-tooltip`}>
        <Icon name={iconName} />
        <Tooltip id={`${specKey}-tooltip`} content={content} />
      </li>
    ) : (
      <li key={specKey} className={specClass}>
        {link ? (
          <>
            <Anchor
              href={link}
              onClick={() => {
                linkAction();
              }}
            >
              <Icon name={iconName} />
              &nbsp;<span className="secondary-specs__spec__content">{content}</span>
              <Icon name="angle-right" />
            </Anchor>
          </>
        ) : (
          <>
            <Icon name={iconName} />
            &nbsp;{content}
          </>
        )}
      </li>
    );
  };

  renderSpec = (spec) => {
    const { [spec]: prop, transactionType, objectType, t } = this.props;

    if (prop) {
      switch (spec) {
        case 'bedrooms':
          return (
            transactionType === !TRANSACTION_TYPES.SALE &&
            objectType === !OBJECT_TYPES.APARTMENT &&
            this.renderSingleSpec(
              spec,
              'secondary-specs__spec',
              'pillow',
              `${prop} ${t('objectSpec.trailing.bedrooms')}`
            )
          );

        case 'bathrooms':
          return (
            prop > 1 &&
            this.renderSingleSpec(
              spec,
              'secondary-specs__spec',
              'bathroom',
              t('objectSpec.trailing.bathrooms', { count: prop })
            )
          );

        case 'elevator':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'elevator', t('objectSpec.elevator'));

        case 'balcony':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'balcony', t('objectSpec.balconyType'));

        case 'terrace':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'terrace', t('BALCONY_TYPE.terrace'));

        case 'sauna':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'thermostat', t('SANITARY_ARRANGEMENTS.sauna'));

        case 'constructionYear':
          return (
            transactionType === TRANSACTION_TYPES.SALE &&
            this.renderSingleSpec(spec, 'secondary-specs__spec', 'bricks', `${t('objectSpec.builtIn')} ${prop}`)
          );

        case 'storage':
          return (
            transactionType === !TRANSACTION_TYPES.RENT &&
            objectType === !OBJECT_TYPES.Apartment &&
            this.renderSingleSpec(spec, 'secondary-specs__spec', 'boxes', t('objectSpec.storageRoom'))
          );

        case 'storageSpace':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'boxes', t('objectSpec.storageRoom'));

        case 'energyCertificateType':
          return (
            (transactionType === !TRANSACTION_TYPES.RENT && objectType === !OBJECT_TYPES.Apartment) ||
            (transactionType === !TRANSACTION_TYPES.RENT &&
              objectType === !OBJECT_TYPES.CottageOrVilla &&
              this.renderSingleSpec(
                spec,
                'secondary-specs__spec',
                'bulb',
                `${prop} ${t('objectSpec.trailing.energyCertificate')}`
              ))
          );

        case 'parking':
          return this.renderSingleSpec(
            spec,
            'secondary-specs__spec',
            'parking',
            prop
              .map((i) =>
                t(
                  `${
                    objectType === OBJECT_TYPES.Commercial ? 'PARKING_SELECTION_COMMERCIAL' : 'PARKING_SELECTION'
                  }.${i}`
                )
              )
              .join(', ')
          );

        case 'immediatelyAvailable':
          return (
            transactionType === TRANSACTION_TYPES.RENT &&
            this.renderSingleSpec(spec, 'secondary-specs__spec', 'key', t('objectSpec.availableImmediately'))
          );

        case 'furniture':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'furniture', t('objectSpec.furniture'));

        case 'purposeOfUse':
          return this.renderSingleSpec(
            spec,
            'secondary-specs__spec',
            'purpose-of-use',
            prop
              .map((i) =>
                t(`${objectType === OBJECT_TYPES.Land ? 'PURPOSE_OF_USE_LAND' : 'PURPOSE_OF_USE_COMMERCIAL'}.${i}`)
              )
              .join(', ')
          );

        case 'electricity':
          return (
            transactionType === TRANSACTION_TYPES.SALE &&
            this.renderSingleSpec(spec, 'secondary-specs__spec', 'bolt', t('ELECTRICITY.ELECTRICITY'))
          );

        case 'gas':
          return (
            transactionType === TRANSACTION_TYPES.SALE &&
            this.renderSingleSpec(spec, 'secondary-specs__spec', 'bubbles', t('GAS.GAS'))
          );

        case 'water':
          return (
            transactionType === TRANSACTION_TYPES.SALE &&
            this.renderSingleSpec(spec, 'secondary-specs__spec', 'water', t('WATER.WATER'))
          );

        case 'garden':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'garden', t('objectSpec.garden'));

        case 'road':
          return (
            transactionType === TRANSACTION_TYPES.SALE &&
            this.renderSingleSpec(spec, 'secondary-specs__spec', 'road', t('ROAD.ROAD'))
          );

        case 'sewerage':
          return (
            transactionType === TRANSACTION_TYPES.SALE &&
            this.renderSingleSpec(spec, 'secondary-specs__spec', 'pipe', t('HAS_SEWERAGE.HAS_SEWERAGE'))
          );

        case 'heating':
          return (
            transactionType === TRANSACTION_TYPES.SALE &&
            this.renderSingleSpec(
              spec,
              'secondary-specs__spec',
              '',
              <>
                <span>{`${prop.join(', ')}`}</span>
                <span>{prop.map((i) => t(`HEATING_SYSTEM.${i}`)).join(', ')}</span>
              </>
            )
          );

        case 'ventilation':
          return (
            transactionType === TRANSACTION_TYPES.SALE &&
            this.renderSingleSpec(
              spec,
              'secondary-specs__spec',
              '',
              prop.map((i) => t(`VENTILATION_TYPE.${i}`)).join(', ')
            )
          );

        case 'meetingRoom':
          return (
            transactionType === TRANSACTION_TYPES.SALE &&
            this.renderSingleSpec(spec, 'secondary-specs__spec', 'meeting-room', t('objectSpec.meetingRoom'))
          );

        case 'sharedWarehouse':
          return this.renderSingleSpec(
            spec,
            'secondary-specs__spec',
            'shared-warehouse',
            t('objectSpec.sharedWarehouse')
          );

        case 'sharedLounge':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'shared-lounge', t('objectSpec.sharedLounge'));

        case 'smartHome':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'smart-home', t('objectSpec.smartHome'));

        case 'heatedFloors':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'thermostat', t('objectSpec.heatedFloors'));

        case 'finishStage':
          return this.renderSingleSpec(
            spec,
            'secondary-specs__spec',
            'finish-stage',
            prop.map((i) => t(`${'FINISH_STAGE'}.${i}`)).join(', ')
          );

        case 'achievements':
          return this.renderSingleSpec(spec, 'secondary-specs__spec secondary-specs__spec--highlight', 'cup', prop);

        case 'outdoorSpaces':
          return this.renderSingleSpec(
            spec,
            'secondary-specs__spec',
            'balcony',
            prop.map((i) => t(`${'OUTDOOR_SPACES'}.${i}`)).join(', ')
          );

        case 'nearbySurroundings':
          if (prop.size > 0) {
            return prop.map((i) => {
              const getIconName = (item) => {
                switch (item) {
                  case NEARBY_SURROUNDINGS.CLOSE_TO_THE_WATER:
                    return 'close-to-water';
                  case NEARBY_SURROUNDINGS.CLOSE_TO_THE_PARK:
                    return 'close-to-park';
                  case NEARBY_SURROUNDINGS.CLOSE_TO_THE_FOREST:
                    return 'close-to-forest';
                  case NEARBY_SURROUNDINGS.GREEN_CORTYARDS:
                    return 'green-courtyards';
                  case NEARBY_SURROUNDINGS.PLAYGROUND:
                    return 'playground';
                  case NEARBY_SURROUNDINGS.SPORTS_FIELD:
                    return 'sports-field';
                  default:
                    return null;
                }
              };
              return this.renderSingleSpec(i, 'secondary-specs__spec', getIconName(i), t(`NEARBY_SURROUNDINGS.${i}`));
            });
          }
          return null;

        case 'evCharger':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'car-circle-bolt', t('objectSpec.evCharger'));

        case 'permissionToBuild':
          return this.renderSingleSpec(
            spec,
            'secondary-specs__spec',
            'house-circle-check',
            t('objectSpec.permissionToBuild')
          );

        case 'permissionToUse':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'file-check', t('objectSpec.permissionToUse'));

        case 'runningBusiness':
          return this.renderSingleSpec(spec, 'secondary-specs__spec', 'briefcase-2', t('objectSpec.runningBusiness'));

        default:
          return '';
      }
    }
    return null;
  };

  render() {
    const {
      objectType,
      row,
      nrOfApartments,
      openDays,
      rentToOwn,
      tetAvailability,
      elektrumOffer,
      alteroOffer,
      teliaOffer,
      estoOffer,
      alexelaOffer,
      inbankOffer,
      getAProVariant,
      className,
      minimal,
      language,
      t,
    } = this.props;

    const secondarySpecs = getSecondarySpecs(objectType);

    const secondarySpecsStyle = classNames(
      'secondary-specs',
      {
        'secondary-specs--row': row,
        'secondary-specs__minimal': minimal,
      },
      className
    );

    return (
      <ul className={secondarySpecsStyle}>
        {nrOfApartments &&
          this.renderSingleSpec(
            'nrOfApartments',
            'secondary-specs__spec',
            'apartment-o',
            t('objectSpec.nrOfApartments', {
              count: Number(nrOfApartments),
            })
          )}
        {secondarySpecs.map(this.renderSpec)}
        {openDays && this.renderSingleSpec('openDays', 'object__labels-open', 'open-house', openDays, false)}
        {rentToOwn &&
          this.renderSingleSpec(
            'rentToOwn',
            'secondary-specs__spec secondary-specs__spec--rent_to_own',
            'key',
            t('objectSpec.rentToOwn'),
            false
          )}
        {tetAvailability && (
          <>
            {this.renderSingleSpec(
              'tetAvailability',
              'secondary-specs__spec secondary-specs__spec--tet',
              tetAvailability === 1 ? 'tet' : 'tet-e',
              tetAvailability === 1 ? t(`objectSpec.tetOptics`) : t(`objectSpec.tetElectricity`),
              false,
              tetAvailability === 1 ? TET_OPTICS_LINK : TET_ELECTRICITY_LINK,
              () => {
                ReactGA.event({
                  category: GA_DETAIL_VIEW,
                  action: 'TetLinkClick',
                  label: tetAvailability === 1 ? 'optics' : 'electricity',
                });
              }
            )}
            {this.renderSingleSpec(
              'tetAvailability',
              'secondary-specs__spec secondary-specs__spec--tet',
              tetAvailability === 1 ? 'tet-e' : 'tet-i',
              tetAvailability === 1 ? t(`objectSpec.tetElectricity`) : t(`objectSpec.tetInternet`),
              false,
              tetAvailability === 1 ? TET_ELECTRICITY_LINK : TET_INTERNET_LINK,
              () => {
                ReactGA.event({
                  category: GA_DETAIL_VIEW,
                  action: 'TetLinkClick',
                  label: tetAvailability === 1 ? 'electricity' : 'internet',
                });
              }
            )}
          </>
        )}
        {elektrumOffer && (
          <li key="elektrumElectricity" className="secondary-specs__spec secondary-specs__spec--fw">
            <ElektrumSpecsItem language={language} t={t} />
          </li>
        )}
        {alteroOffer && (
          <li key="altero" className="secondary-specs__spec secondary-specs__spec--fw">
            <AlteroSpecsItem language={language} t={t} />
          </li>
        )}
        {inbankOffer && (
          <li key="inbank" className="secondary-specs__spec secondary-specs__spec--fw">
            <InbankSpecsItem language={language} t={t} />
          </li>
        )}
        {teliaOffer &&
          this.renderSingleSpec(
            'teliaOffer',
            'secondary-specs__spec secondary-specs__spec--telia',
            'telia',
            t(`objectSpec.telia.${teliaOffer}`),
            false,
            teliaOffer === 'home' ? TELIA_HOME_LINK[language] : TELIA_COMMERCIAL_LINK[language],
            () => {
              ReactGA.event({
                category: GA_DETAIL_VIEW,
                action: 'TeliaLinkClick',
                label: teliaOffer,
              });
            }
          )}
        {estoOffer &&
          this.renderSingleSpec(
            'estoOffer',
            'secondary-specs__spec secondary-specs__spec--esto',
            'esto',
            t('objectSpec.estoOffer'),
            false,
            ESTO_LINK[language],
            () => {
              ReactGA.event({
                category: GA_DETAIL_VIEW,
                action: 'EstoLinkClick',
              });
            }
          )}
        {alexelaOffer &&
          this.renderSingleSpec(
            'alexelaOffer',
            'secondary-specs__spec secondary-specs__spec--alexela',
            'alexela',
            t(`objectSpec.alexela.${alexelaOffer}`),
            false,
            alexelaOffer === 'private' ? ALEXELA_PRIVATE_LINK[language] : ALEXELA_BUSINESS_LINK[language],
            () => {
              ReactGA.event({
                category: GA_DETAIL_VIEW,
                action: 'AlexelaLinkClick',
                label: alexelaOffer,
              });
            }
          )}
        {getAProVariant &&
          this.renderSingleSpec(
            'getAPro',
            'secondary-specs__spec secondary-specs__spec--get-a-pro',
            'get-a-pro',
            t(getAProVariant.text),
            false,
            getAProVariant.link,
            () => {
              ReactGA.event({
                category: GA_DETAIL_VIEW,
                action: 'GetAProSecondarySpecsClick',
                label: getAProVariant.variant,
              });
            }
          )}
      </ul>
    );
  }
}

SecondarySpecs.propTypes = propTypes;
SecondarySpecs.defaultProps = defaultProps;

export default SecondarySpecs;
