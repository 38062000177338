import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  title: '',
  className: '',
};

const VirtualTour = (props) => {
  const { src, title, className } = props;

  const virtualTourStyle = classNames(
    {
      'virtual-tour': true,
    },
    className
  );

  return <iframe src={src} className={virtualTourStyle} title={title} />;
};

VirtualTour.propTypes = propTypes;
VirtualTour.defaultProps = defaultProps;

export default VirtualTour;
