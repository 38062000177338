import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UPDATE_MY_OBJECT } from '@/constants/actions';
import { NotificationName } from './notificationInterfaces';

const initialState: {
  open: boolean;
  name: NotificationName | '';
} = {
  open: false,
  name: '',
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    openNotification(state, action: PayloadAction<NotificationName>) {
      state.open = true;
      state.name = action.payload;
    },
    closeNotification(state, action: PayloadAction<void>) {
      state.open = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(UPDATE_MY_OBJECT, (state, action) => {
      state.open = true;
      state.name = NotificationName.MyObjectSaved;
    });
  },
});

export const { openNotification, closeNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
