export type MaybeArray<T> = T | T[];

export enum AsyncDataStatus {
  None,
  Loading,
  Loaded,
}

export interface AsyncData<T = any> {
  status: AsyncDataStatus;
  data: T;
  error: Error | string | false;
}

export function createAsyncData<T>(
  data: T,
  status = AsyncDataStatus.None,
  error: AsyncData['error'] = false
): AsyncData<T> {
  return {
    status,
    data,
    error,
  };
}

export interface NameValue<Value extends string = string> {
  name: string;
  value: Value;
}

export interface NameValueExtra<T = Record<string, unknown>> extends NameValue {
  extra: T;
}

export enum SortingOrder {
  asc = 'asc',
  desc = 'desc',
  default = 'default',
}

export interface SortableNameValue extends NameValue {
  order: SortingOrder;
}

export function isSortableNameValue(item: NameValue | SortableNameValue): item is SortableNameValue {
  return (item as SortableNameValue).order !== undefined;
}
