import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import routeConfig from '@/constants/routes';

import PrivateRoute from '@/components/router/PrivateRoute';
import LoanFeedback from './Finance/LoanFeedback';

const Main = lazy(() => import('./Main'));
const NewProjectsLanding = lazy(() => import('./NewProjectsLanding'));
const ObjectResults = lazy(() => import('./ObjectResults'));
const MyObjects = lazy(() => import('./MyObjects'));
const Favourites = lazy(() => import('./Favourites'));
const MySearches = lazy(() => import('./MySearches'));
const PageNotFound = lazy(() => import('./PageNotFound'));
const PasswordReset = lazy(() => import('./PasswordReset'));
const BrokerSearch = lazy(() => import('./BrokerSearch'));
const CompanySearch = lazy(() => import('./CompanySearch'));
const BrokerDetail = lazy(() => import('./brokerDetail/BrokerDetail'));
const BrokerRequest = lazy(() => import('./brokerRequest/BrokerRequest'));
const RateBroker = lazy(() => import('./rateBroker/RateBroker'));
const CompanyDetail = lazy(() => import('./companyDetail/CompanyDetail'));
const MyObject = lazy(() => import('./MyObject'));
const AccountPromo = lazy(() => import('./AccountPromo'));
const Invoices = lazy(() => import('./Invoices'));
const VirtualTourPromo = lazy(() => import('./VirtualTourPromo'));
const Payment = lazy(() => import('./Payment'));
const Settings = lazy(() => import('./Settings/Settings'));
const FinanceCenter = lazy(() => import('./Finance/FinanceCenter'));
const LoanApplication = lazy(() => import('./Finance/LoanApplication'));
const AdminTools = lazy(() => import('./AdminTools'));
const NewsletterUnsubscribe = lazy(() => import('./unsubscribe/Newsletter'));
const SearchesUnsubscribe = lazy(() => import('./unsubscribe/Searches'));
const HotOffersHousesAndLands = lazy(() => import('./landingPages/HotOffersHousesAndLands'));
const OpenDays = lazy(() => import('./landingPages/OpenDays'));

function AppRoutes(): React.ReactElement {
  return (
    <Switch>
      <Route exact path={routeConfig.root} component={Main} />
      <Route path={routeConfig.login} component={Main} />
      <Route path={routeConfig.registration} component={Main} />
      <Route exact path={routeConfig.newProjects} component={NewProjectsLanding} />
      <Route path={routeConfig.objectResults} component={ObjectResults} />
      <Route path={routeConfig.favourites} component={Favourites} />
      <Route path={routeConfig.mySearches} component={MySearches} />
      <Route path={routeConfig.passwordReset} component={PasswordReset} />
      <Route path={routeConfig.brokerDetail} component={BrokerDetail} />
      <Route path={routeConfig.brokerRequest} component={BrokerRequest} />
      <Route path={routeConfig.rateBroker} component={RateBroker} />
      <Route path={routeConfig.companyDetail} component={CompanyDetail} />
      <Route path={routeConfig.payment} component={Payment} />
      <Route path={routeConfig.myObject} component={MyObject} />
      {PORTAL_EE && <Route exact path={routeConfig.financeCenter} component={FinanceCenter} />}
      {PORTAL_EE && <Route path={routeConfig.loanApplication} component={LoanApplication} />}
      {PORTAL_EE && <Route path={routeConfig.loanFeedback} component={LoanFeedback} />}
      <PrivateRoute path={routeConfig.myObjects} component={MyObjects} />
      <PrivateRoute path={routeConfig.invoices} component={Invoices} />
      <PrivateRoute path={routeConfig.settings} component={Settings} />
      <PrivateRoute path={routeConfig.adminTools} component={AdminTools} />
      <Route path={routeConfig.brokerSearch} component={BrokerSearch} />
      <Route path={routeConfig.companySearch} component={CompanySearch} />
      {PORTAL_LV && <Route path={routeConfig.hotOffersHousesAndLands} component={HotOffersHousesAndLands} />}
      {PORTAL_LV && <Route path={routeConfig.openDays} component={OpenDays} />}
      {process.env.NEXT_PUBLIC_APP_ENV === 'development'
        ? [
            <Route key="virtualTourPromo" path={routeConfig.virtualTourPromo} component={VirtualTourPromo} />,
            <Route key="accountPromo" path={routeConfig.accountPromo} component={AccountPromo} />,
          ]
        : null}
      <Route path={routeConfig.newsletterUnsubscribe} component={NewsletterUnsubscribe} />
      <Route path={routeConfig.searchesUnsubscribe} component={SearchesUnsubscribe} />
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default AppRoutes;
