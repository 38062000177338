import React from 'react';
import classNames from 'classnames';
import Image, { ImageProps } from '@/components/img/Image';

interface Props {
  image?: ImageProps['src'];
  imageAlt?: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  horizontal?: boolean;
  withBg?: boolean;
}

const FancySection = ({ title, subtitle, image, imageAlt, horizontal = false, withBg = false }: Props) => {
  return (
    <div
      className={classNames('fancy-section', { 'fancy-section--horizontal': horizontal, 'fancy-section--bg': withBg })}
    >
      {image ? <Image src={image} alt={imageAlt} className="fancy-section__img" /> : null}
      <h3 className="fancy-section__title">{title}</h3>
      <div className="fancy-section__subtitle">{subtitle}</div>
    </div>
  );
};

export default FancySection;
