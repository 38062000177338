import { createSelector, defaultMemoize } from 'reselect';
import { Map } from 'immutable';

import { RootState } from '@/store';
import { OBJECT_TYPES, TRANSACTION_TYPES, MY_OBJECTS_PER_PAGE } from '@/constants/object';
import * as objectStates from '@/utils/objectStates';
import { formatDate, fullAddress } from '@/utils/formatting';
import { fromISOString } from '@/utils/date';
import { getFormattedPublishingRange } from '@/utils/publishing';
import { identity } from '@/utils/helpers';

export const getMyObjectsList = createSelector(
  (state: RootState): Map<string, any>[] => state.myObjects.objects,
  (objects) => {
    const published = objects.filter((o) => objectStates.isPublished(o.get('status_id')));
    published.sort((a, b) => b.get('date_created') - a.get('date_created'));
    const unpublished = objects.filter((o) => objectStates.isUnpublished(o.get('status_id')));
    unpublished.sort((a, b) => b.get('date_modified') - a.get('date_modified'));
    const drafts = objects.filter((o) => objectStates.isDraft(o.get('status_id')));
    drafts.sort((a, b) => b.get('date_modified') - a.get('date_modified'));

    return {
      published,
      unpublished,
      drafts,
    };
  }
);

export const getMyObjectsPageCount = createSelector(
  (state: RootState) => state.myObjects.totalItems,
  (totalItems) => {
    return Math.ceil(totalItems / MY_OBJECTS_PER_PAGE);
  }
);

export const getMyObject = (state: RootState) => state.myObject.userInterface;
export const getMyObjectOriginal = (state: RootState) => state.myObject.original;

export const getMyObjectId = (state: RootState) => getMyObject(state).get('id');
export const getMyObjectFriendlyId = (state: RootState) => getMyObjectOriginal(state).get('friendly_id');

export const getMyObjectType = (state: RootState) => getMyObject(state).get('objectType');

export const getMyObjectStatus = (state: RootState) => getMyObject(state).get('status');
export const getMyObjectPromoCode = (state: RootState) => getMyObject(state).getIn(['packages', 'promoCode']);
export const getLandRegistrySearch = (state: RootState) => getMyObject(state).getIn(['location', 'landRegistrySearch']);

export const getAddress = (state: RootState) => getMyObject(state).getIn(['location', 'address']);
export const getAddressMap = createSelector(getAddress, (address) => {
  return Map({
    county_name: address.getIn(['county', 'name']),
    parish_name: address.getIn(['cityParish', 'name']),
    district_name: address.getIn(['districtSettlement', 'name']),
    street_name: address.getIn(['street', 'name']),
    house_number: address.get('houseNr'),
    location_name: address.get('place'),
    apartment_number: address.get('apartmentNr'),
  });
});

export const getAddressString = createSelector(getAddressMap, (address) => {
  return fullAddress(address);
});

export const getCadasterNr = (state: RootState) => getMyObject(state).getIn(['location', 'cadasterNr']);
export const getEstateRegisterNr = (state: RootState) => getMyObject(state).getIn(['location', 'estateRegisterNr']);
export const getPosition = (state: RootState) => getMyObject(state).getIn(['location', 'position']);
export const getZoom = (state: RootState) => getMyObject(state).getIn(['location', 'address', 'zoom']);
export const getStreetviewPov = (state: RootState) => getMyObject(state).getIn(['location', 'pov']);
export const getMyObjectImg = (state: RootState) => getMyObject(state).get('images');

export const getMainImageFromList = defaultMemoize(
  (images) => images.find((img) => img.image_type === 'main_image') || images[0] || null
);
export const getMyObjectVideos = (state: RootState) => getMyObject(state).getIn(['images', 'videos']);
export const getAdCategory = createSelector(getMyObject, (myObject) =>
  Map({
    transactionType: myObject.get('transactionType'),
    objectType: myObject.get('objectType'),
  })
);
export const getAdInfo = (state: RootState) => getMyObject(state).get('info');
export const getAdPayment = (state: RootState) => getMyObject(state).get('payment');

export const getMyObjectPackages = (state: RootState) => getMyObject(state).get('packages');

export const getAllowedInfoFields = createSelector(
  (state: RootState) => getMyObject(state).getIn(['objectType', 'value']),
  (state: RootState) => getMyObject(state).getIn(['transactionType', 'value']),
  (objectType, transactionType) => {
    const land = objectType === OBJECT_TYPES.Land;
    const commercial = objectType === OBJECT_TYPES.Commercial;
    const residential = !land && objectType !== OBJECT_TYPES.Garage;

    return {
      purposeOfUse: commercial || land,
      detailedPlan: land,
      size: !land,
      rooms: residential,
      floor: [OBJECT_TYPES.Apartment, OBJECT_TYPES.Commercial].includes(objectType),
      totalFloors: residential,
      buildingMaterial: !land && !(commercial && transactionType === TRANSACTION_TYPES.SALE),
      condition: !land,
      lotSize: ![OBJECT_TYPES.Apartment, OBJECT_TYPES.Garage].includes(objectType),
      balconyType: residential && !commercial,
      balconySize: residential && !commercial,
      elevator: commercial || [OBJECT_TYPES.Apartment, OBJECT_TYPES.HouseShare].includes(objectType),
      yearBuilt: !land,
      roofType: residential,
      energyCertificate: PORTAL_EE && residential,
      numberOfBedrooms: residential && !commercial,
      numberOfBathrooms: residential && !commercial,
      kitchenArea: residential && !commercial,
      numberOfDesks: commercial,
      parkingSelection: residential,
      parkingPlaces: commercial,
      parkingCost: commercial,
      vat: commercial && transactionType === TRANSACTION_TYPES.RENT,
      heatingSystem: residential,
      sanitary: residential,
      stove: residential && !commercial,
      ventilation: residential,
      extraSpaces: residential,
      municipalEngineering: land,
      communications: residential,
      security: !land,
      extraValues: residential,
      limitations: PORTAL_EE,
      brokersFee: transactionType !== TRANSACTION_TYPES.RENT,
      depositMoney: transactionType === TRANSACTION_TYPES.RENT,
      projectType: PORTAL_LV && objectType === OBJECT_TYPES.Apartment,
      communalCosts: transactionType === TRANSACTION_TYPES.RENT,
    };
  }
);

export const getSelectedPackages = createSelector(
  (state: RootState) => getMyObjectPackages(state).getIn(['services', 'draft']),
  (services) => {
    return services.filter((item) => item.get('selected'));
  }
);

export const getPeriodPackage = createSelector(
  (state: RootState) => getMyObjectPackages(state).get('period'),
  (state: RootState) => getMyObjectPackages(state).get('publishing'),
  (periodService, publishing) => {
    const formattedPeriod = (() => {
      const publishingEndDate: Date | null =
        publishing.get('active') && publishing.get('endDate') ? fromISOString(publishing.get('endDate')) : null;
      // If existing publishing is still active, use the end date for next start date
      if (publishingEndDate && publishingEndDate > new Date()) {
        publishingEndDate.setDate(publishingEndDate.getDate() + 1);
        publishingEndDate.setHours(0, 0, 0);
        return getFormattedPublishingRange(periodService.get('period'), publishingEndDate);
      }
      return getFormattedPublishingRange(periodService.get('period'));
    })();
    return periodService.set('formattedPeriod', formattedPeriod);
  }
);

export const getKvExportServiceDetails = (state: RootState) =>
  getMyObjectPackages(state).getIn(['period', 'kvExportService']);

export const getActiveBoughtPeriodService = createSelector(
  (state: RootState) => getMyObjectPackages(state).getIn(['period', 'active']),
  (services) => {
    return defaultMemoize((paymentId: number) => {
      const service = services.find((srv) => srv.get('paymentId') === paymentId);
      if (!service) return null;
      return service.set(
        'formattedPeriod',
        `${formatDate(fromISOString(service.get('startDate')))} - ${formatDate(fromISOString(service.get('endDate')))}`
      );
    });
  }
);
export const getActiveBoughtServices = createSelector(
  (state: RootState) => getMyObjectPackages(state).getIn(['services', 'active']),
  (services) => {
    return defaultMemoize((paymentId: number) =>
      services.map((srvs) => srvs.find((srv) => srv.get('paymentId') === paymentId)).filter(identity)
    );
  }
);
