import {
  MAP_OBJECT_SELECTED,
  MAP_OBJECT_UPDATE,
  MAP_LOAD_NEARBY_PLACES,
  MAP_LOAD_OBJECT_DETAILS,
  MAP_CLEAR_OBJECTS,
  MAP_LOAD_OBJECT_DETAILS_INIT,
  MAP_LOAD_OBJECT_DETAILS_FINISHED,
  SET_STREET_VIEW_AVAILABILITY,
  OBJECT_TO_MAP_MARKER,
  CLEAR_OBJECT_TO_MAP_MARKER,
  MAP_CLEAR_SELECTED_ZOOM,
  HIGHLIGHT_OBJECT_MAP_MARKER,
  SET_MAP_BOUNDS,
} from '@/constants/actions';
import { OBJECT_TYPES } from '@/constants/object';
import { getPlaceTypeCodes } from '@/constants/map';

import api from '@/api/City24Api';
import { NotFoundError, HttpError } from '@/errors/http';
import { extendBounds } from '@/utils/map';
import { closeMapInfo } from '../MapInfo/mapInfoActions';

export function selectMapObject(marker) {
  return {
    type: MAP_OBJECT_SELECTED,
    marker,
  };
}

function objectQueryByObjectType(objectType, id) {
  const query = { short: true };
  if (objectType === OBJECT_TYPES.NewProject) {
    return api.getProject(id, query);
  }
  if (objectType === OBJECT_TYPES.ModularHouse) {
    return api.getModularHouse(id, query);
  }
  return api.getObject(id, query);
}

/*
 * @param {Array} ids subobjects of selected object - object group
 */
export function fetchSelectedObjectData(objectType, ids) {
  return (dispatch) => {
    dispatch({ type: MAP_LOAD_OBJECT_DETAILS_INIT, ids });
    return Promise.allSettled(
      ids.map((current) =>
        objectQueryByObjectType(objectType, current)
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            if (res.status === 404) {
              throw new NotFoundError('Object not found');
            } else {
              throw new HttpError('Error');
            }
          })
          .then((object) => {
            return dispatch({ type: MAP_LOAD_OBJECT_DETAILS, id: current, object });
          })
      )
    ).then(() => {
      return dispatch({ type: MAP_LOAD_OBJECT_DETAILS_FINISHED });
    });
  };
}

export function composeBoundingBox(n, w, s, e) {
  return {
    nw: `${n},${w}`,
    se: `${s},${e}`,
  };
}

function composeMapSearchQuery(bounds, zoomLevel) {
  const [north, south, west, east] = extendBounds(
    bounds.getNorth(),
    bounds.getSouth(),
    bounds.getWest(),
    bounds.getEast(),
    0
  ).map((nr) => nr.toFixed(6));

  return {
    // Extend bounding box so items outside of view will get loaded as well
    boundingBox: composeBoundingBox(north, west, south, east),
    // Force to range of 1 - 20
    zoomLevel: Math.max(1, (zoomLevel / 24) * 20).toFixed(3),
    extent: Math.max(512, (zoomLevel / 24) * 1500).toFixed(0),
  };
}

export function updateMapObjects(searchQuery, bounds, zoomLevel) {
  return (dispatch) => {
    if (!searchQuery) {
      return Promise.resolve(dispatch({ type: MAP_OBJECT_UPDATE, markers: [] }));
    }
    const query = {
      ...searchQuery,
      ...composeMapSearchQuery(bounds, zoomLevel),
    };

    return api
      .searchMapObjects(query.unitType, query)
      .then((res) => (res.ok ? res.json() : []))
      .then((markers) =>
        dispatch({
          type: MAP_OBJECT_UPDATE,
          markers,
        })
      );
  };
}

export function clearMapObjects() {
  return {
    type: MAP_CLEAR_OBJECTS,
  };
}

export function fetchNearbyPlaces(latitude, longitude) {
  const coordinates = `${latitude},${longitude}`;
  return (dispatch) => {
    return Promise.all(
      ['kindergarden', 'school', 'transport'].map((group) =>
        api
          .searchNearbyPlaces({
            coordinates,
            'placeType.placeTypeCode': getPlaceTypeCodes(group),
          })
          .then((res) => (res.ok ? res.json() : []))
          .then((places) =>
            dispatch({
              type: MAP_LOAD_NEARBY_PLACES,
              group,
              places,
            })
          )
      )
    );
  };
}

export function checkStreetView(lat, lng, radius) {
  return (dispatch) => {
    let available;
    fetch(
      `https://maps.googleapis.com/maps/api/streetview/metadata?size=600x300&location=${lat},${lng}&radius=${radius}&key=${process.env.NEXT_PUBLIC_STREETVIEW_ACCESS_TOKEN}`
    )
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 'OK') {
          available = true;
        } else {
          available = false;
        }
        dispatch({
          type: SET_STREET_VIEW_AVAILABILITY,
          available,
        });
      });
  };
}

export function showObjectOnMap(marker, queriedUnitType) {
  return (dispatch) => {
    dispatch(closeMapInfo());
    dispatch(selectMapObject(marker));
    return dispatch(fetchSelectedObjectData(marker.unit_type || queriedUnitType, marker.ids)).then(() => {
      return dispatch({
        type: CLEAR_OBJECT_TO_MAP_MARKER,
      });
    });
  };
}

export function setObjectToMarker(id, latitude, longitude, zoom = 18) {
  return {
    type: OBJECT_TO_MAP_MARKER,
    object: { id, latitude, longitude, zoom },
  };
}

export function highlightObjectToMarker(id) {
  return {
    type: HIGHLIGHT_OBJECT_MAP_MARKER,
    id,
  };
}
export function clearHighlightObjectToMarker() {
  return {
    type: HIGHLIGHT_OBJECT_MAP_MARKER,
    id: null,
  };
}

export function deleteSelectedZoom() {
  return { type: MAP_CLEAR_SELECTED_ZOOM };
}

export function setMapBounds(n, e, s, w, z) {
  return {
    type: SET_MAP_BOUNDS,
    bounds: {
      n,
      e,
      s,
      w,
    },
    zoom: z,
  };
}
