import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import ReactGA from '@/utils/ga';
import type { TFunction } from 'next-i18next';

import Language, { ISO639_2 } from '@city24/common/enums/Language';
import { NameValue } from '@/types/collections';
import { ROUTES } from '@/constants/routes';
import { LANGUAGES } from '@/constants';
import { GA_HEADER_MENU } from '@/constants/ga';
import { ROLE_CUSTOMER_SUPPORT } from '@/constants/signIn';
import { fixedBody, hiddenHeader } from '@/utils/styles';
import { getUserFullName } from '@/utils/contacts';

import { RootState, ConnectedProps } from '@/store';
import { toggleHeader } from '@/actions/appActions';

import { setSearchModalOpened } from '@/components/modals/ModalActions';
import { fetchFavourites, loadFavouriteObjects, signOut } from '@/actions/userActions';
import { getObjMediaFull } from '@/components/object/objectDetailSelectors';
import { getFilterCount } from '@/components/search/searchSelectors';
import { getBrowser, getSearchModalOpened, getHeaderHidden, getLanguage } from '@/selectors/appSelectors';
import { getUser, getFavourites, getSavedSearches, getFavouriteObjects } from '@/selectors/userSelectors';
import { ModalName } from '@/components/modals/modalInterfaces';
import { openModal } from '@/components/modals/modalReducer';

import Icon from '@/components/icon/Icon';
import SimpleObject from '@/components/object/SimpleObject/SimpleObject';
import QuickSearch from '@/components/quickSearch/QuickSearch';
import ActionList from '@/components/actionList/ActionList';
import LinkButton from '@/components/button/LinkButton';
import AppContext from '@/components/appContext';
import AuthModals from '@/components/modals/AuthModals';
import Image from '@/components/img/Image';
import logoEE from '@/img/c24_ee_ua.svg';
import logoLV from '@/img/c24_lv_ua.svg';
import MobNav from './MobNav';
import MenuSelect, { MenuSelectContext } from './MenuSelect';
import LanguageSwitcher, { LanguageSwitcherProps } from './LanguageSwitcher';

// const moreLinks = List([
//   Map({ name: 'header.moreLinks.news', value: 'news', route: '' }),
//   Map({ name: 'header.moreLinks.homeLoan', value: 'homeLoan', route: '' }),
//   Map({ name: 'header.moreLinks.homeInsurance', value: 'homeInsurance', route: '' })
// ]);

export interface HeaderProps {
  branded?: boolean;
  topRef?: React.RefObject<HTMLDivElement>;
}
export interface HeaderWrapperProps extends HeaderProps {
  t: TFunction;
  renderLanguageLink: LanguageSwitcherProps['renderLink'];
  routes: typeof ROUTES;
  changeLanguage: (lang: Language) => void;
}

export type HeaderComponentProps = HeaderWrapperProps & PropsFromRedux;
interface State {
  mobMenuOpened: boolean;
  mobMenuClosing: boolean;
}

class BaseHeader extends React.Component<HeaderComponentProps, State> {
  windowScroll = 0;

  languageOptions: NameValue<Language>[];

  constructor(props: HeaderComponentProps) {
    super(props);
    this.state = {
      mobMenuOpened: false,
      mobMenuClosing: false,
    };

    this.languageOptions = LANGUAGES.map((lang) => ({ name: ISO639_2[lang], value: lang }));
  }

  componentDidMount() {
    const { dispatch, browser } = this.props;
    dispatch(fetchFavourites());

    if (browser.lessThan.large) {
      this.hideHeader();
    }

    window.addEventListener('scroll', this.handleScroll, { passive: true });
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps: HeaderComponentProps, prevState: State) {
    const { headerHidden } = this.props;
    const { mobMenuOpened } = this.state;

    if (prevState.mobMenuOpened !== mobMenuOpened) {
      if (mobMenuOpened) {
        fixedBody(true);
      } else {
        fixedBody(false);
      }
    }

    if (prevProps.headerHidden !== headerHidden) {
      if (headerHidden) {
        hiddenHeader(true);
      } else {
        hiddenHeader(false);
      }
    }

    if (prevProps.favourites !== this.props.favourites) {
      this.getMissingFavouriteObjects();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  getMissingFavouriteObjects() {
    const { favourites, favouriteObjects, dispatch } = this.props;
    const ids = favouriteObjects.keySeq().toSet();
    const favs = favourites.keySeq().toSet();
    if (!favs.isSubset(ids)) {
      setTimeout(() => {
        dispatch(loadFavouriteObjects(favs.subtract(ids).takeLast(6)));
      }, 2000);
    }
  }

  handleResize = () => {
    const { objMediaFull, headerHidden, dispatch, browser } = this.props;
    if (!objMediaFull && browser.greaterThan.medium) {
      if (headerHidden) {
        dispatch(toggleHeader(false));
      }
    }
  };

  handleScroll = () => {
    const { objMediaFull, browser } = this.props;

    if (!objMediaFull && browser.lessThan.large) {
      this.hideHeader();
    }
  };

  hideHeader = () => {
    const { dispatch, headerHidden } = this.props;
    const threshold = 300;

    const winScroll = window.scrollY;

    if (this.windowScroll < winScroll && winScroll > threshold) {
      if (!headerHidden) dispatch(toggleHeader(true));
    } else if (headerHidden) dispatch(toggleHeader(false));

    this.windowScroll = winScroll;
  };

  openMobMenu = () => {
    const { dispatch } = this.props;
    const { mobMenuOpened } = this.state;

    if (!mobMenuOpened) {
      dispatch(setSearchModalOpened(false));
      this.setState({
        mobMenuOpened: true,
      });

      ReactGA.event({
        category: GA_HEADER_MENU,
        action: 'open_menu',
      });
    }
  };

  closeMobMenu = () => {
    const { mobMenuOpened } = this.state;

    if (mobMenuOpened) {
      this.setState({ mobMenuClosing: true });
      setTimeout(() => {
        this.setState({
          mobMenuOpened: false,
          mobMenuClosing: false,
        });
      }, 500); // Mobile menu closing duration for animation
    }
  };

  signIn = () => {
    const { dispatch } = this.props;
    this.closeMobMenu();
    dispatch(openModal(ModalName.SignIn));
  };

  signOut = () => {
    const { dispatch } = this.props;
    dispatch(signOut());
    this.closeMobMenu();
  };

  renderLanguageSelect = () => {
    const { t, language, renderLanguageLink } = this.props;
    return (
      <LanguageSwitcher t={t} language={language} renderLink={renderLanguageLink} options={this.languageOptions} />
    );
  };

  render() {
    const {
      dispatch,
      browser,
      headerHidden,
      t,
      routes,
      user,
      favourites,
      favouriteObjects,
      savedSearches,
      language,
      branded,
      changeLanguage,
      topRef,
    } = this.props;
    const { mobMenuOpened, mobMenuClosing } = this.state;

    const getRecentFavourites = () => {
      return favourites
        .keySeq()
        .map((key) => favouriteObjects.get(key))
        .filter((o) => o)
        .reverse()
        .take(3)
        .toArray();
    };

    const recentFavourites = getRecentFavourites();

    const headerStyle = classNames('header', {
      'header--hidden': headerHidden,
      'header--branded': branded,
    });

    const gaMenuEvent = (action: string) => {
      ReactGA.event({
        category: GA_HEADER_MENU,
        action,
      });
    };

    return (
      <AppContext.Consumer>
        {({ Link }) => (
          <>
            <header ref={topRef} className={headerStyle}>
              <div className="header__logo">
                <Link href={routes.root} onClick={() => gaMenuEvent('click_logo')}>
                  <Image src={PORTAL_EE ? logoEE : logoLV} alt="City24 logo" width="155" height="30" />
                </Link>
              </div>
              {browser.greaterThan.medium ? (
                <>
                  <nav className="header__nav header__nav--main">
                    <Link className="header__item" href={routes.newProjects} onClick={() => gaMenuEvent('click_logo')}>
                      {t('header.newProjects')}
                    </Link>
                    <MenuSelect t={t} title={t('header.agentFinder')}>
                      <MenuSelectContext.Consumer>
                        {({ closeDropdown }) => (
                          <ActionList>
                            <ActionList.Item to={routes.brokerSearch} onClick={closeDropdown}>
                              {t('header.agentFinder.findBrokers')}
                            </ActionList.Item>
                            <ActionList.Item to={routes.companySearch} onClick={closeDropdown}>
                              {t('header.agentFinder.findCompanies')}
                            </ActionList.Item>
                            <ActionList.Item to={routes.brokerRequest} onClick={closeDropdown}>
                              {t('header.agentFinder.sendRequestToAllBrokers')}
                            </ActionList.Item>
                            {PORTAL_EE && (
                              <ActionList.Item
                                to={generatePath(routes.starOfTheYear)}
                                isNextJSPage
                                onClick={closeDropdown}
                              >
                                {t('header.agentFinder.starOfTheYear')}
                              </ActionList.Item>
                            )}
                          </ActionList>
                        )}
                      </MenuSelectContext.Consumer>
                    </MenuSelect>
                    {/* <MenuSelect title={t('header.more')} links={moreLinks} t={t} /> */}
                    <Link className="header__item" href={generatePath(routes.news)}>
                      {t('header.news')}
                    </Link>
                    {PORTAL_EE && (
                      <Link className="header__item" href={routes.financeCenter}>
                        {t('header.homeLoan')}
                      </Link>
                    )}
                  </nav>
                  <nav className="header__nav header__nav--alt">
                    {recentFavourites.length > 0 ? (
                      <MenuSelect t={t} title={t('header.favourites')} icon="heart-o">
                        <h5 className="menu-select__title">{t('header.recentFavourites')}</h5>
                        {recentFavourites.map((fav) => {
                          if (fav) {
                            return (
                              <SimpleObject
                                key={fav.get('friendly_id')}
                                t={t}
                                object={fav}
                                routes={routes}
                                layout="list"
                                imageFormat="960x640"
                                hideFavourite
                                hideMap
                                minimal
                              />
                            );
                          }
                          return null;
                        })}
                        <div className="menu-select__footer">
                          <LinkButton btnType="primary" extended href={routes.favourites}>
                            {t('header.viewAllFavourites')}
                          </LinkButton>
                        </div>
                      </MenuSelect>
                    ) : (
                      <Link href={routes.favourites} className="header__item">
                        <>
                          <Icon name="heart-o" className="icon--fw" />
                          {t('header.favourites')}
                        </>
                      </Link>
                    )}
                    <MenuSelect t={t} title={t('header.mySearches')} className="header__my-searches" icon="my-searches">
                      <QuickSearch t={t} routes={routes} />
                      <div className="menu-select__footer">
                        <LinkButton btnType="primary" extended href={routes.mySearches}>
                          {t('header.viewAllMySearches')}
                        </LinkButton>
                      </div>
                    </MenuSelect>
                    {this.renderLanguageSelect()}
                    {user.signedIn ? (
                      <MenuSelect
                        title={
                          <>
                            <Icon name="user" className="icon--fw" /> {getUserFullName(user)}
                          </>
                        }
                      >
                        <ActionList>
                          <ActionList.Item icon="house" to={routes.myObjects}>
                            {t('common.myObjects')}
                          </ActionList.Item>
                          <ActionList.Item icon="receipt" to={routes.invoices}>
                            {t('common.invoices')}
                          </ActionList.Item>
                          <ActionList.Item icon="settings" to={routes.settings}>
                            {t('common.settings')}
                          </ActionList.Item>
                          <ActionList.Item icon="sign-out" onClick={this.signOut}>
                            {t('common.signOut')}
                          </ActionList.Item>
                          {user.roles.has(ROLE_CUSTOMER_SUPPORT) && (
                            <ActionList.Item icon="meeting-room" to={routes.adminTools}>
                              {t('common.adminTools')}
                            </ActionList.Item>
                          )}
                        </ActionList>
                      </MenuSelect>
                    ) : (
                      <button
                        type="button"
                        className="header__item"
                        onClick={() => dispatch(openModal(ModalName.SignIn))}
                      >
                        {t('common.headerSignIn')}
                      </button>
                    )}
                  </nav>
                  <LinkButton
                    href={generatePath(routes.myObject, { id: 'new' })}
                    className="header__btn header__place-ad"
                    btnType="secondary"
                    bgColor="light"
                    size="sm"
                  >
                    {t('common.placeAd')}
                  </LinkButton>
                </>
              ) : (
                <div className="header__nav--mob">
                  <Link
                    href={generatePath(routes.myObject, { id: 'new' })}
                    className="header__item header__place-ad-link"
                  >
                    <Icon name="plus" className="icon--fw" />
                    <span>{t('common.placeAdShort')}</span>
                  </Link>
                  <Link
                    href={routes.favourites}
                    className="header__item"
                    onClick={() => gaMenuEvent('click_favorites')}
                  >
                    <Icon name="heart-o" className="icon--fw" />
                  </Link>
                  <button
                    type="button"
                    className="header__item"
                    onClick={mobMenuOpened ? this.closeMobMenu : this.openMobMenu}
                  >
                    <span className="icon icon-menu icon--fw" />
                  </button>
                </div>
              )}
            </header>
            <MobNav
              t={t}
              isOpen={mobMenuOpened}
              isClosing={mobMenuClosing}
              user={user}
              favourites={favourites}
              savedSearches={savedSearches}
              closeMobMenu={this.closeMobMenu}
              signIn={this.signIn}
              signOut={this.signOut}
              language={language}
              changeLanguage={changeLanguage}
              langOpts={this.languageOptions}
              routes={routes}
            />
            <AuthModals t={t} />
          </>
        )}
      </AppContext.Consumer>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    user: getUser(state),
    favourites: getFavourites(state),
    favouriteObjects: getFavouriteObjects(state),
    savedSearches: getSavedSearches(state),
    searchModalOpened: getSearchModalOpened(state),
    hidden: getHeaderHidden(state),
    browser: getBrowser(state),
    filterCount: getFilterCount(state),
    language: getLanguage(state),
    headerHidden: getHeaderHidden(state),
    objMediaFull: getObjMediaFull(state),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BaseHeader);
