import { init as apiInit, initAuth as apiInitAuth } from '@city24/common/api/city24';
import baseConfig, { ApiConfig as BaseApiConfig, bindError500Count } from '@city24/common/api/city24/config';
import { getToken, setToken } from '@city24/common/api/city24/tokens';
import { defaultLocale } from '@city24/common/enums/Language';

export interface ApiConfig extends BaseApiConfig {
  locale: string;
  setForceReload: () => void;
  debugApiCalls: boolean;
  requestStack: { endpoint: string; status: number; headers: string[]; error?: string }[];
}

apiInit(process.env.NEXT_PUBLIC_CITY24_API_URL, PORTAL);
apiInitAuth(getToken, setToken);

// Extending common API config
const config = baseConfig as ApiConfig;

config.locale = defaultLocale[process.env.NEXT_PUBLIC_DEFAULT_LOCALE];
config.setForceReload = () => {};
config.debugApiCalls = DEBUG_API;
config.requestStack = [];

export function getLocale(): string {
  return config.locale;
}

export function changeLocale(locale: string): void {
  config.locale = locale.replace('-', '_');
}

changeLocale(config.locale);

export { bindError500Count };

export function bindForceReload(action: () => void): any {
  config.setForceReload = action;
}

export default config;
