import React, { useContext } from 'react';

import AppContext from '@/components/appContext';
import { LinkProps } from '@/components/link/LinkInterface';
import Anchor from '@/components/link/Anchor';

interface Props {
  children: React.ReactNode;
  to: LinkProps['href'];
  onClick: LinkProps['onClick'];
  external?: boolean;
  visuallyHidden?: boolean;
  sameTab?: boolean;
}

function FooterSectionItem({ children, to, onClick, external = false, sameTab, visuallyHidden = false }: Props) {
  const { Link } = useContext(AppContext);
  return (
    <li className={visuallyHidden ? 'hidden' : ''}>
      {external ? (
        <Anchor href={to as string} className="footer__link" onClick={onClick} sameTab={sameTab}>
          {children}
        </Anchor>
      ) : (
        <Link href={to} className="footer__link" onClick={onClick}>
          {children}
        </Link>
      )}
    </li>
  );
}

export default FooterSectionItem;
