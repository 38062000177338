// static links for SEO footer section
export type seoFooterSectionTabs = {
  name: string;
  value: string;
  sections: {
    name: string;
    link?: string; // section title link
    links: {
      to: string;
      text: string;
    }[];
  }[];
};

type seoFooterSections = {
  name: string;
  link?: string; // section title link
  tabs: seoFooterSectionTabs[];
}[];

export const SEO_FOOTER_MAX_LINKS = 50; // temporary, so all tokens are visible // 5

export const SEO_FOOTER_SECTIONS: seoFooterSections = PORTAL_EE
  ? [
      {
        name: 'seoLinks.section.re.1',
        link: '/',
        tabs: [
          {
            value: 'sale',
            name: 'seoLinks.tab.re.sale.1',
            sections: [
              {
                name: 'seoLinks.apartments.sale.1',
                link: '/real-estate-search/apartments-for-sale',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/rooms=1',
                    text: 'seoLinks.apartments.sale.1room.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/rooms=2',
                    text: 'seoLinks.apartments.sale.2room.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/rooms=3',
                    text: 'seoLinks.apartments.sale.3room.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/rooms=4,5+',
                    text: 'seoLinks.apartments.sale.4room.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/private-user',
                    text: 'seoLinks.apartments.sale.fromOwner.1',
                  },
                ],
              },
              {
                name: 'seoLinks.houses.sale.1',
                link: '/real-estate-search/houses-for-sale',
                links: [
                  { to: '/real-estate-search/houses-for-sale/size=na-100', text: 'seoLinks.houses.sale.100.1' },
                  { to: '/real-estate-search/houses-for-sale/size=100-150', text: 'seoLinks.houses.sale.150.1' },
                  { to: '/real-estate-search/houses-for-sale/size=150-200', text: 'seoLinks.houses.sale.200.1' },
                  { to: '/real-estate-search/houses-for-sale/size=200-na', text: 'seoLinks.houses.sale.200+.1' },
                  { to: '/real-estate-search/houses-for-sale/private-user', text: 'seoLinks.houses.sale.fromOwner.1' },
                ],
              },
              {
                name: 'seoLinks.commercials.sale.1',
                link: '/real-estate-search/commercials-for-sale',
                links: [
                  { to: '/real-estate-search/commercials-for-sale/purpose=1', text: 'seoLinks.commercials.sale.1.1' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=2', text: 'seoLinks.commercials.sale.2.1' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=4', text: 'seoLinks.commercials.sale.4.1' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=5', text: 'seoLinks.commercials.sale.5.1' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=6', text: 'seoLinks.commercials.sale.6.1' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=7', text: 'seoLinks.commercials.sale.7.1' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=8', text: 'seoLinks.commercials.sale.8.1' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=9', text: 'seoLinks.commercials.sale.9.1' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=10', text: 'seoLinks.commercials.sale.10.1' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=11', text: 'seoLinks.commercials.sale.11.1' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=12', text: 'seoLinks.commercials.sale.12.1' },
                ],
              },
              {
                name: 'seoLinks.land.sale.1',
                link: '/real-estate-search/land-lots-for-sale',
                links: [
                  { to: '/real-estate-search/land-lots-for-sale/purpose=1', text: 'seoLinks.land.sale.1.1' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=2', text: 'seoLinks.land.sale.2.1' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=3', text: 'seoLinks.land.sale.3.1' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=4', text: 'seoLinks.land.sale.4.1' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=6', text: 'seoLinks.land.sale.6.1' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=7', text: 'seoLinks.land.sale.7.1' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=8', text: 'seoLinks.land.sale.8.1' },
                ],
              },
              {
                name: '',
                links: [
                  { to: '/real-estate-search/house-shares-for-sale', text: 'seoLinks.houseShare.sale.1' },
                  { to: '/real-estate-search/summer-houses-for-sale', text: 'seoLinks.cottage.sale.1' },
                  { to: '/real-estate-search/garages-for-sale', text: 'seoLinks.garage.sale.1' },
                  { to: '/real-estate-search/modular-house-for-sale', text: 'seoLinks.modularHouse.sale.1' },
                ],
              },
            ],
          },
          {
            value: 'rent',
            name: 'seoLinks.tab.re.rent.1',
            sections: [
              {
                name: 'seoLinks.apartments.rent.1',
                link: '/real-estate-search/apartments-for-rent',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/rooms=1',
                    text: 'seoLinks.apartments.rent.1room.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/rooms=2',
                    text: 'seoLinks.apartments.rent.2room.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/rooms=3',
                    text: 'seoLinks.apartments.rent.3room.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/rooms=4,5+',
                    text: 'seoLinks.apartments.rent.4room.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/private-user',
                    text: 'seoLinks.apartments.rent.fromOwner.1',
                  },
                ],
              },
              {
                name: 'seoLinks.houses.rent.1',
                link: '/real-estate-search/houses-for-rent',
                links: [
                  { to: '/real-estate-search/houses-for-rent/size=na-100', text: 'seoLinks.houses.rent.100.1' },
                  { to: '/real-estate-search/houses-for-rent/size=100-150', text: 'seoLinks.houses.rent.150.1' },
                  { to: '/real-estate-search/houses-for-rent/size=150-200', text: 'seoLinks.houses.rent.200.1' },
                  { to: '/real-estate-search/houses-for-rent/size=200-na', text: 'seoLinks.houses.rent.200+.1' },
                  { to: '/real-estate-search/houses-for-rent/private-user', text: 'seoLinks.houses.rent.fromOwner.1' },
                ],
              },
              {
                name: 'seoLinks.commercials.rent.1',
                link: '/real-estate-search/commercials-for-rent',
                links: [
                  { to: '/real-estate-search/commercials-for-rent/purpose=1', text: 'seoLinks.commercials.rent.1.1' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=2', text: 'seoLinks.commercials.rent.2.1' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=4', text: 'seoLinks.commercials.rent.4.1' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=5', text: 'seoLinks.commercials.rent.5.1' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=6', text: 'seoLinks.commercials.rent.6.1' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=7', text: 'seoLinks.commercials.rent.7.1' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=8', text: 'seoLinks.commercials.rent.8.1' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=9', text: 'seoLinks.commercials.rent.9.1' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=10', text: 'seoLinks.commercials.rent.10.1' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=11', text: 'seoLinks.commercials.rent.11.1' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=12', text: 'seoLinks.commercials.rent.12.1' },
                ],
              },
              {
                name: 'seoLinks.land.rent.1',
                link: '/real-estate-search/land-lots-for-rent',
                links: [
                  { to: '/real-estate-search/land-lots-for-rent/purpose=1', text: 'seoLinks.land.rent.1.1' },
                  { to: '/real-estate-search/land-lots-for-rent/purpose=2', text: 'seoLinks.land.rent.2.1' },
                  { to: '/real-estate-search/land-lots-for-rent/purpose=3', text: 'seoLinks.land.rent.3.1' },
                  { to: '/real-estate-search/land-lots-for-rent/purpose=6', text: 'seoLinks.land.rent.6.1' },
                  { to: '/real-estate-search/land-lots-for-rent/purpose=7', text: 'seoLinks.land.rent.7.1' },
                ],
              },
              {
                name: '',
                links: [
                  { to: '/real-estate-search/house-shares-for-rent', text: 'seoLinks.houseShare.rent.1' },
                  { to: '/real-estate-search/summer-houses-for-rent', text: 'seoLinks.cottage.rent.1' },
                  { to: '/real-estate-search/garages-for-rent', text: 'seoLinks.garage.rent.1' },
                ],
              },
            ],
          },
          {
            value: 'projects',
            name: 'seoLinks.tab.projects.1',
            sections: [
              {
                name: 'seoLinks.projects.1',
                link: '/new-projects',
                links: [
                  {
                    to: '/new-projects',
                    text: 'seoLinks.projects.sale.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/t=apartments',
                    text: 'seoLinks.projects.apartments.sale.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/t=house-shares,houses',
                    text: 'seoLinks.projects.houses.sale.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/t=commercials',
                    text: 'seoLinks.projects.commercials.sale.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/open-house',
                    text: 'seoLinks.projects.openHouse.sale.1',
                  },
                ],
              },
              {
                name: 'seoLinks.projects.tallinn.1',
                link: '/real-estate-search/new-projects-for-sale/tallinn/id=181-parish',
                links: [
                  {
                    to: '/real-estate-search/new-projects-for-sale/tallinn-haabersti-linnaosa/id=540-city',
                    text: 'seoLinks.np.haabersti.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/tallinn-kesklinna-linnaosa/id=1240-city',
                    text: 'seoLinks.np.kesklinna.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/tallinn-kristiine-linnaosa/id=1535-city',
                    text: 'seoLinks.np.kristiine.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/tallinn-lasnamae-linnaosa/id=1897-city',
                    text: 'seoLinks.np.lasnamae.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/tallinn-mustamae-linnaosa/id=2413-city',
                    text: 'seoLinks.np.mustamae.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/tallinn-nomme-linnaosa/id=2670-city',
                    text: 'seoLinks.np.nomme.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/tallinn-pirita-linnaosa/id=3039-city',
                    text: 'seoLinks.np.pirita.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/tallinn-pohja-tallinna-linnaosa/id=3166-city',
                    text: 'seoLinks.np.pohjaTallinna.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/search-term=Kalamaja',
                    text: 'seoLinks.np.kalamaja.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/joelahtme-vald/id=47-parish',
                    text: 'seoLinks.np.joelahtme.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/harku-vald/id=38-parish',
                    text: 'seoLinks.np.harku.1',
                  },
                  { to: '/real-estate-search/new-projects-for-sale/rae-vald/id=149-parish', text: 'seoLinks.np.rae.1' },
                  {
                    to: '/real-estate-search/new-projects-for-sale/saku-vald/id=210332-parish',
                    text: 'seoLinks.np.saku.1',
                  },
                  { to: '/real-estate-search/new-projects-for-sale/search-term=saue', text: 'seoLinks.np.saue.1' },
                  {
                    to: '/real-estate-search/new-projects-for-sale/viimsi-vald/id=212-parish',
                    text: 'seoLinks.np.viimsi.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/keila-linn/id=63-parish',
                    text: 'seoLinks.np.keila.1',
                  },
                ],
              },
              {
                name: '',
                links: [
                  {
                    to: '/real-estate-search/new-projects-for-sale/tartu-maakond/id=20269-county',
                    text: 'seoLinks.np.tartu.1',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/parnu-maakond/id=20267-county',
                    text: 'seoLinks.np.parnu.1',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'seoLinks.section.regions.1',
        link: '/',
        tabs: [
          {
            value: 'sale',
            name: 'seoLinks.tab.regions.apartments.sale.1',
            sections: [
              {
                name: 'seoLinks.apartments.tallinn.sale.1',
                link: '/real-estate-search/apartments-for-sale/tallinn/id=181-parish',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/tallinn-haabersti-linnaosa/id=540-city',
                    text: 'seoLinks.apartments.haabersti.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tallinn-kesklinna-linnaosa/id=1240-city',
                    text: 'seoLinks.apartments.kesklinna.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tallinn-kadriorg/id=64717-city',
                    text: 'seoLinks.apartments.kadriorg.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tallinn-kristiine-linnaosa/id=1535-city',
                    text: 'seoLinks.apartments.kristiine.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tallinn-lasnamae-linnaosa/id=1897-city',
                    text: 'seoLinks.apartments.lasnamae.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tallinn-mustamae-linnaosa/id=2413-city',
                    text: 'seoLinks.apartments.mustamae.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tallinn-nomme-linnaosa/id=2670-city',
                    text: 'seoLinks.apartments.nomme.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tallinn-pirita-linnaosa/id=3039-city',
                    text: 'seoLinks.apartments.pirita.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tallinn-pohja-tallinna-linnaosa/id=3166-city',
                    text: 'seoLinks.apartments.pohjaTallinna.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tallinn-vanalinn/id=64718-city',
                    text: 'seoLinks.apartments.vanalinn.1',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.tartu.sale.1',
                link: '/real-estate-search/apartments-for-sale/tartu-linn/id=20277-parish',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-annelinn/id=64746-district',
                    text: 'seoLinks.apartments.annelinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-ihaste/id=64747-district',
                    text: 'seoLinks.apartments.ihaste.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-jaamamoisa/id=64748-district',
                    text: 'seoLinks.apartments.jaamamoisa.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-kesklinn/id=64750-district',
                    text: 'seoLinks.apartments.kesklinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-karlova/id=64749-district',
                    text: 'seoLinks.apartments.karlova.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-maarjamoisa/id=64751-district',
                    text: 'seoLinks.apartments.maarjamoisa.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-raadi-kruusamae/id=64752-district',
                    text: 'seoLinks.apartments.raadiKruusamae.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-ropka/id=64754-district',
                    text: 'seoLinks.apartments.ropka.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-ropka-toostuse/id=64755-district',
                    text: 'seoLinks.apartments.ropkaToostuse.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-ranilinn/id=64753-district',
                    text: 'seoLinks.apartments.ranilinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-supilinn/id=64756-district',
                    text: 'seoLinks.apartments.supilinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-tammelinn/id=64758-district',
                    text: 'seoLinks.apartments.tammelinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-tahtvere/id=64757-district',
                    text: 'seoLinks.apartments.tahtvere.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-vaksali/id=64760-district',
                    text: 'seoLinks.apartments.vaksali.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-veeriku/id=64763-district',
                    text: 'seoLinks.apartments.veeriku.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-linn-ulejoe/id=64759-district',
                    text: 'seoLinks.apartments.ulejoe.1',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.parnu.sale.1',
                link: '/real-estate-search/apartments-for-sale/parnu-linn/id=20276-parish',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-linn-eeslinn/id=64734-district',
                    text: 'seoLinks.apartments.eeslinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-linn-kesklinn/id=64735-district',
                    text: 'seoLinks.apartments.kesklinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-linn-mai/id=64737-district',
                    text: 'seoLinks.apartments.mai.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-linn-papiniidu/id=64745-district',
                    text: 'seoLinks.apartments.papiniidu.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-linn-raekula/id=64739-district',
                    text: 'seoLinks.apartments.raekula.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-linn-rannarajoon/id=64740-district',
                    text: 'seoLinks.apartments.rannarajoon.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-linn-raama/id=64738-district',
                    text: 'seoLinks.apartments.raama.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-linn-tammiste/id=64741-district',
                    text: 'seoLinks.apartments.tammiste.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-linn-vana-parnu/id=64743-district',
                    text: 'seoLinks.apartments.vanaParnu.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-linn-ulejoe/id=64742-district',
                    text: 'seoLinks.apartments.ulejoe.1',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.narva.sale.1',
                link: '/real-estate-search/apartments-for-sale/narva-linn/id=117-parish',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/narva-linn-joaorg/id=64720-city',
                    text: 'seoLinks.apartments.joaorg.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/narva-linn-kalevi/id=64721-city',
                    text: 'seoLinks.apartments.kalevi.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/narva-linn-kerese/id=64722-city',
                    text: 'seoLinks.apartments.kerese.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/narva-linn-kesklinn/id=64723-city',
                    text: 'seoLinks.apartments.kesklinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/narva-linn-kreenholmi/id=64724-city',
                    text: 'seoLinks.apartments.kreenholmi.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/narva-linn-paemurru/id=64726-city',
                    text: 'seoLinks.apartments.paemurru.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/narva-linn-pahklimae/id=64727-city',
                    text: 'seoLinks.apartments.pahklimae.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/narva-linn-soldino/id=64729-city',
                    text: 'seoLinks.apartments.soldino.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/narva-linn-vanalinn/id=64731-city',
                    text: 'seoLinks.apartments.vanalinn.1',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.regions.sale.1',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/harju-maakond/id=1-county',
                    text: 'seoLinks.apartments.harju.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/tartu-maakond/id=20269-county',
                    text: 'seoLinks.apartments.tartumaa.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/parnu-maakond/id=20267-county',
                    text: 'seoLinks.apartments.parnumaa.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/laane-maakond/id=20264-county',
                    text: 'seoLinks.apartments.laane.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/ida-viru-maakond/id=20261-county',
                    text: 'seoLinks.apartments.idaViru.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/laane-viru-maakond/id=20265-county',
                    text: 'seoLinks.apartments.laaneViru.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jogeva-maakond/id=20262-county',
                    text: 'seoLinks.apartments.jogeva.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jarva-maakond/id=20263-county',
                    text: 'seoLinks.apartments.jarva.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/polva-maakond/id=20266-county',
                    text: 'seoLinks.apartments.polva.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/rapla-maakond/id=20268-county',
                    text: 'seoLinks.apartments.rapla.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/viljandi-maakond/id=14-county',
                    text: 'seoLinks.apartments.viljandi.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/voru-maakond/id=20271-county',
                    text: 'seoLinks.apartments.voru.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/valga-maakond/id=20270-county',
                    text: 'seoLinks.apartments.valga.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/saare-maakond/id=11-county',
                    text: 'seoLinks.apartments.saare.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/hiiu-maakond/id=2-county',
                    text: 'seoLinks.apartments.hiiu.1',
                  },
                ],
              },
            ],
          },
          {
            value: 'rent',
            name: 'seoLinks.tab.regions.apartments.rent.1',
            sections: [
              {
                name: 'seoLinks.apartments.tallinn.rent.1',
                link: '/real-estate-search/apartments-for-rent/tallinn/id=181-parish',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/tallinn-haabersti-linnaosa/id=540-city',
                    text: 'seoLinks.apartments.haabersti.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tallinn-kesklinna-linnaosa/id=1240-city',
                    text: 'seoLinks.apartments.kesklinna.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tallinn-kadriorg/id=64717-city',
                    text: 'seoLinks.apartments.kadriorg.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tallinn-kristiine-linnaosa/id=1535-city',
                    text: 'seoLinks.apartments.kristiine.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tallinn-lasnamae-linnaosa/id=1897-city',
                    text: 'seoLinks.apartments.lasnamae.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tallinn-mustamae-linnaosa/id=2413-city',
                    text: 'seoLinks.apartments.mustamae.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tallinn-nomme-linnaosa/id=2670-city',
                    text: 'seoLinks.apartments.nomme.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tallinn-pirita-linnaosa/id=3039-city',
                    text: 'seoLinks.apartments.pirita.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tallinn-pohja-tallinna-linnaosa/id=3166-city',
                    text: 'seoLinks.apartments.pohjaTallinna.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tallinn-vanalinn/id=64718-city',
                    text: 'seoLinks.apartments.vanalinn.1',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.tartu.rent.1',
                link: '/real-estate-search/apartments-for-rent/tartu-linn/id=20277-parish',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-annelinn/id=64746-district',
                    text: 'seoLinks.apartments.annelinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-ihaste/id=64747-district',
                    text: 'seoLinks.apartments.ihaste.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-jaamamoisa/id=64748-district',
                    text: 'seoLinks.apartments.jaamamoisa.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-kesklinn/id=64750-district',
                    text: 'seoLinks.apartments.kesklinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-karlova/id=64749-district',
                    text: 'seoLinks.apartments.karlova.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-maarjamoisa/id=64751-district',
                    text: 'seoLinks.apartments.maarjamoisa.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-raadi-kruusamae/id=64752-district',
                    text: 'seoLinks.apartments.raadiKruusamae.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-ropka/id=64754-district',
                    text: 'seoLinks.apartments.ropka.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-ropka-toostuse/id=64755-district',
                    text: 'seoLinks.apartments.ropkaToostuse.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-ranilinn/id=64753-district',
                    text: 'seoLinks.apartments.ranilinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-supilinn/id=64756-district',
                    text: 'seoLinks.apartments.supilinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-tammelinn/id=64758-district',
                    text: 'seoLinks.apartments.tammelinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-tahtvere/id=64757-district',
                    text: 'seoLinks.apartments.tahtvere.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-vaksali/id=64760-district',
                    text: 'seoLinks.apartments.vaksali.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-veeriku/id=64763-district',
                    text: 'seoLinks.apartments.veeriku.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-linn-ulejoe/id=64759-district',
                    text: 'seoLinks.apartments.ulejoe.1',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.parnu.rent.1',
                link: '/real-estate-search/apartments-for-rent/parnu-linn/id=20276-parish',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-linn-eeslinn/id=64734-district',
                    text: 'seoLinks.apartments.eeslinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-linn-kesklinn/id=64735-district',
                    text: 'seoLinks.apartments.kesklinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-linn-mai/id=64737-district',
                    text: 'seoLinks.apartments.mai.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-linn-papiniidu/id=64745-district',
                    text: 'seoLinks.apartments.papiniidu.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-linn-raekula/id=64739-district',
                    text: 'seoLinks.apartments.raekula.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-linn-rannarajoon/id=64740-district',
                    text: 'seoLinks.apartments.rannarajoon.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-linn-raama/id=64738-district',
                    text: 'seoLinks.apartments.raama.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-linn-tammiste/id=64741-district',
                    text: 'seoLinks.apartments.tammiste.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-linn-vana-parnu/id=64743-district',
                    text: 'seoLinks.apartments.vanaParnu.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-linn-ulejoe/id=64742-district',
                    text: 'seoLinks.apartments.ulejoe.1',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.narva.rent.1',
                link: '/real-estate-search/apartments-for-rent/narva-linn/id=117-parish',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/narva-linn-joaorg/id=64720-city',
                    text: 'seoLinks.apartments.joaorg.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/narva-linn-kalevi/id=64721-city',
                    text: 'seoLinks.apartments.kalevi.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/narva-linn-kerese/id=64722-city',
                    text: 'seoLinks.apartments.kerese.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/narva-linn-kesklinn/id=64723-city',
                    text: 'seoLinks.apartments.kesklinn.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/narva-linn-kreenholmi/id=64724-city',
                    text: 'seoLinks.apartments.kreenholmi.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/narva-linn-paemurru/id=64726-city',
                    text: 'seoLinks.apartments.paemurru.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/narva-linn-pahklimae/id=64727-city',
                    text: 'seoLinks.apartments.pahklimae.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/narva-linn-soldino/id=64729-city',
                    text: 'seoLinks.apartments.soldino.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/narva-linn-vanalinn/id=64731-city',
                    text: 'seoLinks.apartments.vanalinn.1',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.regions.rent.1',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/harju-maakond/id=1-county',
                    text: 'seoLinks.apartments.harju.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/tartu-maakond/id=20269-county',
                    text: 'seoLinks.apartments.tartumaa.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/parnu-maakond/id=20267-county',
                    text: 'seoLinks.apartments.parnumaa.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/laane-maakond/id=20264-county',
                    text: 'seoLinks.apartments.laane.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/ida-viru-maakond/id=20261-county',
                    text: 'seoLinks.apartments.idaViru.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/laane-viru-maakond/id=20265-county',
                    text: 'seoLinks.apartments.laaneViru.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jogeva-maakond/id=20262-county',
                    text: 'seoLinks.apartments.jogeva.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jarva-maakond/id=20263-county',
                    text: 'seoLinks.apartments.jarva.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/polva-maakond/id=20266-county',
                    text: 'seoLinks.apartments.polva.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/rapla-maakond/id=20268-county',
                    text: 'seoLinks.apartments.rapla.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/viljandi-maakond/id=14-county',
                    text: 'seoLinks.apartments.viljandi.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/voru-maakond/id=20271-county',
                    text: 'seoLinks.apartments.voru.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/valga-maakond/id=20270-county',
                    text: 'seoLinks.apartments.valga.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/saare-maakond/id=11-county',
                    text: 'seoLinks.apartments.saare.1',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/hiiu-maakond/id=2-county',
                    text: 'seoLinks.apartments.hiiu.1',
                  },
                ],
              },
            ],
          },
          {
            value: 'houseSale',
            name: 'seoLinks.tab.regions.houses.sale.1',
            sections: [
              {
                name: 'seoLinks.houses.tallinn.sale.1',
                link: '/real-estate-search/houses-for-sale/tallinn/id=181-parish',
                links: [
                  {
                    to: '/real-estate-search/houses-for-sale/tallinn-haabersti-linnaosa/id=540-city',
                    text: 'seoLinks.apartments.haabersti.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tallinn-kesklinna-linnaosa/id=1240-city',
                    text: 'seoLinks.apartments.kesklinna.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tallinn-kadriorg/id=64717-city',
                    text: 'seoLinks.apartments.kadriorg.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tallinn-kristiine-linnaosa/id=1535-city',
                    text: 'seoLinks.apartments.kristiine.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tallinn-lasnamae-linnaosa/id=1897-city',
                    text: 'seoLinks.apartments.lasnamae.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tallinn-nomme-linnaosa/id=2670-city',
                    text: 'seoLinks.apartments.nomme.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tallinn-pirita-linnaosa/id=3039-city',
                    text: 'seoLinks.apartments.pirita.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tallinn-pohja-tallinna-linnaosa/id=3166-city',
                    text: 'seoLinks.apartments.pohjaTallinna.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tallinn-vanalinn/id=64718-city',
                    text: 'seoLinks.apartments.vanalinn.1',
                  },
                ],
              },
              {
                name: 'seoLinks.houses.tartu.sale.1',
                link: '/real-estate-search/houses-for-sale/tartu-linn/id=20277-parish',
                links: [
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-annelinn/id=64746-district',
                    text: 'seoLinks.apartments.annelinn.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-ihaste/id=64747-district',
                    text: 'seoLinks.apartments.ihaste.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-jaamamoisa/id=64748-district',
                    text: 'seoLinks.apartments.jaamamoisa.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-kesklinn/id=64750-district',
                    text: 'seoLinks.apartments.kesklinn.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-karlova/id=64749-district',
                    text: 'seoLinks.apartments.karlova.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-maarjamoisa/id=64751-district',
                    text: 'seoLinks.apartments.maarjamoisa.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-raadi-kruusamae/id=64752-district',
                    text: 'seoLinks.apartments.raadiKruusamae.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-ropka/id=64754-district',
                    text: 'seoLinks.apartments.ropka.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-ropka-toostuse/id=64755-district',
                    text: 'seoLinks.apartments.ropkaToostuse.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-ranilinn/id=64753-district',
                    text: 'seoLinks.apartments.ranilinn.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-supilinn/id=64756-district',
                    text: 'seoLinks.apartments.supilinn.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-tammelinn/id=64758-district',
                    text: 'seoLinks.apartments.tammelinn.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-tahtvere/id=64757-district',
                    text: 'seoLinks.apartments.tahtvere.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-vaksali/id=64760-district',
                    text: 'seoLinks.apartments.vaksali.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-veeriku/id=64763-district',
                    text: 'seoLinks.apartments.veeriku.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-linn-ulejoe/id=64759-district',
                    text: 'seoLinks.apartments.ulejoe.1',
                  },
                ],
              },
              {
                name: 'seoLinks.houses.parnu.sale.1',
                link: '/real-estate-search/houses-for-sale/parnu-linn/id=20276-parish',
                links: [
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-linn-eeslinn/id=64734-district',
                    text: 'seoLinks.apartments.eeslinn.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-linn-kesklinn/id=64735-district',
                    text: 'seoLinks.apartments.kesklinn.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-linn-mai/id=64737-district',
                    text: 'seoLinks.apartments.mai.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-linn-papiniidu/id=64745-district',
                    text: 'seoLinks.apartments.papiniidu.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-linn-raekula/id=64739-district',
                    text: 'seoLinks.apartments.raekula.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-linn-rannarajoon/id=64740-district',
                    text: 'seoLinks.apartments.rannarajoon.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-linn-raama/id=64738-district',
                    text: 'seoLinks.apartments.raama.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-linn-tammiste/id=64741-district',
                    text: 'seoLinks.apartments.tammiste.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-linn-vana-parnu/id=64743-district',
                    text: 'seoLinks.apartments.vanaParnu.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-linn-ulejoe/id=64742-district',
                    text: 'seoLinks.apartments.ulejoe.1',
                  },
                ],
              },
              {
                name: '',
                links: [
                  {
                    to: '/real-estate-search/houses-for-sale/narva-linn/id=117-parish',
                    text: 'seoLinks.houses.narva.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/narva-joesuu-linn/id=20274-parish',
                    text: 'seoLinks.houses.narvaJoesuu.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/haapsalu-linn/id=20272-parish',
                    text: 'seoLinks.houses.haapsalu.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/rakvere-linn/id=152-parish',
                    text: 'seoLinks.houses.rakvere.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/saaremaa-vald-kuressaare-linn/id=20402-city',
                    text: 'seoLinks.houses.kuressaare.1',
                  },
                ],
              },
              {
                name: 'seoLinks.houses.regions.sale.1',
                links: [
                  {
                    to: '/real-estate-search/houses-for-sale/harju-maakond/id=1-county',
                    text: 'seoLinks.apartments.harju.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/tartu-maakond/id=20269-county',
                    text: 'seoLinks.apartments.tartumaa.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/parnu-maakond/id=20267-county',
                    text: 'seoLinks.apartments.parnumaa.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/laane-maakond/id=20264-county',
                    text: 'seoLinks.apartments.laane.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/ida-viru-maakond/id=20261-county',
                    text: 'seoLinks.apartments.idaViru.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/laane-viru-maakond/id=20265-county',
                    text: 'seoLinks.apartments.laaneViru.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jogeva-maakond/id=20262-county',
                    text: 'seoLinks.apartments.jogeva.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jarva-maakond/id=20263-county',
                    text: 'seoLinks.apartments.jarva.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/polva-maakond/id=20266-county',
                    text: 'seoLinks.apartments.polva.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/rapla-maakond/id=20268-county',
                    text: 'seoLinks.apartments.rapla.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/viljandi-maakond/id=14-county',
                    text: 'seoLinks.apartments.viljandi.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/voru-maakond/id=20271-county',
                    text: 'seoLinks.apartments.voru.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/valga-maakond/id=20270-county',
                    text: 'seoLinks.apartments.valga.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/saare-maakond/id=11-county',
                    text: 'seoLinks.apartments.saare.1',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/hiiu-maakond/id=2-county',
                    text: 'seoLinks.apartments.hiiu.1',
                  },
                ],
              },
            ],
          },
          {
            value: 'landSale',
            name: 'seoLinks.tab.regions.land.sale.1',
            sections: [
              {
                name: 'seoLinks.land.cities.sale.1',
                link: '/real-estate-search/land-lots-for-sale',
                links: [
                  {
                    to: '/real-estate-search/land-lots-for-sale/tallinn/id=181-parish',
                    text: 'seoLinks.land.tallinn.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/tartu-linn/id=20411-city',
                    text: 'seoLinks.land.tartu.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/parnu-linn/id=20409-city',
                    text: 'seoLinks.land.parnu.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/narva-linn/id=117-parish',
                    text: 'seoLinks.land.narva.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/haapsalu-linn/id=20272-parish',
                    text: 'seoLinks.land.haapsalu.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/rakvere-vald/id=20293-parish',
                    text: 'seoLinks.land.rakvere.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/viljandi-maakond/id=14-county',
                    text: 'seoLinks.land.viljandi.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/saaremaa-vald-kuressaare-linn/id=20402-city',
                    text: 'seoLinks.land.saaremaa.1',
                  },
                  { to: '/real-estate-search/land-lots-for-sale/private-user', text: 'seoLinks.land.fromOwner.1' },
                ],
              },
              {
                name: 'seoLinks.land.regions.sale.1',
                link: '/real-estate-search/land-lots-for-sale',
                links: [
                  {
                    to: '/real-estate-search/land-lots-for-sale/harju-maakond/id=1-county',
                    text: 'seoLinks.apartments.harju.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/tartu-maakond/id=20269-county',
                    text: 'seoLinks.apartments.tartumaa.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/parnu-maakond/id=20267-county',
                    text: 'seoLinks.apartments.parnumaa.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/laane-maakond/id=20264-county',
                    text: 'seoLinks.apartments.laane.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/ida-viru-maakond/id=20261-county',
                    text: 'seoLinks.apartments.idaViru.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/laane-viru-maakond/id=20265-county',
                    text: 'seoLinks.apartments.laaneViru.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/jogeva-maakond/id=20262-county',
                    text: 'seoLinks.apartments.jogeva.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/jarva-maakond/id=20263-county',
                    text: 'seoLinks.apartments.jarva.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/polva-maakond/id=20266-county',
                    text: 'seoLinks.apartments.polva.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/rapla-maakond/id=20268-county',
                    text: 'seoLinks.apartments.rapla.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/viljandi-maakond/id=14-county',
                    text: 'seoLinks.apartments.viljandi.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/voru-maakond/id=20271-county',
                    text: 'seoLinks.apartments.voru.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/valga-maakond/id=20270-county',
                    text: 'seoLinks.apartments.valga.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/saare-maakond/id=11-county',
                    text: 'seoLinks.apartments.saare.1',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/hiiu-maakond/id=2-county',
                    text: 'seoLinks.apartments.hiiu.1',
                  },
                ],
              },
            ],
          },
          {
            value: 'commercialRent',
            name: 'seoLinks.tab.regions.commercial.rent.1',
            sections: [
              {
                name: 'seoLinks.commercial.tallinn.rent.1',
                link: '/real-estate-search/commercials-for-rent/tallinn/id=181-parish',
                links: [
                  {
                    to: '/real-estate-search/commercials-for-rent/tallinn/id=181-parish',
                    text: 'seoLinks.commercial.tallinn.all.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tallinn/purpose=2/id=181-parish',
                    text: 'seoLinks.commercial.tallinn.2.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tallinn/purpose=1/id=181-parish',
                    text: 'seoLinks.commercial.tallinn.1.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tallinn/purpose=4/id=181-parish',
                    text: 'seoLinks.commercial.tallinn.4.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tallinn/purpose=10/id=181-parish',
                    text: 'seoLinks.commercial.tallinn.10.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tallinn/purpose=7,8,9,5/id=181-parish',
                    text: 'seoLinks.commercial.tallinn.7.8.5.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tallinn/purpose=9/id=181-parish',
                    text: 'seoLinks.commercial.tallinn.9.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tallinn/condition=7/id=181-parish',
                    text: 'seoLinks.commercial.tallinn.c7.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tallinn/purpose=11/id=181-parish',
                    text: 'seoLinks.commercial.tallinn.11.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/harju-maakond/purpose=5,7,8,8,9/id=1-county',
                    text: 'seoLinks.commercial.harjumaa.rent.1',
                  },
                ],
              },
              {
                name: 'seoLinks.commercial.tartu.rent.1',
                link: '/real-estate-search/commercials-for-rent/tartu-linn/id=20277-parish',
                links: [
                  {
                    to: '/real-estate-search/commercials-for-rent/tartu-linn/id=20277-parish',
                    text: 'seoLinks.commercial.tartu.all.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tartu-linn/purpose=2/id=20277-parish',
                    text: 'seoLinks.commercial.tartu.2.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tartu-linn/purpose=1/id=20277-parish',
                    text: 'seoLinks.commercial.tartu.1.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tartu-linn/purpose=4/id=20277-parish',
                    text: 'seoLinks.commercial.tartu.4.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tartu-linn/purpose=5,7,8,8,9/id=20277-parish',
                    text: 'seoLinks.commercial.tartu.5.7.8.9.12.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tartu-linn/purpose=9/id=20277-parish',
                    text: 'seoLinks.commercial.tartu.9.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tartu-linn/condition=7/id=20277-parish',
                    text: 'seoLinks.commercial.tartu.7.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/tartu-maakond/purpose=5,7,8,8,9/id=20269-county',
                    text: 'seoLinks.commercial.tartumaa.rent.1',
                  },
                ],
              },
              {
                name: 'seoLinks.commercial.parnu.rent.1',
                link: '/real-estate-search/commercials-for-rent/parnu-linn/id=20276-parish',
                links: [
                  {
                    to: '/real-estate-search/commercials-for-rent/parnu-linn/id=20276-parish',
                    text: 'seoLinks.commercial.parnu.all.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/parnu-linn/purpose=2/id=20276-parish',
                    text: 'seoLinks.commercial.parnu.2.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/parnu-linn/purpose=1/id=20276-parish',
                    text: 'seoLinks.commercial.parnu.1.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/parnu-linn/purpose=4/id=20276-parish',
                    text: 'seoLinks.commercial.parnu.4.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/parnu-linn-parnu-linn/purpose=10/id=20409-city',
                    text: 'seoLinks.commercial.parnu.10.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/parnu-linn/purpose=5,7,8,8,9/id=20276-parish',
                    text: 'seoLinks.commercial.parnu.5.7.8.9.12.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/parnu-linn/condition=7/id=20276-parish',
                    text: 'seoLinks.commercial.parnu.7.1',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/parnu-maakond/purpose=5,7,8,8,9/id=20267-county',
                    text: 'seoLinks.commercial.parnumaa.rent.1',
                  },
                ],
              },
            ],
          },
        ],
      },
    ]
  : [
      {
        name: 'seoLinks.section.re.2',
        link: '/',
        tabs: [
          {
            value: 'sale',
            name: 'seoLinks.tab.re.sale.2',
            sections: [
              {
                name: 'seoLinks.apartments.sale.2',
                link: '/real-estate-search/apartments-for-sale',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/rooms=1',
                    text: 'seoLinks.apartments.sale.1room.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/rooms=2',
                    text: 'seoLinks.apartments.sale.2room.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/rooms=3',
                    text: 'seoLinks.apartments.sale.3room.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/rooms=4,5+',
                    text: 'seoLinks.apartments.sale.4room.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/private-user',
                    text: 'seoLinks.apartments.sale.fromOwner.2',
                  },
                ],
              },
              {
                name: 'seoLinks.houses.sale.2',
                link: '/real-estate-search/houses-for-sale',
                links: [
                  { to: '/real-estate-search/houses-for-sale/size=na-100', text: 'seoLinks.houses.sale.100.2' },
                  { to: '/real-estate-search/houses-for-sale/size=100-150', text: 'seoLinks.houses.sale.150.2' },
                  { to: '/real-estate-search/houses-for-sale/size=150-200', text: 'seoLinks.houses.sale.200.2' },
                  { to: '/real-estate-search/houses-for-sale/size=200-na', text: 'seoLinks.houses.sale.200+.2' },
                  { to: '/real-estate-search/houses-for-sale/private-user', text: 'seoLinks.houses.sale.fromOwner.2' },
                  { to: '/real-estate-search/modular-house-for-sale', text: 'seoLinks.modularHouse.sale.2' },
                ],
              },
              {
                name: 'seoLinks.commercials.sale.2',
                link: '/real-estate-search/commercials-for-sale',
                links: [
                  { to: '/real-estate-search/commercials-for-sale/purpose=2', text: 'seoLinks.commercials.sale.2.2' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=4', text: 'seoLinks.commercials.sale.4.2' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=8', text: 'seoLinks.commercials.sale.8.2' },
                  { to: '/real-estate-search/commercials-for-sale/purpose=11', text: 'seoLinks.commercials.sale.11.2' },
                  { to: '/real-estate-search/garages-for-sale', text: 'seoLinks.garage.sale.2' },
                ],
              },
              {
                name: 'seoLinks.land.sale.2',
                link: '/real-estate-search/land-lots-for-sale',
                links: [
                  { to: '/real-estate-search/land-lots-for-sale/purpose=1', text: 'seoLinks.land.sale.1.2' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=2', text: 'seoLinks.land.sale.2.2' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=3', text: 'seoLinks.land.sale.3.2' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=4', text: 'seoLinks.land.sale.4.2' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=6', text: 'seoLinks.land.sale.6.2' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=7', text: 'seoLinks.land.sale.7.2' },
                  { to: '/real-estate-search/land-lots-for-sale/purpose=8', text: 'seoLinks.land.sale.8.2' },
                ],
              },
            ],
          },
          {
            value: 'rent',
            name: 'seoLinks.tab.re.rent.2',
            sections: [
              {
                name: 'seoLinks.apartments.rent.2',
                link: '/real-estate-search/apartments-for-rent',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/rooms=1',
                    text: 'seoLinks.apartments.rent.1room.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/rooms=2',
                    text: 'seoLinks.apartments.rent.2room.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/rooms=3',
                    text: 'seoLinks.apartments.rent.3room.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/rooms=4,5+',
                    text: 'seoLinks.apartments.rent.4room.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/private-user',
                    text: 'seoLinks.apartments.rent.fromOwner.2',
                  },
                ],
              },
              {
                name: 'seoLinks.houses.rent.2',
                link: '/real-estate-search/houses-for-rent',
                links: [
                  { to: '/real-estate-search/houses-for-rent/size=na-100', text: 'seoLinks.houses.rent.100.2' },
                  { to: '/real-estate-search/houses-for-rent/size=100-150', text: 'seoLinks.houses.rent.150.2' },
                  { to: '/real-estate-search/houses-for-rent/size=150-200', text: 'seoLinks.houses.rent.200.2' },
                  { to: '/real-estate-search/houses-for-sale/size=200-na', text: 'seoLinks.houses.rent.200+.2' },
                  { to: '/real-estate-search/houses-for-rent/private-user', text: 'seoLinks.houses.rent.fromOwner.2' },
                ],
              },
              {
                name: 'seoLinks.commercials.rent.2',
                link: '/real-estate-search/commercials-for-rent',
                links: [
                  { to: '/real-estate-search/commercials-for-rent/purpose=2', text: 'seoLinks.commercials.rent.2.2' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=4', text: 'seoLinks.commercials.rent.4.2' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=8', text: 'seoLinks.commercials.rent.8.2' },
                  { to: '/real-estate-search/commercials-for-rent/purpose=11', text: 'seoLinks.commercials.rent.11.2' },
                ],
              },
              {
                name: 'seoLinks.land.rent.2',
                link: '/real-estate-search/land-lots-for-rent',
                links: [
                  { to: '/real-estate-search/land-lots-for-rent/purpose=1', text: 'seoLinks.land.rent.1.2' },
                  { to: '/real-estate-search/land-lots-for-rent/purpose=2', text: 'seoLinks.land.rent.2.2' },
                  { to: '/real-estate-search/land-lots-for-rent/purpose=3', text: 'seoLinks.land.rent.3.2' },
                  { to: '/real-estate-search/land-lots-for-rent/purpose=6', text: 'seoLinks.land.rent.6.2' },
                  { to: '/real-estate-search/land-lots-for-rent/purpose=7', text: 'seoLinks.land.rent.7.2' },
                  { to: '/real-estate-search/land-lots-for-rent/purpose=8', text: 'seoLinks.land.rent.8.2' },
                ],
              },
            ],
          },
          {
            value: 'projects',
            name: 'seoLinks.tab.projects.2',
            sections: [
              {
                name: 'seoLinks.projects.2',
                link: '/new-projects',
                links: [
                  {
                    to: '/new-projects',
                    text: 'seoLinks.projects.sale.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/t=apartments',
                    text: 'seoLinks.projects.apartments.sale.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/t=houses',
                    text: 'seoLinks.projects.houses.sale.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/t=commercials',
                    text: 'seoLinks.projects.commercials.sale.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/t=land-lots',
                    text: 'seoLinks.projects.landLots.sale.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/open-house',
                    text: 'seoLinks.projects.openHouse.sale.2',
                  },
                ],
              },
              {
                name: 'seoLinks.projects.riga.2',
                link: '/real-estate-search/new-projects-for-sale/riga/id=25110-county',
                links: [
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-agenskalns/id=25875-city',
                    text: 'seoLinks.np.agenskalns.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-bierini/id=25884-city',
                    text: 'seoLinks.np.bierini.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-centrs/id=25892-city',
                    text: 'seoLinks.np.centrs.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-dreilini/id=25897-city',
                    text: 'seoLinks.np.dreilini.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-imanta/id=25901-city',
                    text: 'seoLinks.np.imanta.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-jugla/id=25904-city',
                    text: 'seoLinks.np.jugla.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-kliversala/id=25909-city',
                    text: 'seoLinks.np.kliversala.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-krasta-masivs/id=25910-city',
                    text: 'seoLinks.np.krastaMasivs.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-kengarags/id=25906-city',
                    text: 'seoLinks.np.kengarags.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-maskavas-priekspilseta/id=25913-city',
                    text: 'seoLinks.np.maskavas.2',
                  },
                ],
              },
              {
                name: '',
                links: [
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-mezaparks/id=25914-city',
                    text: 'seoLinks.np.mezaparks.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-mezciems/id=25915-city',
                    text: 'seoLinks.np.mezciems.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-pardaugava/id=25918-city',
                    text: 'seoLinks.np.pardaugava.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-pleskodale-sampeteris/id=25920-city',
                    text: 'seoLinks.np.sampeteris.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-plavnieki/id=25936-city',
                    text: 'seoLinks.np.plavnieki.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-purvciems/id=25921-city',
                    text: 'seoLinks.np.purvciems.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-skanste/id=201838-city',
                    text: 'seoLinks.np.skanste.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-teika/id=25926-city',
                    text: 'seoLinks.np.teika.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-tornakalns/id=25927-city',
                    text: 'seoLinks.np.tornakalns.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-vecriga/id=25931-city',
                    text: 'seoLinks.np.vecriga.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/riga-ziepniekkalns/id=25934-city',
                    text: 'seoLinks.np.ziepniekkalns.2',
                  },
                ],
              },
              {
                name: 'seoLinks.projects.near_riga.2',
                links: [
                  {
                    to: '/real-estate-search/new-projects-for-sale/jurmala/id=25114-county',
                    text: 'seoLinks.np.jurmala.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/marupes-nov/id=25063-county',
                    text: 'seoLinks.np.marupe.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/babites-nov/id=25034-county',
                    text: 'seoLinks.np.babite.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/adazu-nov/id=25027-county',
                    text: 'seoLinks.np.adazi.2',
                  },
                  {
                    to: '/real-estate-search/new-projects-for-sale/kekavas-nov/id=25057-county',
                    text: 'seoLinks.np.kekava.2',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'seoLinks.section.regions.2',
        link: '/',
        tabs: [
          {
            value: 'sale',
            name: 'seoLinks.tab.regions.apartments.sale.2',
            sections: [
              {
                name: 'seoLinks.apartments.riga.sale.2',
                link: '/real-estate-search/apartments-for-sale/riga/id=25110-county',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-agenskalns/id=25875-city',
                    text: 'seoLinks.apartments.agenskalns.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-bierini/id=25884-city',
                    text: 'seoLinks.apartments.bierini.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-centrs/id=25892-city',
                    text: 'seoLinks.apartments.centrs.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-ciekurkalns/id=25893-city',
                    text: 'seoLinks.apartments.ciekurkalns.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-dzirciems/id=25898-city',
                    text: 'seoLinks.apartments.darzciems.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-dreilini/id=25897-city',
                    text: 'seoLinks.apartments.dreilini.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-imanta/id=25901-city',
                    text: 'seoLinks.apartments.imanta.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-ilguciems/id=25900-city',
                    text: 'seoLinks.apartments.ilguciems.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-jugla/id=25904-city',
                    text: 'seoLinks.apartments.jugla.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-kliversala/id=25909-city',
                    text: 'seoLinks.apartments.kliversala.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-krasta-masivs/id=25910-city',
                    text: 'seoLinks.apartments.krastaMasivs.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-kengarags/id=25906-city',
                    text: 'seoLinks.apartments.kengarags.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-maskavas-priekspilseta/id=25913-city',
                    text: 'seoLinks.apartments.maskavas.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-mezaparks/id=25914-city',
                    text: 'seoLinks.apartments.mezaparks.sale.2',
                  },
                ],
              },
              {
                name: '',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-mezciems/id=25915-city',
                    text: 'seoLinks.apartments.mezciems.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-pardaugava/id=25918-city',
                    text: 'seoLinks.apartments.pardaugava.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-sarkandaugava/id=25923-city',
                    text: 'seoLinks.apartments.sarkandaugava.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-pleskodale-sampeteris/id=25920-city',
                    text: 'seoLinks.apartments.sampeteris.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-plavnieki/id=25936-city',
                    text: 'seoLinks.apartments.plavnieki.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-purvciems/id=25921-city',
                    text: 'seoLinks.apartments.purvciems.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-skanste/id=201838-city',
                    text: 'seoLinks.apartments.skanste.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-teika/id=25926-city',
                    text: 'seoLinks.apartments.teika.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-tornakalns/id=25927-city',
                    text: 'seoLinks.apartments.tornakalns.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-vecriga/id=25931-city',
                    text: 'seoLinks.apartments.vecriga.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-vecmilgravis/id=25930-city',
                    text: 'seoLinks.apartments.vecmilgravis.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-ziepniekkalns/id=25934-city',
                    text: 'seoLinks.apartments.ziepniekkalns.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/riga-zolitude/id=25935-city',
                    text: 'seoLinks.apartments.zolitude.sale.2',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.jurmala.sale.2',
                link: '/real-estate-search/apartments-for-sale/jurmala/id=25114-county',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-asari/id=25937-city',
                    text: 'seoLinks.apartments.asari.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-bulduri/id=25938-city',
                    text: 'seoLinks.apartments.bulduri.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-bulluciems/id=25939-city',
                    text: 'seoLinks.apartments.bulluciems.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-dubulti/id=25941-city',
                    text: 'seoLinks.apartments.dubulti.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-dzintari/id=25942-city',
                    text: 'seoLinks.apartments.dzintari.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-jaundubulti/id=25943-city',
                    text: 'seoLinks.apartments.jaundubulti.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-kauguri/id=25946-city',
                    text: 'seoLinks.apartments.kauguri.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-lielupe/id=25887-city',
                    text: 'seoLinks.apartments.lielupe.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-majori/id=25888-city',
                    text: 'seoLinks.apartments.majori.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-melluzi/id=25890-city',
                    text: 'seoLinks.apartments.melluzi.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jurmala-pumpuri/id=25881-city',
                    text: 'seoLinks.apartments.pumpuri.sale.2',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.regions.sale.2',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-sale/liepaja/id=25115-county',
                    text: 'seoLinks.apartments.liepaja.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/adazu-nov/id=25027-county',
                    text: 'seoLinks.apartments.adazi.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/babites-nov/id=25034-county',
                    text: 'seoLinks.apartments.babite.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/jelgava/id=25112-county',
                    text: 'seoLinks.apartments.jelgava.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/kekavas-nov-balozi,kekavas-nov-kekava,kekavas-nov-kekavas-pag/id=25527-parish,25531-parish,25529-parish',
                    text: 'seoLinks.apartments.kekava.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/marupes-nov/id=25063-county',
                    text: 'seoLinks.apartments.marupe.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/salaspils-nov/id=25109-county',
                    text: 'seoLinks.apartments.salaspils.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/saulkrastu-nov/id=25103-county',
                    text: 'seoLinks.apartments.saulkrasti.sale.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-sale/stopinu-nov-valodzes,stopinu-nov-ulbroka,stopinu-nov-sauriesi,stopinu-nov-lici/id=25869-parish,25863-parish,25866-parish,25864-parish',
                    text: 'seoLinks.apartments.stopini.sale.2',
                  },
                ],
              },
            ],
          },
          {
            value: 'rent',
            name: 'seoLinks.tab.regions.apartments.rent.2',
            sections: [
              {
                name: 'seoLinks.apartments.riga.rent.2',
                link: '/real-estate-search/apartments-for-rent/riga/id=25110-county',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-agenskalns/id=25875-city',
                    text: 'seoLinks.apartments.agenskalns.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-bierini/id=25884-city',
                    text: 'seoLinks.apartments.bierini.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-centrs/id=25892-city',
                    text: 'seoLinks.apartments.centrs.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-dreilini/id=25897-city',
                    text: 'seoLinks.apartments.dreilini.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-imanta/id=25901-city',
                    text: 'seoLinks.apartments.imanta.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-jugla/id=25904-city',
                    text: 'seoLinks.apartments.jugla.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-kliversala/id=25909-city',
                    text: 'seoLinks.apartments.kliversala.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-krasta-masivs/id=25910-city',
                    text: 'seoLinks.apartments.krastaMasivs.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-kengarags/id=25906-city',
                    text: 'seoLinks.apartments.kengarags.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-maskavas-priekspilseta/id=25913-city',
                    text: 'seoLinks.apartments.maskavas.rent.2',
                  },
                ],
              },
              {
                name: '',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-mezaparks/id=25914-city',
                    text: 'seoLinks.apartments.mezaparks.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-mezciems/id=25915-city',
                    text: 'seoLinks.apartments.mezciems.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-pardaugava/id=25918-city',
                    text: 'seoLinks.apartments.pardaugava.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-pleskodale-sampeteris/id=25920-city',
                    text: 'seoLinks.apartments.sampeteris.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-plavnieki/id=25936-city',
                    text: 'seoLinks.apartments.plavnieki.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-purvciems/id=25921-city',
                    text: 'seoLinks.apartments.purvciems.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-skanste/id=201838-city',
                    text: 'seoLinks.apartments.skanste.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-teika/id=25926-city',
                    text: 'seoLinks.apartments.teika.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-tornakalns/id=25927-city',
                    text: 'seoLinks.apartments.tornakalns.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-vecriga/id=25931-city',
                    text: 'seoLinks.apartments.vecriga.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/riga-ziepniekkalns/id=25934-city',
                    text: 'seoLinks.apartments.ziepniekkalns.rent.2',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.jurmala.rent.2',
                link: '/real-estate-search/apartments-for-rent/jurmala/id=25114-county',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-asari/id=25937-city',
                    text: 'seoLinks.apartments.asari.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-bulduri/id=25938-city',
                    text: 'seoLinks.apartments.bulduri.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-bulluciems/id=25939-city',
                    text: 'seoLinks.apartments.bulluciems.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-dubulti/id=25941-city',
                    text: 'seoLinks.apartments.dubulti.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-dzintari/id=25942-city',
                    text: 'seoLinks.apartments.dzintari.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-jaundubulti/id=25943-city',
                    text: 'seoLinks.apartments.jaundubulti.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-kauguri/id=25946-city',
                    text: 'seoLinks.apartments.kauguri.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-lielupe/id=25887-city',
                    text: 'seoLinks.apartments.lielupe.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-majori/id=25888-city',
                    text: 'seoLinks.apartments.majori.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-melluzi/id=25890-city',
                    text: 'seoLinks.apartments.melluzi.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/jurmala-pumpuri/id=25881-city',
                    text: 'seoLinks.apartments.pumpuri.rent.2',
                  },
                ],
              },
              {
                name: 'seoLinks.apartments.regions.rent.2',
                links: [
                  {
                    to: '/real-estate-search/apartments-for-rent/liepaja/id=25115-county',
                    text: 'seoLinks.apartments.liepaja.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/adazu-nov/id=25027-county',
                    text: 'seoLinks.apartments.adazi.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/babites-nov/id=25034-county',
                    text: 'seoLinks.apartments.babite.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/marupes-nov/id=25063-county',
                    text: 'seoLinks.apartments.marupe.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/salaspils-nov/id=25109-county',
                    text: 'seoLinks.apartments.salaspils.rent.2',
                  },
                  {
                    to: '/real-estate-search/apartments-for-rent/saulkrastu-nov/id=25103-county',
                    text: 'seoLinks.apartments.saulkrasti.rent.2',
                  },
                ],
              },
            ],
          },
          {
            value: 'houseSale',
            name: 'seoLinks.tab.regions.houses.sale.2',
            sections: [
              {
                name: 'seoLinks.houses.riga.sale.2',
                link: '/real-estate-search/houses-for-sale/riga/id=25110-county',
                links: [
                  {
                    to: '/real-estate-search/houses-for-sale/riga-agenskalns/id=25875-city',
                    text: 'seoLinks.houses.agenskalns.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-aplokciems/id=25874-city',
                    text: 'seoLinks.houses.aplokciems.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-bergi/id=25883-city',
                    text: 'seoLinks.houses.bergi.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-bierini/id=25884-city',
                    text: 'seoLinks.houses.bierini.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-bolderaja/id=25889-city',
                    text: 'seoLinks.houses.bolderaja.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-bukulti/id=25891-city',
                    text: 'seoLinks.houses.bukulti.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-centrs/id=25892-city',
                    text: 'seoLinks.houses.centrs.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-ciekurkalns/id=25893-city',
                    text: 'seoLinks.houses.ciekurkalns.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-darzciems/id=25894-city',
                    text: 'seoLinks.houses.darzciems.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-darzini/id=25895-city',
                    text: 'seoLinks.houses.darzini.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-daugavgriva/id=25896-city',
                    text: 'seoLinks.houses.daugavgriva.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-dreilini/id=25897-city',
                    text: 'seoLinks.houses.dreilini.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-ilguciems/id=25900-city',
                    text: 'seoLinks.houses.ilguciems.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-imanta/id=25901-city',
                    text: 'seoLinks.houses.imanta.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-jaunciems/id=25902-city',
                    text: 'seoLinks.houses.jaunciems.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-jugla/id=25904-city',
                    text: 'seoLinks.houses.jugla.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-kliversala/id=25909-city',
                    text: 'seoLinks.houses.kliversala.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-krasta-masivs/id=25910-city',
                    text: 'seoLinks.houses.krastaMasivs.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-kengarags/id=25906-city',
                    text: 'seoLinks.houses.kengarags.2',
                  },
                ],
              },
              {
                name: '',
                links: [
                  {
                    to: '/real-estate-search/houses-for-sale/riga-mangalu-pussala/id=25912-city',
                    text: 'seoLinks.houses.mangaluPussala.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-maskavas-priekspilseta/id=25913-city',
                    text: 'seoLinks.houses.maskavas.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-mezaparks/id=25914-city',
                    text: 'seoLinks.houses.mezaparks.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-mezciems/id=25915-city',
                    text: 'seoLinks.houses.mezciems.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-pardaugava/id=25918-city',
                    text: 'seoLinks.houses.pardaugava.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-pleskodale-sampeteris/id=25920-city',
                    text: 'seoLinks.houses.sampeteris.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-plavnieki/id=25936-city',
                    text: 'seoLinks.houses.plavnieki.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-purvciems/id=25921-city',
                    text: 'seoLinks.houses.purvciems.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-sarkandaugava/id=25923-city',
                    text: 'seoLinks.houses.sarkandaugava.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-teika/id=25926-city',
                    text: 'seoLinks.houses.teika.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-tornakalns/id=25927-city',
                    text: 'seoLinks.houses.tornakalns.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-trisciems/id=25877-city',
                    text: 'seoLinks.houses.trisciems.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-vecriga/id=25931-city',
                    text: 'seoLinks.houses.vecriga.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-ziepniekkalns/id=25934-city',
                    text: 'seoLinks.houses.ziepniekkalns.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-vecaki/id=25928-city',
                    text: 'seoLinks.houses.vecaki.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-vecmilgravis/id=25930-city',
                    text: 'seoLinks.houses.vecmilgravis.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/riga-zolitude/id=25935-city',
                    text: 'seoLinks.houses.zolitude.2',
                  },
                ],
              },
              {
                name: 'seoLinks.houses.jurmala.sale.2',
                link: '/real-estate-search/houses-for-sale/jurmala/id=25114-county',
                links: [
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-asari/id=25937-city',
                    text: 'seoLinks.houses.asari.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-bulduri/id=25938-city',
                    text: 'seoLinks.houses.bulduri.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-bulluciems/id=25939-city',
                    text: 'seoLinks.houses.bulluciems.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-dubulti/id=25941-city',
                    text: 'seoLinks.houses.dubulti.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-dzintari/id=25942-city',
                    text: 'seoLinks.houses.dzintari.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-jaundubulti/id=25943-city',
                    text: 'seoLinks.houses.jaundubulti.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-kauguri/id=25946-city',
                    text: 'seoLinks.houses.kauguri.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-lielupe/id=25887-city',
                    text: 'seoLinks.houses.lielupe.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-majori/id=25888-city',
                    text: 'seoLinks.houses.majori.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-melluzi/id=25890-city',
                    text: 'seoLinks.houses.melluzi.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-pumpuri/id=25881-city',
                    text: 'seoLinks.houses.pumpuri.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala-valteri/id=25882-city',
                    text: 'seoLinks.houses.valteri.2',
                  },
                ],
              },
              {
                name: 'seoLinks.houses.regions.sale.2',
                links: [
                  {
                    to: '/real-estate-search/houses-for-sale/jurmala/id=25114-county',
                    text: 'seoLinks.houses.jurmala.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/marupes-nov/id=25063-county',
                    text: 'seoLinks.houses.marupe.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/adazu-nov/id=25027-county',
                    text: 'seoLinks.houses.adazi.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/carnikavas-nov/id=25028-county',
                    text: 'seoLinks.houses.carnikava.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/garkalnes-nov/id=25029-county',
                    text: 'seoLinks.houses.garkalne.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/babites-nov/id=25034-county',
                    text: 'seoLinks.houses.babite.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/kekavas-nov/id=25057-county',
                    text: 'seoLinks.houses.kekava.2',
                  },
                  {
                    to: '/real-estate-search/houses-for-sale/saulkrastu-nov/id=25103-county',
                    text: 'seoLinks.houses.saulkrasti.2',
                  },
                ],
              },
            ],
          },
          {
            value: 'landSale',
            name: 'seoLinks.tab.regions.land.sale.2',
            sections: [
              {
                name: 'seoLinks.land.cities.sale.2',
                link: '/real-estate-search/land-lots-for-sale',
                links: [
                  { to: '/real-estate-search/land-lots-for-sale/riga/id=25110-county', text: 'seoLinks.land.riga.2' },
                  {
                    to: '/real-estate-search/land-lots-for-sale/jelgava/id=25112-county',
                    text: 'seoLinks.land.jelgava.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/jurmala/id=25114-county',
                    text: 'seoLinks.land.jurmala.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/adazu-nov/id=25027-county',
                    text: 'seoLinks.land.adazi.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/babites-nov/id=25034-county',
                    text: 'seoLinks.land.babite.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/carnikavas-nov/id=25028-county',
                    text: 'seoLinks.land.carnikava.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/garkalnes-nov/id=25029-county',
                    text: 'seoLinks.land.garkalne.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/kekavas-nov/id=25057-county',
                    text: 'seoLinks.land.kekava.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/marupes-nov/id=25063-county',
                    text: 'seoLinks.land.marupe.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/olaines-nov/id=25068-county',
                    text: 'seoLinks.land.olaine.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/salaspils-nov/id=25109-county',
                    text: 'seoLinks.land.salaspils.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/saulkrastu-nov/id=25103-county',
                    text: 'seoLinks.land.saulkrasti.2',
                  },
                  {
                    to: '/real-estate-search/land-lots-for-sale/siguldas-nov/id=25016-county',
                    text: 'seoLinks.land.sigulda.2',
                  },
                ],
              },
            ],
          },
          {
            value: 'commercialRent',
            name: 'seoLinks.tab.regions.commercial.rent.2',
            sections: [
              {
                name: 'seoLinks.commercial.riga.rent.2',
                link: '/real-estate-search/commercials-for-rent/riga/id=25110-county',
                links: [
                  {
                    to: '/real-estate-search/commercials-for-rent/riga/id=25110-county',
                    text: 'seoLinks.commercial.riga.all.2',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/riga/purpose=2/id=25110-county',
                    text: 'seoLinks.commercial.riga.2.2',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/riga/purpose=4/id=25110-county',
                    text: 'seoLinks.commercial.riga.4.2',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/riga/purpose=8/id=25110-county',
                    text: 'seoLinks.commercial.riga.8.2',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/riga/purpose=11/id=25110-county',
                    text: 'seoLinks.commercial.riga.11.2',
                  },
                ],
              },
              {
                name: 'seoLinks.commercial.regions.rent.2',
                links: [
                  {
                    to: '/real-estate-search/commercials-for-rent/jelgava/id=25112-county',
                    text: 'seoLinks.commercial.jelgava.2',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/jurmala/id=25114-county',
                    text: 'seoLinks.commercial.jurmala.2',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/babites-nov/id=25034-county',
                    text: 'seoLinks.commercial.babite.2',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/kekavas-nov/id=25057-county',
                    text: 'seoLinks.commercial.kekava.2',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/marupes-nov/id=25063-county',
                    text: 'seoLinks.commercial.marupe.2',
                  },
                  {
                    to: '/real-estate-search/commercials-for-rent/siguldas-nov/id=25016-county',
                    text: 'seoLinks.commercial.sigulda.2',
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
