import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '@/components/icon/Icon';
import Container from '@/components/container/Container';
import Image from '@/components/img/Image';

const propTypes = {
  icon: PropTypes.string,
};

const defaultProps = {
  icon: '',
};

const Empty = (props) => {
  const { icon, image, imageAlt, title, subtitle, tight, children } = props;

  const emptyStyles = classNames({
    empty: true,
    'empty--tight': tight,
  });

  return (
    <div className={emptyStyles}>
      <Container>
        {icon && (
          <div className="empty__icon">
            <Icon name={icon} />
          </div>
        )}
        {image && <Image src={image} alt={imageAlt} className="empty__image" />}
        <h3 className="empty__title">{title}</h3>
        <div className="empty__subtitle">{subtitle}</div>
        <div>{children}</div>
      </Container>
    </div>
  );
};

Empty.propTypes = propTypes;
Empty.defaultProps = defaultProps;

export default Empty;
