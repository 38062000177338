import React from 'react';
import classNames from 'classnames';
import ReactGA from '@/utils/ga';
import type { TFunction } from 'next-i18next';
import { OpenDaysDetails } from '@city24/common/api/openDaysApi';

import { GA_DETAIL_VIEW } from '@/constants/ga';
import openDaysApi from '@/api/openDaysApi';
import Anchor from '@/components/link/Anchor';

interface Props {
  t: TFunction;
  title: string;
  className: string;
  id: string;
  language: string;
  details: OpenDaysDetails;
}

function OpenHouse({ t, title, className, id, language, details }: Props) {
  const getDate = () => {
    const { openDate, startTime, endTime } = details;

    const [year, month, day] = openDate.split('-');
    const dateString = `${day}.${month}.${year}`;

    const start = startTime.replace(/(.*)\D\d+/, '$1');
    const end = endTime.replace(/(.*)\D\d+/, '$1');

    return `${dateString}, ${start}-${end}`;
  };

  return (
    <div className={classNames('open-house', className)}>
      <Anchor
        href={openDaysApi.getOpenDaysSignupUrl(id, language)}
        onClick={() => {
          ReactGA.event({
            category: GA_DETAIL_VIEW,
            action: 'open_registerOpenHouse_button',
          });
        }}
      >
        <div className="open-house__wrap">
          <div className="open-house__icon">
            <span className="icon icon-open-house" />
          </div>
          <div className="open-house__flex-wrap open-house__content">
            <div className="open-house__title h4">{`${title} ${getDate()}`}</div>
            <div className="open-house__label">{t('openHouse.registrationRequired')}</div>
          </div>
          <div className="open-house__right-icon">
            <span className="icon icon-angle-right" />
          </div>
        </div>
      </Anchor>
    </div>
  );
}

export default OpenHouse;
