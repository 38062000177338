import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from './Checkbox';
import Toggle from '../toggle/Toggle';
import ErrorMessage from '../errorMessage/ErrorMessage';

const propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checked: PropTypes.bool,
  getValue: PropTypes.func,
};

const defaultProps = {
  id: null,
  value: '',
  text: '',
  checked: false,
  getValue: () => {},
};

class CheckboxField extends React.Component {
  handleChange = (checked, value) => {
    const { getValue } = this.props;

    getValue(checked, value);
  };

  render() {
    const { checked, className, toggle, value, id, text, error, small, luminor } = this.props;

    const formFieldStyle = classNames(
      {
        'form-field': true,
        'form-field--small': small,
      },
      className
    );
    return (
      <div className={formFieldStyle}>
        <div className="form-field__flex">
          {toggle ? (
            <Toggle id={id} value={value} handleChange={this.handleChange} checked={checked} />
          ) : (
            <Checkbox
              id={id}
              value={value}
              handleChange={this.handleChange}
              checked={checked}
              error={error}
              luminor={luminor}
            />
          )}
          <label htmlFor={id}>{text}</label>
        </div>
        {error ? <ErrorMessage text={error} /> : null}
      </div>
    );
  }
}

CheckboxField.propTypes = propTypes;
CheckboxField.defaultProps = defaultProps;

export default CheckboxField;
