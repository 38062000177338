export function uuidv4() {
  if (crypto.randomUUID) {
    return crypto.randomUUID();
  }
  return [1e7, 1e3, 4e3, 8e3, 1e11].join('-').replace(/[018]/g, (c) => {
    const asNumber = Number(c);
    return (asNumber ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (asNumber / 4)))).toString(16);
  });
}

export function generateAnonToken() {
  return Math.floor(Date.now() / 1000) + uuidv4();
}
