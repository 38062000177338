import React from 'react';
import ReactGA from '@/utils/ga';
import Language from '@city24/common/enums/Language';
import { GA_DETAIL_VIEW } from '@/constants/ga';
import { ELEKTRUM_ELECTRICITY_LINKS, ELEKTRUM_NG_LINKS } from '@/constants/integrations';
import { TFunction } from 'next-i18next';
import Anchor from '@/components/link/Anchor';
import Icon from '@/components/icon/Icon';

import css from '../SpecsItem.module.scss';

interface Props {
  language: Language;
  t: TFunction;
}

const ElektrumSpecsItem = ({ language, t }: Props): React.ReactElement => {
  return (
    <div className={`${css.item} ${css.elektrum}`}>
      <div>
        <Icon name="elektrum" />
      </div>
      <div>
        <Anchor
          href={ELEKTRUM_ELECTRICITY_LINKS![language]}
          onClick={() => {
            ReactGA.event({
              category: GA_DETAIL_VIEW,
              action: 'ElektrumLinkClick',
              label: 'electricity',
            });
          }}
        >
          <span className={css.content}>{t(`objectSpec.elektrumElectricity`)}</span>
          <Icon name="angle-right" className={css.icon} />
        </Anchor>
        <Anchor
          href={ELEKTRUM_NG_LINKS![language]}
          onClick={() => {
            ReactGA.event({
              category: GA_DETAIL_VIEW,
              action: 'ElektrumLinkClick',
              label: 'ng',
            });
          }}
        >
          <span className={css.content}>{t(`objectSpec.elektrumNg`)}</span>
          <Icon name="angle-right" className={css.icon} />
        </Anchor>
      </div>
    </div>
  );
};

export default ElektrumSpecsItem;
