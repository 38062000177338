import React, { useState, useRef, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import { ContainerQuery } from 'react-container-query';
import ReactResizeDetector from 'react-resize-detector';

import { useAppSelector } from '@/hooks';
import { getBrowser } from '@/selectors/appSelectors';

function CustomArrow(props: React.HTMLAttributes<HTMLButtonElement> & { arrowTop?: string; text?: string }) {
  const { className, style = {}, onClick, arrowTop, text } = props;

  return (
    <button type="button" className={className} style={{ ...style, top: arrowTop }} onClick={onClick}>
      {text}
    </button>
  );
}

interface Props {
  children?: React.ReactElement[];
  className?: string;
  initialSlide?: number;
  noSlides?: number;
  stickArrowsToElement?: string;
  infinite?: boolean;
  sliderQuery?: { [slideCount: number]: { minWidth?: number; maxWidth?: number } };
  dark?: boolean;
}

function ItemSlider({
  children = [],
  className,
  initialSlide = 0,
  noSlides,
  stickArrowsToElement,
  infinite = false,
  sliderQuery,
  dark,
}: Props) {
  const sliderRef = useRef<Slider>(null);
  const browser = useAppSelector(getBrowser);
  const [arrowTop, setArrowTop] = useState<string | undefined>(undefined);

  const calcArrowTop = () => {
    if (sliderRef.current && stickArrowsToElement) {
      const el = sliderRef.current.innerSlider!.list!.querySelector(stickArrowsToElement) as HTMLElement;
      if (el) {
        const newTop = `${el.offsetHeight / 2 + 8}px`;

        if (arrowTop !== newTop) {
          setArrowTop(newTop);
        }
      }
    }
  };

  useEffect(() => {
    calcArrowTop();
  }, [children]);

  const containerQuery = useMemo(() => {
    if (sliderQuery) {
      return sliderQuery;
    }
    return {
      1: { maxWidth: 360 },
      2: { minWidth: 361, maxWidth: 680 },
      3: { minWidth: 681, maxWidth: 1020 },
      4: { minWidth: 1021, maxWidth: 1360 },
      5: { minWidth: 1361, maxWidth: 1700 },
      6: { minWidth: 1701 },
    };
  }, [sliderQuery?.[1]?.maxWidth]);

  const itemSliderMobStyle = classNames('item-slider--mob', className);

  return (
    <>
      {browser.lessThan.medium ? (
        <ul className={itemSliderMobStyle}>
          {children.map((child) => {
            return (
              <li key={child.key} className="item-slider--mob__slide">
                {child}
              </li>
            );
          })}
        </ul>
      ) : (
        <ContainerQuery query={containerQuery}>
          {(params) => {
            let slidesToShow = 1;

            if (noSlides) {
              // override the number of slides if provided via the props
              slidesToShow = noSlides;
            } else {
              for (; slidesToShow < 6; slidesToShow++) {
                if (params[slidesToShow]) {
                  break;
                }
              }
            }

            const className = classNames('item-slider', { 'item-slider--dark': dark });

            return (
              <ReactResizeDetector handleWidth refreshMode="debounce" refreshRate={250} onResize={calcArrowTop}>
                <Slider
                  // For some reason, in some cases slider does not show items when children change.
                  // Using children as key to force reload the Slider component
                  key={children}
                  speed={500}
                  slidesToShow={slidesToShow}
                  slidesToScroll={slidesToShow}
                  className={className}
                  dots={false}
                  infinite={infinite}
                  initialSlide={initialSlide}
                  nextArrow={<CustomArrow arrowTop={arrowTop} text="Next" />}
                  prevArrow={<CustomArrow arrowTop={arrowTop} text="Previous" />}
                  ref={sliderRef}
                >
                  {children}
                </Slider>
              </ReactResizeDetector>
            );
          }}
        </ContainerQuery>
      )}
    </>
  );
}

export default ItemSlider;
