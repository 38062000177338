import { fromJS } from 'immutable';

const name = 'loanCalc.period.years';
export const PERIOD_OPTIONS = fromJS([...Array(29).keys()].map((i) => ({ name, value: `${i + 1}` }))).push(
  fromJS({ name, value: '30', default: true })
);

export const SELF_FINANCE_UNIT_OPTIONS = fromJS([
  { name: '%', value: 'percent', default: true },
  { name: '€', value: 'eur' },
]);

export const DEFAULT_INTEREST = PORTAL_EE ? 3.5 : 2.5;
export const DEFAULT_SELF_FINANCE = PORTAL_EE ? 20 : 15;
