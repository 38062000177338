import { createSelector } from 'reselect';

import { createStatsFilters } from '@/utils/appliedFilters';
import { addFavouriteToObject } from '@/utils/helpers';
import { getBrokerBrandOptions } from '@/utils/contacts';
import { getFavourites } from '@/selectors/userSelectors';
import { RootState } from '@/store';

const getBrokerDetailsState = (state: RootState) => state.broker.details;

export const getBrokerDetails = (state: RootState) => getBrokerDetailsState(state).info;

export const getBrokerPropertyStatistics = (state: RootState) =>
  getBrokerDetailsState(state).statistics?.properties || {};

export const getBrokerFilterList = createSelector(getBrokerPropertyStatistics, createStatsFilters);

export const getBrandOptions = createSelector(getBrokerDetails, (broker) => {
  return getBrokerBrandOptions(broker.get('business_card'));
});

export const getBrokerRating = createSelector(getBrokerDetails, (broker) => broker.get('average_rating'));

export const getBrokerObjects = createSelector(
  (state) => getBrokerDetailsState(state).objects.objects,
  getFavourites,
  (objects, favourites) => {
    if (!objects) {
      return objects;
    }

    return objects.map((object) => addFavouriteToObject(favourites, object));
  }
);
export const getBrokerRecentObjects = (state: RootState) => getBrokerDetailsState(state).objects.recentObjects;

export const getBrokerObjectsCount = (state: RootState) => getBrokerDetailsState(state).objects.total;

export const getBrokerOffice = (state: RootState) => getBrokerDetailsState(state).office;
