import React from 'react';
import PropTypes from 'prop-types';

import ReactGA from '@/utils/ga';
import Input from '../../input/Input';
import InputRange from '../../input/InputRange';
import { GA_SEARCH } from '@/constants/ga';

const propTypes = {
  minYearBuilt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxYearBuilt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleMinYearBuilt: PropTypes.func.isRequired,
  handleMaxYearBuilt: PropTypes.func.isRequired,
};

const defaultProps = {
  minYearBuilt: '',
  maxYearBuilt: '',
};

const YearBuiltRange = (props) => {
  const { minYearBuilt, maxYearBuilt, handleMinYearBuilt, handleMaxYearBuilt, t } = props;

  return (
    <div className="filter__price-range">
      <h4 className="filter__heading">{t('objectSpec.yearBuilt')}</h4>
      <InputRange>
        <Input
          notched
          label="min"
          value={minYearBuilt}
          type="number"
          getValue={handleMinYearBuilt}
          gaEvent={() =>
            ReactGA.event({
              category: GA_SEARCH,
              action: 'select_year_built',
              label: 'rangeMin',
            })
          }
        />
        <Input
          notched
          label="max"
          value={maxYearBuilt}
          type="number"
          getValue={handleMaxYearBuilt}
          gaEvent={() =>
            ReactGA.event({
              category: GA_SEARCH,
              action: 'select_year_built',
              label: 'rangeMax',
            })
          }
        />
      </InputRange>
    </div>
  );
};

YearBuiltRange.propTypes = propTypes;
YearBuiltRange.defaultProps = defaultProps;

export default YearBuiltRange;
