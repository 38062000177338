import {
  LOAD_AD_MASTER,
  ADD_AD_MASTER,
  LOAD_AD_SLAVE,
  REMOVE_AD_SLAVE,
  REMOVE_AD_MASTER,
  SET_AD_BANNER,
  SET_AD_KEY_VARS,
} from '@/constants/actions';

export function addAdMaster(masterId, slaveId) {
  return {
    type: ADD_AD_MASTER,
    masterId,
    slaveId,
  };
}

export function removeAdMaster(masterId) {
  return {
    type: REMOVE_AD_MASTER,
    masterId,
  };
}

export function loadAdMaster(masterId, keys, vars) {
  return {
    type: LOAD_AD_MASTER,
    masterId,
    keys,
    vars,
  };
}

export function loadAdSlave(masterId, slaveId) {
  return {
    type: LOAD_AD_SLAVE,
    masterId,
    slaveId,
  };
}

export function removeAdSlave(masterId, slaveId) {
  return {
    type: REMOVE_AD_SLAVE,
    masterId,
    slaveId,
  };
}

export function setAdKeyVars(masterId, vars, keys) {
  return {
    type: SET_AD_KEY_VARS,
    masterId,
    vars,
    keys: keys?.map((string) => string.replace(/ /g, '+')),
  };
}

export function setAdLoaded(masterId, slaveId, height) {
  return {
    type: SET_AD_BANNER,
    masterId,
    slaveId,
    height,
  };
}
