import { SET_REPORT_AD_MODAL, SET_REPORT_AD_CONFIRM_MODAL, REPORT_AD_ERROR } from '@/constants/actions';

import api from '@/api/City24Api';
import { ViolationError } from '@/errors/app';
import { HttpError } from '@/errors/http';
import { AppDispatch } from '@/store';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import { openModal } from '../modalReducer';
import { ModalName } from '../modalInterfaces';

export function sendReport(
  id: string,
  objectType: UnitTypeLiteral,
  email: string,
  reason: string,
  comment: string,
  token: string
) {
  return (dispatch: AppDispatch) => {
    api
      .reportAd(objectType, id, {
        email,
        reason,
        comment,
        token,
        portal: PORTAL,
      })
      .then((res) => {
        if (res.ok) {
          dispatch(openModal({ name: ModalName.ReportAd, confirmed: true }));
        } else if (res.status === 400) {
          return res.json().then((body) => {
            throw new ViolationError(body.detail, body.violations);
          });
        } else {
          throw new HttpError();
        }

        return null;
      })
      .catch((err) => {
        dispatch({
          type: REPORT_AD_ERROR,
          error: (err instanceof ViolationError && 'violation') || 'error',
          violations: err.violations,
        });
      });
  };
}
