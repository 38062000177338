import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import { SIZE_UNIT_VALUES, OBJECT_TYPES } from '@/constants/object';
import { formatPrice, formatPriceRange } from '@/utils/formatting';

import Icon from '@/components/icon/Icon';
import Label from '@/components/label/Label';
import LoadingPlaceholderBox from '@/components/loading/LoadingPlaceholderBox';
import ObjectLoanLabel from '@/components/object/ObjectLoan/ObjectLoanLabel';

const ObjectPrice = (props) => {
  const {
    layout = 'item', // or 'detail'
    objectType,
    transactionType,
    price,
    pricePerUnit,
    pricePerUnitMin,
    pricePerUnitMax,
    pricePerUnitType,
    oldPrice,
    minPrice,
    maxPrice,
    priceElement,
    loading,
    enablePlaceholderPrice,
    hidePricePerUnit,
    hideOldPrice,
    rentToOwn,
    rentToOwnLabelAsBlock,
    monthlyLoanPayment,
    friendlyId,
    addVAT,
    t,
  } = props;

  const showUnitPriceAsMainPrice =
    !price && !minPrice && !maxPrice && (pricePerUnitMin || pricePerUnitMax || pricePerUnit);

  const renderPriceDrop = () => {
    if (Number(price) < Number(oldPrice)) {
      return <Icon name="arrow-down" className="object-price__pricedrop-arrow" />;
    }
    return null;
  };

  const formatUnitPrice = () => {
    let priceText = '';
    if (pricePerUnitMin || pricePerUnitMax) {
      priceText = `${pricePerUnitMin ? formatPrice(pricePerUnitMin, true, 0) : '...'} - ${
        pricePerUnitMax ? formatPrice(pricePerUnitMax, false, 0) : '...'
      }`;
    } else if (pricePerUnit) {
      priceText = formatPrice(pricePerUnit);
    }
    return priceText ? `${priceText}/${pricePerUnitType || SIZE_UNIT_VALUES[1]}` : null;
  };

  const renderMainPrice = () => {
    let element = priceElement;

    if (!element) {
      const hasPriceRange =
        [OBJECT_TYPES.NewProject, OBJECT_TYPES.ModularHouse].includes(objectType) && (minPrice || maxPrice);
      if (loading) {
        element = <LoadingPlaceholderBox size={4} />;
      } else if (showUnitPriceAsMainPrice) {
        element = formatUnitPrice();
      } else if (hasPriceRange) {
        element = formatPriceRange(minPrice, maxPrice);
      } else {
        element = (
          <>
            {renderPriceDrop()}
            {(() => {
              if (price) {
                return addVAT ? `${formatPrice(price)} + ${t(`objectSpec.VAT.short.${PORTAL}`)}` : formatPrice(price);
              }
              if (enablePlaceholderPrice) {
                return '... ... €';
              }
              return null;
            })()}
          </>
        );
      }
    }

    return <div className="object-price__main-price">{element}</div>;
  };

  const renderOldPrice = () => {
    if (oldPrice && !hideOldPrice && Number(price) < Number(oldPrice)) {
      const percentage = Math.floor((price / oldPrice) * 100) - 100;

      return (
        <div className="object-price__pricedrop">
          <Label small green>
            {`${percentage}%`}
          </Label>
          <span className="object-price__old-price">{formatPrice(oldPrice)}</span>
        </div>
      );
    }
    return null;
  };

  const renderPricePerUnit = () => {
    if (loading) {
      return <LoadingPlaceholderBox size={2} />;
    }
    if (showUnitPriceAsMainPrice) {
      return null;
    }
    const priceText = formatUnitPrice();
    return priceText ? <div className="object-price__m2-price">{priceText}</div> : null;
  };

  const renderMonthlyPayment = () => {
    if (monthlyLoanPayment && !(PORTAL_LV && isMobile)) {
      return (
        <ObjectLoanLabel
          t={t}
          monthlyLoanPayment={monthlyLoanPayment}
          friendlyId={friendlyId}
          price={price}
          transactionType={transactionType}
          objectType={objectType}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className={`object-price object-price--${layout}`}>
        {renderMainPrice()}
        {renderOldPrice()}
        {!hidePricePerUnit && renderPricePerUnit()}
        {!rentToOwnLabelAsBlock && rentToOwn}
        {renderMonthlyPayment()}
      </div>
      {rentToOwnLabelAsBlock && rentToOwn}
    </>
  );
};

ObjectPrice.propTypes = {
  price: PropTypes.string,
  oldPrice: PropTypes.string,
  rentToOwnLabelAsBlock: PropTypes.bool,
};

ObjectPrice.defaultProps = {
  price: null,
  oldPrice: null,
  rentToOwnLabelAsBlock: false,
};

export default ObjectPrice;
