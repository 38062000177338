import { createSelector } from 'reselect';
import { getFavourites } from '@/selectors/userSelectors';
import { addFavouriteToObject } from '@/utils/helpers';
import { RootState } from '@/store';

export const getMapSelectedMarker = (state: RootState) => state.map.markers.selected;
export const getMapMarkers = (state: RootState) => state.map.markers.markers;
export const getMapNearbyPlaces = (state: RootState) => state.map.nearbyPlaces;
const getMapInfo = (state: RootState) => state.map.info;
export const getObjectToMapMarker = (state: RootState) => getMapInfo(state).objectToMapMarker;
export const getHighlightedMapMarker = (state: RootState) => getMapInfo(state).highlightedMapMarker;
export const getMapSelectedObjects = createSelector(
  (state) => getMapInfo(state).selectedObjectIds,
  (state) => getMapInfo(state).objects,
  getFavourites,
  (selected, objects, favourites) => {
    return selected.map((key) => addFavouriteToObject(favourites, objects[key]));
  }
);
export const isMapInfoOpen = (state: RootState) => getMapInfo(state).isOpen;
export const isMapInfoLoading = (state: RootState) => getMapInfo(state).isLoading;
export const getActiveMapBounds = (state: RootState) => state.map.bounds;
