import { List, Map } from 'immutable';
import Language from '@city24/common/enums/Language';

export const MODEL_STATUSES = Object.freeze({
  NEW: 0,
  VALID: 1,
  PUBLISHED: 2,
  EXPIRED: 3,
  SOLD_RENTED: 4,
  TERMINATED: 5,
  ARCHIVED: 6,
  CLOSED: 7,
  USER_ARCHIVED: 8,
  PRIVATE: 9,
});

export const PORTAL_NAME = PORTAL_EE ? 'CITY24_EE' : 'CITY24_LV';
export const SITE_NAME = PORTAL_EE ? 'City24.ee' : 'City24.lv';

export function defaultLocaleForLanguage(language: string | undefined): string {
  switch (language) {
    case 'et':
      return 'et-EE';
    case 'ru':
      return 'ru-RU';
    case 'lv':
      return 'lv-LV';
    case 'en':
      return 'en-GB';
    default:
      return process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
  }
}

export function languageFromLocale(locale: string = process.env.NEXT_PUBLIC_DEFAULT_LOCALE): string {
  return locale.slice(0, 2);
}

export const DEFAULT_LANG = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

export const LANGUAGES = [PORTAL_EE ? Language.et : Language.lv, Language.ru, Language.en];

export function countryCodeForLanguage(language: string): string {
  switch (language) {
    case 'et':
      return 'EST';
    case 'ru':
      return 'RUS';
    case 'lv':
      return 'LAT';
    case 'en':
      return 'ENG';
    default:
      return '';
  }
}

export const RESULTS_VIEW = {
  list: 'list',
  grid: 'grid',
  map: 'map',
};

export const RESULTS_VIEW_OPTIONS = List([
  Map({ icon: 'list', name: 'common.list', value: RESULTS_VIEW.list }),
  Map({ icon: 'grid', name: 'common.grid', value: RESULTS_VIEW.grid }),
  Map({ icon: 'map-pin', name: 'common.map', value: RESULTS_VIEW.map }),
]);

export const SEARCH_RESULTS_TO_SHOW = 50;
export const SEARCH_RESULTS_SPECIAL_PLACEMENTS = 9; // 8 banners + save search widget

export const PAYMENT_STATUS = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};
