import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '@/hooks';

import { AsyncDataStatus } from '@/types/collections';
import { setObjectListModal } from '@/components/modals/ModalActions';
import { GET_LINKED_OBJECTS } from '@/constants/actions';
import { fetchProjectRealties } from '@/components/object/ObjectDetailsActions';
import { getLinkedObjects } from '@/components/search/searchSelectors';
import { getBrowser, getPrefixedRoutes } from '@/selectors/appSelectors';

import ObjectList from '@/components/object/ObjectsList/ObjectList';
import Loading from '@/components/loading/Loading';
import Icon from '@/components/icon/Icon';
import Anchor from '@/components/link/Anchor';

type Props = {
  projectId: string;
  objectId: string;
  projectName?: string;
  realtiesCount: number;
  realtiesCountTotal?: string;
  compact?: boolean;
  asPopup: boolean;
};

const LinkedObjects = ({
  projectId,
  objectId,
  projectName,
  realtiesCount = 0,
  realtiesCountTotal,
  compact = false,
  asPopup = false,
}: Props): React.ReactElement | null => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const browser = useAppSelector(getBrowser);
  const routes = useAppSelector(getPrefixedRoutes);
  const [opened, setOpened] = useState(false);

  const realtiesProxy = useAppSelector(getLinkedObjects(projectId));

  useEffect(() => {
    if (opened && realtiesProxy.status === AsyncDataStatus.None) {
      dispatch(fetchProjectRealties(projectId, null, GET_LINKED_OBJECTS));
    }
  }, [opened]);

  useEffect(() => {
    if (asPopup) {
      dispatch(setObjectListModal(opened, projectId, projectName));
    }
  }, [opened]);

  return (
    <div className={classNames('lo', { 'lo--compact': compact })}>
      <Anchor
        className="lo__header"
        onClick={() => {
          setOpened(!opened);
        }}
      >
        {!compact && <Icon name="new-project" />}
        {projectName && <span className="project-name">{projectName}</span>}
        {compact && (
          <span className="header-link">
            <Icon name="angle-right" />
          </span>
        )}
        {realtiesCount > 0 && (
          <span className="info">
            {t('objectSpec.realtiesCount', {
              // TODO: count must be a number, need to refactor the token
              count: realtiesCountTotal ? `${realtiesCount}/${parseInt(realtiesCountTotal)}` : realtiesCount,
              interpolation: { escapeValue: false },
            })}
          </span>
        )}
        {!compact && (
          <span className="header-link">
            {browser.greaterThan.small && t(opened ? 'common.hideApartments' : 'common.showApartments')}
            {` `}
            <Icon name={opened ? 'angle-up' : 'angle-down'} />
          </span>
        )}
      </Anchor>
      {!asPopup && (
        <div className="lo__body">
          {opened && realtiesProxy.status === AsyncDataStatus.Loading && <Loading />}
          {opened && realtiesProxy.status === AsyncDataStatus.Loaded && realtiesProxy.data.length > 0 && (
            <ObjectList
              objects={realtiesProxy.data}
              routes={routes}
              onlyMainFeatures={browser.lessThan.medium}
              newTab
              t={t}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LinkedObjects;
