import { List, Map } from 'immutable';

import {
  PURPOSE_OPTIONS,
  FLOOR,
  TOTAL_FLOORS,
  BUILDING_MATERIAL_OPTIONS,
  CONDITION_OPTIONS,
  BALCONY_TYPE_OPTIONS,
  LIFT,
  ROOF_TYPE_OPTIONS,
  ENERGY_CERTIFICATE_OPTIONS,
  NUMBER_OF_BEDROOMS,
  NUMBER_OF_BATHROOMS,
  KITCHEN_AREA,
  NUMBER_OF_DESKS,
  PARKING_SELECTION_OPTIONS,
  PARKING_SELECTION_OPTIONS_COMMERCIAL,
  PARKING_COST_OPTIONS,
  HEATING_SYSTEM_OPTIONS,
  SANITARY_ARRANGEMENTS_OPTIONS,
  STOVE_OPTIONS,
  VENTILATION_TYPE_OPTIONS,
  RENT_COMMUNAL_COSTS_OPTIONS,
  EXTRA_SPACES_OPTIONS,
  OWNERSHIP_OPTIONS,
  LIMITATION_OPTIONS,
  AVAILABLE,
  IMMEDIATELY_AVAILABLE,
  OTHER_USE_POSSIBLE,
  RENT_SUMMER_COMMUNAL_COSTS,
  RENT_WINTER_COMMUNAL_COSTS,
  BALCONY_SIZE,
  RENT_PER_DAY,
  PARKING_PLACES,
  MUNICIPAL_ENGINEERING_OPTIONS,
  COMMUNICATIONS_OPTIONS,
  SECURITY_OPTIONS,
  EXTRA_VALUES_OPTIONS,
  CONSTRUCTION_YEAR,
  COUNT_PRICE_PER_UNIT_AUTO,
  PRICE_PER_UNIT_MEASUREMENT,
  DEPOSIT_MONEY,
  BROKERS_FEE,
  PROJECT_TYPE_OPTIONS,
  RENT_TO_OWN,
  DETAILED_PLAN,
  VAT,
} from '../../constants/attributes';
import { OBJECT_TYPES, TRANSACTION_TYPES } from '../../constants/object';

import { fromISOString, toISOString } from '../../utils/date';
import { identity } from '../../utils/helpers';

function applyAttributeMappings(
  unitType,
  transactionType,
  { number: numberValue, bool: boolValue, date: dateValue, singleOption, multiOption, groupedSingleOptions }
) {
  numberValue(RENT_PER_DAY, 'rentPerDay');
  if (unitType === OBJECT_TYPES.Commercial && transactionType === TRANSACTION_TYPES.RENT) {
    boolValue(VAT, 'vat');
  }
  if (unitType === OBJECT_TYPES.Commercial) {
    multiOption('PURPOSE_OF_USE_COMMERCIAL', 'purpose', PURPOSE_OPTIONS.get(unitType));
  } else if (unitType === OBJECT_TYPES.Land) {
    multiOption('PURPOSE_OF_USE_LAND', 'purpose', PURPOSE_OPTIONS.get(unitType));
  }
  boolValue(OTHER_USE_POSSIBLE, 'otherUsePossible');
  boolValue(DETAILED_PLAN, 'detailedPlan');

  numberValue(FLOOR, 'floor', true);
  numberValue(TOTAL_FLOORS, 'totalFloors');
  multiOption('BUILDING_MATERIAL', 'material', BUILDING_MATERIAL_OPTIONS);
  multiOption('CONDITION', 'condition', CONDITION_OPTIONS);
  multiOption('BALCONY_TYPE', 'balcony', BALCONY_TYPE_OPTIONS);
  numberValue(BALCONY_SIZE, 'balconySize');
  boolValue(LIFT, 'elevator');
  numberValue(CONSTRUCTION_YEAR, 'yearBuilt');
  singleOption('ROOF_TYPE', 'roofType', ROOF_TYPE_OPTIONS);
  singleOption('ENERGY_CERTIFICATE_TYPE', 'energyCertificate', ENERGY_CERTIFICATE_OPTIONS);
  numberValue(DEPOSIT_MONEY, 'depositMoney');
  numberValue(BROKERS_FEE, 'brokersFee');
  singleOption('HOUSE_TYPE', 'projectType', PROJECT_TYPE_OPTIONS);
  boolValue(RENT_TO_OWN, 'rentToOwn');

  numberValue(NUMBER_OF_BEDROOMS, 'bedrooms');
  numberValue(NUMBER_OF_BATHROOMS, 'bathrooms');
  numberValue(KITCHEN_AREA, 'kitchenSize');
  numberValue(NUMBER_OF_DESKS, 'numberOfDesks');

  numberValue(PARKING_PLACES, 'numberOfParkingPlaces');
  multiOption('PARKING_SELECTION', 'parkingLocations', PARKING_SELECTION_OPTIONS);
  multiOption('PARKING_SELECTION_COMMERCIAL', 'parkingLocationsCommercial', PARKING_SELECTION_OPTIONS_COMMERCIAL);
  groupedSingleOptions('parkingCost', PARKING_COST_OPTIONS);

  multiOption('HEATING_SYSTEM', 'heatingSystem', HEATING_SYSTEM_OPTIONS);
  multiOption('SANITARY_ARRANGEMENTS', 'sanitaryArrangements', SANITARY_ARRANGEMENTS_OPTIONS);
  multiOption('STOVE', 'stove', STOVE_OPTIONS);
  multiOption('VENTILATION_TYPE', 'ventilation', VENTILATION_TYPE_OPTIONS);
  multiOption('RENT_COMMUNAL_COSTS', 'communalCostItems', RENT_COMMUNAL_COSTS_OPTIONS);
  numberValue(RENT_SUMMER_COMMUNAL_COSTS, 'summerCommunalCost');
  numberValue(RENT_WINTER_COMMUNAL_COSTS, 'winterCommunalCost');

  groupedSingleOptions('extraSpaces', EXTRA_SPACES_OPTIONS);
  groupedSingleOptions('municipalEngineering', MUNICIPAL_ENGINEERING_OPTIONS);
  multiOption('COMMUNICATIONS', 'communications', COMMUNICATIONS_OPTIONS);
  multiOption('SECURITY', 'security', SECURITY_OPTIONS);
  groupedSingleOptions('extraValues', EXTRA_VALUES_OPTIONS);
  singleOption('OWNERSHIP', 'ownership', OWNERSHIP_OPTIONS);
  singleOption('LIMITATIONS', 'limitations', LIMITATION_OPTIONS);

  dateValue(AVAILABLE, 'available');
  boolValue(IMMEDIATELY_AVAILABLE, 'immediatelyAvailable');
}

/**
 *
 * @param {string} unitType
 * @param {string} transactionType
 * @param {object} attributes
 */
export function fromAttributesToUI(unitType, transactionType, attributes) {
  const mappedAttr = {};

  applyAttributeMappings(unitType, transactionType, {
    number: (attr, field, anyValue = false) => {
      const raw = attributes[attr];
      const value = Number(raw);
      if (raw !== null && raw !== '' && !Number.isNaN(value) && (anyValue || value > 0)) {
        mappedAttr[field] = value;
      } else {
        mappedAttr[field] = null;
      }
    },
    bool: (attr, field) => {
      mappedAttr[field] = !!attributes[attr];
    },
    date: (attr, field) => {
      if (attributes[attr]) {
        mappedAttr[field] = fromISOString(attributes[attr]);
      } else {
        mappedAttr[field] = null;
      }
    },
    singleOption: (attr, field, options) => {
      if (attributes[attr]) {
        const value = attributes[attr].pop();
        mappedAttr[field] = options.find((opt) => opt.get('value') === value) || Map();
      } else {
        mappedAttr[field] = Map();
      }
    },
    multiOption: (attr, field, options) => {
      if (attributes[attr]) {
        mappedAttr[field] = List(
          attributes[attr].map((value) => options.find((opt) => opt.get('value') === value)).filter(identity)
        );
      } else {
        mappedAttr[field] = List();
      }
    },
    groupedSingleOptions: (field, options) => {
      mappedAttr[field] = options.filter((opt) => attributes[opt.get('value')]);
    },
  });

  return Map(mappedAttr);
}

/**
 *
 * @param {string} unitType
 * @param {string} transactionType
 * @param {Map} uiInfo
 */
export function fromUIToAttributes(unitType, transactionType, uiInfo) {
  const mappedAttr = {
    [COUNT_PRICE_PER_UNIT_AUTO]: true,
    [PRICE_PER_UNIT_MEASUREMENT]: 1,
  };

  applyAttributeMappings(unitType, transactionType, {
    number: (attr, field, anyValue = false) => {
      const raw = uiInfo.get(field);
      const value = Number(raw);
      if (raw !== null && raw !== '' && !Number.isNaN(value) && (anyValue || value > 0)) {
        mappedAttr[attr] = value;
      }
    },
    bool: (attr, field) => {
      const value = uiInfo.get(field);
      if (value !== null) {
        mappedAttr[attr] = !!value;
      }
    },
    date: (attr, field) => {
      if (uiInfo.get(field)) {
        mappedAttr[attr] = toISOString(uiInfo.get(field));
      }
    },
    singleOption: (attr, field, options) => {
      if (uiInfo.get(field)) {
        mappedAttr[attr] = uiInfo.getIn([field, 'value']);
      }
    },
    multiOption: (attr, field, options) => {
      const values = uiInfo.get(field).map((opt) => opt.get('value'));
      if (!values.isEmpty()) {
        mappedAttr[attr] = values.toArray();
      }
    },
    groupedSingleOptions: (field, options) => {
      uiInfo.get(field).forEach((opt) => {
        mappedAttr[opt.get('value')] = true;
      });
    },
  });

  return mappedAttr;
}
