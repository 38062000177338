import React from 'react';
import { ImgProps, useImage } from 'react-image';
import Image, { ImageProps } from './Image';

interface Props extends Omit<ImageProps, 'src'>, Pick<ImgProps, 'src' | 'loader'> {}

interface ChildProps extends ImageProps {
  src?: string;
  loader?: ImgProps['loader'];
  isLoading: boolean;
}

/* eslint-disable jsx-a11y/alt-text */
function createImgChild({ src, isLoading, placeholder, loader, ...props }: ChildProps) {
  if (isLoading) {
    if (loader) {
      return loader;
    }
    if (placeholder) {
      return <Image src={placeholder} {...props} />;
    }
  }
  return <Image key={src} src={src} placeholder={placeholder} {...props} />;
}

function Img({ src: srcList, ...props }: Props) {
  const { src, isLoading } = useImage({ srcList, useSuspense: false });
  return createImgChild({ src, isLoading, ...props });
}

export default Img;
