import React, { useContext, useState } from 'react';
import classNames from 'classnames';

import { SEO_FOOTER_MAX_LINKS, seoFooterSectionTabs } from '@/constants/siteLinks';
import Tabs from '@/components/tabs/Tabs';
import Tab from '@/components/tabs/Tab';
import FooterSection from '@/components/Footer/FooterSection';
import FooterSectionItem from '@/components/Footer/FooterSectionItem';
import AppContext from '@/components/appContext';

type Props = {
  title: string;
  link?: string;
  tabs: seoFooterSectionTabs[];
  onFooterLinkClick: () => void;
};

const SiteLinksSection = ({ title, link, tabs, onFooterLinkClick }: Props): React.ReactElement => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const { Link } = useContext(AppContext);

  const renderTabContent = () => {
    const renderLinks = (links: { to: string; text: string }[]) => {
      return links.map((link, idx) => (
        <FooterSectionItem
          key={link.to + link.text}
          visuallyHidden={idx + 1 > SEO_FOOTER_MAX_LINKS}
          to={link.to}
          onClick={onFooterLinkClick}
        >
          {link.text}
        </FooterSectionItem>
      ));
    };

    return tabs.map((tab) => (
      <div
        key={tab.value}
        className={classNames(`footer__sections footer--col-${tab.sections.length}`, {
          hidden: tab.value !== selectedTab,
        })}
      >
        {tab.sections.map((section) => (
          <FooterSection
            key={section.link + section.name}
            title={section.name}
            link={section.link}
            onClick={onFooterLinkClick}
          >
            {renderLinks(section.links)}
          </FooterSection>
        ))}
      </div>
    ));
  };

  return (
    <div className="site-links__section">
      {link ? (
        <Link href={link} className="h2">
          {title}
        </Link>
      ) : (
        <span className="h2">{title}</span>
      )}
      <Tabs selected={selectedTab} minimal onChange={setSelectedTab}>
        {tabs.map((tab) => (
          <Tab key={tab.value} value={tab.value}>
            {tab.name}
          </Tab>
        ))}
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default SiteLinksSection;
