import React from 'react';
import type { TFunction } from 'next-i18next';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import Modal from '@/components/modals/Modal';
import ModalHeader from '@/components/modals/ModalHeader';
import MultiBankCalculator from '@/components/finance/Calculators/MultiBankCalculator/MultiBankCalculator';

import { useAppDispatch, useModalState } from '@/hooks';
import { ModalName, MultiBankModalState } from '../modalInterfaces';
import { closeModal } from '../modalReducer';

import css from './MultiBankCalculatorModal.module.scss';
import MultiBankCalculatorContext from '@/components/finance/Calculators/MultiBankCalculator/context';

type Props = {
  t: TFunction;
};

function MultiBankCalculatorModal({ t }: Props): React.ReactElement {
  const dispatch = useAppDispatch();
  const modal = useModalState<MultiBankModalState>(ModalName.MultiBank);
  const { price, unitType, bank, yearBuilt } = modal.state;

  return (
    <MultiBankCalculatorContext.Provider value={{ yearBuilt }}>
      <Modal isOpen={modal.open} onRequestClose={() => dispatch(closeModal())} className={css.modal}>
        <ModalHeader title={t('objectDetail.multiBankCalculator')} />
        <MultiBankCalculator
          objectPrice={price}
          unitType={unitType || UnitTypeLiteral.APARTMENT}
          selectedBank={bank}
          t={t}
          placementName="modal"
        />
      </Modal>
    </MultiBankCalculatorContext.Provider>
  );
}

export default MultiBankCalculatorModal;
