import React from 'react';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import * as modalActions from './ModalActions';

import {
  getSearchModalOpened,
  getSaveSearchModal,
  getSaveSearchConfirmModalOpened,
  getSaveFavouriteModalOpened,
  getSavedSearchRemoveModal,
  getAreaSelectModalOpened,
  getDeleteMyObjectModal,
  getInvoicesSentModalOpened,
  getSuccessfulActionModal,
  getSEBCalculatorModal,
  getDetailPreviewModal,
  getPrefixedRoutes,
  getObjectListModal,
} from '@/selectors/appSelectors';

import SearchModal from './SearchModal/SearchModal';
import SaveSearchModal from './SaveSearchModal/SaveSearchModal';
import SaveSearchConfirmModal from './SaveSearchConfirmModal/SaveSearchConfirmModal';
import ReportAdModal from './ReportAdModal/ReportAdModal';
import SaveFavouriteModal from './SaveFavouriteModal/SaveFavouriteModal';
import AreaSelectModal from './AreaSelectModal/AreaSelectModal';
import SavedSearchRemoveModal from './SavedSearchRemoveModal/SavedSearchRemoveModal';
import ShareModal from './ShareModal/ShareModal';
import DeleteMyObjectModal from './DeleteMyObjectModal/DeleteMyObjectModal';
import InvoicesSentModal from './InvoicesSentModal/InvoicesSentModal';
import SuccessfulActionModal from './SuccessfulActionModal/SuccessfulActionModal';
import DetailPreviewModal from './DetailPreviewModal/DetailPreviewModal';
import SEBCalculatorModal from '@/components/modals/SEBCalculatorModal/SEBCalculatorModal';
import ObjectListModal from '@/components/modals/ObjectListModal/ObjectListModal';
import MultiBankHomeLoanCalculatorModal from '@/components/modals/MultiBankCalculatorModal/MultiBankCalculatorModal';
import SimpleConfirmModal from './SimpleConfirmModal';
import MessageBrokerModal from './MessageBrokerModal/MessageBrokerModal';

class Modals extends React.PureComponent {
  render() {
    const {
      t,
      routes,
      searchModalOpened,
      setSearchModalOpened,
      saveSearchModal,
      setSaveSearchModal,
      saveSearchConfirmModalOpened,
      setSaveSearchConfirmModalOpened,
      saveFavouriteModalOpened,
      setSaveFavouriteModalOpened,
      savedSearchRemoveModal,
      setSavedSearchRemoveModal,
      areaSelectModalOpened,
      setAreaSelectModalOpened,
      deleteMyObjectModal,
      setDeleteMyObjectModal,
      invoicesSentModalOpened,
      setInvoicesSentModal,
      successfulActionModalState,
      setSuccessfulActionModal,
      detailPreviewModal,
      setDetailPreviewModalOpened,
      SEBCalculatorModalState,
      setSEBCalculatorModalOpened,
      objectListModal,
      setObjectListModal,
    } = this.props;

    // TODO: move ShareModal, ReportAdModal, SimpleConfirmModal to relevant pages

    return (
      <>
        {searchModalOpened && (
          <SearchModal isOpen={searchModalOpened} closeModal={() => setSearchModalOpened(false)} t={t} />
        )}
        {saveSearchModal.get('opened') && (
          <SaveSearchModal
            isOpen={saveSearchModal.get('opened')}
            item={saveSearchModal.get('item')}
            edit={saveSearchModal.get('edit')}
            closeModal={() => setSaveSearchModal(false)}
            t={t}
          />
        )}
        <SaveSearchConfirmModal
          isOpen={saveSearchConfirmModalOpened}
          closeModal={() => setSaveSearchConfirmModalOpened(false)}
          routes={routes}
          t={t}
        />
        <ReportAdModal t={t} />
        <SaveFavouriteModal
          isOpen={saveFavouriteModalOpened}
          closeModal={() => setSaveFavouriteModalOpened(false)}
          t={t}
        />
        <SavedSearchRemoveModal
          isOpen={savedSearchRemoveModal.get('opened')}
          id={savedSearchRemoveModal.get('id')}
          name={savedSearchRemoveModal.get('name')}
          emailAlerts={savedSearchRemoveModal.get('emailAlerts')}
          closeModal={() => setSavedSearchRemoveModal(false)}
          t={t}
        />
        <AreaSelectModal isOpen={areaSelectModalOpened} closeModal={() => setAreaSelectModalOpened(false)} t={t} />
        <ShareModal t={t} />
        <MessageBrokerModal t={t} />
        <SimpleConfirmModal t={t} />
        <MultiBankHomeLoanCalculatorModal t={t} />
        <DeleteMyObjectModal
          isOpen={deleteMyObjectModal.get('opened')}
          action={deleteMyObjectModal.get('action')}
          id={deleteMyObjectModal.get('id')}
          friendlyId={deleteMyObjectModal.get('friendlyId')}
          address={deleteMyObjectModal.get('address')}
          location={deleteMyObjectModal.get('location')}
          closeModal={() => setDeleteMyObjectModal(false)}
          t={t}
        />
        <InvoicesSentModal isOpen={invoicesSentModalOpened} closeModal={() => setInvoicesSentModal(false)} t={t} />
        <SuccessfulActionModal
          isOpen={successfulActionModalState.get('opened')}
          closeModal={() => setSuccessfulActionModal(false, null)}
          actionType={successfulActionModalState.get('actionType')}
          t={t}
        />
        <DetailPreviewModal
          id={detailPreviewModal.get('id')}
          isOpen={detailPreviewModal.get('opened')}
          closeModal={() => setDetailPreviewModalOpened(false)}
          t={t}
        />
        <SEBCalculatorModal
          isOpen={SEBCalculatorModalState.get('opened')}
          price={SEBCalculatorModalState.get('price')}
          transactionType={SEBCalculatorModalState.get('transactionType')}
          closeModal={() => setSEBCalculatorModalOpened(false)}
          t={t}
        />
        <ObjectListModal
          isOpen={objectListModal.get('opened')}
          objectId={objectListModal.get('objectId')}
          projectName={objectListModal.get('projectName')}
          closeModal={() => setObjectListModal(false, null)}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    routes: getPrefixedRoutes(state),
    searchModalOpened: getSearchModalOpened(state),
    saveSearchModal: getSaveSearchModal(state),
    savedSearchRemoveModal: getSavedSearchRemoveModal(state),
    saveSearchConfirmModalOpened: getSaveSearchConfirmModalOpened(state),
    saveFavouriteModalOpened: getSaveFavouriteModalOpened(state),
    areaSelectModalOpened: getAreaSelectModalOpened(state),
    deleteMyObjectModal: getDeleteMyObjectModal(state),
    invoicesSentModalOpened: getInvoicesSentModalOpened(state),
    successfulActionModalState: getSuccessfulActionModal(state),
    SEBCalculatorModalState: getSEBCalculatorModal(state),
    detailPreviewModal: getDetailPreviewModal(state),
    objectListModal: getObjectListModal(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(modalActions, dispatch);
}

export default compose(withTranslation('modals'), connect(mapStateToProps, mapDispatchToProps))(Modals);
