import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { applyImageFormat } from '@city24/common/utils/formatting';

import ReactResizeDetector from 'react-resize-detector';
import Image from '@/components/img/Image';
import ObjectPlaceholder from '@/img/placeholder/object_placeholder.svg';

const propTypes = {
  images: PropTypes.node,
  className: PropTypes.string,
  maxSlides: PropTypes.number,
  loop: PropTypes.bool,
  imageAlt: PropTypes.string,
  imageFormat: PropTypes.string,
  onFirstInteraction: PropTypes.func,
};

const defaultProps = {
  images: null,
  className: '',
  maxSlides: 1,
  loop: false,
  imageAlt: '',
  imageFormat: '432x288',
  onFirstInteraction: () => {},
};

function CustomArrow(props) {
  const { className, style, onClick, arrowTop, text } = props;

  return (
    <button type="button" className={className} style={{ ...style, top: arrowTop && arrowTop }} onClick={onClick}>
      {text}
    </button>
  );
}

class ObjectImageSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: props.initialSlide || 0,
      arrowTop: null,
      hadInteraction: false,
    };

    this.sliderRef = React.createRef();
    this.itemRef = React.createRef();
  }

  renderImage = (image) => {
    const { imageAlt, imageFormat } = this.props;

    return <Image lazyload placeholder={ObjectPlaceholder} alt={imageAlt} src={applyImageFormat(image, imageFormat)} />;
  };

  setArrowTop = () => {
    const { stickArrowsToElement } = this.props;
    if (this.itemRef.current && stickArrowsToElement) {
      const el = this.itemRef.current.querySelector(stickArrowsToElement);
      const elHeight = el.offsetHeight;

      const newTop = `${elHeight / 2 + 8}px`;

      if (this.state.arrowTop !== newTop) {
        this.setState({ arrowTop: newTop });
      }
    }
  };

  next = () => {
    this.sliderRef.current.slickNext();
  };

  previous = () => {
    this.sliderRef.current.slickPrev();
  };

  render() {
    const { images, initialSlide, maxSlides, loop, onFirstInteraction } = this.props;
    const { arrowTop, hadInteraction } = this.state;

    return (
      <ReactResizeDetector handleWidth refreshMode="debounce" refreshRate={250} onResize={this.setArrowTop}>
        <Slider
          speed={500}
          slidesToShow={maxSlides}
          slidesToScroll={maxSlides}
          className="object-image-slider"
          dots={false}
          infinite={loop}
          initialSlide={initialSlide || 0}
          afterChange={(current) => {
            this.setState({ activeSlide: current });
            if (!hadInteraction) {
              onFirstInteraction();
              this.setState({ hadInteraction: true });
            }
          }}
          nextArrow={<CustomArrow arrowTop={arrowTop} text="Next" />}
          prevArrow={<CustomArrow arrowTop={arrowTop} text="Previous" />}
          ref={this.sliderRef}
        >
          {images.map((img) => {
            return (
              <div key={img} ref={this.itemRef}>
                {this.renderImage(img)}
              </div>
            );
          })}
        </Slider>
      </ReactResizeDetector>
    );
  }
}

ObjectImageSlider.propTypes = propTypes;
ObjectImageSlider.defaultProps = defaultProps;

export default ObjectImageSlider;
