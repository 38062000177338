import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactGA from '@/utils/ga';

import { getLuminorLinks } from '@/selectors/calculatorsSelectors';
import Anchor from '@/components/link/Anchor';
import LuminorTheme from '../LuminorTheme/LuminorTheme';

function LuminorConsumerLoanLink({ t }) {
  const { renLoanDetailsOffer } = useSelector(getLuminorLinks);
  return (
    <LuminorTheme>
      <div>
        <Trans t={t} i18nKey="loanCalc.consumerLoan" components={{ nbsp: <span style={{ whiteSpace: 'nowrap' }} /> }} />
      </div>
      <Anchor
        className="luminor-theme__link"
        href={renLoanDetailsOffer}
        onClick={() => {
          ReactGA.event({
            category: 'luminorMonthlyPaymentHome_objFullDesc',
            action: 'click_getLoanOffer_url',
          });
        }}
      >
        {t('luminor.homeLoanCalc.cta')}
      </Anchor>
    </LuminorTheme>
  );
}

LuminorConsumerLoanLink.propTypes = {
  t: PropTypes.func.isRequired,
};
// LuminorConsumerLoanLink.defaultProps = {};

export default LuminorConsumerLoanLink;
