import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { getUnitTypeFromSavedSearch } from '@/utils/search';
import { OBJECT_TYPES, TRANSACTION_TYPES } from '@/constants/object';
import { objectTransactionString, formatMinMaxPrice } from '@/utils/formatting';
import Label from '@/components/label/Label';
import Icon from '@/components/icon/Icon';
import AppContext from '@/components/appContext';

function composeSearchName(savedSearch, t) {
  const { name } = savedSearch;

  if (name) {
    return name;
  }

  const {
    search_mode,
    criteria: { unit_types, transaction_type },
  } = savedSearch;

  const objectType = getUnitTypeFromSavedSearch(search_mode, unit_types) || OBJECT_TYPES.Apartment;
  const transactionType = (transaction_type && transaction_type.transaction_type_code) || TRANSACTION_TYPES.SALE;

  return t(objectTransactionString(objectType, transactionType));
}

function composeSearchFilters(savedSearch) {
  const filterList = [];

  const { criteria } = savedSearch;

  const minPrice = Number(criteria.min_price);
  const maxPrice = Number(criteria.max_price);

  if (minPrice || maxPrice) {
    filterList.push(<span key="priceRange">{formatMinMaxPrice(minPrice, maxPrice)}</span>);
  }

  const minSize = criteria.min_size;
  const maxSize = criteria.max_size;

  if (minSize || maxSize) {
    filterList.push(<span key="sizeRange">{`${minSize || '...'} - ${maxSize || '...'} m²`}</span>);
  }

  return filterList;
}

function QuickSavedSearch({ linkTo, savedSearch, onClick, className, t }) {
  const { Link } = useContext(AppContext);
  const newAdsCount = savedSearch.new_ads_count;
  return (
    <Link href={linkTo} className={className} onClick={onClick}>
      <div className="h4 quick-search__title">{t('quickSearch.latestSavedSearch')}</div>
      <div className="quick-search__wrap">
        {newAdsCount > 0 && (
          <Label rounded orange className="quick-search__new-count">
            {`+${newAdsCount}`}
          </Label>
        )}
        <div className="quick-search__content">
          <div className="quick-search__name">{composeSearchName(savedSearch, t)}</div>
          <div className="quick-search__filters">{composeSearchFilters(savedSearch)}</div>
          <div />
        </div>
        <Icon name="angle-right" />
      </div>
    </Link>
  );
}

QuickSavedSearch.propTypes = {
  linkTo: PropTypes.string.isRequired,
  savedSearch: PropTypes.shape({
    name: PropTypes.string,
    new_ads_count: PropTypes.number.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
QuickSavedSearch.defaultProps = {
  onClick: () => {},
};

export default QuickSavedSearch;
