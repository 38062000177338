import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  large: PropTypes.bool,
  borderTop: PropTypes.bool,
  highlight: PropTypes.bool,
  textCenter: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  large: false,
  borderTop: false,
  highlight: false,
  textCenter: false,
  className: null,
  children: null,
};

const Section = (props) => {
  const { borderTop, large, highlight, textCenter, className, children } = props;
  const sectionStyle = classNames(
    {
      section: true,
      center: textCenter,
      'section--large': large,
      'section--highlight': highlight,
      'section--highlight-primary': highlight === 'primary',
      'section--highlight-secondary': highlight === 'secondary',
      'section--border-top': borderTop,
    },
    className
  );
  return <section className={sectionStyle}>{children}</section>;
};

Section.propTypes = propTypes;
Section.defaultProps = defaultProps;

export default Section;
