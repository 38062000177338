import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import FancySection from '../ModalSections/FancySection';

import Success from '../../../img/icons/success.svg';
import Button from '../../button/Button';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const defaultProps = {};

const InvoicesSentModal = (props) => {
  const { isOpen, closeModal, t } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <FancySection image={Success} title={t('invoicesSentModal.title')} subtitle={t('invoicesSentModal.subtitle')} />
      <Button primary extended text={t('common.close')} onClick={closeModal} />
    </Modal>
  );
};

InvoicesSentModal.propTypes = propTypes;
InvoicesSentModal.defaultProps = defaultProps;

export default InvoicesSentModal;
