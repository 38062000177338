import React from 'react';
import PropTypes from 'prop-types';
import { List, Collection, Map } from 'immutable';
import classNames from 'classnames';
import ReactGA from '@/utils/ga';
import { createSelector } from 'reselect';
import { Trans } from 'react-i18next';

import { SIZE_UNIT_VALUES } from '@/constants/object';
import { showLuminorBankContent, showSebBankContent } from '@/constants/finance';
import {
  AVAILABLE,
  BALCONY_SIZE,
  BROKERS_FEE,
  COMMUNAL_COSTS,
  DEPOSIT_MONEY,
  FLOOR,
  KITCHEN_AREA,
  RENT_PER_DAY,
  RENT_SUMMER_COMMUNAL_COSTS,
  RENT_WINTER_COMMUNAL_COSTS,
  VAT,
} from '@/constants/attributes';
import { formatPrice, formatDistance, formatDate } from '@/utils/formatting';
import { getPricePerUnitType } from '@/utils/object';

import Button from '@/components/button/Button';
import LuminorTheme from '@/components/finance/LuminorTheme/LuminorTheme';
import {
  defaultLuminorMonthly,
  defaultSebMonthly,
  defaultSebParams,
} from '@/components/finance/Calculators/loanCalculation';
import SebTheme from '@/components/finance/SebTheme/SebTheme';
import LuminorHomeLoanLink from '@/components/finance/Links/LuminorHomeLoanLink';
import LuminorConsumerLoanLink from '@/components/finance/Links/LuminorConsumerLoanLink';
import {
  OBJECT_SPEC_GROUPS,
  OBJECT_SPEC_GROUPS_ORDER,
  OBJECT_SPEC_GROUPS_ORDER_ALT,
  OBJECT_SPEC_GROUPS_ORDER_NP,
  OBJECT_SPEC_GROUPS_ORDER_NP_ALT,
} from '@/constants/object/fullSpecsGroups';

const propTypes = {
  object: PropTypes.instanceOf(Collection.Keyed).isRequired,
  t: PropTypes.func.isRequired,
  altOrder: PropTypes.bool,
  hideBankOffers: PropTypes.bool,
};

const defaultProps = {
  altOrder: false,
  hideBankOffers: false,
};

class FullSpecs extends React.Component {
  constructor(props) {
    super(props);

    // FORMAT SPECS FROM OBJECT
    this.valuesSelector = createSelector(
      (p) => p.object,
      (object) => {
        const valuesList = [
          'id',
          'friendly_id',
          'lot_size',
          'price',
          'price_range',
          'price_per_unit',
          'price_per_unit_range',
          'property_size',
          'room_count',
          'year_built',
          'friendly_id',
        ];

        // if (PORTAL_EE && showLuminorBankContent(object.get('transaction_type'), object.get('unit_type'))) {
        //   valuesList.push('luminor_monthly_payment', 'luminor_consumer_loan');
        // }
        // if (PORTAL_LV && showSebBankContent(object.get('transaction_type'), object.get('unit_type'))) {
        //   valuesList.push('seb_monthly_payment');
        // }

        const addressValuesList = ['cadaster_number', 'estate_register_number', 'distance_from_capital'];
        return Map().withMutations((arr) => {
          valuesList.forEach((key) => {
            if (key === 'price_range') {
              return arr.set(key, `${formatPrice(object.get('price_min'))} - ${formatPrice(object.get('price_max'))}`);
            }
            if (
              key === 'price_per_unit_range' &&
              object.get('price_per_unit_min') > 0 &&
              object.get('price_per_unit_max') > 0
            ) {
              return arr.set(
                key,
                `${formatPrice(object.get('price_per_unit_min'))} - ${formatPrice(object.get('price_per_unit_max'))}`
              );
            }
            return arr.set(key, object.get(key));
          });

          addressValuesList.forEach((key) => {
            arr.set(key, object.getIn(['address', key]));
          });

          if (object.has('attributes')) {
            arr.merge(object.get('attributes'));
          }

          if (object.getIn(['info', 'url'])) {
            arr.set('info', object.get('info'));
          }
        });
      }
    );
  }

  handleSEBLoanCta = () => {
    ReactGA.event({
      category: 'sebMonthlyPaymentHome_objFullDesc',
      action: 'click_getLoanOffer_url',
    });
  };

  getSpecValue = (specType) => {
    const { t } = this.props;
    const arr = this.valuesSelector(this.props);

    if (Array.isArray(specType)) {
      return specType.map((spec) => ({ value: arr.get(spec.value), name: t(spec.name) }));
    }

    // use only one attribute
    if (arr.get('year_built') && arr.get('CONSTRUCTION_YEAR')) {
      if (specType === 'CONSTRUCTION_YEAR') return null;
    }

    return arr.get(specType) || null;
  };

  // CHECK IF SPEC HAS ANY VALUE
  checkValues = (specType) => {
    // if (PORTAL_EE && ['luminor_monthly_payment', 'luminor_consumer_loan'].includes(specType)) {
    //   const { object } = this.props;
    //   return (
    //     showLuminorBankContent(object.get('transaction_type'), object.get('unit_type')) &&
    //     (object.get('price_min') || object.get('price'))
    //   );
    // }
    // if (PORTAL_LV && ['seb_monthly_payment'].includes(specType)) {
    //   const { object } = this.props;
    //   return (
    //     showSebBankContent(object.get('transaction_type'), object.get('unit_type')) &&
    //     (object.get('price_min') || object.get('price'))
    //   );
    // }

    const val = this.getSpecValue(specType);

    if (val) {
      if (List.isList(val) && val.size < 1) {
        return false;
      }
      if (Array.isArray(val)) {
        return val.filter((v) => v.value).length > 0;
      }
      if (val === '') {
        return false;
      }
      return true;
    }
    return false;
  };

  renderSpecs = () => {
    const { t, large, object, altOrder, hideBankOffers } = this.props;

    const pricePerUnitType = getPricePerUnitType(
      object.get('unit_type'),
      object.get('size_unit_id'),
      object.get('lot_size_unit_id')
    );

    let specGroupOrder;
    if (object.get('unit_type') === 'NewProject') {
      specGroupOrder = altOrder ? OBJECT_SPEC_GROUPS_ORDER_NP_ALT : OBJECT_SPEC_GROUPS_ORDER_NP;
    } else {
      specGroupOrder = altOrder ? OBJECT_SPEC_GROUPS_ORDER_ALT : OBJECT_SPEC_GROUPS_ORDER;
    }

    return specGroupOrder
      .map((orderItem) => OBJECT_SPEC_GROUPS.find((specGroup) => specGroup.name === orderItem))
      .map((specGroup) => {
        const hasValues = specGroup.specTypes.filter((specType) => {
          if (
            hideBankOffers &&
            ['luminor_monthly_payment', 'luminor_consumer_loan' /*'seb_monthly_payment'*/].includes(specType.value)
          ) {
            return false;
          }
          return this.checkValues(specType.value);
        });

        if (hasValues.length > 0) {
          return (
            <table key={specGroup.name} className="full-specs">
              <thead>
                <tr>
                  <th colSpan="2">
                    <div className="h5 full-specs__title">{t(specGroup.name)}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {hasValues.map((specType) => {
                  const specValue = this.renderSpecValue(specType.value);
                  if (specValue === null) {
                    return null;
                  }
                  return (
                    <tr key={specType.name}>
                      <td>
                        {pricePerUnitType === SIZE_UNIT_VALUES[5] && specType.name === 'objectSpec.pricePerUnit'
                          ? t(`${specType.name}Ha`)
                          : t(specType.name)}
                      </td>
                      <td className="td--strong">
                        <div
                          className={classNames('full-specs__value', {
                            'full-specs__value-lowercase': ![
                              'objectSpec.energyCertificate',
                              'objectSpec.luminorConsumerLoan',
                              'objectSpec.luminorMonthlyPayment',
                            ].includes(specType.name),
                          })}
                        >
                          {specValue}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        }

        return null;
      });
  };

  renderSpecValue = (specType) => {
    const { object, t, gaEvent, locale } = this.props;
    const totalAreaUnit = SIZE_UNIT_VALUES[object.get('size_unit_id')];
    const lotSizeUnit = SIZE_UNIT_VALUES[object.get('lot_size_unit_id')];
    const arr = this.valuesSelector(this.props);
    const spec = this.getSpecValue(specType);

    switch (specType) {
      case 'FLOOR':
        return arr.get(FLOOR);

      case 'price':
      case [RENT_PER_DAY]:
      case [BROKERS_FEE]:
      case [DEPOSIT_MONEY]:
      case [RENT_SUMMER_COMMUNAL_COSTS]:
      case [RENT_WINTER_COMMUNAL_COSTS]:
        return formatPrice(spec);

      // case 'luminor_monthly_payment':
      //   return (
      //     <LuminorHomeLoanLink
      //       t={t}
      //       price={object.get('price_min') || object.get('price')}
      //       id={object.get('friendly_id')}
      //     />
      //   );
      // case 'luminor_consumer_loan':
      //   return <LuminorConsumerLoanLink t={t} />;
      // case 'seb_monthly_payment':
      //   return (
      //     <SebTheme>
      //       <div>
      //         {`${Math.round(defaultSebMonthly(object.get('price_min') || object.get('price')))} €/${t(
      //           'loanCalc.perMonth'
      //         )}`}
      //       </div>
      //       <Button
      //         link
      //         text={t('luminor.homeLoanCalc.cta')}
      //         href={locale === 'ru-RU' ? defaultSebParams.fullSpecsLink.ru : defaultSebParams.fullSpecsLink.lv}
      //         newTab
      //         onClick={this.handleSEBLoanCta}
      //       />
      //     </SebTheme>
      //   );

      case 'price_per_unit':
        return `${formatPrice(spec)}/${getPricePerUnitType(
          object.get('unit_type'),
          object.get('size_unit_id'),
          object.get('lot_size_unit_id')
        )}`;

      case [KITCHEN_AREA]:
      case [BALCONY_SIZE]:
        return `${Number(spec)} m²`;

      case 'lot_size':
        return `${Number(spec)} ${lotSizeUnit}`;

      case 'property_size':
        return `${Number(spec)} ${totalAreaUnit}`;

      case 'info': {
        const info = arr.get('info');
        return (
          <a
            href={info.get('url')}
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() => {
              if (gaEvent) gaEvent('projectWebsite_link');
            }}
          >
            {info.get('title') || info.get('url')}
          </a>
        );
      }

      case 'distance_from_capital':
        return formatDistance(parseFloat(spec) * 1000);

      case AVAILABLE:
        return spec ? formatDate(spec) : null;

      case [COMMUNAL_COSTS]:
        return t(`${specType}.${spec}`);

      case 'cadaster_number':
        return PORTAL_EE ? (
          <a href={`https://xgis.maaamet.ee/ky/${spec}`} target="_blank" rel="noopener noreferrer">
            {spec}
          </a>
        ) : (
          <>{spec}</>
        );

      case VAT:
        return spec === false || spec === 'no' ? t('objectSpec.vat_excluded') : t('objectSpec.vat_included');

      default: {
        if (List.isList(spec)) {
          return spec.map((specVal) => <div key={specVal}>{t(`${specType}.${specVal}`)}</div>);
        }
        if (Array.isArray(spec)) {
          return spec.map((s) => s.value && <div key={s.name}>{s.name}</div>);
        }
        if (spec === true) {
          return t('common.yes');
        }
        if (spec === false) {
          return t('common.no');
        }
        return spec;
      }
    }
  };

  render() {
    const { large } = this.props;
    const fullSpecsWrapStyle = classNames({
      'full-specs__wrap': true,
      'full-specs__wrap--large': large,
    });

    return <div className={fullSpecsWrapStyle}>{this.renderSpecs()}</div>;
  }
}

FullSpecs.propTypes = propTypes;
FullSpecs.defaultProps = defaultProps;

export default FullSpecs;
