import { LanguageTokens } from '@/types/finance';
import Language from '@city24/common/enums/Language';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';

export const TET_OPTICS_LINK =
  'https://www.tet.lv/?utm_source=city24&utm_medium=text_link&utm_campaign=855195_internets_sity24_jul22';
export const TET_ELECTRICITY_LINK =
  'https://www.tet.lv/elektriba/pakalpojums/?utm_source=city24&utm_medium=text_link&utm_campaign=855194_elektriba_sity24_jul22';
export const TET_INTERNET_LINK =
  'https://www.tet.lv/internets/pieslegumu-veidi/?utm_source=city24&utm_medium=text_link&utm_campaign=855195_internets_sity24_jul22';

export const TELIA_COMMERCIAL_LINK = {
  et: 'https://www.telia.ee/ari/kontor/arimajad?utm_campaign=b2b-city24-2022&utm_medium=link&utm_source=city24&utm_content=arimajad-est',
  ru: 'https://www.telia.ee/ru/ari/kontor/arimajad?utm_campaign=b2b-city24-2022&utm_medium=link&utm_source=city24&utm_content=arimajad-rus',
  en: 'https://www.telia.ee/ari/kontor/arimajad?utm_campaign=b2b-city24-2022&utm_medium=link&utm_source=city24&utm_content=arimajad-eng',
};
export const TELIA_HOME_LINK = {
  et: 'https://www.telia.ee/era/koduinternet?utm_campaign=b2c-internet-aom-2023&utm_medium=textlink&utm_source=city24&utm_content=textlink-est',
  ru: 'https://www.telia.ee/ru/era/koduinternet?utm_campaign=b2c-internet-aom-2023&utm_medium=textlink&utm_source=city24&utm_content=textlink-rus',
  en: 'https://www.telia.ee/era/koduinternet?utm_campaign=b2c-internet-aom-2023&utm_medium=textlink&utm_source=city24&utm_content=textlink-est',
};

export const ESTO_LINK = {
  et: 'https://esto.eu/ee/kliendile/vaikelaen?lang=et&utm_source=city24&utm_medium=product-placement&utm_campaign=rental-small-loan',
  ru: 'https://esto.eu/ee/kliendile/vaikelaen?lang=ru&utm_source=city24&utm_medium=product-placement&utm_campaign=rental-small-loan',
  en: 'https://esto.eu/ee/kliendile/vaikelaen?lang=en&utm_source=city24&utm_medium=product-placement&utm_campaign=rental-small-loan',
};

export const ALEXELA_BUSINESS_LINK = {
  et: 'https://www.alexela.ee/et/arikliendi-elektripaketid?utm_source=city24&utm_medium=cpc&utm_campaign=elekter_arikliendile',
  ru: 'https://www.alexela.ee/ru/pakety-elektroenergii-dlya-biznes-klientov?utm_source=city24&utm_medium=cpc&utm_campaign=elekter_arikliendile',
  en: 'https://www.alexela.ee/en/pakety-elektroenergii-dlya-biznes-klientov?utm_source=city24&utm_medium=cpc&utm_campaign=elekter_arikliendile',
};
export const ALEXELA_PRIVATE_LINK = {
  et: 'https://www.alexela.ee/et/elekter?utm_source=city24&utm_medium=cpc&utm_campaign=elekter_kodutarbijale',
  ru: 'https://www.alexela.ee/ru/elekter?utm_source=city24&utm_medium=cpc&utm_campaign=elekter_kodutarbijale',
  en: 'https://www.alexela.ee/en/elekter?utm_source=city24&utm_medium=cpc&utm_campaign=elekter_kodutarbijale',
};

export const ELEKTRUM_ELECTRICITY_LINKS: LanguageTokens | null = PORTAL_EE
  ? null
  : {
      lv: 'https://track.adform.net/C/?bn=60743257',
      ru: 'https://track.adform.net/C/?bn=60743260',
      en: 'https://track.adform.net/C/?bn=60743256',
    };
export const ELEKTRUM_NG_LINKS: LanguageTokens | null = PORTAL_EE
  ? null
  : {
      lv: 'https://track.adform.net/C/?bn=60743255',
      ru: 'https://track.adform.net/C/?bn=60743248',
      en: 'https://track.adform.net/C/?bn=60743251',
    };
export const ALTERO_LINKS: LanguageTokens | null = PORTAL_EE
  ? null
  : {
      lv: 'https://www.altero.lv/lv/majokla-apdrosinasana?utm_source=city24&utm_medium=integration&utm_campaign=insurance',
      ru: 'https://www.altero.lv/ru/strahovanie-imushestva?utm_source=city24&utm_medium=integration&utm_campaign=insurance&utm_content=ru',
      en: 'https://www.altero.lv/lv/majokla-apdrosinasana?utm_source=city24&utm_medium=integration&utm_campaign=insurance',
    };
export const ALTERO_LOAN_LINKS: LanguageTokens | null = PORTAL_EE
  ? null
  : {
      lv: 'https://www.altero.lv/lv/finansejums-uznemumiem/?utm_source=city24&utm_medium=integration&utm_campaign=SME',
      ru: 'https://www.altero.lv/lv/finansejums-uznemumiem/?utm_source=city24&utm_medium=integration&utm_campaign=SME',
      en: 'https://www.altero.lv/lv/finansejums-uznemumiem/?utm_source=city24&utm_medium=integration&utm_campaign=SME',
    };

export const INBANK_OFFER_LINKS: LanguageTokens | null = PORTAL_EE
  ? null
  : {
      lv: 'https://d8fj.short.gy/inbank_textlink_lv',
      en: 'https://d8fj.short.gy/inbank_textlink_en',
      ru: 'https://d8fj.short.gy/inbank_textlink_ru',
    };

export const GET_A_PRO_LINKS: { [key: string]: string[] } = PORTAL_EE
  ? {}
  : {
      lv: [
        'https://getapro.lv/category/dzivoklu-remonts?utm_source=city&utm_medium=top_link',
        'https://getapro.lv/category/telpu-uzkopsana?utm_source=city&utm_medium=top_link',
        'https://getapro.lv/category/mebelu-parvadajumi?utm_source=city&utm_medium=top_link', // do not change order unless adjusted in getGetAProVariant selector
        'https://getapro.lv/category/dizainera-pakalpojumi?utm_source=city&utm_medium=top_link',
        'https://getapro.lv/category/arhitekta-pakalpojumi?utm_source=city&utm_medium=top_link',
      ],
      ru: [
        'https://getapro.lv/ru/category/remont-kvartiri?utm_source=city&utm_medium=top_link',
        'https://getapro.lv/ru/category/uborka-pomeshhenij?utm_source=city&utm_medium=top_link',
        'https://getapro.lv/ru/category/perevozka-mebeli?utm_source=city&utm_medium=top_link', // do not change order unless adjusted in getGetAProVariant selector
        'https://getapro.lv/ru/category/uslugi-dizajnera?utm_source=city&utm_medium=top_link',
        'https://getapro.lv/ru/category/uslugi-arhitektora?utm_source=city&utm_medium=top_link',
      ],
      en: [
        'https://getapro.lv/category/dzivoklu-remonts?utm_source=city&utm_medium=top_link',
        'https://getapro.lv/category/telpu-uzkopsana?utm_source=city&utm_medium=top_link',
        'https://getapro.lv/category/mebelu-parvadajumi?utm_source=city&utm_medium=top_link', // do not change order unless adjusted in getGetAProVariant selector
        'https://getapro.lv/category/dizainera-pakalpojumi?utm_source=city&utm_medium=top_link',
        'https://getapro.lv/category/arhitekta-pakalpojumi?utm_source=city&utm_medium=top_link',
      ],
    };

// links in object details after FullSpecs
export const GET_A_PRO_FS_LINKS: Readonly<{ [key in Language]?: { label: string; link: string }[] }> | null = PORTAL_EE
  ? null
  : {
      [Language.lv]: [
        {
          label: 'cleaning',
          link: 'https://getapro.lv/category/telpu-uzkopsana?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'refurbishment',
          link: 'https://getapro.lv/category/dzivoklu-remonts?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'transportation',
          link: 'https://getapro.lv/category/mebelu-parvadajumi?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'architect',
          link: 'https://getapro.lv/category/arhitekta-pakalpojumi?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'design',
          link: 'https://getapro.lv/category/dizainera-pakalpojumi?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'legal',
          link: 'https://getapro.lv/category/juridiskie-pakalpojumi?utm_source=city&utm_medium=bottom_link',
        },
      ],
      [Language.ru]: [
        {
          label: 'cleaning',
          link: 'https://getapro.lv/ru/category/uborka-pomeshhenij?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'refurbishment',
          link: 'https://getapro.lv/ru/category/remont-kvartiri?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'transportation',
          link: 'https://getapro.lv/ru/category/perevozka-mebeli?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'architect',
          link: 'https://getapro.lv/ru/category/uslugi-arhitektora?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'design',
          link: 'https://getapro.lv/ru/category/uslugi-dizajnera?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'legal',
          link: 'https://getapro.lv/ru/category/juridicheskie-uslugi?utm_source=city&utm_medium=bottom_link',
        },
      ],
      [Language.en]: [
        {
          label: 'cleaning',
          link: 'https://getapro.lv/category/telpu-uzkopsana?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'refurbishment',
          link: 'https://getapro.lv/category/dzivoklu-remonts?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'transportation',
          link: 'https://getapro.lv/category/mebelu-parvadajumi?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'architect',
          link: 'https://getapro.lv/category/arhitekta-pakalpojumi?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'design',
          link: 'https://getapro.lv/category/dizainera-pakalpojumi?utm_source=city&utm_medium=bottom_link',
        },
        {
          label: 'legal',
          link: 'https://getapro.lv/category/juridiskie-pakalpojumi?utm_source=city&utm_medium=bottom_link',
        },
      ],
    };

export function getKveeURL(): string {
  if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
    return 'https://dev.kv.ee';
  }
  if (process.env.NEXT_PUBLIC_APP_ENV === 'beta') {
    return 'https://beta.kv.ee';
  }
  return 'https://www.kv.ee';
}

export const CENU_BANKA_TAB_ORDER = [
  'NUMBER_OF_DEALS',
  'SQM_AVERAGE_PRICE',
  'AVERAGE_DEAL_AMOUNT',
  'DEALS_BY_FLOOR_NUMBER',
  'DEALS_BY_NUMBER_OF_ROOMS',
];

export function showEstoOffer(objectType: UnitTypeLiteral): boolean {
  return [
    UnitTypeLiteral.APARTMENT,
    UnitTypeLiteral.HOUSE,
    UnitTypeLiteral.HOUSE_SHARE,
    UnitTypeLiteral.COTTAGE_OR_VILLA,
  ].includes(objectType);
}
