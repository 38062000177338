import React, { useContext } from 'react';
import { TFunction } from 'i18next';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import ReactGA from '@/utils/ga';

import { useAppDispatch } from '@/hooks';
import { openModal } from '@/components/modals/modalReducer';
import { ModalName } from '@/components/modals/modalInterfaces';
import MultiBankCalculatorContext from '@/components/finance/Calculators/MultiBankCalculator/context';

type Props = {
  t: TFunction;
  monthlyLoanPayment: number | null;
  objectType: UnitTypeLiteral;
  price: number;
};

function ObjectLoanLabel({ t, monthlyLoanPayment, objectType, price }: Props): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const { yearBuilt } = useContext(MultiBankCalculatorContext);
  const linkWrapper = (children: React.ReactNode) => (
    <button
      type="button"
      className="object__monthly-payment-wrap"
      onClick={(e) => {
        dispatch(openModal({ name: ModalName.MultiBank, price, unitType: objectType, yearBuilt }));
        ReactGA.event({
          category: 'sebMonthlyPaymentHome_searchResults',
          action: 'click_monthlyPayment_url',
        });
        e.stopPropagation();
      }}
    >
      {children}
    </button>
  );

  return monthlyLoanPayment && Number.isFinite(monthlyLoanPayment)
    ? linkWrapper(
        <div className="object__monthly-payment">{`${t('loanCalc.monthlyPaymentFrom')} ${monthlyLoanPayment} €`}</div>
      )
    : null;
}

export default ObjectLoanLabel;
