import { TRANSACTION_TYPES } from '@/constants/object';

export const getAppliedFilters = (searchFilters, initialState) => {
  let count = 0;
  const i = initialState;
  const {
    searchTerm,
    minPrice,
    maxPrice,
    minSize,
    maxSize,
    minLotSize,
    maxLotSize,
    minFloor,
    maxFloor,
    minYearBuilt,
    maxYearBuilt,
    onlyLastFloor,
    rooms,
    fromOwner,
    condition,
    material,
    extras,
    projectType,
    purpose,
    virtualTour,
    openHouse,
    specialOffers,
    auction,
    energyCertificate,
    pricedrop,
    dateAdded,
  } = searchFilters;

  if (searchTerm.length) {
    count += 1;
  }

  if (minPrice !== '' || maxPrice !== '') {
    count += 1;
  }

  if (minSize !== '' || maxSize !== '') {
    count += 1;
  }

  if (minLotSize !== '' || maxLotSize !== '') {
    count += 1;
  }

  if (minFloor !== '' || maxFloor !== '' || onlyLastFloor !== i.get('onlyLastFloor')) {
    count += 1;
  }

  if (minYearBuilt !== '' || maxYearBuilt !== '') {
    count += 1;
  }

  if (rooms !== i.get('rooms')) {
    count += 1;
  }

  if (fromOwner !== i.get('fromOwner')) {
    count += 1;
  }

  if (!condition.equals(i.get('condition'))) {
    count += 1;
  }

  if (!material.equals(i.get('material'))) {
    count += 1;
  }

  if (!energyCertificate.equals(i.get('energyCertificate'))) {
    count += 1;
  }

  if (purpose && !purpose.equals(i.get('purpose'))) {
    count += 1;
  }

  if (extras && !extras.equals(i.get('extras'))) {
    count += 1;
  }

  if (!projectType.equals(i.get('projectType'))) {
    count += 1;
  }

  if (virtualTour !== i.get('virtualTour')) {
    count += 1;
  }

  if (openHouse !== i.get('openHouse')) {
    count += 1;
  }

  if (specialOffers !== i.get('specialOffers')) {
    count += 1;
  }

  if (auction !== i.get('auction')) {
    count += 1;
  }

  if (pricedrop !== i.get('pricedrop')) {
    count += 1;
  }

  if (!dateAdded.equals(i.get('dateAdded'))) {
    count += 1;
  }

  return count;
};

/**
 * Create filter list from statistics object.
 * @param {Object} stats - statistics object. Key is unit type and value is count.
 */
export function generateFiltersFromStats(stats) {
  if (!stats) {
    return [];
  }
  const keys = Object.keys(stats);

  if (keys.length === 0) {
    return [];
  }

  return keys.map((key) => {
    const count = stats[key];
    return {
      name: `unitType.${key}`,
      value: key,
      count,
    };
  });
}

export function createStatsFilters(stats) {
  const { SALE, RENT } = TRANSACTION_TYPES;
  const firstOption = {
    value: 'all',
    name: 'common.all',
  };
  return {
    [SALE]: [firstOption, ...generateFiltersFromStats(stats[SALE])],
    [RENT]: [firstOption, ...generateFiltersFromStats(stats[RENT])],
  };
}
