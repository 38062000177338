import { createSelector, createStructuredSelector, defaultMemoize } from 'reselect';

import { RootState } from '@/store';
import { getFilters } from '@/constants/filters';
import { isAddressTerm } from '@/utils/searchTerms';
import { addFavouriteToObject, combineLocationString } from '@/utils/helpers';

import { getBrowser } from '@/selectors/appSelectors';
import { getFavourites } from '@/selectors/userSelectors';
import { OBJECT_TYPE_OPTIONS, OBJECT_TYPE_OPTION_PROJECT } from '@/constants/attributes';
import { AsyncDataStatus, createAsyncData } from '@/types/collections';

const getSearchView = (state: RootState) => state.search.view;
const getSearchForm = (state: RootState) => state.search.form;
const getSearchResults = (state: RootState) => state.search.results;

export const getSearchResultObjects = createSelector(
  (state: RootState) => getSearchResults(state).objects,
  getFavourites,
  (objects, favourites) => objects.map((object) => addFavouriteToObject(favourites, object))
);

export const getLinkedObjects = (id: string) => (state: RootState) =>
  getSearchResults(state).linkedObjects[id] || createAsyncData([], AsyncDataStatus.None);

export const getSearchPrevNext = (state: RootState) => getSearchResults(state).prevNext;

export const getTransactionType = (state: RootState) => getSearchForm(state).get('transactionType');
export const getObjectType = (state: RootState) => getSearchForm(state).get('objectType');
export const getSearchTerm = (state: RootState) => getSearchForm(state).get('searchTerm');
export const getSubObjectTypes = (state: RootState) => getSearchForm(state).get('subObjectTypes');
export const getConstructionPhase = (state: RootState) => getSearchForm(state).get('constructionPhase');
export const getSearchTermDraft = (state: RootState) => getSearchForm(state).get('searchTermDraft');
export const getMinPrice = (state: RootState) => getSearchForm(state).get('minPrice');
export const getMaxPrice = (state: RootState) => getSearchForm(state).get('maxPrice');
export const getMinSize = (state: RootState) => getSearchForm(state).get('minSize');
export const getMaxSize = (state: RootState) => getSearchForm(state).get('maxSize');
export const getMinLotSize = (state: RootState) => getSearchForm(state).get('minLotSize');
export const getMaxLotSize = (state: RootState) => getSearchForm(state).get('maxLotSize');
export const getLotSizeType = (state: RootState) => getSearchForm(state).get('lotSizeType');
export const getPriceType = (state: RootState) => getSearchForm(state).get('priceType');
export const getMinFloor = (state: RootState) => getSearchForm(state).get('minFloor');
export const getMaxFloor = (state: RootState) => getSearchForm(state).get('maxFloor');
export const getOnlyLastFloor = (state: RootState) => getSearchForm(state).get('onlyLastFloor');
export const getMinYearBuilt = (state: RootState) => getSearchForm(state).get('minYearBuilt');
export const getMaxYearBuilt = (state: RootState) => getSearchForm(state).get('maxYearBuilt');
export const getEnergyCertificate = (state: RootState) => getSearchForm(state).get('energyCertificate');
export const getRooms = (state: RootState) => getSearchForm(state).get('rooms');
export const getFromOwner = (state: RootState) => getSearchForm(state).get('fromOwner');
export const getCondition = (state: RootState) => getSearchForm(state).get('condition');
export const getMaterial = (state: RootState) => getSearchForm(state).get('material');
export const getExtras = (state: RootState) => getSearchForm(state).get('extras');
export const getProjectType = (state: RootState) => getSearchForm(state).get('projectType');
export const getPurpose = (state: RootState) => getSearchForm(state).get('purpose');
// export const getAdditional = state => getSearchForm(state).get('additional');
export const getVirtualTour = (state: RootState) => getSearchForm(state).get('virtualTour');
export const getOpenHouse = (state: RootState) => getSearchForm(state).get('openHouse');
export const getSpecialOffers = (state: RootState) => getSearchForm(state).get('specialOffers');
export const getAuction = (state: RootState) => getSearchForm(state).get('auction');
export const getPricedrop = (state: RootState) => getSearchForm(state).get('pricedrop');
export const getDateAdded = (state: RootState) => getSearchForm(state).get('dateAdded');
export const getFilterCount = (state: RootState) => getSearchForm(state).get('filterCount');
export const getLastRun = (state: RootState) => getSearchForm(state).get('lastRun');
export const getRentToOwn = (state: RootState) => getSearchForm(state).get('rentToOwn');
export const getSearchParamsURL = (state: RootState) => getSearchForm(state).get('searchParamsURL');

export const getFiltersByObjectType = createSelector(getObjectType, (objectType) =>
  getFilters(objectType.get('value'))
);
export const getFiltersToRender = createSelector(getBrowser, getFiltersByObjectType, (browser, filters) => {
  const filtersToRender = filters.slice();
  const additionalFilter = filtersToRender.pop();

  if (PORTAL_LV) {
    return [
      ...filtersToRender.filter((i) =>
        ['purpose', 'price', 'size', 'lotSize', 'rooms', 'floor', 'fromOwner', 'projectType'].includes(i)
      ),
      additionalFilter,
    ];
  }

  const amountToShow = (() => {
    if (browser.is.medium) {
      return 6;
    }
    if (browser.is.large) {
      return 8;
    }
    return Math.min(filtersToRender.length + 1, 10);
  })();

  return [...filtersToRender.slice(0, amountToShow), additionalFilter];
});
export const getAdditionalFilters = createSelector(
  getFiltersByObjectType,
  getFiltersToRender,
  (filters, filtersToRender) => filters.filter((filter) => !filtersToRender.includes(filter))
);

export const getResultLayout = createSelector(
  (state: RootState) => getBrowser(state).lessThan.large,
  getSearchView,
  (mobView, view) => {
    if (mobView) {
      return view.mobileLayout;
    }
    return view.layout;
  }
);
export const getSorting = (state: RootState) => getSearchResults(state).sorting;
export const getSearchPagination = (state: RootState) => getSearchResults(state).pagination;
export const getObjectResultsTotal = (state: RootState) => getSearchPagination(state).total;
export const getObjectResultsType = (state: RootState) => getSearchResults(state).type;
export const getObjectResultsToShow = (state: RootState) => getSearchResults(state).objectsToShow;
export const getObjectResultsPage = (state: RootState) => getSearchResults(state).objectsPage;
export const getLastSearchQuery = (state: RootState) => getSearchResults(state).lastQuery;
export const getLastSearchFilters = (state: RootState) => getSearchResults(state).searchFilters;
export const getResultsLoading = (state: RootState) => getSearchResults(state).resultsLoading;
export const getMapOpened = (state: RootState) => getSearchResults(state).mapOpened;

export const getAutocompleteResults = (state: RootState) => getSearchResults(state).autocompleteResults;
export const getAutocompleteAddressTerms = createSelector(getSearchTerm, (terms) =>
  terms.filter((v) => isAddressTerm(v.value))
);

interface SearchFilters {
  transactionType: ReturnType<typeof getTransactionType>;
  objectType: ReturnType<typeof getObjectType>;
  subObjectTypes: ReturnType<typeof getSubObjectTypes>;
  constructionPhase: ReturnType<typeof getConstructionPhase>;
  searchTerm: ReturnType<typeof getSearchTerm>;
  minPrice: ReturnType<typeof getMinPrice>;
  maxPrice: ReturnType<typeof getMaxPrice>;
  minSize: ReturnType<typeof getMinSize>;
  maxSize: ReturnType<typeof getMaxSize>;
  minLotSize: ReturnType<typeof getMinLotSize>;
  maxLotSize: ReturnType<typeof getMaxLotSize>;
  lotSizeType: ReturnType<typeof getLotSizeType>;
  priceType: ReturnType<typeof getPriceType>;
  minFloor: ReturnType<typeof getMinFloor>;
  maxFloor: ReturnType<typeof getMaxFloor>;
  minYearBuilt: ReturnType<typeof getMinYearBuilt>;
  maxYearBuilt: ReturnType<typeof getMaxYearBuilt>;
  onlyLastFloor: ReturnType<typeof getOnlyLastFloor>;
  energyCertificate: ReturnType<typeof getEnergyCertificate>;
  rooms: ReturnType<typeof getRooms>;
  fromOwner: ReturnType<typeof getFromOwner>;
  condition: ReturnType<typeof getCondition>;
  material: ReturnType<typeof getMaterial>;
  extras: ReturnType<typeof getExtras>;
  projectType: ReturnType<typeof getProjectType>;
  purpose: ReturnType<typeof getPurpose>;
  dateAdded: ReturnType<typeof getDateAdded>;
  sorting: ReturnType<typeof getSorting>;
  // additional: ReturnType<typeof getAdditional>,
  virtualTour: ReturnType<typeof getVirtualTour>;
  openHouse: ReturnType<typeof getOpenHouse>;
  specialOffers: ReturnType<typeof getSpecialOffers>;
  auction: ReturnType<typeof getAuction>;
  pricedrop: ReturnType<typeof getPricedrop>;
  objectResultsToShow: ReturnType<typeof getObjectResultsToShow>;
  lastRun: ReturnType<typeof getLastRun>;
  rentToOwn: ReturnType<typeof getRentToOwn>;
}

export const getSearchFilters = createStructuredSelector<RootState, SearchFilters>({
  transactionType: getTransactionType,
  objectType: getObjectType,
  subObjectTypes: getSubObjectTypes,
  constructionPhase: getConstructionPhase,
  searchTerm: getSearchTerm,
  minPrice: getMinPrice,
  maxPrice: getMaxPrice,
  minSize: getMinSize,
  maxSize: getMaxSize,
  minLotSize: getMinLotSize,
  maxLotSize: getMaxLotSize,
  lotSizeType: getLotSizeType,
  priceType: getPriceType,
  minFloor: getMinFloor,
  maxFloor: getMaxFloor,
  minYearBuilt: getMinYearBuilt,
  maxYearBuilt: getMaxYearBuilt,
  onlyLastFloor: getOnlyLastFloor,
  energyCertificate: getEnergyCertificate,
  rooms: getRooms,
  fromOwner: getFromOwner,
  condition: getCondition,
  material: getMaterial,
  extras: getExtras,
  projectType: getProjectType,
  purpose: getPurpose,
  dateAdded: getDateAdded,
  sorting: getSorting,
  // additional: getAdditional,
  virtualTour: getVirtualTour,
  openHouse: getOpenHouse,
  specialOffers: getSpecialOffers,
  auction: getAuction,
  pricedrop: getPricedrop,
  objectResultsToShow: getObjectResultsToShow,
  lastRun: getLastRun,
  rentToOwn: getRentToOwn,
});

export const getObjectTypeOptions = defaultMemoize((showProject: boolean) => {
  if (!showProject) {
    return OBJECT_TYPE_OPTIONS;
  }
  if (PORTAL_EE) {
    return OBJECT_TYPE_OPTIONS.unshift(OBJECT_TYPE_OPTION_PROJECT);
  }
  return OBJECT_TYPE_OPTIONS.insert(-1, OBJECT_TYPE_OPTION_PROJECT);
});

export const getResultsMetaInfo = createSelector(getSearchTerm, (searchTerms) => {
  const locationItems = combineLocationString(searchTerms, ', ');
  const location = [];
  locationItems.forEach((loc) => {
    location.push(...loc.toLowerCase().split(', '));
  });
  return {
    location,
    locationNames: combineLocationString(searchTerms, ', ', true).join(', '),
  };
});
