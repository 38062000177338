import React, { useMemo } from 'react';
import classNames from 'classnames';
import type { TFunction } from 'next-i18next';
import { formatDate } from '@city24/common/utils/date';
import { orderedPhaseOfConstruction } from '@city24/common/enums/realty/attribute/index';
import { isImmutable } from 'immutable';
import UnitAttributes from '@city24/common/types/UnitAttributes';
import { SelectionAttribute } from '@city24/common/enums/realty/Attribute';

import css from './Timeline.module.scss';

interface Props {
  t: TFunction;
  items: UnitAttributes[SelectionAttribute.PHASE_OF_CONSTRUCTION];
}

function Timeline({ items: rawItems, t }: Props) {
  const orderedItems = useMemo(() => {
    // TODO: Remove ImmutableJS check if detailed view SSR is released
    const x = isImmutable(rawItems)
      ? (rawItems.toJS() as UnitAttributes[SelectionAttribute.PHASE_OF_CONSTRUCTION])
      : rawItems;
    return orderedPhaseOfConstruction().map((key) => [key, x[key]] as const);
  }, [rawItems]);

  let lastActive = -1;
  return (
    <div className={css.wrap}>
      <ul className={css.list}>
        {orderedItems.map(([key, item], index) => {
          let optionalClass = '';
          if (item?.active) {
            optionalClass = css.past;
            lastActive = index;
          } else if (lastActive === index - 1) {
            optionalClass = css.current;
          }

          return (
            <li key={key} className={classNames(css.item, optionalClass)}>
              <span className={css.bullet} />
              <span>
                <span className={css.name}>{t(`PHASE_OF_CONSTRUCTION.${key}`)}</span>
                {item?.date && <span className={css.extra}>{formatDate(item.date, 'date')}</span>}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Timeline;
