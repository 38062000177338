import React from 'react';
import { isIOS } from 'react-device-detect';
import ReactGA from '@/utils/ga';
import { TFunction } from 'next-i18next';

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  ViberShareButton,
  EmailShareButton,
} from 'react-share';
import { useAppDispatch, useAppSelector, useModalState } from '@/hooks';
import { GA_SHARE_AD } from '@/constants/ga';
import { getBrowser } from '@/selectors/appSelectors';

import Button from '@/components/button/Button';
import ButtonGroup from '@/components/button/ButtonGroup';
import CopyURL from '@/components/CopyURL/CopyURL';
import Icon from '@/components/icon/Icon';

import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import { ModalName, ShareModalState } from '../modalInterfaces';
import { closeModal } from '../modalReducer';
import css from './ShareModal.module.scss';

interface Props {
  t: TFunction;
}

function ShareModal({ t }: Props) {
  const dispatch = useAppDispatch();
  const browser = useAppSelector(getBrowser);
  const modal = useModalState<ShareModalState>(ModalName.Share);
  const { id, isRealtyShare, sharePath: url } = modal.state;
  const gaEvent = (action: string, label?: string) => {
    ReactGA.event({
      category: GA_SHARE_AD,
      action,
      label,
    });
  };

  const closeShareModal = () => dispatch(closeModal());
  const onClose = () => {
    gaEvent('close_button');
    closeShareModal();
  };

  return (
    <Modal isOpen={modal.open} onRequestClose={onClose}>
      <ModalHeader title={t('shareModal.title')} />
      <ul className={css.list}>
        <li>
          <CopyURL url={url} gaEvent={() => gaEvent('click_copyLink')} onClick={closeShareModal}>
            <span className={`${css.icon} ${css.copy}`}>
              <Icon name="copy" />
            </span>
            <span className={css.label}>{t('copyLink.copyLink')}</span>
          </CopyURL>
        </li>
        {isRealtyShare && (
          <>
            <li>
              <EmailShareButton
                url={url}
                subject={`${t('shareModal.email.subject')} (id:${id})`}
                body={t('shareModal.email.body')}
                beforeOnClick={() => gaEvent('click_email')}
              >
                <span className={`${css.icon} ${css.email}`}>
                  <Icon name="envelope" />
                </span>
                <span className={css.label}>{t('common.email')}</span>
              </EmailShareButton>
            </li>
            <li>
              <a
                href={`sms:${isIOS ? '&' : '?'}&body=${t('shareModal.sms.body')} ${url}`}
                className={css.label}
                onClick={() => gaEvent('click_sms')}
              >
                <span className={`${css.icon} ${css.sms}`}>
                  <Icon name="phone" />
                </span>
                <span className={css.label}>SMS</span>
              </a>
            </li>
          </>
        )}
        <li>
          <FacebookShareButton url={url}>
            <div role="button" onClick={() => gaEvent('click_facebook')}>
              <span className={`${css.icon} ${css.facebook}`}>
                <Icon name="facebook" />
              </span>
              <span className={css.label}>Facebook</span>
            </div>
          </FacebookShareButton>
        </li>
        {isRealtyShare && (
          <>
            {browser.lessThan.medium && (
              <li>
                <a
                  href={`fb-messenger://share/?link=${url}&app_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}`}
                  onClick={() => gaEvent('click_facebook')}
                >
                  <span className={`${css.icon} ${css.messenger}`}>
                    <Icon name="messenger" />
                  </span>
                  <span className={css.label}>Messenger</span>
                </a>
              </li>
            )}
            <li>
              <WhatsappShareButton url={url} title={t('shareModal.whatsapp.title')}>
                <div role="button" onClick={() => gaEvent('click_whatsApp')}>
                  <span className={`${css.icon} ${css.whatsapp}`}>
                    <Icon name="whatsapp" />
                  </span>
                  <span className={css.label}>WhatsApp</span>
                </div>
              </WhatsappShareButton>
            </li>
            <li>
              <ViberShareButton url={url} title={t('shareModal.viber.title')}>
                <div role="button" onClick={() => gaEvent('click_viber')}>
                  <span className={`${css.icon} ${css.viber}`}>
                    <Icon name="viber" />
                  </span>
                  <span className={css.label}>Viber</span>
                </div>
              </ViberShareButton>
            </li>
            <li>
              <TwitterShareButton url={url} title={t('shareModal.twitter.title')}>
                <div role="button" onClick={() => gaEvent('click_twitter')}>
                  <span className={`${css.icon} ${css.twitter}`}>
                    <Icon name="twitter" />
                  </span>
                  <span className={css.label}>Twitter</span>
                </div>
              </TwitterShareButton>
            </li>
          </>
        )}
      </ul>
      <ModalFooter>
        <ButtonGroup>
          <Button transparent text={t('common.cancel')} onClick={onClose} />
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
}

export default ShareModal;
