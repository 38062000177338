import api, { paginatedQuery, parsePagination } from '@/api/City24Api';
import { PORTAL_NAME } from '@/constants';
import { HAS_OPEN_DAYS } from '@/constants/attributes';
import { GET_MAIN_PAGE_OBJECTS, GET_MAIN_PAGE_CAMPAIGN, GET_MAIN_PAGE_CAMPAIGNS_INFO } from '@/constants/actions';
import { OBJECT_PLACEMENTS, OBJECT_TYPES, TRANSACTION_TYPES } from '@/constants/object';
import { DataLoadProxy } from '@/utils/collections';
import { shuffleArray } from '@/utils/helpers';
import Portal from '@city24/common/enums/Portal';

const commonQuery = {
  /**
   * @ToDo: For some reason PORTAL is undefined at this stage. Investigate and fix.
   */
  // address: { cc: PORTAL },
  address: { cc: PORTAL_LV ? Portal.City24LV : Portal.City24EE },
};

export function fetchMainCampaignInfo() {
  return (dispatch) => {
    return api
      .getMainCampaignsInfo({
        active: true,
        g: ['minimal'],
        portal: PORTAL,
      })
      .then((res) => (res.ok ? res.json() : []))
      .then((campaigns) => {
        return dispatch({
          type: GET_MAIN_PAGE_CAMPAIGNS_INFO,
          campaigns,
        });
      });
  };
}
/**
 *
 * @param {string} sectionType
 * @param {DataLoadProxy} objects
 */
function getMainPageCampaign(sectionType, objects) {
  return {
    type: GET_MAIN_PAGE_CAMPAIGN,
    sectionType,
    objects,
    total: objects.data.length,
  };
}

function fetchCampaignOffers(dispatch, sectionType, action) {
  dispatch(getMainPageCampaign(sectionType, DataLoadProxy.loading([])));
  return action
    .then((res) => (res.ok ? res.json() : []))
    .then((objects) => {
      return dispatch(getMainPageCampaign(sectionType, DataLoadProxy.loaded(shuffleArray(objects))));
    });
}

export function fetchHotOffers() {
  return (dispatch) => {
    return fetchCampaignOffers(dispatch, 'hotOffers', api.getCampaignOffers('hot', { portalName: PORTAL_NAME }));
  };
}

export function fetchModularHouseOffers() {
  return (dispatch) => {
    return fetchCampaignOffers(
      dispatch,
      'modularHouseOffers',
      api.getCampaignOffers('modhouse', { portalName: PORTAL_NAME })
    );
  };
}

export function fetchOpenHouseOffers() {
  return (dispatch) => {
    return fetchCampaignOffers(
      dispatch,
      'openHouseOffers',
      api.getCampaignOffers('openhouse', { portalName: PORTAL_NAME })
    );
  };
}

export function fetchProjectsOffers() {
  return (dispatch) => {
    return fetchCampaignOffers(
      dispatch,
      'projectsOffers',
      api.getCampaignOffers('projects', { portalName: PORTAL_NAME })
    );
  };
}

export function fetchHotSpecial() {
  return (dispatch) => {
    return fetchCampaignOffers(
      dispatch,
      'hotspecial',
      api.getCampaignOffers('hotspecial', { portalName: PORTAL_NAME })
    );
  };
}

export function fetchTopOffers() {
  return (dispatch) => {
    return api
      .getObjectsByPlacement(OBJECT_PLACEMENTS.TOP_OFFERS, commonQuery)
      .then((res) => (res.ok ? Promise.all([res.json(), parsePagination(res)]) : [[], {}]))
      .then(([objects, pagination]) => {
        return dispatch({
          type: GET_MAIN_PAGE_OBJECTS,
          sectionType: 'topOffers',
          objects,
          total: pagination.total || 0,
        });
      });
  };
}

export function fetchSpecialOffers() {
  return (dispatch) => {
    return api
      .getSpecialOffers(commonQuery)
      .then((res) => (res.ok ? Promise.all([res.json(), parsePagination(res)]) : [[], {}]))
      .then(([objects, pagination]) => {
        return dispatch({
          type: GET_MAIN_PAGE_OBJECTS,
          sectionType: 'specialOffers',
          objects,
          total: pagination.total || 0,
        });
      });
  };
}

export function fetchHotOffersHousesAndLandsOffers() {
  return (dispatch) => {
    dispatch(getMainPageCampaign('hotOffersHousesAndLands', DataLoadProxy.loading([])));
    const promises = [
      api
        .getObjectsByPlacement(`${OBJECT_PLACEMENTS.HOT_OFFERS_HOUSE_LAND}_1`)
        .then((res) => (res.ok ? res.json() : [])),
      api
        .getObjectsByPlacement(`${OBJECT_PLACEMENTS.HOT_OFFERS_HOUSE_LAND}_2`)
        .then((res) => (res.ok ? res.json() : [])),
      api
        .getObjectsByPlacement(`${OBJECT_PLACEMENTS.HOT_OFFERS_HOUSE_LAND}_3`)
        .then((res) => (res.ok ? res.json() : [])),
    ];
    return Promise.all(promises).then(([block_1, block_2, block_3]) => {
      const objects = [...block_1, ...block_2, ...block_3];
      return dispatch(getMainPageCampaign('hotOffersHousesAndLands', DataLoadProxy.loaded(shuffleArray(objects))));
    });
  };
}

export function fetchMainPageProjects(itemsPerPage) {
  return (dispatch) => {
    Promise.all([
      api.getObjectsByPlacement(OBJECT_PLACEMENTS.TOP_PROJECTS, paginatedQuery(itemsPerPage, 1, commonQuery)),
      api.searchProjects(commonQuery, { method: 'HEAD' }),
    ])
      .then(([projects, allProjects]) =>
        projects.ok ? Promise.all([projects.json(), parsePagination(allProjects)]) : [[], null]
      )
      .then(([objects, pagination]) => {
        dispatch({
          type: GET_MAIN_PAGE_OBJECTS,
          sectionType: 'projects',
          objects,
          total: pagination.total,
        });
      });
  };
}

export function fetchFromOwnerOffers(itemsPerPage) {
  return (dispatch) => {
    api
      .getFromOwnerOffers(paginatedQuery(itemsPerPage, 1, commonQuery))
      .then((res) => (res.ok ? Promise.all([res.json(), parsePagination(res)]) : [[], null]))
      .then(([objects, pagination]) => {
        dispatch({
          type: GET_MAIN_PAGE_OBJECTS,
          sectionType: 'apartmentsFromOwner',
          objects,
          total: pagination.total,
        });
      });
  };
}

export function genericOffers(sectionType, itemsPerPage, query) {
  return (dispatch) => {
    api
      .getRealtiesOffers(
        paginatedQuery(itemsPerPage, 1, {
          ...commonQuery,
          ...query,
        })
      )
      .then((res) => (res.ok ? Promise.all([res.json(), parsePagination(res)]) : [[], null]))
      .then(([objects, pagination]) => {
        dispatch({
          type: GET_MAIN_PAGE_OBJECTS,
          sectionType,
          objects,
          total: pagination.total,
        });
      });
  };
}

function genericProjectsSearch(sectionType, itemsPerPage, query) {
  return api
    .searchProjects(
      paginatedQuery(itemsPerPage, 1, {
        ...commonQuery,
        ...query,
      })
    )
    .then((res) => {
      const pagination = parsePagination(res);
      return res.ok ? Promise.all([res.json(), pagination]) : [[], pagination];
    })
    .then(([objects, pagination]) => {
      return {
        type: GET_MAIN_PAGE_OBJECTS,
        sectionType,
        objects,
        total: pagination.total,
      };
    });
}

export function fetchApartmentsForSale(itemsPerPage) {
  return (dispatch) => {
    const items = genericOffers('apartmentsSale', itemsPerPage, {
      tsType: TRANSACTION_TYPES.SALE,
      unitType: OBJECT_TYPES.Apartment,
    });
    dispatch(items);
  };
}

export function fetchApartmentsForRent(itemsPerPage) {
  return (dispatch) => {
    const items = genericOffers('apartmentsRent', itemsPerPage, {
      tsType: TRANSACTION_TYPES.RENT,
      unitType: OBJECT_TYPES.Apartment,
    });
    dispatch(items);
  };
}

export function fetchHousesForSale(itemsPerPage) {
  return (dispatch) => {
    const items = genericOffers('houseSale', itemsPerPage, {
      tsType: TRANSACTION_TYPES.SALE,
      unitType: OBJECT_TYPES.House,
    });
    dispatch(items);
  };
}

export function fetchHousesForRent(itemsPerPage) {
  return (dispatch) => {
    const items = genericOffers('houseRent', itemsPerPage, {
      tsType: TRANSACTION_TYPES.RENT,
      unitType: OBJECT_TYPES.House,
    });
    dispatch(items);
  };
}

export function fetchNpCommercialForRent(itemsPerPage) {
  return (dispatch) => {
    genericProjectsSearch('npCommercialRent', itemsPerPage, {
      tsType: TRANSACTION_TYPES.RENT,
      unitType: OBJECT_TYPES.Commercial,
      randomizer: true,
    }).then((action) => {
      return dispatch(action);
    });
  };
}
export function fetchCommercialForSale(itemsPerPage) {
  return (dispatch) => {
    const items = genericOffers('commercialSale', itemsPerPage, {
      tsType: TRANSACTION_TYPES.SALE,
      unitType: OBJECT_TYPES.Commercial,
    });
    dispatch(items);
  };
}
export function fetchCommercialsForRent(itemsPerPage) {
  return (dispatch) => {
    const items = genericOffers('commercialRent', itemsPerPage, {
      tsType: TRANSACTION_TYPES.RENT,
      unitType: OBJECT_TYPES.Commercial,
    });
    dispatch(items);
  };
}

export function fetchLands(itemsPerPage) {
  return (dispatch) => {
    const items = genericOffers('land', itemsPerPage, {
      // tsType: TRANSACTION_TYPES.RENT,
      unitType: OBJECT_TYPES.Land,
    });
    dispatch(items);
  };
}

export function fetchPricedropObjects(itemsPerPage) {
  return (dispatch) => {
    const items = genericOffers('pricedrop', itemsPerPage, {
      pricedrop: true,
    });
    dispatch(items);
  };
}

export function fetchOpenHouseProjects(itemsPerPage) {
  return (dispatch) => {
    genericProjectsSearch('openHouseProjects', itemsPerPage, {
      attributes: { [HAS_OPEN_DAYS]: true },
    }).then((action) => {
      const { objects } = action;
      // Add random objects if there are open days objects and less than expected
      if (objects.length > 0 && objects.length < itemsPerPage) {
        const existing = objects.map((o) => o.id);
        return genericProjectsSearch(null, itemsPerPage, {}).then(({ objects: additional }) => {
          action.objects = objects
            .concat(shuffleArray(additional.filter((o) => !existing.includes(o.id))))
            .slice(0, itemsPerPage);
          return dispatch(action);
        });
      }

      return dispatch(action);
    });
  };
}

export function fetchFreshPublishedProjects(itemsPerPage) {
  return (dispatch) => {
    genericProjectsSearch('freshPublishedProjects', itemsPerPage, {
      order: { datePublished: 'desc' },
    }).then((action) => {
      action.objects = action.objects.map((item) => {
        // Add new label for all items here
        item.new = true;
        return item;
      });
      dispatch(action);
    });
  };
}

export function fetchComingSoonProjects(itemsPerPage) {
  return (dispatch) => {
    genericProjectsSearch('csProjects', itemsPerPage, {
      cp: 'comingSoon',
    }).then((action) => {
      dispatch(action);
    });
  };
}

export function fetchProjectsByLocations(itemsPerPage, locations) {
  return (dispatch) => {
    locations.forEach((loc, i) => {
      const { address } = commonQuery;
      genericProjectsSearch('projectsByLocation', itemsPerPage, {
        address: { ...address, ...loc.query },
      }).then((action) => {
        action.location = loc;
        action.position = i;
        action.objects = shuffleArray(action.objects);
        dispatch(action);
      });
    });
  };
}

export function fetchMainPageSections(itemsPerPage) {
  return (dispatch) => {
    [
      fetchSpecialOffers(),
      fetchTopOffers(12),
      fetchMainPageProjects(10),
      fetchFromOwnerOffers(itemsPerPage),
      fetchApartmentsForSale(itemsPerPage),
      fetchApartmentsForRent(itemsPerPage),
      fetchHousesForSale(itemsPerPage),
      fetchHousesForRent(itemsPerPage),
      fetchNpCommercialForRent(itemsPerPage),
      fetchCommercialForSale(itemsPerPage),
      fetchCommercialsForRent(itemsPerPage),
      fetchLands(itemsPerPage),
      fetchPricedropObjects(itemsPerPage),
    ].forEach((action) => dispatch(action));
  };
}
