import ReactGA from '@/utils/ga';
import {
  BASEMENT,
  BUILDING_MATERIAL,
  CONDITION,
  ELECTRICITY,
  FURNITURE,
  HAS_BALCONY,
  HAS_SEWERAGE,
  HEATING_SYSTEM,
  LIFT,
  MEETING_ROOM,
  PARKING_SELECTION,
  PURPOSE_OF_USE_COMMERCIAL,
  PURPOSE_OF_USE_LAND,
  SANITARY_ARRANGEMENTS,
  SECURITY,
  VENTILATION_TYPE,
  WATER,
} from './attributes';
import { EMAIL_ALERT_ASAP, EMAIL_ALERT_OFF, EMAIL_ALERT_ONCE_A_DAY, EMAIL_ALERT_ONCE_A_WEEK } from './savedSearches';

// Categories
export const GA_HEADER_MENU = 'header_menu';
export const GA_FOOTER_MENU = 'footer_menu';
export const GA_COOKIE = 'cookie';
export const GA_STICKY_LINE = 'stickyLine';
export const GA_SIGNIN = 'signIn';
export const GA_SEARCH = 'search_box';
export const GA_MAIN = 'frontPage';
export const GA_SEARCH_PAGE = 'searchListPage';
export const GA_SAVE_SEARCH = 'saveSearch';
export const GA_DETAIL_VIEW = 'detailView';
export const GA_PASSWORD_RESET = 'passwordReset';
export const GA_NEW_ACCOUNT = 'newAccount';
export const GA_FAVORITES = 'favorites';
export const GA_MY_SEARCHES = 'mySearches';
export const GA_SEB_CALCULATOR = 'SEBcalculator';
export const GA_SHARE_AD = 'shareAd';
export const GA_REPORT_AD = 'reportAd';
export const GA_SEND_MESSAGE = 'sendMessage';
export const GA_PAGE_NOT_FOUND = 'pageNotFound';
export const GA_OBJECT_NOT_FOUND = 'objectNotFound';
export const GA_COMPANY_DETAIL = 'companyDetail';
export const GA_BROKER_DETAIL = 'brokerDetail';
export const GA_CAMPAIGN_LANDING = 'campaignLanding';
export const GA_MULTI_BANK_CALCULATOR = 'multi_bank_calculator';
export const GA_SINGLE_BANK_CALCULATOR = 'single_bank_calculator';
export const GA_INBANK_CALCULATOR = 'inbank_calculator';
export const GA_MY_OBJECT = 'myObject';
export const GA_MY_OBJECT_PACKAGES = 'myObjectPackages';

// maped Labels
export const GAL_SEARCH_EXTRAS = {
  [LIFT]: 'elevator',
  [HAS_BALCONY]: 'balcony',
  [BASEMENT]: 'cellar',
  [SANITARY_ARRANGEMENTS.SAUNA]: 'sauna',
  [SANITARY_ARRANGEMENTS.BATH]: 'bath',
  [SANITARY_ARRANGEMENTS.SAUNA]: 'sauna',
  [PARKING_SELECTION.GARAGE]: 'garage',
  [FURNITURE]: 'furnished',
  [HEATING_SYSTEM.FIREPLACE]: 'fireplace',
  [MEETING_ROOM]: 'meetingRoom',
  [VENTILATION_TYPE.CLIMATE_CONTROL]: 'climateControl',
  [VENTILATION_TYPE.COMPULSORY_VENTILATION]: 'compulsoryVentilation',
  [VENTILATION_TYPE.AIR_CONDITIONER]: 'conditioner',
  [HAS_SEWERAGE]: 'sewerage',
  [ELECTRICITY]: 'electricity',
  [WATER]: 'water',
  [SECURITY.SECURITY_SYSTEM]: 'securitySystem',
};

export const GAL_SEARCH_PURPOSE = {
  [PURPOSE_OF_USE_COMMERCIAL.FOOD_SERVICES]: 'foodServices',
  [PURPOSE_OF_USE_COMMERCIAL.MERCHANT]: 'merchant',
  [PURPOSE_OF_USE_COMMERCIAL.NOT_DEFINED]: 'notDefined',
  [PURPOSE_OF_USE_COMMERCIAL.OFFICE]: 'office',
  [PURPOSE_OF_USE_COMMERCIAL.PRODUCTION]: 'production',
  [PURPOSE_OF_USE_COMMERCIAL.SERVICES]: 'services',
  [PURPOSE_OF_USE_COMMERCIAL.WAREHOUSE]: 'warehouse',
  [PURPOSE_OF_USE_LAND.COMMERCIAL_LAND]: 'commercialLand',
  [PURPOSE_OF_USE_LAND.FOREST_LAND]: 'forestLefand',
  [PURPOSE_OF_USE_LAND.INDUSTRIAL_LAND]: 'industrialLefand',
  [PURPOSE_OF_USE_LAND.LAND_BY_WATERS]: 'landByWaters',
  [PURPOSE_OF_USE_LAND.LAND_WITHOUT_PURPOSE_DETERMINED]: 'landWithoutPurposeDetermined',
  [PURPOSE_OF_USE_LAND.PRODUCTION_LAND]: 'productionLefand',
  [PURPOSE_OF_USE_LAND.PROFIT_YIELDING_LAND]: 'profitYieldingLefand',
  [PURPOSE_OF_USE_LAND.RESIDENTAL_LAND]: 'residentalLand',
};

export const GAL_SEARCH_CONDITION = Object.freeze({
  [CONDITION.NEWLY_DECORATED]: 'newlyDecorated',
  [CONDITION.FOUNDATION]: 'foundation',
  [CONDITION.FRAMEWORK]: 'framework',
  [CONDITION.ESSENTIAL_REPAIR_IS_DONE]: 'essentialRepairDone',
  [CONDITION.NEEDS_COMPLETE_RENEWAL]: 'needsCompleteRenewal',
  [CONDITION.NEEDS_DECORATION]: 'needsDecoration',
  [CONDITION.NEW_BUILDING]: 'newBuilding',
  [CONDITION.READY]: 'ready',
  [CONDITION.RENOVATED]: 'renovated',
  [CONDITION.UNDER_CONSTRUCTION]: 'underConstruction',
});

export const GAL_SEARCH_MATERIAL = {
  [BUILDING_MATERIAL.BRICK]: 'brick',
  [BUILDING_MATERIAL.LOG_HOUSE]: 'logHouse',
  [BUILDING_MATERIAL.LOG_STONE_HOUSE]: 'logStoneHouse',
  [BUILDING_MATERIAL.MONOLITH]: 'monolith',
  [BUILDING_MATERIAL.PANEL_HOUSE]: 'panelHouse',
  [BUILDING_MATERIAL.STONE_HOUSE]: 'stoneHouse',
  [BUILDING_MATERIAL.WOODEN_HOUSE]: 'woodenHouse',
};

export const GAL_SEARCH_PAGE_SORTING = {
  relevance: 'default',
  datePublishedDesc: 'latestFirst',
  datePublishedAsc: 'oldestFirst',
  priceDesc: 'highestPriceFirst',
  priceAsc: 'lowestPriceFirst',
  pricePerUnitDesc: 'highestM2PriceFirst',
  pricePerUnitAsc: 'lowestM2PriceFirst',
};

export const GAL_SAVE_SEARCH_INTERVAL = {
  [EMAIL_ALERT_OFF]: 'off',
  [EMAIL_ALERT_ONCE_A_DAY]: 'oncePerDay',
  [EMAIL_ALERT_ONCE_A_WEEK]: 'oncePerWeek',
  [EMAIL_ALERT_ASAP]: 'atOnce',
};

export const GAL_REPORT_AD_REASON = {
  wrong_description: 'wrongAdDescription',
  fraud: 'fraudulentAd',
  sold_rented: 'soldRentedProperty',
  other: 'other',
};

// Events
export const GAE_SELECT_PROPERTY_TYPE = (label) => {
  ReactGA.event({
    category: GA_SEARCH,
    action: 'select_propertyType',
    label,
  });
};

export const GAE_INSERT_AUTOCOMPLETE_TEXT = () => {
  ReactGA.event({
    category: GA_SEARCH,
    action: 'insert_autocomplete_textfield',
  });
};

export const GAE_CLOSE_SEARCH_OPTIOINS = () => {
  ReactGA.event({
    category: GA_SEARCH,
    action: 'close_searchOptions',
  });
};
