import React, { Suspense, useEffect } from 'react';
import ReactGA from '@/utils/ga';
import ReactPixel from 'react-facebook-pixel';
import { Route, useLocation } from 'react-router-dom';
import { Title } from 'react-head';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { getLanguage } from '@/selectors/appSelectors';
import { SITE_NAME } from '@/constants';
import { isPageviewTrackingEnabled, languagesPrefix } from '@/constants/routes';
import { fetchMainCampaignInfo } from '@/actions/mainPageActions';

import AppContext from './appContext';
import LegacyLink from './link/LegacyLink';
import LangSwitch from './LangSwitch';
import AdMaster from './Ad/AdMaster';
import Modals from './modals/Modals';
import Notifications from './notifications/Notifications';
import ScrollToTop from './scrollToTop/ScrollToTop';
import Loading from './loading/Loading';
import ErrorHandler from './ErrorHandler/ErrorHandler';
import useGaCustomDefinitions from '@/hooks/gaCustomDefinitions';
import { calculateResponsiveState } from 'redux-responsive';
import { setSorting } from './search/SearchActions';

function App(): React.ReactElement {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const language = useAppSelector(getLanguage);

  useEffect(() => {
    document.body.classList.add(PORTAL_EE ? 'ee' : 'lv');
    dispatch(calculateResponsiveState(window));
    dispatch(fetchMainCampaignInfo());
    const searchSort = sessionStorage.getItem('searchSort');
    if (searchSort) {
      dispatch(setSorting(JSON.parse(searchSort)));
    }
  }, []);

  useEffect(() => {
    const strippedPath = location.pathname.replace(/^\/(et|en|lv|ru)\//, '/');
    if (isPageviewTrackingEnabled(strippedPath)) {
      // Because we cannot disable pageviews separately for Pixel, we only init it on pages where we want to track pageviews and other events
      if (!window.fbq) {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID!, undefined, { autoConfig: true, debug: DEBUG });
        ReactPixel.pageView();
      }
      ReactGA.pageview(location.pathname);
      if (PORTAL_EE) {
        window?.pp_gemius_hit?.('d60wsoNu7_qcZNRzcTFlXZR6P8fG4YcC8ydNkVbT4dn.e7');
      }
    }
  }, [location.pathname]);

  useGaCustomDefinitions();

  return (
    <div className="App">
      <Suspense fallback={<Loading full />}>
        <ErrorHandler>
          <GoogleReCaptchaProvider
            language={language}
            reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_V3_SITE_KEY}
          >
            <AppContext.Provider value={{ Link: LegacyLink, useTranslation }}>
              <Title>{SITE_NAME}</Title>
              <ScrollToTop />
              <AdMaster />
              <Route path={languagesPrefix} component={LangSwitch} />
              <Modals />
              <Notifications />
            </AppContext.Provider>
          </GoogleReCaptchaProvider>
        </ErrorHandler>
      </Suspense>
    </div>
  );
}

export default App;
