import OBJECT_SPEC_TYPES from './specTypes';

export const OBJECT_SPEC_GROUPS_ORDER = [
  'objectSpec.group.cost',
  'objectSpec.group.apartment',
  'objectSpec.group.utilities',
  'objectSpec.group.building',
] as const;
export const OBJECT_SPEC_GROUPS_ORDER_ALT = [
  'objectSpec.group.apartment',
  'objectSpec.group.cost',
  'objectSpec.group.building',
  'objectSpec.group.utilities',
] as const;
export const OBJECT_SPEC_GROUPS_ORDER_NP = ['objectSpec.group.cost_np', 'objectSpec.group.apartment_np'] as const;
export const OBJECT_SPEC_GROUPS_ORDER_NP_ALT = ['objectSpec.group.apartment_np', 'objectSpec.group.cost_np'] as const;

export const OBJECT_SPEC_GROUPS = [
  {
    name: 'objectSpec.group.apartment',
    specTypes: [
      OBJECT_SPEC_TYPES.object_id,
      OBJECT_SPEC_TYPES.CONDITION,
      OBJECT_SPEC_TYPES.room_count,
      OBJECT_SPEC_TYPES.NUMBER_OF_DESKS,
      OBJECT_SPEC_TYPES.NUMBER_OF_BEDROOMS,
      OBJECT_SPEC_TYPES.NUMBER_OF_BATHROOMS,
      OBJECT_SPEC_TYPES.property_size,
      OBJECT_SPEC_TYPES.KITCHEN_AREA,
      OBJECT_SPEC_TYPES.lot_size,
      OBJECT_SPEC_TYPES.BALCONY_TYPE,
      OBJECT_SPEC_TYPES.BALCONY_SIZE,
      OBJECT_SPEC_TYPES.FLOOR,
      OBJECT_SPEC_TYPES.TOTAL_FLOORS,
      OBJECT_SPEC_TYPES.HOUSE_CLASS,
      OBJECT_SPEC_TYPES.OWNERSHIP,
      OBJECT_SPEC_TYPES.SECURITY,
      OBJECT_SPEC_TYPES.EXTRA_VALUES,
      OBJECT_SPEC_TYPES.LIMITATIONS,
      OBJECT_SPEC_TYPES.AVAILABLE,
      OBJECT_SPEC_TYPES.OUTDOOR_SPACES,
      OBJECT_SPEC_TYPES.ADVANTAGES,
      OBJECT_SPEC_TYPES.PARKING_SELECTION_PROJECT,
      OBJECT_SPEC_TYPES.SECURITY_OPTIONS_PROJECT,
      OBJECT_SPEC_TYPES.NEARBY_SURROUNDINGS,
    ],
  },
  {
    name: 'objectSpec.group.cost',
    specTypes: [
      OBJECT_SPEC_TYPES.price,
      OBJECT_SPEC_TYPES.VAT,
      OBJECT_SPEC_TYPES.price_per_unit,
      OBJECT_SPEC_TYPES.RENT_PER_DAY,
      OBJECT_SPEC_TYPES.COMMUNAL_COSTS,
      OBJECT_SPEC_TYPES.RENT_COMMUNAL_COSTS,
      OBJECT_SPEC_TYPES.RENT_SUMMER_COMMUNAL_COSTS,
      OBJECT_SPEC_TYPES.RENT_WINTER_COMMUNAL_COSTS,
      OBJECT_SPEC_TYPES.DEPOSIT_MONEY,
      OBJECT_SPEC_TYPES.BROKERS_FEE,
      OBJECT_SPEC_TYPES.LUMINOR_MONTHLY_PAYMENT,
      OBJECT_SPEC_TYPES.LUMINOR_CONSUMER_LOAN,
      OBJECT_SPEC_TYPES.SEB_MONTHLY_PAYMENT,
    ],
  },
  {
    name: 'objectSpec.group.building',
    specTypes: [
      OBJECT_SPEC_TYPES.cadaster_number,
      OBJECT_SPEC_TYPES.estate_register_number,
      OBJECT_SPEC_TYPES.ENERGY_CERTIFICATE_TYPE,
      OBJECT_SPEC_TYPES.PURPOSE_OF_USE_LAND,
      OBJECT_SPEC_TYPES.PURPOSE_OF_USE_COMMERCIAL,
      OBJECT_SPEC_TYPES.OTHER_USE_POSSIBLE,
      OBJECT_SPEC_TYPES.DETAILED_PLAN,
      OBJECT_SPEC_TYPES.HOUSE_TYPE,
      OBJECT_SPEC_TYPES.BUILDING_MATERIAL,
      OBJECT_SPEC_TYPES.CONSTRUCTION_YEAR,
      OBJECT_SPEC_TYPES.year_built,
      OBJECT_SPEC_TYPES.LIFT,
      OBJECT_SPEC_TYPES.PARKING_SELECTION,
      OBJECT_SPEC_TYPES.PARKING_SELECTION_COMMERCIAL,
      OBJECT_SPEC_TYPES.PARKING_PLACES,
      OBJECT_SPEC_TYPES.PARKING_FEE,
      OBJECT_SPEC_TYPES.PARKING_FREE,
      OBJECT_SPEC_TYPES.ROOF_TYPE,
      OBJECT_SPEC_TYPES.EXTRA_SPACES,
      OBJECT_SPEC_TYPES.distance_from_capital,
      OBJECT_SPEC_TYPES.info,
      OBJECT_SPEC_TYPES.FINISH_STAGE,
    ],
  },
  {
    name: 'objectSpec.group.utilities',
    specTypes: [
      OBJECT_SPEC_TYPES.HEATING_SYSTEM,
      OBJECT_SPEC_TYPES.HEATING_TYPE,
      OBJECT_SPEC_TYPES.ENERGY_SOURCE,
      OBJECT_SPEC_TYPES.SANITARY_ARRANGEMENTS,
      OBJECT_SPEC_TYPES.STOVE,
      OBJECT_SPEC_TYPES.COMMUNICATIONS,
      OBJECT_SPEC_TYPES.VENTILATION_TYPE,
      OBJECT_SPEC_TYPES.UTILITIES,
    ],
  },
  {
    name: 'objectSpec.group.cost_np',
    specTypes: [
      OBJECT_SPEC_TYPES.price,
      OBJECT_SPEC_TYPES.price_per_unit,
      OBJECT_SPEC_TYPES.SEB_MONTHLY_PAYMENT,
      OBJECT_SPEC_TYPES.LUMINOR_MONTHLY_PAYMENT,
    ],
  },
  {
    name: 'objectSpec.group.apartment_np',
    specTypes: [
      OBJECT_SPEC_TYPES.object_id,
      OBJECT_SPEC_TYPES.MOVE_IN_READY_YEAR,
      OBJECT_SPEC_TYPES.FINISH_STAGE,
      OBJECT_SPEC_TYPES.ENERGY_CERTIFICATE_TYPE,
      OBJECT_SPEC_TYPES.TOTAL_FLOORS,
      OBJECT_SPEC_TYPES.OUTDOOR_SPACES,
      OBJECT_SPEC_TYPES.NEARBY_SURROUNDINGS,
      OBJECT_SPEC_TYPES.ADVANTAGES,
      OBJECT_SPEC_TYPES.PARKING_SELECTION_PROJECT,
      OBJECT_SPEC_TYPES.SECURITY_OPTIONS_PROJECT,
      OBJECT_SPEC_TYPES.UTILITIES,
      OBJECT_SPEC_TYPES.MUNICIPALITY_UTILITIES,
      OBJECT_SPEC_TYPES.info,
      OBJECT_SPEC_TYPES.NO_OF_LAND_LOTS,
      OBJECT_SPEC_TYPES.lot_size_min,
      OBJECT_SPEC_TYPES.lot_size_max,
    ],
  },
] as const;
