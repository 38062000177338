import { isImmutable } from 'immutable';
import { StandaloneAttribute } from '@city24/common/enums/realty/Attribute';
import { RealtyPublic } from '@city24/common/types/public/RealtyPublic';
import { MODEL_STATUSES } from '@/constants';
import { fromISOString } from './date';
import { EXPORT_TO_KV } from '@/constants/packages';

export function isPublished(statusCode: number): boolean {
  return statusCode === MODEL_STATUSES.PUBLISHED;
}

export function isInactive(statusCode: number): boolean {
  return !isPublished(statusCode);
}

export function isDraft(statusCode: number): boolean {
  return statusCode === MODEL_STATUSES.NEW;
}

export function isArchived(statusCode: number): boolean {
  return statusCode === MODEL_STATUSES.ARCHIVED || statusCode === MODEL_STATUSES.USER_ARCHIVED;
}

export function isUnpublished(statusCode: number): boolean {
  return statusCode === MODEL_STATUSES.VALID || statusCode === MODEL_STATUSES.EXPIRED || isArchived(statusCode);
}

function hasOpenDayAttr(object): boolean {
  if (object) {
    if (isImmutable(object)) {
      return object.getIn(['attributes', StandaloneAttribute.HAS_OPEN_DAYS]) as boolean;
    }
    return object.attributes?.[StandaloneAttribute.HAS_OPEN_DAYS];
  }
  return false;
}

export function hasOpenDays(object): boolean {
  if (isImmutable(object)) {
    return hasOpenDayAttr(object) || hasOpenDayAttr(object.get('project'));
  }
  return hasOpenDayAttr(object) || hasOpenDayAttr(object.project);
}

function hasSpecialLabelAttr(object): boolean {
  if (object) {
    if (isImmutable(object)) {
      return object.getIn(['attributes', StandaloneAttribute.HAS_SPECIAL_LABEL]) as boolean;
    }

    return object.attributes?.[StandaloneAttribute.HAS_SPECIAL_LABEL];
  }
  return false;
}

export function hasSpecialLabel(object): boolean {
  return (
    hasSpecialLabelAttr(object) ||
    (isImmutable(object) ? hasSpecialLabelAttr(object.get('project')) : hasSpecialLabelAttr(object.project))
  );
}

export function areValidStreetviewParams(streetviewParams: RealtyPublic['streetview_params']): boolean {
  return !!(streetviewParams && streetviewParams.heading !== undefined && streetviewParams.heading !== null);
}
export function allowStreetview(streetviewParams: RealtyPublic['streetview_params']): boolean {
  return streetviewParams === null || !!(streetviewParams && streetviewParams.heading === undefined);
}

export function getBookedUntil(object): boolean | Date {
  let booked;
  if (isImmutable(object)) {
    booked = object.get('booked');
    if (isImmutable(booked)) {
      booked = booked.toJS();
    }
  } else {
    booked = object.booked;
  }

  if (!booked) {
    return false;
  }
  if (booked === true) {
    return true;
  }
  return booked.booked_until ? fromISOString(booked.booked_until) : false;
}

export function isBooked(booked: boolean | Date): boolean {
  if (booked instanceof Date) {
    return booked > new Date();
  }
  return booked;
}

export function isExportedToKV(object): boolean {
  const services = object.getIn(['latest_media_publishing', 'publishing_services']);
  if (!services) {
    return false;
  }
  const matchingService = services.find(
    (service) => service.get('active_service') && service.get('service_type') === EXPORT_TO_KV
  );
  return !!matchingService;
}

export function isImportedFromKV(object): boolean {
  return object.getIn(['import_source', 'source']) === 'KV.ee';
}

export function getImportedId(object): string {
  return object.getIn(['import_source', 'id']);
}

export function isEditingAllowed(object) {
  // Actively published objects imported from KV are not editable
  if (isImportedFromKV(object)) {
    return !isPublished(object.get('status_id'));
  }
  return true;
}
