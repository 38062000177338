import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from '@/utils/ga';

import { GA_FAVORITES } from '@/constants/ga';
import { getPrefixedRoutes, getSignedIn } from '@/selectors/appSelectors';

import Icon from '@/components/icon/Icon';
import Button from '@/components/button/Button';
import List from '@/components/list/List';
import ButtonGroup from '@/components/button/ButtonGroup';
import FavouriteSavedIcon from '@/img/icons/favourite-saved.svg';

import { setSignInModalOpened } from '../ModalActions';

import Modal from '../Modal';
import ModalFooter from '../ModalFooter';
import FancySection from '../ModalSections/FancySection';

const propTypes = {
  setSignInModalOpened: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const defaultProps = {};

class SaveFavouriteModal extends React.Component {
  openSignIn = () => {
    const { closeModal } = this.props;
    closeModal();
    this.props.setSignInModalOpened(true);
  };

  render() {
    const { isOpen, closeModal, signedIn, t, routes } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="sf-modal"
        gaEvent={() =>
          ReactGA.event({
            category: GA_FAVORITES,
            action: 'close_button',
          })
        }
      >
        <FancySection
          image={FavouriteSavedIcon}
          title={t('firstFavouriteModal.title')}
          subtitle={[
            t('firstFavouriteModal.textBeforeLink'),
            ' ',
            <Link
              key="firstFavouriteModal"
              to={routes.favourites}
              className="inline-link"
              onClick={() => {
                ReactGA.event({
                  category: GA_FAVORITES,
                  action: 'click_favourites',
                });
                closeModal();
              }}
            >
              <Icon name="heart" />
              {t('firstFavouriteModal.link')}
            </Link>,
          ]}
        />
        {!signedIn && (
          <div className="sf-modal__benefits">
            <h5 className="modal__section-title">{t('firstFavouriteModal.signInPromo.title')}</h5>
            <List
              className="sf-modal__list"
              check
              items={[
                t('firstFavouriteModal.signInPromo.keepFavourites'),
                t('firstFavouriteModal.signInPromo.seeFavouriteCount'),
              ]}
            />
          </div>
        )}
        <ModalFooter>
          <ButtonGroup center={signedIn}>
            {signedIn ? (
              <Button
                primary
                text={t('common.gotIt')}
                onClick={() => {
                  ReactGA.event({
                    category: GA_FAVORITES,
                    action: 'gotIt_button',
                  });
                  closeModal();
                }}
              />
            ) : (
              <>
                <Button
                  transparent
                  text={t('common.close')}
                  onClick={() => {
                    ReactGA.event({
                      category: GA_FAVORITES,
                      action: 'close_button',
                    });
                    closeModal();
                  }}
                />
                <Button
                  primary
                  text={t('common.signIn')}
                  onClick={() => {
                    ReactGA.event({
                      category: GA_FAVORITES,
                      action: 'open_signIn_button',
                    });
                    this.openSignIn();
                  }}
                />
              </>
            )}
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }
}

SaveFavouriteModal.propTypes = propTypes;
SaveFavouriteModal.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    signedIn: getSignedIn(state),
    routes: getPrefixedRoutes(state),
  };
}
const mapDispatchToProps = { setSignInModalOpened };

export default connect(mapStateToProps, mapDispatchToProps)(SaveFavouriteModal);
