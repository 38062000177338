import React from 'react';
import type { NameValueExtra } from '@/types/collections';
import type { AutocompleteResultsProps } from './AutocompleteTypes';

export type SimpleAutocompleteResultsProps = AutocompleteResultsProps<NameValueExtra<{ secondaryName: string }>> & {
  getValue: (value: NameValueExtra<{ secondaryName?: string; [key: string]: any }> | undefined) => void;
};

function SimpleAutocompleteResults({ values, getValue, close }: SimpleAutocompleteResultsProps): JSX.Element {
  const insertToken = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { target } = event;
    if (getValue) {
      const value = values.find((v) => v.value === (target as HTMLButtonElement).value);
      getValue(value);
    }
    close();
  };

  return (
    <ul>
      {values.map(({ value, name, extra: { secondaryName } }) => {
        return (
          <li key={value}>
            <button type="button" className="autocomplete__item" value={value} onClick={insertToken}>
              <span className="autocomplete__value">
                <span className="autocomplete__primary-val">{name}</span>
                {secondaryName && <span className="autocomplete__secondary-val">{secondaryName}</span>}
              </span>
            </button>
          </li>
        );
      })}
    </ul>
  );
}

export default SimpleAutocompleteResults;
