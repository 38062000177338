import React from 'react';
import classNames from 'classnames';

type Props = {
  id?: string;
  floating: boolean;
  children: React.ReactNode;
};

function InputLabel({ id, floating, children }: Props): React.ReactElement {
  return (
    <div className="notch__base">
      <div className="leading" />
      <div className={classNames('notch', { 'label--floating': floating })}>
        <label htmlFor={id}>{children}</label>
      </div>
      <div className="trailing" />
    </div>
  );
}

export default InputLabel;
