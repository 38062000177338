import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropdown from '@/components/dropdown/Dropdown';
import Icon from '@/components/icon/Icon';
import AppContext from '@/components/appContext';

export const MenuSelectContext = React.createContext({
  closeDropdown: () => {},
});

const propTypes = {
  rawLabel: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onSignOut: PropTypes.func,
  gaEvent: PropTypes.func,
};

const defaultProps = {
  rawLabel: false,
  onSignOut: () => {},
  gaEvent: null,
};

class MenuSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: props.opened || false,
    };

    this.menuSelectRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.opened && nextProps.opened !== prevState.opened) {
      return { opened: nextProps.opened };
    }
    return null;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (!this.menuSelectRef.current) return;
    if (!this.menuSelectRef.current.contains(e.target)) {
      this.closeDropdown();
    }
  };

  closeDropdown = () => {
    this.setState({ opened: false });
  };

  handleClick = () => {
    const { opened } = this.state;
    const { gaEvent } = this.props;

    this.setState({ opened: !opened });
    if (gaEvent) {
      gaEvent();
    }
  };

  renderContent = () => {
    const { links, type, rawLabel, t, onSignOut } = this.props;

    if (links) {
      return (
        <AppContext.Consumer>
          {({ Link }) => (
            <ul className="menu-select__options">
              {links.map((option) => {
                return (
                  <li key={option.get('value')}>
                    <Link href={option.get('route')}>{rawLabel ? option.get('name') : t(option.get('name'))}</Link>
                  </li>
                );
              })}
            </ul>
          )}
        </AppContext.Consumer>
      );
    }
  };

  render() {
    const { title, children, icon, className } = this.props;
    const { opened } = this.state;

    return (
      <MenuSelectContext.Provider value={{ closeDropdown: this.closeDropdown }}>
        <div ref={this.menuSelectRef} className={className}>
          <button
            type="button"
            className={classNames('header__item', { 'header__item--active': opened })}
            onClick={this.handleClick}
          >
            {icon && <Icon name={icon} className="icon--fw" />}
            {title}
          </button>
          <Dropdown className="menu-select" opened={opened} closeDropdown={this.closeDropdown}>
            {this.renderContent() || children}
          </Dropdown>
        </div>
      </MenuSelectContext.Provider>
    );
  }
}

MenuSelect.propTypes = propTypes;
MenuSelect.defaultProps = defaultProps;

export default MenuSelect;
