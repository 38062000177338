import { combineReducers } from 'redux';
import { Map, List, fromJS } from 'immutable';

import {
  GET_OFFICE_DETAILS,
  GET_OFFICE_STATISTICS,
  GET_OFFICE_OBJECTS,
  APPEND_OFFICE_OBJECTS,
  RESET_OFFICE_OBJECTS,
} from '@/constants/actions';
import produce from 'immer';

const detailInitialState = Map();

function companyDetail(state = detailInitialState, action) {
  switch (action.type) {
    case GET_OFFICE_DETAILS:
      return state.merge(action.details);
    case GET_OFFICE_STATISTICS:
      return state.mergeIn(['statistics'], action.statistics);
    case GET_OFFICE_OBJECTS: {
      if (action.unitType && state.get('statistics')) {
        return state.updateIn(
          ['statistics', 'properties'],
          {},
          produce((stats) => {
            stats[action.transactionType] = stats[action.transactionType] || {};
            stats[action.transactionType][action.unitType] = action.total;
          })
        );
      }

      return state;
    }
    default:
      return state;
  }
}

const objectsInitialState = { objects: List(), total: 0, transactionType: '', unitType: null };

function companyObjects(state = objectsInitialState, action) {
  const { type, ...data } = action;
  switch (type) {
    case GET_OFFICE_OBJECTS:
      return { ...state, ...data, objects: data.objects.map((obj) => fromJS(obj)) };
    case APPEND_OFFICE_OBJECTS:
      return { ...state, objects: state.objects.concat(data.objects.map((obj) => fromJS(obj))) };
    case RESET_OFFICE_OBJECTS:
      return objectsInitialState;
    default:
      return state;
  }
}

export default combineReducers({
  details: companyDetail,
  objects: companyObjects,
});
