import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactFacebookLogin, { FacebookLoginProps } from '@greatsumini/react-facebook-login';
import { parse } from 'query-string';
import * as Sentry from '@sentry/nextjs';
import { isMobile } from 'react-device-detect';

import { signInByAccessToken, signInError } from '@/actions/userActions';
import { getLocale } from '@/selectors/appSelectors';

interface Props {
  hasErrors?: boolean;
  render?: FacebookLoginProps['render'];
}

function FacebookLogin({ hasErrors = false, render }: Props) {
  const dispatch = useDispatch();
  const locale = useSelector(getLocale).replace('-', '_');

  const onSuccess: FacebookLoginProps['onSuccess'] = (response) => {
    if (response.accessToken) {
      dispatch(signInByAccessToken(FacebookLogin.provider, response.accessToken));
    } else {
      // eslint-disable-next-line no-console
      console.error('Failed to get access token', response);
      Sentry.withScope((scope) => {
        scope.setExtras(response);
        Sentry.captureMessage(`Facebook login: Failed to get access token`);
      });
    }
  };

  const onFail: FacebookLoginProps['onFail'] = (response) => {
    // eslint-disable-next-line no-console
    console.error('Failed to get access token', response);
    Sentry.withScope((scope) => {
      scope.setExtras(response);
      Sentry.captureMessage(`Facebook login: Failed to get access token`);
    });
  };
  useEffect(() => {
    if (hasErrors) {
      const response = parse(window.location.search);
      // eslint-disable-next-line no-console
      console.error(`Facebook login: ${response.error_description}`, response);

      dispatch(signInError(response.error, FacebookLogin.provider, response));
      Sentry.withScope((scope) => {
        scope.setExtras(response);
        Sentry.captureMessage(`Facebook login: ${response.error_description}`);
      });
    }
  }, []);

  return (
    <ReactFacebookLogin
      useRedirect={!isMobile}
      appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
      initParams={{
        version: 'v17.0',
      }}
      language={locale}
      onSuccess={onSuccess}
      onFail={onFail}
      dialogParams={
        {
          redirect_uri: process.env.NEXT_PUBLIC_ORIGIN_URL,
        } as FacebookLoginProps['dialogParams']
      }
      render={render}
    />
  );
}
FacebookLogin.provider = 'facebook';

export default FacebookLogin;
