import React from 'react';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';

import { OBJECT_TYPES } from '@/constants/object';
import { shouldSearchByTotalFloors } from '@/constants/filters';
import { FLOOR, TOTAL_FLOORS } from '@/constants/attributes';
import { formatFloorString } from '@/utils/formatting';
import { getBookedUntil, isBooked } from '@/utils/objectStates';

import SimpleCommonObject from './SimpleCommonObject';
import SimpleProject from './SimpleProject';
import SimpleModularHouse from './SimpleModularHouse';

function SimpleObject({ object, routes, ...props }) {
  const getAttrValue = (type) => object.getIn(['attributes', type]);

  const totalFloors = getAttrValue(TOTAL_FLOORS);
  if (object.get('unit_type') === OBJECT_TYPES.NewProject) {
    return (
      <SimpleProject
        object={object}
        objectRoute={routes && routes.projectDetail}
        priceSqrmMin={object.get('price_per_unit_min')}
        priceSqrmMax={object.get('price_per_unit_max')}
        routes={routes}
        roomCountMin={object.get('room_count_min')}
        roomCountMax={object.get('room_count_max')}
        floor={totalFloors}
        {...props}
      />
    );
  }

  if (object.get('unit_type') === OBJECT_TYPES.ModularHouse) {
    return (
      <SimpleModularHouse
        object={object}
        objectRoute={routes && routes.modularHouseDetail}
        floor={totalFloors}
        routes={routes}
        {...props}
      />
    );
  }

  return (
    <SimpleCommonObject
      {...props}
      routes={routes}
      object={object}
      objectRoute={routes && routes.objectDetail}
      projectFriendlyId={object.getIn(['project', 'friendly_id'])}
      getAttrValue={getAttrValue}
      price={object.get('price')}
      priceSqrm={object.get('price_per_unit')}
      oldPrice={object.get('old_price')}
      size={object.get('property_size')}
      sizeUnit={object.get('size_unit_id')}
      lotSize={object.get('lot_size')}
      lotSizeUnit={object.get('lot_size_unit_id')}
      floor={
        shouldSearchByTotalFloors(object.get('unit_type'))
          ? totalFloors
          : formatFloorString(getAttrValue(FLOOR), totalFloors)
      }
      rooms={object.get('room_count')}
      booked={isBooked(getBookedUntil(object))}
    />
  );
}

SimpleObject.propTypes = {
  object: PropTypes.instanceOf(Collection.Keyed).isRequired,
  routes: PropTypes.oneOfType([
    PropTypes.shape({
      projectDetail: PropTypes.string,
      modularHouseDetail: PropTypes.string,
      objectDetail: PropTypes.string,
    }),
    PropTypes.bool,
  ]).isRequired,
};
// SimpleObject.defaultProps = {};

export default SimpleObject;
