const id = (a) => a;

function makeQueryElement(key, value) {
  if (Array.isArray(value)) {
    return value.map((v) => makeQueryElement(`${key}[]`, v)).join('&');
  }
  if (value !== null && typeof value === 'object') {
    return Object.keys(value)
      .map((subKey) => makeQueryElement(`${key}[${subKey}]`, value[subKey]))
      .join('&');
  }

  const encodedKey = encodeURIComponent(key);

  if (value === null) {
    return encodedKey;
  }
  if (typeof value === 'boolean') {
    return `${encodedKey}=${value ? 1 : 0}`;
  }
  if (typeof value === 'undefined') {
    return undefined;
  }

  return `${encodedKey}=${encodeURIComponent(value)}`;
}

export function stringify(params) {
  return Object.keys(params)
    .map((key) => makeQueryElement(key, params[key]))
    .filter(id)
    .join('&');
}

export default {
  stringify,
};
