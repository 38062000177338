import levelIcon from '@/img/packages/boost-level.svg';
import levelSelectedIcon from '@/img/packages/boost-level-selected.svg';
import specialOfferIcon from '@/img/packages/special-offer.svg';
import specialOfferSelectedIcon from '@/img/packages/special-offer-selected.svg';
import fromOwnerIcon from '@/img/packages/from-owner.svg';
import fromOwnerSelectedIcon from '@/img/packages/from-owner-selected.svg';
import mediaCampaignIcon from '@/img/packages/media-campaign.svg';
import mediaCampaignSelectedIcon from '@/img/packages/media-campaign-selected.svg';
import { TRANSACTION_TYPES } from './object';

// PERIOD PACKAGES

export enum PERIOD_PACKAGE {
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
}

export const PERIOD_PACKAGE_ORDER = [PERIOD_PACKAGE.s, PERIOD_PACKAGE.m, PERIOD_PACKAGE.l, PERIOD_PACKAGE.xl] as const;

/**
 * Determine period package from the amount of days and transaction type
 * This is not definitive as real values will come from API
 */
export function determinePeriodPackage(period: number, transactionType: string): PERIOD_PACKAGE {
  let idx = -1;
  if (transactionType === TRANSACTION_TYPES.RENT) {
    idx = [15, 30, 45, 60].findIndex((days) => period <= days);
  } else {
    idx = [30, 60, 90, PORTAL_LV ? 180 : 365].findIndex((days) => period <= days);
  }
  if (idx === -1) {
    return PERIOD_PACKAGE.xl;
  }
  return PERIOD_PACKAGE_ORDER[idx];
}

export const EXPORT_TO_KV = 'EXPORT_TO_KV';

// MARKETING PACKAGES

export const MARKETING_PACKAGE = {
  adLevel: 'adLevel',
  specialOffer: 'specialOffer',
  fromOwner: 'fromOwner',
  mediaCampaign: 'mediaCampaign',
};

export function getMarketingPackageConfig(
  pkgName: string
): Partial<{ name: string; description: string; icon: string; iconSelected: string; serviceName: string }> {
  if (pkgName === MARKETING_PACKAGE.adLevel) {
    return {
      name: 'myObject.packages.adLevel.title',
      description: PORTAL_LV ? 'myObject.packages.adLevel.description' : 'myObject.packages.adLevel.descriptionEe',
      icon: levelIcon,
      iconSelected: levelSelectedIcon,
      serviceName: 'AD_LEVEL',
    };
  }
  if (pkgName === MARKETING_PACKAGE.specialOffer) {
    return {
      name: 'myObject.packages.specialOffer.title',
      description: PORTAL_LV
        ? 'myObject.packages.specialOffer.description'
        : 'myObject.packages.specialOffer.descriptionEe',
      icon: specialOfferIcon,
      iconSelected: specialOfferSelectedIcon,
      serviceName: 'SPECIAL_OFFER',
    };
  }
  if (pkgName === MARKETING_PACKAGE.fromOwner) {
    return {
      name: PORTAL_LV ? 'myObject.packages.fromOwner.title' : 'myObject.packages.fromOwner.titleEe',
      description: PORTAL_LV ? 'myObject.packages.fromOwner.description' : 'myObject.packages.fromOwner.descriptionEe',
      icon: fromOwnerIcon,
      iconSelected: fromOwnerSelectedIcon,
      serviceName: 'INTERNAL_INFOBLOCK',
    };
  }
  if (pkgName === MARKETING_PACKAGE.mediaCampaign && PORTAL_LV) {
    return {
      name: 'myObject.packages.mediaCampaign.title',
      description: 'myObject.packages.mediaCampaign.description',
      icon: mediaCampaignIcon,
      iconSelected: mediaCampaignSelectedIcon,
      serviceName: 'EXTERNAL_INFOBLOCK',
    };
  }

  return {};
}
