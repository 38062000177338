import React, { useEffect, useMemo, useState } from 'react';
import { Map, Range } from 'immutable';
import Markdown from 'markdown-to-jsx';
import type { TFunction } from 'next-i18next';

import { formatPrice } from '@/utils/formatting';
import ReactGA from '@/utils/ga';
import Tooltip from '@/components/tooltip/Tooltip';
import Icon from '@/components/icon/Icon';
import Select from '@/components/select/Select';
import Button from '@/components/button/Button';
import Image from '@/components/img/Image';

import logo from '@/img/altero.svg';

import css from './AlteroInsuranceCalculator.module.scss';

type Props = {
  t: TFunction;
  unitSize: number | null;
};

const AlteroInsuranceCalculator = ({ t, unitSize }: Props): React.ReactElement => {
  const areaRange = Range(30, 301, 30).map((i) => Map({ name: 'loanCalc.range.sqm', value: `${i}` }));
  const [selectedArea, setSelectedArea] = useState(areaRange.first());

  useEffect(() => {
    if (unitSize) {
      const closestArea = areaRange.reduce((prev, curr) => {
        return Math.abs(Number(curr.get('value')) - unitSize) < Math.abs(Number(prev.get('value')) - unitSize)
          ? curr
          : prev;
      }, areaRange.first()!);
      setSelectedArea(closestArea as Map<string, string>);
    }
  }, [unitSize]);

  const monthlyPayment = useMemo(() => {
    return (1.1 * Number(selectedArea.get('value'))) / 12;
  }, [selectedArea]);

  return (
    <div className={css.altero}>
      <div className={css.header}>
        <div className={css.logo}>
          <Image src={logo} alt={t('loanCalc.alteroInsurance.title')} />
        </div>
        <div>{t('loanCalc.alteroInsurance.title')}</div>
      </div>
      <div className={css.body}>
        <h2 className="sbcc__monthly-payment">
          {formatPrice(monthlyPayment)}
          <span className="sbcc__per-month">/{t('loanCalc.perMonth')}</span>{' '}
          <a role="tooltip" className="tooltip" data-tooltip-id="altero-insurance">
            <Icon name="question" />
          </a>
          <Tooltip id="altero-insurance" className="tooltip">
            <div className="sbcc__tooltip-content">
              <Markdown>{t('loanCalc.alteroInsurance.tooltip')}</Markdown>
            </div>
          </Tooltip>
        </h2>
        <div className="sbcc__amount">
          <div className="sbcc__period-label">{t('loanCalc.alteroInsurance.period')}</div>
          <Select
            className="hlcm__period-select"
            extraSmall
            options={areaRange}
            selected={selectedArea}
            getValue={(val) => setSelectedArea(val)}
            translateOption={(option) => t(option.get('name'), { count: parseInt(option.get('value'), 10) })}
            gaEvent={() =>
              ReactGA.event({
                category: 'altero_insurance_calculator',
                action: 'update_field_value',
                label: 'area',
              })
            }
          />
        </div>
      </div>
      <Button
        link
        text={t(`loanCalc.alteroInsurance.cta`)}
        href="https://www.altero.lv/lv/majokla-apdrosinasana?utm_source=city24&utm_medium=integration_calculator&utm_campaign=insurance"
        className={css.cta}
        newTab
        onClick={() => {
          ReactGA.event({
            category: 'altero_insurance_calculator',
            action: 'button_click',
          });
        }}
      />
    </div>
  );
};

export default AlteroInsuranceCalculator;
