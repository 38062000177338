import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const ModalFooter = (props) => {
  const { children, sticky, bottom } = props;

  const modalFooterStyle = classNames({
    'modal-footer': true,
    'modal-footer--sticky': sticky,
  });

  return <div className={modalFooterStyle}>{children}</div>;
};

ModalFooter.propTypes = propTypes;
ModalFooter.defaultProps = defaultProps;

export default ModalFooter;
