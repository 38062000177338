import { generatePath } from 'react-router-dom';
import { TransactionTypeLiteral } from '@city24/common/enums/realty/TransactionType';
import { UnitTypeLiteral } from '@city24/common/enums/realty/UnitType';
import Language from '@city24/common/enums/Language';

import SEO from '@/constants/seo.json';
import { OBJECT_TYPES } from '@/constants/object';
import { removeSpecialCharacters } from './helpers';

const { SEO_TYPE_COMBINATIONS } = SEO;

export function getRouteByObjectType(routes, type) {
  switch (type) {
    case OBJECT_TYPES.NewProject:
      return routes.projectDetail;
    case OBJECT_TYPES.ModularHouse:
      return routes.modularHouseDetail;
    default:
      return routes.objectDetail;
  }
}

export function getShortRouteByObjectType(routes, type) {
  switch (type) {
    case OBJECT_TYPES.NewProject:
      return routes.projectDetailShort;
    default:
      return routes.objectDetailShort;
  }
}

export function getObjectDetailRoute(route, id, typeCombination = 'type', location = 'location') {
  if (!id) {
    return '';
  }

  return generatePath(route, {
    id,
    typeCombination,
    location: removeSpecialCharacters(location).replace('/', '') || 'address',
  }).toLowerCase();
}

export function getNewsArticleRoute(route, id, slug) {
  return generatePath(route, {
    id,
    slug,
  }).toLowerCase();
}

export function typeCombinationString(transaction, objectType, url, lang = 'en') {
  const lowerWithCheck = (string = '') => (string && string.toLowerCase()) || '';
  try {
    return SEO_TYPE_COMBINATIONS[lang || 'en'][transaction]?.[objectType]?.[url || 'name'];
  } catch (e) {
    console.warn(`Transaction type: "${transaction}" doesn't contain the object "${objectType}"`, e);
    // if type combination is not defined, provide alternative value
    return url ? `${lowerWithCheck(objectType)}-${lowerWithCheck(transaction)}` : `${objectType} ${transaction}`;
  }
}

export function parseUrlTypeCombination(
  typeCombination: string,
  lang: Language = Language.en
): [undefined, undefined] | [TransactionTypeLiteral | 'all', UnitTypeLiteral] {
  const transactionTypes = SEO_TYPE_COMBINATIONS[lang];
  const transactionTypeKeys = Object.keys(transactionTypes) as (keyof typeof transactionTypes)[];
  for (let i = 0; i < transactionTypeKeys.length; i++) {
    const transactionType = transactionTypeKeys[i];

    const objectTypes = transactionTypes[transactionType];
    const objectType = (Object.keys(objectTypes) as (keyof typeof objectTypes)[]).find(
      (ot) => objectTypes[ot].url === typeCombination
    ) as UnitTypeLiteral;
    if (objectType) {
      return [transactionType, objectType];
    }
  }

  return [undefined, undefined];
}

export function getFullUrl(route) {
  return `${window.location.origin}${route}`;
}

export function getLanguagePrefixedUrl(url, currentLanguage, targetLanguage) {
  if (currentLanguage) {
    // language is defined, remove or replace
    if (!targetLanguage) {
      return url.replace(`/${currentLanguage}`, '');
    }
    if (currentLanguage === targetLanguage) {
      return url;
    }
    return url.replace(`/${currentLanguage}`, `/${targetLanguage}`);
  }

  // language not defined, prepend
  if (!targetLanguage) {
    return url;
  }

  return `/${targetLanguage}${url}`;
}
