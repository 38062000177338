const pads: { [pad: string]: string[] } = {};

function getPadString(pad: string, lengthDiff: number) {
  if (pads[pad]) {
    if (pads[pad][lengthDiff]) {
      return pads[pad][lengthDiff];
    }
  } else {
    pads[pad] = [];
  }

  return undefined;
}

/**
 * Convert number to string with 0-padding with specified minimum length
 */
export function zeroFill(nr: number, length: number): string {
  const an = Math.abs(nr);
  const lengthDiff = length - (an === 0 ? 1 : 1 + Math.floor(Math.log(an) / Math.LN10));
  if (lengthDiff <= 0) {
    return `${nr}`;
  }
  const pad = '0';
  const makeString = (zeroString: string) => `${nr < 0 ? '-' : ''}${zeroString}${an}`;
  const padString = getPadString(pad, lengthDiff);
  if (padString) {
    return makeString(padString);
  }
  const zeroString = (10 ** lengthDiff).toString().substr(1);
  pads[pad][lengthDiff] = zeroString;
  return makeString(zeroString);
}

export function twoZeroFill(nr: number): string {
  return zeroFill(nr, 2);
}

/**
 * Pad left of string up to specified length
 */
export function leftPad(string: string, length: number, pad = ' '): string {
  const lengthDiff = length - string.length;
  if (lengthDiff <= 0) {
    return string;
  }

  const padString = getPadString(pad, lengthDiff);
  if (padString) {
    return pads[pad][lengthDiff] + string;
  }
  let padStr = '';
  for (let i = 0; i < lengthDiff; i += 1) {
    padStr += pad;
  }
  pads[pad][lengthDiff] = padStr;
  return padStr + string;
}

export function getDecimalPart(num: number) {
  if (Number.isInteger(num)) {
    return 0;
  }

  const decimalStr = num.toString().split('.')[1];
  return Number(decimalStr);
}
