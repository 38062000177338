import React, { ImgHTMLAttributes, useCallback, useState } from 'react';
import type { ImageProps as NextImageProps, StaticImageData } from 'next/image';
import LazyLoadSensor from '@/components/visibilitySensor/LazyLoadSensor';

export interface ImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'placeholder'> {
  src?: NextImageProps['src'];
  placeholder?: NextImageProps['src'];
  lazyload?: boolean;
}

function isStaticImageData(src: ImageProps['src']): src is StaticImageData {
  if ((src as StaticImageData).src) {
    return true;
  }
  return false;
}

function Image({ lazyload = false, placeholder = '', src: imageSrc, alt, width, height, ...props }: ImageProps) {
  let src = imageSrc as string;
  if (imageSrc && isStaticImageData(imageSrc)) {
    src = imageSrc.src;
    width ??= imageSrc.width;
    height ??= imageSrc.height;
  }
  let placeholderSrc = placeholder as string;
  let placeholderWidth = width;
  let placeholderHeight = height;
  if (placeholder && isStaticImageData(placeholder)) {
    placeholderSrc = placeholder.src;
    placeholderWidth = placeholder.width;
    placeholderHeight = placeholder.height;
  }
  const [error, setError] = useState(false);

  const onError = useCallback(
    (event) => {
      setError(true);
      props.onError?.(event);
    },
    [src]
  );
  const createPlaceholder = () => (
    <img {...props} src={placeholderSrc} alt={alt} width={placeholderWidth} height={placeholderHeight} />
  );
  if (!imageSrc || error) {
    if (placeholder) {
      return createPlaceholder();
    }
    return null;
  }
  if (lazyload) {
    return (
      <LazyLoadSensor partialVisibility placeholder={createPlaceholder()}>
        <img {...props} src={src} alt={alt} width={width} height={height} onError={onError} />
      </LazyLoadSensor>
    );
  }

  return <img {...props} src={src} alt={alt} width={width} height={height} onError={onError} />;
}

export default Image;
