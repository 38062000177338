import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../button/Button';
import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import { unpublishMyObject, deleteMyObject } from '../../../legacyPages/MyObject/MyObjectActions';

const propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  action: PropTypes.oneOf(['delete', 'unpublish']),
};

const defaultProps = {
  isOpen: false,
  action: 'delete',
};

const DeleteMyObjectModal = (props) => {
  const { isOpen, closeModal, action, address, location, id, friendlyId, t, unpublishMyObject, deleteMyObject } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <ModalHeader
        title={
          action === 'unpublish' ? t('deleteMyObjectModal.unpublish.title') : t('deleteMyObjectModal.delete.title')
        }
      />
      <section>
        <div className="dmo__address-wrap">
          <h4 className="dmo__address">{address}</h4>
          <div className="dmo__location">{location}</div>
        </div>
        <div className="dmo__id">ID: {friendlyId}</div>
      </section>
      <p>{action === 'unpublish' ? t('deleteMyObjectModal.unpublish.body') : t('deleteMyObjectModal.delete.body')}</p>
      <ModalFooter>
        <Button transparent extended text={t('common.cancel')} onClick={closeModal} />
        <Button
          primary
          extended
          text={t('common.confirm')}
          onClick={() => {
            if (action === 'unpublish') {
              unpublishMyObject(id);
            } else {
              deleteMyObject(id);
            }
            closeModal();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

DeleteMyObjectModal.propTypes = propTypes;
DeleteMyObjectModal.defaultProps = defaultProps;

const mapDispatchToProps = { unpublishMyObject, deleteMyObject };

export default connect(null, mapDispatchToProps)(DeleteMyObjectModal);
