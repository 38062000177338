import { fromJS } from 'immutable';
import { SORTING_VALUES } from './filters';

// BROKER SEARCH SORTING OPTIONS
export const COMPANY_SEARCH_SORTING = fromJS([
  SORTING_VALUES.default,
  { name: 'brokerSearch.sorting.mostObjectsFirst', value: 'mostObjectsFirst' },
  { name: 'companySearch.sorting.mostBrokersFirst', value: 'mostBrokersFirst' },
  { name: 'brokerSearch.sorting.alphabetical', value: 'alphabetical' },
]);

export const COMPANY_OBJECTS_PER_PAGE = 30;
