/* eslint max-classes-per-file: "off" */
import { BadRequestError } from './http';

export class ViolationError extends BadRequestError {
  name = 'ViolationError';

  violations: { message: string }[];

  constructor(
    message: string,
    violations: typeof ViolationError.prototype.violations = [],
    context?: typeof BadRequestError.prototype.context,
    body?: typeof BadRequestError.prototype.body
  ) {
    super(message, context, body);
    this.violations = violations;
  }

  static fromBadRequestError(err: BadRequestError): ViolationError {
    return new ViolationError(err.message, err.body.violations, err.context, err.body);
  }
}

export class ImageUploadError extends Error {
  context: { file?: File; event?: ErrorEvent };

  constructor(message: string, context: typeof ImageUploadError.prototype.context) {
    super(message);
    this.context = context;
  }
}
