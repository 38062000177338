import RouteType from '@city24/common/enums/nearbyPlace/RouteType';
import PlaceType from '@city24/common/enums/nearbyPlace/PlaceType';

// Map constants
/**
 * Returns map default bounds
 * @param {string} latKey Latitude key in result objects
 * @param {string} lngKey Longitude key in result objects
 * @returns {Array.<{lat: number, lng: number}>} Southwest and northeast bounds
 */
export function getMapDefaultBounds(latKey = 'lat', lngKey = 'lng') {
  if (PORTAL_EE) {
    // EE bounds
    return [
      { [lngKey]: 21.76, [latKey]: 57.52 },
      { [lngKey]: 28.21, [latKey]: 59.76 },
    ];
  }
  // LV bounds
  return [
    { [lngKey]: 20.96, [latKey]: 55.68 },
    { [lngKey]: 28.25, [latKey]: 58.09 },
  ];
}

export function getPlaceTypeCodes(group: string) {
  switch (group) {
    case 'transport':
      return [PlaceType.BusStation, PlaceType.TrainStation, PlaceType.FerryStation, PlaceType.Airport];
    case 'school':
      return [PlaceType.School, PlaceType.University, PlaceType.SpecializedSchool, PlaceType.VocationalSchool];
    case 'kindergarden':
      return [PlaceType.Kindergarden];
    default:
      return [];
  }
}

export function getRouteTypeName(routeType: RouteType | string) {
  switch (routeType) {
    case RouteType.Tram:
      return 'tram';
    case RouteType.Trolley:
      return 'trolley';
    case RouteType.SubwayTrain:
    case RouteType.RailwayTrain:
      return 'train';
    case RouteType.Ferry:
      return 'ferry';
    case RouteType.Bus:
    default:
      return 'bus';
  }
}

// Street view
export const STREET_VIEW_RADIUS = 100;

export enum MapLocationType {
  DO_NOT_SHOW_MAP = 'DO_NOT_SHOW_MAP',
}
